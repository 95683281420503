<template>
  <v-app>
    <v-container class="pb-0 mt-n4">
      <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <div style="height: 80vh" class="pt-16 pa-6 pa-sm-4 pa-md-4 pb-0  d-none d-md-block">
        <v-col cols="12" class="pl-7 pb-0">
          <v-card class="mt-5 elevation-0 ">
            <v-toolbar v-if="tab == 0" class="mt-3 mb-1 px-1  toolbar-contant " elevation="0" color="white">
              <v-list class="" style="background-color: transparent;">
                <v-list-item class=" pt-0 px-0 ">
                  <v-list-item-content class=" pt-0">
                    <v-list-item-title class="text-start"
                      style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                      Tradebook</v-list-item-title>
                    <v-list-item-subtitle class="text-start pt-1"
                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">All your
                      trade activity based on dates
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-list class="mr-7 pt-0" style="background-color: transparent;">
                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                  <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
                  <!-- height="16px" /> -->
                  <v-list-item-content class="pl-2 pa-0">
                    <v-list-item-title class="text-start "
                      style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                      {{ TradeBookData.length }}
                      Trades</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-menu v-if="TradeBookData.length > 0" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                    <v-list-item-title @click="valdwl(item.title)">{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu  v-if="TradeBookData.length > 0" :disabled="loader" v-model="menuvaluforfilter" bottom offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                <v-card class="elevation-0">
                  <v-radio-group 
                      v-model="radios2"
                      mandatory
                      class="pt-0 mb-n4"
                    >
                    <div>
                      <v-list>
                      <v-list-item>
                          <v-radio  color="black" label="All" value="all" class="mr-2"></v-radio>
                          <v-radio color="black"  label="Equities" value="eq" class="mr-2"></v-radio>
                          <v-radio color="black"  label="Future & Options" value="fno"  class="mr-2 mt-n2"></v-radio>
                        </v-list-item>
                    </v-list>
                    </div>
                          <div class="mt-n4">
                            <v-list>
                      <v-list-item>
                          
                          <v-radio color="black"  label="Commodities" value="com"  class="mr-2"></v-radio>
                          <v-radio color="black"  label="Currencies" value="cur"  class="mr-2 mt-n2"></v-radio>
                      </v-list-item>
                    </v-list>
                          </div>
                      
                    </v-radio-group>
                    <v-divider class="mt-n2"></v-divider>
                    <v-radio-group
                   
                    class="pt-0 "
                      v-model="radios3"
                      mandatory
                    >
                    <v-list>
                      <v-list-item>
                          <v-radio label="All"  color="black"  value="allbuysell" class="mr-2"></v-radio>
                          <v-radio label="Buy" color="black" value="buy" class="mr-2"></v-radio>
                          <v-radio label="Sell"  color="black" value="sell" class="mr-2 mt-n2"></v-radio>
                          
                      </v-list-item>
                    </v-list> 
                    <v-btn @click="filtersegforbuysell(radios2,radios3)" color="black" class="mx-4 elevation-0 text-capitalize" rounded outlined>Submit</v-btn>
                    </v-radio-group>
                </v-card>
                    
                  
              </v-menu>
              <!-- prepend-inner-icon="mdi-filter-variant" -->
             
              <v-menu  :disabled="loader" class="" left  v-model="menu2" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat v-model="fromshow"
                    label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                
                
                <v-card>
                  <div>
                    <v-date-picker  :max="maxval" color="black"  :highlighted="highlightedDates" v-model="fromdate" @input="dateinsert()"></v-date-picker>
                    <v-date-picker  :max="maxval" color="black" :highlighted="highlightedDates" v-model="todate" @change="gettradedata()"></v-date-picker>
                  </div>
                  <v-divider></v-divider>
                  <div class="d-flex mt-n2">
                    <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="mountedfun(7)">Last 7 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfun(30)">Last 30 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(0)">Current FY</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-1)">Last FY</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-2)">{{yearsarray[0]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-3)">{{yearsarray[1]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-4)">{{yearsarray[2]}}</p>
                    
                    <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                  </div>
                </v-card>
              </v-menu>
              <!-- <v-select :items="filterr" @change="filterseg(filterdata_unpl)" v-model="filterdata_unpl"
                placeholder="Filter" dense solo rounded flat background-color="#F1F3F8" hide-details
                class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
              </v-select> -->
              <v-text-field style="max-width: 190px;" v-model="search" class="tophundraedmutual mt-4  " height="36px"
                background-color="#F1F3F8" label="Search" solo flat rounded dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>

            </v-toolbar>
            <v-toolbar v-else class="mt-3 mb-1 px-1  toolbar-contant " elevation="0" color="white">
              <v-list class="" style="background-color: transparent;">
                <v-list-item class=" pt-0 px-0 ">
                  <v-list-item-content class=" pt-0">
                    <v-list-item-title class="text-start"
                      style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                      Contract Note</v-list-item-title>
                    <v-list-item-subtitle class="text-start pt-1"
                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <!-- <v-list class="mr-7 pt-0" style="background-color: transparent;">
                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                  
                  <v-list-item-content class="pl-2 pa-0">
                    <v-list-item-title class="text-start "
                      style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                      {{ TradeBookData.length }}
                      Trades</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->
              <v-menu  bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in itemsdwlcont" :key="i" @click="() => { }">
                    <v-list-item-title @click="valdwlcont(item.title)">{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-menu v-model="menuvaluforfilter" bottom offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                <v-card class="elevation-0">
                  <v-radio-group 
                      v-model="radios2"
                      mandatory
                      class="pt-0 mb-n4"
                    >
                    <div>
                      <v-list>
                      <v-list-item>
                          <v-radio  color="black" label="All" value="all" class="mr-2"></v-radio>
                          <v-radio color="black"  label="Equities" value="eq" class="mr-2"></v-radio>
                          <v-radio color="black"  label="Future & Options" value="fno"  class="mr-2 mt-n2"></v-radio>
                        </v-list-item>
                    </v-list>
                    </div>
                          <div class="mt-n4">
                            <v-list>
                      <v-list-item>
                          
                          <v-radio color="black"  label="Commodities" value="com"  class="mr-2"></v-radio>
                          <v-radio color="black"  label="Currencies" value="cur"  class="mr-2 mt-n2"></v-radio>
                      </v-list-item>
                    </v-list>
                          </div>
                      
                    </v-radio-group>
                    <v-divider class="mt-n2"></v-divider>
                    <v-radio-group
                   
                    class="pt-0 "
                      v-model="radios3"
                      mandatory
                    >
                    <v-list>
                      <v-list-item>
                          <v-radio label="All"  color="black"  value="allbuysell" class="mr-2"></v-radio>
                          <v-radio label="Buy" color="black" value="buy" class="mr-2"></v-radio>
                          <v-radio label="Sell"  color="black" value="sell" class="mr-2 mt-n2"></v-radio>
                          
                      </v-list-item>
                    </v-list> 
                    <v-btn @click="filtersegforbuysell(radios2,radios3)" color="black" class="mx-4 elevation-0 text-capitalize" rounded outlined>Submit</v-btn>
                    </v-radio-group>
                </v-card>
                    
                  
              </v-menu> -->
              <!-- prepend-inner-icon="mdi-filter-variant" -->
              <v-select  :items="filterrForContract" @change="filterrForContractFun(filterValue)" dense v-model="filterValue" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 15%; font-size: 21px">
              </v-select>
              <v-menu class="" left  v-model="menuForContract" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat v-model="fromshowContract"
                    label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                
                
                <v-card>
                  <div>
                    <v-date-picker  :max="maxval" color="black"  :highlighted="highlightedDates" v-model="FromValForContract" @input="dateinsertContract()"></v-date-picker>
                    <!-- <v-date-picker  :max="maxval" color="black" :highlighted="highlightedDates" v-model="todate" @change="gettradedata()"></v-date-picker> -->
                  </div>
                  <v-divider></v-divider>
                  <!-- <div class="d-flex mt-n2">
                    <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="mountedfun(7)">Last 7 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfun(30)">Last 30 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(0)">Current FY</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-1)">Last FY</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-2)">{{yearsarray[0]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-3)">{{yearsarray[1]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-4)">{{yearsarray[2]}}</p>
                     
                  </div> -->
                </v-card>
              </v-menu>
              <!-- <v-select :items="filterr" @change="filterseg(filterdata_unpl)" v-model="filterdata_unpl"
                placeholder="Filter" dense solo rounded flat background-color="#F1F3F8" hide-details
                class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
              </v-select> -->
              <v-text-field style="max-width: 190px;" v-model="search" class="tophundraedmutual mt-4  " height="36px"
                background-color="#F1F3F8" label="Search" solo flat rounded dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>

            </v-toolbar>
            <v-divider></v-divider>
            <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
              <v-tab v-for="item in itemstab" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs> -->

            <!-- <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table ref="dataTableRef" :search="search" mobile-breakpoint hide-default-footer fixed-header
                  disable-sort height="420px" :headers="HeaderForTrade" :items="TradeBookData" :loading="loader"
                  :items-per-page="itemperpage" style="border: 1px solid #EFEEF3;" class="elevation-0 rounded-lg mt-6">
                 
                  <template v-slot:[`item.SCRIP_NAME`]="{ item }">
                    <span class="font-weight-regular subtitle-2 mt-2"> {{ item.SCRIP_NAME }} </span><span class="caption"
                      v-if="item.STRIKE_PRICE != 0"> {{ (item.STRIKE_PRICE) }}</span> <span class="caption">{{
                        (item.OPTION_TYPE) }}</span>
                    <span v-if="item.showseries != 'EQ'" class="caption mb-0 font-weight-regular pl-2">{{ item.EXPIRY_DATE
                    }}</span>
                  </template>
                  <template v-slot:[`item.COMPANY_CODE`]="{ item }">
                    <span class="font-weight-regular subtitle-2"> {{ item.COMPANY_CODE }}</span>
                  </template>
                  <template v-slot:[`item.TRADE_DATE`]="{ item }">
                    <span class="font-weight-bold subtitle-2"> {{ item.TRADE_DATE }}</span>
                  </template>
                  <template v-slot:[`item.showamt`]="{ item }">
                    <span class="font-weight-regular">₹{{ item.showamt }}</span>
                  </template>
                  <template v-slot:[`item.TRADE_NUMBER`]="{ item }">
                    <span class="font-weight-regular text-right"> {{ item.TRADE_NUMBER }}</span>
                  </template>
                  <template v-slot:[`item.showtype`]="{ item }">
                    <span v-if="item.showtype == 'BUY'" style="color: green;" class="font-weight-regular">BUY</span>
                    <span v-else-if="item.showtype == 'SELL'" style="color: red;" class="font-weight-regular">
                      SELL</span>
                  </template>
                  <template v-slot:[`item.showprice`]="{ item }">
                    <span class="font-weight-regular" style="text-align:right"> ₹{{ item.showprice }}</span>
                  </template>
                  <template v-slot:[`item.showqnt`]="{ item }">
                    <span class="font-weight-regular"> {{ item.showqnt }}</span>
                  </template>
                  <template v-slot:[`item.STRIKE_PRICE`]="{ item }">
                    <span class="font-weight-regular"> ₹{{ item.STRIKE_PRICE }}</span>
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="20%" height="" />
                      <h4 style="color: rgb(165, 165, 165)">No data available</h4>
                    </v-col>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table hide-default-footer :headers="dessertHeaders" :items="dessertstabs"
                  :single-expand="singleExpand" :expanded.sync="expanded" item-key="name" show-expand class="elevation-1">
                
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      More info about {{ item.name }}
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items> -->
            <!-- {{tab}} -->
            <v-tabs background-color="transparent" color="basil"  v-model="tab">
              <v-tab>
                Tradebook
              </v-tab>
              <v-tab>
                Contract Note
              </v-tab> 
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table ref="dataTableRef" :search="search" mobile-breakpoint hide-default-footer fixed-header
                  disable-sort height="420px" :headers="HeaderForTrade" :items="TradeBookData" :loading="loader"
                  :items-per-page="itemperpage" style="border: 1px solid #EFEEF3;" class="elevation-0 rounded-lg ">
                 
                  <template v-slot:[`item.SCRIP_NAME`]="{ item }">
                    <span class="font-weight-regular subtitle-2 mt-2"> {{ item.SCRIP_NAME }} </span><span class="caption"
                      v-if="item.STRIKE_PRICE != 0"> {{ (item.STRIKE_PRICE) }}</span> <span class="caption">{{
                        (item.OPTION_TYPE) }}</span>
                    <span v-if="item.showseries != 'EQ'" class="caption mb-0 font-weight-regular pl-2">{{ item.EXPIRY_DATE
                    }}</span>
                  </template>
                  <template v-slot:[`item.COMPANY_CODE`]="{ item }">
                    <span class="font-weight-regular subtitle-2"> {{ item.COMPANY_CODE }}</span>
                  </template>
                  <template v-slot:[`item.TRADE_DATE`]="{ item }">
                    <span class="font-weight-bold subtitle-2"> {{ item.TRADE_DATE }}</span>
                  </template>
                  <template v-slot:[`item.showamt`]="{ item }">
                    <span class="font-weight-regular">₹{{ item.showamt }}</span>
                  </template>
                  <template v-slot:[`item.TRADE_NUMBER`]="{ item }">
                    <span class="font-weight-regular text-right"> {{ item.TRADE_NUMBER }}</span>
                  </template>
                  <template v-slot:[`item.showtype`]="{ item }">
                    <span v-if="item.showtype == 'BUY'" style="color: green;" class="font-weight-regular">BUY</span>
                    <span v-else-if="item.showtype == 'SELL'" style="color: red;" class="font-weight-regular">
                      SELL</span>
                  </template>
                  <template v-slot:[`item.showprice`]="{ item }">
                    <span class="font-weight-regular" style="text-align:right"> ₹{{ item.showprice }}</span>
                  </template>
                  <template v-slot:[`item.showqnt`]="{ item }">
                    <span class="font-weight-regular"> {{ item.showqnt }}</span>
                  </template>
                  <template v-slot:[`item.STRIKE_PRICE`]="{ item }">
                    <span class="font-weight-regular"> ₹{{ item.STRIKE_PRICE }}</span>
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-16">
                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                    <p class="font-weight-bold black--text">No data available</p>
                  </v-col>
                      </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item>
               
                <v-data-table ref="dataTableRef" :search="search" mobile-breakpoint hide-default-footer fixed-header
                    disable-sort height="420px" :headers="HeaderForContract" :items="ContractData" :loading="loaderForContract"
                    :items-per-page="-1" :group-by="['SCRIP_SYMBOL']" style="border: 1px solid #EFEEF3;" class="elevation-0 rounded-lg">
                    <template slot="group.header" slot-scope="{  }">
                      <!-- <tr style="background-color: ">
                        <td >
                          <strong class="mb-0 ml-2 mt-1">{{ items[0].COMPANY_CODE }}</strong>
                        </td>
                      </tr> -->
                    </template>
                    
                    <template slot="group.summary" slot-scope="{  items }">
                      <!-- <tr> -->
                        <td style="background-color: #F9FBFC"> 
                        
                        <p class="caption font-weight-bold  mb-0 ml-2">Total Buy:<span   class="caption font-weight-regular ml-2">{{(NetData[items[0].SCRIP_SYMBOL])[0].BUY_QUANTITY}} @ {{((NetData[items[0].SCRIP_SYMBOL])[0].Buy_Rate).toFixed(4)}} = {{((NetData[items[0].SCRIP_SYMBOL])[0].Buy_Amount).toFixed(4)}}</span></p>

                        
                        </td>
                        <td style="background-color: #F9FBFC">  </td>
                        <td style="background-color: #F9FBFC"> 
                          
                          <p class="caption font-weight-bold mb-0 ml-2">Total Sell : <span   class="caption  font-weight-regular  ml-2">{{(NetData[items[0].SCRIP_SYMBOL])[0].SELL_QUANTITY}} @ {{((NetData[items[0].SCRIP_SYMBOL])[0].Sell_Rate).toFixed(4)}} = {{((NetData[items[0].SCRIP_SYMBOL])[0].Sell_Amount).toFixed(4)}}</span></p>

                        
                        </td>
                        <td style="background-color: #F9FBFC">  </td> 
                        <td style="background-color: #F9FBFC">  </td>
                        <td style="background-color: #F9FBFC">  </td>
                        <td style="background-color: #F9FBFC">  </td>
                        <td style="background-color: #F9FBFC">  </td>
                        
                        <td style="background-color: #f9fbfc"><p class="caption font-weight-bold  ml-2 mb-0">Net : <span class="font-weight-regular">{{((NetData[items[0].SCRIP_SYMBOL])[0].NET_AMT).toFixed(4)}}</span> </p></td>
                      <!-- </tr> -->
                    </template>
                    <template v-slot:[`item.ORDER_NUMBER`]="{ item }">
                      <span class="font-weight-regular body-2 mt-2">{{ item.ORDER_NUMBER }}</span>
                      <p class="font-weight-regular caption  mb-0">{{ item.ORDER_DATETIME.split(" ")[1] }}</p>

                    </template>
                    <template v-slot:[`item.srcname`]="{ item }">
                      <span class="font-weight-bold caption mt-2">{{ item.INSTRUMENT_TYPE }} {{ item.SCRIP_NAME }} {{ item.EXPIRY_DATE.split(" ")[0] }} {{ (item.STRIKE_PRICE) }} {{ item.OPTION_TYPE }}</span>
                      
                    </template>
                    <template v-slot:[`item.TRADE_NUMBER`]="{ item }">
                      <span v-if="item.TRADE_NUMBER" class="font-weight-regular body-2 mt-2">{{ item.TRADE_NUMBER }}</span>
                      <span v-else class="font-weight-regular body-2 mt-2">-</span>
                      <p class="font-weight-regular caption mb-0">{{ item.TRADE_DATETIME.split(" ")[1] }}</p>

                    </template>
                    <template v-slot:[`item.buySell`]="{ item }">
                      <span class="font-weight-regular subtitle-2 green--text mt-2" v-if="item.BUY_SALE == 'BUY'">B</span>
                      <span class="font-weight-regular subtitle-2 mt-2 red--text" v-else-if="item.BUY_SALE == 'SALE'">S</span>
                      <span class="font-weight-regular subtitle-2 mt-2" v-else >-</span>

                    </template>
                    <template v-slot:[`item.netrate`]="{ item }">
                      <span class="font-weight-regular subtitle-2  mt-2" v-if="item.BUY_SALE == 'BUY'">{{ item.BUY_PRICE }}</span>
                      <span class="font-weight-regular subtitle-2 mt-2  " v-else-if="item.BUY_SALE == 'SALE'">{{ item.SELL_PRICE }}</span>
                      <span class="font-weight-regular subtitle-2 mt-2" v-else >-</span>

                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                      <span class="font-weight-regular subtitle-2 mt-2  "  >{{item.QUANTITY}}</span>

                      <!-- <span class="font-weight-regular subtitle-2   mt-2" v-if="item.NET_BUY_PRICE">{{item.BUY_QUANTITY}}</span>
                      <span class="font-weight-regular subtitle-2 mt-2  " v-else-if="item.NET_SELL_PRICE">-{{item.SELL_QUANTITY}}</span> -->
                    </template>
                    <template v-slot:[`item.grossRate`]="{ item }">
                      <span class="font-weight-regular subtitle-2 mt-2" v-if="item.NET_BUY_PRICE">{{item.NET_BUY_PRICE.toFixed(4)}}</span>
                      <span class="font-weight-regular subtitle-2 mt-2" v-else-if="item.NET_SELL_PRICE">{{item.NET_SELL_PRICE.toFixed(4)}}</span>
                      <span class="font-weight-regular subtitle-2 mt-2" v-else >-</span>

                    </template> 
                    
                    <template v-slot:[`item.NetAmount`]="{ item }">
                      <span class="font-weight-regular subtitle-2 mt-2" v-if="item.NET_BUY_PRICE">{{((Number(item.NET_BUY_PRICE))*(Number(item.BUY_QUANTITY))).toFixed(2)}}  </span>
                      <span class="font-weight-regular subtitle-2 mt-2" v-else-if="item.NET_SELL_PRICE">{{((Number(item.NET_SELL_PRICE))*(Number(item.SELL_QUANTITY))).toFixed(2)}}</span>
                      <span class="font-weight-regular subtitle-2 mt-2" v-else >0</span>

                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-16">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
                    </template>
                  </v-data-table>
              </v-tab-item>
            </v-tabs-items>
            
          </v-card>
        </v-col>
        <div v-if="tab == 1" class="ml-8 mt-4 ">
          <v-list  class="" style="background-color: transparent;">
          <v-list-item class=" pt-0 px-0 ">
              <v-list-item-content class=" pt-0">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                  Settlement</v-list-item-title> 
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-data-table ref="dataTableRef" :search="search" mobile-breakpoint hide-default-footer fixed-header
                  disable-sort height="420px" :headers="HeaderForSettlement" :items="SettlementData" :loading="loader"
                  :items-per-page="itemperpage" style="border: 1px solid #EFEEF3;" class="elevation-0 rounded-lg mt-n4">
                 
                   
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-16">
                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                    <p class="font-weight-bold black--text">No data available</p>
                  </v-col>
                      </template>
                </v-data-table>
        </div>
      </div>
      <div class="d-md-none">
        <v-toolbar v-if="tab == 0" class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent;">
            <v-list-item class=" pt-0 px-0">
              <v-list-item-content class=" pt-0">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                  Tradebook </v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                  style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">All your trade activity based on
                  dates
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-list class=" pt-0" style="background-color: transparent;">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
            </v-list-item>
          </v-list>
        </v-toolbar>
        <v-divider v-if="tab == 0" class=" mx-2"></v-divider>
        <div v-if="tab == 0" class="d-flex row">
          <p class="text-start  mb-4  mt-5 ml-2 px-4 "
            style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
            No of trades
            <span class=" pa-1 rounded-lg" style=" color: #000000;">{{ (TradeBookData.length) }}</span>
          </p>
        </div>
        <v-divider v-if="tab == 0" class=" mx-2"></v-divider>
        <v-row v-if="tab == 0" class="px-2">
          
          <v-col cols="9">
            <v-text-field v-if="TradeBookData.length > 1" style="width: 100%;" v-model="search"
              class="tophundraedmutual   mt-3  " height="24px" background-color="#F1F3F8" label="Search" solo text-align
              flat rounded dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="16px" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-menu v-model="menuvaluforfiltermobile" bottom offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-3 mx-2 mb-2" text solo v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                <v-card class="elevation-0">
                  <v-radio-group 
                      v-model="radios2"
                      mandatory
                      class="pt-0 mb-n4"
                    >
                    <div>
                      <v-list>
                      <v-list-item>
                          <v-radio  color="black" label="All" value="all" class="mr-2"></v-radio>
                          <v-radio color="black"  label="Equities" value="eq" class="mr-2"></v-radio>
                          <v-radio color="black"  label="Future & Options" value="fno"  class="mr-2 mt-n2"></v-radio>
                        </v-list-item>
                    </v-list>
                    </div>
                          <div class="mt-n4">
                            <v-list>
                      <v-list-item>
                          
                          <v-radio color="black"  label="Commodities" value="com"  class="mr-2"></v-radio>
                          <v-radio color="black"  label="Currencies" value="cur"  class="mr-2 mt-n2"></v-radio>
                      </v-list-item>
                    </v-list>
                          </div>
                      
                    </v-radio-group>
                    <v-divider class="mt-n2"></v-divider>
                    <v-radio-group
                   
                    class="pt-0 "
                      v-model="radios3"
                      mandatory
                    >
                    <v-list>
                      <v-list-item>
                          <v-radio label="All"  color="black"  value="allbuysell" class="mr-2"></v-radio>
                          <v-radio label="Buy" color="black" value="buy" class="mr-2"></v-radio>
                          <v-radio label="Sell"  color="black" value="sell" class="mr-2 mt-n2"></v-radio>
                          
                      </v-list-item>
                    </v-list> 
                    <v-btn @click="filtersegforbuysell(radios2,radios3)" color="black" class="mx-4 elevation-0 text-capitalize" rounded outlined>Submit</v-btn>
                    </v-radio-group>
                </v-card>
                    
                  
              </v-menu>
          </v-col>
          <v-col cols="1">
            <div class="text-center">
              <v-bottom-sheet v-model="sheet" inset>

                <v-sheet class="text-center" height="200px">
                  <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                    <v-icon size="40">mdi mdi-close-circle</v-icon>
                  </v-btn> -->
                  <!-- {{ radios }}/ -->
                  <p class="mb-0 pt-3 title font-weight-medium">Tradebook</p>
                  <v-card class="elevation-0 ma-8 mt-0">
                    <v-radio-group class="mt-0" v-model="radios" mandatory>
                      <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                      <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                    </v-radio-group>
                    <v-btn @click="valdwl(radios)" class="white--text elevation-0" color="black" block rounded flat solo>
                      {{
                        radios }}</v-btn>
                  </v-card>
                </v-sheet>
              </v-bottom-sheet>
            </div>
            <v-btn v-if="TradeBookData.length > 0" @click="sheet = true" class="ml-auto mr-6 pt-2 mt-2" flat solo icon> <v-icon>mdi
                mdi-download</v-icon></v-btn>
          </v-col>
          <v-col cols="12">
            <v-menu class="" :offset-x="offset" left v-model="menu23" :close-on-content-click="false"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-n3" solo rounded text flat v-model="fromshow" label="From date" dense color="black"
                  background-color="#F1F3F8" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
             
              <v-card>
                  <div>
                    <v-date-picker color="black" v-model="fromdate" @input="dateinsert()"></v-date-picker>
                    <v-date-picker :min="fromdate" color="black" v-model="todate" @change="gettradedata()"></v-date-picker>
                  </div>
                  <v-divider></v-divider>
                  <div class="d-flex mt-n2">
                    <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="mountedfun(7)">Last 7 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfun(30)">Last 30 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(0)">Current F Year</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-1)">Last F Year</p>

                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-2)">{{yearsarray[0]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-3)">{{yearsarray[1]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-4)">{{yearsarray[2]}}</p>
                    <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                  </div>
                </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-toolbar v-if="tab == 1" class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent;">
            <v-list-item class=" pt-0 px-0">
              <v-list-item-content class=" pt-0">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                  Contract Note </v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                  style="color: #666666;font-size: 12px;font-weight:469;line-height:14px"> 
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-list class=" pt-0" style="background-color: transparent;">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
            </v-list-item>
          </v-list>
        </v-toolbar> 
        <v-divider v-if="tab == 1" class=" mx-2"></v-divider>
        <v-row v-if="tab == 1" class="px-2">
          
          <v-col cols="10">
            <v-text-field v-if="TradeBookData.length > 1" style="width: 100%;" v-model="search"
              class="tophundraedmutual   mt-3  " height="24px" background-color="#F1F3F8" label="Search" solo text-align
              flat rounded dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="16px" />
              </template>
            </v-text-field>
          </v-col>
        
          <v-col cols="2">
            <div class="text-center">
              <v-bottom-sheet v-model="sheet" inset>

                <v-sheet class="text-center" height="200px">
                  <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                    <v-icon size="40">mdi mdi-close-circle</v-icon>
                  </v-btn> -->
                  <!-- {{ radios }}/ -->
                  <p class="mb-0 pt-3 title font-weight-medium">Tradebook</p>
                  <v-card class="elevation-0 ma-8 mt-0">
                    <v-radio-group class="mt-0" v-model="radios" mandatory>
                      <!-- <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio> -->
                      <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                    </v-radio-group>
                    <v-btn @click="valdwlcont(radios)" class="white--text elevation-0" color="black" block rounded flat solo>
                      {{
                        radios }}</v-btn>
                  </v-card>
                </v-sheet>
              </v-bottom-sheet>
            </div>
            <v-btn   @click="sheet = true" class="ml-auto mr-6 pt-2 mt-2" flat solo icon> <v-icon>mdi
                mdi-download</v-icon></v-btn>
          </v-col>
          <v-col cols="6">
            <v-menu class="" left  v-model="menuForContractMobile" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field   class="mt-n4 " solo rounded flat v-model="fromshowContract"
                    label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                
                
                <v-card>
                  <div>
                    <v-date-picker  :max="maxval" color="black"  :highlighted="highlightedDates" v-model="FromValForContract" @input="dateinsertContract()"></v-date-picker>
                    <!-- <v-date-picker  :max="maxval" color="black" :highlighted="highlightedDates" v-model="todate" @change="gettradedata()"></v-date-picker> -->
                  </div>
                  <v-divider></v-divider>
                  <!-- <div class="d-flex mt-n2">
                    <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="mountedfun(7)">Last 7 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfun(30)">Last 30 days</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(0)">Current FY</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-1)">Last FY</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-2)">{{yearsarray[0]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-3)">{{yearsarray[1]}}</p>
                    <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-4)">{{yearsarray[2]}}</p>
                     
                  </div> -->
                </v-card>
              </v-menu>
          </v-col>
          <v-col cols="6">
            <v-select :items="filterrForContract" @change="filterrForContractFun(filterValue)" dense v-model="filterValue" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px"></v-select>
          </v-col>
        </v-row>
        <v-tabs background-color="transparent" color="basil"  v-model="tab">
              <v-tab>
                Tradebook
              </v-tab>
              <v-tab>
                Contract Note
              </v-tab> 
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card v-if="filteredDesserts.length == 0 && loader == false"
                style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
                width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">No data available</p>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                class="elevation-0  px-4" width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-for="item in filteredDesserts " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                class="elevation-0  px-4" width="100%">
                <div class="d-flex row pa-4">
                  <p class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ item.SCRIP_NAME }}
                  </p>
                  <p class="ml-auto  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ item.showqnt }}
                  </p>
                </div>

                <v-divider class="mb-2"></v-divider>
                <v-row class="px-2">
                  <v-col cols="9">

                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item.COMPANY_CODE }}
                      <span class="mb-0 caption font-weight-regular" style="color: #000000;">{{ item.TRADE_DATE }}</span>
                      <span class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item.EXPIRY_DATE }}</span>

                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Amount : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">₹{{ item.showamt }}</span>
                    </p>


                  </v-col>
                  <v-col class="text-end pa-0 pt-4" cols="3">
                    <span v-if="item.showtype == 'BUY'" style="color: green;" class="font-weight-regular">BUY</span>
                    <span v-else-if="item.showtype == 'SELL'" style="color: red;" class="font-weight-regular">
                      SELL</span>


                  </v-col>
                </v-row>
              </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card v-if="ContractData.length == 0 && loaderForContract == false"
                style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
                width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">No data available</p>
                  </v-col>
                </v-row>
            </v-card>
            <div  v-else >
              <v-card v-for="(item,index) in ContractDataMobile " :key="index" style="border-top: 5px #F1F3F8 solid;"
                class="elevation-0  px-4" width="100%">
                <div v-for="(itemVal,indexVal ) in ContractDataMobile[index]" :key="indexVal">
                  <div class="d-flex row pa-4">
                  <p class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;">{{ itemVal.INSTRUMENT_TYPE }} {{ itemVal.SCRIP_NAME }} {{ itemVal.EXPIRY_DATE.split(" ")[0] }} {{ (itemVal.STRIKE_PRICE) }} {{ itemVal.OPTION_TYPE }}  
                  </p>
                  
                  <p class="ml-auto  mb-0" style="color: #000000;font-size: 15px;font-weight:459;" v-if="itemVal.NET_BUY_PRICE">{{((Number(itemVal.NET_BUY_PRICE))*(Number(itemVal.BUY_QUANTITY))).toFixed(2)}}  </p>
                  <p class="ml-auto  mb-0" style="color: #000000;font-size: 15px;font-weight:459;" v-else-if="itemVal.NET_SELL_PRICE">{{((Number(itemVal.NET_SELL_PRICE))*(Number(itemVal.SELL_QUANTITY))).toFixed(2)}}</p>
                  <p class="ml-auto  mb-0" style="color: #000000;font-size: 15px;font-weight:459;" v-else >0</p>
                  </div>

                    <v-divider class="mb-2"></v-divider>
                    <v-row class="px-2">
                      <v-col cols="9">

                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;"> 
                          <span class="mb-0 caption font-weight-regular" style="color: #000000;">Trade No/Date :{{itemVal.TRADE_NUMBER}} ({{itemVal.TRADE_DATETIME.split(" ")[1] }})</span>

                        </p>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;"> 
                          <span class="mb-0 caption font-weight-regular" style="color: #000000;">Order No/Date :{{itemVal.ORDER_NUMBER}} ({{itemVal.ORDER_DATETIME.split(" ")[1] }})</span>

                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Gross Rate : <span  v-if="itemVal.BUY_SALE == 'BUY'"
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">₹{{itemVal.BUY_PRICE}}</span>
                            <span  v-else-if="itemVal.BUY_SALE == 'SALE'"
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">₹{{itemVal.SELL_PRICE}}</span>
                            <span  v-else 
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">-</span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;"> 
                          Net Rate
                    :
                            <span class="font-weight-regular black--text subtitle-2  mt-2" v-if="itemVal.BUY_SALE == 'BUY'">{{ itemVal.NET_BUY_PRICE }}</span>
                          <span class="font-weight-regular black--text subtitle-2 mt-2  " v-else-if="itemVal.BUY_SALE == 'SALE'">{{ itemVal.NET_SELL_PRICE }}</span>
                       
                        </p>

                      </v-col>
                      <v-col class="text-end pa-0 pt-4" cols="3">
                      
                          <p class="mb-3 body-2 font-weight-regular" style="color: #000000;font-size: 15px;font-weight:459;">Net Qty : {{itemVal.QUANTITY }}
                          </p>
                          <span  v-if="itemVal.BUY_PRICE" style="color: green;" class="font-weight-regular">Buy</span>
                        <span  v-else-if="itemVal.SELL_PRICE" style="color: red;" class="font-weight-regular">
                          Sell</span>
                          <span  v-else   class="font-weight-regular">
                            -</span>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #000000;font-size: 15px;font-weight:459;">Brokerage : {{itemVal.TRADE_BROKERAGE }}
                            </p>
                      </v-col>
                    </v-row>
                </div>
                <v-divider class="mb-2"></v-divider>

                <div class="d-flex">
                  <p class="caption font-weight-bold  mb-0 ml-2">Total Buy:<span   class="caption font-weight-regular ml-2">{{(NetData[index])[0].BUY_QUANTITY}} @ {{((NetData[index])[0].Buy_Rate).toFixed(4)}} = {{((NetData[index])[0].Buy_Amount).toFixed(4)}}</span></p>
                  <p class="caption font-weight-bold ml-auto mb-0 ml-2">Total Sell:<span   class="caption font-weight-regular ml-2">{{(NetData[index])[0].SELL_QUANTITY}} @ {{((NetData[index])[0].Sell_Rate).toFixed(4)}} = {{((NetData[index])[0].Sell_Amount).toFixed(4)}}</span></p>
                </div>
                <p class="caption font-weight-bold  mb-2 ml-2">Total Net:<span   class="caption font-weight-regular ml-2">{{((NetData[index])[0].NET_AMT).toFixed(4)}} </span></p>

              </v-card>

            </div>
            

              <v-card v-if="loaderForContract" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                class="elevation-0  px-4" width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                  </v-col>
                </v-row>
              </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-divider   class="mb-2 mt-2"></v-divider>

       <div v-if="tab == 1" class=""> 
          <v-list-item-title class="text-start mt-4 mb-4 ml-4"
          style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
          Settlement </v-list-item-title>
      

       <!-- {{SettlementData}} -->
       <v-card v-for="(item,index) in SettlementData " :key="index" style="border-top: 5px #F1F3F8 solid;"
                class="elevation-0  " width="100%">
              <div v-for="(value,key) in item" :key="key">

                
                <div class="d-flex row pa-4 mx-2" >
                  <p v-if="key != 'Description'" class="text-start  mb-0" style="color: #666666;font-size: 13px;font-weight:459;"> {{ key }}</p>
                    <p v-else class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ value }}
                  </p>
                  <p v-if="key != 'Description'" class="ml-auto  mb-0" style="color: #666666;font-size: 13px;font-weight:459;"> {{value}}
                  </p>
                  <!-- <p v-else class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ value }}
                  </p> -->
                </div>

                <v-divider v-if="key == 'Description'" class="mb-2"></v-divider>
                <!-- <v-row class="px-2">
                  <v-col cols="9">

                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{key}}
                      <span class="mb-0 caption font-weight-regular" style="color: #000000;">td</span>
                      <span class="mb-1 body-2 font-weight-regular" style="color: #666666;">ed</span>

                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">
                    </p>


                  </v-col>
                  <v-col class="text-end pa-0 pt-4" cols="3">
                    


                  </v-col>
                </v-row> -->
              </div>
              </v-card>
            </div>
      </div>
    </v-container>


  </v-app>
</template>

<script>
// import CalenDartb from '../../heatmap calendars/CalenDartb.vue';
import { apiurl } from '../../../Api.js'
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import zebupdfImage from '../../../assets/zebupdf.png';

import ExcelJS from 'exceljs';
import imagePath from '@/assets/zebulogo.svg';
export default {
  data() {

    return {
      expanded: [],
      singleExpand: true,
      radios : null,
      ContractFullData : [],
      SettlementData : [],
      dessertHeaders: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
        { text: '', value: 'data-table-expand' },
      ],
      // SettlementData : [],
      HeaderForSettlement: [
        { text: 'Description', value: 'Description' },
      ],
      filterrForContract: [
              "All", "Common Contract", "Commodity Contract"
      ],
      yesterdayValue : '',
      dessertstabs: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: 1,
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: 1,
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: 7,
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: 8,
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: 16,
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: 0,
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: 2,
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: 45,
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: 22,
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: 6,
        },
      ],
      tab: null,

      itemstab: [
        'Overview', 'Family'
      ],
      FromValForContract :'',
      radios2: null,
      radios3: null,
      loaderForContract : false,
      sheet: false,
      filterr: [
        "All", "Equities", "Future & Options", "Commodities", "Currencies"
      ],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 600000)).toISOString().substr(0, 10),
      menu2: false,
      menuForContract : false,
      menuForContractMobile : false,
      menu23: false,
      menu1: false,
      dates: [''],
      profiledetails : [],
            cname : '',
      fromdate: '',
      loader: false,
      todate: '',
      Series: '',
      search: '',
      fromshowContract : '',
      ContractData : [],
      ContractDataMobile : [],
      more: true,
      less: false,
      itemperpage: 0,
      offset: '',
      NetData : [],
      fromshow: '',
      itemsdwl: [
        {
          title: 'Download Excel',
        },
        {
          title: 'Download Pdf',
        },
      ],
      itemsdwlcont: [
        {
          title: 'Download Excel',
        },
       
      ],
      msg: '',
      ContractDataForFilterr : [],
      maxval : '',
      firstmax : '',

      filterdata_unpl: "All",
      HeaderForTrade: [
        { text: "Trade Date", value: "TRADE_DATE", align: 'start', sortable: true, class: "headerfont" },

        { text: "Exchange", value: "COMPANY_CODE", align: 'start', class: "headerfont" },

        {
          text: "Scrip", value: "SCRIP_NAME", align: 'start', class: "headerfont"
        },

        { text: "Trade Type", value: "showtype", align: 'start', class: "headerfont" },
        { text: "Quantity", value: "showqnt", align: 'end', class: "headerfont" },
        { text: "Price", value: "showprice", align: 'end', class: "headerfont" },
        { text: "Amount", value: "showamt", align: 'end', class: "headerfont" },

        { text: "Trade No", value: "TRADE_NUMBER", align: 'end', class: "headerfont" },

      ],
      filterValue : 'All',
      HeaderForContract: [
      // {
      //     text: "Category",
      //     value: "",
      //   },
      { text: "Script Symbol", value: "srcname", align: 'start', class: "headerfont" },

        { text: "Order No", value: "ORDER_NUMBER", align: 'start', sortable: true, class: "headerfont" }, 
        // { text: "Order Time", value: "ORDER_TIME", align: 'start', class: "headerfont" }, 
        { text: "Trade No", value: "TRADE_NUMBER", align: 'start', class: "headerfont" }, 
        // { text: "Trade Time", value: "TRADE_TIME", align: 'start', class: "headerfont" },
        { text: "B/S", value: "buySell", align: 'end', class: "headerfont" },
        { text: "Qty", value: "qty", align: 'end', class: "headerfont" },
        // { text: "Gross Rate/Trade Price (in foreign currency)", value: "showamt", align: 'end', class: "headerfont" }, 
        // { text: "Brokerage", value: "TRADE_NUMBER", align: 'end', class: "headerfont" }, 
        { text: "Gross Rate", value: "netrate", align: 'end', class: "headerfont" }, 

        { text: "Brokrage", value: "TRADE_BROKERAGE", align: 'end', class: "headerfont" }, 
        { text: "Net Rate", value: "grossRate", align: 'end', class: "headerfont" }, 

        { text: "NetTotal", value: "NetAmount", align: 'end', class: "headerfont" }, 
      ],
      TradeBookData: [],
      alert: false,
      temparrayoffilter : [],
      finalfilterarray : [],
      menuvaluforfilter : false,
      menuvaluforfiltermobile : false,
      yearsarray : [],
    };
  },
  methods: {
    filterrForContractFun(item){
      this.ContractData = []

      if (item == "All") {
        this.ContractData = this.ContractDataForFilterr 
      }else if(item == "Common Contract"){
        let DummyDate = this.ContractFullData['Common']
          // console.log(DummyDate);
          
          for (let i = 0; i < DummyDate.length; i++) {
            let splitval = DummyDate[i].STRIKE_PRICE
            // console.log(splitval,splitval.split(".")[1]);
            let splsecval = splitval.split(".")[1]
            splsecval = Number(splsecval) 
            if (splsecval != 0) {
               var FinalValPush = splitval
            }else{
              FinalValPush = splitval.split(".")[0]
            }
            DummyDate[i].STRIKE_PRICE = FinalValPush
          }
          this.ContractData = DummyDate
      }else if(item == "Commodity Contract"){
        let DummyDate = this.ContractFullData['Commodity']
          // console.log(DummyDate);
          
          for (let i = 0; i < DummyDate.length; i++) {
            let splitval = DummyDate[i].STRIKE_PRICE
            // console.log(splitval,splitval.split(".")[1]);
            let splsecval = splitval.split(".")[1]
            splsecval = Number(splsecval) 
            if (splsecval != 0) {
                FinalValPush = splitval
            }else{
              FinalValPush = splitval.split(".")[0]
            }
            DummyDate[i].STRIKE_PRICE = FinalValPush
          }
          this.ContractData = DummyDate
      }
      
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    filtersegforbuysell(val1, val2) {
      var data = this.dessertspop;
      const seg = val1;
      const seg2 = val2;

      if (seg == 'eq') {
        this.temparrayoffilter = data.filter(item => item.COMPANY_CODE === 'NSE_CASH');
      } else if (seg == 'fno') {
        this.temparrayoffilter = data.filter(item => item.COMPANY_CODE === 'NSE_FNO');
      } else if (seg == 'com') {
        this.temparrayoffilter = data.filter(item => item.COMPANY_CODE === 'MCX');
      } else if (seg == 'cur') {
        this.temparrayoffilter = data.filter(item => item.COMPANY_CODE === 'CD_NSE');
      } else {
        this.temparrayoffilter = data;
      }

      var data2 = this.temparrayoffilter;
      if (seg2 == 'buy') {
        this.TradeBookData = data2.filter(item => item.showtype === 'BUY');
      } else if (seg2 == 'sell') {
        this.TradeBookData = data2.filter(item => item.showtype === 'SELL');
      } else {
        this.TradeBookData = data2;
      }

      this.menuvaluforfilter = false;
      this.menuvaluforfiltermobile = false;
    },

    
    filterseg(filterdata_unpl) {
      var data = this.dessertspop
      // const dataval = this.TradeBookData
      // const data2 = this.TradeBookData
      // const data3 = this.TradeBookData
      const seg = filterdata_unpl
      // console.log("sssss", seg);
      if (seg == 'Equities') {
        this.TradeBookData = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'NSE_CASH') {
            this.TradeBookData.push(data[i])
          }
        }
        this.scrollToBottom()

      } else if (seg == 'Future & Options') {
        this.TradeBookData = []
        // const data1 = this.TradeBookData
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'NSE_FNO') {
            this.TradeBookData.push(data[i])
          }
        }
        this.scrollToBottom()

      } else if (seg == 'Commodities') {
        this.TradeBookData = []
        // const data1 = this.TradeBookData
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'MCX') {
            this.TradeBookData.push(data[i])
          }
        }
        this.scrollToBottom()

      } else if (seg == 'Currencies') {
        this.TradeBookData = []
        // const data1 = this.TradeBookData
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'CD_NSE') {
            this.TradeBookData.push(data[i])
          }
        }
        this.scrollToBottom()

      }
      // else if (seg == 'Mutual Funds') {
      //   this.TradeBookData = []
      //   // const data1 = this.TradeBookData
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].COMPANY_CODE == 'MF_BSE') {
      //       this.TradeBookData.push(data[i])
      //     }
      //   }
      //   this.scrollToBottom()

      // } 
      else {
        this.TradeBookData = data
        this.scrollToBottom()

      }
    },
    scrollToBottom() {
      const dataTable = this.$refs.dataTableRef;

      if (dataTable) {
        setTimeout(() => {
          const container = dataTable.$el.querySelector('.v-data-table__wrapper');
          container.scrollTop = container.scrollHeight;
          
          // if (container.scrollTop) {
          //   alert('page go to end')
          // }
        }, 0);
      }
    },

    convertImageToBase64() {

        fetch(imagePath)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.imageBase64 = reader.result;
                };
                reader.readAsDataURL(blob);
            })
            .catch((error) => {
                console.error('Error loading local image:', error);
            });
        },
    


    valdwl(item) {
      let data = item
      // console.log("dsdsdsdsds", data);

      if (data === "Download Excel") {
  // Check if the data array is valid


  
  if (!this.TradeBookData || !Array.isArray(this.TradeBookData)) {
    console.error('Invalid data for Excel export.');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Trade Book');

  // Define header rows
  const headerRows = [
    
    ["","Trade Book","Client ID", this.actid],
    ["","","Client Name", this.cname],
    ["","", "Client PAN", this.profiledetails['PAN_NO']],
    ["","","Client email", this.profiledetails['CLIENT_ID_MAIL']],
    ["","", "From", this.fromshow.split('_to_')[0]],
    ["","","To", this.fromshow.split('_to_')[1]],

    [],
    ['Trade Date', 'Exchange', 'Scrip', 'Trade Type', 'Quantity', 'Price', 'Amount','Trade No']
  ];

  // Prepare rows data
  const rows = this.TradeBookData.map(element => [
    element.TRADE_DATE || "",
    element.COMPANY_CODE || "",
    element.SCRIP_NAME || "",
    element.showtype || "",
    element.showqnt || "",
    element.showprice || 0,
    element.showamt || 0,
    element.TRADE_NUMBER || 0
  ]);

  // Combine headerRows with data rows
  const allRows = headerRows.concat(rows);
  const footerRows = [
    [],
    ["Zebu Share And Wealth Management Private Limited"],
    ["Correspondence Office: No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096."],
    ["Phone No: 044-4855 7991, Fax: 044-4855 7991"],
    ["TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300"],
    ["CIN NO: U67120TZ2013PTC019704 Website Address: www.zebuetrade.com, Investor Grievances Email id: grievance@zebuetrade.com"]
  ];

  allRows.push(...footerRows);
  // Add rows to the worksheet
  allRows.forEach((row) => {
    const excelRow = worksheet.addRow(row);

    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);

      // Apply font and fill styles based on cell content
      if (['ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Trade Book'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 14, bold: true };
      } else if (['Trade Date', 'Exchange', 'Scrip', 'Trade Type', 'Quantity', 'Price', 'Amount', 'Trade No'].includes(cell)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }

      if (['Quantity', 'Price', 'Amount'].includes(headerRows[headerRows.length - 1][cellIndex])) {
      cellRef.alignment = { horizontal: 'right' };
      cellRef.numFmt = '#,##0.00';  // Set number format for better readability
    } else {
      // Align all other cells to the left
      cellRef.alignment = { horizontal: 'left' };
    }
    });
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });
  const imageId = workbook.addImage({
    base64: this.imageBase64,
    extension: 'svg'
  });

  worksheet.getRow(1).height = 32.53;
  worksheet.getColumn(1).width = 20;

  worksheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });
  // Write the Excel file to a buffer
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Tradebook_${this.fromshow}.xlsx`;
    link.click();
  }).catch(error => console.error('Error generating Excel file:', error));
}
 
      
        
        else if (data == 'Download Pdf') {
                let rows = [];
                // let rowsexpense = [];
                // let headerexpense = [];
                let columnHeader = ['Trade Date', 'Exchange', 'Scrip', 'Trade Type', 'Quantity', 'Price', 'Amount', 'Trade No'];

                let pdfName = `${'Trade Book'}${this.fromshow}`;
               
                // this.dessertsexpense.forEach(element => {
                //     var temp3 = [
                //         element.NET_AMOUNT
                //     ]
                //     rowsexpense.push(temp3)
                // });
          
               
                // console.log(headerexpense);
                this.TradeBookData.forEach(element => {
                    var temp = [
                    element.TRADE_DATE, element.COMPANY_CODE, element.SCRIP_NAME, element.showtype, element.showqnt, element.showprice, element.showamt, element.TRADE_NUMBER

                    ];
                    rows.push(temp);
                });
                // console.log(rows);
                // Create a new jsPDF document
                var doc = new jsPDF();
                // Add header and customize text
                //doc.setFont('Tenon');
                // doc.setFontSize(12);
                // doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
                // doc.setFontSize(6);
                // doc.text("Correspondence Office:No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096.", 38, 13);
                // doc.setFontSize(6);
                // doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
                // doc.setFontSize(6);
                // doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
                // doc.setFontSize(6);
                // doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
                doc.setFontSize(7);
                doc.setFont('bold');
                doc.text(`${'Client Name   :  '}${this.cname}`, 15, 33);
                doc.setFontSize(7);
                doc.text(`${'Client Code    :  '}${this.actid}`, 15, 37);
                doc.setFontSize(7);
                doc.text(`${'PAN               :  '}${this.profiledetails['PAN_NO']}`, 15, 41);
                doc.setFontSize(7);
                doc.text(`${'Email Id         :  '}${this.profiledetails['CLIENT_ID_MAIL']}`, 15, 45);
                doc.setFontSize(7);
                doc.text(`${'From     :  '}${this.fromshow.split('_to_')[0]}`, 133, 33);
                doc.setFontSize(7);
                doc.text(`${'To         :  '}${this.fromshow.split('_to_')[1]}`, 133, 37);
                doc.setFontSize(7);
                doc.text(`${'Mobile  :  '}${this.profiledetails['MOBILE_NO']}`, 133, 41);
                doc.setFontSize(7);
                doc.text(`${'Address :  '}${this.profiledetails['CL_RESI_ADD1']}`, 133, 45);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD2']}`, 144, 48);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD3']}`, 144, 51);
                doc.setFontSize(14);
                doc.text('Trade Book', 90, 14.5);
                const dividerY = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(0, dividerY, 218, dividerY); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                
                doc.setFontSize(8);
                // //doc.setFont('Tenon');
                
                const columnStyles = {
                    0: { fontStyle: 'bold',textColor: [0, 0, 0],halign: 'left'},
                    1 : {halign: 'left'},
                    2 : {halign: 'left'},
                    3 : {halign: 'left'},
                    4 : {halign: 'right'},
                    5:  {halign: 'right'},
                    6 : {halign: 'right'},
                    7 : {halign: 'left'},
                    // 8 : {halign: 'left'}
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
              
                autoTable(doc, { head: [columnHeader], body: rows, startY: 58, styles: {
                    
                            fontSize: 7,
                            // font: 'Tenon',
                            lineColor: [200, 200, 200],
                            // textColor: [0,0,0],
                        },theme: 'grid', // Use the 'grid' theme
                        headStyles: {
                            fillColor: [31, 52, 101], // RGB color for dark blue header background
                            textColor: [255, 255, 255], // White text color for the header
                        },
                        columnStyles: columnStyles,
                        table: {
                            // fillColor: [31, 52, 101]
                            // fillColor: tableBackgroundColor,
                        }, });
                        if (doc.internal.pages.length > 1) {
                            doc.addPage();
                            doc.autoTable.previous.finalY = 10

} else {
    doc.autoTable.previous.finalY += 20; // Add some spacing between tables
}
                        // doc.text('End table', 10, doc.autoTable.previous.finalY + 10);
                        doc.setFontSize(12);
                        doc.setTextColor(0, 0, 0);
                        
                                        // Add custom text
                                        // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                                        // Save the PDF
                                        var totalPages = doc.internal.getNumberOfPages();
                        for (var i = 1; i <= totalPages; i++) {
                            doc.setPage(i);
                            
                            
                            const dividerY = doc.internal.pageSize.height - 13; // Adjust the Y-coordinate based on your layout
                            doc.setFontSize(6);
                            doc.text(14, doc.internal.pageSize.height - 9, 'Zebu Share And Wealth Management Private Limited');
                            doc.setFontSize(4);
                            doc.text(14, doc.internal.pageSize.height - 7, 'Correspondence Office:No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096.');
                            doc.setFontSize(4);
                            doc.text(84, doc.internal.pageSize.height - 7, 'Phone No:044-4855 7991, Fax :044-4855 7991');
                            doc.setFontSize(4);
                            doc.text(14, doc.internal.pageSize.height - 5, 'TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704');
                            doc.setFontSize(4);
                            doc.text(109, doc.internal.pageSize.height - 5, 'Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com');
                            doc.setLineWidth(0.2); // Set line width
                            doc.setDrawColor(0); // Set line color to black
                            doc.line(0, dividerY, 218, dividerY); 
                        }
                                        doc.save(pdfName + '.pdf');
            }


      //pdf


   
    },

    valdwlcont(item) {
      let data = item
      this.sheet = false

      if (data === "Download Excel") {
    if (!this.ContractData || !Array.isArray(this.ContractData)) {
        console.error('Invalid data for Excel export.');
        return;
    }
    // console.log('dummmdata',this.ContractData);
    

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Trade Book');

    // Header rows
    const headerRows = [
        ["", "CONTRACT NOTE", "Client ID", this.actid],
        ["", "", "Client Name", this.cname],
        ["", "", "Client PAN", this.profiledetails?.['PAN_NO'] || ''],
        ["", "", "Client email", this.profiledetails?.['CLIENT_ID_MAIL'] || ''],
        ["", "", "Trade Date", this.fromshowContract],
        [],
        ['Script Symbol',  'Order No', 'Order Time', 'Trade No', 'Trade Time',  'B/S', 'Quantity','Gross Rate', 'Brokrage','Net Rate' ,'NetTotal']
    ];

    headerRows.forEach(row => worksheet.addRow(row));

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
        }, {});
    };

    const groupedData = groupBy(this.ContractData, 'SCRIP_SYMBOL');
    const groupedDatanet = this.NetData;

    // console.log('Grouped Data:groupedDatanet', this.NetData);

    // const netAmountMap = this.ContractData.Net || {};

    Object.keys(groupedData).forEach(series => {
        // const headingRow = worksheet.addRow([`${series}`]);
        // headingRow.font = { name: 'Arial', size: 11, bold: true };

        const rows = groupedData[series].map(element => [
        (element.INSTRUMENT_TYPE + element.SCRIP_NAME + element.EXPIRY_DATE.split(" ")[0] + element.STRIKE_PRICE + element.OPTION_TYPE) || "" ,
            element.ORDER_NUMBER || "",
            element.ORDER_DATETIME || "",
            element.TRADE_NUMBER || ".",
            element.TRADE_DATETIME || "",
            element.BUY_PRICE != "" ? 'B' : element.SELL_PRICE != "" ? "S" : ".",
            (element.QUANTITY) || "",

            element.GrossRate || element.SELL_PRICE || element.BUY_PRICE || 0,
            element.TRADE_BROKERAGE || "0",

            element.netrate || element.NET_BUY_PRICE || element.NET_SELL_PRICE || 0,
            (element.BUY_PRICE ? (Number(element.BUY_PRICE) * Number(element.BUY_QUANTITY)) 
                : (element.SELL_PRICE ? (Number(element.SELL_PRICE) * Number(element.SELL_QUANTITY)) : 0)),
            
        ]);

        // {{(NetData[items[0].SCRIP_SERIES])[0].SELL_QUANTITY}} @ 
        // {{(NetData[items[0].SCRIP_SERIES])[0].Sell_Rate}}={{(NetData[items[0].SCRIP_SERIES])[0].Sell_Amount}}

        rows.forEach(row => worksheet.addRow(row));
        
    //  console.log('series',groupedDatanet[series],groupedDatanet[series][0].BUY_QUANTITY);
    //  console.log('series',series);
        const totalbuyqty = groupedDatanet[series][0].BUY_QUANTITY
        const totalbuyqrate = groupedDatanet[series][0].Buy_Rate
        const totalbuyamount = groupedDatanet[series][0].Buy_Amount
        const totalnetam = groupedDatanet[series][0].NET_AMT


        const totalsellqty = groupedDatanet[series][0].SELL_QUANTITY
        const totalsellqrate = groupedDatanet[series][0].Sell_Rate
        const totalsellamount = groupedDatanet[series][0].Sell_Amount
        


        worksheet.addRow([`Total Buy :  ${totalbuyqty} @ ${totalbuyqrate} = ${totalbuyamount}`, "", "", `Total Sell : ${totalsellqty} @ ${totalsellqrate} = ${totalsellamount}`, "", "","","","","", `Total NET ${totalnetam}`]);




        worksheet.addRow([]);


        // const netDataAmount = netAmountMap[series] && netAmountMap[series].length > 0
        //     ? netAmountMap[series][0]?.NET_AMT || 0
        //     : 0;

        // worksheet.addRow([`NET AMOUNT for `, "", "", "", "", "", "", netDataAmount]);

        // console.log('Rows for Series:', series, rows);
        // console.log('Net Data Amount:', netDataAmount);
    });

    // Footer rows
    const descriptions = [
    'Pay In/Pay Out Obligation',
    'Taxable Value Of Supply (Brokerage)',
    'Taxable Value Of Supply (Tot)',
    'CGST* RATE:9% AMOUNT (RS.)',
    'SGST* RATE:9% AMOUNT (RS.)',
    'Net Amount Receivable/Payable By Client'
];

// Assuming `contactsetment` is already defined and populated
const contactsetment = this.setlamtdata;

// Initialize a totals object for each key and an overall total
const totals = {};
const grandTotals = {
    payinout: 0,
    brokerage: 0,
    tot: 0,
    cgst: 0,
    sgst: 0,
    net_amt: 0
};

// Filter out entries where LATEST_CONTRACT_NO is 0
const filteredContactsetment = {};
for (const key in contactsetment) {
    const entries = contactsetment[key].filter(entry => entry.LATEST_CONTRACT_NO !== 0);

    if (entries.length > 0) {
        filteredContactsetment[key] = entries;

        // Initialize totals for this key
        totals[key] = {
            payinout: 0,
            brokerage: 0,
            tot: 0,
            cgst: 0,
            sgst: 0,
            net_amt: 0
        };

        // Calculate totals
        entries.forEach(entry => {
            totals[key].payinout += entry.payinout;
            totals[key].brokerage += entry.brokerage;
            totals[key].tot += entry.tot;
            totals[key].cgst += entry.cgst;
            totals[key].sgst += entry.sgst;
            totals[key].net_amt += entry.net_amt;

            // Update grand totals
            grandTotals.payinout += entry.payinout;
            grandTotals.brokerage += entry.brokerage;
            grandTotals.tot += entry.tot;
            grandTotals.cgst += entry.cgst;
            grandTotals.sgst += entry.sgst;
            grandTotals.net_amt += entry.net_amt;
        });
    }
}

// Add header row only for filtered keys
worksheet.addRow(['Description', ...Object.keys(filteredContactsetment), 'Total']).font = { bold: true };

// Add data rows
descriptions.forEach((description, index) => {
    const row = [
        description,
        ...Object.keys(filteredContactsetment).map(key => {
            const value = Object.values(totals[key])[index];
            return value < 0 ? value + ' DR' : value + ' CR';
        }),
        (() => {
            const totalValue = Object.values(grandTotals)[index];
            return totalValue < 0 ? totalValue + ' DR' : totalValue + ' CR';
        })()
    ];
    worksheet.addRow(row);
});





    const footerRows = [
        [],
        ["Zebu Share And Wealth Management Private Limited"],
        ["Correspondence Office: No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096."],
        ["Phone No: 044-4855 7991, Fax: 044-4855 7991"],
        ["TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300"],
        ["CIN NO: U67120TZ2013PTC019704 Website Address: www.zebuetrade.com, Investor Grievances Email id: grievance@zebuetrade.com"]
    ];

    footerRows.forEach(row => worksheet.addRow(row));


    worksheet.eachRow((row) => {
        row.eachCell((cell) => {
            if (['Zebu Share And Wealth Management Private Limited', 'CONTRACT NOTE'].includes(cell.value)) {
                cell.font = { name: 'Arial', size: 14, bold: true };
            } else if (['Script Symbol', 'Order No', 'Order Time', 'Trade No', 'Trade Time', 'Security/Contract', 'B/S', 'Gross Rate', 'Quantity','Brokrage','Net Rate', 'NetTotal'].includes(cell.value)) {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
                cell.font = { bold: true, color: { argb: 'ffffff' } };
            }
            cell.alignment = { horizontal: 'left' };
    //         if (['Quantity', 'Gross Rate', 'Brokrage', 'Net Rate','NetTotal'].includes(headerRows[headerRows.length - 1][cell])) {
    //           cell.alignment = { horizontal: 'right' };
    //           cell.numFmt = '#,##0.00';  // Set number format for better readability
    // } else {
    //   // Align all other cells to the left
    //   cell.alignment = { horizontal: 'left' };
    // }
   
        });
    });

    worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            const cellValue = cell.value ? cell.value.toString() : '';
            maxLength = Math.max(maxLength, cellValue.length);
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

    worksheet.eachRow({ includeEmpty: false }, (row) => {
        row.eachCell({ includeEmpty: false }, (cell) => {
            if (cell.value !== ""
            ) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });
    });

    if (this.imageBase64) {
        const imageId = workbook.addImage({
            base64: this.imageBase64,
            extension: 'svg'
        });

        worksheet.getRow(1).height = 32.53;
        worksheet.getColumn(1).width = 20;

        worksheet.addImage(imageId, {
            tl: { col: 0, row: 0 },
            ext: { width: 102.53, height: 40.53 }
        });
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `CONTRACTNOTE${this.fromshow}.xlsx`;
        link.click();
    }).catch(error => console.error('Error generating Excel file:', error));
}
   
    },
   
   
   
   
    download() {
      const data = this.TradeBookData;
      const fileName = `${'Trade_'}${this.fromshow} `
      const exportType = exportFromJSON.types.csv;

      if (data) exportFromJSON({ data, fileName, exportType });
    },
    lessbtn() {
      this.more = true
      this.less = false
      this.itemperpage = 9
    },
    morebtn() {
      this.more = false
      this.less = true
      this.itemperpage = this.TradeBookData.length
    },

    dateinsert() {
      //console.log('kjj')
      var fromdate = ''
      // var todate = ''
      let dateString = this.fromdate
      let dateObject = new Date(dateString);
      let year = dateObject.getFullYear();
      let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
      let day = dateObject.getDate();
      let formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
      fromdate = formattedDate
      this.yesterdayValue = 
      this.fromshow = fromdate
    },
    dateinsertContract() {
      //console.log('kjj')
      
      // var todate = ''
      let dateString = this.FromValForContract
      let dateObject = new Date(dateString);
      let year = dateObject.getFullYear();
      let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
      let day = dateObject.getDate();
      let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year; 
      // let formattedDate2 = year  + '-' + (month < 10 ? '0' : '') + month + '-'  + (day < 10 ? '0' : '') + day; 
      // this.FromValForContract = formattedDate2
      this.yesterdayValue = formattedDate
      this.fromshowContract = formattedDate
      this.menuForContract = false
      this.menuForContractMobile = false
      this.mountedfunctionContract()
    },
    gettradedata() {
      this.TradeBookData = []
      this.menu2 = false
      this.menu23 = false

      if (this.fromdate != '' && this.todate != '') {


        this.loader = true
        var fromdate = ''
        var todate = ''
        let dateString = this.fromdate
        let dateObject = new Date(dateString);
        let year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
        let day = dateObject.getDate();
        let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
        fromdate = formattedDate
        this.fromshow = fromdate
        let dateString1 = this.todate
        let dateObject1 = new Date(dateString1);
        let year1 = dateObject1.getFullYear();
        let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
        let day1 = dateObject1.getDate();
        let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
        todate = formattedDate1
        this.toshow = todate
        this.fromshow = this.fromshow + '_to_' + this.toshow

        const axios = require('axios');
        let data = JSON.stringify({
          "cc": this.actid,
          "from": fromdate,
          "to": todate,
        });
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${apiurl}/getTradeDetails`,

          headers: {
            'Content-Type': 'application/json',
            'clientid': this.actid,
            'Authorization': this.susertoken
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            //console.log(JSON.stringify(response.data));
            axiosthis.loader = false
            axiosthis.radios2 = 'all'
            axiosthis.radios3 = 'allbuysell'
            axiosthis.TradeBookData = response.data['trades']
            axiosthis.itemperpage = axiosthis.TradeBookData.length

            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let dateString = axiosthis.TradeBookData[i]['EXPIRY_DATE'];
            //   let date = new Date(dateString);
            //   let year = date.getFullYear();
            //   let month = date.getMonth() + 1; // Months are 0-based, so add 1
            //   let day = date.getDate();
            //   let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
            //   axiosthis.TradeBookData[i]['EXPIRY_DATE'] = numericalDateFormat
            // }
            //console.log(axiosthis.TradeBookData, "axiosthis.desserts2");
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let dateString = axiosthis.TradeBookData[i]['TRADE_DATE'];
            //   let date = new Date(dateString);
            //   let year = date.getFullYear();
            //   let month = date.getMonth() + 1; // Months are 0-based, so add 1
            //   let day = date.getDate();
            //   let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
            //   axiosthis.TradeBookData[i]['TRADE_DATE'] = numericalDateFormat
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   if (axiosthis.TradeBookData[i]['BUY_QUANTITY'] != 0) {
            //     axiosthis.TradeBookData[i]['showqnt'] = axiosthis.TradeBookData[i]['BUY_QUANTITY']
            //     axiosthis.TradeBookData[i]['showprice'] = axiosthis.TradeBookData[i]['NET_BUY_PRICE']
            //     axiosthis.TradeBookData[i]['showtype'] = "BUY"
            //     let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //   axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            //   } else if (axiosthis.TradeBookData[i]['SELL_QUANTITY'] != 0) {
            //     axiosthis.TradeBookData[i]['showqnt'] = axiosthis.TradeBookData[i]['SELL_QUANTITY']
            //     axiosthis.TradeBookData[i]['showprice'] = axiosthis.TradeBookData[i]['NET_SELL_PRICE']
            //     axiosthis.TradeBookData[i]['showtype'] = "SELL"
            //     let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //   axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            //   }
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //   axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            // }
            // //console.log(axiosthis.TradeBookData[10]['SERIES'],"axiosthis.TradeBookData[i]['SERIES']");
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   if (axiosthis.TradeBookData[i]['SERIES'] == "EQ") {
            //     // //console.log(axiosthis.TradeBookData[i]['SERIES'],"axiosthis.TradeBookData[i]['SERIES']");
            //     axiosthis.TradeBookData[i]['showseries'] = 'EQ'
            //   } else {
            //     axiosthis.TradeBookData[i]['showseries'] = 'FNO'
            //   }
            // }
            axiosthis.dessertspop = axiosthis.TradeBookData
            // console.log("dedededede", axiosthis.dessertspop);
            axiosthis.scrollToBottom()

          })
          .catch((error) => {
            console.log(error);
            if (error.message == 'Network Error') {
              this.msg = error.message
              axiosthis.loader = false
              axiosthis.alert = true
              setTimeout(() => {
                axiosthis.alert = false
              }, 5000);
              // alert(error.message)
            }
          });
      } else {
        // //console.log("please enter the date")
      }

    },
    dateforsort(){
        let today = new Date();
        let sevthdate = new Date(today);
        let todaydate = new Date(today);
        sevthdate.setDate(today.getDate() - 7);
        todaydate.setDate(today.getDate());
        let dd1 = todaydate.getDate();
        let mm1 = todaydate.getMonth() + 1; // January is 0!
        let yyyy1 = todaydate.getFullYear();
        let dd = sevthdate.getDate();
        let mm = sevthdate.getMonth() + 1; // January is 0!
        let yyyy = sevthdate.getFullYear();
        let seventhdate = dd + "/" + mm + "/" + yyyy;
        let todatevalue = dd1 + "/" + mm1 + "/" + yyyy1;
        // console.log(seventhdate,todatevalue);
        this.filterfrom = todatevalue
        this.filterto = seventhdate
    },
    mountedfunyearselect(val){
        this.menu23 = false
        var valuedate = val
        this.menu2 = false
        this.TradeBookData = []
        var today = new Date();
        // this.fromdate = yyyysevthdate + '-' + mmsevthdate + '-' + ddsevthdate;
        // var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var mm = 5
        var yyyy = today.getFullYear();
        // var yyyy = 2022
            // if (mm < 4 ) {
            //     var yearmount = yyyy
            // } else {
            //     yearmount = yyyy - 1
            // }
            // var year = yearis
            // var yearnext = yearmount + 1

        if (valuedate == 0 ) {
          if (mm < 4) {
            var yearmount = yyyy - 1
            var yearnext = yyyy
          }else {
            yearmount = yyyy 
            yearnext = yyyy + 1
          }
        }

        if (valuedate == -1) {
            if (mm < 4) {
              yearmount = yyyy - 2
              yearnext = yyyy - 1
              
            }else{
              yearmount = yyyy - 1
              yearnext = yyyy 
            }
        }
        if (valuedate == -2) {
            if (mm < 4) {
              yearmount = yyyy - 3
              yearnext = yyyy - 2
              
            }else{
              yearmount = yyyy - 2
              yearnext = yyyy - 1
            }
        }
        if (valuedate == -3) {
            if (mm < 4) {
              yearmount = yyyy - 4
              yearnext = yyyy - 3
              
            }else{
              yearmount = yyyy - 3
              yearnext = yyyy - 2
            }
        }
        if (valuedate == -4) {
            if (mm < 4) {
              yearmount = yyyy - 5
              yearnext = yyyy - 4
              
            }else{
              yearmount = yyyy - 4
              yearnext = yyyy - 3
            }
        }
        // var todayapi = dd + '/' + mm + '/' + yyyy;
        var today1 = '31/03/' + yearnext
        // var fromapi = '01' + '/' + mm + '/' + yyyy;
        // var fromapi = seventhdate;
        // this.maxval = yearmount+'-'+mm+'-'+dd
        // let ydd = String(today.getDate() - 1);
        // let ymm = String(today.getDate()).padStart(2, '0');
        // let yeyy = String(today.getMonth() + 1).padStart(2, '0'); 
        // this.firstmax = yearmount + '-' + ymm + '-' + ydd
        this.fromshow = '01/04/' + yearmount
        // this.fromshow = seventhdate
        this.fromdate = yearmount + '-' + mm + '-' + '01';
        // var  =yyyy  + '/' + dd + '/' + mm
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        this.loader = true
        this.todate = yearnext + '-03-31'
        this.toshow = today1
        this.fromshow = this.fromshow + '_to_' + this.toshow
        const axios = require('axios');
        let data = JSON.stringify({
          "cc": this.actid,
          "from":'01/04' + '/' + yearmount,
          "to": '31/03' + '/' + yearnext,
        });
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${apiurl}/getTradeDetails`,

          headers: {
            'Content-Type': 'application/json',
            'clientid': this.actid,
            'Authorization': this.susertoken
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosthis.loader = false
            axiosthis.radios2 = 'all'
            axiosthis.radios3 = 'allbuysell'

            axiosthis.TradeBookData = response.data['trades']
            axiosthis.itemperpage = axiosthis.TradeBookData.length

            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let dateString = axiosthis.TradeBookData[i]['EXPIRY_DATE'];
            //   let date = new Date(dateString);
            //   let year = date.getFullYear();
            //   let month = date.getMonth() + 1; // Months are 0-based, so add 1
            //   let day = date.getDate();
            //   let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
            //   axiosthis.TradeBookData[i]['EXPIRY_DATE'] = numericalDateFormat
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let dateString = axiosthis.TradeBookData[i]['TRADE_DATE'];
            //   let date = new Date(dateString);
            //   let year = date.getFullYear();
            //   let month = date.getMonth() + 1; // Months are 0-based, so add 1
            //   let day = date.getDate();
            //   let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
            //   axiosthis.TradeBookData[i]['TRADE_DATE'] = numericalDateFormat
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   if (axiosthis.TradeBookData[i]['BUY_QUANTITY'] != 0) {
            //     axiosthis.TradeBookData[i]['showqnt'] = axiosthis.TradeBookData[i]['BUY_QUANTITY']
            //     axiosthis.TradeBookData[i]['showprice'] = axiosthis.TradeBookData[i]['NET_BUY_PRICE']
            //     axiosthis.TradeBookData[i]['showtype'] = "BUY"
            //     let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //     axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            //   } else if (axiosthis.TradeBookData[i]['SELL_QUANTITY'] != 0) {
            //     axiosthis.TradeBookData[i]['showqnt'] = axiosthis.TradeBookData[i]['SELL_QUANTITY']
            //     axiosthis.TradeBookData[i]['showprice'] = axiosthis.TradeBookData[i]['NET_SELL_PRICE']
            //     axiosthis.TradeBookData[i]['showtype'] = "SELL"
            //     let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //     axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            //   }
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //   axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   if (axiosthis.TradeBookData[i]['SERIES'] == "EQ") {
            //     axiosthis.TradeBookData[i]['showseries'] = 'EQ'
            //   } else {
            //     axiosthis.TradeBookData[i]['showseries'] = 'FNO'
            //   }
            // }
            axiosthis.dessertspop = axiosthis.TradeBookData
            axiosthis.scrollToBottom()
          })
          .catch((error) => {
            console.log(error);
            if (error.message == 'Network Error') {
              this.msg = error.message
              axiosthis.loader = false
              axiosthis.alert = true
              setTimeout(() => {
                axiosthis.alert = false
              }, 5000);
              // alert(error.message)
            }
          });
    },
    highlightedDates() {
      // Check if both fromdate and todate are selected
      if (this.fromdate && this.todate) {
        // Convert selected dates to milliseconds
        const fromDateMs = new Date(this.fromdate).getTime();
        const toDateMs = new Date(this.todate).getTime();

        // Return a function to highlight dates between fromdate and todate
        return date => {
          const dateMs = new Date(date).getTime();
          return dateMs >= fromDateMs && dateMs <= toDateMs;
        };
      } else {
        return null; // If either fromdate or todate is not selected, return null
      }
    },
    mountedfun(val){
      var valuedate = val
      this.menu2 = false
      this.menu23 = false
      this.TradeBookData = []
      var today = new Date();
      let sevthdate = new Date(today);
      sevthdate.setDate(today.getDate() - valuedate);
      let ddsevthdate =  String(sevthdate.getDate()).padStart(2, '0');
      let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
      
      let yyyysevthdate = sevthdate.getFullYear();

      
      

      let seventhdate = ddsevthdate + "/" + mmsevthdate + "/" + yyyysevthdate;
      this.fromdate = yyyysevthdate + '-' + mmsevthdate + '-' + ddsevthdate;
     

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayapi = dd + '/' + mm + '/' + yyyy;
        var today1 = dd + '/' + mm + '/' + yyyy;
        // var fromapi = '01' + '/' + mm + '/' + yyyy;
         this.todate = yyyy + '-' + mm + '-' + dd;
        var fromapi = seventhdate;
        this.maxval = yyyy+'-'+mm+'-'+dd
        let ydd = String(today.getDate() - 1);
        let ymm = String(today.getDate()).padStart(2, '0');
        let yeyy = String(today.getMonth() + 1).padStart(2, '0'); 
        this.firstmax = yeyy + '-' + ymm + '-' + ydd
        // this.fromshow = fromapi
        this.fromshow = seventhdate
        // this.fromdate = yyyy + '-' + mm + '-' + '01';
        // var  =yyyy  + '/' + dd + '/' + mm
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        this.cname = localStorage.getItem("cname");
        var res = this.decryptionFunction(localStorage.getItem("profile_data"));
        // console.log('ttttt',JSON.parse(res));
        var resp = JSON.parse(res);
        this.profiledetails = resp.client_data;
        this.loader = true

        this.toshow = today1
        this.fromshow = this.fromshow + '_to_' + this.toshow
        const axios = require('axios');
        let data = JSON.stringify({
          "cc": this.actid,
          "from": fromapi,
          "to": todayapi,
        });
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${apiurl}/getTradeDetails`,

          headers: {
            'Content-Type': 'application/json',
            'clientid': this.actid,
            'Authorization': this.susertoken
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            // console.log(JSON.parse(response.data));
            axiosthis.loader = false

            axiosthis.TradeBookData = response.data['trades']
            axiosthis.itemperpage = axiosthis.TradeBookData.length

            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let dateString = axiosthis.TradeBookData[i]['EXPIRY_DATE'];
            //   let date = new Date(dateString);
            //   let year = date.getFullYear();
            //   let month = date.getMonth() + 1; // Months are 0-based, so add 1
            //   let day = date.getDate();
            //   let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
            //   axiosthis.TradeBookData[i]['EXPIRY_DATE'] = numericalDateFormat
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let dateString = axiosthis.TradeBookData[i]['TRADE_DATE'];
            //   let date = new Date(dateString);
            //   let year = date.getFullYear();
            //   let month = date.getMonth() + 1; // Months are 0-based, so add 1
            //   let day = date.getDate();
            //   let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
            //   axiosthis.TradeBookData[i]['TRADE_DATE'] = numericalDateFormat
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   if (axiosthis.TradeBookData[i]['BUY_QUANTITY'] != 0) {
            //     axiosthis.TradeBookData[i]['showqnt'] = axiosthis.TradeBookData[i]['BUY_QUANTITY']
            //     axiosthis.TradeBookData[i]['showprice'] = axiosthis.TradeBookData[i]['NET_BUY_PRICE']
            //     axiosthis.TradeBookData[i]['showtype'] = "BUY"
            //     let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //     axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            //   } else if (axiosthis.TradeBookData[i]['SELL_QUANTITY'] != 0) {
            //     axiosthis.TradeBookData[i]['showqnt'] = axiosthis.TradeBookData[i]['SELL_QUANTITY']
            //     axiosthis.TradeBookData[i]['showprice'] = axiosthis.TradeBookData[i]['NET_SELL_PRICE']
            //     axiosthis.TradeBookData[i]['showtype'] = "SELL"
            //     let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //     axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            //   }
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   let showamt = axiosthis.TradeBookData[i]['showqnt'] * axiosthis.TradeBookData[i]['showprice']
            //   axiosthis.TradeBookData[i]['showamt'] = showamt.toFixed(2)
            // }
            // for (let i = 0; i < axiosthis.TradeBookData.length; i++) {
            //   if (axiosthis.TradeBookData[i]['SERIES'] == "EQ") {
            //     axiosthis.TradeBookData[i]['showseries'] = 'EQ'
            //   } else {
            //     axiosthis.TradeBookData[i]['showseries'] = 'FNO'
            //   }
            // }
            axiosthis.dessertspop = axiosthis.TradeBookData
            axiosthis.scrollToBottom()
          })
          .catch((error) => {
            console.log(error);
            if (error.message == 'Network Error') {
              this.msg = error.message
              axiosthis.loader = false
              axiosthis.alert = true
              setTimeout(() => {
                axiosthis.alert = false
              }, 5000);
              // alert(error.message)
            }
          });
    },
    mountedfunctionContract(){
      this.loaderForContract = true
      this.ContractData = []
      this.NetData = []
      this.setlamtdata = []
      this.SettlementData = []
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.actid,
        "from": this.yesterdayValue,
        "to": this.yesterdayValue
      });
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.14:9003/getContractNote',
        url: `${apiurl}/getContractNote`,

        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': this.susertoken,
          'clientid': this.actid
        },
        data : data
      };
      let axiosthis = this
      axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        
        // let dummyDateForPush = []
        let SettleDummy = [];
        if (response.data.msg !== 'No Data Available') {
          axiosthis.HeaderForSettlement =  [
        { text: 'Description', value: 'Description' },
      ],
   SettleDummy = response.data.settlement;

  // Initial SettlementData array setup
  axiosthis.SettlementData = [
    { Description: 'Pay In/Pay Out Obligation' },
    { Description: 'Taxable Value Of Supply (Brokerage)' },
    { Description: 'Taxable Value Of Supply (Tot)' },
    { Description: 'CGST* RATE:9% AMOUNT (RS.)' },
    { Description: 'SGST* RATE:9% AMOUNT (RS.)' },
    { Description: 'Net Amount Receivable/Payable By Client' }
  ];

  // Iterate over SettleDummy keys
  for (let key in SettleDummy) {
    // console.log(key, SettleDummy[key][0].LATEST_CONTRACT_NO);
    if (SettleDummy[key][0].LATEST_CONTRACT_NO != 0) {
      
    // Push header information
    this.HeaderForSettlement.push({ text: key, value: key });

    // Iterate over each item in SettleDummy[key] array
    for (let i = 0; i < SettleDummy[key].length; i++) {
      axiosthis.SettlementData[0][key] = SettleDummy[key][i].payinout;
      axiosthis.SettlementData[1][key] = SettleDummy[key][i].brokerage;
      axiosthis.SettlementData[2][key] = SettleDummy[key][i].tot;
      axiosthis.SettlementData[3][key] = SettleDummy[key][i].cgst;
      axiosthis.SettlementData[4][key] = SettleDummy[key][i].sgst;
      axiosthis.SettlementData[5][key] = SettleDummy[key][i].net_amt;
    }
    }
  }

  // console.log(axiosthis.SettlementData, "Updated Settlement Data");

  // Handle ContractFullData and Data processing
  axiosthis.ContractFullData = response.data.Data;
  let dummyDateForPush = [];

  if (response.data.Data['Common']) {
    dummyDateForPush = dummyDateForPush.concat(response.data.Data['Common']);
  }
  if (response.data.Data['Commodity']) {
    dummyDateForPush = dummyDateForPush.concat(response.data.Data['Commodity']);
  }

  // Process DummyDate for STRIKE_PRICE adjustment
  let DummyDate = dummyDateForPush.map(item => {
    let splitval = item.STRIKE_PRICE.split(".");
    let splsecval = Number(splitval[1]);
    item.STRIKE_PRICE = splsecval !== 0 ? splitval.join(".") : splitval[0];
    return item;
  });

  // Assign processed data to relevant variables
  axiosthis.ContractData = DummyDate;
  axiosthis.ContractDataForFilterr = DummyDate;
  axiosthis.loaderForContract = false;
  axiosthis.NetData = response.data.Net;
  axiosthis.setlamtdata = response.data.settlement


  // Group data by 'SCRIP_SYMBOL'
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };
  axiosthis.ContractDataMobile = groupBy(axiosthis.ContractData, 'SCRIP_SYMBOL');
}
else{
          axiosthis.loaderForContract = false
          axiosthis.ContractData = []
          axiosthis.NetData = []
          axiosthis.setlamtdata = []
        }
      })
      .catch((error) => {
        console.log(error);
      });

    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    filteredDesserts() {
      const searchTerm = this.search.toLowerCase();
      return this.TradeBookData.filter(item => item.SCRIP_NAME.toLowerCase().includes(searchTerm));
    },
    
  },
  mounted() { 
      var today = new Date();
      let sevthdate = new Date(today);
      let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
      let yyyysevthdate = sevthdate.getFullYear();
      if (mmsevthdate < 4) {
           var yearmount = yyyysevthdate - 1
      }else{
            yearmount = yyyysevthdate
      }
      var startYear = yearmount - 4;
      this.yearis = yearmount;
      for (let year = yearmount; year >= startYear; year--) {
      // let yeararraytem = []

      this.yearsarray.push(year)
    }
    this.yearsarray = this.yearsarray.slice(2, 5)


    let yesday = new Date(today);
      yesday.setDate(today.getDate() - 1);
      let yesdate =  String(yesday.getDate()).padStart(2, '0');
      let yesmonth = String(yesday.getMonth() + 1).padStart(2, '0') 
      let yesyear = yesday.getFullYear();
      
      let Yesdatevalue = yesdate + "/" + yesmonth + "/" + yesyear;
      this.yesterdayValue = Yesdatevalue
      this.fromshowContract = Yesdatevalue
      this.FromValForContract = yesyear + "-" + yesmonth + "-" + yesdate 
      // console.log(Yesdatevalue);
    this.mountedfun(7)
    this.mountedfunctionContract()
    this.convertImageToBase64()
    // console.log(this.yearsarray)
  },
}
</script>

<style>
.v-picker__title.White {
  display: none;
}

.v-picker__title.black {
  display: none;
}

.theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
    background: #ffffff;
}
</style>