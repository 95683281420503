<template>
  <div id="app" class="ma-10 ml-10">
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="mx-3 px-1">
      <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Family account</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">You can add your family account.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>
      <!-- <p class="font-weight-bold fs-22 mb-0">Family account</p>
      <p class="subtitle-2 txt-666">You can add your family account.</p> -->
<v-divider></v-divider>

      <v-card class="pa-md-6 mb-6 mt-6" elevation="0" :outlined="$vuetify.breakpoint.smAndDown ? false : true" width="100%">
       

        <p class="subtitle-1 txt-666 font-weight-medium">
          Would you like to add your family account?
        </p>
        <v-row class="mt-2" no-gutters>
          <v-col>
            <!-- v-if="((mobStatus.closure_status != 'e-signed pending') && (mobStatus.closure_status != 'e-signed completed'))" -->
            <!-- <v-btn
                          
                          height="40px" elevation="0" color="black white--text" rounded :loading="emailLoader"
                          @click="deactivateConfirmation = true">
                          <span class="text-none px-8">Submit</span>
                        </v-btn> -->

            <v-form ref="addFamilyform" v-model="validFamily" lazy-validation>
              <div>
                <div>
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="12">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <v-row no-gutters>
                              <v-col cols="12" md="6" class="pb-0 pr-md-8">
                                <p class="ma-0 pa-0" style="font-weight: 499">
                                  Member client ID<span style="color: red">*</span>
                                </p>

                                <v-text-field class="pa-0" @keyup="
                                  memberId = memberId.toUpperCase(), samemembercheck()
                                  " :rules="nomineeField" v-model="memberId" placeholder="">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pb-0">
                                <p class="ma-0 pa-0" style="font-weight: 499">
                                  Relationship
                                  <span style="color: red">*</span>
                                </p>

                                <v-select class="pa-0" :rules="nomineeField" v-model="newFamilyRelation"
                                  :items="relationNominee">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                    </div>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>

                            <v-row no-gutters>
                              <v-col cols="12" md="6" class="pr-md-8">
                                <p class="ma-0 pa-0" style="font-weight: 499">
                                  Member PAN<span style="color: red">*</span>
                                </p>

                                <v-text-field class="pa-0" type="text" maxlength="10" @keyup="
                                  memberPan = memberPan.toUpperCase()
                                  " :rules="nomineeField" v-model="memberPan" placeholder="">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/pan-card.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <p class="ma-0 pa-0" style="font-weight: 499">
                                  Member Mobile
                                  <span style="color: red">*</span>
                                </p>

                                <v-text-field class="pa-0" @keyup="
                                  memberPan = memberPan.toUpperCase()
                                  " :rules="mobileNumberRules" hide-spin-buttons type="number" v-model="memberMobile"
                                  placeholder="">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16"
                                        src="@/assets/mobile-phone.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <v-row class="mt-2" no-gutters>
                <v-col>
                  <div class="d-md-inline-flex" v-if="accountstatus != 'client_close'">                    
                    <!-- <p class="mt-10 mr-4" style="display:inline-block"><a>{{ submissionText }}</a></p> -->
                    <v-btn :loading="familyloader" @click="addFamilySubmit()" :disabled="!validFamily" color="#000"
                      :block="$vuetify.breakpoint.xsOnly ? true : false"
                      class="white--text text-none rounded-pill elevation-0 mt-md-6"><span
                        class="white--text subtitle-1 font-weight-medium px-6">+ Add Member</span></v-btn>
                  </div>
                  <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          + Add Member
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>

      <p class="subtitle-2 txt-666 mt-4">
        *As per the regulation, you have to provide all mandate fields.
      </p>

      <div>
        <p class="title font-weight-bold black--text mb-1">Status</p>
        <div class="d-none d-lg-block d-xl-block">
        <v-data-table min-width="600" :hide-default-footer="true" :loading="familystatusloading" height="60vh" outlined
          fixed-header :headers="orderbookHeaders" :items="familyRequestData" :items-per-page="familyRequestData.length"
          class="elevation-1 rounded-lg" must-sort multi-sort :sort-desc="[true]">
          <template v-slot:no-data>
            <v-container>
              <v-card class="elevation-0  d-flex justify-space-around align-center pa-15 my-12 ma-md-5 px-md-0">
                <div class="text-center">
                  <img width="64px" src="@/assets/nodatatable.svg" alt="no orders available">
                  <p class="title font-weight-medium mb-0">no requests available</p>
                </div>
              </v-card>
              <!-- <v-card height="40vh" class="elevation-0 d-flex d-md-none justify-space-around align-center d-md-none d-block px-15 px-md-0" style="z-index:0">
              <div class="text-center">
                <img width="64px" src="@/assets/nodatatable.svg" alt="no orders available">
                <p class="title font-weight-medium mb-0">no requests available</p>
              </div>
            </v-card> -->
            </v-container>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
            <div v-if="item.status != 'linked'">
            <a v-if="item.url" :href="item.url" target="_blank">

              <p small class="mb-0 green--text mr-3" style="cursor: pointer;display:inline-block">
                Accept
              </p>
            </a></div>
            <span>

              <p small class="mb-0 red--text" style="cursor: pointer; display:inline-block"
                @click="selectTableitem = item, familyrequest = true">
                Delete
              </p>
            </span>
          </div>
          </template>
          <template v-slot:[`item.memberid`]="{ item }">
            <p v-if="(item.memberid == client_code)">
              {{ item.clientid }} <v-icon small color="green darken-2">
                mdi-arrow-bottom-left
              </v-icon>
            </p>
            <p v-else>
              {{ item.memberid }} <v-icon small color="green darken-2">
                mdi-arrow-top-right
              </v-icon>
            </p>
          </template>
        </v-data-table>
      </div>
      <div class="d-block d-lg-none">
     
        <div v-for="(item, index) in familyRequestData" :key="index">
              <div>
                <v-card outlined elevation="0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Member ID : </v-list-item-subtitle>
               
                    <v-list-item-subtitle 
                      >Datetime :
                    </v-list-item-subtitle>
                    <v-list-item-subtitle 
                      >Status :
                    </v-list-item-subtitle>
                    <v-list-item-subtitle 
                      >Actions :
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="font-weight-bold">
                   
                 
                    <v-list-item-title> 
                      <p class="mb-0" v-if="(item.memberid == client_code)">
              {{ item.clientid }} <v-icon small size="15" color="green darken-2">
                mdi-arrow-bottom-left
              </v-icon>
            </p>
            <p class="mb-0" v-else>
              {{ item.memberid }} <v-icon small size="15" color="green darken-2">
                mdi-arrow-top-right
              </v-icon>
            </p>
          </v-list-item-title>
                    <v-list-item-title >{{
                      item.req_link_time
                    }}</v-list-item-title>

<v-list-item-title >{{
                      item.status
                    }}</v-list-item-title>

<v-list-item-title > <a v-if="item.url" :href="item.url">

<p small class="mb-0 green--text mr-3" style="cursor: pointer;display:inline-block">
  Accept
</p>
</a>
<span>

<p small class="mb-0 red--text" style="cursor: pointer; display:inline-block"
  @click="selectTableitem = item, familyrequest = true">
  Delete
</p>
</span></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              </div>
            </div>
      </div>
      
      </div>


    </div>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog v-model="familySubmission" max-width="350">
      <v-card class="rounded-lg px-2 px-md-4">
        <v-card-title>Request has been submitted sucessfully </v-card-title>
        <v-card-text>
          {{ Succtext }}
          <!-- <b>"{{familyRequestData.length > 0 && familyRequestData[0].memberemail ? familyRequestData[0].memberemail : '' }}"</b> -->
          . Once they accept, you can start viewing their portfolio</v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="familyrequest" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-4">
        <v-card-title>Delete request? </v-card-title>
        <v-card-text>
          Are you sure you want to delete
          <b>"{{ selectTableitem.membername }} ({{ selectTableitem.memberid }})" request?</b>
          <br />You can't undo this action.</v-card-text>
        <v-card-actions class="pb-4">
          <v-row no-gutters>
            <v-col>
              <v-btn height="40px" block color="grey darken-1 text-none" text @click="familyrequest = false">
                Cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn height="40px" block color="red darken-1 text-none" text @click="deletereqitem">
                Remove
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deactivateConfirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-4">
        <v-form v-if="deactivateForm" ref="deactivateform">
          <v-card-title> Deactivate account? </v-card-title>
          <v-card-text>
            Are you sure you want to Deactivate your account
            <b>"{{ profileData.CLIENT_ID }}"?</b> <br />You can't undo this
            action.</v-card-text>

          <v-card-text>
            <v-select :items="reasons" :rules="nomineeField" filled v-model="closureReason" label="Reason"></v-select>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-row no-gutters>
              <v-col>
                <v-btn block color="grey darken-1" text @click="deactivateConfirmation = false">
                  Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block color="red darken-1" text @click="deactivEapi">
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<script src="https://app.digio.in/sdk/v11/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v11/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      xsOnly: null,
      smAndDown: null,
      txt: "",
      blurLoader: true,
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "Mother",
        "Father",
        "Brother",
        "Sister",
        "Daughter",
        "Wife",
        "Husband",
        "Son",
        "Grand-Son",
        "Grand-Father",
        "Grand-Daughter",
        "Grand-Mother",
        'Friend',
      ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,
      typesOfimg: [
        "Latest 6 months Bank Statement",
        "Latest ITR Copy",
        "Latest 6 months salary slip",
        "DP holding statement as on date",
        "Net worth Certificate",
        "Copy of Form 16 in case of salary income",
      ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      allSegments: [
        "BSE_CASH",
        "MF_BSE",
        "NSE_CASH",
        "BSE_FNO",
        "NSE_FNO",
        "CD_NSE",
        "CD_BSE",
        "BSE_COM",
        "NSE_COM",
        "ICEX",
        "MCX",
      ],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      reasons: [
        "High brokerage and charges",
        "Monthly maintenance charges",
        "Need better margins",
        "Problem with payment and withdrawal",
        "Need better order placement options",
        "App crashes",
        "Issues with back office portals",
        "Problem with customer service",
        "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        { text: "My Account" },
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        { text: "Manage Funds", link: "secufund" },
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Yes, add nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: '',
      familyRequestData: [],
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: '',
      disabled: false,
      readonly: false,
      emailtext:[],
      accountstatus:""
    };
  },
  // watch: {
  //   menu(val) {
  //     val && setTimeout(() => (this.activePicker = "YEAR"));
  //   },
  //   menunto(val) {
  //     val && setTimeout(() => (this.activePicker = "YEAR"));
  //   },
  //   menuu(val) {
  //     val && setTimeout(() => (this.activePicker = "YEAR"));
  //   },
  //   menuunto(val) {
  //     val && setTimeout(() => (this.activePicker = "YEAR"));
  //   },
  //   menunthre(val) {
  //     val && setTimeout(() => (this.activePicker = "YEAR"));
  //   },
  //   menuunthre(val) {
  //     val && setTimeout(() => (this.activePicker = "YEAR"));
  //   },
  // },
  methods: {
    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = ''
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },
    deletereqitem() {
      this.selectTableitem
      // console.log(this.selectTableitem)
      var axiosThis = this
      this.familystatusloading = true
      let data = JSON.stringify({
        "id": this.selectTableitem.id
      });

      let config = {
        method: "post",
        url: api.api_url + "/remove_family_member",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "id removed successfully") {
            axiosThis.familyrequest = false
            axiosThis.successSnackbar = true;
            axiosThis.Succtext = "Removed sucessfully";
          }
          // console.log(JSON.stringify(response.data));
          axiosThis.familystatusloading = false
          axiosThis.getFamily()
        })
        .catch((error) => {
          console.log(error);
        });

    },
    getFamily() {
      var axiosThis = this
      this.familystatusloading = true
      let data = JSON.stringify({
        clientid: this.client_code,
      });

      let config = {
        method: "post",
        url: api.api_url + "/family_linked",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "client not linked to any id") {
            axiosThis.familyRequestData = []


          } else {
            if (response.data.accessable) {
              axiosThis.familyRequestData = response.data.accessable
              // axiosThis.emailtext = response.data.accessable
              // console.log('axiosThis.emailtext',axiosThis.emailtext)
            }
          }

          // console.log(JSON.stringify(response.data));
          axiosThis.familystatusloading = false
        })
        .catch((error) => {
          console.log(error);
        });

    },

    addFamilySubmit() {
      var axiosThis = this
      this.emailtext = []
      var valid = this.$refs.addFamilyform.validate();
      if (valid == true) {
        this.familyloader = true
        let data = JSON.stringify({
          clientid: this.client_code,
          memberid: this.memberId,
          pan: this.memberPan,
          mobile_no: this.memberMobile,
          clientname: this.name,
          relationship: this.newFamilyRelation,
        });
        this.mobileMember = this.memberMobile
        let config = {
          method: "post",
          url: api.api_url + "/send_link_request",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.familyloader = false
            if (response.data.emsg == "requested successfully") {
             
              axiosThis.familySubmission = true;
              axiosThis.Succtext = "A verification link has been sent in an Email ";
              axiosThis.memberId = ''
              axiosThis.memberPan = ''
              axiosThis.memberMobile = ''
              axiosThis.newFamilyRelation = ''
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.emsg;

            }


            // console.log(JSON.stringify(response.data));
            axiosThis.getFamily()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deactivEapi() {
      // console.log("klk");
      this.editType = "closure";

      var axiosThis = this;
      var valid = this.$refs.deactivateform.validate();
      if (valid == true) {
        let data = JSON.stringify({
          client_id: this.client_code,
          client_name: this.name,
          client_email: this.profileData.CLIENT_ID_MAIL,
          dp_code: this.profileData.CLIENT_DP_CODE,
          segments: JSON.stringify(this.profileData.segments_data),
          reason: this.closureReason,
          address: this.profileData.CL_RESI_ADD1,
        });

        let config = {
          method: "post",

          url: api.api_url + "/closure",
          headers: {
            Authorization: this.tok,
            clientid: this.client_code,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            if (response.data.msg == "you are having some holdings") {
              axiosThis.canceledData = "You have holdings in your account.";
              axiosThis.deactivateForm = false;
              axiosThis.deactivateCanceled = true;
            } else if (
              response.data.closure_fileid &&
              response.data.closure_mailid
            ) {
              axiosThis.deactivateConfirmation = false;
              axiosThis.deactivateCanceled = false;
              axiosThis.deactivateForm = true;
              axiosThis.closureReason = "";
              axiosThis.digioEsign(
                response.data.closure_fileid,
                response.data.closure_mailid,
                response.data.closure_session
              );
            } else if (response.data.msg == "you are having ledger balance") {
              axiosThis.canceledData =
                "You have ledger balance in your account.";
              axiosThis.deactivateForm = false;
              axiosThis.deactivateCanceled = true;
            } else if (response.data.msg == "back office error") {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.msg;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleFiles(files) {
      // Handle the selected files here
      // console.log(files);
    },
    openFileInput() {
      // console.log("click input",this.$refs.fileInput.$refs.input.click())
      // Programmatically trigger the click event of the hidden input
      this.$refs.fileInput.$refs.input.click();
    },

    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },

    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (
        this.usernewemail.toUpperCase() ==
        this.profileData.CLIENT_ID_MAIL.toUpperCase()
      ) {
        this.usernewemail = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    }, 
       connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
      //   stateid +
      //   "";
      // for local
      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },

    selectOptionBtn() {
      // console.log("cjsjsakcnsioun", this.yesorno);
      if (this.yesorno == "yes") {
        this.nomineeForm = true;
      } else if (this.yesorno == "no") {
        this.nomineeForm = false;
      }
    },
    pincodeFetch() {
      this.pinLoader = true;
      var axiosThis = this;
      // console.warn("hh");
      if (this.newNomineePincode.length >= 6) {
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineePincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeState = resp.State;
            axiosThis.newNomineeCountry = resp.Country;
            axiosThis.newNomineeCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.pincodeAddress.length >= 6) {
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.pincodeAddress,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.stateAddress = resp.State;
            axiosThis.countryAddress = resp.Country;
            axiosThis.distAddress = resp.District;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincode.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianState = resp.State;
            axiosThis.newNomineeGuardianCountry = resp.Country;
            axiosThis.newNomineeGuardianCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodento.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatento = resp.State;
            axiosThis.newNomineeGuardianCountrynto = resp.Country;
            axiosThis.newNomineeGuardianCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodenthre.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatenthre = resp.State;
            axiosThis.newNomineeGuardianCountrynthre = resp.Country;
            axiosThis.newNomineeGuardianCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineePincodento.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" + this.newNomineePincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatento = resp.State;
            axiosThis.newNomineeCountrynto = resp.Country;
            axiosThis.newNomineeCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.newNomineePincodenthre.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineePincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatenthre = resp.State;
            axiosThis.newNomineeCountrynthre = resp.Country;
            axiosThis.newNomineeCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
    },

    // digioEsign(file, email, session) {
    //   var axo = this;
    //   this.blurLoader = true;
    //   axo.editdialog = false;
    //   // console.log(file, email,session)
    //   // localStorage.setItem('fileid', this.digioFile)
    //   var options = {
    //     environment: "production",
    //     callback: function (t) {
    //       axo.statusCheckNominee(axo.client_code);
    //       // console.log(t);
    //       // console.log(axo.stata);
    //       axo.blurLoader = false;

    //       if (axo.stata != undefined) {
    //         if (axo.stata.app_status == "e-signed pending") {
    //           axo.radioBtn = true;
    //           axo.nomineesubmitBtn = true;
    //         } else {
    //           axo.radioBtn = false;
    //           axo.nomineesubmitBtn = false;
    //         }
    //       } else {
    //         axo.radioBtn = false;
    //         axo.nomineesubmitBtn = false;
    //       }

    //       if (t.message == "Signing cancelled") {
    //         // if(axo.editType == 'mobile_change') {
    //         axo.filedownloadDatabase("failure", file);
    //         axo.fetchMobileStatus();

    //         // }
    //         // if(axo.editType == 'email_change') {
    //         // axo.filedownloadDatabase("failure", file);
    //         // axo.fetchMobileStatus()

    //         // }
    //         // if(axo.editType == 'address_change') {
    //         // axo.filedownloadDatabase("failure", file);
    //         // axo.fetchMobileStatus()
    //         // }
    //       } else if (t.message == "Signed Successfully") {
    //         // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
    //         // if(axo.editType == 'mobile_change') {
    //         axo.filedownloadDatabase("success", file);
    //         // }
    //         // if(axo.editType == 'email_change') {
    //         // axo.filedownloadDatabase("success", file);
    //         // }
    //         // if(axo.editType == 'address_change') {
    //         // axo.filedownloadDatabase("success", file);
    //         // }
    //       }

    //       if (t["hasOwnProperty"]("error_code")) {
    //         // document.getElementById("loading").style.display='none';
    //         //// console.log('Error Digio')
    //         //// console.log(t.message)
    //       } else {
    //         //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
    //         //successDigio();
    //         //// console.log(this.step, this.ekycStatus);
    //         setTimeout(function () {
    //           //code goes here
    //           window.location.reload();
    //         }, 4500);
    //       }
    //     },
    //   };

    //   var digio = new Digio(options);
    //   digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
    //   // digio.submit(this.digioFile, this.digioMail,);
    //   // console.log(file,email,session)
    //   digio.submit(file, email, session);
    //   // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

    //   // console.log(digio)
    // },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },


    fetchMobileStatus() {
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    otpButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var valid = this.$refs.formm.validate();
        if (valid == true) {
          // console.log("josi");
          this.mobileLoader = true;
          // this.blurLoader = true

          var data = JSON.stringify({
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            mobile_otp: this.userOtp,
            prev_mobile_no: this.profileData.MOBILE_NO,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
            dp_code: this.profileData.CLIENT_DP_CODE,
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", enc, dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });

          let config = {
            method: "post",
            // url: api.api_url+"/mob_otp_ver",
            url: api.api_url + "/mob_otp_ver",

            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              axiosThis.mobileLoader = false;

              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              var responceReq = response.data;
              // console.log(response.data.message);
              if (responceReq.msg == "otp valid") {
                axiosThis.requestotp = true;
                axiosThis.verifyotp = false;
                axiosThis.successSnackbar = true;
                axiosThis.mobileLoader = false;
                axiosThis.blurLoader = true;

                const date = new Date();
                const offset =
                  date.getTimezoneOffset() == 0
                    ? 0
                    : -1 * date.getTimezoneOffset();

                let normalized = new Date(date.getTime() + offset * 60000);
                let indiaTime = new Date(
                  normalized.toLocaleString("en-US", {
                    timeZone: "Asia/Calcutta",
                  })
                );

                // console.log("Time in India::", indiaTime.toISOString());
                var dateAndTime = indiaTime.toISOString();

                let data = JSON.stringify({
                  pres_mobile_no: axiosThis.usernewmobile,
                  client_id: axiosThis.client_code,
                  prev_mobile_no: axiosThis.profileData.MOBILE_NO,
                  client_name: axiosThis.name,
                  client_email:
                    axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
                  dp_code: axiosThis.profileData.CLIENT_DP_CODE,
                  date_time: dateAndTime,
                });

                let config = {
                  method: "post",

                  url: api.api_url + "/file_write_mob",
                  headers: {
                    Authorization: axiosThis.tok,
                    clientid: axiosThis.profileData.CLIENT_ID,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    // axiosThis.digioEsign(responceReq.fileid, responceReq.mailid)
                    var responceReq = response.data;
                    axiosThis.digioEsign(
                      responceReq.fileid,
                      responceReq.mailid,
                      responceReq.session
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                axiosThis.Succtext = "OTP Verified";
                axiosThis.editdialog = false;
                axiosThis.usernewmobile = "";
                axiosThis.userOtp = "";
              } else {
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "otp not valid";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        //// console.log("email otp verify", this.$refs.emailform);
        var emailvalid = this.$refs.formemailveri.validate();
        if (emailvalid == true) {
          // this.blurLoader = true

          // console.log("josi");
          let data = JSON.stringify({
            client_id: this.client_code,
            present_email: this.userEmailadress.toUpperCase(),
            emailotp: this.useremailOtp,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpverify",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // console.log(response.data.msg);
              if (response.data.msg == "otp valid") {
                axiosThis.blurLoader = true;

                axiosThis.successSnackbar = true;

                axiosThis.verifyotp = false;
                axiosThis.requestotp = true;
                axiosThis.userOtp = "";
                axiosThis.usernewmobile = "";
                (axiosThis.usernewemail = ""),
                  (axiosThis.requestotpemail = false),
                  (axiosThis.useremailOtp = ""),
                  (axiosThis.verifyotpemail = false);
                axiosThis.Succtext = "OTP Verfified Sucessfully";
                // axiosThis.connectDigio()

                const date = new Date();
                const offset =
                  date.getTimezoneOffset() == 0
                    ? 0
                    : -1 * date.getTimezoneOffset();

                let normalized = new Date(date.getTime() + offset * 60000);
                let indiaTime = new Date(
                  normalized.toLocaleString("en-US", {
                    timeZone: "Asia/Calcutta",
                  })
                );

                // console.log("Time in India::", indiaTime.toISOString());
                var dateAndTime = indiaTime.toISOString();

                let data = JSON.stringify({
                  client_id: axiosThis.client_code,
                  client_email:
                    axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
                  dp_code: axiosThis.profileData.CLIENT_DP_CODE,
                  date_time: dateAndTime,
                  present_email: axiosThis.userEmailadress.toUpperCase(),
                  previous_email:
                    axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
                  client_name: axiosThis.name,
                });
                axiosThis.blurLoader = true;

                let config = {
                  method: "post",

                  url: api.api_url + "/file_write_email",
                  headers: {
                    Authorization: axiosThis.tok,
                    clientid: axiosThis.profileData.CLIENT_ID,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    var responceReq = response.data;
                    axiosThis.digioEsign(
                      responceReq.fileid,
                      responceReq.mailid,
                      responceReq.session
                    );
                  })
                  .catch((error) => {
                    axiosThis.blurLoader = false;

                    console.log(error);
                  });

                axiosThis.editdialog = false;
              } else {
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Invalid OTP";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      // console.log("submit clik");
    },
    sendButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var emailvalid = this.$refs.form.validate();
        if (emailvalid == true) {
          this.mobileLoader = true;

          this.cardLoader = true;
          // console.log("josi");
          var dall = JSON.stringify({
            prev_mobile_no: this.profileData.MOBILE_NO,
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });
          let data = dall;

          let config = {
            method: "post",
            url: api.api_url + "/mob_otp_req",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              axiosThis.mobileLoader = false;

              var responceReq = response.data;
              axiosThis.cardLoader = false;
              // console.log(JSON.stringify(response.data));
              if (responceReq.msg == "otp sent") {
                axiosThis.requestotp = false;
                axiosThis.verifyotp = true;

                axiosThis.Succtext = "OTP Sent";
                axiosThis.successSnackbar = true;
              } else if (responceReq.msg == "already this mobile number used") {
                axiosThis.usernewmobile = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        var valid = this.$refs.formemailreq.validate();
        if (valid == true) {
          this.emailLoader = true;
          this.cardLoader = true;
          // console.log("email");
          this.userEmailadress = this.usernewemail;
          let data = JSON.stringify({
            client_id: this.client_code,
            previous_email: this.profileData.CLIENT_ID_MAIL,
            present_email: this.usernewemail.toUpperCase(),
            client_name: this.name,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpsend",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // axiosThis.cardLoader = false;
              axiosThis.emailLoader = false;

              // console.log(JSON.stringify(response.data));
              if (response.data.msg == "success send mail") {
                axiosThis.requestotpemail = false;
                axiosThis.verifyotpemail = true;
              } else if (response.data.msg == "Mobile already exists") {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              } else {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = response.data.msg;
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
    },

    digilockerAdressSubmit() {
      var axiosThis = this;

      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;

      // var url_data = this.$route.params;
      // console.log(token,"poiuytrewqqqqqqasdfghjkllkmnbvcxz");

      // var addressvalid = this.$refs.formadress.validate();
      if (token && state) {
        this.editType = "address_change";
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", "");
        data.append("proff", "");
        data.append("cur_address", "");
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append(
          "client_email",
          this.profileData.CLIENT_ID_MAIL.toUpperCase()
        );
        data.append("aadhar_address", "");
        data.append("adr_manual", "aadhar");
        data.append("code", token);
        data.append("state", state);

        // let config = {
        //   method: 'post',
        //   // 
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            axiosThis.$router.replace("/");
            // window.location.assign(window.location.href.split('?')[0])

            // const query = this.$route.query;
            //   delete query.code;
            //   delete query.state;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    checkSame() {
      if (this.profileData.MOBILE_NO == this.usernewmobile) {
        this.usernewmobile = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new Mobile number";
      }
    },

    incomeproof() {
      if (this.selectionas == 4) {
        this.proofField = true;
      } else {
        this.proofField = false;
        this.passwordField = false;
        this.incorrectPass = false;
      }
    },


    mobileEdit(data) {
      this.tok = localStorage.getItem("usession");

      if (data == "mobile") {
        this.currentClick = "mobile";
        this.editType = "mobile_change";
        // this.cardTitle = 'Mobile Number Verification'
        this.subTitle = "Enter your new mobile number";
        this.requestotp = true;
        this.requestotpemail = false;
        this.bankchange = false;
        this.editdialog = true;

        this.annualIncomechange = false;
        this.mobileLoader = false;

        this.nosegmentsPop = false;
        this.segments = false;

        this.adresschange = false;
        // this.addressVerification = false;
      }
      if (data == "email") {
        this.currentClick = "email";
        this.editType = "email_change";
        this.requestotpemail = true;
        this.requestotp = false;
        this.editdialog = true;

        this.nosegmentsPop = false;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.addressVerification = false;
        this.adresschange = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Enter your new email number";
      }
      if (data == "address") {
        this.currentClick = "address";
        this.editType = "address_change";
        this.adressOptions = true;
        this.manual = false;
        this.nosegmentsPop = false;
        this.editdialog = true;

        this.requestotp = false;
        this.requestotpemail = false;
        this.adresschange = true;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      if (data == "addbank") {
        this.currentClick = "addbank";
        this.editType = "bank_change";
        this.adressOptions = false;
        this.manual = false;
        this.editdialog = true;

        this.requestotp = false;
        this.nosegmentsPop = false;
        this.requestotpemail = false;
        this.adresschange = false;
        this.segments = false;
        this.annualIncomechange = false;
        this.bankproofTypes = "Saving Account";
        this.bankchange = true;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      if (data == "income") {
        this.currentClick = "income";
        this.editType = "income_change";
        this.adressOptions = false;
        this.manual = false;
        this.requestotp = false;
        this.requestotpemail = false;
        this.editdialog = true;

        this.adresschange = false;
        this.bankchange = false;
        this.segments = false;
        this.annualIncomechange = true;
        this.nosegmentsPop = false;
        this.annualIncomeverifyotp = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adress ";
        if (this.profileData.ANNUAL_INCOME == "Less Then One Lakhs") {
          this.selectionas = 0;
          // console.log("responce",0);
        }
        if (this.profileData.ANNUAL_INCOME == "One To Five Lakhs") {
          this.selectionas = 1;
          // console.log("responce",1);
        }
        if (this.profileData.ANNUAL_INCOME == "Five To Ten Lakhs") {
          // console.log("responce",2);
          this.selectionas = 2;
        }
        if (this.profileData.ANNUAL_INCOME == "Ten To TwentyFive Lakhs") {
          // console.log("responce",3);
          this.selectionas = 3;
        }
        if (
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To Fifty Lakhs" ||
          this.profileData.ANNUAL_INCOME == "Above Five Crore" ||
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To One Crore"
        ) {
          // console.log("responce",4);
          this.selectionas = 4;
        }
      }
      if (data == "segment") {
        this.inactiveSegments = [];
        this.existingSegments = [];
        this.currentClick = "Segment";
        this.editType = "Segment_change";
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to activate segment";
        for (var a = 0; a < this.equty.length; a++) {
          if (this.equty[a].COMPANY_CODE == "BSE_CASH") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);
              this.bsecash = true;
              this.bsecashDisable = true;
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
            // console.log(this.equty[a],"bse")
          }
          if (this.equty[a].COMPANY_CODE == "MF_BSE") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);

              this.mfbse = true;
              this.mfDisable = true;
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
            // console.log(this.equty[a],"mf")
          }
          if (this.equty[a].COMPANY_CODE == "NSE_CASH") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);

              this.nsecash = true;
              this.nsecashDisable = true;
              // console.log(this.equty[a],"nse")
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
          }

          // }
        }
        for (var b = 0; b < this.derivat.length; b++) {
          if (this.derivat[b].COMPANY_CODE == "BSE_FNO") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.bsefno = true;
              this.bsefnoDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "NSE_FNO") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.nsefno = true;
              this.nsefnoDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "CD_NSE") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.cdnse = true;
              this.cdnseDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "CD_BSE") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.cdbse = true;
              this.cdbseDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          // }
        }

        for (var c = 0; c < this.commoditi.length; c++) {
          if (this.commoditi[c].COMPANY_CODE == "BSE_COM") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.bsecom = true;
              this.bsecomDisable = true;
              // console.log(this.commoditi[c],"bse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "NSE_COM") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.nsecom = true;
              this.nsecomDisable = true;
              // console.log(this.commoditi[c],"mf")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "ICEX") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.icex = true;
              this.icexDisable = true;
              // console.log(this.commoditi[a],"nse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "MCX") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.mcx = true;
              this.mcxDisable = true;
              // console.log(this.commoditi[c],"nse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          // }
        }
        var array1;
        var array2;

        // for (var i = 0;i<this.allSegments.length;i++) {
        let arr = this.allSegments;
        // let remove = arr.filter((id) => id !== this.existingSegments[i].COMPANY_CODE)
        array1 = arr.filter((item) => !this.existingSegments.includes(item));
        array2 = array1.filter((item) => !this.inactiveSegments.includes(item));

        this.newSegments = array2;
        // console.log(array1, this.existingSegments.length);
        if (array2.length == 11) {
          this.nosegmentsPop = true;
        } else {
          this.editdialog = true;
          this.adressOptions = false;
          this.manual = false;
          this.requestotp = false;
          this.requestotpemail = false;
          this.adresschange = false;
          this.bankchange = false;
          this.annualIncomechange = false;
          this.segments = true;
          this.annualIncomeverifyotp = false;
          this.segOpt = false;
          this.addNewSeg = true;
          this.activateSeg = false;
          this.nosegmentsPop = false;
        }
        // [1,2,3,4,6]
        // }
        // console.log(this.inactiveSegments,"......",this.existingSegments,this.allSegments)
      }
      // console.log("edit mobile");
    },
    valu(val) {
      // console.log(val, "val");
      const validat = val;
      if (validat == false) {
        this.createChartsred();
      }
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },

    getAvailBal(dataa) {
      var axiosThis = this;
      var dall = JSON.stringify({
        client_id: dataa,
      });
      var enc = this.encryptionFunction(dall);
      // console.log(dall, enc);
      let data = JSON.stringify({
        string: enc,
      });

      let config = {
        method: "post",
        // url: 'http://192.168.5.121:5555/profile',
        url: api.api_url + "/profile",

        headers: {
          Authorization: this.tok,
          clientid: dataa,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
          if (!response.data.emsg) {
            if (!response.data.msg) {
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              localStorage.setItem('clinet_name', axiosThis.name)

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation =
                  axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }
              axiosThis.fetchMobileStatus();

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
              for (
                var i = 0;
                i < axiosThis.profileData.segments_data.length;
                i++
              ) {
                // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE);
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_NSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_BSE"
                ) {
                  axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_BSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_NSE"
                ) {
                  axiosThis.derivat.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MCX" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX"
                ) {
                  axiosThis.commoditi.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
              }
              // console.log(axiosThis.derivat);
              // axiosThis.payout.ledger_amount = axiosThis.payout.ledger_amount.toFixed(2)
              // axiosThis.payout.withdraw_amount = axiosThis.payout.withdraw_amount.toFixed(2)

              // axiosThis.cashledg = axiosThis.payout.ledger_amount
            } else {
              // msg = axiosThis.decryptionFunction(response.data.msg);
              // console.warn(JSON.parse(msg));
            }
          } else {
            // console.warn("expired")
            if (response.data.emsg == "token expired") {
              axiosThis.snackbar = true;
              axiosThis.txt = "Expired";
              // console.warn("Hs")
              localStorage.clear();
              sessionStorage.clear();
              setTimeout(function () {
                // axiosThis.$router.push("/");
                axiosThis.goSso();

                window.location.reload();
              }, 2000);
            }
            if (response.data.emsg == "invalid token") {
              axiosThis.snackbar = true;
              axiosThis.txt = "Invalid";
              // console.warn("Hs")
              localStorage.clear();
              sessionStorage.clear();
              setTimeout(function () {
                // axiosThis.$router.push("/");
                axiosThis.goSso();

                window.location.reload();
              }, 2000);
            }
            if (response.data.emsg == "clientid not exist") {
              axiosThis.snackbar = true;
              axiosThis.txt = "Invalid";
              // console.warn("Hs")
              localStorage.clear();
              sessionStorage.clear();
              setTimeout(function () {
                // axiosThis.$router.push("/");
                axiosThis.goSso();

                window.location.reload();
              }, 2000);
            }
          }
          //  else {
          //     axiosThis.errtext = "Server Down try again later";
          // axiosThis.errorSnackbar = true;
          //     console.warn("Backoffice error")
          // }
          axiosThis.digilockerAdressSubmit();
          axiosThis.getFamily()
        })
        .catch((error) => {
          // alert("Server error, please try again later",error)
          console.log(error);
        });
    },
    chipp() {
      this.chha = !this.chha;
    },
    getCardColor(index) {
      // Custom logic to determine the card color based on the index or other conditions
      if (index % 2 === 0) {
        return "#ECF9EF"; // Example: Blue color for even index cards
      } else {
        return "#FAEAEA"; // Example: Green color for odd index cards
      }
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""),
        (this.requestotpemail = false),
        (this.useremailOtp = ""),
        (this.verifyotpemail = false),
        (this.stateAddress = ""),
        (this.countryAddress = ""),
        (this.distAddress = ""),
        (this.pincodeAddress = "");
      (this.usernewAdress = ""),
        (this.adressproffdoc = null),
        (this.bankIfscCode = ""),
        (this.bankAccountnumber = ""),
        (this.bankproofTypes = ""),
        (this.bankproffdoc = null),
        (this.defaultCheckbox = false);
    },
  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    this.blurLoader = false;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    // console.log("jiji", vendorAcc, vendorToken);
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
        // this.statusCheckNominee(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>
