<template>
  <v-container class="pa-0  ml-md-5 mt-md-5">
    <v-alert v-if="alert" dense type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
      <strong>{{ msg }}</strong>
    </v-alert>
    <div v-if="awesome" class="pt-lg-16 pa-lg-6 pa-sm-4 pa-md-4">
      <p class="lstctntit headline mb-1">Dashboard</p>
      <p class="body-1" color="#757575">Snapshot of your
        holdings and trading activity</p>

      <v-row>
        <v-col cols="12" sm="8" md="8">

          <div class="pt-4 ">
            <p class="title mb-1" color="#757575">Wealth
              segmentation</p>
            <v-card outlined class="elevation-0 rounded-lg">
              <v-row>
                <v-col cols="12" sm="5" md="5" class="my-auto">
                  <div class="pa-8">
                    <img src="@/assets/Wealth segmentation chart bw.svg" width="100%">
                  </div>
                </v-col>
                <v-col cols="12" sm="7" md="7" class="my-auto pr-5">

                  <div class="text-center ">
                    <img src="@/assets/No trades yet.svg">
                    <p class="title  mb-4">No trades yet</p>
                    <p class="body-1 px-16">You haven’t added
                      any trades to your account.
                      Start
                      adding your trades to
                      track them.</p>

                    <v-dialog v-model="dialog" width="800">

                      <template v-slot:activator="{ on, attrs }">
                        <v-btn large outlined color="#1877F2" v-bind="attrs" v-on="on">
                          Add
                          your
                          trades!</v-btn>
                      </template>

                      <v-card class="rounded-lg" style="overflow: hidden;">
                        <div>
                          <v-row class=" my-auto">
                            <v-col class=" trmnucolsl" cols="12" sm="5" md="5">
                              <div class="pt-4 px-8">
                                <p class="ctnbtl headline  mb-4" style="color: #505359;">
                                  Add your trades</p>
                                <p class="body-2 pb-0 mb-sm-16 pb-sm-16 mb-md-16 pb-md-16 " style="color: #6B7280;">
                                  You can now
                                  add your current and old trades to start
                                  tracking
                                  them.</p>

                                <p class="mb-1 mt-0 pt-0 mb-sm-1 mt-sm-16 pt-sm-16 mb-md-1 mt-md-16 pt-md-16"
                                  style="color: #000000; letter-spacing: 0.16em;">
                                  <img class="mr-3" src="@/assets/Contract Notes.svg" width="20px">CONTRACT NOTES
                                </p>
                                <p class="body-1 mb-1 pr-0 pr-sm-6 pr-md-6 mb-sm-4 mb-md-4" style="color: #6B7280;">
                                  Contract
                                  notes
                                  contain all the information about your
                                  trades on
                                  the
                                  exchanges.</p>
                              </div>
                            </v-col>
                            <v-col class="trmnucols2" cols="12" sm="7" md="7">
                              <div class="pt-1 px-8 pt-sm-5 pt-md-5">
                                <p class=" mb-4 body-2" style="color: #000000;  ">
                                  Upload Contract Note</p>
                                <p class="ctnbtl title mr-0 pr-0 pr-sm-10 mr-sm-16 pr-md-10 mr-md-16"
                                  style="line-height:28px;">Start trackr
                                  by
                                  uploading
                                  your daily contract notes</p>

                                <template>
                                  <div class="rounded-lg text-center px-10 py-5 px-sm-16 py-sm-10 px-md-16 py-md-10"
                                    style="border: 1px dashed #D0D3D6;">
                                    <img class="mb-6" src="@/assets/file drop.svg" width="25px">
                                    <p class="mb-0">You can drop your contract notes here to be uploaded</p>
                                    <v-file-input class="mt-0 pt-0 mt-sm-6 pt-sm-1 mt-md-6 pt-md-1" prepend-icon="">
                                    </v-file-input>
                                  </div>

                                </template>
                                <p class="text-center pt-6" style="color:#1877F2;">
                                  Where
                                  can you find your contract notes?</p>
                              </div>
                            </v-col>
                          </v-row>
                        </div>

                        <!-- <div>
                            <div class="pt-8 pb-10 px-8">
                              <p class=" mb-4 body-2"
                                style="color: #000000;  ">
                                Verify Transactions</p>
                              <p class="ctnbtl title mr-0 pr-0 mr-sm-16 pr-sm-10 mr-md-16 pr-md-10"
                                style="line-height:28px;">We’ve found these
                                transactions
                                from your contract notes, please verify and approve.</p>
                              <v-card class="elevation-1">
                                <v-data-table mobile-breakpoint fixed-header disable-sort :headers="headers"
                                  :items="response['Holdings']" :items-per-page="7" hide-default-footer class="elevation-0">
                                  <template v-slot:item="{ item }">
                                    <v-simple-checkbox v-model="item.chbox" disabled></v-simple-checkbox>
                                  </template>
                                </v-data-table>
                                <v-divider></v-divider>
                                <v-row class="px-3 py-3 d-none d-sm-flex d-md-flex">
                                  <v-col cols="12" sm="6" md="6" class="pb-0">
                                    <v-btn small depressed class="ma-0" @click="button">
                                      No trade selected
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6" class="pb-0 text-right">
                                    <v-btn small text color="#1877F2" class="ma-0" @click="button">
                                      Add trades
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </div>
                          </div> -->

                        <!-- <div>
                          <div class="pt-8 pb-10 px-8">
                            <p class=" ctnbtl  mb-4 body-2"
                              style="color: #000000;  ">
                              Add Transactions</p>
                            <p class="ctnbtl title mb-16" style="line-height:28px;">
                              Based on your selection, we are adding trades to your
                              account.</p>

                            <v-row class="mb-16">
                              <v-col>
                                <div>
                                  <p class="caption"
                                    style="color: #000000;  ">
                                    Total Trades Added</p>
                                  <p>
                                    <span class="ctnbtl display-1" style="line-height:28px;">15</span>
                                    trades
                                  </p>
                                </div>
                              </v-col>
                              <v-col>
                                <div>
                                  <p class="caption"
                                    style="color: #000000;  ">
                                    Total Trade Value</p>
                                  <p class="ctnbtl display-1" style="line-height:28px;">
                                    5,42,152<small>.85</small></p>
                                </div>
                              </v-col>
                            </v-row>

                            <v-btn large outlined color="#1877F2" v-bind="attrs" v-on="on">Add to trackr</v-btn>
                          </div>
                        </div> -->
                      </v-card>
                    </v-dialog>
                  </div>

                </v-col>
              </v-row>
            </v-card>
          </div>

        </v-col>

        <v-col cols="12" sm="4" md="4">

          <div class="pt-4">
            <p class="body-1 mb-1" color="#757575">P&L summary
            </p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Total Capital</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Realised P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Current Value</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      UNRealised P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#757575">Fees & Taxes
            </p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Short term P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Fees paid</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Long term P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Taxes Liable</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#757575">Upcoming events</p>
            <v-card outlined class="elevation-0 rounded-lg px-4">
              <div>
                <v-list-item>
                  <div class="text-center pa-2">
                    <p class="pa-16">No upcoming events for
                      your portfolio</p>
                  </div>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>

        </v-col>
      </v-row>
    </div>

    <div v-else class="pt-lg-6 pa-lg-6 pa-sm-4 pa-md-4 ml-lg-2">
      <v-toolbar class="mb-3 toolbar-contantd px-md-3" elevation="0" color="white">

        <v-list-item-title class="text-start mt-1 ml-3"
          style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
          Dashboard
          <v-list-item-subtitle class="text-start pt-1 "
            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Snapshot of your
            holdings and trading activity
          </v-list-item-subtitle>
        </v-list-item-title>

        <v-spacer></v-spacer>
        <!-- <v-select style="width: 160px;" dense background-color="#F1F3F8" rounded
                                @change="yearwisedata(yearis)" :items="items" v-model="yearis" flat solo
                                class="mt-n3 dropdown  elevation-0 rounded-pill  " label="Year"
                                prepend-inner-icon="mdi-calendar" hide-details append-icon="mdi-chevron-down"></v-select> -->
      </v-toolbar>
      <!-- <button @click="awesome = !awesome">toggle</button> -->
      <v-divider class=" mt-2"></v-divider>
      <!-- <div v-if="loader" class="text-center">
                      <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                  </div> -->
      <!-- {{statheader}} -->
      <v-card class="mt-8 elevation-0 " outlined style="height: 80px; width: 100%; background-color: #FAFBFF">
        <v-row class="pa-3 ml-2">
          <v-col v-for="(item, index) in statheader" :key="index">
            <p class="mb-0" style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
              {{ item.title }}
            </p>
            <v-skeleton-loader v-if="loader" class="mx-auto mb-2 mt-2" max-width="300"
              type="heading"></v-skeleton-loader>
            <p v-else class="mb-0 mt-2 body-2 font-weight-regular">
              {{ item.value }}<span v-if="item.value3 || item.value3" class="mb-0 mt-2   caption font-weight-regular"> -
              </span><span v-if="item.value2" class="mb-0 mt-2 caption font-weight-regular">({{ item.value2 }})</span>
              <v-tooltip color="black" v-if="item.value3" bottom>
                <template v-slot:activator="{ on, attrs }">


                  <span style="cursor: pointer" v-bind="attrs" v-on="on"
                    class="mb-0 mt-2 green--text caption font-weight-bold">({{ item.value3 }})</span>
                </template>
                <span>Profit Stock</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="item.value4" bottom>
                <template v-slot:activator="{ on, attrs }">


                  <span style="cursor: pointer" v-bind="attrs" v-on="on"
                    class="mb-0 mt-2 red--text caption font-weight-bold">({{ item.value4 }})</span>
                </template>
                <span>Loss Stock</span>
              </v-tooltip>

            </p>
          </v-col>

        </v-row>
      </v-card>
      <v-row>
        <v-col class="col-md-12 col-sm-12 col-lg-12">
          <v-card class="mt-8 elevation-0 " outlined style="height: 100%; width: 100%; background-color: #FAFBFF">
            <v-row>
              <v-col>
                <v-card class="mt-4 elevation-0 " style="height: 100%; width: 100%; background-color: #FAFBFF">
                  <v-list-item-title class="text-start mt-3 ml-4"
                    style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Fund
                    <v-list-item-subtitle class="text-start pt-1 "
                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">
                      Pooled investments managed for multiple investors.
                    </v-list-item-subtitle>
                  </v-list-item-title>
                  <v-card class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
                    <v-row class="pa-1 ml-1">
                      <v-col cols="4">

                        <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                          Opening Balance
                        </p>
                        <!-- {{ loader }} -->

                        <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                          type="heading"></v-skeleton-loader>
                        <p v-else class="mb-0 body-2 font-weight-bold">
                          {{ (maindata.opening_balance ? maindata.opening_balance  : '0.00').toString() }}
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                          Pay in
                        </p>
                        <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                          type="heading"></v-skeleton-loader>
                        <p v-else class="mb-0 body-2 font-weight-bold">
                          {{ (maindata.payin ? maindata.payin  : '0.00').toString() }}
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                          Pay out
                        </p>
                        <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                          type="heading"></v-skeleton-loader>
                        <p v-else class="mb-0 body-2 font-weight-bold">
                          {{ (maindata.payout ? maindata.payout  : '0.00').toString() }}
                        </p>
                      </v-col>
                    </v-row>

                  </v-card>
                  <v-card class="mt-5 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
                    <v-row class="pa-1 ml-1">
                      <v-col cols="4">
                        <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                          Jv
                        </p>
                        <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                          type="heading"></v-skeleton-loader>
                        <p v-else class="mb-0 body-2 font-weight-bold">
                          {{ (jv ? jv  : '0.00').toString() }}
                        </p>
                      </v-col>

                    </v-row>
                  </v-card>

                  <div class="progress-bar ml-4">
                    <v-tooltip class="text-center" color="black" v-if="maindata.opening_balance" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="progress-linehover"
                          :style="{ width: calculatePercentage(maindata.opening_balance) + '%', backgroundColor: '#d9534f' }">
                        </div>
                      </template>
                      <span class="text-center">Opening balance<br>{{ maindata.opening_balance  }}</span>
                    </v-tooltip>
                    <v-tooltip color="black" v-if="maindata.payin" bottom>
                      <template v-slot:activator="{ on, attrs }">



                        <div v-bind="attrs" v-on="on" class="progress-linehover"
                          :style="{ width: calculatePercentage(maindata.payin) + '%', backgroundColor: '#5bc0de' }">
                        </div>

                      </template>
                      <span>{{ maindata.payin  }}</span>
                    </v-tooltip>
                    <v-tooltip color="black" v-if="maindata.payout" bottom>
                      <template v-slot:activator="{ on, attrs }">



                        <div v-bind="attrs" v-on="on" class="progress-linehover"
                          :style="{ width: calculatePercentage(maindata.payout) + '%', backgroundColor: '#f0ad4e' }">
                        </div>

                      </template>
                      <span>{{ maindata.payout  }}</span>
                    </v-tooltip>
                    <v-tooltip color="black" v-if="jv" bottom>
                      <template v-slot:activator="{ on, attrs }">



                        <div v-bind="attrs" v-on="on" class="progress-linehover"
                          :style="{ width: calculatePercentage(jv) + '%', backgroundColor: '#85c88a' }"></div>

                      </template>
                      <span>{{ jv }}</span>
                    </v-tooltip>


                  </div>
                </v-card>
              </v-col>
              <v-col class="col-md-6 col-sm-12 col-lg-6">
                <v-card class="mt-4 elevation-0 " style="height: 100%; width: 100%; background-color: #FAFBFF">
                  <v-list-item-title class="text-start mt-3 ml-4"
                    style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Margin
                    <v-list-item-subtitle class="text-start pt-1 "
                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Borrowed funds to trade
                      financial assets.
                    </v-list-item-subtitle>
                  </v-list-item-title>
                  <v-card class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
                    <v-row class="pa-1 ml-1">
                      <v-col cols="4" v-for="(value, key ) in maindata.marginsummary" :key="key">
                        <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                          {{ key }}
                        </p>
                        <p v-if="value[0]" class="mb-0 body-2 font-weight-bold">
                          {{ (value[0].sum)  }}
                        </p>
                        <p v-else class="mb-0 body-2 font-weight-bold">
                          0.00
                        </p>
                      </v-col>


                    </v-row>
                  </v-card>

                </v-card>
              </v-col>
            </v-row>

          </v-card>
        </v-col>

        <v-col class="col-md-6 col-sm-12 col-lg-6">
          <v-card class="mt-8 elevation-0 " outlined style="height: 100%; width: 100%; background-color: #FAFBFF">
            <v-list-item-title class="text-start mt-3 ml-4"
              style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
              Equity
              <v-list-item-subtitle class="text-start pt-1 "
                style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Ownership share in a company's
                stock.
              </v-list-item-subtitle>
            </v-list-item-title>
            <v-card class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Realised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.trading_realized ? maindata.trading_realized  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Buy delivery turnover
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.delivery_turnover ? maindata.delivery_turnover  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Changes
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.eq_charges ? maindata.eq_charges  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>

            <!-- <v-card class="mt-5 ma-4 elevation-0 "  style=" height: 70px;  width: 93%; background-color: #FAFBFF">
                           
                        </v-card>  -->

            <div class="progress-bar ml-4" style="margin-top: 110px;">
              <v-tooltip color="black" v-if="maindata.opening_balance" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageEQ(maindata.trading_realized) + '%', backgroundColor: '#d9534f' }">
                  </div>
                </template>
                <span>{{ maindata.trading_realized  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.payin" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageEQ(maindata.delivery_turnover) + '%', backgroundColor: '#5bc0de' }">
                  </div>

                </template>
                <span>{{ maindata.delivery_turnover  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.payout" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageEQ(maindata.eq_charges) + '%', backgroundColor: '#f0ad4e' }">
                  </div>

                </template>
                <span>{{ maindata.eq_charges  }}</span>
              </v-tooltip>



            </div>
          </v-card>
        </v-col>
        <v-col class="col-md-6 col-sm-12 col-lg-6">
          <v-card class="mt-8 elevation-0 " outlined style="height: 100%; width: 100%; background-color: #FAFBFF">
            <v-list-item-title class="text-start mt-3 ml-4"
              style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
              Derivatives
              <v-list-item-subtitle class="text-start pt-1 "
                style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Contracts derive value from
                underlying assets.
              </v-list-item-subtitle>
            </v-list-item-title>
            <v-card class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Realised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.fo_realised ? maindata.fo_realised  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Unrealised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.fut_unrealised ? maindata.fut_unrealised  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Long option
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.opt_buy_dr ? maindata.opt_buy_dr  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mt-5 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Short option
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.opt_sell_cr ? maindata.opt_sell_cr  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Charges
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.fo_charges ? maindata.fo_charges  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <div class="progress-bar ml-4">
              <v-tooltip color="black" v-if="maindata.fo_realised" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageDER(maindata.fo_realised) + '%', backgroundColor: '#d9534f' }">
                  </div>
                </template>
                <span>{{ maindata.fo_realised  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.fut_unrealised" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageDER(maindata.fut_unrealised) + '%', backgroundColor: '#5bc0de' }">
                  </div>

                </template>
                <span>{{ maindata.fut_unrealised  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.opt_buy_dr" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageDER(maindata.opt_buy_dr) + '%', backgroundColor: '#f0ad4e' }">
                  </div>

                </template>
                <span>{{ maindata.opt_buy_dr  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.opt_sell_cr" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageDER(maindata.opt_sell_cr) + '%', backgroundColor: '#85c88a' }">
                  </div>

                </template>
                <span>{{ maindata.opt_sell_cr }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.fo_charges" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageDER(maindata.fo_charges) + '%', backgroundColor: '#8b948c' }">
                  </div>

                </template>
                <span>{{ maindata.fo_charges }}</span>
              </v-tooltip>

            </div>
          </v-card>
        </v-col>
        <v-col class="col-md-6 col-sm-12 col-lg-6">
          <v-card class="mt-8 elevation-0 " outlined style="height: 100%; width: 100%; background-color: #FAFBFF">
            <v-list-item-title class="text-start mt-3 ml-4"
              style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
              Commodity
              <v-list-item-subtitle class="text-start pt-1 "
                style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Basic good traded in financial
                markets.
              </v-list-item-subtitle>
            </v-list-item-title>
            <v-card class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Realised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.comm_realised ? maindata.comm_realised  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Unrealised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.comm_unrealised ? maindata.comm_unrealised  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Long option
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.comm_buy_dr ? maindata.comm_buy_dr  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mt-5 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Short option
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.comm_sell_dr ? maindata.comm_sell_dr  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Charges
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.comm_charges ? maindata.comm_charges  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <div class="progress-bar ml-4">
              <v-tooltip color="black" v-if="maindata.comm_realised" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCOM(maindata.comm_realised) + '%', backgroundColor: '#d9534f' }">
                  </div>
                </template>
                <span>{{ maindata.comm_realised  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.comm_unrealised" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCOM(maindata.comm_unrealised) + '%', backgroundColor: '#5bc0de' }">
                  </div>

                </template>
                <span>{{ maindata.comm_unrealised  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.comm_buy_dr" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCOM(maindata.comm_buy_dr) + '%', backgroundColor: '#f0ad4e' }">
                  </div>

                </template>
                <span>{{ maindata.comm_buy_dr  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.comm_sell_dr" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCOM(maindata.comm_sell_dr) + '%', backgroundColor: '#85c88a' }">
                  </div>

                </template>
                <span>{{ maindata.comm_sell_dr }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.comm_charges" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCOM(maindata.comm_charges) + '%', backgroundColor: '#8b948c' }">
                  </div>

                </template>
                <span>{{ maindata.comm_charges }}</span>
              </v-tooltip>

            </div>
          </v-card>
        </v-col>
        <v-col class="col-md-6 col-sm-12 col-lg-6">
          <v-card class="mt-8 elevation-0 " outlined style="height: 100%; width: 100%; background-color: #FAFBFF">
            <v-list-item-title class="text-start mt-3 ml-4"
              style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
              Currency
              <v-list-item-subtitle class="text-start pt-1 "
                style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Official money used for economic
                exchange.
              </v-list-item-subtitle>
            </v-list-item-title>
            <v-card class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Realised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.curr_realised ? maindata.curr_realised  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Unrealised P&L
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.curr_unrealised ? maindata.curr_unrealised  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Long option
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.curr_buy_dr ? maindata.curr_buy_dr  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mt-5 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
              <v-row class="pa-1 ml-1">
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Short option
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.curr_sell_dr ? maindata.curr_sell_dr  : '0.00').toString() }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                    Charges
                  </p>
                  <v-skeleton-loader v-if="loader" class="mx-auto mb-2" max-width="300"
                    type="heading"></v-skeleton-loader>
                  <p v-else class="mb-0 body-2 font-weight-bold">
                    {{ (maindata.curr_charges ? maindata.curr_charges  : '0.00').toString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <div class="progress-bar ml-4">
              <v-tooltip color="black" v-if="maindata.curr_realised" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCUR(maindata.curr_realised) + '%', backgroundColor: '#d9534f' }">
                  </div>
                </template>
                <span>{{ maindata.curr_realised  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.curr_unrealised" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCUR(maindata.curr_unrealised) + '%', backgroundColor: '#5bc0de' }">
                  </div>

                </template>
                <span>{{ maindata.curr_unrealised  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.curr_buy_dr" bottom>
                <template v-slot:activator="{ on, attrs }">



                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCUR(maindata.curr_buy_dr) + '%', backgroundColor: '#f0ad4e' }">
                  </div>

                </template>
                <span>{{ maindata.curr_buy_dr  }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.curr_sell_dr" bottom>
                <template v-slot:activator="{ on, attrs }"> 
                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCUR(maindata.curr_sell_dr) + '%', backgroundColor: '#85c88a' }">
                  </div> 
                </template>
                <span>{{ maindata.curr_sell_dr }}</span>
              </v-tooltip>
              <v-tooltip color="black" v-if="maindata.curr_charges" bottom>
                <template v-slot:activator="{ on, attrs }"> 
                  <div v-bind="attrs" v-on="on" class="progress-linehover"
                    :style="{ width: calculatePercentageCUR(maindata.curr_charges) + '%', backgroundColor: '#8b948c' }">
                  </div> 
                </template>
                <span>{{ maindata.curr_charges }}</span>
              </v-tooltip>

            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mt-12 elevation-0 " outlined style="height: 95%; width: 100%; background-color: #FAFBFF">

            <div class="d-flex">
              <v-list-item-title class="text-start mt-3 ml-4"
                style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                Trading Metrics
                <v-list-item-subtitle class="text-start pt-1 "
                  style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Ownership share in a company's
                  stock.
                </v-list-item-subtitle>
              </v-list-item-title>
              <v-select label="Filter"   :items="filterr"
                @change="StatDataGet(fromapi, toapi, 'click')" dense rounded solo flat v-model="filterdata_unpl"
                placeholder="filter" background-color="#F1F3F8" hide-details class="mr-6 mt-4 fildrop ml-auto"
                style="max-width: 160px; font-size: 17px"> 
                <template v-slot:selection="{ item }">
                  <span class="font-weight-medium caption">{{ item }}</span>
                </template>
              </v-select>
            </div>
            <v-row> 
              <v-col class="col-md-6 col-sm-12 col-lg-6"> 
                <v-card @click="cardselectChart('one')"
                  :style="{ backgroundColor: selectCard == 'one' ? '#e6e9f7' : 'white' }" class="mt-6 ma-4 elevation-0 "
                  outlined style="   width: 93%; background-color: #ffffff">
                  <v-row class="pa-1 ml-1">
                    <v-col cols="4">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Trading Days
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2 green--text font-weight-bold">
                        {{ (Summary['Trading Days'] ? Summary['Trading Days'] : '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Win Days
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2 green--text font-weight-bold">
                        {{ (Summary['Win Days'] ? Summary['Win Days'] : '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Loss Days
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2 red--text font-weight-bold">
                        {{ (Summary['Loss Days'] ? Summary['Loss Days'] : '0').toString() }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card @click="cardselectChart('three')"
                  :style="{ backgroundColor: selectCard == 'three' ? '#e6e9f7' : 'white' }"
                  class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
                  <v-row class="pa-1 ml-1">
                    <v-col class="col-md-6 col-sm-12 col-lg-6">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Winning Streak Days
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0  green--text body-2 font-weight-bold">
                        {{ (Summary['Winning Streak Days'] ? Summary['Winning Streak Days'] : '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="6">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Losing Streak Days
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2  red--text font-weight-bold">
                        {{ (Summary['Losing Streak Days'] ? Summary['Losing Streak Days'] : '0 ').toString() }}
                      </p>
                    </v-col>

                  </v-row>
                </v-card>
                <v-card @click="cardselectChart('two')"
                  :style="{ backgroundColor: selectCard == 'two' ? '#e6e9f7' : 'white' }" class="mt-6 ma-4 elevation-0 "
                  outlined style="   width: 93%; background-color: #ffffff">
                  <v-row class="pa-1 ml-1">
                    <v-col cols="4">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Ave Pro (Pro Days)
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2 green--text font-weight-bold">
                        {{ (Summary['Average Profit on Profit Days'] ? Summary['Average Profit on Profit Days'] :
                          '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Ave Loss (Loss Days)
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2 red--text font-weight-bold">
                        {{ (Summary['Average Loss on Loss Days'] ? Summary['Average Loss on Loss Days'] :
                          '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Ave Pro/Loss Daily
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2 font-weight-bold">
                        {{ (Summary['Average Profit/Loss Daily'] ? Summary['Average Profit/Loss Daily'] :
                          '0').toString() }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
                
                <v-card @click="cardselectChart('four')"
                  :style="{ backgroundColor: selectCard == 'four' ? '#e6e9f7' : 'white' }"
                  class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
                  <v-row class="pa-1 ml-1">
                    <v-col cols="6">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Maximum Profit in a Day
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2  green--text font-weight-bold">
                        {{ (Summary['Maximum Profit in a Day'] ? Summary['Maximum Profit in a Day'] : '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="6">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Maximum Loss in a Day
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2  red--text font-weight-bold">
                        {{ (Summary['Maximum Loss in a Day'] ? Summary['Maximum Loss in a Day'] : '0.00').toString() }}
                      </p>
                    </v-col>

                  </v-row>
                </v-card>

                <!-- @click="cardselectChart('five')" -->
                <v-card :style="{ backgroundColor: selectCard == 'five' ? '#e6e9f7' : 'white' }"
                  class="mt-6 ma-4 elevation-0 " outlined style="   width: 93%; background-color: #ffffff">
                  <v-row class="pa-1 ml-1">
                    <v-col cols="6">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Win Rate
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2  green--text font-weight-bold">
                        {{ (Summary['Win Rate'] ? Summary['Win Rate'] : '0').toString() }}
                      </p>
                    </v-col>
                    <v-col cols="6">
                      <p class="mb-1 mt-1 font-weight-regular" style="font-size: 14px">
                        Maximum Drawdown
                      </p>
                      <v-skeleton-loader v-if="loaderStat" class="mx-auto mb-2" max-width="300"
                        type="heading"></v-skeleton-loader>
                      <p v-else class="mb-0 body-2  red--text font-weight-bold">
                        {{ (Summary['Maximum Drawdown'] ? Summary['Maximum Drawdown'] : '0 ').toString() }}
                      </p>
                    </v-col>

                  </v-row>
                </v-card>

              </v-col>

              <v-col class="col-md-6 col-sm-12 col-lg-6">

                <!-- <v-list-item-title class="text-start mt-3 ml-4"
                                      style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                      Trading Metrics
                                      <v-list-item-subtitle class="text-start pt-1 "
                                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Ownership share in a company's stock.
                                    </v-list-item-subtitle>
                                  </v-list-item-title> -->
                <!-- <div class="chartmdl ml-10">

                                    
                                    <div class="chartctn"  >
                                      <span class="title" style="color: #505359; ">323</span>

                                      <p class="body-2" style="color: #FF8D24;   ">
                                        Charges
                                      </p>
                                    </div> -->
                <!-- <span id="acquisitions1" style="width: 1450px;" class="ml-2"> </span> -->
                <v-card style="background: transparent ; margin-top: -20px" height="650px " id="acquisitions1"
                  class="rounded-lg   elevation-0" st> 
                </v-card>
                <!-- </div> -->

              </v-col>

            </v-row>
          </v-card>
        </v-col>

      </v-row>
      <!-- <v-row class="mt-12 ml-2">
                    <div class="d-flex">
                      <div>
                        <p class="mb-0" style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">Trading Stat</p>
                        <p class="mb-0" style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Stat of your trading</p>
                      </div> 
                    </div>
                    <div class="ml-auto">
                     
                     
                    </div>
                  </v-row> -->

      <!-- <v-divider class="mt-8"></v-divider> -->

      <v-row>
        <v-col>

        </v-col>
        <v-col>

          <!-- <v-card :loading="loader" outlined rounded-pill class="mt-6  mb-2 elevation-0 pr-6 mr-4">
                <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                    Fund 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Opening Balance</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Pay in
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Pay Out
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">JV
                                  </p>
                                   

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                
                                    {{ (maindata.opening_balance ? maindata.opening_balance  : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                  
                                    {{ (maindata.payin ? maindata.payin  : '0.00').toString() }}
                                  </p>
                                  <p  class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                
                                    {{ (maindata.payout ? maindata.payout  : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    {{ (jv ? jv  : '0.00').toString() }}
                                  </p>
                                </v-col>
                              </v-row>

             
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                    Equity 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Realised P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Buy delivery turnover 
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Changes
                                  </p>
                                  
                                 

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.trading_realized ? maindata.trading_realized  : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                     
                                    {{ (maindata.delivery_turnover ? maindata.delivery_turnover  : '0.00').toString() }}
                                  </p>
                                  <p  class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.eq_charges ? maindata.eq_charges  : '0.00').toString() }}
                                  </p>
                                  
                                </v-col>
                              </v-row>  
                             
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                                Derivatives 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        "> Realised P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    "> Unrealised P&L
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Long option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Short option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Charges
                                  </p>
                                
                                  

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.fo_realised ? maindata.fo_realised  : '0.00').toString() }}
                                  </p>
                                  
                                 
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.fut_unrealised ? maindata.fut_unrealised  : '0.00').toString() }}
                                  </p>
                                
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                   
                                    {{ (maindata.opt_buy_dr ? maindata.opt_buy_dr  : '0.00').toString() }}
                                  </p>
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.opt_sell_cr ? maindata.opt_sell_cr  : '0.00').toString() }}
                                  </p>
                                  
                                  <p class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.fo_charges ? maindata.fo_charges  : '0.00').toString() }}
                                  </p>
                                 
                                </v-col>
                              </v-row>  
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                                Commodity 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Realised  P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Unrealised  P&L
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Long option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Short option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Changes
                                  </p>
                                
                                

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p  class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                
                                    {{ (maindata.comm_realised ? maindata.comm_realised  : '0.00').toString() }}
                                  </p>
                            
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.comm_unrealised ? maindata.comm_unrealised  : '0.00').toString() }}
                                  </p>
                                 
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.comm_buy_dr ? maindata.comm_buy_dr  : '0.00').toString() }}
                                  </p>
                              
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.comm_sell_dr ? maindata.comm_sell_dr  : '0.00').toString() }}
                                  </p>
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                   
                                    {{ (maindata.comm_charges ? maindata.comm_charges  : '0.00').toString() }}
                                  </p>
                                  
                                </v-col>
                              </v-row>  
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                                Currency 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Realised P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Unrealised P&L
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Long option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Short option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Changes
                                  </p>
                                
                               

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p v-if="maindata.curr_realised" class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.curr_realised ? maindata.curr_realised  : '0.00').toString() }}
                                  </p>
                                
                                  
                                  <p v-if="maindata.curr_unrealised" class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                             
                                    {{ (maindata.curr_unrealised ? maindata.curr_unrealised  : '0.00').toString() }}
                                  </p>

                               
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                               
                                    {{ (maindata.curr_buy_dr ? maindata.curr_buy_dr  : '0.00').toString() }}
                                  </p>
                     
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                               
                                    {{ (maindata.curr_sell_dr ? maindata.curr_sell_dr  : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    {{ (maindata.curr_charges ? maindata.curr_charges  : '0.00').toString() }}
                               
                                    
                                  </p>
                               
                                   
                                
                                </v-col>
                              </v-row>  
                            

                              
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                    Margin 
                  </p>
                  <v-row   v-for="(value, key ) in maindata.marginsummary" :key="key" class="pl-11 ">

                    <v-col class="pa-1" cols="8">
                  
                                  <p class="body-2  mb-0" style="color: #000000;   
                                        ">{{ key }}</p>
                                  
                                  
                               

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p v-if="value[0]" class="body-2  mb-0" style="color: #000000;   text-align: right;  
                                    ">
                                    {{ (value[0].sum)  }}
                                  </p>
                                   
                                  
                                </v-col>
                              </v-row>  
              </v-card> -->
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<style scoped>
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

input[type='range'] {
  width: 100%;
  margin-top: 20px;
}

.sumval {
  font-size: 22px;
}

@media only screen and (max-width: 1264px) {
  .sumval {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .sumval {
    font-size: 17px;
  }
}

@media only screen and (max-width: 600px) {
  .sumval {
    font-size: 18px;
  }
}

@media only screen and (max-width: 425px) {
  .sumval {
    font-size: 13px;
  }
}

.chartmdl {
  position: relative;
  text-align: center;
  height: 70%;
  width: 70%;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 100px;
  border: 15px solid #6eb94b;
  display: inline-block;
}

.progress-chart {
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.details-link {
  color: blue;
  text-decoration: none;
  font-size: 14px;
}

.progress-bar {
  display: flex;
  height: 20px;
  width: 93%;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.progress-green {
  background-color: #85c88a;
  height: 100%;
}

.progress-red {
  background-color: #d9534f;
  height: 100%;
}

.chartctn {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-linehover:hover {
  transform: scale(1.05);
  /* Slightly enlarge on hover */

}

#cardclasscolor {
  height: 54px;
  width: 6px;
  background-color: rgb(170, 170, 170);
  border-color: rgb(170, 170, 170);
}
</style>



<script>
// import WealthSegmentation from '../charts/WealthSegmentation.vue';
// import HoldingCurve from '../charts/HoldingCurve.vue';
// import TradeCurve from '../charts/TradeCurve.vue';
import { apiurl } from '../../Api'
import axios from "axios"
import Chart from 'chart.js/auto';
import * as echarts from "echarts";

const { postData } = require('../../apiGetData'); // Import the postData function

export default {
  data() {
    return {
      // /* eslint-disable */
      awesome: false,
      dialog: false,
      loader: true,
      loaderStat: true,
      fromdate: '',
      eventloader: false,
      maindata: [],
      items: [],
      opbalance: '',
      eqRealised: '',
      fo_unrealised: '',
      cd_realised: '',
      cd_unrealised: '',
      fulldetails: true,
      chartInstance: null,
      funddetails: false,
      wealthdetails: false,
      Margindetails: false,
      pnldetails: false,
      chargesdetails: false,
      chartVal: null,
      cd_sell_cr: '',
      cd_buy_dr: '',
      comm_sell_dr: '',
      clprice: 0,
      filterdata_unpl: 'Equity',
      comm_buy_dr: '',

      comm_unrealised: '',
      fund: 0,
      wealth: 0,
      ltpvalarray: [],
      pnlseg: 0,
      chargesseg: 0,
      jv: 0,
      cd_charges: '',
      statheader: [
        {
          "title": "Closing Price",
          "value": 0
        },
        {
          "title": "Stocks",
          "value": 0,
          "value3": 0,
          "value4": 0
        },
        {
          "title": "Invested Value",
          "value": 0
        },
        // {
        //   "title" : "Current Value",
        //   "value" : 0,
        //   "value2" : 0

        // },
        {
          "title": "Pnl & Pnl %",
          "value": 0,
          "value2": 0

        },
        // {
        //   "title" : "Commodity",
        //   "value" : 0
        // },
        // {
        //   "title" : "Currency",
        //   "value" : 0
        // },
        // {
        //   "title" : "Margin",
        //   "value" : 0
        // },
      ],
      eventarray: [],
      upcomeannoncement: [],
      filterr: ['Equity', 'Fno', 'Commodity', 'Currency'],
      upcomebonus: [],
      upcomedivident: [],
      actid: '',
      Assets_value: 0,
      bill_credit: '',
      fromapi: '',
      selectCard: '',
      toapi: '',
      yearis: '',

      bill_debit: '',
      comm_charges: '',
      comm_realised: '',
      eq_charges: '',
      ltparray: [],
      holdings_value_buyprice: 0,
      holdings_value_no_buyprice: 0,
      fo_charges: '',
      margin: 0,
      total_pnl: 0,
      fo_realised: '',
      opt_buy_dr: '',
      opt_sell_cr: '',
      response: [],
      DeliveryEq: 0,
      payin: '',
      payout: '',
      Summary: [],
      alert: false,
      holdings: 36, // Example value
      greenPercentage: 70, // Percentage for the green section
      redPercentage: 30, // Percentage for the red section
      shorterm_realized: '',
      trading_realized: '',
      susertoken: '',
      headers: [
        { text: "Instruments", value: "" },
        { text: "Qty", value: "" },
        { text: "Buy avg.", value: "" },
        { text: "Invest value", value: "" },
        { text: "Select trade", value: "" },
      ],
    };
  },
  computed: {
    totalFund() {
      // Calculate the total of all separate values
      return this.maindata.opening_balance + this.maindata.payin + this.maindata.payout + this.jv;
    },
    totalEq() {
      // Calculate the total of all separate values
      console.log(this.maindata.trading_realized, this.maindata.delivery_turnover, this.maindata.eq_charges, 'donedone')
      return Math.abs(this.maindata.trading_realized) + Math.abs(this.maindata.delivery_turnover) + Math.abs(this.maindata.eq_charges);
    },
    totalDer() {

      return Math.abs(this.maindata.fo_realised) + Math.abs(this.maindata.fut_unrealised) + Math.abs(this.maindata.opt_buy_dr) + Math.abs(this.maindata.opt_sell_cr) + Math.abs(this.maindata.fo_charges);
    },
    totalCOM() {

      return Math.abs(this.maindata.comm_realised) + Math.abs(this.maindata.comm_unrealised) + Math.abs(this.maindata.comm_buy_dr) + Math.abs(this.maindata.comm_sell_dr) + Math.abs(this.maindata.comm_charges);
    },
    totalCUR() {

      return Math.abs(this.maindata.curr_realised) + Math.abs(this.maindata.curr_unrealised) + Math.abs(this.maindata.curr_buy_dr) + Math.abs(this.maindata.curr_sell_dr) + Math.abs(this.maindata.curr_charges);
    },
  },
  methods: {
    chartejs() {
      this.chartInstance = echarts.init(document.getElementById("acquisitions1"));

      var option;

      const gaugeData = [
        {
          value: 100,
          name: 'Total trading days',
          title: {
            offsetCenter: ['0%', '-60%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '-50%']
          }
        },
        {
          value: 30,
          name: 'Win days',
          title: {
            offsetCenter: ['0%', '-35%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '-25%']
          }
        },
        {
          value: 60,
          name: 'Loss days',
          title: {
            offsetCenter: ['0%', '-10%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0%']
          }
        },
        {
          value: 20,
          name: 'Win streak days',
          title: {
            offsetCenter: ['0%', '15%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '25%']
          }
        },
        {
          value: 10,
          name: 'Loss streak days',
          title: {
            offsetCenter: ['0%', '40%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '50%']                                     
          }
        }
      ];
      option = {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 0.1  ,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 60
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: gaugeData,
            title: {
              fontSize: 13
            },
            detail: {
              width: 40,
              height: 11,
              fontSize: 13,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 16,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      };

      this.chartInstance.setOption(option);
    },

    calculatePercentageCUR(value) {
      // Calculate the percentage for each separate value

      return ((Math.abs(value) / this.totalCUR) * 100) ;
    },
    calculatePercentageCOM(value) {
      // Calculate the percentage for each separate value

      return ((Math.abs(value) / this.totalCOM) * 100) ;
    },
    calculatePercentageDER(value) {
      // Calculate the percentage for each separate value

      return ((Math.abs(value) / this.totalDer) * 100) ;
    },
    calculatePercentageEQ(value) {
      // Calculate the percentage for each separate value

      return ((Math.abs(value) / this.totalEq) * 100) ;
    },
    calculatePercentage(value) {
      // Calculate the percentage for each separate value

      return ((Math.abs(value) / this.totalFund) * 100) ;
    },
    yearwisedata(yearis) {
      this.fromshow = ''
      this.yearpop = false
      this.loader = false
      this.loaderStat = false
      this.menu2 = false
      this.response['Holdings'] = []
      this.maindata = []
      var today = new Date();
      // var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      if (mm < 3 && yyyy == yearis) {
        var yearmount = yearis - 1
      } else {
        yearmount = yearis
      }
      // var year = yearis
      let yearnext = yearmount + 1
      let data = JSON.stringify({
        "cc": this.actid,
        "from": '1/04' + '/' + yearmount,
        "to": '31/03' + '/' + yearnext,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: api.apiurl + '/getLedger',
        url: `${apiurl}/accDashboard`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          console.log(response.data);

          axiosthis.maindata = response.data
          console.log(axiosthis.maindata);
          axiosthis.loaderStat = false
          axiosthis.loader = false
          axiosthis.statheader[0].value = axiosthis.maindata.closing_balance
          axiosthis.opbalance = (axiosthis.maindata.opening_balance) 
          axiosthis.payin = (axiosthis.maindata.payin) 
          axiosthis.payout = (axiosthis.maindata.payout) 
          axiosthis.clprice = (axiosthis.maindata.closing_balance) 

          axiosthis.Assets_value = (axiosthis.maindata.Assets_value) 

          axiosthis.eqRealised = (axiosthis.maindata.shorterm_realized + axiosthis.maindata.trading_realized) 
          axiosthis.fo_realised = (axiosthis.maindata.fo_realised) 
          axiosthis.fo_unrealised = (axiosthis.maindata.fot_unrealised)
          axiosthis.opt_buy_dr = (axiosthis.maindata.opt_buy_dr) 
          axiosthis.opt_sell_cr = (axiosthis.maindata.opt_sell_cr) 
          axiosthis.cd_realised = (axiosthis.maindata.curr_realised) 
          axiosthis.cd_unrealised = (axiosthis.maindata.curr_unrealised) 
          axiosthis.cd_buy_dr = (axiosthis.maindata.curr_buy_dr) 
          axiosthis.cd_sell_cr = (axiosthis.maindata.curr_sell_dr) 

          axiosthis.comm_realised = (axiosthis.maindata.comm_realised) 
          axiosthis.comm_unrealised = (axiosthis.maindata.comm_unrealised) 
          axiosthis.comm_sell_dr = (axiosthis.maindata.comm_sell_dr) 
          axiosthis.comm_buy_dr = (axiosthis.maindata.comm_buy_dr) 

          axiosthis.comm_charges = (axiosthis.maindata.comm_charges) 
          axiosthis.eq_charges = (axiosthis.maindata.eq_charges) 
          axiosthis.fo_charges = (axiosthis.maindata.fo_charges) 
          axiosthis.cd_charges = (axiosthis.maindata.curr_charges) 
          axiosthis.DeliveryEq = (axiosthis.maindata.delivery_turnover) 
          axiosthis.bill_credit = axiosthis.maindata.bill_credit
          axiosthis.bill_debit = axiosthis.maindata.bill_debit
          axiosthis.jv = (Number(axiosthis.bill_credit) - Number(axiosthis.bill_debit)) 
          // Convert axiosthis values to numbers
          axiosthis.opbalance = parseFloat(axiosthis.opbalance);
          axiosthis.payin = parseFloat(axiosthis.payin);
          axiosthis.payout = parseFloat(axiosthis.payout);
          axiosthis.Assets_value = parseFloat(axiosthis.Assets_value);
          axiosthis.eqRealised = parseFloat(axiosthis.eqRealised);
          axiosthis.fo_realised = parseFloat(axiosthis.fo_realised);
          axiosthis.fo_unrealised = parseFloat(axiosthis.fo_unrealised);
          axiosthis.opt_buy_dr = parseFloat(axiosthis.opt_buy_dr);
          axiosthis.opt_sell_cr = parseFloat(axiosthis.opt_sell_cr);
          axiosthis.cd_realised = parseFloat(axiosthis.cd_realised);
          axiosthis.cd_unrealised = parseFloat(axiosthis.cd_unrealised);
          axiosthis.cd_buy_dr = parseFloat(axiosthis.cd_buy_dr);
          axiosthis.cd_sell_cr = parseFloat(axiosthis.cd_sell_cr);
          axiosthis.comm_realised = parseFloat(axiosthis.comm_realised);
          axiosthis.comm_unrealised = parseFloat(axiosthis.comm_unrealised);
          axiosthis.comm_sell_dr = parseFloat(axiosthis.comm_sell_dr);
          axiosthis.comm_buy_dr = parseFloat(axiosthis.comm_buy_dr);
          axiosthis.comm_charges = parseFloat(axiosthis.comm_charges);
          axiosthis.DeliveryEq = parseFloat(axiosthis.DeliveryEq);
          axiosthis.eq_charges = parseFloat(axiosthis.eq_charges);
          axiosthis.fo_charges = parseFloat(axiosthis.fo_charges);
          axiosthis.cd_charges = parseFloat(axiosthis.cd_charges);
          axiosthis.jv = parseFloat(axiosthis.jv);

          // Perform addition
          axiosthis.fund = axiosthis.opbalance + axiosthis.payin + axiosthis.payout;
          if (axiosthis.Assets_value < 0) {
            axiosthis.wealth = (axiosthis.Assets_value * -1) 
          }
          // axiosthis.wealth = axiosthis.Assets_value;
          axiosthis.pnlseg = (axiosthis.eqRealised + axiosthis.fo_realised + axiosthis.fo_unrealised + axiosthis.opt_buy_dr + axiosthis.opt_sell_cr + axiosthis.cd_realised + axiosthis.cd_unrealised + axiosthis.cd_buy_dr + axiosthis.cd_sell_cr + axiosthis.comm_realised + axiosthis.comm_unrealised + axiosthis.comm_sell_dr + axiosthis.comm_buy_dr) ;
          axiosthis.chargesseg = axiosthis.comm_charges + axiosthis.eq_charges + axiosthis.fo_charges + axiosthis.cd_charges + axiosthis.jv;
          axiosthis.margin = axiosthis.maindata.margin 
          // Now axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, and axiosthis.chargesseg
          // will have the calculated values with proper type conversion.

          console.log(axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, axiosthis.chargesseg, "kjkjkj")
          // axiosthis.shorterm_realized = axiosthis.maindata.shorterm_realized
          // axiosthis.trading_realized = axiosthis.maindata.trading_realized
          // axiosthis.chartdataupdate()
          // console.log(axiosthis.Assets_value);
          // axiosthis.maindata =  axiosthis.maindata.
          // axiosthis.maindata =  axiosthis.maindata.
          // axiosthis.maindata =  axiosthis.maindata.
          // axiosthis.maindata =  axiosthis.maindata.
          // axiosthis.maindata =  axiosthis.maindata.
          // axiosthis.maindata =  axiosthis.maindata.
          axiosthis.loaderStat = false
          axiosthis.loader = false

        })
        .catch((error) => {
          console.log(error);
          if (error.message == 'Network Error') {
            axiosthis.msg = error.message
            axiosthis.loader = false
            axiosthis.alert = true
            setTimeout(() => {
              axiosthis.alert = false
            }, 5000);

          }
        });

    },
    getColor(value) {
      const sortedValues = [this.clprice, this.chargesseg, this.pnlseg, this.wealth];
      const stval = sortedValues.sort((a, b) => b - a)
      const index = stval.indexOf(value);
      console.log(stval, value, index, ' sortedValues');
      switch (index) {
        case 0:
          return '#015FEC';
        case 1:
          return '#1D1D1D';
        case 2:
          return '#AAAAAA';
        case 3:
          return '#001638';
        case 4:
          return '#6eb94b';
        case 5:
          return '#6eb94b';
        default:
          return 'white'; // Default background color
      }
    },

    fundshow() {
      this.fulldetails = false,
        this.funddetails = true

      this.wealthdetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.chartdataupdate()
      console.log('fund')
    },
    dialogclose() {
      this.fulldetails = true,
        this.funddetails = false
      this.wealthdetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.chartdataupdate()

    },
    marginshow() {
      this.Margindetails = true
      this.fulldetails = false,
        this.funddetails = false
      this.wealthdetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.chartdataupdate()
    },
    chargeshow() {
      this.chargesdetails = true
      this.fulldetails = false,
        this.pnldetails = false
      this.wealthdetails = false
      this.funddetails = false
      this.chartdataupdate()
    },
    pnlshow() {
      this.fulldetails = false,
        this.chargesdetails = false
      this.pnldetails = true
      this.wealthdetails = false
      this.funddetails = false
      this.chartdataupdate()
    },
    wealthshow() {
      this.wealthdetails = true
      this.funddetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.fulldetails = false
      this.chartdataupdate()
    },

    async HoldingsDataGet(date) {
      const postDataObj = {

        cc: this.actid,
        to: date
      };
      const headers = {
        'Content-Type': 'application/json',
        'clientid': this.actid, // Dynamic client ID
        'Authorization': this.susertoken // Dynamic authorization token
      };
      try {
        // Call the postData function and pass the data
        let axiosthis = this
        var response = []
        response = await postData('getHoldings', postDataObj, headers);
        // console.log('Post created:', this.response);

        axiosthis.response = response.Holdings
        // for (let i = 0; i < axiosthis.response['Holdings'].length; i++) {
        //     if (axiosthis.response['Holdings'][i].Exchange != 0 && axiosthis.response['Holdings'][i].Token != 0) {
        //       axiosthis.ltparray.push({ "exch": axiosthis.response['Holdings'][i].Exchange, "token": axiosthis.response['Holdings'][i].Token })
        //     } else {
        //       //
        //     }
        //     // console.log(axiosthis.ltparray,"INaxiosthis.ltparray");
        // }
        // axiosthis.ltpGetdata() 
        // axiosthis.intervalId = setInterval(() => {
        //   axiosthis.ltpGetdata() 
        // }, 5000);
        var procount = 0
        var losscount = 0
        for (let i = 0; i < axiosthis.response.length; i++) {
          const element = axiosthis.response[i];

          if (element.pnl > 0) {
            console.log(element.pnl);
            procount = procount + 1
          } else if (element.pnl < 0) {
            console.log(element.pnl);
            losscount = losscount + 1
          }

        }
        console.log(procount, losscount);
        axiosthis.statheader[1].value3 = procount
        axiosthis.statheader[1].value4 = losscount

        axiosthis.statheader[1].value = this.response.length
        axiosthis.statheader[2].value = response.total_invested
        axiosthis.statheader[3].value = response.total_pnl
        axiosthis.statheader[3].value2 = response.pnl_perc
      } catch (error) {
        console.error('Failed to create post:', error);
      }
    },
    async StatDataGet(Fromdate, todate, val) {
      if (this.filterdata_unpl == 'Equity') {
        var SegmentValue = 'NSE_CASH,BSE_CASH'
      } else if (this.filterdata_unpl == 'Fno') {
        SegmentValue = 'NSE_FNO,BSE_FNO'
      } else if (this.filterdata_unpl == 'Commodity') {
        SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
      } else if (this.filterdata_unpl == 'Currency') {
        SegmentValue = 'CD_NSE,CD_BSE,CD_MCX,CD_USE'
      }
      console.log(val);
      if (val == 'click') {
        this.loaderStat = true
        this.selectCard = ''
      }
      const postDataObj = {

        cc: this.actid,
        to: todate,
        segment: SegmentValue,
        from: Fromdate
      };
      const headers = {
        'Content-Type': 'application/json',
        'clientid': this.actid, // Dynamic client ID
        'Authorization': this.susertoken // Dynamic authorization token
      };
      try {
        // Call the postData function and pass the data
        let axiosthis = this
        var response = []
        response = await postData('getJournal', postDataObj, headers);
        console.log('Post created:', response);
        if (response != 'No Data') {
          axiosthis.Summary = response.summary
          if (val == 'click') {
            axiosthis.loaderStat = false
            axiosthis.selectCard = 'one'
            axiosthis.chartVal.data.datasets[0].data = [axiosthis.Summary['Win Days'], axiosthis.Summary['Loss Days']]; // New data values
            axiosthis.chartInstance.gaugeData 
            axiosthis.chartVal.data.labels = ['Win Days', 'Loss Days']; // New labels if needed
            axiosthis.chartVal.update(); // Apply the updates
          }
          // for (let i = 0; i < axiosthis.response['Holdings'].length; i++) {
          //     if (axiosthis.response['Holdings'][i].Exchange != 0 && axiosthis.response['Holdings'][i].Token != 0) {
          //       axiosthis.ltparray.push({ "exch": axiosthis.response['Holdings'][i].Exchange, "token": axiosthis.response['Holdings'][i].Token })
          //     } else {
          //       //
          //     }
          //     // console.log(axiosthis.ltparray,"INaxiosthis.ltparray");
          // }
          // axiosthis.ltpGetdata() 
          // axiosthis.intervalId = setInterval(() => {
          //   axiosthis.ltpGetdata() 
          // }, 5000);

        } else {
          axiosthis.loaderStat = false
          axiosthis.Summary = []
        }

      } catch (error) {
        console.error('Failed to create post:', error);
      }
    },
    ltpGetdata() {
      alert("enter")
      let data = JSON.stringify({
        "data": this.ltparray
      });

      let config = {
        method: 'post',
        url: 'https://asvr.mynt.in/bcast/GetLtp',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data)); 
          var resarray = []
          resarray = response.data.data;
          axiosthis.ltpvalarray = resarray
          var parsedobj = JSON.parse(JSON.stringify(resarray))
          // console.log("lists s", parsedobj) 
          // for (let w = 0; w < axiosthis.desserts.length; w++) { 
          //   var list = parsedobj.findIndex(o => o.token== axiosthis.desserts[w].Token);
          //   axiosthis.desserts[w]['ltpdata'] = parseFloat(parsedobj[list].lp)
          //   axiosthis.desserts[w]['pnl_ltp'] = axiosthis.desserts[w]['ltpdata'] - axiosthis.desserts[w]['buy_price'] * axiosthis.desserts[w]['NET']
          //   if (axiosthis.desserts[w]['buy_price'] != 0) {
          //   axiosthis.desserts[w]['pnl_per_ltp'] = (axiosthis.desserts[w]['pnl_ltp'] / axiosthis.desserts[w]['buy_price'] * axiosthis.desserts[w]['NET'] ) * 100
          //   }else{
          //   axiosthis.desserts[w]['pnl_per_ltp'] = 0

          //   }

          // }
          // console.log("lists", axiosthis.desserts);
          // axiosthis.selectedItem2 = true




          // console.log(axiosthis.ltpvalarray[adxiosthis.desserts[0].Token] , "test");
          // this.val1 = resarray[0].lp
          // this.val2 = resarray[1].lp
          // this.val3 = resarray[2].lp
          for (let i = 0; i < axiosthis.response.length; i++) {
            if (parsedobj && parsedobj[axiosthis.response[i].Token] && axiosthis.response[i].Token == parsedobj[axiosthis.response[i].Token]?.token) {
              axiosthis.response[i]['ltp'] = parseFloat((parsedobj[axiosthis.response[i].Token].lp));

              if (axiosthis.response[i]['buy_price'] != 0) {
                axiosthis.response[i]['pnl_stat'] = parseFloat(((parseFloat(axiosthis.response[i]['ltp']) - axiosthis.response[i]['buy_price']) * axiosthis.response[i]['NET']) );

                let totalInvest = axiosthis.response[i]['buy_price'] * axiosthis.response[i]['NET'];
                let pnlPercentage = parseFloat((axiosthis.response[i]['pnl_stat'] / totalInvest) * 100);
                axiosthis.response[i]['pnl_perc_stat'] = parseFloat(pnlPercentage );
                axiosthis.response[i]['buy_val_stat'] = parseFloat(axiosthis.response[i]['ltp']) * axiosthis.response[i]['NET']
                axiosthis.response[i]['no_buy_val_stat'] = 0
                // let holdingsValueBuyPrice = 0
                //  holdingsValueBuyPrice += axiosthis.response[i].buy_val_stat || 0;
                //  axiosthis.headerval.holdings_value_buyprice = holdingsValueBuyPrice
              } else {
                axiosthis.response[i]['pnl_perc_stat'] = 0;
                axiosthis.response[i]['pnl_stat'] = 0;
                axiosthis.response[i]['no_buy_val_stat'] = parseFloat(axiosthis.response[i]['ltp']) * axiosthis.response[i]['NET'];
                axiosthis.response[i]['buy_val_stat'] = 0
                // let holdingsValuenoBuyPrice = 0
                //  holdingsValuenoBuyPrice += axiosthis.response[i].no_buy_val_stat || 0;
                //  axiosthis.headerval.holdings_value_no_buyprice = holdingsValuenoBuyPrice
              }

            } else {
              axiosthis.response[i]['ltp'] = parseFloat([axiosthis.response[i].last_close]);

              if (axiosthis.response[i]['buy_price'] != 0) {
                axiosthis.response[i]['pnl_stat'] = parseFloat(((parseFloat(axiosthis.response[i]['ltp']) - axiosthis.response[i]['buy_price']) * axiosthis.response[i]['NET']) );

                let totalInvest = axiosthis.response[i]['buy_price'] * axiosthis.response[i]['NET'];
                let pnlPercentage = parseFloat((axiosthis.response[i]['pnl_stat'] / totalInvest) * 100);
                axiosthis.response[i]['pnl_perc_stat'] = parseFloat(pnlPercentage );
                axiosthis.response[i]['buy_val_stat'] = parseFloat(axiosthis.response[i]['ltp']) * axiosthis.response[i]['NET']
                axiosthis.response[i]['no_buy_val_stat'] = 0
                // let holdingsValueBuyPrice = 0
                //  holdingsValueBuyPrice += axiosthis.response[i].buy_val_stat || 0;
                //  axiosthis.headerval.holdings_value_buyprice = holdingsValueBuyPrice
              } else {
                axiosthis.response[i]['pnl_perc_stat'] = 0;
                axiosthis.response[i]['pnl_stat'] = 0;
                axiosthis.response[i]['no_buy_val_stat'] = parseFloat(axiosthis.response[i]['ltp']) * axiosthis.response[i]['NET'];
                axiosthis.response[i]['buy_val_stat'] = 0
                // let holdingsValuenoBuyPrice = 0
                //  holdingsValuenoBuyPrice += axiosthis.response[i].no_buy_val_stat || 0;
                //  axiosthis.headerval.holdings_value_no_buyprice = holdingsValuenoBuyPrice
              }
              // Handle the case where parsedobj[axiosthis.response[i].Token] is undefined or doesn't match the condition
            }
          }
          if (axiosthis.response) {
            let holdingsValueNoBuyPrice = axiosthis.response.map(obj => obj.no_buy_val_stat || 0).reduce((acc, val) => acc + val, 0);
            axiosthis.holdings_value_no_buyprice = holdingsValueNoBuyPrice;
            let holdingsValueBuyPrice = axiosthis.response.map(obj => obj.buy_val_stat || 0).reduce((acc, val) => acc + val, 0);
            axiosthis.holdings_value_buyprice = holdingsValueBuyPrice
            axiosthis.total_pnl = axiosthis.headerval.holdings_value_buyprice - this.headerval.total_invested
            axiosthis.statheader[3].value = axiosthis.holdings_value_buyprice
            axiosthis.statheader[3].value2 = axiosthis.holdings_value_no_buyprice
            axiosthis.statheader[3].value = axiosthis.total_pnl.total_pnl

            // this.headerval.holdings_value_no_buyprice = this.response.reduce((accumulator, object) => {
            //   return accumulator + object.no_buy_val_stat;
            // }, 0);
            // this.headerval.holdings_value_buyprice = this.desserts.reduce((accumulator, object) => {
            //   return accumulator + object.buy_val_stat;
            // }, 0);
            // let total_pnl = axiosthis.desserts.map(obj => obj.pnl_stat || 0).reduce((acc, val) => acc + val, 0);
            // this.headerval.total_pnl = total_pnl
            // console.log(this.headerval.total_pnl,"dadadada")

          } else {
            this.total_invested = 0
            this.holdings_value_no_buyprice = 0
            this.holdings_value_buyprice = 0
            this.total_pnl = 0
          }

          // this.headerval.total_pnl = this.desserts.reduce((accumulator, object) => {
          //   return accumulator + object.pnl_stat;
          // }, 0);
          // console.log(axiosthis.desserts,"test");


          let valofpnl = (this.headerval.total_pnl / this.headerval.total_invested) * 100
          this.headerval.pnl_perc = (valofpnl) 
          // console.log(this.headerval.holdings_value_no_buyprice,this.headerval.holdings_value_buyprice, 'test2');


          // console.log(axiosthis.desserts, 'list1');

          // Create a dictionary for faster lookup
          // const resDict = {};
          // for (const res of resarray) {
          //   resDict[res.token] = res;
          // }

          // // Update axiosthis.desserts
          // for (let i = 0; i < axiosthis.desserts.length; i++) { 
          //   const dessert = axiosthis.desserts[i];
          //   const resObject = resDict[dessert.Token];

          //   if (resObject) {
          //     dessert['ltp'] = resObject.lp;
          //     dessert['pnl'] = parseFloat(((parseInt(dessert['ltp']) - dessert['buy_price']) * dessert['updated_qty']) );

          //     let totalInvest = dessert['buy_price'] * dessert['updated_qty'];
          //     let pnlPercentage = parseFloat((dessert['pnl'] / totalInvest) * 100);
          //     dessert['pnl_perc'] = parseFloat(pnlPercentage );
          //   }
          // }
          //    this.headerval.holdings_value_no_buyprice = this.desserts.reduce((accumulator, object) => {
          // return accumulator + object.present_no_buy_price;
          //   }, 0);
          //   console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
          //   // ........................................................................................
          //   this.headerval.holdings_value_buyprice = this.desserts.reduce((accumulator, object) => {
          //     return accumulator + object.present_with_buy_price;
          //   }, 0);
          //   console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
          // ........................................................................................
          // this.headerval.total_pnl = this.desserts.reduce((accumulator, object) => {
          //   return accumulator + object.pnl_stat;
          // }, 0);
          // console.log(this.desserts[0].pnl, this.desserts[1].pnl, this.desserts[2].pnl, this.headerval.total_pnl, 'this.headerval.total_invested');
          // ........................................................................................
          // let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
          // this.headerval.pnl_perc = (valofpnl) 



          // axiosthis.selectedItem2 = true

          // console.log(this.headerval.pnl_perc,this.headerval.total_pnl,"pnl_perc_stat");

        })
        .catch((error) => {
          console.log(error);
        });

    },
    cardselectChart(val) {
      if (val == 'one') {
        this.selectCard = 'one'
        console.log(this.Summary['Win Days']);

        this.chartVal.data.datasets[0].data = [this.Summary['Win Days'], this.Summary['Loss Days']]; // New data values
        this.chartVal.data.labels = ['Win Days', 'Loss Days']; // New labels if needed
        this.chartVal.update(); // Apply the updates
      } else if (val == 'two') {
        this.selectCard = 'two'
        this.chartVal.data.datasets[0].data = [this.Summary['Average Profit on Profit Days'], this.Summary['Average Loss on Loss Days']]; // New data values
        this.chartVal.data.labels = ['Ave Pro', 'Ave Loss']; // New labels if needed
        this.chartVal.update(); // Apply the updates

      } else if (val == 'three') {
        this.selectCard = 'three'
        this.chartVal.data.datasets[0].data = [this.Summary['Winning Streak Days'], this.Summary['Losing Streak Days']]; // New data values
        this.chartVal.data.labels = ['Winning Streak Days', 'Losing Streak Days',]; // New labels if needed
        this.chartVal.update(); // Apply the updates

      } else if (val == 'four') {
        this.selectCard = 'four'
        this.chartVal.data.datasets[0].data = [this.Summary['Maximum Profit in a Day'], this.Summary['Maximum Loss in a Day']]; // New data values
        this.chartVal.data.labels = ['Maximum Profit in a Day', 'Maximum Loss in a Day']; // New labels if needed
        this.chartVal.update(); // Apply the updates

      }
      //  else if(val == 'five'){
      //   this.selectCard = 'five'
      //   this.chartVal.data.datasets[0].data = [200, 150, 80]; // New data values
      //   this.chartVal.data.labels = ['Green', 'Orange', 'Purple']; // New labels if needed
      //   this.chartVal.update(); // Apply the updates

      // }
    },
    chart() {
      const data = {
        labels: [
          'Win Days',
          'Loss Days',

        ],
        datasets: [{
          //  'rgb(255, 99, 132)',
          //   'rgb(54, 162, 235)',
          //   'rgb(255, 205, 86)'
          data: [this.Summary['Win Days'], this.Summary['Loss Days']],
          backgroundColor: [
            '#85c88a', '#f54e4e'
          ],
          hoverOffset: 4
        }]
      };

      this.chartVal = new Chart(
        document.getElementById('acquisitions'),
        {
          type: 'doughnut',
          data: data,
        }
      );
    },

    // chartdataupdate() {
    //   console.log('fun call');

    //   const ctx = document.getElementById('myChart').getContext('2d');
    //   let data = '';

    //   function generateChartData(labels, data, backgroundColor) {
    //     const originalData = {
    //       labels: labels,
    //       datasets: [{
    //         data: data,
    //         backgroundColor: backgroundColor,
    //       }]
    //     };

    //     // Extract data values and labels
    //     const dataValues = originalData.datasets[0].data;
    //     const labelsArray = originalData.labels;

    //     // Create an array of objects to store data values and corresponding labels
    //     const dataWithLabels = dataValues.map((value, index) => ({ value, label: labelsArray[index] }));

    //     // Sort the array based on data values
    //     dataWithLabels.sort((a, b) => b.value - a.value);

    //     // Extract sorted labels and data values
    //     const sortedLabels = dataWithLabels.map(item => item.label);
    //     const sortedDataValues = dataWithLabels.map(item => item.value);

    //     // Create the sorted data object
    //     const sortedData = {
    //       labels: sortedLabels,
    //       datasets: [{
    //         data: sortedDataValues,
    //         backgroundColor: backgroundColor,
    //       }]
    //     };

    //     return sortedData;
    //   }

    //   // Usage example:

    //   if (this.fulldetails) {
    //     data = generateChartData(['Wealth', 'Pnl', 'Charges','Closing Price'], [this.wealth, this.pnlseg, this.chargesseg,this.clprice], ['#015FEC', '#1D1D1D', '#AAAAAA','#001638']);
    //   } else if (this.funddetails) {
    //     data = generateChartData(['Opening Balance', 'Pay in', 'Pay Out'], [this.opbalance, this.payin, this.payout], ['#015FEC', '#1D1D1D', '#AAAAAA']);
    //   } else if (this.wealthdetails) {
    //     data = generateChartData(['Delivery Value'], [this.Assets_value], ['#015FEC', '#1D1D1D', '#AAAAAA']);
    //   } else if (this.pnldetails) {
    //     data = generateChartData(
    //       ['Equity Realised', 'FNO Realised', 'Future unrealised', 'Option Buy Dr', 'Option Sell Cr', 'Currency Realised', 'Currency unrealised', 'Currency Buy Dr', 'Currency Sell Cr', 'Commodity Relised', 'Commodity unrealised', 'Commodity Buy Dr', 'Commodity sell Dr'],
    //       [this.eqRealised, this.fo_realised, this.fo_unrealised, this.opt_buy_dr, this.opt_sell_cr, this.cd_realised, this.cd_unrealised, this.cd_buy_dr, this.cd_sell_cr, this.comm_realised, this.comm_unrealised, this.comm_buy_dr, this.comm_sell_dr],
    //       ['#015FEC', '#1D1D1D', '#AAAAAA', '#001638']
    //     );
    //   } else if (this.chargesdetails) {
    //     data = generateChartData(['Equity charges', 'FNO charges', 'Currency charges', 'Commdity charges', 'JV'], [this.eq_charges, this.fo_charges, this.cd_charges, this.comm_charges, this.jv], ['#015FEC', '#1D1D1D', '#AAAAAA', '#001638','#001638']);
    //   }else if(this.Margindetails){
    //       var VarArray = []
    //       var KeyVal = []
    //       for (const key in this.maindata.marginsummary) {
    //         if (this.maindata.marginsummary.hasOwnProperty(key)) {
    //           const value = this.maindata.marginsummary[key];
    //           console.log(`${key}: ${value[0].sum}`,"popopopopopopopo");
    //           VarArray.push(key)
    //           let emdata = (value[0].sum) 
    //           KeyVal.push(Number(emdata))
    //         } 
    //       }
    //       data = generateChartData(VarArray,KeyVal , ['#015FEC', '#1D1D1D', '#AAAAAA', '#001638','#001638']);
    //     console.log(VarArray,KeyVal,"popopopopopopopo");

    //   }


    //   if (this.myChart) {
    //     this.myChart.destroy(); // Destroy the existing chart
    //   }

    //   this.myChart = new Chart(ctx, {
    //     type: 'doughnut',
    //     data: data,
    //     options: {
    //       legend: {
    //         display: false,
    //       },
    //       cutoutPercentage: 75,
    //       scales: {
    //         y: {
    //           beginAtZero: true
    //         }
    //       }
    //     }
    //   });
    //   this.loader = false
    // }

  },
  mounted() {
    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    this.cname = localStorage.getItem("cname");
    // var res = this.decryptionFunction(localStorage.getItem("profile_data"));
    // console.log('ttttt',JSON.parse(res));
    // var resp = JSON.parse(res);
    // this.profiledetails = resp.client_data;



    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    // var nextyear = yyyy + 1
    // var startYear = yyyy - 4;
    this.yearis = yyyy
    // for (let year = startYear; year <= yyyy; year++) {
    //   this.items.push(year);
    // }
    // var today = new Date();
    // var yyyy = today.getFullYear();

    if (mm < 4) {
      var yearmount = yyyy - 1
    } else {
      yearmount = yyyy
    }
    var startYear = yearmount - 4;
    this.yearis = yearmount;

    for (let year = yearmount; year >= startYear; year--) {
      this.items.push(year);
    }
    // var todayapi = dd + '/' + mm + '/' + yyyy;
    var fromapi = '01' + '/' + '04' + '/' + yearmount;
    // let nextyear = parseInt(yearmount) + 1

    var today1 = dd + '/' + mm + '/' +( Number(yearmount) + 1);
    console.log(today1);
    
    this.fromapi = fromapi
    this.toapi = today1
    this.HoldingsDataGet(today1)
    this.StatDataGet(fromapi, today1)
    // const axios = require('axios');
    let data = JSON.stringify({
      "cc": this.actid,
      "from": fromapi,
      "to": today1
    });
    let axiosthis = this
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiurl}/accDashboard`,
      // url: 'http://192.168.5.163:9003/accDashboard',
      headers: {
        'Authorization': this.susertoken,
        'clientid': this.actid,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log(response.data);
        axiosthis.maindata = response.data
        console.log(axiosthis.maindata);
        axiosthis.loader = false
        axiosthis.loaderStat = false
        axiosthis.statheader[0].value = axiosthis.maindata.closing_balance
        axiosthis.opbalance = (axiosthis.maindata.opening_balance) 
        axiosthis.payin = (axiosthis.maindata.payin) 
        axiosthis.payout = (axiosthis.maindata.payout) 
        axiosthis.clprice = (axiosthis.maindata.closing_balance) 
        axiosthis.Assets_value = (axiosthis.maindata.Assets_value) 

        axiosthis.eqRealised = (axiosthis.maindata.shorterm_realized + axiosthis.maindata.trading_realized) 
        axiosthis.fo_realised = (axiosthis.maindata.fo_realised) 
        axiosthis.fo_unrealised = (axiosthis.maindata.fot_unrealised)
        axiosthis.opt_buy_dr = (axiosthis.maindata.opt_buy_dr) 
        axiosthis.opt_sell_cr = (axiosthis.maindata.opt_sell_cr) 
        axiosthis.cd_realised = (axiosthis.maindata.curr_realised) 
        axiosthis.cd_unrealised = (axiosthis.maindata.curr_unrealised) 
        axiosthis.cd_buy_dr = (axiosthis.maindata.curr_buy_dr) 
        axiosthis.cd_sell_cr = (axiosthis.maindata.curr_sell_dr) 

        axiosthis.comm_realised = (axiosthis.maindata.comm_realised) 
        axiosthis.comm_unrealised = (axiosthis.maindata.comm_unrealised) 
        axiosthis.comm_sell_dr = (axiosthis.maindata.comm_sell_dr) 
        axiosthis.comm_buy_dr = (axiosthis.maindata.comm_buy_dr) 

        axiosthis.comm_charges = (axiosthis.maindata.comm_charges) 
        axiosthis.eq_charges = (axiosthis.maindata.eq_charges) 
        axiosthis.fo_charges = (axiosthis.maindata.fo_charges) 
        axiosthis.cd_charges = (axiosthis.maindata.curr_charges) 
        axiosthis.DeliveryEq = (axiosthis.maindata.delivery_turnover) 
        axiosthis.bill_credit = axiosthis.maindata.bill_credit
        axiosthis.bill_debit = axiosthis.maindata.bill_debit
        axiosthis.jv = (Number(axiosthis.bill_credit) - Number(axiosthis.bill_debit)) 
        // Convert axiosthis values to numbers
        axiosthis.opbalance = parseFloat(axiosthis.opbalance);
        axiosthis.payin = parseFloat(axiosthis.payin);
        axiosthis.payout = parseFloat(axiosthis.payout);
        axiosthis.Assets_value = parseFloat(axiosthis.Assets_value);
        axiosthis.eqRealised = parseFloat(axiosthis.eqRealised);
        axiosthis.fo_realised = parseFloat(axiosthis.fo_realised);
        axiosthis.fo_unrealised = parseFloat(axiosthis.fo_unrealised);
        axiosthis.opt_buy_dr = parseFloat(axiosthis.opt_buy_dr);
        axiosthis.opt_sell_cr = parseFloat(axiosthis.opt_sell_cr);
        axiosthis.cd_realised = parseFloat(axiosthis.cd_realised);
        axiosthis.cd_unrealised = parseFloat(axiosthis.cd_unrealised);
        axiosthis.cd_buy_dr = parseFloat(axiosthis.cd_buy_dr);
        axiosthis.cd_sell_cr = parseFloat(axiosthis.cd_sell_cr);
        axiosthis.comm_realised = parseFloat(axiosthis.comm_realised);
        axiosthis.comm_unrealised = parseFloat(axiosthis.comm_unrealised);
        axiosthis.comm_sell_dr = parseFloat(axiosthis.comm_sell_dr);
        axiosthis.comm_buy_dr = parseFloat(axiosthis.comm_buy_dr);
        axiosthis.comm_charges = parseFloat(axiosthis.comm_charges);
        axiosthis.DeliveryEq = parseFloat(axiosthis.DeliveryEq);
        axiosthis.eq_charges = parseFloat(axiosthis.eq_charges);
        axiosthis.fo_charges = parseFloat(axiosthis.fo_charges);
        axiosthis.cd_charges = parseFloat(axiosthis.cd_charges);
        axiosthis.jv = parseFloat(axiosthis.jv);

        // Perform addition
        axiosthis.fund = axiosthis.opbalance + axiosthis.payin + axiosthis.payout;
        if (axiosthis.Assets_value < 0) {
          axiosthis.wealth = (axiosthis.Assets_value * -1) 
        }
        // axiosthis.wealth = axiosthis.Assets_value;
        axiosthis.pnlseg = (axiosthis.eqRealised + axiosthis.fo_realised + axiosthis.fo_unrealised + axiosthis.opt_buy_dr + axiosthis.opt_sell_cr + axiosthis.cd_realised + axiosthis.cd_unrealised + axiosthis.cd_buy_dr + axiosthis.cd_sell_cr + axiosthis.comm_realised + axiosthis.comm_unrealised + axiosthis.comm_sell_dr + axiosthis.comm_buy_dr) ;
        axiosthis.chargesseg = axiosthis.comm_charges + axiosthis.eq_charges + axiosthis.fo_charges + axiosthis.cd_charges + axiosthis.jv;
        axiosthis.margin = axiosthis.maindata.margin 
        // Now axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, and axiosthis.chargesseg
        // will have the calculated values with proper type conversion.

        console.log(axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, axiosthis.chargesseg, "kjkjkj")
        // axiosthis.shorterm_realized = axiosthis.maindata.shorterm_realized
        // axiosthis.trading_realized = axiosthis.maindata.trading_realized
        // axiosthis.chartdataupdate()
        // console.log(axiosthis.Assets_value);
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        axiosthis.loaderStat = false
        axiosthis.loader = false
        axiosthis.selectCard = 'one'
        // axiosthis.chart()
        axiosthis.chartejs()
          ()
      })
      .catch((error) => {
        console.log(error);
        if (error.message == 'Network Error') {
          axiosthis.msg = error.message
          axiosthis.loaderStat = false
          axiosthis.loader = false
          axiosthis.alert = true
          setTimeout(() => {
            axiosthis.alert = false
          }, 5000);
        }
      });
    // const axios = require('axios');
    // let data1 = '';

    // let config1 = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'http://192.168.5.142:5001/getEquityCorporateActions',
    //   headers: {},
    //   data: data1
    // };
    // // let axiosthis = this
    // axios.request(config1)
    //   .then((response) => {
    //     function processDateArray(array, datePropertyName) {
    //       array.forEach(item => {
    //         const inputDateString = item[datePropertyName];
    //         const inputDate = new Date(inputDateString);
    //         item[datePropertyName] = inputDate;
    //       });

    //       array.sort((a, b) => b[datePropertyName] - a[datePropertyName]);

    //       array.forEach(item => {
    //         const day = item[datePropertyName].getDate().toString().padStart(2, '0');
    //         const month = (item[datePropertyName].getMonth() + 1).toString().padStart(2, '0');
    //         const year = item[datePropertyName].getFullYear();
    //         item[datePropertyName] = `${day}/${month}/${year}`;
    //       });
    //     }
    //     processDateArray(response.data.announcement, 'board meeting date');
    //     processDateArray(response.data.bonus, 'ex_bonus_date');
    //     processDateArray(response.data.dividend, 'ex-date');
    //     axiosthis.annostablearray = response.data.announcement
    //     if (axiosthis.annostablearray.length > 0) {
    //       for (let i = 0; i < 2; i++) {
    //         axiosthis.upcomeannoncement.push(axiosthis.annostablearray[i])
    //       }

    //     }

    //     axiosthis.bonustablearray = response.data.bonus
    //     console.log(axiosthis.bonustablearray.length, axiosthis.bonustablearray, 'lala')
    //     if (axiosthis.bonustablearray.length > 1) {
    //       for (let i = 0; i < 2; i++) {
    //         axiosthis.upcomebonus.push(axiosthis.bonustablearray[i])
    //         console.log('if')
    //       }
    //     } else {
    //       axiosthis.upcomebonus = axiosthis.bonustablearray

    //     }
    //     //  if (axiosthis.bonustablearray.length > 0) {
    //     //     for (let i = 0; i < 2; i++) {
    //     //       axiosthis.upcomebonus.push(axiosthis.bonustablearray[i])
    //     //     }

    //     //   }
    //     axiosthis.dividendtablearray = response.data.dividend
    //     if (axiosthis.dividendtablearray.length > 1) {
    //       for (let i = 0; i < 2; i++) {
    //         axiosthis.upcomedivident.push(axiosthis.dividendtablearray[i])
    //       }
    //     }

    //     // console.log(axiosthis.upcomeannoncement ,'dada');
    //     axiosthis.rightstablearray = response.data.rights
    //     axiosthis.splittablearray = response.data.split
    //     axiosthis.eventloader = false
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     if (error.message == 'Network Error') {
    //                 this.msg = error.message
    //                 axiosthis.eventloader = false
    //                 axiosthis.alert = true
    //                 setTimeout(() => {
    //                     axiosthis.alert = false
    //                 }, 5000);
    //               }
    //   });

    // this.maxval =   maxyearval + '-' + mm + '-' + dd
    // this.toshow = today1
    // this.fromshow = fromapi
    // this.segfrom = fromapi
    // this.fromdateapi = fromapi

  },

  // components: { WealthSegmentation, HoldingCurve, TradeCurve },
}
</script>
