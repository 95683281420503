<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <!-- <div id="app" class="web-camera-container">
  <div class="camera-button">
      <v-btn type="button" class="button is-rounded" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
        <span v-if="!isCameraOpen">Open Camera</span>
        <span v-else>Close Camera</span>
      </v-btn>
  </div>
  
  <div v-show="isCameraOpen && isLoading" class="camera-loading">

  </div>
  
  <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
    
    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
      
    <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
    
    <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
  </div>
  
  <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
    <button type="button" class="button" @click="takePhoto">
      <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
    </button>
  </div>
  
  <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
    <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">
      Download
    </a>
  </div>
</div> -->
    <div class="px-4">
      <div class="py-md-7 d-block d-md-none"></div>
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1">
          <v-list-item-content>
            <v-list-item-title class="text-start" style="color: #000000; font-size: 20px; font-weight: 559; line-height: 24px; letter-spacing: -1px"> Profile Details</v-list-item-title>
            <v-list-item-subtitle class="text-start pt-1" style="color: #666666; font-size: 12px; font-weight: 469; line-height: 14px">These details are used for all communication related to your account. </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <p class="font-weight-bold fs-22 mb-0">Profile Details 
          
        </p>
        <p class="subtitle-2 txt-666">
          These details are used for all communication related to your account.
        </p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="py-md-6 px-md-3 mt-6 mb-md-6" :outlined="$vuetify.breakpoint.smAndDown ? false : true" elevation="0" width="100%">
        <p class="subtitle-1 txt-666 font-weight-medium">Personal Details</p>
        <!-- {{accountstatus}} -->
        <v-row no-glutters>
          <v-col cols="12" sm="6" md="4">
            <v-text-field readonly :loading="profileLoader" v-model="profileData.Pan_Name" append-icon="" label="NAME AS PER PAN"></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-text-field   readonly :loading="profileLoader"
              v-model="profileData.CLIENT_NAME" append-icon="" label="NAME"></v-text-field>
          </v-col> -->
          <v-col cols="12" sm="6" md="4">
            <v-text-field readonly :loading="profileLoader" v-model="profileData.BIRTH_DATE" append-icon="" label="BIRTH DATE"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field readonly :loading="profileLoader" v-model="profileData.Marital_status" append-icon="" label="MARITAL STATUS"></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field readonly :loading="profileLoader" :value="`*******${hashpan}`" append-icon="" label="PAN NUMBER"></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field :loading="profileLoader" readonly label="EMAIL" :disabled="emailDisable" v-model="profileData.CLIENT_ID_MAIL">
              <template #append>
                <div v-if="accountstatus != 'client_close'">
                  <v-btn v-if="!emailDisable" @click="mobileEdit('email')" class="rounded" style="background-color: #000" elevation="0" text small :disabled="accountstatus == 'client_close'">
                    <!-- <img src="@/assets/pen.svg" alt="pen"> -->
                    <span style="color: #fff; font-size: 10px">Edit</span>
                  </v-btn>
                </div>
                <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="rounded" elevation="0" small> Edit </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>
                </div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field id="mobileedit" readonly :disabled="mobileDisable" :loading="profileLoader" v-model="profileData.MOBILE_NO" label="MOBILE">
              <template #append>
                <div v-if="accountstatus != 'client_close'">
                  <v-btn v-if="!mobileDisable" @click="mobileEdit('mobile')" class="rounded" style="background-color: #000" elevation="0" text small :disabled="accountstatus == 'client_close'">
                    <!-- <img src="@/assets/pen.svg" alt="pen"> -->
                    <span style="color: #fff; font-size: 10px">Edit</span>
                  </v-btn>
                </div>
                <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="rounded" elevation="0" small> Edit </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>
                </div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="8">
            <v-text-field :loading="profileLoader" readonly :disabled="addressDisable" label="ADDRESS" v-model="profileData.CL_RESI_ADD1" @click:append="mobileEdit('address')">
              <template #append>
                <div v-if="accountstatus != 'client_close'">
                  <v-btn v-if="!addressDisable" @click="mobileEdit('address')" class="rounded" style="background-color: #000" elevation="0" text small :disabled="accountstatus == 'client_close'">
                    <!-- <img src="@/assets/pen.svg" alt="pen"> -->
                    <span style="color: #fff; font-size: 10px">Edit</span>
                  </v-btn>
                </div>
                <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="rounded" elevation="0" small> Edit </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>
                </div>
              </template>
            </v-text-field>
          </v-col>

          <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field id="mobileedit"   
                    readonly :disabled="mobileDisable" :loading="profileLoader" v-model="profileData.MOBILE_NO"
                    :append-icon="'mdi-pencil-circle-outline'" label="MOBILE"
                    @click:append="mobileEdit('mobile')"></v-text-field>
                </v-col> -->
        </v-row>
        <v-card rounded="lg" v-if="mobStatus.mobile_status == 'e-signed pending'" color="#FCEFD4" elevation="0" class="">
          <div class="px-2 py-1">
            <p style="font-size: 10.5px;  font-weight: 459; margin: 0; display: inline; color: #666666" class="d-none d-md-block">
              Your Mobile Change request is not yet Completed,<span  style="color: #0037b7; cursor: pointer" @click="digioEsign(mobStatus.mobile_file_id, mobStatus.mob_client_email.toUpperCase(), mobStatus.mob_session), (editType = 'mobile_change')"> Click here esign</span>
              <span @click="(editType = 'mobile_change'), (fileide = mobStatus.mobile_file_id), (confirmation = true)" class="mt-1 mr-2 " style="color: red; cursor: pointer; float: right">Cancel request</span>
            </p>
            <div class="mt-1  d-md-none">
              <p style="font-size: 10.5px;  font-weight: 459; margin: 0; display: inline; color: #666666">Your Mobile Change request is not yet Completed,</p>
              <div class="d-flex">
              <span style="color: #0037b7; cursor: pointer" @click="digioEsign(mobStatus.mobile_file_id, mobStatus.mob_client_email.toUpperCase(), mobStatus.mob_session), (editType = 'mobile_change')"> Click here esign</span>
              <span @click="(editType = 'mobile_change'), (fileide = mobStatus.mobile_file_id), (confirmation = true)" class="mt-1 mr-2 ml-auto" style="color: red; cursor: pointer; float: right">Cancel request</span>
            </div>
            </div>
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.mobile_status == 'e-signed completed'" color="#FCEFD4" elevation="0" class="">
          <div class="px-2 py-1">
            <p style="font-size: 10.5px; font-weight: 459; margin: 0; display: inline; color: #666666">
              Your Mobile Change request is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.email_status == 'e-signed pending'" color="#FCEFD4" elevation="0" class="mt-4">
          <div class="px-2 py-1">
            <p style="font-size: 10.5px; font-weight: 459; margin: 0; display: inline; color: #666666" class="d-none d-md-block">
              Your Email Change request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="digioEsign(mobStatus.email_file_id, mobStatus.email_new_email_id.toLowerCase(), mobStatus.email_session), (editType = 'email_change')"> Click here esign</span>
              <span @click="(editType = 'email_change'), (fileide = mobStatus.email_file_id), (confirmation = true)" class="mt-1 mr-2" style="color: red; cursor: pointer; float: right">Cancel request</span>
            </p>

            <div class="d-md-none">
              <p style="font-size: 10.5px;  font-weight: 459; margin: 0; display: inline; color: #666666"> Your Email Change request is not yet Completed,</p>
              <div class="d-flex">
                <span style="color: #0037b7; cursor: pointer" @click="digioEsign(mobStatus.email_file_id, mobStatus.email_new_email_id.toLowerCase(), mobStatus.email_session), (editType = 'email_change')"> Click here esign</span>

              <span @click="(editType = 'email_change'), (fileide = mobStatus.email_file_id), (confirmation = true)" class="mt-1 mr-2 ml-auto" style="color: red; cursor: pointer; float: right">Cancel request</span>

              </div>

            </div>



            <!-- <span
                @click="editType = 'email_change', fileide = mobStatus.email_file_id, confirmation = true"
                class="mt-1 mr-2 d-none d-sm-flex d-sm-flex d-md-flex d-lg-none d-xl-none" style="color: red;cursor: pointer;float:right">Cancel request</span> -->
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.email_status == 'e-signed completed'" color="#FCEFD4" elevation="0" class="mt-4">
          <div class="px-2 py-1">
            <p style="font-size: 10.5px; font-weight: 459; margin: 0; display: inline; color: #666666">
              Your Email Change request for (
              {{ this.mobStatus.email_new_email_id }} ) is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.address_status == 'e-signed pending'" color="#FCEFD4" elevation="0" class="mt-4">
          <div class="px-2 py-1">
            <p style="font-size: 10.5px; font-weight: 459; margin: 0; display: inline; color: #666666" class="d-none d-md-block">
              Your Address Change request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="(editType = 'address_change'), digioEsign(mobStatus.address_file_id, mobStatus.address_client_email.toLowerCase(), mobStatus.address_session)"> Click here esign</span
              ><span @click="(editType = 'address_change'), (fileide = mobStatus.address_file_id), (confirmation = true)" class="mt-1 mr-2" style="color: red; cursor: pointer; float: right">Cancel request</span>
            </p>

            <div class="d-md-none">
              <p style="font-size: 10.5px; font-weight: 459; margin: 0; display: inline; color: #666666" >
                Your Address Change request is not yet Completed,</p>
                <div class="d-flex">
                  <span style="color: #0037b7; cursor: pointer" @click="(editType = 'address_change'), digioEsign(mobStatus.address_file_id, mobStatus.address_client_email.toLowerCase(), mobStatus.address_session)"> Click here esign</span>
                  <span @click="(editType = 'address_change'), (fileide = mobStatus.address_file_id), (confirmation = true)" class="mt-1 mr-2 ml-auto" style="color: red; cursor: pointer; float: right">Cancel request</span>
                </div>
            </div>



          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.address_status == 'e-signed completed'" color="#FCEFD4" elevation="0" class="mt-4">
          <div class="px-2 py-1">
            <p style="font-size: 10.5px; font-weight: 459; margin: 0; display: inline; color: #666666">
              Your Address Change request is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>
      </v-card>

      <!-- <v-card 
                class="py-md-6 mb-md-6 d-md-none"
                elevation="0"
                width="100%"
              >
                <p class="subtitle-1 txt-666 font-weight-medium">
                  Personal Details
                </p>

                <v-row no-glutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                       
                      readonly
                      :loading="profileLoader"
                      v-model="profileData.CLIENT_NAME"
                      append-icon=""
                      label="NAME"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                       
                      readonly
                      :loading="profileLoader"
                      :value="`*******${hashpan}`"
                      append-icon=""
                      label="PAN NUMbER"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                       
                      :loading="profileLoader"
                      readonly
                      label="EMAIL"
                      :disabled="emailDisable"
                      :append-icon="'mdi-pencil-circle-outline'"
                      v-model="profileData.CLIENT_ID_MAIL"
                    >
                      <template #append>
                        <v-btn @click="mobileEdit('email')" icon small>
                          <img src="@/assets/pen.svg" alt="pen" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      id="mobileedit"
                       
                      readonly
                      :disabled="mobileDisable"
                      :loading="profileLoader"
                      v-model="profileData.MOBILE_NO"
                      :append-icon="'mdi-pencil-circle-outline'"
                      label="MOBILE"
                    >
                      <template #append>
                        <v-btn @click="mobileEdit('mobile')" icon small>
                          <img src="@/assets/pen.svg" alt="pen" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-text-field
                      :loading="profileLoader"
                      readonly
                      :disabled="addressDisable"
                       
                      :append-icon="'mdi-pencil-circle-outline'"
                      label="ADDRESS"
                      v-model="profileData.CL_RESI_ADD1"
                      @click:append="mobileEdit('address')"
                    >
                      <template #append>
                        <v-btn @click="mobileEdit('address')" icon small>
                          <img src="@/assets/pen.svg" alt="pen" />
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                
                </v-row>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.mobile_status == 'e-signed pending'"
                  color="#FCEFD4"
                  elevation="0"
                  class=""
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your Mobile Change request is not yet Completed,<span
                        style="color: #0037b7; cursor: pointer"
                        @click="
                          digioEsign(
                            mobStatus.mobile_file_id,
                            mobStatus.mob_client_email,
                            mobStatus.mob_session
                          ),
                            (editType = 'mobile_change')
                        "
                      >
                        Click here esign</span
                      >
                    </p>
                  </div>
                </v-card>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.mobile_status == 'e-signed completed'"
                  color="#FCEFD4"
                  elevation="0"
                  class=""
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your Mobile Change request is in process
                    </p>
                  </div>
                </v-card>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.email_status == 'e-signed pending'"
                  color="#FCEFD4"
                  elevation="0"
                  class="mt-4"
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your Email Change request is not yet Completed,<span
                        style="color: #0037b7; cursor: pointer"
                        @click="
                          digioEsign(
                            mobStatus.email_file_id,
                            mobStatus.email_new_email_id,
                            mobStatus.email_session
                          ),
                            (editType = 'email_change')
                        "
                      >
                        Click here esign</span
                      >
                    </p>
                  </div>
                </v-card>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.email_status == 'e-signed completed'"
                  color="#FCEFD4"
                  elevation="0"
                  class="mt-4"
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your Email Change request for (
                      {{ this.mobStatus.email_new_email_id }} ) is in process
                    </p>
                  </div>
                </v-card>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.address_status == 'e-signed pending'"
                  color="#FCEFD4"
                  elevation="0"
                  class="mt-4"
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your Address Change request is not yet Completed,<span
                        style="color: #0037b7; cursor: pointer"
                        @click="
                          (editType = 'address_change'),
                            digioEsign(
                              mobStatus.address_file_id,
                              mobStatus.address_client_email,
                              mobStatus.address_session
                            )
                        "
                      >
                        Click here esign</span
                      >
                    </p>
                  </div>
                </v-card>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.address_status == 'e-signed completed'"
                  color="#FCEFD4"
                  elevation="0"
                  class="mt-4"
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your Address Change request is in process
                    </p>
                  </div>
                </v-card>
              </v-card> -->
    </div>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white" transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white" transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index: 2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog v-model="confirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-2">
        <v-card-title class="pr-2"
          >Cancel request? <v-spacer></v-spacer>
          <v-btn icon @click="confirmation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >
        <v-card-text>
          Are you sure you want to cancel your
          <b>"{{ this.editType }}"</b> request?
        </v-card-text>
        <v-card-actions class="pb-4">
          <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
          <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign">
            <span class="text-none">Proceed</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editdialog" :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'" persistent :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="addNewSeg ? '500' : '420'" min-width="390">
      <v-card class="pb-4 rounded-lg px-md-4">
        <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white" transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
          <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
          {{ errtext }}
          <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
        </v-snackbar>
        <div v-if="requestotp">
          <v-form ref="form">
            <v-card-title style="font-size: 16px">
              Mobile change request <v-spacer></v-spacer>
              <v-btn icon @click="closeBtn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <!-- <p class="ma-0 pa-0" style="font-weight:499">Old Mobile number</p> -->
              <p class="ma-0 pa-0 font-weight-medium txt-666">Present Number</p>
              <v-text-field dense class="pa-0" readonly type="number" hide-spin-buttons v-model="profileData.MOBILE_NO">
                <!-- <template #prepend-inner>
                <div style="width:30px">
                  <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mobile-phone.svg"/>
                </div>
              </template> -->
              </v-text-field>
              <p class="ma-0 pa-0" style="font-weight: 499">New Mobile number <span style="color: red">*</span></p>
              <v-text-field class="pa-0" required type="number" hide-spin-buttons @keyup="checkSame" :rules="mobileNumberRules" placeholder="Enter Mobile no" v-model="usernewmobile">
                <template #prepend-inner>
                  <div style="width: 30px">
                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mobile-phone.svg" />
                  </div>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text " rounded block :loading="mobileLoader" @click="sendButtonClicked">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <div v-if="kraImgCapture">
          <v-form ref="form">
            <v-card-title style="font-size: 16px">
              Let's take a selfie<v-spacer></v-spacer>
              <v-btn icon @click="closeBtn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <!-- <p class="ma-0 pa-0" style="font-weight:499">Old Mobile number</p> -->
              <p class="ma-0 pa-0 font-weight-medium txt-666">Click a selfie for your Re-KYC verification. Please make sure you are in a well-lit area.</p>
              <div></div>
              <div id="app" class="web-camera-container">
                <div align="center" class="camera-button mt-4">
                  <v-btn
                    :ripple="false"
                    v-if="!isCameraOpen"
                    plain
                    class="button is-rounded"
                    :class="{
                      'is-primary': !isCameraOpen,
                      'is-danger': isCameraOpen,
                    }"
                    @click="toggleCamera"
                  >
                    <span>Open Camera</span>
                    <!-- <span v-else>Close Camera</span> -->
                  </v-btn>
                </div>

                <div v-show="isCameraOpen && isLoading" class="camera-loading"></div>

                <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{flash: isShotPhoto}">
                  <div class="camera-shutter" :class="{flash: isShotPhoto}"></div>

                  <video v-show="!isPhotoTaken" ref="camera" width="100%" height="100%" autoplay></video>

                  <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="350" :height="260"></canvas>
                </div>

                <div align="center" v-if="isCameraOpen && !isLoading" class="camera-shoot">
                  <v-btn :ripple="false" depressed class="button" @click="takePhoto">
                    <!-- <v-icon large>
        mdi-camera-iris
      </v-icon> -->

                    <div v-if="isPhotoTaken && isCameraOpen">Retry</div>
                    <div v-else>Capture</div>
                  </v-btn>
                </div>
                <!-- <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
    <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">
      Download
    </a>
  </div> -->
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text " rounded block :loading="mobileLoader" @click="checkKraandsentOTP">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <div v-if="verifyotp">
          <v-form ref="formm">
            <v-card-title style="font-size: 16px">
              Verify Your new number
              <v-spacer></v-spacer>
              <v-btn icon @click="closeBtn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="ma-0 pa-0" style="font-weight: 499">Mobile number <span style="color: red">*</span></p>
              <v-text-field class="pa-0" readonly hide-spin-buttons v-model="usernewmobile">
                <template #prepend-inner>
                  <div style="width: 30px">
                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mobile-phone.svg" />
                  </div>
                </template>
              </v-text-field>
              <p class="ma-0 pa-0" style="font-weight: 499">Enter 4 digit OTP sent to mobile no.<span style="color: red">*</span></p>
              <v-text-field class="pa-0" required type="number" hide-spin-buttons pattern="\d*" maxlength="4" :rules="otpRules" placeholder="00-00" v-model="userOtp">
                <template #prepend-inner>
                  <div style="width: 30px">
                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mobile-chat.svg" />
                  </div>
                </template>
              </v-text-field>
              <!-- <p class="ma-0 pa-0">OTP</p>
                                <v-otp-input
                length="4" plain style="height:40px"
              ></v-otp-input> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text" rounded block :loading="mobileLoader" @click="otpButtonClicked">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <div v-if="requestotpemail">
          <v-form ref="formemailreq">
            <v-card-title style="font-size: 16px">
              Email change request <v-spacer></v-spacer>
              <v-btn icon @click="closeBtn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="ma-0 pa-0 font-weight-medium txt-666">Old email id</p>

              <v-text-field class="pa-0" readonly v-model="profileData.CLIENT_ID_MAIL"></v-text-field>
              <p class="ma-0 pa-0" style="font-weight: 499">New email id <span style="color: red">*</span></p>

              <v-text-field class="pa-0" required @keyup="usercopytest" :rules="emailRules" placeholder="Enter New email" v-model="usernewemail">
                <template #prepend-inner>
                  <div style="width: 30px">
                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mail-box.svg" />
                  </div>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text " rounded block :loading="emailLoader" @click="sendButtonClicked">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <div v-if="verifyotpemail">
          <v-form ref="formemailveri">
            <v-card-title style="font-size: 16px">
              Verify Your new email
              <v-spacer></v-spacer>
              <v-btn icon @click="closeBtn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="ma-0 pa-0" style="font-weight: 499">New email id</p>
              <v-text-field class="pa-0" readonly v-model="usernewemail">
                <template #prepend-inner>
                  <div style="width: 30px">
                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mail-box.svg" />
                  </div>
                </template>
              </v-text-field>

              <p class="ma-0 pa-0" style="font-weight: 499">
                Enter 4 digit OTP sent to your e-mail.
                <span style="color: red">*</span>
              </p>
              <v-text-field class="pa-0" required type="number" hide-spin-buttons pattern="\d*" maxlength="4" :rules="otpRules" placeholder="00-00" v-model="useremailOtp">
                <template #prepend-inner>
                  <div style="width: 30px">
                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mobile-chat.svg" />
                  </div>
                </template>
              </v-text-field>
              <!-- <p class="ma-0 pa-0">OTP</p>
                                <v-otp-input
    length="4" plain style="height:40px"
  ></v-otp-input> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text " rounded block :loading="emailLoader" @click="otpButtonClicked">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <div v-if="adresschange">
          <v-card-title style="font-size: 16px">
            Address change request <v-spacer></v-spacer>
            <v-btn icon @click="closeBtn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <div class="mx-4 mb-4" v-if="adressOptions">
            <v-btn height="40px" color="#000" elevation="0" class="white--text text-none" block rounded @click="connectDigio()"> Aadhar (Digilocker) </v-btn>
          </div>

          <div class="mx-4 mb-2" v-if="adressOptions">
            <v-btn height="40px" elevation="0" outlined style="border: 1px solid #000 !important" rounded class="text-none" block @click="(adressOptions = false), (manual = true)"> Type manualy </v-btn>
          </div>

          <v-form v-if="manual" ref="formadress">
            <v-card-text>
              <p class="ma-0 pa-0 font-weight-medium txt-666">Old Address</p>

              <v-text-field readonly class="pa-0" v-model="profileData.CL_RESI_ADD1"></v-text-field>
              <p class="ma-0 pa-0 font-weight-bold">New Address <span style="color: red">*</span></p>

              <v-text-field class="pa-0" required :rules="addressRules" placeholder="New Address" v-model="usernewAdress"></v-text-field>

              <!-- PINCODE FOR MANUAL ADDRESS -->
              <v-row no-gutters>
                <v-col>
                  <v-list-item class="pa-0 pr-2">
                    <v-list-item-content class="pa-0">
                      <p class="ma-0 pa-0 font-weight-bold">Pincode <span style="color: red">*</span></p>
                      <v-text-field class="pa-0" hide-spin-buttons type="number" placeholder="PINCODE" :loading="pinLoader" v-model="pincodeAddress" @keyup="pincodeFetch()" :rules="nomineeField"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="pa-0 pr-2">
                    <v-list-item-content class="pa-0">
                      <p class="ma-0 pa-0 font-weight-bold">State <span style="color: red">*</span></p>

                      <v-text-field class="pa-0" :rules="nomineeField" placeholder="STATE" v-model="stateAddress"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item class="pa-0 pl-2">
                    <v-list-item-content class="pa-0">
                      <p class="ma-0 pa-0 font-weight-bold">District <span style="color: red">*</span></p>

                      <v-text-field class="pa-0" :rules="nomineeField" placeholder="DISTRICT" v-model="distAddress"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="pa-0 pl-2">
                    <v-list-item-content class="pa-0">
                      <p class="ma-0 pa-0 font-weight-bold">Country <span style="color: red">*</span></p>

                      <v-text-field class="pa-0" :rules="nomineeField" placeholder="COUNTRY" v-model="countryAddress"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <p class="ma-0 pa-0 font-weight-bold">Proof type <span style="color: red">*</span></p>

              <v-select class="pa-0" :items="prooftype" :rules="nomineeField" v-model="proofTypes" placeholder="Proof type"></v-select>

              <p class="ma-0 pa-0 font-weight-bold">Upload proof <span style="color: red">*</span></p>
              <v-card
                color="rgba(246, 247, 247, 0.5)"
                @ondragover="onChange"
                :class="dragging ? 'dropAreaDragging' : ''"
                @dragenter="dragging = true"
                @dragend="dragging = false"
                @dragleave="dragging = false"
                class="fileupload elevation-0 rounded-lg text-center pa-2"
                style="border: 1px dashed #d0d3d6"
              >
                <!-- <v-card class="pa-3" @click="openFileInput()"> -->
                <!-- <div align="center"> -->
                <!-- <p style="font-weight:499;font-size:15px;color:#0037B7"  class="ma-0"> Click here to upload</p> -->
                <!-- <p>You can drop your last 6 months Bank statement here to be uploaded</p> -->
                <div class="innerfilup">
                  <!-- <img class="mb-2" src="@/assets/file drop.svg" width="48px" /> -->
                  <p class="font-weight-bold fs-18" style="color: #0037b7 !important ; margin-bottom: 0em">Click here to upload</p>
                  <p class="mb-0 txt-666 font-weight-regular" style="line-height: 18px">You can drop your Address proof here to be uploaded</p>
                </div>
                <input type="file" id="items" accept=".pdf" name="items[]" required multiple @change="onChange" />

                <!-- </div> -->
              </v-card>
              <div v-if="panFile">
                <p>
                  <span class="txt-666">File names</span> :
                  <b>{{ panimagedata.name }}</b>
                </p>
                <div class="text-right mb-2">
                  <!-- <v-card width="100%" class="elevation-0 mb-16 mb-md-0" style="transform: scaleX(-1) !important">
                                <iframe style="border: none; border-radius: 8px;" :src="panimage" width="100%"
                                    height="240"></iframe>
                            </v-card> -->
                </div>
              </div>
              <!-- <v-file-input
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                class="pa-0"
                :rules="nomineeField"
                v-model="adressproffdoc"
                accept="image/*,.pdf"
                placeholder="Upload Proof"
              ></v-file-input> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text " rounded block :loading="addressLoader" @click="kraImageCheck">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--small {
  height: 0px;
  min-width: 0px;
  /* padding: 0 12.4444444444px; */
  padding-left: 12px;
  padding-right: 12px;

  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
<!-- <style>
.scroll-container,
  .scroll-container:hover,
  .scroll-container:focus { 
            
            overflow-y: scroll; 
        } 
        
  
        .scroll-container::-webkit-scrollbar { 
            width: 5px; 
        } 
  
        .scroll-container::-webkit-scrollbar-track { 
            background: #f1f1f1; 
        } 
  
        .scroll-container::-webkit-scrollbar-thumb { 
          background: #e5ebec; 
            border-radius: 10px;
        } 
  
        .scroll-container::-webkit-scrollbar-thumb:hover { 
            background: #555; 
        } 
  
        .scroll-container::-webkit-scrollbar-button { 
            display: none; 
        } 
</style> -->
<script src="https://app.digio.in/sdk/v11/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v11/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";
// import * as faceapi from 'face-api.js';
import axios from "axios";
export default {
  // name: "BarChart",

  components: {},
  data() {
    return {
      xsOnly: null,
      smAndDowm: null,
      txt: "",
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [(v) => !!v || "Enter Amount", (v) => (v && v >= 10) || "Should be above 10"],
      upiRules: [(v) => !!v || "Enter UPI ID", (v) => /.+@.+/.test(v) || "Invalid UPI ID"],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: ["Mother", "Father", "Brother", "Sister", "Daughter", "Wife", "Husband", "Son", "Grand-Son", "Grand-Father", "Grand-Daughter", "Grand-Mother"],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [(v) => !v || v.length <= 10 || "Enter a valid mobile number", (v) => !!v || "Mobile is required"],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,
      emailRules: [(v) => !/^\s/.test(v) || "E-mail cannot start with spaces", (v) => !!v || "E-mail is required", (v) => /.+@.+/.test(v) || "E-mail must be valid", (v) => !/\s/.test(v) || "Email cannot contain spaces"],
      addressRules: [(v) => !!v || "Address is required", (v) => !/^\s/.test(v) || "Address cannot start with spaces"],

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      confirmation: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,

      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      allSegments: ["BSE_CASH", "MF_BSE", "NSE_CASH", "BSE_FNO", "NSE_FNO", "CD_NSE", "CD_BSE", "BSE_COM", "NSE_COM", "ICEX", "MCX"],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      reasons: [
        "High brokerage and charges",
        "Monthly maintenance charges",
        "Need better margins",
        "Problem with payment and withdrawal",
        "Need better order placement options",
        "App crashes",
        "Issues with back office portals",
        "Problem with customer service",
        "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        {text: "My Account"},
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        {text: "Manage Funds", link: "secufund"},
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        {txt: "Yes, add nominee", val: "yes"},
        {txt: "No, i will do it later", val: "no"},
      ],
      bankprooftype: [
        {txt: "Saving Account", val: "Saving Account"},
        {txt: "Current Account", val: "Current Account"},
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: "",
      familyRequestData: "",
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: "",
      disabled: false,
      readonly: false,
      kraImgCapture: false,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      imageURL: "",
      adressObject: "",
      fileide: "",
      accountstatus: "",
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    rejectEsign() {
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
        file_id: this.fileide,
        type: this.editType,
      });

      let config = {
        method: "post",
        url: api.api_url + "/manual_cancel_request",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          this.confirmation = false;
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == "canceled successfully") {
            this.Succtext = "Canceled successfully";
            this.successSnackbar = true;
            this.fetchMobileStatus();
            location.reload();
          } else {
            this.errtext = response.data.msg;
            this.errorSnackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.", error);
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      // else {
      //   // console.log("Working fine")
      //   this.isCameraOpen = false;
      //   this.isPhotoTaken = false;
      //   this.isShotPhoto = false;
      //   this.stopCameraStream();
      // }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 350, 260);
      this.downloadImage();
    },

    downloadImage() {
      // const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
      this.imageURL = canvas;
      // download.setAttribute("href", canvas);
    },

    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = "";
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },
    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      // console.log(file["type"]);
      if (file["type"] == "image/jpeg" || file["type"] == "image/jpg" || file["type"] == "image/png" || file["type"] == "application/pdf") {
        this.panFile = file;
        this.panimage = window.URL.createObjectURL(this.panFile);
        this.panimagedata["name"] = this.panFile.name;
        this.panimagedata["size"] = (this.panFile.size / 1048576).toFixed(1);
        this.passHide();
      } else {
        this.cardloader = false;
        this.toasttext = "Only png, jpg, jpeg image formats are supported.";
        this.snackbar = true;
      }
    },

    openFileInput() {
      // console.log("click input",this.$refs.fileInput.$refs.input.click())
      // Programmatically trigger the click event of the hidden input
      this.$refs.fileInput.$refs.input.click();
    },

    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`);
      // console.log('sso');
    },
    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (this.usernewemail.toUpperCase() == this.profileData.CLIENT_ID_MAIL.toUpperCase()) {
        this.usernewemail = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    },

    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // FOR LOCAL
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=692DFD73&state=" +
      //   stateid +
      //   "";

      // -----------------------------------------------------------------------------------------
      // FOR Production
      window.location.href = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" + stateid + "";

      // this.digioEsign(
      //               responceReq.fileid,
      //               responceReq.mailid,
      //               responceReq.session
      //             );
    },
    percentcalc() {
      // console.log('press')
      if (Number(this.newNomineepercentone) > Number(this.max)) {
        this.newNomineepercentone = this.max;
      } else if (Number(this.newNomineepercentone) < 0) {
        this.newNomineepercentone = 0;
      }
    },
    percentcalcnto() {
      // console.log('press')
      // var p1 = this.newNomineepercent
      // var p2 = this.newNomineepercentnto
      // var balance =  Number(p1) - Number(p2)
      // var d = Number(this.max) - this.newNomineepercentnto
      // if (Number(this.max) - this.newNomineepercent){
      var d = Number(this.max) - this.newNomineepercentone;
      this.secnompercentmax = d;
      // this.newNomineepercentnto = d
      // }
      if (Number(this.newNomineepercentnto) > Number(this.secnompercentmax)) {
        this.newNomineepercentnto = this.secnompercentmax;
      } else if (Number(this.newNomineepercentnto) < 0) {
        this.newNomineepercentnto = 0;
      }
    },
    percentcalcnthre() {
      // console.log('press')
      var d = Number(this.max) - (Number(this.newNomineepercentone) + Number(this.newNomineepercentnto));
      this.thernompercentmax = d;

      if (Number(this.newNomineepercentnthre) > Number(this.thernompercentmax)) {
        this.newNomineepercentnthre = this.thernompercentmax;
      } else if (Number(this.newNomineepercentnthre) < 0) {
        this.newNomineepercentnthre = 0;
      }
    },
    selectOptionBtn() {
      // console.log("cjsjsakcnsioun", this.yesorno);
      if (this.yesorno == "yes") {
        this.nomineeForm = true;
      } else if (this.yesorno == "no") {
        this.nomineeForm = false;
      }
    },
    pincodeFetch() {
      var axiosThis = this;
      // console.warn("hh");
      // if (this.newNomineePincode.length >= 6) {
      //   let config = {
      //     method: "get",
      //     url: "https://api.postalpincode.in/pincode/" + this.newNomineePincode,
      //     headers: {},
      //   };

      //   axios
      //     .request(config)
      //     .then((response) => {
      //       axiosThis.pinLoader = false;

      //       // var resp = response.data[0].PostOffice[0];
      //       axiosThis.newNomineeState = resp.State;
      //       axiosThis.newNomineeCountry = resp.Country;
      //       axiosThis.newNomineeCity = resp.Division;

      //       // console.log(JSON.stringify(response.data));
      //     })
      //     .catch((error) => {
      //       axiosThis.pinLoader = false;

      //       console.log(error);
      //     });
      // }
      if (this.pincodeAddress.length >= 6) {
        this.pinLoader = true;
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.pincodeAddress,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.stateAddress = resp.State;
            axiosThis.countryAddress = resp.Country;
            axiosThis.distAddress = resp.District;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincode.length >= 6) {
        this.pinLoader = true;
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineeGuardianPincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianState = resp.State;
            axiosThis.newNomineeGuardianCountry = resp.Country;
            axiosThis.newNomineeGuardianCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodento.length >= 6) {
        this.pinLoader = true;
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineeGuardianPincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatento = resp.State;
            axiosThis.newNomineeGuardianCountrynto = resp.Country;
            axiosThis.newNomineeGuardianCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodenthre.length >= 6) {
        this.pinLoader = true;
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineeGuardianPincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatenthre = resp.State;
            axiosThis.newNomineeGuardianCountrynthre = resp.Country;
            axiosThis.newNomineeGuardianCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineePincodento.length >= 6) {
        this.pinLoader = true;
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineePincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatento = resp.State;
            axiosThis.newNomineeCountrynto = resp.Country;
            axiosThis.newNomineeCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.newNomineePincodenthre.length >= 6) {
        this.pinLoader = true;
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineePincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatenthre = resp.State;
            axiosThis.newNomineeCountrynthre = resp.Country;
            axiosThis.newNomineeCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
    },
    digioEsign(file, email, session) {
      var axo = this;
      this.blurLoader = true;
      axo.editdialog = false;
      // console.log(file, email,session)
      // localStorage.setItem('fileid', this.digioFile)
      var options = {
        environment: "production",
        callback: function (t) {
          // axo.statusCheckNominee(axo.client_code);
          // console.log(t);
          // console.log(axo.stata);
          axo.blurLoader = false;

          if (axo.stata != undefined) {
            if (axo.stata.app_status == "e-signed pending") {
              axo.radioBtn = true;
              axo.nomineesubmitBtn = true;
            } else {
              axo.radioBtn = false;
              axo.nomineesubmitBtn = false;
            }
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }

          if (t.message == "Signing cancelled") {
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("failure", file);
            axo.fetchMobileStatus();

            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()

            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()
            // }
          } else if (t.message == "Signed Successfully") {
            // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("success", file);
            // }
          }

          if (t["hasOwnProperty"]("error_code")) {
            // document.getElementById("loading").style.display='none';
            //// console.log('Error Digio')
            //// console.log(t.message)
          } else {
            //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
            //successDigio();
            //// console.log(this.step, this.ekycStatus);
            setTimeout(function () {
              //code goes here
              window.location.reload();
            }, 4500);
          }
        },
      };

      var digio = new Digio(options);
      digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
      // digio.submit(this.digioFile, this.digioMail,);
      // console.log(file,email,session)
      digio.submit(file, email, session);
      // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

      // console.log(digio)
    },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          axiosThis.fetchMobileStatus()
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchMobileStatus() {
      this.blurLoader = true

      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          axiosThis.blurLoader = false

          // console.log(response.data);
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "clientid not exist") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }

          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;

          if (axiosThis.mobStatus.mobile_status == "e-signed pending" || axiosThis.mobStatus.address_status == "e-signed pending" || axiosThis.mobStatus.email_status == "e-signed pending") {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (axiosThis.mobStatus.address_status == "e-signed completed" || axiosThis.mobStatus.address_status == "e-signed pending") {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
          }
          if (axiosThis.mobStatus.mobile_status == "e-signed completed" || axiosThis.mobStatus.mobile_status == "e-signed pending") {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (axiosThis.mobStatus.email_status == "e-signed completed" || axiosThis.mobStatus.email_status == "e-signed pending") {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (axiosThis.mobStatus.income_status == "e-signed completed" || axiosThis.mobStatus.income_status == "e-signed pending") {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          axiosThis.blurLoader = false

          console.log(error);
        });
    },
    otpFileWrite() {
      var axiosThis = this;
      if (this.currentClick == "mobile") {
        axiosThis.requestotp = true;
        axiosThis.verifyotp = false;
        axiosThis.successSnackbar = true;
        axiosThis.mobileLoader = false;
        axiosThis.blurLoader = true;

        const date = new Date();
        const offset = date.getTimezoneOffset() == 0 ? 0 : -1 * date.getTimezoneOffset();

        let normalized = new Date(date.getTime() + offset * 60000);
        let indiaTime = new Date(
          normalized.toLocaleString("en-US", {
            timeZone: "Asia/Calcutta",
          })
        );

        // console.log("Time in India::", indiaTime.toISOString());
        var dateAndTime = indiaTime.toISOString();

        let data = JSON.stringify({
          pres_mobile_no: axiosThis.usernewmobile,
          client_id: axiosThis.client_code,
          prev_mobile_no: axiosThis.profileData.MOBILE_NO,
          client_name: axiosThis.name,
          client_email: axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
          dp_code: axiosThis.profileData.CLIENT_DP_CODE,
          date_time: dateAndTime,
        });

        let config = {
          method: "post",

          url: api.api_url + "/file_write_mob",
          headers: {
            Authorization: axiosThis.tok,
            clientid: axiosThis.profileData.CLIENT_ID,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            // axiosThis.digioEsign(responceReq.fileid, responceReq.mailid)
            var responceReq = response.data;
            if (responceReq.fileid && responceReq.mailid) {
              axiosThis.fetchMobileStatus()
              // axiosThis.digioEsign(responceReq.fileid, responceReq.mailid, responceReq.session);
            } else {
              axiosThis.blurLoader = false;
              axiosThis.successSnackbar = false;
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = responceReq;
            }
          })
          .catch((error) => {
            console.log(error);
          });

        axiosThis.Succtext = "OTP Verified";
        axiosThis.editdialog = false;
        axiosThis.usernewmobile = "";
        axiosThis.userOtp = "";
      }
    },
    emailOtpfileWrite() {
      var axiosThis = this;
      // if(this.currentClick == 'email') {

      axiosThis.blurLoader = true;
      // axiosThis.requestotp = true;

      axiosThis.successSnackbar = true;
      axiosThis.verifyotp = false;
      axiosThis.requestotp = true;
      axiosThis.userOtp = "";
      axiosThis.usernewmobile = "";
      (axiosThis.usernewemail = ""), (axiosThis.requestotpemail = false), (axiosThis.useremailOtp = ""), (axiosThis.verifyotpemail = false);
      axiosThis.Succtext = "OTP Verfified Sucessfully";
      // axiosThis.connectDigio()

      const date = new Date();
      const offset = date.getTimezoneOffset() == 0 ? 0 : -1 * date.getTimezoneOffset();

      let normalized = new Date(date.getTime() + offset * 60000);
      let indiaTime = new Date(
        normalized.toLocaleString("en-US", {
          timeZone: "Asia/Calcutta",
        })
      );

      // console.log("Time in India::", indiaTime.toISOString());
      var dateAndTime = indiaTime.toISOString();

      let data = JSON.stringify({
        client_id: axiosThis.client_code,
        client_email: axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
        dp_code: axiosThis.profileData.CLIENT_DP_CODE,
        date_time: dateAndTime,
        present_email: axiosThis.userEmailadress.toUpperCase(),
        previous_email: axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
        client_name: axiosThis.name,
      });
      axiosThis.blurLoader = true;

      let config = {
        method: "post",

        url: api.api_url + "/file_write_email",
        headers: {
          Authorization: axiosThis.tok,
          clientid: axiosThis.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          var responceReq = response.data;
          if (responceReq.fileid && responceReq.mailid) {
            axiosThis.fetchMobileStatus()
            // axiosThis.digioEsign(responceReq.fileid, responceReq.mailid, responceReq.session);
          } else {
            axiosThis.successSnackbar = false;

            axiosThis.blurLoader = false;
            axiosThis.errorSnackbar = true;
            axiosThis.errtext = responceReq;
          }
        })
        .catch((error) => {
          axiosThis.blurLoader = false;

          console.log(error);
        });

      axiosThis.editdialog = false;
      // }
    },
    digiFileWrite() {
      var axiosThis = this;

      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;
      this.editType = "address_change";
      const FormData = require("form-data");
      let data = new FormData();
      data.append("file", "");
      data.append("proff", "");
      data.append("cur_address", "");
      data.append("ext_address", this.profileData.CL_RESI_ADD1);
      data.append("dp_code", this.profileData.CLIENT_DP_CODE);
      data.append("client_id", this.client_code);
      data.append("client_name", this.name);
      data.append("client_email", this.profileData.CLIENT_ID_MAIL.toUpperCase());
      data.append("aadhar_address", "");
      data.append("adr_manual", "aadhar");
      data.append("code", token);
      data.append("state", state);

      // let config = {
      //   method: 'post',
      //   //
      //   url: api.api_url+'/adr_chn',
      //   headers: {
      //   },
      //   data : data
      // };

      // axios.request(config)
      axios
        .post(api.api_url + "/adr_chn", data, {
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          axiosThis.adresschange = false;
          axiosThis.editdialog = false;
          if (response.data.fileid) {
            axiosThis.fetchMobileStatus()
            // axiosThis.digioEsign(response.data.fileid, response.data.mailid, response.data.session);
          } else {
            axiosThis.errorSnackbar = true;
            axiosThis.errtext = "Error in Server, please try again later";
          }
          axiosThis.$router.replace("/");
          // window.location.assign(window.location.href.split('?')[0])

          // const query = this.$route.query;
          //   delete query.code;
          //   delete query.state;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    kraImageCheck() {
      // let params = (new URL(document.location)).searchParams;
      // let token = this.aadharcode;
      // let state = this.aadharstate;
      let data = JSON.stringify({
        client_id: this.client_code,
      });
      var axiosThis = this;
      let config = {
        method: "post",
        url: api.api_url + "/checking_img",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data.data == "image found") {
            if (this.currentClick == "mobile") {
              this.otpFileWrite();
            } else if (this.currentClick == "email") {
              this.emailOtpfileWrite();
            } else if (this.currentClick == "address") {
              // console.log('i am in')
              if (this.manual) {
                this.manualAdressSubmit();
              } else {
                this.digiFileWrite();
              }
            }
          } else {
            axiosThis.verifyotp = false;
            axiosThis.kraImgCapture = true;
            axiosThis.verifyotpemail = false;
            axiosThis.adresschange = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    otpButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var valid = this.$refs.formm.validate();
        if (valid == true) {
          // console.log("josi");
          this.mobileLoader = true;
          // this.blurLoader = true

          var data = JSON.stringify({
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            mobile_otp: this.userOtp,
            prev_mobile_no: this.profileData.MOBILE_NO,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
            dp_code: this.profileData.CLIENT_DP_CODE,
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", enc, dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });

          let config = {
            method: "post",
            // url: api.api_url+"/mob_otp_ver",
            url: api.api_url + "/mob_otp_ver",

            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              axiosThis.mobileLoader = false;

              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              var responceReq = response.data;
              // console.log(response.data.message);
              if (responceReq.msg == "otp valid") {
                axiosThis.kraImageCheck();
              } else {
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "otp not valid";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        //// console.log("email otp verify", this.$refs.emailform);
        var emailvalid = this.$refs.formemailveri.validate();
        if (emailvalid == true) {
          // this.blurLoader = true

          // console.log("josi");
          let data = JSON.stringify({
            client_id: this.client_code,
            present_email: this.userEmailadress.toUpperCase(),
            emailotp: this.useremailOtp,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpverify",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // console.log(response.data.msg);
              if (response.data.msg == "otp valid") {
                axiosThis.kraImageCheck();
                // @click="kraImageCheck"
              } else {
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Invalid OTP";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      // console.log("submit clik");
    },

    checkKraandsentOTP() {
      let token = this.aadharcode;
      let state = this.aadharstate;
      var axiosThis = this;
      if (this.kraImgCapture) {
        if (this.isPhotoTaken) {
          const canvas = document.getElementById("photoTaken");
          // const blob = new Blob(canvas);
          this.imageURL = canvas.toBlob(
            (blob) => {
              // do something with the blob
              this.imageURL = blob;
              // console.log(blob,"//..//..");
              let data = new FormData();
              data.append("image", blob);
              data.append("client_id", this.client_code);

              let config = {
                method: "post",
                url: api.api_url + "/img_upload",
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  // console.log(JSON.stringify(response.data));
                  if (response.data.data == "image saved") {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.isShotPhoto = false;
                    this.stopCameraStream();
                    this.kraImgCapture = false;
                    this.editdialog = false;
                    if (this.currentClick == "mobile") {
                      this.otpFileWrite();
                    } else if (this.currentClick == "email") {
                      this.emailOtpfileWrite();
                    } else if (this.currentClick == "address") {
                      if (this.adressObject) {
                        this.manualAdressSubmit();
                      } else if (token && state) {
                        this.digiFileWrite();
                      }
                    }
                  } else {
                    axiosThis.errtext = response.data.data;
                    axiosThis.errorSnackbar = true;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            },
            "image/jpeg", // image format
            0.8 // image quality
          );
          // const imagew = URL.createObjectURL(blob);
          //  var imagew = this.blobToJpg(canvas,0.95)
        } else {
          this.errtext = "Kindly take a selfi";
          this.errorSnackbar = true;
        }
      }
    },
    sendButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var emailvalid = this.$refs.form.validate();
        if (emailvalid == true) {
          this.mobileLoader = true;

          this.cardLoader = true;
          // console.log("josi");
          var dall = JSON.stringify({
            prev_mobile_no: this.profileData.MOBILE_NO,
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });
          let data = dall;

          let config = {
            method: "post",
            url: api.api_url + "/mob_otp_req",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              axiosThis.mobileLoader = false;

              var responceReq = response.data;
              axiosThis.cardLoader = false;
              // console.log(JSON.stringify(response.data));
              if (responceReq.msg == "otp sent") {
                axiosThis.requestotp = false;
                axiosThis.verifyotp = true;

                axiosThis.Succtext = "OTP Sent";
                axiosThis.successSnackbar = true;
              } else if (responceReq.msg == "already this mobile number used") {
                axiosThis.usernewmobile = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              } else if (responceReq.msg == "This number already linked to another account") {
                axiosThis.usernewmobile = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = responceReq.msg;
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        var valid = this.$refs.formemailreq.validate();
        if (valid == true) {
          this.emailLoader = true;
          this.cardLoader = true;
          // console.log("email");
          this.userEmailadress = this.usernewemail;
          let data = JSON.stringify({
            client_id: this.client_code,
            previous_email: this.profileData.CLIENT_ID_MAIL,
            present_email: this.usernewemail.toUpperCase(),
            client_name: this.name,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpsend",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // axiosThis.cardLoader = false;
              axiosThis.emailLoader = false;

              // console.log(JSON.stringify(response.data));
              if (response.data.msg == "success send mail") {
                axiosThis.requestotpemail = false;
                axiosThis.verifyotpemail = true;
              } else if (response.data.msg == "Mobile already exists") {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              } else {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = response.data.msg;
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
    },
    manualAdressSubmit() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      var addressvalid = this.$refs.formadress.validate();
      if (addressvalid == true) {
        this.addressLoader = true;

        const FormData = require("form-data");
        let data = new FormData();
        this.adressObject = JSON.stringify({
          address: this.usernewAdress,
          pincode: this.pincodeAddress,
          state: this.stateAddress.toUpperCase(),
          dist: this.distAddress.toUpperCase(),
          country: this.countryAddress.toUpperCase(),
        });
        data.append("file", this.panFile);
        data.append("proff", this.proofTypes);
        data.append("cur_address", this.adressObject);
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append("client_email", this.profileData.CLIENT_ID_MAIL.toUpperCase());
        data.append("aadhar_address", "");
        data.append("adr_manual", "manual");
        data.append("code", "");
        data.append("state", "");

        // let config = {
        //   method: 'post',
        //   //
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.blurLoader = false;

            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              axiosThis.fetchMobileStatus()
              // axiosThis.digioEsign(response.data.fileid, response.data.mailid, response.data.session);
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            axiosThis.addressLoader = false;
          })
          .catch((error) => {
            console.log(error);
            axiosThis.addressLoader = false;
          });
      }
    },
    digilockerAdressSubmit(a, b) {
      // var axiosThis = this;
      // console.log("inn", a, b);
      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;

      // var url_data = this.$route.params;
      // console.log(token,"poiuytrewqqqqqqasdfghjkllkmnbvcxz");

      // var addressvalid = this.$refs.formadress.validate();
      if (token && state) {
        // console.log("inn if");

        this.editType = "address_change";
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", "");
        data.append("proff", "");
        data.append("cur_address", "");
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append("client_email", this.profileData.CLIENT_ID_MAIL.toUpperCase());
        data.append("aadhar_address", "");
        data.append("adr_manual", "aadhar");
        data.append("code", token);
        data.append("state", state);

        // let config = {
        //   method: 'post',
        //   //
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        let axiosThis = this;
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data), response, 'DIGI');
            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              axiosThis.fetchMobileStatus()
              // axiosThis.digioEsign(response.data.fileid, response.data.mailid, response.data.session);
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            // axiosThis.$router.replace("/");
            // window.location.assign(window.location.href.split('?')[0])
            // const query = this.$route.query;
            //   delete query.code;
            //   delete query.state;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // console.log("inn else", token, state, a, b);
      }
    },
    paswordverificationBtn() {
      // this.annualincomeOtpReq()
      this.tok = localStorage.getItem("usession");

      // var valid = this.$refs.incomeupdate.validate();
      // console.log(valid)
      if (this.passwordRequired == true) {
        // var fd = this.$refs.incomeupdate.validate();
        // console.log("<<<<<<<<<<<<<",fd)
        var axiosThis = this;
        // this.passwordField = false;
        const FormData = require("form-data");
        let data = new FormData();
        data.append("client_id", this.client_code);
        data.append("password", this.pdfPassword);
        data.append("proff_file", this.panFile);

        let config = {
          method: "post",
          url: api.api_url + "/pdf_password_check",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            if (response.data["password required"] == "In-Correct") {
              axiosThis.pdfPassword = "";
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Incorrect Password";

              axiosThis.passwordField = true;
              axiosThis.paswordverification = true;
            } else {
              axiosThis.annualincomeOtpReq();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.panFile && this.selectionas == 4) {
        this.annualincomeOtpReq();
      } else if (!this.panFile && this.selectionas !== 4) {
        this.annualincomeOtpReq();
      } else {
        this.errorSnackbar = true;
        this.errtext = "Upload proof";
      }
    },
    checkSame() {
      if (this.profileData.MOBILE_NO == this.usernewmobile) {
        this.usernewmobile = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new Mobile number";
      }
    },
    ifscFetch(ifsc) {
      var axiosThis = this;
      this.bankIfscCode = this.bankIfscCode.toUpperCase();
      if (ifsc.indexOf(" ") >= 0) {
        // console.log("contains spaces");
        this.bankIfscCode = ifsc.replace(/\s+/g, "");
      }
      if (this.bankIfscCode.length >= 11) {
        let config = {
          method: "get",
          url: "https://ifsc.razorpay.com/" + ifsc,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            axiosThis.ifscBankInfo = response.data;
          })
          .catch((error) => {
            axiosThis.ifscBankInfo = [];
            console.log(error);
          });
      }
    },
    incomeproof() {
      if (this.selectionas == 4) {
        this.proofField = true;
      } else {
        this.proofField = false;
        this.passwordField = false;
        this.incorrectPass = false;
      }
    },

    passHide() {
      // let file = e.target.files[0] || e.dataTransfer.files[0]
      //           this.panFile = file;

      var axiosThis = this;
      this.passwordField = false;
      const FormData = require("form-data");
      let data = new FormData();
      data.append("client_id", this.client_code);
      data.append("proff_file", this.panFile);

      let config = {
        method: "post",
        url: api.api_url + "/pdf_lock",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data["password required"] == "True") {
            axiosThis.passwordField = true;
            axiosThis.passwordRequired = true;
            // axiosThis.paswordverification = true
          } else {
            axiosThis.passwordRequired = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    mobileEdit(data) {
      this.tok = localStorage.getItem("usession");

      if (data == "mobile") {
        this.currentClick = "mobile";
        this.editType = "mobile_change";
        // this.cardTitle = 'Mobile Number Verification'
        this.subTitle = "Enter your new mobile number";
        this.requestotp = true;
        this.requestotpemail = false;
        this.bankchange = false;
        this.editdialog = true;

        this.annualIncomechange = false;
        this.mobileLoader = false;

        this.nosegmentsPop = false;
        this.segments = false;

        this.adresschange = false;
        // this.addressVerification = false;
      }
      if (data == "email") {
        this.currentClick = "email";
        this.editType = "email_change";
        this.requestotpemail = true;
        this.requestotp = false;
        this.editdialog = true;

        this.nosegmentsPop = false;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.addressVerification = false;
        this.adresschange = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Enter your new email number";
      }
      if (data == "address") {
        this.currentClick = "address";
        this.editType = "address_change";
        this.adressOptions = true;
        this.manual = false;
        this.nosegmentsPop = false;
        this.editdialog = true;

        this.requestotp = false;
        this.requestotpemail = false;
        this.adresschange = true;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      this.kraImgCapture = false;
    },
    getAvailBal() {
      var axiosThis = this;

      axiosThis.blurLoader = false;
      axiosThis.profileLoader = false;
      // console.log(response)

      axiosThis.equty = [];
      axiosThis.derivat = [];
      axiosThis.commoditi = [];

      // console.log(response.data.str)
      var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
      // console.log("pppp",JSON.parse(res));
      var resp = JSON.parse(res);
      axiosThis.profileData = resp.client_data;
      axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
      axiosThis.BankData = resp.bank_data;

      axiosThis.name = axiosThis.profileData.CLIENT_NAME;
      // console.log(axiosThis.name)
      localStorage.setItem("client_name", axiosThis.name);
      //   if(typeof axiosThis.name == 'string'){
      // }
      // axiosThis.$router.push("/");

      axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

      if (axiosThis.profileData.Nominee_Name) {
        // axiosThis.multinominee = resp.
        axiosThis.nomineeYes = true;
        axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
        axiosThis.newNomineeRelation = axiosThis.profileData.Nominee_Relation;
        axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
        // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
      }
      axiosThis.fetchMobileStatus();

      axiosThis.profileData.CL_RESI_ADD1 = axiosThis.profileData.CL_RESI_ADD1 + " " + axiosThis.profileData.CL_RESI_ADD2 + " " + axiosThis.profileData.CL_RESI_ADD3;
      for (var i = 0; i < axiosThis.profileData.segments_data.length; i++) {
        // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE);
        if (axiosThis.profileData.segments_data[i].COMPANY_CODE == "BSE_CASH" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "MF_NSE" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "NSE_CASH" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "MF_BSE") {
          axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
        }
        if (axiosThis.profileData.segments_data[i].COMPANY_CODE == "NSE_FNO" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "BSE_FNO" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "CD_BSE" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "CD_NSE") {
          axiosThis.derivat.push(axiosThis.profileData.segments_data[i]);
        }
        if (axiosThis.profileData.segments_data[i].COMPANY_CODE == "BSE_COM" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "NSE_COM" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "MCX" || axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX") {
          axiosThis.commoditi.push(axiosThis.profileData.segments_data[i]);
        }
      }

      axiosThis.digilockerAdressSubmit();
    },
    chipp() {
      this.chha = !this.chha;
    },
    getCardColor(index) {
      // Custom logic to determine the card color based on the index or other conditions
      if (index % 2 === 0) {
        return "#ECF9EF"; // Example: Blue color for even index cards
      } else {
        return "#FAEAEA"; // Example: Green color for odd index cards
      }
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""), (this.requestotpemail = false), (this.useremailOtp = ""), (this.verifyotpemail = false), (this.stateAddress = ""), (this.countryAddress = ""), (this.distAddress = ""), (this.pincodeAddress = "");
      (this.usernewAdress = ""), (this.adressproffdoc = null), (this.bankIfscCode = ""), (this.bankAccountnumber = ""), (this.bankproofTypes = ""), (this.bankproffdoc = null), (this.defaultCheckbox = false);

      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },
  },
  computed: {
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [(value) => !!value || "OTP Required", (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits"];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [(value) => !!value || "email id is required", (value) => /.+@.+\..+/.test(value) || "Email must be valid"];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    // this.loadModelsAndStartVideo();
    this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var error = params.get("error");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    this.accountstatus = localStorage.getItem("clientStatus");
    // console.log("jiji", vendorAcc, vendorToken);

    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
      // this.digilockerAdressSubmit(cod, stat);
    }

    if (error) {
      this.errorSnackbar = true;
      this.errtext = params.get("error_description");
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const {y} = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>
