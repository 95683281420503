<template>
  <v-app>
    <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
      <v-alert v-if="alert" dense outlined type="success" style="position: fixed; top: 8; right: 0; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <v-alert v-if="alert1" dense outlined type="error" style="position: fixed; top: 8; right: 0; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <div class=" pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
        <v-col cols="12" class="pl-7">
          <v-card class="mt-7 elevation-0">
            <v-toolbar class="mb-3 toolbar-contantd px-md-3" elevation="0" color="white">
              <v-list-item class="pl-0 pl-md-1 ">
                <v-list-item-content>
                  <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                   PDF Download</v-list-item-title>
                  <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Financial
                    transactions and details within a single Click.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-spacer></v-spacer> -->



              <v-menu class="" v-model="menu2" :close-on-content-click="false" transition="scale-transition" left offset-x
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field style="width: 400px;" class="mr-2 mt-4" solo rounded flat v-model="fromshow" label="Date"
                    dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="minval" :max="maxval" color="black" v-model="fromdate"></v-date-picker>
                <v-date-picker :max="maxval" :min="minval" color="black" v-model="todate" @change="contractget()"></v-date-picker>
              </v-menu>
              <v-select :items="filtertype" @change="filterArray(filtervalue)" dense v-model="filtervalue" label="filter"
                solo rounded flat background-color="#F1F3F8" hide-details class="fildrop mt-n2"
                style="max-width: 140px; font-size: 21px">


              </v-select>
              <v-text-field v-model="search" class=" ml-2 mt-4" height="36px" background-color="#F1F3F8"
                style="min-width: 180px; " label="Search " solo rounded flat dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>
            </v-toolbar>
            <v-divider></v-divider>

            <!-- <div class=" mt-5 d-flex justify-space-between px-md-3" elevation="0" color="white">

              <div class="d-flex">
                <v-select style="width: 150px;" dense background-color="#F1F3F8" rounded @change="getledger(yearis)"
                :items="segmentitem" v-model="segmenttypevalue" flat solo class="  mr-2  elevation-0 rounded-pill  "
                label="Segment" prepend-inner-icon="mdi mdi-segment" hide-details append-icon="mdi-chevron-down"></v-select>
              <v-select label="Report Type" style="width: 180px;" dense background-color="#F1F3F8" rounded @change="getledger(yearis)"
                :items="reporttypeitem" v-model="reporttypeval" flat solo class="  mr-2 elevation-0 rounded-pill" 
                prepend-inner-icon="mdi mdi-file-chart-outline" hide-details append-icon="mdi-chevron-down">
              </v-select>
                
              <v-select style="width: 160px;" dense background-color="#F1F3F8" rounded @change="getledger(yearis)"
                :items="yearsitem" v-model="yearis" flat solo class=" dropdown  elevation-0 rounded-pill  " label="From"
                prepend-inner-icon="mdi-calendar" hide-details append-icon="mdi-chevron-down"></v-select>
              
              </div>
              <div class="d-flex">
                <v-menu class="" v-model="menu2" :close-on-content-click="false" transition="scale-transition" left
                  offset-x min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="width: 170px;" class="mr-2 " solo rounded flat v-model="fromshow" label="Date"
                      dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="black" v-model="fromdate"></v-date-picker>
                  <v-date-picker color="black" v-model="todate" @change="menu23 = false"></v-date-picker>
                </v-menu>
                <v-btn @click="contractget()" rounded color="black" class="text-capitalize" dark>
                  Get Report
                </v-btn>

              </div>


            </div> -->
            <!-- <v-btn @click="takeScreenshot">take</v-btn> -->
            <div id="target-element">
              <v-row>
                <v-col cols="8">
                  <v-dialog v-model="dialoguorder" width="570">
                    <v-card class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                      </div>
                      <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <div class="d-flex mx-8 mt-2 mb-2">
                        <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">0</p>
                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">
                          <v-col cols="3">
                            <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <v-text-field @keyup="ordervalcalculate()" type="number" :min="minBidQuantity"
                              :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense
                              background-color="#F1F3F8" v-model="bidvalueuser" style="width: "
                              height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                              minPrice }} - ₹{{ maxPrice }}</span></p>

                            <v-text-field @change="bidlessmore()" @keyup="ordervalcalculate()"
                              :disabled="pricetextdisabled" type="number" :min="minPrice" :max="maxPrice" step="1"
                              label="Bid Qty" solo rounded flat dense background-color="#F1F3F8" v-model="pricevalueuser"
                              style="width: " class="ml-2" height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2" @change="cutoffadd()"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                        <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                          <v-col>

                            <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                            <v-text-field label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
                              v-model="upiidtype" style="width: " height="36px"></v-text-field>


                          </v-col>
                          <!-- <v-col> -->
                          <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                          <!-- </v-col> -->
                        </v-row>
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div class="ma-2">
                            <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ inverstvalue }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                            <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn>
                            <v-btn @click="orderapply()" class="  ma-2  text-capitalize white--text" solo text rounded
                              flat style="background-color: black;"> Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card>

                  </v-dialog>
                  <v-bottom-sheet v-model="orderformobile" inset>
                    <v-sheet class="">
                      <v-card class="">
                        <v-list-item-content class=" pt-5 pl-5">
                          <v-list-item-title class="text-start"
                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                              issuetype }}
                          </v-list-item-title>

                          <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                          <!-- <v-btn absolute right top icon color="black" @click="orderformobile = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn> -->
                        </v-list-item-content>
                        <v-divider></v-divider>

                        <div class="d-flex mx-8 mt-2 ">
                          <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                            class="mb-0">{{ syname }} </p>
                          <p style="color: #000000;font-size: 12px"
                            class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                        </div>
                        <p style="color: #000000;font-size: 14px;"
                          class="mb-1 font-weight-regular ml-8 caption grey--text">{{ biddingStartDate }} to {{
                            biddingEndDate }}</p>
                        <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                        <div class="d-flex mx-8 mt-2 mb-2">
                          <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                          <p style="color: #000000;font-size: 12px"
                            class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">0</p>
                        </div>
                        <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                        <div class=" mx-8">
                          <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                          <v-row class="">
                            <v-col cols="12">
                              <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                              }}</span></p>
                              <v-text-field @keyup="ordervalcalculate()" type="number" :min="minBidQuantity"
                                :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense
                                background-color="#F1F3F8" v-model="bidvalueuser" style="width: "
                                height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="12" class="mt-n5">
                              <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                                minPrice }} - ₹{{ maxPrice }}</span></p>

                              <v-text-field @keyup="ordervalcalculate()" :disabled="pricetextdisabled" type="number"
                                :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense
                                background-color="#F1F3F8" v-model="pricevalueuser" style="width: " class="ml-2"
                                height="36px"></v-text-field>
                              <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-n5 ml-2"
                                @change="cutoffadd()" v-model="checkbox" label="Cutoff Price"></v-checkbox>

                            </v-col>
                          </v-row>


                          <v-row v-if="issuetype == 'RIGHTS'" class="mt-n7">
                            <v-col>

                              <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                              <v-text-field label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiidtype" style="width: " height="36px"></v-text-field>


                            </v-col>
                            <!-- <v-col> -->
                            <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                            <!-- </v-col> -->
                          </v-row>
                          <v-divider class="mx-2"></v-divider>
                          <div class="d-flex">
                            <div class="ma-2">
                              <p class="mb-0  grey--text" style="font-size: 10px">Required amount to buy Bid</p>
                              <p class="mb-0 body-1 black--text"> ₹ {{ inverstvalue }}</p>
                            </div>
                            <div class="ml-auto d-flex mt-2">
                              <v-btn class="ma-2 text-capitalize" small outlined rounded color="blaack"
                                @click="orderformobile = false">Cancel</v-btn>
                              <v-btn @click="orderapply()" small class="  ma-2  text-capitalize white--text" solo text
                                rounded flat style="background-color: black;"> Apply</v-btn>
                            </div>

                          </div>






                        </div>

                      </v-card>
                    </v-sheet>
                  </v-bottom-sheet>

                </v-col>
              </v-row>
              <!-- for multiple select downloads -->
              <!-- fixed-header v-model="selected" show-select item-key="recno" :single-select="singleSelect" -->
              <v-data-table fixed-header :search="search" hide-default-footer :loading="loader" height="495px"
                mobile-breakpoint disable-sort :headers="Overviewheaders" :items="contractdatatable"
                :items-per-page="itemperpage" style="border: 1px solid #efeef3;" class="elevation-0 rounded-lg mt-n4">


                <template v-slot:[`item.recno`]="{ item }">
                  <span class="font-weight-bold" style="text-align: right">
                    {{ item.recno }}</span>
                </template>
                <template v-slot:[`item.DocDate`]="{ item }">
                  <span class="font-weight-bold" style="text-align: right">
                    {{ item.DocDate }}</span>
                </template>
                <template v-slot:[`item.GenerateDate`]="{ item }">
                  <span class="font-weight-bold" style="text-align: right">
                    {{ item.GenerateDate }}</span>
                </template>
                <template v-slot:[`item.DocType`]="{ item }">
                  <span class="font-weight-bold" style="text-align: right">
                    {{ item.DocType }}</span>
                </template>
                <template v-slot:[`item.DocFileName`]="{ item }">
                  <span @click="downloadcontractnote(item)" class="font-weight-bold"
                    style="text-align: right; color: rgb(0, 55, 183); cursor: pointer;">
                    {{ item.DocFileName }}</span>
                </template>

                <template v-slot:no-data>
                  <v-col class="mx-auto pa-15 mt-5">
                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                    <p class="font-weight-bold black--text">
                      No data available
                    </p>
                  </v-col>
                </template>
                <!-- <template v-slot:footer>
                                                  <v-divider></v-divider>
                                                  <div class="text-center" v-if="more">
                                                      <v-btn text color="primary" dark class="ma-2 text-none"
                                                          @click="morebtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                              more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                      </v-btn>
                                                  </div>
                                                  <div class="text-center" v-if="less">
                                                      <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                          @click="lessbtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                              Show Less</span> <v-icon color="black">
                                                              mdi-chevron-up</v-icon>
                                                      </v-btn>
                                                  </div>
                                              </template> -->
              </v-data-table>

              <!-- <div class="display-flex row d-none d-md-block pa-8">
                  <v-row>
                    <v-col>
                      <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                        <p class="pt-1 mb-3" style="
                          font-weight: 450;
                          font-size: 16px;
                          color: #000000;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          Realised P&L
                        </p>
                        <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                        <div class="display-flex row pt-6 pl-2">
                          <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                            <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl < 0" style="
                              color: red;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disrealised_pnl.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl > 0" style="
                              color: green;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disrealised_pnl.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl == 0" style="
                              color: black;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disrealised_pnl.toFixed(2) }}
                            </p>
                          </v-card>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                        <p class="pt-1 mb-3" style="
                          font-weight: 450;
                          font-size: 16px;
                          color: #000000;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          Unrealised P&L
                        </p>
                        <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                        <div class="display-flex row pt-6 pl-2">
                          <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                            <p class="mb-0" v-if="disunrealised_pnl && disunrealised_pnl < 0" style="
                              color: red;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disunrealised_pnl.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl > 0" style="
                              color: green;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disunrealised_pnl.toFixed(2) }}
                            </p>
  
                            <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl == 0
                                                    " style="
                              color: black;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disunrealised_pnl.toFixed(2) }}
                            </p>
                          </v-card>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                        <p class="pt-1 mb-3" style="
                          font-weight: 450;
                          font-size: 16px;
                          color: #000000;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          Total P&L
                        </p>
                        <v-progress-linear style="width: 30px" v-if="segloader" indeterminate
                          color="blue"></v-progress-linear>
                        <v-divider v-else-if="!segloader" class="mt-2" style="border: solid px #000000" width="30px"
                          color="#000000"></v-divider>
                        <div class="display-flex row pt-6 pl-2">
                          <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                            <p class="mb-0" v-if="segval && segval < 0" style="
                              color: red;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ segval.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-else-if="segval && segval > 0" style="
                              color: green;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ segval.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-else-if="segval && segval == 0" style="
                              color: black;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ segval.toFixed(2) }}
                            </p>
                          </v-card>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col>
                     
                      <div v-if="!loader">
                        <p class="mt-2 mb-3" style="
                          font-weight: 450;
                          font-size: 14px;
                          color: #4b4b4b;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          <v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
                          P&L verified by Zebu
                         
                        </p>
                      
                        <v-row>
                          <v-col cols="7" class="pa-0 pl-3">
  
                           
                            <p class="mt-2 mb-0 " style="font-size: 12px">Share to Everyone</p>
                            <div class="d-flex mt-n1">
                              <v-switch color="black" @change="switchbtn" class="mt-1" v-model="agreeforshare" inset dense></v-switch>
                              <v-btn class="mt-2 px-3"  disabled  rounded x-small v-if="!agreeforshare">
                                <v-icon class="" size="16">mdi mdi-share-variant</v-icon>
                              </v-btn>
                              <v-btn dark class="mt-2 px-3" rounded x-small v-else @click="shareto">
                                <v-icon color="white" class="" size="16">mdi mdi-share-variant</v-icon>
                              </v-btn>
  
                            </div>
                          </v-col>
  
                        </v-row>
  
                      </div>
                     
                    </v-col>
                  </v-row>
                </div> -->

            </div>
          </v-card>
        </v-col>
      </div>
      <div class="d-md-none">
        <v-toolbar class="mt-6 mb-1 px-2 toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent">
            <v-list-item class="pt-0 px-0">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                      <path
                        d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                        fill="#C8C8C8" />
                      <path
                        d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                        fill="#C8C8C8" />
                      <path
                        d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                        fill="#363636" />
                      <path
                        d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                        fill="#E3E3E3" />
                      <path
                        d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                        fill="#43A6DD" />
                      <path
                        d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                        fill="#43A6DD" />
                      <path
                        d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                        fill="#43A6DD" />
                      <path
                        d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                        fill="#43A6DD" />
                      <path
                        d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                        fill="#363636" />
                    </svg> -->
              <v-list-item-content class="pt-0">
                <v-list-item-title class="text-start" style="
                      color: #000000;
                      font-size: 20px;
                      font-weight: 559;
                      line-height: 16px;
                      letter-spacing: -1px;
                    ">
                PDF Download
                </v-list-item-title>

                <v-list-item-subtitle class="text-start pt-1" style="
                      color: #666666;
                      font-size: 12px;
                      font-weight: 469;
                      line-height: 14px;
                    ">Financial
                  transactions and details within a single Click.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>

          <v-list class="pt-0" style="background-color: transparent">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px">
              <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
              <!-- height="16px" /> -->
            </v-list-item>
            <!-- <v-list-item-content class="pa-0 pt-1">
                    <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                      recommended</v-list-item-title>
                  </v-list-item-content> -->
          </v-list>
        </v-toolbar>
        <v-divider class="mx-2 mb-2"></v-divider>
        <v-row class="mx-2">
          <v-col cols="6">
            <v-text-field style="width: 100%;" v-model="search" class="tophundraedmutual   mt-3  " height="24px"
              background-color="#F1F3F8" label="Search" solo text-align flat rounded dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="16px" />
              </template>
            </v-text-field>

          </v-col>
          <v-col cols="6">
            <v-select :items="filtertype" @change="filterArray(filtervalue)" dense v-model="filtervalue" label="filter"
              solo rounded flat background-color="#F1F3F8" hide-details class="fildrop mt-3 "
              style="max-width: 100%; font-size: 21px">


            </v-select>
          </v-col>
          <v-col cols="12" class="mt-n6">
            <v-menu class="" v-model="menu" :close-on-content-click="false" transition="scale-transition" left offset-x
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field style="width: 400px;" class="mr-2 " solo rounded flat v-model="fromshow" label="Date" dense
                  color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker :min="minval" :max="maxval" color="black" v-model="fromdate"></v-date-picker>
                <v-date-picker :max="maxval" :min="minval" color="black" v-model="todate" @change="contractget()"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-card v-if="filteredDesserts.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in filteredDesserts " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
          class="elevation-0  px-4" width="100%">
          <div class="d-flex row pa-4">
            <p class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ item.DocType }}
            </p>

          </div>

          <v-divider class="mb-2"></v-divider>
          <v-row class="px-2">
            <v-col cols="9">

              <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item.DocDate }}


              </p>
              <p @click="downloadcontractnote(item)" class="mb-3 body-2 font-weight-regular"
                style="color: rgb(0, 55, 183);">{{ item.DocFileName }}
              </p>


            </v-col>
            <v-col class="text-end pa-0 pt-2" cols="3">
              <!-- <span class="font-weight-regular" >{{ item.GenerateDate }}</span> -->



            </v-col>
          </v-row>
        </v-card>


        <!-- </v-tab-item> -->
        <!-- <v-tab-item>
                          <v-card v-for="item in filteredDesserts1 " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                              class="elevation-0  px-4" width="100%">
  
                              <p v-if="item.COMPANY_CODE == 'NSE_FNO'" class="text-start pa-2 pb-0 mb-0"
                                  style="color: #000000;font-size: 15px;font-weight:459;">{{ item.SCRIP_SYMBOL }}
                              </p>
                              <p v-else-if="item.COMPANY_CODE == 'BSE_CASH' || item.COMPANY_CODE == 'NSE_CASH'"
                                  class="text-start pb-0 pa-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                  {{ item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}
                              </p>
                              <span class="font-weight-regular pt-0 caption text-start pa-2 mb-0"> {{ item.TRADE_DATE
                              }}</span>
                              <v-divider class="mb-2"></v-divider>
  
                              <v-row class="px-2">
                                  <v-col>
                                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount:
                                      </p>
                                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BQTY
                                              }}</span>
                                      </p>
                                      <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                  (item.BRATE.toFixed(2)) }}</span>
                                      </p>
  
  
                                  </v-col>
                                  <v-col class="text-end">
                                      <p v-if="item.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">
                                          {{ item.NETAMT }}</p>
                                      <p v-if="item.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                          item.NETAMT }}</p>
                                      <p v-if="item.NETAMT == 0" class="mb-0 body-2 font-weight-regular"
                                          style="color: #000000;">
                                          {{ item.NETAMT }}</p>
  
                                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SQTY }}
  
                                          </span>
                                      </p>
                                      <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                  (item.SRATE).toFixed(2) }}
                                          </span>
                                      </p>
  
  
                                  </v-col>
                              </v-row>
                          </v-card>
                          <v-card v-if="filteredDesserts1.length == 0 && loader == false"
                              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                              class="elevation-0  px-4" width="100%">
                              <v-row class="px-2">
                                  <v-col class="mx-auto text-center my-4">
                                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                      <p class="font-weight-bold black--text">No data available</p>
                                  </v-col>
                              </v-row>
                          </v-card>
                          <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                              class="elevation-0  px-4" width="100%">
                              <v-row class="px-2">
                                  <v-col class="mx-auto text-center my-4">
                                      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-tab-item>
                  </v-tabs-items> -->
      </div>
    </v-container>
  </v-app>
</template>
  
<script>
// import CalendarProfitlose from '@/views/heatmap calendars/CalendarProfitlose.vue';
// import { apiurl } from "../../../Api.js";
// import { apidailypnl } from "../../../Api.js";
// import exportFromJSON from "export-from-json";
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
// import html2canvas from 'html2canvas';
import { apiurl } from '../../../Api.js'



export default {
  data() {
    return {
      segmenttypevalue: '',
      reporttypeval: '',
      bidvalueuser: '',
      yearis: '',
      yearsitem: [],
      segmentitem: ['All', 'Eqities', 'Future & Options', 'Commodities', 'Currencies'],
      reporttypeitem: ['All', 'ACCOUNT OPENING LETTER', 'ANNUAL GLOBAL TRAN.STATEMENT', 'BILL', 'CLIENT CONFIRMATION', 'CLIENT INTIMATION', 'COLLATERAL CONFIRMATION', 'CONTRACT', 'BALANCE CONFIRMATION', 'CONTRACT AUCTION',
        'CONTRACT BUYBACK', 'CONTRACT CLOSEOUT', 'Downloads T-DAY', 'DETAIL CONFIRMATION', 'GST INVOICE', 'LEDGER DETAILS', 'DEBIT NOTE', 'MARGIN REPORT', 'MTF MARGIN REPORT',
        'QUARTERLY SECURITIES LEDGER', 'RETENTION REPORT', 'BALANCE CONFIRMATION', 'CLIENT FUND LEDGER', 'SECURITY REGISTER'],
      // yearsitem : [],
      pricevalueuser: '',
      dialogmobile: false,
      dialoguorder: false,
      username: '',
      segval: 0,
      summary: true,
      radios: null,
      radios1: null,
      inject: {
        theme: {
          default: { isDark: false },
        },
      },
      itemss: [],
      filtervalue: 'All',
      filtertype: [],
      search: "",
      searchde: "",
      expanded: [],
      more: true,
      less: false,
      more1: true,
      less1: false,
      itemperpage: 8,
      // msg: "",

      // alert: false,

      sheet: false,
      sheet1: false,
      sheetforshare: false,
      itemperpage1: 8,
      loader: false,
      headerval: [],
      dialog: false,
      dialogcopytext: false,
      detailedpoparray: [],

      yearindex: "",
      search1: "",

      itemperpage1inner: 0,
      // tab: null,
      // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
      desserts: [],
      dessertsoriginal: [],
      tab1: null,
      tab2: null,
      tab3: null,
      tab4: null,
      tab5: null,
      tab6: null,
      scriptname: "",
      dessertspop: [],
      dessertspopexpense: [],
      desserts2: [],
      uniqueCode: '',
      sortBy: "SCRIP_NAME",
      offset: "",
      segfrom: "",
      dates: [""],
      filterr: [
        "All",
        "Equities",
        "Future & Options",
        "Commodities",
        "Currencies",
      ],
      filterdata_unpl: "",
      orderformobile: false,
      filterdata_unpl1: "All",
      dessertsbuyback: [],
      dessertsdelist: [],
      dessertstake: [],
      dessertsofs: [],
      dessertstrights: [],
      itemsdwl: [
        {
          title: "Download Excel",
        },
        {
          title: "Download Pdf",
        },
      ],
      show: false,
      alert: false,
      alert1: false,
      msg: '',
      Overviewheaders2: [
        {
          text: "Trade Date",
          value: "TRADE_DATE",
          width: "10%",
          class: "headerfont",
        },

        // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },

        {
          text: "Buy Qty",
          value: "BQTY",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Buy Rate",
          value: "BRATE",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'Buy Amt', value: 'BAMT', align: "end" },
        {
          text: "Sell Qty",
          value: "SQTY",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Sell Rate",
          value: "SRATE",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'Sale Amt', value: 'SAMT', align: "end" },
        {
          text: "Net Qty",
          value: "NETQTY",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Net Rate",
          value: "NRATE",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'NET Amt', value: 'NETAMT', align: "end" },
        // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
        {
          text: "P & L",
          value: "NETAMT",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
      ],
      finaltotal: 0,
      singleSelect: false,
      selected: [],
      Overviewheaders: [
        // { text: "Recno", value: "recno", class: "headerfont" },
        // { text: "com", value: "Sort_No", class: "headerfont", },
        // { text: "comcode", value: "COMPANY_CODE", class: "headerfont", },
        // { text: '', value: 'data-table-expand', class: "headerfont" },
        // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
        // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
        //   {
        //   text: "Exchange",
        //   value: "type",
        //   align: "start",
        //   class: "headerfont",
        // },

        // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
        {
          text: "Doc Date",
          value: "DocDate",
          align: "start",
          class: "headerfont",
        },
        // {
        //   text: "Generate Date",
        //   value: "GenerateDate",
        //   align: "start",
        //   class: "headerfont",
        // },
        {
          text: "Doc Type",
          value: "DocType",
          align: "start",
          class: "headerfont",
        },
        // // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
        // {
        //   text: "Record Date",
        //   value: "rpnl",
        //   align: "end",
        //   class: "headerfont",
        // },
        // { text: "Net Rate", value: "", align: "end", class: "headerfont" },
        // { text: "Close price", value: "", align: "end", class: "headerfont" },
        // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
        // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        // {
        //   text: "Cut Off Time",
        //   value: "NOT_PROFIT",
        //   align: "end",
        //   class: "headerfont",
        // },
        {
          text: "Doc FileName",
          value: "DocFileName",
          align: "start",
          class: "headerfont",
        },

      ],
      tab: null,
      items: ["Summary", "Detailed"],
      desserts23: [],
      sortKey: "Sort_No",
      detpop: [],
      actid: "",
      susertoken: "",
      loaderpopup: false,
      keyyy: "",
      downsum: true,
      disrealised_pnl: "",
      disunrealised_pnl: "",
      year: "",
      dessertstaxdis: [],
      menu2: false,
      menu: false,
      menu23: false,
      todate: "",
      fromdate: "",
      segloader: false,
      fromshow: "",
      sortOrder: 1, // 1 for ascending, -1 for descending
      originalrea: 0,
      originalunrea: 0,
      maxval: '',
      switchval: '',
      agreeforshare: false,
      shareresdata: [],
      itemsyear: [],
      text1: 'kgghkhkhh',
      postalready: null,
      postalreadystats: '',
      postalreadyucode: '',
      switch1: true,
      switch2: false,
      issuetype: '',
      opendatetime: '',
      closedatetime: '',
      cutOffPrice: '',
      maxbidqty: 0,
      minBidQuantity: 0,
      syname: '',
      biddingEndDate: '',
      biddingStartDate: '',
      dailyStartTime: '',
      dailyEndTime: '',
      minPrice: '',
      filterdummyarray: [],
      maxPrice: '',
      minval : '',
      steplot: 0,
      checkbox: false,
      pricetextdisabled: false,
      inverstvalue: '',
      apisymbol: '',
      // inverstvalue : '',
      apitype: '',
      upiidtype: '',
      upiproviderselect: '',
      toshow: '',
      fromdateapi: '',
      contractdatatable: [],
      upiprovider: ["@allbank",
        '@aubank',
        '@axisbank',
        '@axl',
        '@barodampay', '@bandhan',
        '@citi',
        '@citigold', '@dbs',
        '@dlb',
        '@federal',
        '@freecharge',
        '@hsbc',
        '@icici',
        '@idbi',
        '@indus',
        '@ibl',
        '@kbl',
        '@kotak',
        '@okaxis',
        '@okhdfcbank',
        '@okicici',
        '@oksbi',
        '@paytm', '@rbl', '@sbi',
        '@sib',
        '@upi',
        '@uco',
        '@ybl', '@yesbank',
        '@pz',
        '@axisb',
        '@zoicici',
        '@wasbi',
        '@wahdfcbank',
        '@waaxis',
        '@waicici',
        '@idfcbank', '@timecosmos',
        '@tapicici', '@sliceaxis', '@shriramhdfcbank',
        '@pingpay', '@NIYOICICI', '@naviaxis', '@ikwik', '@goaxb',
        '@jupiteraxis', '@yesg', '@abfspay', '@abfspay', '@rapl', '@yapl', '@apl', '@slice', '@mbk', '@pockets', '@idfcpay', '@amazonpay', '@spicepay', '@omni', '@dhani', '@bpaywallet', '@trans', '@fam', '@pinelabs', '@digikhata', '@oxymoney',




      ],


    };
  },
  // components: { CalendarProfitlose },
  computed: {
    // sortedItems() {
    //   return this.dessertsoriginal.slice().sort((a, b) => {
    //     const modifier = this.sortOrder;
    //     return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
    //   });
    // },
    // dateRangeText() {
    //   return this.dates.join(" ~ ");
    // },
    filteredDesserts() {
      const searchTerm = this.search.toLowerCase();
      return this.contractdatatable.filter((item) =>
        item.DocType.toLowerCase().includes(searchTerm)
      );
    },
    // filteredDesserts1() {
    //   const searchTerm = this.searchde.toLowerCase();
    //   return this.desserts23.filter((item) =>
    //     item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm)
    //   );
    // },
  },
  created() {
    // Perform default sort on component creation

  },
  methods: {
    sortByDate() {
      this.contractdatatable.sort((a, b) => {
        const dateA = new Date(a.DocDate.split('/').reverse().join('/'));
        const dateB = new Date(b.DocDate.split('/').reverse().join('/'));
        return dateB - dateA; // Keep this line for ascending order
      });
    },
    filterArray(filtervalue) {
      // this.selectedTag = 'All'
      var data = this.filterdummyarray
      // expense_amt
      // unrealised_pnl
      // realised_pnl

      // const dataval = this.desserts
      // const data2 = this.desserts
      // const data3 = this.desserts
      var seg = filtervalue
      // // //   //console.log("sssss", seg);
      // Assuming this.filtertype is an array of possible values for seg

      // Check if seg is a valid value in this.filtertype
      if (this.filtertype.includes(seg)) {
        if (seg == 'All') {
          this.contractdatatable = data
        } else {
          this.contractdatatable = data.filter(item => item.DocType === seg);

        }
        // Use filter method to create a new array with matching items
      } else {
        // Handle the case where seg is not a valid value
        console.error('Invalid value for seg:', seg);
      }


      // if (seg == 'Margin Statement') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'Margin Statement') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // } else if (seg == 'Contract') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'Contract') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // } else if (seg == 'Weekly Statement') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'Weekly Statement') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // }else if (seg == 'Retention Report') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'Retention Report') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // }
      // else if (seg == 'AGTS Report') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'AGTS Report') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // }else if (seg == 'Other Statement') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'Other Statement') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // }else if (seg == 'Ledger Detail') {
      //   this.contractdatatable = []
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].DocType == 'Ledger Detail') {
      //       this.contractdatatable.push(data[i])
      //     }
      //   }

      // }
      // else if (seg == 'All') {
      //   this.contractdatatable = data
      // }
    },
    downloadcontractnote(item) {
      // console.log(this.selected)
      // console.log(item,"klklklklklk");
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.actid,
        "recno": item.recno
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.112:5050/downloaddoc',
        url: `${apiurl}/downloaddoc`,

        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(response.headers['content-disposition']);
          // let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
          // let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
          // console.log(response,"lklklklklklklk");
          // 
          if(response.headers['content-type'] != 'application/json'){
            // alert("ghjkl;")
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${item.DocFileName.split(".")[0]}.zip`; // You can customize the downloaded file name
            // a.download = 'downloaded_file.zip'; // You can customize the downloaded file name

            document.body.appendChild(a);

            // Trigger a click on the link to start the download
            a.click();

            // Remove the link from the DOM
            document.body.removeChild(a);
          }else{
            axiosthis.alert1 = true
            axiosthis.msg = 'No data found'
            setTimeout(() => {
              axiosthis.alert1 = false
            }, 3000);
          }
          // console.log(`${filename}.${extension}`)
          // console.log(JSON.stringify(response.hasAccept));
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    contractget() {
      this.filtervalue = 'All'
      this.menu2 = false
      this.menu = false
      // console.log(this.fromdate,this.todate,'datedatedate')
      if (this.fromdate != '' && this.todate != '') {
        this.contractdatatable = []
        this.loader = true
        var fromdate = ''
        var todate = ''
        let dateString = this.fromdate
        let dateObject = new Date(dateString);
        let year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
        let day = dateObject.getDate();
        let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
        fromdate = formattedDate
        this.fromshow = fromdate
        this.segfrom = fromdate
        this.fromdateapi = fromdate
        let dateString1 = this.todate
        let dateObject1 = new Date(dateString1);
        let year1 = dateObject1.getFullYear();
        let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
        let day1 = dateObject1.getDate();
        let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
        todate = formattedDate1
        this.toshow = todate
        this.fromshow = this.fromshow + '_' + 'to' + '_' + this.toshow
        // this.equitiesdata()
        // this.derivativesdat()
        
        const axios = require('axios');
        let data = JSON.stringify({
          "cc": this.actid,
          "from": this.fromdateapi,
          "to": this.toshow
        });
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          // url: 'http://192.168.5.112:5050/getdocdownloads',
          url: `${apiurl}/getdocdownloads`,

          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            if (response.msg == 'No Data Available') {
              axiosthis.loader = false
             
            } else {
              // console.log(response.data.msg);
              axiosthis.loader = false
              // console.log(JSON.stringify(response.data));
              axiosthis.contractdatatable = response.data
              axiosthis.filterdummyarray = response.data
              axiosthis.itemperpage = axiosthis.contractdatatable.length
              const tags = Array.from(new Set(this.contractdatatable.flatMap(item => item.DocType)));
              axiosthis.filtertype = ['All', ...tags];
              // console.log(axiosthis.filtertype);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // const axios = require('axios');

        // this.equitiesdata()
        // this.derivativesdat()

      }
    },
   
  },

  mounted() {

    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    this.loader = true
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    if (mm <= 3) {
      var yearmount = yyyy - 1
 
    } else {
      yearmount = yyyy
    }
 
    

    // var todayapi = dd + '/' + mm + '/' + yyyy;
    var fromapi = '01' + '/' + '04' + '/' + yearmount;
    this.fromdate = yearmount + '-' +  '04' + '-' + '01'

    this.minval = yearmount - 2 +  '-' + '04' + '-' +'01'  ;

    var today1 = dd + '/' + mm + '/' + yyyy;
    if (mm < 3) {
      var maxyearval = yyyy 
    } else {
      maxyearval = yyyy
    }
    this.maxval =   maxyearval + '-' + mm + '-' + dd
    this.toshow = today1
    this.fromshow = fromapi
    this.segfrom = fromapi
    this.fromdateapi = fromapi
    this.filterdata_unpl1 = "All"
    this.filterdata_unpl = "All"

    this.fromshow = (this.fromshow) + '_' + 'to' + '_' + (this.toshow)
        // let dateString2 = fromapi
        // let dateObject2 = new Date(dateString2);
        // let year2 = dateObject2.getFullYear();
        // let month2 = dateObject2.getMonth() + 1; // Months are 0-indexed, so add 1
        // let day2 = dateObject2.getDate();
        // console.log(year2,month2,day2,'datedate');
        // console.log(year2 + '-' + (month2 < 10 ? '0' : '') +month2+ '-' + (day2 < 10 ? '0' : '') + day2 ,"datadatdadatdadt");
        // let formattedDate2 =  year2 + '-' + (month2 < 10 ? '0' : '') +month2+ '-' + (day2 < 10 ? '0' : '') + day2 
        // console.log(formattedDate2,'datedate');
        // let dateString3 = this.toshow
        // let dateObject3 = new Date(dateString3);
        // let year3 = dateObject3.getFullYear();
        // let month3= dateObject3.getMonth() + 1; // Months are 0-indexed, so add 1
        // let day3 = dateObject3.getDate();
        // let formattedDate3 =         year3 + '-' + (month3 < 10 ? '0' : '') +month3+ '-' + (day3 < 10 ? '0' : '') + day3 
        // console.log(formattedDate3,'datedate');
        // this.todate = formattedDate3
    const axios = require('axios');
    let data = JSON.stringify({
      "cc": this.actid,
      "from": this.fromdateapi,
      "to": this.toshow
    });
    let axiosthis = this
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      // url: 'http://192.168.5.112:5050/getdocdownloads',
      url: `${apiurl}/getdocdownloads`,

      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response.msg == 'No Data Available') {
          axiosthis.loader = false
         
        }else{
          // console.log(JSON.stringify(response.data));
          axiosthis.contractdatatable = response.data
          axiosthis.sortByDate();
          axiosthis.loader = false

          axiosthis.filterdummyarray = response.data
          axiosthis.itemperpage = axiosthis.contractdatatable.length
          const tags = Array.from(new Set(this.contractdatatable.flatMap(item => item.DocType)));
          axiosthis.filtertype = ['All', ...tags];
          // console.log(axiosthis.filtertype);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // if (mm < 3) {
    //     var yearmount = currentYear 
    // } else {
    //     yearmount = currentYear
    // }
  },
};
</script>
  
<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}


input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #020202 !important;
}

.v-tab {
  text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
  /* flex: 1 0 auto; */
  max-width: none !important;
}
</style>
