<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="mx-3 px-1">
      <!-- <div class="py-md-7  d-block d-md-none "></div> -->
      <div class="px-md-3">

        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Account Closure</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">If you close your account, you won’t be able to trade with Zebu.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>
        <!-- <p class="font-weight-bold fs-22 mb-0">Account Closure</p>
        <p class="subtitle-2 txt-666">If you close your account, you won’t be able to trade with Zebu.</p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="py-md-6 px-md-3 mt-6 mb-6" elevation="0" :outlined="$vuetify.breakpoint.smAndDown ? false : true"
        width="100%">
        <v-card rounded="lg" v-if="mobStatus.closure_status == 'e-signed pending'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p class="d-none d-md-block" style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Account close request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
                digioEsign(
                  mobStatus.closure_fileid,
                  mobStatus.closure_client_email,
                  mobStatus.closure_session
                ),
                (editType = 'closure')
                ">
                Click here esign</span>
              <span @click="editType = 'closure', fileide = mobStatus.closure_fileid, confirmation = true" class="mt-1 mr-2"
                style="color:red;cursor: pointer;float:right">Cancel request</span>
            </p>

            <div class="d-md-none">
              <p  style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Account close request is not yet Completed,
              </p>
              <div class="d-flex">
                <span style="color: #0037b7; cursor: pointer" @click="
                digioEsign(
                  mobStatus.closure_fileid,
                  mobStatus.closure_client_email,
                  mobStatus.closure_session
                ),
                (editType = 'closure')
                ">
                Click here esign</span>

                <span @click="editType = 'closure', fileide = mobStatus.closure_fileid, confirmation = true" class="ml-auto mt-1 mr-2"
                style="color:red;cursor: pointer;float:right">Cancel request</span>
              </div>
            </div>
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.closure_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Account close request is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>

        <p v-if="mobStatus.closure_status != 'e-signed pending' &&
          mobStatus.closure_status != 'e-signed completed'
          " class="subtitle-1 txt-666 font-weight-medium">
          Would you like to close your account?
        </p>
        <v-row class="mt-2" no-gutters>
          <v-col>
            <v-btn :disabled="accountstatus == 'client_close'" v-if="mobStatus.closure_status != 'e-signed pending' &&
              mobStatus.closure_status != 'e-signed completed'
              " :block="$vuetify.breakpoint.xsOnly ? true : false" elevation="0" color="black white--text" rounded
              :loading="emailLoader" @click="deactivateConfirmation = true">
              <span class="text-none px-8">Click here to Initiate</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <p class="subtitle-2 txt-666 mt-4">
        *Clear your ledger debit if any to proceed with the account closure
      </p>
    </div>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false,closebutton()" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-dialog v-model="confirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-2">
        <v-card-title class="pr-2">Cancel request? <v-spacer></v-spacer>
          <v-btn icon @click="confirmation = false,closebutton()">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <v-card-text>
          Are you sure you want to cancel your <b>"{{ this.editType }}"</b> request?
        </v-card-text>
        <v-card-actions class="pb-4">


          <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
          <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign">
            <span class="text-none ">Proceed</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deactivateConfirmation" persistent :fullscreen="$vuetify.breakpoint.xsOnly"
      :max-width="addNewSeg ? '500' : '440'" min-width="390"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'">
 
      <v-card :loading="loaderPop" class="rounded-lg px-2 px-md-4">
        <v-form v-if="deactivateForm" ref="deactivateform">
          <v-card-title>  Account Closure ?<v-spacer></v-spacer> <v-btn icon @click="deactivateConfirmation = false,closebutton()">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title>

          <v-card-text class="pb-2">
            Are you sure you want to Deactivate your account
            <b>"{{ profileData.CLIENT_ID }}"?</b> <br /></v-card-text>

          <v-card-text class="py-0">
            <v-select :items="reasons" :rules="nomineeField" outlined rounded dense v-model="closureReason"
              label="Reason"></v-select>
          </v-card-text>
          <v-card-actions class="pb-4 mx-2">
            <!-- <v-row no-gutters> -->
            <!-- <v-col>
                <v-btn
                  block
                  color="grey darken-1"
                  text
                  @click="deactivateConfirmation = false"
                >
                  Cancel
                </v-btn>
              </v-col> -->
            <!-- <v-col> -->
            <v-btn block color="black white--text " :disabled="closureReason == ''" rounded :loading="marginload" @click="marginbalance_check(),check_holding()">
              submit
            </v-btn>
            <!-- </v-col> -->
            <!-- </v-row> -->
          </v-card-actions>
        </v-form>
        <v-form v-if="deactivateCanceled">
          <v-card-title> Account Closure ? </v-card-title>
          <v-card-text>
            You have a ledger balance of <b> Rs {{ ammountleader }} </b> in your <b>"{{ profileData.CLIENT_ID }}"</b>. <br> Please settle the outstanding amount so we can proceed further.
         </v-card-text>

            <v-alert class="mb-0 mt-2 py-1"  dense  
               elevation="0">
              <p style="font-size:14px;" class="text-center mb-0">Insufficient balance, Add fund
                <a :href="`https://fund.mynt.in/?uid=${actid}&token=${susertoken}`"><b>Click here</b></a>
              </p>
            </v-alert>
          <v-card-actions class="pb-4">
            <v-row no-gutters>
              <v-col>
                <v-btn block color="black" class="mt-3 text-none" elevation="0" rounded
                  @click="deactivateConfirmation = false, canceledData = '', deactivateCanceled = false, deactivateForm = true,closebutton()">
                 <span style="color:#fff">Close</span> 
                </v-btn>
              </v-col>

            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

<v-dialog v-model="marginposive" persistent max-width="380" >

  <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>

  <v-card  class="rounded-lg px-2 pa-3 px-md-4">
      

          <p class="title"> Account Closer? </p>

          <div v-if="holdingres != 'yes' || blatranyesno != 'zero ledger balance'" >
       

          <p>
            <!-- You currently have a ledger balance of   in your account  <b>"{{ profileData.CLIENT_ID }}"</b>. Kindly proceed to transfer this balance amount to another account. -->
            Please withdraw your available balance of <b> " Rs {{ ammountleader }} " </b> before submitting your closure request."

            </p>


        

            <v-alert class="mb-0 mt-2 py-1"  dense  
               elevation="0">
              <p style="font-size:14px;" class="text-center mb-0">Click here to 
                <a :href="`https://fund.mynt.in/withdrawal?uid=${actid}&token=${susertoken}`"><b>withdraw Your Amount</b></a>
              </p>
            </v-alert>
            <v-card-actions class="pb-4">
            <v-row no-gutters>
              <v-col>
                <v-btn block color="black" class="mt-3 text-none" elevation="0" rounded
                  @click="marginposive = false, canceledData = '',closebutton()">
                 <span style="color:#fff">Close</span> 
                </v-btn>
              </v-col>

            </v-row>
          </v-card-actions>
           
          </div>


            <div  v-if="holdingres == 'yes' && zerobal == 'some holdings' && blatranyesno == 'zero ledger balance'">

              <p class="subtitle-2">
            <!-- You currently have a ledger balance of   in your account  <b>"{{ profileData.CLIENT_ID }}"</b>. Kindly proceed to transfer this balance amount to another account. -->
           Kindly transfer  your stocks to another demat account according to your wish  
          </p>


            <p>Please enter your another Demat account details </p>

              <v-text-field  maxlength="8" 
              v-model="dpcode1"  label="DP ID"></v-text-field>  
                  <v-text-field  maxlength="8" @keyup="dpcodevalide()"
              v-model="dpcode"  label="BO ID"></v-text-field>
            
                 
              
              
              <p class="ma-0 pa-0 caption" style="font-weight: 499">
                 Attach You CMR Copy. <span style="color: red">*</span>

                
              </p>
             <span class="caption mt-2">Attach a copy of the CMR that is either digitally signed or sealed, and physically signed by the respective DP</span>

              <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''"
                @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false"
                class="fileupload elevation-0 rounded-lg pa-2" style="border: 1px dashed #d0d3d6">
             
                <div class="innerfilup">
               
                  <p class="font-weight-bold fs-18 mb-1" style="color: #0037b7 !important;margin-bottom: 0.1em;">
                    Click here to upload
                  </p>
                  <p class="mb-0 txt-666 font-weight-regular" style="line-height: 18px">
                    You can drop your CMR Copy here to be uploaded
                  </p>
                </div>
                <input type="file" id="items" accept=".pdf" name="items[]" required  @change="onChange" />
              </v-card>
              <div v-if="panFile">
                <p>
                  <span class="txt-666">File names</span> :
                  <b>{{ panimagedata.name }}</b>
                </p>
                <div class="text-right mb-2">
                  <!-- <v-card width="100%" class="elevation-0 mb-16 mb-md-0" style="transform: scaleX(-1) !important">
                                <iframe style="border: none; border-radius: 8px;" :src="panimage" width="100%"
                                    height="240"></iframe>
                            </v-card> -->
                </div>
              </div>



          <v-card-actions class="pb-4">
            <v-row no-gutters>
              <v-col>
                <v-btn block rounded color="#F1F3F8" class="  text-none"  elevation="0"
                  @click="marginposive = false,closebutton()">
                  Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn :loading="extradiaload" @click="deactivEapi()" :disabled="dpcode < 4 ||  dpcode1 < 4 || panFile == 0" block rounded color="black" class=" ml-2 text-none" elevation="0"
                >
                <span style="color: #ffffff;">Sumbit</span>  
                </v-btn>
              </v-col>

            </v-row>
          </v-card-actions>
        </div>
      </v-card>


</v-dialog>




  </div>
</template>
<script src="https://app.digio.in/sdk/v11/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v11/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      zerobal:'',
      holdingres:"",
      extradiaload:false,
      marginload:false,
      dpcode:'',
      dpcode1:'',
      avablafile:'',
      panFile:'',
      blatranyesno:'',
      marginposive:false,
      smAndDown: null,
      xsOnly: null,
      txt: "",
      loaderPop: false,
      canceledData: '',
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "Mother",
        "Father",
        "Brother",
        "Sister",
        "Daughter",
        "Wife",
        "Husband",
        "Son",
        "Grand-Son",
        "Grand-Father",
        "Grand-Daughter",
        "Grand-Mother",
      ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "closure",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      confirmation: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,
      typesOfimg: [
        "Latest 6 months Bank Statement",
        "Latest ITR Copy",
        "Latest 6 months salary slip",
        "DP holding statement as on date",
        "Net worth Certificate",
        "Copy of Form 16 in case of salary income",
      ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      allSegments: [
        "BSE_CASH",
        "MF_BSE",
        "NSE_CASH",
        "BSE_FNO",
        "NSE_FNO",
        "CD_NSE",
        "CD_BSE",
        "BSE_COM",
        "NSE_COM",
        "ICEX",
        "MCX",
      ],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      // reasons: [
      //   "High brokerage and charges",
      //   "Monthly maintenance charges",
      //   "Need better margins",
      //   "Problem with payment and withdrawal",
      //   "Need better order placement options",
      //   "App crashes",
      //   "Issues with back office portals",
      //   "Problem with customer service",
      //   "App is complicated to understand",
      //   "Faced losses",
      //   "No time to focus on trading",
      // ],
      reasons: [
        "High brokerage and charges",
        "Anunal maintenance charges",
        // "Need better margins",
        // "Problem with payment and withdrawal",
        // "Need better order placement options",
        // "App crashes",
        // "Issues with back office portals",
        // "Problem with customer service",
        // "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
        "Moving to other broker"
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        { text: "My Account" },
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        { text: "Manage Funds", link: "secufund" },
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Yes, add nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: '',
      familyRequestData: '',
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: '',
      disabled: false,
      readonly: false,
      fileide: '',
      ammountleader:'',
      accountstatus:""
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    dpcodevalide(){
// console.log('ddvvsvssvshs',this.profileData.CLIENT_ID.slice(-8));
if(this.profileData.CLIENT_DP_CODE.slice(-8) == this.dpcode){
  this.errorSnackbar = true
  this.errtext = "Please use a different DP ID since your own DP ID cannot be accepted."
  this.dpcode = ""

}
    },
    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      // console.log(file["type"]);
      if (
       
        file["type"] == "application/pdf"
      ) {
        this.panFile = file;
        this.panimage = window.URL.createObjectURL(this.panFile);
        this.panimagedata["name"] = this.panFile.name;
        this.panimagedata["size"] = (this.panFile.size / 1048576).toFixed(1);
        // this.passHide();
      } else {
        this.cardloader = false;
        this.toasttext = "Only png, jpg, jpeg image formats are supported.";
        this.snackbar = true;
      }
    },

closebutton(){
this.closureReason = '',
this.dpcode1 = '',
this.dpcode = '',
this.panFile = ''

},




    fetchMobileStatus() {
      this.blurLoader = true;
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          axiosThis.blurLoader = false;

          if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }

              if (response.data.emsg == "clientid not exist") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
          // console.log(response.data);
          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;

          // if(axiosThis.mobStatus.DDPI_status == "" || axiosThis.mobStatus.DDPI_status == ""){

          // }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
            // axiosThis.pdfdownloard = axiosThis.mobStatus.DDPI_e_signed_pdf_path 


          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          axiosThis.blurLoader = false;
          console.log(error);
        });
    },
    rejectEsign() {
      let data = JSON.stringify({
        "client_id": this.profileData.CLIENT_ID,
        "file_id": this.fileide,
        "type": this.editType
      });

      let config = {
        method: 'post',
        url: api.api_url + '/manual_cancel_request',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          this.confirmation = false
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == 'canceled successfully') {
            this.Succtext = 'Canceled successfully'
            this.successSnackbar = true
            this.fetchMobileStatus()
            // location.reload();
          } else {
            this.errtext = response.data.msg
            this.errorSnackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = ''
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },
    deletereqitem() {
      this.selectTableitem
      // console.log(this.selectTableitem)
      var axiosThis = this
      this.familystatusloading = true
      let data = JSON.stringify({
        "id": this.selectTableitem.id
      });

      let config = {
        method: "post",
        url: api.api_url + "/remove_family_member",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "id removed successfully") {
            axiosThis.familyrequest = false
            axiosThis.successSnackbar = true;
            axiosThis.Succtext = "Removed sucessfully";
          }
          // console.log(JSON.stringify(response.data));
          axiosThis.familystatusloading = false
          axiosThis.getFamily()
        })
        .catch((error) => {
          console.log(error);
        });

    },
    // getFamily() {
    //   var axiosThis = this
    //   this.familystatusloading = true
    //   let data = JSON.stringify({
    //     clientid: this.client_code,
    //   });

    //   let config = {
    //     method: "post",
    //     url: api.api_url + "/family_linked",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: data,
    //   };

    //   axios
    //     .request(config)
    //     .then((response) => {
    //       if (response.data.emsg == "client not linked to any id") {
    //         axiosThis.familyRequestData = []

    //       } else {
    //         if (response.data.accessable) {
    //           axiosThis.familyRequestData = response.data.accessable
    //         }
    //       }

    //       // console.log(JSON.stringify(response.data));
    //       axiosThis.familystatusloading = false
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    // },
    addFamilySubmit() {
      var axiosThis = this
      var valid = this.$refs.addFamilyform.validate();
      if (valid == true) {
        this.familyloader = true
        let data = JSON.stringify({
          clientid: this.client_code,
          memberid: this.memberId,
          pan: this.memberPan,
          mobile_no: this.memberMobile,
          clientname: this.name,
          relationship: this.newFamilyRelation,
        });
        this.mobileMember = this.memberMobile
        let config = {
          method: "post",
          url: api.api_url + "/send_link_request",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.familyloader = false
            if (response.data.emsg == "requested successfully") {
              axiosThis.familySubmission = true;
              axiosThis.Succtext = "A verification link has been sent in an SMS to";
              axiosThis.memberId = ''
              axiosThis.memberPan = ''
              axiosThis.memberMobile = ''
              axiosThis.newFamilyRelation = ''
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.emsg;

            }


            // console.log(JSON.stringify(response.data));
            axiosThis.getFamily()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },


    // fileinputcheck() {
    //   const dropContainer = document.getElementById('dropContainer');
    //   dropContainer.classList.add('drag-over');
    // },
    handleFiles(files) {
      // Handle the selected files here
      // console.log(files);
    },
    openFileInput() {
      // console.log("click input",this.$refs.fileInput.$refs.input.click())
      // Programmatically trigger the click event of the hidden input
      this.$refs.fileInput.$refs.input.click();
    },

marginbalance_check(){
  this.marginload = true
  const axios = require('axios');
let data = JSON.stringify({
  client_id: this.client_code
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: api.api_url + "/check_closure",

  // url: 'https://rekycuat.mynt.in/check_closure',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};
var axiosThis = this
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
  axiosThis.blatranyesno = response.data.msg1
  axiosThis.ammountleader = response.data.balance
  axiosThis.zerobal = response.data.msg2

  if(response.data.msg1 == 'negative legeder balance' && response.data.stat == 'Not Ok'){
    axiosThis.canceledData =
                "You have ledger balance in your account.";
                axiosThis.marginload = false

    axiosThis.deactivateForm = false;
    
              axiosThis.deactivateCanceled = true;
  }
  else if(response.data.stat == 'Ok'){
    axiosThis.deactivEapi()
    axiosThis.marginload = false

  }
  else if(response.data.msg2 == 'some holdings' || response.data.msg2 == 'zero ledger balance' ){
    axiosThis.deactivateConfirmation = false;
    axiosThis.marginposive = true
    axiosThis.marginload = false

  }
  else if(response.data.msg1 == 'postive ledger balance' && response.data.stat == 'Not Ok'){
    axiosThis.deactivateConfirmation = false;
    axiosThis.marginposive = true
    axiosThis.marginload = false


  }
  else{
    axiosThis.marginload = false
    axiosThis.deactivateConfirmation = false;

    axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data;
  }
  
})
.catch((error) => {
  console.log(error);
  axiosThis.marginload = false
  axiosThis.deactivateConfirmation = false;

  axiosThis.errorSnackbar = true;
              axiosThis.errtext = error;
});
},

check_holding(){
  const axios = require('axios');
let data = JSON.stringify({
  client_code: this.client_code
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: api.api_url + "/getholdingscheck",

  // url: 'https://rekycuat.mynt.in/getholdingscheck',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};
var axiosThis = this
axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  if(response.data.stat == 'Ok'){
    axiosThis.holdingres = response.data.holdings
    console.log("axiosThis.holdingres",axiosThis.holdingres);
  }
})
.catch((error) => {
  console.log(error);
});

},

deactivEapi(){
      
  this.extradiaload = true
      const axios = require("axios");
      const FormData = require("form-data");
      let data = new FormData();
      data.append("client_id",  this.client_code);
      data.append("client_name", this.name);
      data.append("client_email", this.profileData.CLIENT_ID_MAIL);
      data.append("dp_code", this.profileData.CLIENT_DP_CODE);
      data.append("segments", JSON.stringify(this.profileData.segments_data));
      data.append("reason",  this.closureReason);
      data.append("address", this.profileData.CL_RESI_ADD1);
      data.append("transfer", this.zerobal == 'some holdings' ? 'yes' : 'no');
      data.append("transfer_client_id", this.dpcode);
      data.append("transfer_dp_id", this.dpcode1);
      data.append("cmr_file", this.panFile);


      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://rekycuat.mynt.in/closure",
        url: api.api_url + "/closure",
        headers: {},
        data: data,
      };
      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          // axiosThis.deactivateConfirmation = false
           if (
              response.data.closure_fileid &&
              response.data.closure_mailid
            ) {
              axiosThis.marginload = false
              axiosThis.extradiaload = false
              axiosThis.marginposive = false
              axiosThis.deactivateConfirmation = false;
              axiosThis.successSnackbar = true
              axiosThis.Succtext = "successfully"
            
              axiosThis.closureReason = "";
              axiosThis.blurLoader = true
              axiosThis.editdialog = false;
              axiosThis.fetchMobileStatus()
              // axiosThis.digioEsign(
              //   response.data.closure_fileid,
              //   response.data.closure_mailid,
              //   response.data.closure_session
              // );
            }
            else{
              axiosThis.marginload = false
              axiosThis.extradiaload = false
              axiosThis.marginposive = false

              axiosThis.deactivateConfirmation = false;
              axiosThis.errorSnackbar = true
              axiosThis.errtext = response.data.msg
            }
        
        })
        .catch((error) => {
          console.log(error);
          axiosThis.marginload = false
          axiosThis.extradiaload = false
          axiosThis.marginposive = false

          axiosThis.deactivateConfirmation = false;
              axiosThis.errorSnackbar = true
              axiosThis.errtext = error

        });
   
},



    deactivEapi1() {
      // console.log("klk");
      this.editType = "closure";
      this.loaderPop = true;
      var axiosThis = this;
      var valid = this.$refs.deactivateform.validate();
      if (valid == true) {
        let data = JSON.stringify({
          client_id: this.client_code,
          client_name: this.name,
          client_email: this.profileData.CLIENT_ID_MAIL,
          dp_code: this.profileData.CLIENT_DP_CODE,
          segments: JSON.stringify(this.profileData.segments_data),
          reason: this.closureReason,
          address: this.profileData.CL_RESI_ADD1,
        });

        let config = {
          method: "post",

          url: api.api_url + "/closure",
          headers: {
            Authorization: this.tok,
            clientid: this.client_code,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            if (response.data.msg == "you are having some holdings") {
              axiosThis.canceledData = "You have holdings in your account.";
              axiosThis.deactivateForm = false;
              axiosThis.deactivateCanceled = true;
            } else if (
              response.data.closure_fileid &&
              response.data.closure_mailid
            ) {
              axiosThis.deactivateConfirmation = false;
              axiosThis.deactivateCanceled = false;
              axiosThis.deactivateForm = true;
              axiosThis.closureReason = "";
              axiosThis.editdialog = false;
              axiosThis.fetchMobileStatus()
              // axiosThis.digioEsign(
              //   response.data.closure_fileid,
              //   response.data.closure_mailid,
              //   response.data.closure_session
              // );
            } else if (response.data.msg == "you are having ledger balance") {
              axiosThis.canceledData =
                "You have ledger balance in your account.";
              axiosThis.deactivateForm = false;
              axiosThis.deactivateCanceled = true;
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.msg;
              axiosThis.deactivateForm = false;
              axiosThis.deactivateCanceled = false;
              axiosThis.deactivateConfirmation = false;


            }
            axiosThis.loaderPop = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },

    segmentChange() {
      this.$refs.formsegment.validate();
      var axiosThis = this;

      if (
        this.bankproffdocument == true &&
        this.panFile &&
        this.newSegmentSelected[0]
      ) {
        this.bankLoader = true;
        for (var z = 0; z < this.allSegments.length; z++) {
          // console.log("cdcd", this.newSegmentSelected[z]);
          for (var x = 0; x < this.allSegments.length; x++) {
            console.warn(
              "---",
              this.newSegmentSelected[z],
              this.inactiveSegments[x],
              this.newSegmentSelected[x]
            );
            if (
              this.newSegmentSelected[z] != undefined &&
              this.newSegmentSelected[x] != undefined
            ) {
              if (this.newSegmentSelected[z] == this.inactiveSegments[x]) {
                this.addsegtype = "YES";
              }
              if (this.newSegmentSelected[z] == this.newSegments[x]) {
                this.addsegtypeNew = "YES";
              }
            }
          }
        }

        const FormData = require("form-data");
        let data = new FormData();

        data.append("new_segments", JSON.stringify(this.newSegmentSelected));
        data.append("ext_segments", JSON.stringify(this.existingSegments));

        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append(
          "adding_segments",
          this.addsegtypeNew ? this.addsegtypeNew : "NO"
        );
        data.append(
          "re_active_segments",
          this.addsegtype ? this.addsegtype : "NO"
        );

        data.append("client_id", this.profileData.CLIENT_ID);
        data.append("nse_equity", this.nsecash ? "YES" : "NO");
        data.append("bse_equity", this.bsecash ? "YES" : "NO");
        data.append("nse_equity_der", this.nsefno ? "YES" : "NO");
        data.append("bse_equity_der", this.bsefno ? "YES" : "NO");
        data.append("nse_currency_der", this.cdnse ? "YES" : "NO");
        data.append("bse_currency_der", this.cdbse ? "YES" : "NO");
        data.append("nse_commodity_der", this.nsecom ? "YES" : "NO");
        data.append("bse_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("mcx_commodity_der", this.mcx ? "YES" : "NO");
        data.append("icex_commodity_der", this.icex ? "YES" : "NO");
        data.append("nse_mfss", this.mfnse ? "YES" : "NO");
        data.append("bse_mfss", this.mfbse ? "YES" : "NO");
        data.append("nse_slbm", this.nseslbm ? "YES" : "NO");
        data.append("client_email", this.profileData.CLIENT_ID_MAIL);
        data.append("client_name", this.profileData.CLIENT_NAME);
        data.append("proff", this.panFile != null ? this.panFile : "");
        data.append("address", this.profileData.CL_RESI_ADD1);

        let config = {
          method: "post",
          url: api.api_url + "/add_segment",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.bankLoader = false;

            // console.log(JSON.stringify(response.data));
            if (response.data.fileid && response.data.mailid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.newSegmentSelected[0] && !this.bankproffdocument) {
        this.bankLoader = true;

        for (var za = 0; za < this.allSegments.length; za++) {
          // console.log("cdcd", this.newSegmentSelected[za]);
          for (var xa = 0; xa < this.allSegments.length; xa++) {
            console.warn(
              "---",
              this.newSegmentSelected[za],
              this.inactiveSegments[xa],
              this.newSegmentSelected[xa]
            );
            if (
              this.newSegmentSelected[za] != undefined &&
              this.newSegmentSelected[xa] != undefined
            ) {
              if (this.newSegmentSelected[za] == this.inactiveSegments[xa]) {
                this.addsegtype = "YES";
              }
              if (this.newSegmentSelected[za] == this.newSegments[xa]) {
                this.addsegtypeNew = "YES";
              }
            }
          }
        }

        const FormData = require("form-data");
        let data = new FormData();

        data.append("new_segments", JSON.stringify(this.newSegmentSelected));
        data.append("ext_segments", JSON.stringify(this.existingSegments));

        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append(
          "adding_segments",
          this.addsegtypeNew ? this.addsegtypeNew : "NO"
        );
        data.append(
          "re_active_segments",
          this.addsegtype ? this.addsegtype : "NO"
        );

        data.append("client_id", this.profileData.CLIENT_ID);
        data.append("nse_equity", this.nsecash ? "YES" : "NO");
        data.append("bse_equity", this.bsecash ? "YES" : "NO");
        data.append("nse_equity_der", this.nsefno ? "YES" : "NO");
        data.append("bse_equity_der", this.bsefno ? "YES" : "NO");
        data.append("nse_currency_der", this.cdnse ? "YES" : "NO");
        data.append("bse_currency_der", this.cdbse ? "YES" : "NO");
        data.append("nse_commodity_der", this.nsecom ? "YES" : "NO");
        data.append("bse_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("mcx_commodity_der", this.mcx ? "YES" : "NO");
        data.append("icex_commodity_der", this.icex ? "YES" : "NO");
        data.append("nse_mfss", this.mfnse ? "YES" : "NO");
        data.append("bse_mfss", this.mfbse ? "YES" : "NO");
        data.append("nse_slbm", this.nseslbm ? "YES" : "NO");
        data.append("client_email", this.profileData.CLIENT_ID_MAIL);
        data.append("client_name", this.profileData.CLIENT_NAME);
        data.append("proff", this.panFile != null ? this.panFile : "");
        data.append("address", this.profileData.CL_RESI_ADD1);

        let config = {
          method: "post",
          url: api.api_url + "/add_segment",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.bankLoader = false;

            // console.log(JSON.stringify(response.data));
            if (response.data.fileid && response.data.mailid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorSnackbar = true;
        this.errtext = "Invalid data";
      }
    },
    deletebankk(bank) {
      this.deletebank = bank;
      this.confirmation = true;
    },

    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (
        this.usernewemail.toUpperCase() ==
        this.profileData.CLIENT_ID_MAIL.toUpperCase()
      ) {
        this.usernewemail = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    },
    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
      //   stateid +
      //   "";
      // for local
      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },
    selectOptionBtn() {
      // console.log("cjsjsakcnsioun", this.yesorno);
      if (this.yesorno == "yes") {
        this.nomineeForm = true;
      } else if (this.yesorno == "no") {
        this.nomineeForm = false;
      }
    },
    pincodeFetch() {
      this.pinLoader = true;
      var axiosThis = this;
      // console.warn("hh");
      if (this.newNomineePincode.length >= 6) {
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineePincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeState = resp.State;
            axiosThis.newNomineeCountry = resp.Country;
            axiosThis.newNomineeCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.pincodeAddress.length >= 6) {
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.pincodeAddress,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.stateAddress = resp.State;
            axiosThis.countryAddress = resp.Country;
            axiosThis.distAddress = resp.District;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincode.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianState = resp.State;
            axiosThis.newNomineeGuardianCountry = resp.Country;
            axiosThis.newNomineeGuardianCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodento.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatento = resp.State;
            axiosThis.newNomineeGuardianCountrynto = resp.Country;
            axiosThis.newNomineeGuardianCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodenthre.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatenthre = resp.State;
            axiosThis.newNomineeGuardianCountrynthre = resp.Country;
            axiosThis.newNomineeGuardianCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineePincodento.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" + this.newNomineePincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatento = resp.State;
            axiosThis.newNomineeCountrynto = resp.Country;
            axiosThis.newNomineeCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.newNomineePincodenthre.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineePincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatenthre = resp.State;
            axiosThis.newNomineeCountrynthre = resp.Country;
            axiosThis.newNomineeCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
    },

    digioEsign(file, email, session) {
      var axo = this;
      this.blurLoader = true;
      axo.editdialog = false;
      // console.log(file, email,session)
      // localStorage.setItem('fileid', this.digioFile)
      var options = {
        environment: "production",
        callback: function (t) {
          // axo.statusCheckNominee(axo.client_code);
          // console.log(t);
          // console.log(axo.stata);
          axo.blurLoader = false;

          if (axo.stata != undefined) {
            if (axo.stata.app_status == "e-signed pending") {
              axo.radioBtn = true;
              axo.nomineesubmitBtn = true;
            } else {
              axo.radioBtn = false;
              axo.nomineesubmitBtn = false;
            }
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }

          if (t.message == "Signing cancelled") {
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("failure", file);
            axo.fetchMobileStatus();

            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()

            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()
            // }
          } else if (t.message == "Signed Successfully") {
            // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("success", file);
            // }
          }

          if (t["hasOwnProperty"]("error_code")) {
            // document.getElementById("loading").style.display='none';
            //// console.log('Error Digio')
            //// console.log(t.message)
          } else {
            //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
            //successDigio();
            //// console.log(this.step, this.ekycStatus);
            setTimeout(function () {
              //code goes here
              window.location.reload();
            }, 4500);
          }
        },
      };

      var digio = new Digio(options);
      digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
      // digio.submit(this.digioFile, this.digioMail,);
      // console.log(file,email,session)
      digio.submit(file, email, session);
      // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

      // console.log(digio)
    },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    digilockerAdressSubmit() {
      var axiosThis = this;

      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;

      // var url_data = this.$route.params;
      // console.log(token,"poiuytrewqqqqqqasdfghjkllkmnbvcxz");

      // var addressvalid = this.$refs.formadress.validate();
      if (token && state) {
        this.editType = "address_change";
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", "");
        data.append("proff", "");
        data.append("cur_address", "");
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append(
          "client_email",
          this.profileData.CLIENT_ID_MAIL.toUpperCase()
        );
        data.append("aadhar_address", "");
        data.append("adr_manual", "aadhar");
        data.append("code", token);
        data.append("state", state);

        // let config = {
        //   method: 'post',
        //   // 
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            axiosThis.$router.replace("/");
            // window.location.assign(window.location.href.split('?')[0])

            // const query = this.$route.query;
            //   delete query.code;
            //   delete query.state;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },


    valu(val) {
      // console.log(val, "val");
      const validat = val;
      if (validat == false) {
        this.createChartsred();
      }
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    getAvailBal() {

      var axiosThis = this;
  

          axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
        
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
              var resp = JSON.parse(res);
              // console.log('werty',resp);

              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              localStorage.setItem('clinet_name', axiosThis.name)

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation =
                  axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }
              axiosThis.fetchMobileStatus();

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
              for (
                var i = 0;
                i < axiosThis.profileData.segments_data.length;
                i++
              ) {
                // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE);
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_NSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_BSE"
                ) {
                  axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_BSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_NSE"
                ) {
                  axiosThis.derivat.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MCX" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX"
                ) {
                  axiosThis.commoditi.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
              }
              // console.log(axiosThis.derivat);
              // axiosThis.payout.ledger_amount = axiosThis.payout.ledger_amount.toFixed(2)
              // axiosThis.payout.withdraw_amount = axiosThis.payout.withdraw_amount.toFixed(2)

              // axiosThis.cashledg = axiosThis.payout.ledger_amount
      
          
          axiosThis.digilockerAdressSubmit();
       
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""),
        (this.requestotpemail = false),
        (this.useremailOtp = ""),
        (this.verifyotpemail = false),
        (this.stateAddress = ""),
        (this.countryAddress = ""),
        (this.distAddress = ""),
        (this.pincodeAddress = "");
      (this.usernewAdress = ""),
        (this.adressproffdoc = null),
        (this.bankIfscCode = ""),
        (this.bankAccountnumber = ""),
        (this.bankproofTypes = ""),
        (this.bankproffdoc = null),
        (this.defaultCheckbox = false);
    },
  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("jiji", vendorAcc, vendorToken);
    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
        // this.statusCheckNominee(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>