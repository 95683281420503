<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">

    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>

    <div class="mx-3 px-1">
      <!-- <div class="py-md-7  d-block d-md-none "></div> -->
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Nominee  - <span v-if="profileData.Nominee_Name != ''" class="  " style="color:green"> {{profileData.Nominee_Name != '' ? "Your account is Secured" : ""}} <v-icon size="23" color="green">mdi-shield-check-outline</v-icon></span>  <span v-if="profileData.Nominee_Name == ''" style="color:red;font-size:18px" class="  "> 
            {{profileData.Nominee_Name == '' ? "Your account is unsecured" : "" }} <v-icon color="red" size="23">mdi-shield-alert</v-icon> 
          </span></v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Going with nomination is a clever move to safeguard your investments.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>

        <!-- <p class="font-weight-bold fs-22 mb-0">Nominee  - <span v-if="nomineeYes" class=" font-weight-medium title" style="color:green">Your account is Secured <v-icon size="23" color="green">mdi-shield-check-outline</v-icon></span>  <span v-if="!nomineeYes" style="color:red;font-size:18px" class=" font-weight-medium title"> 
            Your account is unsecured  <v-icon color="red" size="23">mdi-shield-alert</v-icon>
          </span></p>
        <p class="subtitle-2 txt-666">
          Nominees are the recipients of your mutual funds and stocks in your absence and a good idea to have.
          Would you like to add nominee for your investment?
          In trading and finance, a nominee is someone or entity who holds assets on behalf of another party, typically for administrative or privacy reasons.
          Going with nomination is a clever move to safeguard your investments.
        </p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="py-md-6 px-md-3 mt-6" elevation="0" :outlined="$vuetify.breakpoint.smAndDown ? false : true"
        width="100%">
        <p class="subtitle-1 mb-0 txt-666 font-weight-medium">
            Add nominee
          </p>
        <div v-if="nomineeYes">
         
<!-- <p class="font-weight-medium subtitle-1" style="color:green">Your account is Secured <v-icon color="green">mdi-shield-check-outline</v-icon></p> -->
          <v-row class="mt-2" no-gutters>
            <v-col cols="12" md="6">
              <div class="pr-md-8">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0">
                    <v-text-field readonly :loading="profileLoader" v-model="newNomineeName"
                      label="NOMINEE NAME"></v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0">
                    <v-text-field :loading="profileLoader" readonly label="NOMINEE RELATION"
                      v-model="newNomineeRelation"></v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2" no-gutters>
            <v-col cols="12" md="6">
              <div class="pr-md-8">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0">
                    <v-text-field readonly :loading="profileLoader" v-model="newNomineeDob"
                      label="NOMINEE DOB"></v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0">
                    <v-text-field :loading="profileLoader" readonly label="PERCENTAGE"
                      v-model="newNomineepercent"></v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <!-- <v-card-actions v-else>
                        <p class="mb-0" style="font-size: 16px; color: #0037b7">
                       {{ submissionText }}
                     </p>
                     <v-spacer></v-spacer>
                   </v-card-actions> -->
            </v-col>

          </v-row>


          <!-- 
          <v-btn outlined  @click="newflowaddnoe2()"> + ADD Nominee 2 </v-btn>
          <v-btn outlined v-if="newmonie3" @click="newflowaddnoe2()"> + ADD Nominee 3 </v-btn>

<v-btn outlined>Contue</v-btn> -->

          <div v-if="nominee2">
            <p class="mb-0" style="font-size: 16px">Nominee 2</p>
            <v-row class="mt-2" no-gutters>
              <v-col cols="12" md="6">
                <div class="pr-md-8">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field readonly :loading="profileLoader" v-model="newNomineeName2"
                        label="NOMINEE NAME"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field :loading="profileLoader" readonly label="NOMINEE RELATION"
                        v-model="newNomineeRelation2"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
              <v-col cols="12" md="6">
                <div class="pr-md-8">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field readonly :loading="profileLoader" v-model="newNomineeDob2"
                        label="NOMINEE DOB"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field :loading="profileLoader" readonly label="PERCENTAGE"
                        v-model="newNomineepercent2"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>

              </v-col>


            </v-row>
            <div v-if="esignBtn">
              <v-card rounded="lg" color="#FCEFD4" elevation="0" v-if="nomineeYes && nominee2 && !nominee3"
                class="px-0 pa-3">
                <div class="d-none d-md-block">
                <div class=" d-flex">
                  <p class=" mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                    Your Nominee Change  request is not yet Completed,
                    
                    <span style="color: #0037b7; cursor: pointer" @click="
                      (editType = 'nominee'),
                      digioEsign(
                        stata.file_id,
                        stata.client_email.toLowerCase(),
                        stata.session
                      )
                      " v-if="esignBtn">
                      Click here esign</span>
                  </p>
                  <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true"
                    class="ml-auto  mr-2 ml-14" style="color:red;cursor: pointer;float:right">Cancel request</span>
                </div>
              </div>

              <div class="d-md-none">
                <p class=" mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                    Your Nominee Change  request is not yet Completed,</p>

                    <div class="d-flex">
                      <span style="color: #0037b7; cursor: pointer" @click="
                      (editType = 'nominee'),
                      digioEsign(
                        stata.file_id,
                        stata.client_email.toLowerCase(),
                        stata.session
                      )
                      " v-if="esignBtn">
                      Click here esign</span>
                                                                                                   
                  <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true"
                    class="ml-auto  mr-2 ml-14" style="color:red;cursor: pointer;float:right">Cancel request</span>
                    </div>
              </div>


                <!-- <p style="font-size: 10px;color: #0037B7;">{{ stata.date_time }}</p> -->
              </v-card>
            </div>
          </div>
          <div v-if="nominee3">
            <p class="mb-0" style="font-size: 16px">Nominee 3</p>
            <v-row class="mt-2" no-gutters>
              <v-col cols="12" md="6">
                <div class="pr-md-8">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field readonly :loading="profileLoader" v-model="newNomineeName3"
                        label="NOMINEE NAME"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field :loading="profileLoader" readonly label="NOMINEE RELATION"
                        v-model="newNomineeRelation3"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
              <v-col cols="12" md="6">
                <div class="pr-md-8">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field readonly :loading="profileLoader" v-model="newNomineeDob3"
                        label="NOMINEE DOB"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                      <v-text-field :loading="profileLoader" readonly label="PERCENTAGE"
                        v-model="newNomineepercent3"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </div>

              </v-col>
              <!-- <v-col cols="12" md="6"> -->

              <!-- </v-col> -->
            </v-row>
            <div v-if="esignBtn">
              <v-card rounded="lg" color="#FCEFD4" elevation="0" class="mt-4 pa-2">
                <div class="d-none d-md-block">
                <div class="d-flex">
                  <p class=" mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                    Your Nominee Change  request is not yet Completed,
                    <span style="color: #0037b7; cursor: pointer" @click="
                      (editType = 'nominee'),
                      digioEsign(
                        stata.file_id,
                        stata.client_email.toLowerCase(),
                        stata.session
                      )
                      " v-if="esignBtn">
                      Click here esign</span>
                  </p>
                
                  <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true"
                    class="ml-auto  mr-2 ml-14" style="color:red;cursor: pointer;float:right">Cancel request</span>
                </div>
              </div>

              <div class="d-md-none">
                <p class=" mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                    Your Nominee Change  request is not yet Completed,</p>

                    <div class="d-flex">
                      <span style="color: #0037b7; cursor: pointer" @click="
                      (editType = 'nominee'),
                      digioEsign(
                        stata.file_id,
                        stata.client_email.toLowerCase(),
                        stata.session
                      )
                      " v-if="esignBtn">
                      Click here esign</span>
         
                
                  <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true"
                    class="ml-auto  mr-2 ml-14" style="color:red;cursor: pointer;float:right">Cancel request</span>
                    </div>

              </div>
              </v-card>
            </div>
          </div>
          <div v-if="accountstatus != 'client_close'">
            <div v-if="newNomineeName2 == '' || newNomineeName3 == '' || neresdata.nom_stat == `data doesn't exists`">
            <v-chip-group class="" column  v-if="!esignBtn && stata.app_status != 'e-signed completed'">
              <v-chip :disabled="radioBtn" @click="(yesorno = tag.val), selectOptionBtn()"
                :color="yesorno == tag.val ? '#000' : '#666666'" :text-color="yesorno == tag.val ? '#fff' : '#666666'"
                v-for="(tag, l) in noms1" :outlined="yesorno != tag.val" :key="l">
                {{ tag.txt }}
              </v-chip>
            </v-chip-group>
          </div>

            <v-chip-group v-if="yesorno == 'yes'" column class="mb-4">
              <v-chip @click="panel = 0" :color="panel == 0 ? '#000' : '#666666'"
                :text-color="panel == 0 ? '#fff' : '#666666'" :outlined="panel != 0">
                1st nominee
                <div v-if="!dissBtn" class="mt-1 ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
                      fill="#34A853" />
                  </svg>
                </div>
              </v-chip>

              <v-chip @click="panel = 1" :color="panel == 1 ? '#000' : '#666666'"
                :text-color="panel == 1 ? '#fff' : '#666666'" :outlined="panel != 1"
                v-if="activetab == 'tab2' || activetab == 'tab3'">
                2nd nominee
                <div v-if="!dissBtn2" class="mt-1 ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
                      fill="#34A853" />
                  </svg>
                </div>
                <v-btn color="white" :disabled="activetab == 'tab3'" icon @click="setclosenee"><v-icon
                    size="16">mdi-backspace-outline</v-icon></v-btn>
              </v-chip>

              <v-chip @click="panel = 2" :color="panel == 2 ? '#000' : '#666666'"
                :text-color="panel == 2 ? '#fff' : '#666666'" :outlined="panel != 2" v-if="activetab == 'tab3'">
                3rd nominee
                <div v-if="!dissBtn3" class="mt-1 ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
                      fill="#34A853" />
                  </svg>
                </div>
                <v-btn color="white" icon @click="setclosenee33"><v-icon size="16">mdi-backspace-outline</v-icon></v-btn>
              </v-chip>
            </v-chip-group>

            <v-form ref="addNomineeform" v-model="valid" lazy-validation>
              <div v-if="nomineeForm">
                <div v-if="panel == 0">
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="12">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Nominee name
                              <span style="color: red">*</span>
                            </p>
                            <v-row no-gutter>
                              <v-col cols="9" md="10">
                                <v-text-field class="pa-0" @keyup="
                                (newNomineeName =
                                  newNomineeName.toUpperCase()),
                                  flit()
                                  " :rules="nomineeField" v-model="newNomineeName" placeholder="ENTER NOMINEE NAME">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="3" md="2">
                                <!-- <v-text-field :readonly="activetab == ''" type="number" suffix="%"
                                        @change="nomPerChgt()" @keyup="nomPerChgt()" :max="n2_permax" :min="n2_permin"
                                        hide-spin-buttons class="nom mb-3 pt-0  mt-1" v-model="nominee2.n2_percent"
                                        :error-messages="n2_percentErrors" required
                                        @blur="$v.nominee2.n2_percent.$touch()"></v-text-field> -->

                                <v-text-field class="nom mb-3 pt-0 mt-1" hide-spin-buttons :rules="nomineeField" min="0"
                                  max="100" type="number" append-icon="mdi-percent"
                                  @keyup="percentcalc(), percentcalcnto()" v-model="newNomineepercentone"
                                  placeholder="PERCENTAGE"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="6">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                            <div>
                              <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto" @input="flit()">

                                <template v-slot:activator="{ on, attrs }">
                                  <p class="ma-0 pa-0" style="font-weight: 499">
                                    Date of birth
                                    <span style="color: red">*  <span style="color:grey"  class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
                                  </p>

                                  <v-text-field v-model="date" placeholder="DOB" @change="flit()" class="pa-0"
                                    :rules="nomineeField" readonly v-bind="attrs" v-on="on">
                                    <template #prepend-inner>
                                      <div style="width: 30px">
                                        <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/Calendar.svg" />
                                      </div>
                                    </template>
                                  </v-text-field>
                                </template>

                                <v-date-picker @input="flit()" v-model="date" :active-picker.sync="activePicker" :max="new Date(
                                  Date.now() -
                                  new Date().getTimezoneOffset() *
                                  60000
                                )
                                  .toISOString()
                                  .substring(0, 10)
                                  " min="1920-01-01" @change="save"></v-date-picker>
                              </v-menu>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Relationship
                              <span style="color: red">*</span>
                            </p>

                            <v-select class="pa-0" @change="flit" :rules="nomineeField" v-model="newNomineeRelation"
                              :items="relationNominee" placeholder="ENTER RELATIONSHIP">
                              <template #prepend-inner>
                                <div style="width: 30px">
                                  <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                </div>
                              </template>
                            </v-select>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="" no-gutters>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <v-checkbox class="ml-3 checklab" v-model="checkbox" :label="customLabel"
                            :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"></v-checkbox>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="!checkbox" no-gutters>
                    <v-col>
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Address <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeAddress"
                              placeholder="ENTER ADDRESS"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="!checkbox" no-gutters class="">
                    <v-col>
                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Pincode <span style="color: red">*</span>
                          </p>

                          <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="ENTER PINCODE"
                            :loading="pinLoader" v-model="newNomineePincode" @input="pincodeFetch()"
                            :rules="nomineeField"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            State <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER STATE"
                            v-model="newNomineeState"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            City <span style="color: red">*</span>
                          </p>
                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER CITY"
                            v-model="newNomineeCity"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Country <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER COUNTRY"
                            v-model="newNomineeCountry"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="belowAge" no-gutters class="">
                    <v-col>
                      <p>Guardian for Nominee 1</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="belowAge">
                    <v-col cols="12" md="12">
                      <div class=" ">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Guardian name
                              <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" @keyup="
                              newNomineeGuardian =
                              newNomineeGuardian.toUpperCase()
                              " :rules="nomineeField" v-model="newNomineeGuardian"
                              placeholder="GUARDIAN NAME"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2" v-if="belowAge" no-gutters>
                    <v-col cols="12" md="6">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                            <div>
                              <v-menu ref="menuu" v-model="menuu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <p class="ma-0 pa-0" style="font-weight: 499">
                                    Guardian DOB
                                    <span style="color: red">*</span>
                                  </p>

                                  <v-text-field v-model="datee" placeholder="ENTER GUARDIAN DATE OF BIRTH"
                                    :rules="nomineeField" readonly class="pa-0" v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="datee" :active-picker.sync="activePicker" :max="new Date(
                                  Date.now() -
                                  new Date().getTimezoneOffset() *
                                  60000
                                )
                                  .toISOString()
                                  .substring(0, 10)
                                  " :rules="date" min="1920-01-01" @change="savee"></v-date-picker>
                              </v-menu>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Guardian relationship
                              <span style="color: red">*</span>
                            </p>

                            <v-select class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianRelation"
                              :items="relationNominee" placeholder="GUARDIAN RELATIONSHIP"></v-select>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2" v-if="belowAge" no-gutters>
                    <v-col>
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Address <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianAddress"
                              placeholder="ENTER ADDRESS"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="belowAge" no-gutters class="">
                    <v-col>
                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Pincode <span style="color: red">*</span>
                          </p>

                          <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="ENTER PINCODE"
                            :loading="pinLoader" v-model="newNomineeGuardianPincode" @keyup="pincodeFetch()"
                            :rules="nomineeField"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            State <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" placeholder="ENTER STATE" v-model="newNomineeGuardianState"
                            class="pa-0"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            City <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER CITY"
                            v-model="newNomineeGuardianCity"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Country <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER COUNTRY"
                            v-model="newNomineeGuardianCountry"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </div>
                <!-- <v-row class="" no-gutters>
                        <v-col>
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <v-checkbox class="ma-0" v-model="checkboxTow" :label="`Add 2nd Nominee`"></v-checkbox>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row> -->
                <div v-if="checkboxTow">
                  <div v-if="panel == 1">
                    <v-row class="mt-2" no-gutters>
                      <v-col cols="12" md="12">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Nominee name
                                <span style="color: red">*</span>
                              </p>

                              <v-row no-gutter>
                                <v-col cols="9" md="10">
                                  <v-text-field class="pa-0" @keyup="
                                  (newNomineeNameto =
                                    newNomineeNameto.toUpperCase()),
                                    flit2()
                                    " :rules="nomineeField" v-model="newNomineeNameto"
                                    placeholder="ENTER NOMINEE NAME">
                                    <template #prepend-inner>
                                      <div style="width: 30px">
                                        <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                      </div>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="3" md="2">
                                  <!-- <v-text-field :readonly="activetab == ''" type="number" suffix="%"
                                        @change="nomPerChgt()" @keyup="nomPerChgt()" :max="n2_permax" :min="n2_permin"
                                        hide-spin-buttons class="nom mb-3 pt-0  mt-1" v-model="nominee2.n2_percent"
                                        :error-messages="n2_percentErrors" required
                                        @blur="$v.nominee2.n2_percent.$touch()"></v-text-field> -->

                                  <v-text-field class="nom mb-3 pt-0 mt-1" hide-spin-buttons :rules="nomineeField"
                                    type="number" min="0" max="100" append-icon="mdi-percent" @keyup="
                                      percentcalcnto(), percentcalcnthre()
                                      " v-model="newNomineepercentnto" placeholder="PERCENTAGE"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2" no-gutters>
                      <v-col cols="12" md="6">
                        <div class="pr-md-8">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                              <div>
                                <v-menu ref="menunto" v-model="menunto" :close-on-content-click="false"
                                  transition="scale-transition" offset-y min-width="auto" @input="flit2()">
                                  <template v-slot:activator="{ on, attrs }">
                                    <p class="ma-0 pa-0" style="font-weight: 499">
                                      Date of birth
                                      <span style="color: red">*  <span style="color:grey"  class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
                                    </p>

                                    <v-text-field v-model="datento" @change="flit2()" @click="flit2()"
                                      placeholder="DATE OF BIRTH" :rules="nomineeField" readonly class="pa-0"
                                      v-bind="attrs" v-on="on">
                                      <template #prepend-inner>
                                        <div style="width: 30px">
                                          <v-img class="mt-1" max-height="16" max-width="16"
                                            src="@/assets/Calendar.svg" />
                                        </div>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker @input="flit2()" v-model="datento" :active-picker.sync="activePicker"
                                    :max="new Date(
                                      Date.now() -
                                      new Date().getTimezoneOffset() *
                                      60000
                                    )
                                      .toISOString()
                                      .substring(0, 10)
                                      " min="1920-01-01" :rules="date" @change="savento"></v-date-picker>
                                </v-menu>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>

                      <v-col cols="12" md="6">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Relationship
                                <span style="color: red">*</span>
                              </p>

                              <v-select class="pa-0" @change="flit2" :rules="nomineeField" v-model="newNomineeRelationto"
                                :items="relationNominee" placeholder="ENTER RELATIONSHIP">
                                <template #prepend-inner>
                                  <div style="width: 30px">
                                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                  </div>
                                </template>
                              </v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="" no-gutters>
                      <v-col>
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <v-checkbox class="ml-3" v-model="checkboxnto" :on-icon="'mdi-checkbox-marked-circle'"
                              :off-icon="'mdi-checkbox-blank-circle-outline'"
                              :label="`Nominee address is same as my address`"></v-checkbox>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="!checkboxnto" no-gutters>
                      <v-col>
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Address <span style="color: red">*</span>
                              </p>

                              <v-text-field :rules="nomineeField" class="pa-0" v-model="newNomineeAddressnto"
                                placeholder="ADDRESS"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-if="!checkboxnto" no-gutters class="">
                      <v-col>
                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Pincode <span style="color: red">*</span>
                            </p>

                            <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                              :loading="pinLoader" v-model="newNomineePincodento" @keyup="pincodeFetch()"
                              :rules="nomineeField"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              State <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" placeholder="STATE" v-model="newNomineeStatento"
                              class="pa-0"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              City <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                              v-model="newNomineeCitynto"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Country <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                              v-model="newNomineeCountrynto"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="belowAgento" no-gutters class="">
                      <v-col>
                        <p style="font-weight: 499">
                          Guardian for Nominee 2
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="belowAgento">
                      <v-col cols="12" md="12">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Guardian name
                                <span style="color: red">*</span>
                              </p>

                              <v-text-field class="pa-0" @keyup="
                                newNomineeGuardiannto =
                                newNomineeGuardiannto.toUpperCase()
                                " :rules="nomineeField" v-model="newNomineeGuardiannto"
                                placeholder="GUARDIAN NAME"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2" v-if="belowAgento" no-gutters>
                      <v-col cols="12" md="6">
                        <div class="pr-md-8">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                              <div>
                                <v-menu ref="menuunto" v-model="menuunto" :close-on-content-click="false"
                                  transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <p class="ma-0 pa-0" style="font-weight: 499">
                                      Guardian DOB
                                      <span style="color: red">*</span>
                                    </p>

                                    <v-text-field v-model="dateento" placeholder="GUARDIAN DATE OF BIRTH"
                                      :rules="nomineeField" readonly class="pa-0" v-bind="attrs" v-on="on">
                                    </v-text-field>
                                  </template>
                                  <v-date-picker v-model="dateento" :active-picker.sync="activePicker" :max="new Date(
                                    Date.now() -
                                    new Date().getTimezoneOffset() *
                                    60000
                                  )
                                    .toISOString()
                                    .substring(0, 10)
                                    " min="1920-01-01" @change="saveento"></v-date-picker>
                                </v-menu>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>

                      <v-col cols="12" md="6">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Guardian relationship
                                <span style="color: red">*</span>
                              </p>

                              <v-select class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianRelationnto"
                                :items="relationNominee" placeholder="GUARDIAN RELATIONSHIP"></v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2" v-if="belowAgento" no-gutters>
                      <v-col>
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Address <span style="color: red">*</span>
                              </p>

                              <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianAddressnto"
                                placeholder="ENTER ADDRESS"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="belowAgento" no-gutters class="">
                      <v-col>
                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Pincode <span style="color: red">*</span>
                            </p>

                            <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                              :loading="pinLoader" v-model="newNomineeGuardianPincodento" @keyup="pincodeFetch()"
                              :rules="nomineeField"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              State <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" placeholder="ENTER STATE"
                              v-model="newNomineeGuardianStatento" class="pa-0"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              City <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                              v-model="newNomineeGuardianCitynto"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Country <span style="color: red">*</span>
                            </p>
                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                              v-model="newNomineeGuardianCountrynto"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- <v-row class="" no-gutters>
                          <v-col>
                            <v-list-item class="pa-0">
                              <v-list-item-content class="pa-0">
                                <v-checkbox class="ma-0" v-model="checkboxThree"
                                  :label="`Add 3rd Nominee`"></v-checkbox>

                                  
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row> -->
                </div>
                <div v-if="checkboxTow && checkboxThree">
                  <div v-if="panel == 2">
                    <v-row class="mt-2" no-gutters>
                      <v-col cols="12" md="12">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Nominee name
                                <span style="color: red">*</span>
                              </p>

                              <v-row no-gutter>
                                <v-col cols="9" md="10">
                                  <v-text-field class="pa-0" @keyup="
                                  (newNomineeNamenthre =
                                    newNomineeNamenthre.toUpperCase()),
                                    flit3()
                                    " :rules="nomineeField" v-model="newNomineeNamenthre" placeholder="NOMINEE NAME">
                                    <template #prepend-inner>
                                      <div style="width: 30px">
                                        <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                      </div>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="3" md="2">
                                  <v-text-field class="nom mb-3 pt-0 mt-1" :rules="nomineeField" hide-spin-buttons min="0"
                                    :max="secnompercentmax" append-icon="mdi-percent" @keyup="percentcalcnthre()"
                                    v-model="newNomineepercentnthre" placeholder="PERCENTAGE"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2" no-gutters>
                      <v-col cols="12" md="6">
                        <div class="pr-md-8">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                              <div>
                                <v-menu ref="menunthre" v-model="menunthre" :close-on-content-click="false"
                                  transition="scale-transition" offset-y min-width="auto" @input="flit3()">
                                  <template v-slot:activator="{ on, attrs }">
                                    <p class="ma-0 pa-0" style="font-weight: 499">
                                      Date of Birth
                                      <span style="color: red">*  <span style="color:grey"  class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
                                    </p>

                                    <v-text-field v-model="datenthre" placeholder="DOB" @change="flit3()" @click="flit3()"
                                      :rules="nomineeField" readonly class="pa-0" v-bind="attrs" v-on="on">
                                      <template #prepend-inner>
                                        <div style="width: 30px">
                                          <v-img class="mt-1" max-height="16" max-width="16"
                                            src="@/assets/Calendar.svg" />
                                        </div>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker v-model="datenthre" :active-picker.sync="activePicker" :max="new Date(
                                    Date.now() -
                                    new Date().getTimezoneOffset() *
                                    60000
                                  )
                                    .toISOString()
                                    .substring(0, 10)
                                    " min="1920-01-01" @input="flit3()" @change="saventhre"></v-date-picker>
                                </v-menu>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>

                      <v-col cols="12" md="6">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Relationship
                                <span style="color: red">*</span>
                              </p>

                              <v-select class="pa-0" @change="flit3" :rules="nomineeField"
                                v-model="newNomineeRelationnthre" :items="relationNominee" placeholder="RELATIONSHIP">
                                <template #prepend-inner>
                                  <div style="width: 30px">
                                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                  </div>
                                </template>
                              </v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="" no-gutters>
                      <v-col>
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <v-checkbox class="ml-3" v-model="checkboxnthre" :on-icon="'mdi-checkbox-marked-circle'"
                              :off-icon="'mdi-checkbox-blank-circle-outline'"
                              :label="`Nominee address is same as my address`"></v-checkbox>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="!checkboxnthre" no-gutters>
                      <v-col>
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Address <span style="color: red">*</span>
                              </p>

                              <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeAddressnthre"
                                placeholder="ADDRESS"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-if="!checkboxnthre" no-gutters class="">
                      <v-col>
                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Pincode <span style="color: red">*</span>
                            </p>

                            <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                              :loading="pinLoader" v-model="newNomineePincodenthre" @keyup="pincodeFetch()"
                              :rules="nomineeField"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              State <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" placeholder="STATE" v-model="newNomineeStatenthre"
                              class="pa-0"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              City <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                              v-model="newNomineeCitynthre"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Country <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                              v-model="newNomineeCountrynthre"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="belowAgenthre" no-gutters class="">
                      <v-col>
                        <p>Guardian for Nominee 3</p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="belowAgenthre">
                      <v-col cols="12" md="12">
                        <div class="pr-md-8">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Guardian name
                                <span style="color: red">*</span>
                              </p>

                              <v-text-field class="pa-0" @keyup="
                                newNomineeGuardiannthre =
                                newNomineeGuardiannthre.toUpperCase()
                                " :rules="nomineeField" v-model="newNomineeGuardiannthre"
                                placeholder="GUARDIAN NAME"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2" v-if="belowAgenthre" no-gutters>
                      <v-col cols="12" md="6">
                        <div class="pr-md-8">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                              <div>
                                <v-menu ref="menuunthre" v-model="menuunthre" :close-on-content-click="false"
                                  transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <p class="ma-0 pa-0" style="font-weight: 499">
                                      Guardian date of birth
                                      <span style="color: red">*</span>
                                    </p>

                                    <v-text-field v-model="dateenthre" placeholder="DATE OF BIRTH" :rules="nomineeField"
                                      readonly class="pa-0" v-bind="attrs" v-on="on"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="dateenthre" :active-picker.sync="activePicker" :max="new Date(
                                    Date.now() -
                                    new Date().getTimezoneOffset() *
                                    60000
                                  )
                                    .toISOString()
                                    .substring(0, 10)
                                    " min="1920-01-01" @change="saveenthre"></v-date-picker>
                                </v-menu>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>

                      <v-col cols="12" md="6">
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Guardian relationship
                                <span style="color: red">*</span>
                              </p>

                              <v-select class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianRelationnthre"
                                :items="relationNominee" placeholder="GUARDIAN RELATIONSHIP"></v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-2" v-if="belowAgenthre" no-gutters>
                      <v-col>
                        <div class="">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <p class="ma-0 pa-0" style="font-weight: 499">
                                Address <span style="color: red">*</span>
                              </p>

                              <v-text-field :rules="nomineeField" class="pa-0" v-model="newNomineeGuardianAddressnthre"
                                placeholder="ADDRESS"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="belowAgenthre" no-gutters class="">
                      <v-col>
                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Pincode <span style="color: red">*</span>
                            </p>

                            <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                              :loading="pinLoader" v-model="newNomineeGuardianPincodenthre" @keyup="pincodeFetch()"
                              :rules="nomineeField"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0 pr-8">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              State <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" placeholder="STATE" v-model="newNomineeGuardianStatenthre"
                              class="pa-0"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              City <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                              v-model="newNomineeGuardianCitynthre"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Country <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                              v-model="newNomineeGuardianCountrynthre"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>

              <v-card elevation="0" class=" mt-5 pa-0">
                <div class="d-md-inline-flex">
                  <v-btn v-if="yesorno == 'yes' &&
                    checkboxThree == false &&
                    !checkboxTow
                    " outlined dense color="#000" :disabled="!valid" @click="addnominee2()"
                    class="text-none rounded-pill elevation-0  mb-4  mt-md-8 mt-lg-6"
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-4 mr-3' : 'mr-3'"><span
                      class="subtitle-1 font-weight-medium px-3 px-md-6">+ Add 2nd Nominee</span></v-btn>

                  <v-btn v-if="yesorno == 'yes' &&
                    checkboxThree == false &&
                    checkboxTow
                    " outlined dense color="#000" :disabled="!valid" @click="addnominee3()"
                    class="text-none rounded-pill elevation-0 mb-4 mt-md-8 mt-lg-6"
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-4 mr-3' : 'mr-3'"><span
                      class="subtitle-1 font-weight-medium px-3 px-md-6">+ Add 3rd Nominee</span></v-btn>
                  <!-- <p class="mt-10 mt-lg-7 mr-md-4" :style="submissionText ? 'display: inline-block' : 'display: none'">
                  <a>{{ submissionText }}</a>
                </p> -->
                <div v-if="accountstatus != 'client_close'">
                  <v-btn v-if="yesorno == 'yes'" dense :loading="cardloader" @click="nomineeSubmitButton11()" color="#000"
                    class="white--text text-none rounded-pill elevation-0  mt-md-8 mt-lg-6" :disabled="accountstatus == 'client_close'"
                    :class="submissionText ? 'ml-3' : ''"><span
                      class="white--text subtitle-1 font-weight-medium px-3 px-md-6">Continue</span></v-btn>
                    </div>

                    <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          Continue
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>

                </div>
                <!-- <p style="font-size: 10px;color: #0037B7;">{{ stata.date_time }}</p> -->
              </v-card>
            </v-form>


          </div>

          <div v-if="esignBtn">

            <v-card rounded="lg" color="#FCEFD4" elevation="0" v-if="nomineeYes && !nominee2 && !nominee3" class="pa-2">
              <div class="d-flex d-none d-md-block ">
                <p class=" d-none d-md-block mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                  Your Nominee Change  request is not yet Completed,<span @click="
                    (editType = 'nominee'),
                    digioEsign(
                      stata.file_id,
                      stata.client_email.toLowerCase(),
                      stata.session
                    )
                    " v-if="esignBtn" style="color: #0037b7; cursor: pointer">
                    Click here esign</span>
                </p>

                <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true" class="ml-auto d-none d-md-block  mr-2 ml-4"
                  style="color:red;cursor: pointer;float:right">Cancel request</span>

              </div>


              <div class="d-md-none ">
                <p class=" mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                  Your Nominee Change  request is not yet Completed,  </p>
                  
                  <div class="d-flex ">
                  <span @click="
                    (editType = 'nominee'),
                    digioEsign(
                      stata.file_id,
                      stata.client_email.toLowerCase(),
                      stata.session
                    )
                    " v-if="esignBtn" style="color: #0037b7; cursor: pointer">
                    Click here esign</span>
             

                <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true" class="ml-auto  mr-2 ml-4"
                  style="color:red;cursor: pointer;float:right">Cancel request</span>
                </div>
              </div>


            </v-card>

          </div> <!-- </v-col> -->
        </div>
        <div v-else>
          <div v-if="accountstatus != 'client_close'">
      
          <v-chip-group class="pb-4" column v-if="!esignBtn && stata.app_status != 'e-signed completed'">
            <v-chip  :disabled="radioBtn" @click="(yesorno = tag.val), selectOptionBtn()"
              :color="yesorno == tag.val ? '#000' : '#666666'" :text-color="yesorno == tag.val ? '#fff' : '#666666'"
              v-for="(tag, l) in noms" :outlined="yesorno != tag.val" :key="l">
              {{ tag.txt }}
            </v-chip>
          </v-chip-group>
        </div>

          <v-chip-group v-if="yesorno == 'yes'" column class="mb-4">
            <v-chip @click="panel = 0" :color="panel == 0 ? '#000' : '#666666'"
              :text-color="panel == 0 ? '#fff' : '#666666'" :outlined="panel != 0">
              1st nominee
              <div v-if="!dissBtn" class="mt-1 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
                    fill="#34A853" />
                </svg>
              </div>
            </v-chip>

            <v-chip @click="panel = 1" :color="panel == 1 ? '#000' : '#666666'"
              :text-color="panel == 1 ? '#fff' : '#666666'" :outlined="panel != 1"
              v-if="activetab == 'tab2' || activetab == 'tab3'">
              2nd nominee
              <div v-if="!dissBtn2" class="mt-1 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
                    fill="#34A853" />
                </svg>
              </div>
              <v-btn color="white" :disabled="activetab == 'tab3'" icon @click="setclosenee"><v-icon
                  size="16">mdi-backspace-outline</v-icon></v-btn>

            </v-chip>

            <v-chip @click="panel = 2" :color="panel == 2 ? '#000' : '#666666'"
              :text-color="panel == 2 ? '#fff' : '#666666'" :outlined="panel != 2" v-if="activetab == 'tab3'">
              3rd nominee
              <div v-if="!dissBtn3" class="mt-1 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
                    fill="#34A853" />
                </svg>
              </div>
              <v-btn color="white" icon @click="setclosenee33"><v-icon size="16">mdi-backspace-outline</v-icon></v-btn>

            </v-chip>
          </v-chip-group>
          <v-form ref="addNomineeform" v-model="valid" lazy-validation>
            <div v-if="nomineeForm">
              <div v-if="panel == 0">
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" md="12">
                    <div class="">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Nominee name
                            <span style="color: red">*</span>
                          </p>
                          <v-row no-gutter>
                            <v-col cols="9" md="10">
                              <v-text-field class="pa-0" @keyup="
                              (newNomineeName =
                                newNomineeName.toUpperCase()),
                                flit()
                                " :rules="nomineeField" v-model="newNomineeName" placeholder="ENTER NOMINEE NAME">
                                <template #prepend-inner>
                                  <div style="width: 30px">
                                    <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                  </div>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="3" md="2">
                              <!-- <v-text-field :readonly="activetab == ''" type="number" suffix="%"
                                        @change="nomPerChgt()" @keyup="nomPerChgt()" :max="n2_permax" :min="n2_permin"
                                        hide-spin-buttons class="nom mb-3 pt-0  mt-1" v-model="nominee2.n2_percent"
                                        :error-messages="n2_percentErrors" required
                                        @blur="$v.nominee2.n2_percent.$touch()"></v-text-field> -->

                              <v-text-field class="nom mb-3 pt-0 mt-1" hide-spin-buttons :rules="nomineeField" min="0"
                                max="100" type="number" append-icon="mdi-percent" @keyup="percentcalc(), percentcalcnto()"
                                v-model="newNomineepercentone" placeholder="PERCENTAGE"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" md="6">
                    <div class="pr-md-8">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                          <div>
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                              transition="scale-transition" offset-y min-width="auto" @input="flit()">

                              <template v-slot:activator="{ on, attrs }">
                                <p class="ma-0 pa-0" style="font-weight: 499">
                                  Date of birth
                                  <span style="color: red">*  <span style="color:grey"  class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
                                </p>

                                <v-text-field v-model="date" placeholder="DOB" @change="flit()" class="pa-0"
                                  :rules="nomineeField" readonly v-bind="attrs" v-on="on">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/Calendar.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </template>

                              <v-date-picker @input="flit()" v-model="date" :active-picker.sync="activePicker" :max="new Date(
                                Date.now() -
                                new Date().getTimezoneOffset() *
                                60000
                              )
                                .toISOString()
                                .substring(0, 10)
                                " min="1920-01-01" @change="save"></v-date-picker>
                            </v-menu>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Relationship
                            <span style="color: red">*</span>
                          </p>

                          <v-select class="pa-0" @change="flit" :rules="nomineeField" v-model="newNomineeRelation"
                            :items="relationNominee" placeholder="ENTER RELATIONSHIP">
                            <template #prepend-inner>
                              <div style="width: 30px">
                                <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                              </div>
                            </template>
                          </v-select>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="" no-gutters>
                  <v-col>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <v-checkbox class="ml-3 checklab" v-model="checkbox" :label="customLabel"
                          :on-icon="'mdi-checkbox-marked-circle'"
                          :off-icon="'mdi-checkbox-blank-circle-outline'"></v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row v-if="!checkbox" no-gutters>
                  <v-col>
                    <div class="">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Address <span style="color: red">*</span>
                          </p>

                          <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeAddress"
                            placeholder="ENTER ADDRESS"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>

                <v-row v-if="!checkbox" no-gutters class="">
                  <v-col>
                    <v-list-item class="pa-0 pr-8">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          Pincode <span style="color: red">*</span>
                        </p>

                        <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="ENTER PINCODE"
                          :loading="pinLoader" v-model="newNomineePincode" @input="pincodeFetch()"
                          :rules="nomineeField"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 pr-8">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          State <span style="color: red">*</span>
                        </p>

                        <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER STATE"
                          v-model="newNomineeState"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          City <span style="color: red">*</span>
                        </p>
                        <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER CITY"
                          v-model="newNomineeCity"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          Country <span style="color: red">*</span>
                        </p>

                        <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER COUNTRY"
                          v-model="newNomineeCountry"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row v-if="belowAge" no-gutters class="">
                  <v-col>
                    <p>Guardian for Nominee 1</p>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="belowAge">
                  <v-col cols="12" md="12">
                    <div class=" ">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Guardian name
                            <span style="color: red">*</span>
                          </p>

                          <v-text-field class="pa-0" @keyup="
                            newNomineeGuardian =
                            newNomineeGuardian.toUpperCase()
                            " :rules="nomineeField" v-model="newNomineeGuardian"
                            placeholder="GUARDIAN NAME"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-2" v-if="belowAge" no-gutters>
                  <v-col cols="12" md="6">
                    <div class="pr-md-8">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                          <div>
                            <v-menu ref="menuu" v-model="menuu" :close-on-content-click="false"
                              transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <p class="ma-0 pa-0" style="font-weight: 499">
                                  Guardian DOB
                                  <span style="color: red">*</span>
                                </p>

                                <v-text-field v-model="datee" placeholder="ENTER GUARDIAN DATE OF BIRTH"
                                  :rules="nomineeField" readonly class="pa-0" v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="datee" :active-picker.sync="activePicker" :max="new Date(
                                Date.now() -
                                new Date().getTimezoneOffset() *
                                60000
                              )
                                .toISOString()
                                .substring(0, 10)
                                " :rules="date" min="1920-01-01" @change="savee"></v-date-picker>
                            </v-menu>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Guardian relationship
                            <span style="color: red">*</span>
                          </p>

                          <v-select class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianRelation"
                            :items="relationNominee" placeholder="GUARDIAN RELATIONSHIP"></v-select>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-2" v-if="belowAge" no-gutters>
                  <v-col>
                    <div class="">
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Address <span style="color: red">*</span>
                          </p>

                          <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianAddress"
                            placeholder="ENTER ADDRESS"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="belowAge" no-gutters class="">
                  <v-col>
                    <v-list-item class="pa-0 pr-8">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          Pincode <span style="color: red">*</span>
                        </p>

                        <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="ENTER PINCODE"
                          :loading="pinLoader" v-model="newNomineeGuardianPincode" @keyup="pincodeFetch()"
                          :rules="nomineeField"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 pr-8">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          State <span style="color: red">*</span>
                        </p>

                        <v-text-field :rules="nomineeField" placeholder="ENTER STATE" v-model="newNomineeGuardianState"
                          class="pa-0"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          City <span style="color: red">*</span>
                        </p>

                        <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER CITY"
                          v-model="newNomineeGuardianCity"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <p class="ma-0 pa-0" style="font-weight: 499">
                          Country <span style="color: red">*</span>
                        </p>

                        <v-text-field :rules="nomineeField" class="pa-0" placeholder="ENTER COUNTRY"
                          v-model="newNomineeGuardianCountry"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </div>
              <!-- <v-row class="" no-gutters>
                        <v-col>
                          <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                              <v-checkbox class="ma-0" v-model="checkboxTow" :label="`Add 2nd Nominee`"></v-checkbox>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row> -->
              <div v-if="checkboxTow">
                <div v-if="panel == 1">
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="12">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Nominee name
                              <span style="color: red">*</span>
                            </p>

                            <v-row no-gutter>
                              <v-col cols="9" md="10">
                                <v-text-field class="pa-0" @keyup="
                                (newNomineeNameto =
                                  newNomineeNameto.toUpperCase()),
                                  flit2()
                                  " :rules="nomineeField" v-model="newNomineeNameto" placeholder="ENTER NOMINEE NAME">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="3" md="2">
                                <!-- <v-text-field :readonly="activetab == ''" type="number" suffix="%"
                                        @change="nomPerChgt()" @keyup="nomPerChgt()" :max="n2_permax" :min="n2_permin"
                                        hide-spin-buttons class="nom mb-3 pt-0  mt-1" v-model="nominee2.n2_percent"
                                        :error-messages="n2_percentErrors" required
                                        @blur="$v.nominee2.n2_percent.$touch()"></v-text-field> -->

                                <v-text-field class="nom mb-3 pt-0 mt-1" hide-spin-buttons :rules="nomineeField"
                                  type="number" min="0" max="100" append-icon="mdi-percent" @keyup="
                                    percentcalcnto(), percentcalcnthre()
                                    " v-model="newNomineepercentnto" placeholder="PERCENTAGE"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="6">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                            <div>
                              <v-menu ref="menunto" v-model="menunto" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto" @input="flit2()">
                                <template v-slot:activator="{ on, attrs }">
                                  <p class="ma-0 pa-0" style="font-weight: 499">
                                    Date of birth
                                    <span style="color: red">*  <span style="color:grey"  class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
                                  </p>

                                  <v-text-field v-model="datento" @change="flit2()" @click="flit2()"
                                    placeholder="DATE OF BIRTH" :rules="nomineeField" readonly class="pa-0" v-bind="attrs"
                                    v-on="on">
                                    <template #prepend-inner>
                                      <div style="width: 30px">
                                        <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/Calendar.svg" />
                                      </div>
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker @input="flit2()" v-model="datento" :active-picker.sync="activePicker" :max="new Date(
                                  Date.now() -
                                  new Date().getTimezoneOffset() *
                                  60000
                                )
                                  .toISOString()
                                  .substring(0, 10)
                                  " min="1920-01-01" :rules="date" @change="savento"></v-date-picker>
                              </v-menu>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Relationship
                              <span style="color: red">*</span>
                            </p>

                            <v-select class="pa-0" @change="flit2" :rules="nomineeField" v-model="newNomineeRelationto"
                              :items="relationNominee" placeholder="ENTER RELATIONSHIP">
                              <template #prepend-inner>
                                <div style="width: 30px">
                                  <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                </div>
                              </template>
                            </v-select>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="" no-gutters>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <v-checkbox class="ml-3" v-model="checkboxnto" :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"
                            :label="`Nominee address is same as my address`"></v-checkbox>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="!checkboxnto" no-gutters>
                    <v-col>
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Address <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" v-model="newNomineeAddressnto"
                              placeholder="ADDRESS"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="!checkboxnto" no-gutters class="">
                    <v-col>
                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Pincode <span style="color: red">*</span>
                          </p>

                          <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                            :loading="pinLoader" v-model="newNomineePincodento" @keyup="pincodeFetch()"
                            :rules="nomineeField"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            State <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" placeholder="STATE" v-model="newNomineeStatento"
                            class="pa-0"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            City <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                            v-model="newNomineeCitynto"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Country <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                            v-model="newNomineeCountrynto"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="belowAgento" no-gutters class="">
                    <v-col>
                      <p style="font-weight: 499">
                        Guardian for Nominee 2
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="belowAgento">
                    <v-col cols="12" md="12">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Guardian name
                              <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" @keyup="
                              newNomineeGuardiannto =
                              newNomineeGuardiannto.toUpperCase()
                              " :rules="nomineeField" v-model="newNomineeGuardiannto"
                              placeholder="GUARDIAN NAME"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2" v-if="belowAgento" no-gutters>
                    <v-col cols="12" md="6">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                            <div>
                              <v-menu ref="menuunto" v-model="menuunto" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <p class="ma-0 pa-0" style="font-weight: 499">
                                    Guardian DOB
                                    <span style="color: red">*</span>
                                  </p>

                                  <v-text-field v-model="dateento" placeholder="GUARDIAN DATE OF BIRTH"
                                    :rules="nomineeField" readonly class="pa-0" v-bind="attrs" v-on="on">
                                  </v-text-field>
                                </template>
                                <v-date-picker v-model="dateento" :active-picker.sync="activePicker" :max="new Date(
                                  Date.now() -
                                  new Date().getTimezoneOffset() *
                                  60000
                                )
                                  .toISOString()
                                  .substring(0, 10)
                                  " min="1920-01-01" @change="saveento"></v-date-picker>
                              </v-menu>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Guardian relationship
                              <span style="color: red">*</span>
                            </p>

                            <v-select class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianRelationnto"
                              :items="relationNominee" placeholder="GUARDIAN RELATIONSHIP"></v-select>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2" v-if="belowAgento" no-gutters>
                    <v-col>
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Address <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianAddressnto"
                              placeholder="ENTER ADDRESS"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="belowAgento" no-gutters class="">
                    <v-col>
                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Pincode <span style="color: red">*</span>
                          </p>

                          <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                            :loading="pinLoader" v-model="newNomineeGuardianPincodento" @keyup="pincodeFetch()"
                            :rules="nomineeField"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            State <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" placeholder="ENTER STATE"
                            v-model="newNomineeGuardianStatento" class="pa-0"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            City <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                            v-model="newNomineeGuardianCitynto"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Country <span style="color: red">*</span>
                          </p>
                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                            v-model="newNomineeGuardianCountrynto"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </div>
                <!-- <v-row class="" no-gutters>
                          <v-col>
                            <v-list-item class="pa-0">
                              <v-list-item-content class="pa-0">
                                <v-checkbox class="ma-0" v-model="checkboxThree"
                                  :label="`Add 3rd Nominee`"></v-checkbox>

                                  
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row> -->
              </div>
              <div v-if="checkboxTow && checkboxThree">
                <div v-if="panel == 2">
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="12">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Nominee name
                              <span style="color: red">*</span>
                            </p>

                            <v-row no-gutter>
                              <v-col cols="9" md="10">
                                <v-text-field class="pa-0" @keyup="
                                (newNomineeNamenthre =
                                  newNomineeNamenthre.toUpperCase()),
                                  flit3()
                                  " :rules="nomineeField" v-model="newNomineeNamenthre" placeholder="NOMINEE NAME">
                                  <template #prepend-inner>
                                    <div style="width: 30px">
                                      <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/user.svg" />
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="3" md="2">
                                <v-text-field class="nom mb-3 pt-0 mt-1" :rules="nomineeField" hide-spin-buttons min="0"
                                  :max="secnompercentmax" append-icon="mdi-percent" @keyup="percentcalcnthre()"
                                  v-model="newNomineepercentnthre" placeholder="PERCENTAGE"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="6">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB" <span color="grey" class="caption"> <span style="color:grey" class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
  ></v-text-field> -->
                            <div>
                              <v-menu ref="menunthre" v-model="menunthre" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto" @input="flit3()">
                                <template v-slot:activator="{ on, attrs }">
                                  <p class="ma-0 pa-0" style="font-weight: 499">
                                    Date of Birth
                                    <span style="color: red">*  <span style="color:grey"  class="caption">(Minor information can be displayed only while collecting the DOB)</span></span>
                                  </p>

                                  <v-text-field v-model="datenthre" placeholder="DOB" @change="flit3()" @click="flit3()"
                                    :rules="nomineeField" readonly class="pa-0" v-bind="attrs" v-on="on">
                                    <template #prepend-inner>
                                      <div style="width: 30px">
                                        <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/Calendar.svg" />
                                      </div>
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker v-model="datenthre" :active-picker.sync="activePicker" :max="new Date(
                                  Date.now() -
                                  new Date().getTimezoneOffset() *
                                  60000
                                )
                                  .toISOString()
                                  .substring(0, 10)
                                  " min="1920-01-01" @input="flit3()" @change="saventhre"></v-date-picker>
                              </v-menu>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Relationship
                              <span style="color: red">*</span>
                            </p>

                            <v-select class="pa-0" @change="flit3" :rules="nomineeField" v-model="newNomineeRelationnthre"
                              :items="relationNominee" placeholder="RELATIONSHIP">
                              <template #prepend-inner>
                                <div style="width: 30px">
                                  <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                </div>
                              </template>
                            </v-select>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="" no-gutters>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <v-checkbox class="ml-3" v-model="checkboxnthre" :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"
                            :label="`Nominee address is same as my address`"></v-checkbox>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="!checkboxnthre" no-gutters>
                    <v-col>
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Address <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" :rules="nomineeField" v-model="newNomineeAddressnthre"
                              placeholder="ADDRESS"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="!checkboxnthre" no-gutters class="">
                    <v-col>
                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Pincode <span style="color: red">*</span>
                          </p>

                          <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                            :loading="pinLoader" v-model="newNomineePincodenthre" @keyup="pincodeFetch()"
                            :rules="nomineeField"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            State <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" placeholder="STATE" v-model="newNomineeStatenthre"
                            class="pa-0"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            City <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                            v-model="newNomineeCitynthre"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Country <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                            v-model="newNomineeCountrynthre"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="belowAgenthre" no-gutters class="">
                    <v-col>
                      <p>Guardian for Nominee 3</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="belowAgenthre">
                    <v-col cols="12" md="12">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Guardian name
                              <span style="color: red">*</span>
                            </p>

                            <v-text-field class="pa-0" @keyup="
                              newNomineeGuardiannthre =
                              newNomineeGuardiannthre.toUpperCase()
                              " :rules="nomineeField" v-model="newNomineeGuardiannthre"
                              placeholder="GUARDIAN NAME"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2" v-if="belowAgenthre" no-gutters>
                    <v-col cols="12" md="6">
                      <div class="pr-md-8">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader" :rules="nomineeField" v-model="newNomineeDob"
  label="NOMINEE DOB"
  ></v-text-field> -->
                            <div>
                              <v-menu ref="menuunthre" v-model="menuunthre" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <p class="ma-0 pa-0" style="font-weight: 499">
                                    Guardian date of birth
                                    <span style="color: red">*</span>
                                  </p>

                                  <v-text-field v-model="dateenthre" placeholder="DATE OF BIRTH" :rules="nomineeField"
                                    readonly class="pa-0" v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateenthre" :active-picker.sync="activePicker" :max="new Date(
                                  Date.now() -
                                  new Date().getTimezoneOffset() *
                                  60000
                                )
                                  .toISOString()
                                  .substring(0, 10)
                                  " min="1920-01-01" @change="saveenthre"></v-date-picker>
                              </v-menu>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <!-- <v-text-field :loading="profileLoader"
  label="NOMINEE RELATION" :rules="nomineeField" v-model="newNomineeRelation"
  ></v-text-field> -->
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Guardian relationship
                              <span style="color: red">*</span>
                            </p>

                            <v-select class="pa-0" :rules="nomineeField" v-model="newNomineeGuardianRelationnthre"
                              :items="relationNominee" placeholder="GUARDIAN RELATIONSHIP"></v-select>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2" v-if="belowAgenthre" no-gutters>
                    <v-col>
                      <div class="">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="pa-0">
                            <p class="ma-0 pa-0" style="font-weight: 499">
                              Address <span style="color: red">*</span>
                            </p>

                            <v-text-field :rules="nomineeField" class="pa-0" v-model="newNomineeGuardianAddressnthre"
                              placeholder="ADDRESS"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="belowAgenthre" no-gutters class="">
                    <v-col>
                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Pincode <span style="color: red">*</span>
                          </p>

                          <v-text-field hide-spin-buttons class="pa-0" type="number" placeholder="PINCODE"
                            :loading="pinLoader" v-model="newNomineeGuardianPincodenthre" @keyup="pincodeFetch()"
                            :rules="nomineeField"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0 pr-8">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            State <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" placeholder="STATE" v-model="newNomineeGuardianStatenthre"
                            class="pa-0"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            City <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="CITY"
                            v-model="newNomineeGuardianCitynthre"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                          <p class="ma-0 pa-0" style="font-weight: 499">
                            Country <span style="color: red">*</span>
                          </p>

                          <v-text-field :rules="nomineeField" class="pa-0" placeholder="COUNTRY"
                            v-model="newNomineeGuardianCountrynthre"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>

            <v-card-actions class="px-0">
              <div class="d-md-inline-flex">
                <v-btn v-if="yesorno == 'yes' &&
                  checkboxThree == false &&
                  !checkboxTow
                  " outlined dense color="#000" :disabled="!valid" @click="addnominee2()"
                  class="text-none rounded-pill elevation-0  mb-4  mt-md-8 mt-lg-6"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-4 mr-3' : 'mr-3'"><span
                    class="subtitle-1 font-weight-medium px-3 px-md-6">+ Add 2nd Nominee</span></v-btn>

                <v-btn v-if="yesorno == 'yes' &&
                  checkboxThree == false &&
                  checkboxTow
                  " outlined dense color="#000" :disabled="!valid" @click="addnominee3()"
                  class="text-none rounded-pill elevation-0 mb-4 mt-md-8 mt-lg-6"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-4 mr-3' : 'mr-3'"><span
                    class="subtitle-1 font-weight-medium px-3 px-md-6">+ Add 3rd Nominee</span></v-btn>
                <!-- <p class="mt-10 mt-lg-7 mr-md-4" :style="submissionText ? 'display: inline-block' : 'display: none'">
                  <a>{{ submissionText }}</a>
                </p> -->
                <!-- {{ stata.app_status }} -->
                <div v-if="accountstatus != 'client_close'">
                <v-btn v-if="stata.app_status != 'e-signed pending' && stata.app_status != 'e-signed completed'" dense :loading="cardloader"
                  @click="nomineeSubmitButton11()" color="#000" :disabled="accountstatus == 'client_close'"
                  class="white--text text-none rounded-pill elevation-0  mt-md-8 mt-lg-6"
                  :class="submissionText ? 'ml-3' : ''"><span
                    class="white--text subtitle-1 font-weight-medium px-3 px-md-6">Continue</span></v-btn>
                  </div>
                  <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          Continue
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>

                <!-- <v-btn   class="white--text text-none rounded-pill elevation-0 mt-8 ml-3 mt-md-8 mt-lg-6" color="#000"
      rounded dense @click="
        (editType = 'nominee'),
        digioEsign(
          stata.file_id,
          stata.client_email.toLowerCase(),
          stata.session
        )
        " v-if="esignBtn">Continue    </v-btn>                    <span v-if="stata.app_status == 'e-signed pending'"  @click="cancelcon = true"  class="mt-10 mr-2 ml-4" style="color:red;cursor: pointer;float:right">Cancel request</span> -->
              </div>
              <!-- <p style="font-size: 10px;color: #0037B7;">{{ stata.date_time }}</p> -->
            </v-card-actions>

            <v-card rounded="lg" v-if="esignBtn" color="#FCEFD4" elevation="0" class="mt-4">
              <div class="px-2 py-1">
                <p style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "  class="d-none d-md-block">
                  Your Nominee Change  request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
                    (editType = 'nominee'),
                    digioEsign(
                      stata.file_id,
                      stata.client_email.toLowerCase(),
                      stata.session
                    )
                    " v-if="esignBtn">
                    Click here esign</span>
                  <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true" class="mt-1 mr-2"
                    style="color:red;cursor: pointer;float:right">Cancel request</span>
                </p>

                <div class="d-md-none">
                  <p style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "  >
                  Your Nominee Change  request is not yet Completed,</p>
                  <div class="d-flex">
                    <span style="color: #0037b7; cursor: pointer" @click="
                    (editType = 'nominee'),
                    digioEsign(
                      stata.file_id,
                      stata.client_email.toLowerCase(),
                      stata.session
                    )
                    " v-if="esignBtn">
                    Click here esign</span>
                    <span v-if="stata.app_status == 'e-signed pending'" @click="cancelcon = true" class="mt-1 ml-auto mr-2"
                    style="color:red;cursor: pointer;float:right">Cancel request</span>
                  </div>

                </div>
              </div>
            </v-card>
          </v-form>
          <!-- <v-spacer></v-spacer>
                      <p class="mb-0" style="font-size: 10px; color: #0037b7">
                        {{ submissionText }}
                      </p>
                  <v-btn class="text-none white--text ml-2 px-4 elevation-0" color="#000" rounded
                        @click="editType = 'nominee', digioEsign(stata.file_id, stata.client_email, stata.session)"
                        v-if="esignBtn">Esign</v-btn> -->
        </div>
      </v-card>
      <v-card rounded="lg" color="#FCEFD4" elevation="0" v-if="stata.app_status == 'e-signed completed'"
                class="px-0 pa-3">
                <div class=" d-flex">
                  <p class=" ml-3 mr-md-4" style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                    Your Nominee Change  request is in process,
                </p>
              </div>
                </v-card>
      <p class="subtitle-2 txt-666 mt-4">
        *As per the regulation, you can have up-to 3 nominee for your

        trading a/c
      </p>
    </div>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog v-model="nosegmentsPop" max-width="380">
      <v-card class="rounded-lg px-2 px-md-4">
        <v-card-title> No active segments found? </v-card-title>
        <v-card-text>
          You have no active segments in your account
          <b>"{{ profileData.CLIENT_ID }}"?</b> <br />Kindly do
          <a href="https://oa.mynt.in/">ekyc again</a></v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelcon" max-width="380">
      <!-- <v-card class="rounded-lg px-2 px-md-4 pa-4">
        <v-card-text class="body-1">
          Are you sure you want to cancel your <b>"Nominee" {{ profileData.CLIENT_ID }}</b> request?
        </v-card-text>
        <v-row>
          <v-col cols="6">
<v-btn @click="cancelcon = false" block rounded color="black"> <span class="white--text">Cancel</span> </v-btn>
          </v-col>
          <v-col cols="6">
<v-btn @click="rejectEsign()" block rounded outlined color="black"> Proceed</v-btn>
          </v-col>
        </v-row>
      </v-card> -->

      <v-card class="rounded-lg px-2 px-md-2">
        <v-card-title class="pr-2">Cancel request? <v-spacer></v-spacer>
          <v-btn icon @click="cancelcon = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <v-card-text>
          Are you sure you want to cancel your <b>"Nominee" {{ profileData.CLIENT_ID }}</b> request?
        </v-card-text>
        <v-card-actions class="pb-4"> <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign()">
            <span class="text-none ">Proceed</span>
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>
<script src="https://app.digio.in/sdk/v11/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v11/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      accountstatus: "",
      xsOnly: null,
      smAndDown: null,
      txt: "",
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      stata: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "MOTHER",
        "FATHER",
        "BROTHER",
        "SISTER",
        "DAUGHTER",
        "WIFE",
        "HUSBAND",
        "SON",
        "GRAND-SON",
        "GRAND-FATHER",
        "GRAND-DAUGHTER",
        "GRAND-MOTHER",
        "FRIEND",
      ],
      // relationNominee: [
      //   "Mother",
      //   "Father",
      //   "Brother",
      //   "Sister",
      //   "Daughter",
      //   "Wife",
      //   "Husband",
      //   "Son",
      //   "Grand-Son",
      //   "Grand-Father",
      //   "Grand-Daughter",
      //   "Grand-Mother",
      //   "Friend",
      // ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 0,   //=============me change 1 to 0
      newNomineepercentnthre: 0,    //=============me change 1 to 0
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      // passwordField: false,
      // typesOfimg: [
      //   "Latest 6 months Bank Statement",
      //   "Latest ITR Copy",
      //   "Latest 6 months salary slip",
      //   "DP holding statement as on date",
      //   "Net worth Certificate",
      //   "Copy of Form 16 in case of salary income",
      // ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      // allSegments: [
      //   "BSE_CASH",
      //   "MF_BSE",
      //   "NSE_CASH",
      //   "BSE_FNO",
      //   "NSE_FNO",
      //   "CD_NSE",
      //   "CD_BSE",
      //   "BSE_COM",
      //   "NSE_COM",
      //   "ICEX",
      //   "MCX",
      // ],
      // newSegments: [],
      // newSegmentSelected: [],
      // bankproffdocument: false,
      // addsegtypeNew: "",
      // mfnse: "",
      // nseslbm: "",
      // deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      // banklogo: "https://rekycbe.mynt.in/autho",
      // reasons: [
      //   "High brokerage and charges",
      //   "Monthly maintenance charges",
      //   "Need better margins",
      //   "Problem with payment and withdrawal",
      //   "Need better order placement options",
      //   "App crashes",
      //   "Issues with back office portals",
      //   "Problem with customer service",
      //   "App is complicated to understand",
      //   "Faced losses",
      //   "No time to focus on trading",
      // ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      // itemsaaass: [
      //   { text: "My Account" },
      //   {
      //     text: "Profile Details",
      //     icon: "",
      //     link: "/accountpage",
      //     mdiicon: "mdi-chevron-right",
      //   },
      //   {
      //     text: "Bank Accounts Linked",
      //     icon: "",
      //     link: "/bankaccount",
      //     mdiicon: "mdi-chevron-right",
      //   },
      //   {
      //     text: "Set Auto Pay",
      //     icon: "",
      //     link: "/autopay",
      //     mdiicon: "mdi-chevron-right",
      //   },
      //   {
      //     text: "Margin against stocks",
      //     icon: "",
      //     link: "/noticationsettings",
      //     mdiicon: "mdi-chevron-right",
      //   },
      //   { text: "Manage Funds", link: "secufund" },
      //   {
      //     text: "Securities Funds ",
      //     icon: "",
      //     link: "managefund",
      //     mdiicon: "mdi-chevron-right",
      //   },
      // ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Click here to secure", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      noms1: [
        { txt: "Edit your nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      // validFamily: "",
      familyloader: false,
      familySubmission: false,
      // endtxt:'',
      familyRequestData: '',
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: '',
      disabled: false,
      readonly: false,
      newflownon: false,
      clicknom: false,
      newmonie3: '',
      cancelcon: false,
      newaddconfn: false,
      neresdata: "",

    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    // ======================================================sabari code=====================================
    newflowaddnoe2() {
      this.newflownon = true
      this.clicknom = false
      this.newmonie3 = true

    },

    setclosenee() {
      //  // console.log("cloooccococ", this.panel);
      //  this.panel = 0;
      //  // console.log("cloooccococ", this.panel);
      this.checkboxTow = false,
        this.activetab = "tab1",
        this.panel = 0,
        this.clearpaneel1()

    },
    setclosenee33() {
      this.checkboxThree = false,
        this.activetab = "tab2",
        this.panel = 1,
        this.clearnomie2()
      this.newNomineepercentone = 50;
      this.newNomineepercentnto = 50;
    },

    clearnomie2() {
      this.newNomineeNamenthre = '',
        this.newNomineepercentnthre = '',
        this.datenthre = '',
        this.menunthre = '',
        this.newNomineeRelationnthre = '',
        this.checkboxnthre = '',
        this.newNomineeAddressnthre = '',
        this.newNomineePincodenthre = '',
        this.newNomineeStatenthre = '',
        this.newNomineeCitynthre = '',
        this.newNomineeCountrynthre = '',
        this.newNomineeGuardiannthre = '',
        this.menuunthre = '',
        this.dateenthre = '',
        this.newNomineeGuardianRelationnthre = '',
        this.newNomineeGuardianAddressnthre = '',
        this.newNomineeGuardianPincodenthre = '',
        this.newNomineeGuardianCitynthre = '',
        this.newNomineeGuardianCountrynthre = ''
    },

    clearpaneel1() {
      this.newNomineeNameto = '',
        this.newNomineepercentnto = '',
        this.menunto = '',
        this.datento = '',
        this.newNomineeRelationto = '',
        this.newNomineeAddressnto = '',
        this.checkboxnto = '',
        this.newNomineePincodento = '',
        this.newNomineeStatento = '',
        this.newNomineeCitynto = '',
        this.newNomineeCountrynto = '',
        this.newNomineeGuardiannto = '',
        this.menuunto = '',
        this.dateento = '',
        this.newNomineeGuardianRelationnto = '',
        this.newNomineeGuardianAddressnto = '',
        this.newNomineeGuardianPincodento = '',
        this.newNomineeGuardianStatento = '',
        this.newNomineeGuardianCitynto = '',
        this.newNomineeGuardianCountrynto = '',
        this.percentcalc()
      this.percentcalcnto()
      this.newNomineepercentone = 100;
    }, rejectEsign() {
      let data = JSON.stringify({
        "client_id": this.profileData.CLIENT_ID,
        "file_id": this.stata.file_id,
        "type": 'nominee'
      });

      let config = {
        method: 'post',
        url: api.api_url + '/manual_cancel_request',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
var axiosThis = this
      axios.request(config)
        .then((response) => {
          axiosThis.canconfirmation = false
          axiosThis.cancelcon = false
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == 'canceled successfully') {
            axiosThis.Succtext = 'Canceled successfully'
            axiosThis.successSnackbar = true
            axiosThis.statusCheckNominee(this.profileData.CLIENT_ID)
            location.reload();
          } else {
            axiosThis.errtext = response.data.msg
            axiosThis.errorSnackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.cancelcon = false

        });
    },    // ======================================================================

    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = ''
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },
    deletereqitem() {
      this.selectTableitem
      // console.log(this.selectTableitem)
      var axiosThis = this
      this.familystatusloading = true
      let data = JSON.stringify({
        "id": this.selectTableitem.id
      });

      let config = {
        method: "post",
        url: api.api_url + "/remove_family_member",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "id removed successfully") {
            axiosThis.familyrequest = false
            axiosThis.successSnackbar = true;
            axiosThis.Succtext = "Removed sucessfully";
          }
          // console.log(JSON.stringify(response.data));
          axiosThis.familystatusloading = false
          // axiosThis.getFamily()
        })
        .catch((error) => {
          console.log(error);
        });

    },

    addFamilySubmit() {
      var axiosThis = this
      var valid = this.$refs.addFamilyform.validate();
      if (valid == true) {
        this.familyloader = true
        let data = JSON.stringify({
          clientid: this.client_code,
          memberid: this.memberId,
          pan: this.memberPan,
          mobile_no: this.memberMobile,
          clientname: this.name,
          relationship: this.newFamilyRelation,
        });
        this.mobileMember = this.memberMobile
        let config = {
          method: "post",
          url: api.api_url + "/send_link_request",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.familyloader = false
            if (response.data.emsg == "requested successfully") {
              axiosThis.familySubmission = true;
              axiosThis.Succtext = "A verification link has been sent in an SMS to";
              axiosThis.memberId = ''
              axiosThis.memberPan = ''
              axiosThis.memberMobile = ''
              axiosThis.newFamilyRelation = ''
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.emsg;

            }


            // console.log(JSON.stringify(response.data));
            // axiosThis.getFamily()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    flit() {
      var valid = this.$refs.addNomineeform.validate();
      // console.log("dara", valid);
      if (valid == true) {
        this.dissBtn = false;
      } else {
        this.dissBtn = true;
      }
    },
    flit2() {
      var valid = this.$refs.addNomineeform.validate();
      // console.log("dara", valid);
      if (valid == true) {
        this.dissBtn2 = false;
      } else {
        this.dissBtn2 = true;
      }
    },
    flit3() {
      var valid = this.$refs.addNomineeform.validate();
      // console.log("dara", valid);
      if (valid == true) {
        this.dissBtn3 = false;
      } else {
        this.dissBtn3 = true;
      }
    },
    addnominee2() {
      var formvalid = this.$refs.addNomineeform.validate();
      if (formvalid == true) {
        this.checkboxTow = true;
        this.activetab = "tab2";
        this.panel = 1;
        this.$refs.addNomineeform.validate();
        this.newNomineepercentone = 50;
        this.newNomineepercentnto = 50;
      }
    },
    addnominee3() {
      var formvalid = this.$refs.addNomineeform.validate();
      if (formvalid == true) {
        this.checkboxThree = true;
        this.activetab = "tab3";
        this.panel = 2;
        this.newNomineepercentone = 34;
        this.newNomineepercentnto = 33;
        this.newNomineepercentnthre = 33;
      }
      if (formvalid == true) {
        this.dissBtn2 = false;
      } else {
        this.dissBtn2 = true;
      }
    },


    newaddnomiee2() {
      this.activetab = "tab1";
      this.panel = 1;

      this.panel = 2;
      this.checkboxTow = true;
      this.activetab = "tab2";
      this.newaddconfn = true;
    },
    newaddnomiee3() {
      this.panel = 3;
      this.checkboxThree = true;
      this.activetab = "tab3";
      this.newaddconfn = true;

    },

    // fileinputcheck() {
    //   const dropContainer = document.getElementById('dropContainer');
    //   dropContainer.classList.add('drag-over');
    // },
    handleFiles(files) {
      // Handle the selected files here
      // console.log(files);
    },
    openFileInput() {
      // console.log("click input",this.$refs.fileInput.$refs.input.click())
      // Programmatically trigger the click event of the hidden input
      this.$refs.fileInput.$refs.input.click();
    },
    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },
    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (
        this.usernewemail.toUpperCase() ==
        this.profileData.CLIENT_ID_MAIL.toUpperCase()
      ) {
        this.usernewemail = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    },
    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
      //   stateid +
      //   "";
      // for local
      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },
    percentcalc() {
      // console.log('press')
      if (Number(this.newNomineepercentone) > Number(this.max)) {
        this.newNomineepercentone = this.max;
      } else if (Number(this.newNomineepercentone) < 0) {
        this.newNomineepercentone = 0;
      }
    },
    percentcalcnto() {
      // console.log('press')
      // var p1 = this.newNomineepercent
      // var p2 = this.newNomineepercentnto
      // var balance =  Number(p1) - Number(p2)
      // var d = Number(this.max) - this.newNomineepercentnto
      // if (Number(this.max) - this.newNomineepercent){
      var d = Number(this.max) - this.newNomineepercentone;
      this.secnompercentmax = d;
      // this.newNomineepercentnto = d
      // }
      if (Number(this.newNomineepercentnto) > Number(this.secnompercentmax)) {
        this.newNomineepercentnto = this.secnompercentmax;
      } else if (Number(this.newNomineepercentnto) < 0) {
        this.newNomineepercentnto = 0;
      }
    },
    percentcalcnthre() {
      // console.log('press')
      var d =
        Number(this.max) -
        (Number(this.newNomineepercentone) + Number(this.newNomineepercentnto));
      this.thernompercentmax = d;

      if (
        Number(this.newNomineepercentnthre) > Number(this.thernompercentmax)
      ) {
        this.newNomineepercentnthre = this.thernompercentmax;
      } else if (Number(this.newNomineepercentnthre) < 0) {
        this.newNomineepercentnthre = 0;
      }
    },

    selectOptionBtn() {
      // console.log("cjsjsakcnsioun", this.yesorno);
      if (this.yesorno == "yes") {
        // console.log("  this.nomineeForm = true;", this.yesorno);

        this.nomineeForm = true;
      } else if (this.yesorno == "no") {
        // console.log("  this.nomineeForm = false;", this.yesorno);

        this.nomineeForm = false;
      }
    },
    // pinecodemepty(){
    //   if(newNomineePincode == ''){
    //     this.pinLoader = true;

    //   }else{
    //     this.pinLoader = false;
    //     this.newNomineePincode
    //   }

    // },
    pincodeFetch() {
      // this.pinLoader = true;
      var axiosThis = this;
      // console.warn("hh");

      if (this.newNomineePincode.length >= 6) {
        this.pinLoader = true;
        // let pincode =this.newNomineePincode.length ? this.newNomineePincode.length>= 6 : ''
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineePincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeState = resp.State;
            axiosThis.newNomineeCountry = resp.Country;
            axiosThis.newNomineeCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;


            console.log(error);
          });
      }

      if (this.pincodeAddress.length >= 6) {
        this.pinLoader = true;

        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.pincodeAddress,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.stateAddress = resp.State;
            axiosThis.countryAddress = resp.Country;
            axiosThis.distAddress = resp.District;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }


      if (this.newNomineeGuardianPincode.length >= 6) {
        this.pinLoader = true;

        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianState = resp.State;
            axiosThis.newNomineeGuardianCountry = resp.Country;
            axiosThis.newNomineeGuardianCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;
            axiosThis.newNomineeGuardianState = ''
            axiosThis.newNomineeGuardianCountry = ''
            axiosThis.newNomineeGuardianCity = ''

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodento.length >= 6) {
        this.pinLoader = true;

        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatento = resp.State;
            axiosThis.newNomineeGuardianCountrynto = resp.Country;
            axiosThis.newNomineeGuardianCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }


      if (this.newNomineeGuardianPincodenthre.length >= 6) {
        this.pinLoader = true;

        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatenthre = resp.State;
            axiosThis.newNomineeGuardianCountrynthre = resp.Country;
            axiosThis.newNomineeGuardianCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }


      if (this.newNomineePincodento.length >= 6) {
        this.pinLoader = true;

        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" + this.newNomineePincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatento = resp.State;
            axiosThis.newNomineeCountrynto = resp.Country;
            axiosThis.newNomineeCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineePincodenthre.length >= 6) {
        this.pinLoader = true;

        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineePincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {

            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatenthre = resp.State;
            axiosThis.newNomineeCountrynthre = resp.Country;
            axiosThis.newNomineeCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }


    },
    save(date) {
      this.$refs.menu.save(date);
      var year = parseInt(date.substring(0, 4));
      var month = parseInt(date.substring(5, 7)) - 1;
      var day = parseInt(date.substring(8, 10));

      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      var nomineeAge = currentYear - year;

      if (month > currentMonth || (month === currentMonth && day > currentDay)) {
        nomineeAge--;
      }
      if (nomineeAge < 18) {
        this.belowAge = true;
      } else {
        this.belowAge = false;
      }
    },
    savento(date) {
      this.$refs.menunto.save(date);
      var year = parseInt(date.substring(0, 4));
      var month = parseInt(date.substring(5, 7)) - 1;
      var day = parseInt(date.substring(8, 10));

      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      var nomineeAge = currentYear - year;

      if (month > currentMonth || (month === currentMonth && day > currentDay)) {
        nomineeAge--;
      }
      if (nomineeAge < 18) {
        this.belowAgento = true;
      } else {
        this.belowAgento = false;
      }
    },
    saventhre(date) {
      this.$refs.menunthre.save(date);
      var year = parseInt(date.substring(0, 4));
      var month = parseInt(date.substring(5, 7)) - 1;
      var day = parseInt(date.substring(8, 10));

      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      var nomineeAge = currentYear - year;

      if (month > currentMonth || (month === currentMonth && day > currentDay)) {
        nomineeAge--;
      }


      if (nomineeAge < 18) {
        this.belowAgenthre = true;
      } else {
        this.belowAgenthre = false;
      }
    },

    savee(date) {
      this.$refs.menuu.save(date);
      var year = parseInt(date.substring(0, 4));
      var month = parseInt(date.substring(5, 7)) - 1;
      var day = parseInt(date.substring(8, 10));

      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      var nomineeAge = currentYear - year;

      if (month > currentMonth || (month === currentMonth && day > currentDay)) {
        nomineeAge--;
      }
      if (nomineeAge < 18) {
        this.datee = "";
        this.errtext = "Guardian must above age 18";
        this.errorSnackbar = true;
      }
    },
    saveento(date) {
      this.$refs.menuunto.save(date);
      var year = parseInt(date.substring(0, 4));
      var month = parseInt(date.substring(5, 7)) - 1;
      var day = parseInt(date.substring(8, 10));

      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      var nomineeAge = currentYear - year;

      if (month > currentMonth || (month === currentMonth && day > currentDay)) {
        nomineeAge--;
      }
      if (nomineeAge < 18) {
        this.dateento = "";
        this.errtext = "Guardian must above age 18";
        this.errorSnackbar = true;
      }
    },
    saveenthre(date) {
      this.$refs.menuunthre.save(date);
      var year = parseInt(date.substring(0, 4));
      var month = parseInt(date.substring(5, 7)) - 1;
      var day = parseInt(date.substring(8, 10));

      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      var nomineeAge = currentYear - year;

      if (month > currentMonth || (month === currentMonth && day > currentDay)) {
        nomineeAge--;
      }
      if (nomineeAge < 18) {
        this.dateenthre = "";
        this.errtext = "Guardian must above age 18";
        this.errorSnackbar = true;
      }
    },
    digioEsign(file, email, session) {

      // console.log(file, email, session, 'kjahsdkjfh')
      var axo = this;
      this.blurLoader = true;
      axo.editdialog = false;
      // console.log(file, email,session)
      // localStorage.setItem('fileid', this.digioFile)
      var options = {
        environment: "production",
        callback: function (t) {
          axo.statusCheckNominee(axo.client_code);
          // console.log(t);
          // console.log(axo.stata);
          axo.blurLoader = false;

          if (axo.stata != undefined) {
            if (axo.stata.app_status == "e-signed pending") {
              axo.radioBtn = true;
              axo.nomineesubmitBtn = true;
            } else {
              axo.radioBtn = false;
              axo.nomineesubmitBtn = false;
            }
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }

          if (t.message == "Signing cancelled") {
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("failure", file);
            axo.fetchMobileStatus();
            location.reload();
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()

            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()
            // }
          } else if (t.message == "Signed Successfully") {
            // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("success", file);
            // }
          }

          if (t["hasOwnProperty"]("error_code")) {
            // document.getElementById("loading").style.display='none';
            //// console.log('Error Digio')
            //// console.log(t.message)
          } else {
            //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
            //successDigio();
            //// console.log(this.step, this.ekycStatus);
            setTimeout(function () {
              //code goes here
              window.location.reload();
            }, 4500);
          }
        },
      };

      var digio = new Digio(options);
      digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
      // digio.submit(this.digioFile, this.digioMail,);
      // console.log(file,email,session)
      digio.submit(file, email, session);
      // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

      // console.log(digio)
    },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    statusCheckNominee(dar) {
this.blurLoader = true
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: dar,
      });

      let config = {
        method: "post",

        url: api.api_url + "/nom_stat",

        headers: {
          "Authorization": this.tok,
          "clientid": dar,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          axiosThis.neresdata = response.data
          // console.log(response.data);
          axiosThis.blurLoader = false;
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired"
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso()

              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid"
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              axiosThis.$router.push("/");
              axiosThis.goSso()

              window.location.reload();
            }, 2000);
          }
          var resp = response.data.nom_stat[0];
          axiosThis.stata = resp;
          if (resp) {
            axiosThis.newNomineeName = resp.nominee1_name
            axiosThis.newNomineeRelation = resp.nominee1_relation
            axiosThis.newNomineeDob = resp.nominee1_DOB
            axiosThis.newNomineepercent = resp.nominee1_percentage
            // if (resp.nominee2_name) {
            // axiosThis.multinominee = resp.
            // axiosThis.nominee2 = true;
            axiosThis.newNomineeName2 = resp.nominee2_name
            axiosThis.newNomineeRelation2 = resp.nominee2_relation
            axiosThis.newNomineeDob2 = resp.nominee2_DOB
            axiosThis.newNomineepercent2 = resp.nominee2_percentage


            axiosThis.newNomineeName3 = resp.nominee3_name
            axiosThis.newNomineeRelation3 = resp.nominee3_relation
            axiosThis.newNomineeDob3 = resp.nominee3_DOB
            axiosThis.newNomineepercent3 = resp.nominee3_percentage
            // }
            if (resp.app_status == "e-signed completed" &&
              resp.nominee_req == "no") {
              axiosThis.radioBtn = true;
              axiosThis.nomineesubmitBtn = true;
              axiosThis.esignBtn = false;

              axiosThis.submissionText =
                "Nominee Submited Successfully " + resp.date_time;
            } else if (resp.app_status == "e-signed completed" &&
              resp.nominee_req == "yes") {
              axiosThis.nomineeYes = true;
              if (axiosThis.newNomineeName2) {
                axiosThis.nominee2 = true

              }
              if (axiosThis.newNomineeName3) {
                axiosThis.nominee3 = true
              }

              axiosThis.radioBtn = true;
              axiosThis.nomineesubmitBtn = true;
              axiosThis.esignBtn = false;

              axiosThis.submissionText =
                "Nominee Submited Successfully " + resp.date_time;
            } else if (
              resp.app_status == "e-signed pending" &&
              resp.nominee_req == "no"
            ) {

              // axiosThis.nomineeYes = true
              axiosThis.radioBtn = true;
              axiosThis.nomineesubmitBtn = true;
              axiosThis.submissionText = "Esign Pending click here to ";
              axiosThis.esignBtn = true;
            } else if (
              resp.app_status == "e-signed pending" &&
              resp.nominee_req == "yes"
            ) {
              axiosThis.nomineeYes = true;
              if (axiosThis.newNomineeName2) {
                axiosThis.nominee2 = true

              }
              if (axiosThis.newNomineeName3) {
                axiosThis.nominee3 = true
              }


              axiosThis.radioBtn = true;
              axiosThis.esignBtn = true;

              axiosThis.nomineesubmitBtn = true;
              axiosThis.submissionText = "Esign Pending " + resp.date_time;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.blurLoader = false

        });
    },


    nomineeSubmitButton11() {
      this.tok = localStorage.getItem("usession");

      this.editType = "nominee";
      var axiosThis = this;
      // console.log("submit");
      var totpercent =
        Number(this.newNomineepercentone) +
        Number(this.newNomineepercentnto) +
        Number(this.newNomineepercentnthre);
      // this.editType = "nominee"
      // console.log('total percent', totpercent)
      var formvalid;
      if (this.nomineeYes == false) {
        formvalid = this.$refs.addNomineeform.validate();
      }
      formvalid = this.$refs.addNomineeform.validate();
      // console.log('stttst222', formvalid)
      if (this.yesorno == "no") {
        // console.log('ifffffffffff', formvalid)

        this.blurLoader = true;
        // 
        const FormData = require("form-data");
        let data = new FormData();
        data.append("clientcode", this.client_code);
        data.append("Nominee1_name", this.newNomineeName);
        data.append("Nominee1_PAN", this.newNomineePan);
        // console.log(this.date,"-----------------")
        data.append("Nominee1_DOB", this.date != null ? this.date : "");
        data.append("Nominee1_relation", this.newNomineeRelation);
        data.append(
          "Nominee1_address1",
          this.checkbox == true && this.yesorno == "yes"
            ? this.profileData.CL_RESI_ADD1
            : this.newNomineeAddress
        );
        data.append(
          "Nominee1_address2",
          this.checkbox == true && this.yesorno == "yes"
            ? this.profileData.CL_RESI_ADD2
            : ""
        );
        data.append(
          "Nominee1_city",
          this.newNomineeCity ? this.newNomineeCity : ""
        );
        data.append(
          "Nominee1_state",
          this.newNomineeState ? this.newNomineeState : ""
        );
        data.append(
          "Nominee1_country",
          this.newNomineeCountry ? this.newNomineeCountry : ""
        );
        data.append(
          "Nominee1_pincode",
          this.newNomineePincode ? this.newNomineePincode : ""
        );
        data.append(
          "Nominee1_mobile",
          this.nomineeMobile != undefined ? this.nomineeMobile : ""
        );
        data.append("Nominee1_email", this.nomineeEmail);
        data.append("client_email", this.profileData.CLIENT_ID_MAIL);
        data.append("client_pan", this.profileData.PAN_NO);
        data.append(
          "Nominee1_percentage",
          this.yesorno == "no"
            ? ""
            : this.checkboxTow
              ? this.newNomineepercentone
              : ""
        );
        data.append("Nominee1_Select_id", "");
        data.append("Nominee2_name", this.newNomineeNameto);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("nominee_req", this.yesorno);
        data.append("client_name", this.name);

        data.append("Nominee2_PAN", this.newNomineePannto);
        data.append("Nominee2_DOB", this.datento != null ? this.datento : "");
        data.append("Nominee2_relation", this.newNomineeRelationto);
        data.append(
          "Nominee2_address1",
          this.checkboxnto == true && this.yesorno == "yes" && this.checkboxTow
            ? this.profileData.CL_RESI_ADD1
            : this.newNomineeAddressnto != undefined
              ? this.newNomineeAddressnto
              : ""
        );
        data.append("Nominee2_address2", "");
        data.append(
          "Nominee2_city",
          this.newNomineeCitynto != undefined ? this.newNomineeCitynto : ""
        );
        data.append(
          "Nominee2_state",
          this.newNomineeStatento != undefined ? this.newNomineeStatento : ""
        );
        data.append(
          "Nominee2_country",
          this.newNomineeCountrynto != undefined
            ? this.newNomineeCountrynto
            : ""
        );
        data.append(
          "Nominee2_pincode",
          this.newNomineePincodento != undefined
            ? this.newNomineePincodento
            : ""
        );
        data.append("Nominee2_mobile", this.nomineeMobilento);
        data.append("Nominee2_email", this.nomineeEmailnto);
        data.append(
          "Nominee2_percentage",
          this.yesorno == "no"
            ? ""
            : this.checkboxTow
              ? this.newNomineepercentnto
              : ""
        );
        data.append("Nominee2_Select_id", "");
        data.append("Nominee3_name", this.newNomineeNamenthre);
        data.append("Nominee3_PAN", this.newNomineePannthre);
        data.append(
          "Nominee3_DOB",
          this.datenthre != null ? this.datenthre : ""
        );
        data.append("Nominee3_relation", this.newNomineeRelationnthre);
        data.append(
          "Nominee3_address1",
          this.checkboxnthre == true &&
            this.yesorno == "yes" &&
            this.checkboxThree
            ? this.profileData.CL_RESI_ADD1
            : this.newNomineeAddressnthre != undefined
              ? this.newNomineeAddressnthre
              : ""
        );
        data.append("Nominee3_address2", "");
        data.append(
          "Nominee3_city",
          this.newNomineeCitynthre != undefined ? this.newNomineeCitynthre : ""
        );
        data.append(
          "Nominee3_state",
          this.newNomineeStatenthre != undefined
            ? this.newNomineeStatenthre
            : ""
        );
        data.append(
          "Nominee3_country",
          this.newNomineeCountrynthre != undefined
            ? this.newNomineeCountrynthre
            : ""
        );
        data.append(
          "Nominee3_pincode",
          this.newNomineePincodenthre != undefined
            ? this.newNomineePincodenthre
            : ""
        );
        data.append("Nominee3_mobile", this.nomineeMobilenthre);
        data.append("Nominee3_email", this.nomineeEmailnthre);
        data.append(
          "Nominee3_percentage",
          this.yesorno == "no"
            ? ""
            : this.checkboxTow && this.checkboxThree
              ? this.newNomineepercentnthre
              : ""
        );
        data.append("Nominee3_Select_id", "");
        data.append("Guardian_name", this.newNomineeGuardian);
        data.append("Guardian_dob", this.datee != null ? this.datee : "");
        data.append("Guardian_address", "");
        data.append("Guardian_city", "");
        data.append("Guardian_state", "");
        data.append("Guardian_country", "");
        data.append("Guardian_mobile", this.newNomineegurdianmobile);
        data.append("Guardian_email", "");
        data.append("Guardian_relation", this.newNomineeGuardianRelation);
        data.append("Guardian_doc_type", "");
        data.append("Guardian_pincode", "");
        data.append("Guardian_name2", this.newNomineeGuardiannto);
        data.append(
          "Guardian_dob2",
          this.dateento != null ? this.dateento : ""
        );
        data.append("Guardian_address2", "");
        data.append("Guardian_city2", "");
        data.append("Guardian_state2", "");
        data.append("Guardian_country2", "");
        data.append("Guardian_mobile2", this.newNomineegurdianmobilento);
        data.append("Guardian_email2", "");
        data.append("Guardian_relation2", this.newNomineeGuardianRelationnto);
        data.append("Guardian_doc_type2", "");
        data.append("Guardian_pincode2", "");
        data.append("Guardian_name3", this.newNomineeGuardiannthre);
        data.append(
          "Guardian_dob3",
          this.dateenthre != null ? this.dateenthre : ""
        );
        data.append("Guardian_address3", "");
        data.append("Guardian_city3", "");
        data.append("Guardian_state3", "");
        data.append("Guardian_country3", "");
        data.append("Guardian_mobile3", this.newNomineegurdianmobilenthre);
        data.append("Guardian_email3", "");
        data.append("Guardian_relation3", this.newNomineeGuardianRelationnthre);
        data.append("Guardian_doc_type3", "");
        data.append("Guardian_pincode3", "");
        // console.log(this.newNomineeProof);
        data.append("nominee_doc1", this.newNomineeProof);
        data.append("nominee_doc2", "");
        data.append("nominee_doc3", "");
        data.append("guardian_doc", "");
        data.append("guardian_doc2", "");
        data.append("guardian_doc3", "");

        axios
          .post(api.api_url + "/nominee", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })

          .then((response) => {
            axiosThis.radioBtn = true;
            axiosThis.nomineesubmitBtn = true;
            // console.log(JSON.stringify(response.data));
            if (response.data.msg == "Success") {
              var resp = response.data;
              axiosThis.statusCheckNominee(this.client_code);
              // axiosThis.panel = false
              window.location.reload();

              // axiosThis.digioEsign(resp.fileid, resp.mailid, resp.session);
              // console.log(resp)
              axiosThis.filedegio = resp.fileid;
            } else if (response.data.msg == "Client already exists") {
              axiosThis.errtext = "Nominee form already initiated";
              axiosThis.errorSnackbar = true;
              axiosThis.blurLoader = false
              // console.log("11111111111111111111")
            }
            else {
              axiosThis.errtext = response.data.msg
              axiosThis.errorSnackbar = true;
              axiosThis.blurLoader = false;
            }
          })
          .catch((error) => {
            console.log(error);
            axiosThis.errtext = error
              axiosThis.errorSnackbar = true;
              axiosThis.blurLoader = false;
          });
      } else if (formvalid == true) {
        // console.log('elsifffffff', formvalid)
        this.blurLoader = true;

        var nomini1 = Number(this.newNomineepercentone);
        var nomini2 = Number(this.newNomineepercentnto);
        var nomini3 = Number(this.newNomineepercentnthre);
        console.warn("aaaaa", nomini1, nomini2, nomini3)

        totpercent =
          Number(this.newNomineepercentone) +
          Number(this.newNomineepercentnto) +
          Number(this.newNomineepercentnthre);
        console.warn("jjjj", totpercent)
        if (this.checkboxTow && this.checkboxThree == false) {
          // console.log("this.checkboxTow && this.checkboxThree == false", this.checkboxTow, this.checkboxThree)
          // console.log("elseifffff")

          if (nomini2 == 0 || nomini1 == 0) {
            // console.log("if =======---else is woking")

            this.errtext = "Invalid nominee sharing percentage";
            this.errorSnackbar = true;
            axiosThis.blurLoader = false;
            //           setTimeout(() => {
            //             location.reload();

            // }, 1000);
          } else {
            // console.log(" elseinside----else is woking", totpercent)

            if (totpercent > 100 || totpercent < 100) {
              // console.log("if --- else----else is woking")

              this.errtext = "Invalid nominee sharing percentage";
              this.errorSnackbar = true;
              axiosThis.blurLoader = false;
              //           setTimeout(() => {
              //             location.reload();

              // }, 1000);
            } else {
              // console.log("else is woking")
              this.blurLoader = true;
              this.per;


              const FormData = require("form-data");
              // var percent1 = 100
              // var percent2 = 0
              // var percent3 = 0
              let data = new FormData();
              data.append("clientcode", this.client_code);
              data.append("Nominee1_name", this.newNomineeName);
              data.append("Nominee1_PAN", this.newNomineePan);
              // console.log(this.date,"-----------------")
              data.append("Nominee1_DOB", this.date != null ? this.date : "");
              data.append(
                "Nominee1_relation",
                this.newNomineeRelation.toUpperCase()
              );
              data.append(
                "Nominee1_address1",
                this.checkbox == true && this.yesorno == "yes"
                  ? this.profileData.CL_RESI_ADD1
                  : this.newNomineeAddress
              );
              data.append(
                "Nominee1_address2",
                this.checkbox == true && this.yesorno == "yes"
                  ? this.profileData.CL_RESI_ADD2
                  : ""
              );
              data.append(
                "Nominee1_city",
                this.newNomineeCity ? this.newNomineeCity : ""
              );
              data.append(
                "Nominee1_state",
                this.newNomineeState ? this.newNomineeState : ""
              );
              data.append(
                "Nominee1_country",
                this.newNomineeCountry ? this.newNomineeCountry : ""
              );
              data.append(
                "Nominee1_pincode",
                this.newNomineePincode ? this.newNomineePincode : ""
              );
              data.append(
                "Nominee1_mobile",
                this.nomineeMobile != undefined ? this.nomineeMobile : ""
              );
              data.append("Nominee1_email", this.nomineeEmail);
              data.append("client_email", this.profileData.CLIENT_ID_MAIL);
              data.append("client_pan", this.profileData.PAN_NO);
              data.append(
                "Nominee1_percentage",
                this.yesorno == "no"
                  ? ""
                  : this.checkboxTow
                    ? this.newNomineepercentone
                    : ""
              );
              data.append("Nominee1_Select_id", "");
              data.append("Nominee2_name", this.newNomineeNameto);
              data.append("dp_code", this.profileData.CLIENT_DP_CODE);
              data.append("nominee_req", this.yesorno);
              data.append("client_name", this.name);

              data.append("Nominee2_PAN", this.newNomineePannto);
              data.append(
                "Nominee2_DOB",
                this.datento != null ? this.datento : ""
              );
              data.append("Nominee2_relation", this.newNomineeRelationto);
              data.append(
                "Nominee2_address1",
                this.checkboxnto == true &&
                  this.yesorno == "yes" &&
                  this.checkboxTow
                  ? this.profileData.CL_RESI_ADD1
                  : this.newNomineeAddressnto != undefined
                    ? this.newNomineeAddressnto
                    : ""
              );
              data.append("Nominee2_address2", "");
              data.append(
                "Nominee2_city",
                this.newNomineeCitynto != undefined
                  ? this.newNomineeCitynto
                  : ""
              );
              data.append(
                "Nominee2_state",
                this.newNomineeStatento != undefined
                  ? this.newNomineeStatento
                  : ""
              );
              data.append(
                "Nominee2_country",
                this.newNomineeCountrynto != undefined
                  ? this.newNomineeCountrynto
                  : ""
              );
              data.append(
                "Nominee2_pincode",
                this.newNomineePincodento != undefined
                  ? this.newNomineePincodento
                  : ""
              );
              data.append("Nominee2_mobile", this.nomineeMobilento);
              data.append("Nominee2_email", this.nomineeEmailnto);
              data.append(
                "Nominee2_percentage",
                this.yesorno == "no"
                  ? ""
                  : this.checkboxTow
                    ? this.newNomineepercentnto
                    : ""
              );
              data.append("Nominee2_Select_id", "");
              data.append("Nominee3_name", this.newNomineeNamenthre);
              data.append("Nominee3_PAN", this.newNomineePannthre);
              data.append(
                "Nominee3_DOB",
                this.datenthre != null ? this.datenthre : ""
              );
              data.append("Nominee3_relation", this.newNomineeRelationnthre);
              data.append(
                "Nominee3_address1",
                this.checkboxnthre == true &&
                  this.yesorno == "yes" &&
                  this.checkboxThree
                  ? this.profileData.CL_RESI_ADD1
                  : this.newNomineeAddressnthre != undefined
                    ? this.newNomineeAddressnthre
                    : ""
              );
              data.append("Nominee3_address2", "");
              data.append(
                "Nominee3_city",
                this.newNomineeCitynthre != undefined
                  ? this.newNomineeCitynthre
                  : ""
              );
              data.append(
                "Nominee3_state",
                this.newNomineeStatenthre != undefined
                  ? this.newNomineeStatenthre
                  : ""
              );
              data.append(
                "Nominee3_country",
                this.newNomineeCountrynthre != undefined
                  ? this.newNomineeCountrynthre
                  : ""
              );
              data.append(
                "Nominee3_pincode",
                this.newNomineePincodenthre != undefined
                  ? this.newNomineePincodenthre
                  : ""
              );
              data.append("Nominee3_mobile", this.nomineeMobilenthre);
              data.append("Nominee3_email", this.nomineeEmailnthre);
              data.append(
                "Nominee3_percentage",
                this.yesorno == "no"
                  ? ""
                  : this.checkboxTow && this.checkboxThree
                    ? this.newNomineepercentnthre
                    : ""
              );
              data.append("Nominee3_Select_id", "");
              data.append("Guardian_name", this.newNomineeGuardian);
              data.append("Guardian_dob", this.datee != null ? this.datee : "");
              data.append(
                "Guardian_address",
                this.belowAge &&
                  this.yesorno == "yes" &&
                  this.newNomineeGuardianAddress != undefined
                  ? this.newNomineeGuardianAddress
                  : ""
              );
              data.append(
                "Guardian_city",
                this.newNomineeGuardianCity != undefined
                  ? this.newNomineeGuardianCity
                  : ""
              );
              data.append(
                "Guardian_state",
                this.newNomineeGuardianState != undefined
                  ? this.newNomineeGuardianState
                  : ""
              );
              data.append(
                "Guardian_country",
                this.newNomineeGuardianCountry != undefined
                  ? this.newNomineeGuardianCountry
                  : ""
              );
              data.append("Guardian_mobile", this.newNomineegurdianmobile);
              data.append("Guardian_email", this.newNomineeGuardianEmail);
              data.append("Guardian_relation", this.newNomineeGuardianRelation);
              data.append("Guardian_doc_type", "");
              data.append(
                "Guardian_pincode",
                this.newNomineeGuardianPincode != undefined
                  ? this.newNomineeGuardianPincode
                  : ""
              );
              data.append("Guardian_name2", this.newNomineeGuardiannto);
              data.append(
                "Guardian_dob2",
                this.dateento != null ? this.dateento : ""
              );
              data.append(
                "Guardian_address2",
                this.belowAgento &&
                  this.yesorno == "yes" &&
                  this.newNomineeGuardianAddressnto != undefined
                  ? this.newNomineeGuardianAddressnto
                  : ""
              );
              data.append(
                "Guardian_city2",
                this.newNomineeGuardianCitynto != undefined
                  ? this.newNomineeGuardianCitynto
                  : ""
              );
              data.append(
                "Guardian_state2",
                this.newNomineeGuardianStatento != undefined
                  ? this.newNomineeGuardianStatento
                  : ""
              );
              data.append(
                "Guardian_country2",
                this.newNomineeGuardianCountrynto != undefined
                  ? this.newNomineeGuardianCountrynto
                  : ""
              );
              data.append("Guardian_mobile2", this.newNomineegurdianmobilento);
              data.append("Guardian_email2", this.newNomineeGuardianEmailnto);
              data.append(
                "Guardian_relation2",
                this.newNomineeGuardianRelationnto
              );
              data.append("Guardian_doc_type2", "");
              data.append(
                "Guardian_pincode2",
                this.newNomineeGuardianPincodento != undefined
                  ? this.newNomineeGuardianPincodento
                  : ""
              );
              data.append("Guardian_name3", this.newNomineeGuardiannthre);
              data.append(
                "Guardian_dob3",
                this.dateenthre != null ? this.dateenthre : ""
              );
              data.append(
                "Guardian_address3",
                this.belowAgenthre &&
                  this.yesorno == "yes" &&
                  this.newNomineeGuardianAddressnthre != undefined
                  ? this.newNomineeGuardianAddressnthre
                  : ""
              );
              data.append(
                "Guardian_city3",
                this.newNomineeGuardianCitynthre != undefined
                  ? this.newNomineeGuardianCitynthre
                  : ""
              );
              data.append(
                "Guardian_state3",
                this.newNomineeGuardianStatenthre != undefined
                  ? this.newNomineeGuardianStatenthre
                  : ""
              );
              data.append(
                "Guardian_country3",
                this.newNomineeGuardianCountrynthre != undefined
                  ? this.newNomineeGuardianCountrynthre
                  : ""
              );
              data.append(
                "Guardian_mobile3",
                this.newNomineegurdianmobilenthre
              );
              data.append("Guardian_email3", this.newNomineeGuardianEmailnthre);
              data.append(
                "Guardian_relation3",
                this.newNomineeGuardianRelationnthre
              );
              data.append("Guardian_doc_type3", "");
              data.append(
                "Guardian_pincode3",
                this.newNomineeGuardianPincodenthre != undefined
                  ? this.newNomineeGuardianPincodenthre
                  : ""
              );
              // console.log(this.newNomineeProof);
              data.append("nominee_doc1", this.newNomineeProof);
              data.append("nominee_doc2", "");
              data.append("nominee_doc3", "");
              data.append("guardian_doc", "");
              data.append("guardian_doc2", "");
              data.append("guardian_doc3", "");
              // console.log("commmm12333")
              // var axiosThis = this;

              // axios
              // .post(api.api_url + "/nominee", data, {
              //   headers: {
              //     Authorization: this.tok,
              //     clientid: this.profileData.CLIENT_ID,
              //     "Content-Type": "multipart/form-data",
              //   },
              // });

              let config = {
                method: "post",

                url: `${api.api_url}` + "/nominee", data,
                headers: {
                  Authorization: this.tok,
                  clientid: this.profileData.CLIENT_ID,
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              };

              // console.log("workinn inside")
              // var axiosThis = this;
              axios
                .request(config)
                .then((response) => {
                  // console.log(JSON.stringify(response.data));
                  axiosThis.blurLoader = false;
                  // console.log("workinn inside succccc")

                  axiosThis.radioBtn = true;
                  axiosThis.nomineesubmitBtn = true;
                  if (response.data.msg == "Success") {
                    var resp = response.data;
                    // axiosThis.digioEsign(
                    //   resp.fileid,
                    //   resp.mailid,
                    //   resp.session
                    // );
                    axiosThis.statusCheckNominee(this.client_code);
              // axiosThis.panel = false
              window.location.reload();


                    axiosThis.filedegio = resp.fileid;

                  } else if (response.data.msg == "Client already exists") {
                    axiosThis.errtext = "Nominee form already initiated";
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false;
                    // console.log("222222222222")

                    //           setTimeout(() => {
                    //             location.reload();

                    // }, 1000);
                  } else {
                    axiosThis.errtext = response.data.msg
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  axiosThis.errtext = error
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false;
                });
            }
          }
        } else if (this.checkboxTow && this.checkboxThree) {
          // console.log("firstelseifffff")

          if (nomini2 == 0 || nomini1 == 0 || nomini3 == 0) {
            axiosThis.
              this.errtext = "Invalid nominee sharing percentage";
            this.errorSnackbar = true;
            axiosThis.blurLoader = false;
            //           setTimeout(() => {
            //             location.reload();

            // }, 1000);
          } else {
            if (totpercent > 100 || totpercent < 100) {
              this.errtext = "Invalid nominee sharing percentage";
              this.errorSnackbar = true;
              axiosThis.blurLoader = false;
              //           setTimeout(() => {
              //             location.reload();

              // }, 1000);
            } else {
              // console.warn("else")


              const FormData = require("form-data");
              // var percent1 = 100
              // var percent2 = 0
              // var percent3 = 0
              var data = new FormData();
              data.append("clientcode", this.client_code);
              data.append("Nominee1_name", this.newNomineeName);
              data.append("Nominee1_PAN", this.newNomineePan);
              // console.log(this.date,"-----------------")
              data.append("Nominee1_DOB", this.date != null ? this.date : "");
              data.append(
                "Nominee1_relation",
                this.newNomineeRelation.toUpperCase()
              );
              data.append(
                "Nominee1_address1",
                this.checkbox == true && this.yesorno == "yes"
                  ? this.profileData.CL_RESI_ADD1
                  : this.newNomineeAddress
              );
              data.append(
                "Nominee1_address2",
                this.checkbox == true && this.yesorno == "yes"
                  ? this.profileData.CL_RESI_ADD2
                  : ""
              );
              data.append(
                "Nominee1_city",
                this.newNomineeCity ? this.newNomineeCity : ""
              );
              data.append(
                "Nominee1_state",
                this.newNomineeState ? this.newNomineeState : ""
              );
              data.append(
                "Nominee1_country",
                this.newNomineeCountry ? this.newNomineeCountry : ""
              );
              data.append(
                "Nominee1_pincode",
                this.newNomineePincode ? this.newNomineePincode : ""
              );
              data.append(
                "Nominee1_mobile",
                this.nomineeMobile != undefined ? this.nomineeMobile : ""
              );
              data.append("Nominee1_email", this.nomineeEmail);
              data.append("client_email", this.profileData.CLIENT_ID_MAIL);
              data.append("client_pan", this.profileData.PAN_NO);
              data.append(
                "Nominee1_percentage",
                this.yesorno == "no"
                  ? ""
                  : this.checkboxTow
                    ? this.newNomineepercentone
                    : ""
              );
              data.append("Nominee1_Select_id", "");
              data.append("Nominee2_name", this.newNomineeNameto);
              data.append("dp_code", this.profileData.CLIENT_DP_CODE);
              data.append("nominee_req", this.yesorno);
              data.append("client_name", this.name);

              data.append("Nominee2_PAN", this.newNomineePannto);
              data.append(
                "Nominee2_DOB",
                this.datento != null ? this.datento : ""
              );
              data.append("Nominee2_relation", this.newNomineeRelationto);
              data.append(
                "Nominee2_address1",
                this.checkboxnto == true &&
                  this.yesorno == "yes" &&
                  this.checkboxTow
                  ? this.profileData.CL_RESI_ADD1
                  : this.newNomineeAddressnto != undefined
                    ? this.newNomineeAddressnto
                    : ""
              );
              data.append("Nominee2_address2", "");
              data.append(
                "Nominee2_city",
                this.newNomineeCitynto != undefined
                  ? this.newNomineeCitynto
                  : ""
              );
              data.append(
                "Nominee2_state",
                this.newNomineeStatento != undefined
                  ? this.newNomineeStatento
                  : ""
              );
              data.append(
                "Nominee2_country",
                this.newNomineeCountrynto != undefined
                  ? this.newNomineeCountrynto
                  : ""
              );
              data.append(
                "Nominee2_pincode",
                this.newNomineePincodento != undefined
                  ? this.newNomineePincodento
                  : ""
              );
              data.append("Nominee2_mobile", this.nomineeMobilento);
              data.append("Nominee2_email", this.nomineeEmailnto);
              data.append(
                "Nominee2_percentage",
                this.yesorno == "no"
                  ? ""
                  : this.checkboxTow
                    ? this.newNomineepercentnto
                    : ""
              );
              data.append("Nominee2_Select_id", "");
              data.append("Nominee3_name", this.newNomineeNamenthre);
              data.append("Nominee3_PAN", this.newNomineePannthre);
              data.append(
                "Nominee3_DOB",
                this.datenthre != null ? this.datenthre : ""
              );
              data.append("Nominee3_relation", this.newNomineeRelationnthre);
              data.append(
                "Nominee3_address1",
                this.checkboxnthre == true &&
                  this.yesorno == "yes" &&
                  this.checkboxThree
                  ? this.profileData.CL_RESI_ADD1
                  : this.newNomineeAddressnthre != undefined
                    ? this.newNomineeAddressnthre
                    : ""
              );
              data.append("Nominee3_address2", "");
              data.append(
                "Nominee3_city",
                this.newNomineeCitynthre != undefined
                  ? this.newNomineeCitynthre
                  : ""
              );
              data.append(
                "Nominee3_state",
                this.newNomineeStatenthre != undefined
                  ? this.newNomineeStatenthre
                  : ""
              );
              data.append(
                "Nominee3_country",
                this.newNomineeCountrynthre != undefined
                  ? this.newNomineeCountrynthre
                  : ""
              );
              data.append(
                "Nominee3_pincode",
                this.newNomineePincodenthre != undefined
                  ? this.newNomineePincodenthre
                  : ""
              );
              data.append("Nominee3_mobile", this.nomineeMobilenthre);
              data.append("Nominee3_email", this.nomineeEmailnthre);
              data.append(
                "Nominee3_percentage",
                this.yesorno == "no"
                  ? ""
                  : this.checkboxTow && this.checkboxThree
                    ? this.newNomineepercentnthre
                    : ""
              );
              data.append("Nominee3_Select_id", "");
              data.append("Guardian_name", this.newNomineeGuardian);
              data.append("Guardian_dob", this.datee != null ? this.datee : "");
              data.append(
                "Guardian_address",
                this.belowAge &&
                  this.yesorno == "yes" &&
                  this.newNomineeGuardianAddress != undefined
                  ? this.newNomineeGuardianAddress
                  : ""
              );
              data.append(
                "Guardian_city",
                this.newNomineeGuardianCity != undefined
                  ? this.newNomineeGuardianCity
                  : ""
              );
              data.append(
                "Guardian_state",
                this.newNomineeGuardianState != undefined
                  ? this.newNomineeGuardianState
                  : ""
              );
              data.append(
                "Guardian_country",
                this.newNomineeGuardianCountry != undefined
                  ? this.newNomineeGuardianCountry
                  : ""
              );
              data.append("Guardian_mobile", this.newNomineegurdianmobile);
              data.append("Guardian_email", this.newNomineeGuardianEmail);
              data.append("Guardian_relation", this.newNomineeGuardianRelation);
              data.append("Guardian_doc_type", "");
              data.append(
                "Guardian_pincode",
                this.newNomineeGuardianPincode != undefined
                  ? this.newNomineeGuardianPincode
                  : ""
              );
              data.append("Guardian_name2", this.newNomineeGuardiannto);
              data.append(
                "Guardian_dob2",
                this.dateento != null ? this.dateento : ""
              );
              data.append(
                "Guardian_address2",
                this.belowAgento &&
                  this.yesorno == "yes" &&
                  this.newNomineeGuardianAddressnto != undefined
                  ? this.newNomineeGuardianAddressnto
                  : ""
              );
              data.append(
                "Guardian_city2",
                this.newNomineeGuardianCitynto != undefined
                  ? this.newNomineeGuardianCitynto
                  : ""
              );
              data.append(
                "Guardian_state2",
                this.newNomineeGuardianStatento != undefined
                  ? this.newNomineeGuardianStatento
                  : ""
              );
              data.append(
                "Guardian_country2",
                this.newNomineeGuardianCountrynto != undefined
                  ? this.newNomineeGuardianCountrynto
                  : ""
              );
              data.append("Guardian_mobile2", this.newNomineegurdianmobilento);
              data.append("Guardian_email2", this.newNomineeGuardianEmailnto);
              data.append(
                "Guardian_relation2",
                this.newNomineeGuardianRelationnto
              );
              data.append("Guardian_doc_type2", "");
              data.append(
                "Guardian_pincode2",
                this.newNomineeGuardianPincodento != undefined
                  ? this.newNomineeGuardianPincodento
                  : ""
              );
              data.append("Guardian_name3", this.newNomineeGuardiannthre);
              data.append(
                "Guardian_dob3",
                this.dateenthre != null ? this.dateenthre : ""
              );
              data.append(
                "Guardian_address3",
                this.belowAgenthre &&
                  this.yesorno == "yes" &&
                  this.newNomineeGuardianAddressnthre != undefined
                  ? this.newNomineeGuardianAddressnthre
                  : ""
              );
              data.append(
                "Guardian_city3",
                this.newNomineeGuardianCitynthre != undefined
                  ? this.newNomineeGuardianCitynthre
                  : ""
              );
              data.append(
                "Guardian_state3",
                this.newNomineeGuardianStatenthre != undefined
                  ? this.newNomineeGuardianStatenthre
                  : ""
              );
              data.append(
                "Guardian_country3",
                this.newNomineeGuardianCountrynthre != undefined
                  ? this.newNomineeGuardianCountrynthre
                  : ""
              );
              data.append(
                "Guardian_mobile3",
                this.newNomineegurdianmobilenthre
              );
              data.append("Guardian_email3", this.newNomineeGuardianEmailnthre);
              data.append(
                "Guardian_relation3",
                this.newNomineeGuardianRelationnthre
              );
              data.append("Guardian_doc_type3", "");
              data.append(
                "Guardian_pincode3",
                this.newNomineeGuardianPincodenthre != undefined
                  ? this.newNomineeGuardianPincodenthre
                  : ""
              );
              // console.log(this.newNomineeProof);
              data.append("nominee_doc1", this.newNomineeProof);
              data.append("nominee_doc2", "");
              data.append("nominee_doc3", "");
              data.append("guardian_doc", "");
              data.append("guardian_doc2", "");
              data.append("guardian_doc3", "");

              axios
                .post(api.api_url + "/nominee", data, {
                  headers: {
                    Authorization: this.tok,
                    clientid: this.profileData.CLIENT_ID,
                    "Content-Type": "multipart/form-data",
                  },
                })

                .then((response) => {
                  axiosThis.blurLoader = false;

                  axiosThis.radioBtn = true;
                  axiosThis.nomineesubmitBtn = true;
                  // console.log(JSON.stringify(response.data));
                  if (response.data.msg == "Success") {
                    var resp = response.data;
                    // axiosThis.digioEsign(
                    //   resp.fileid,
                    //   resp.mailid,
                    //   resp.session
                    // );
                    axiosThis.statusCheckNominee(this.client_code);
              // axiosThis.panel = false
              window.location.reload();


                    axiosThis.filedegio = resp.fileid;
                  } else if (response.data.msg == "Client already exists") {
                    axiosThis.errtext = "Nominee form already initiated";
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false
                    // console.log("333333333333333")

                    //           setTimeout(() => {
                    //             location.reload();

                    // }, 1000);
                  } else {
                    axiosThis.errtext = response.data.msg
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  axiosThis.errtext = error
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false;
                });
            }
          }
        } else if (this.checkboxTow == false && this.checkboxThree == false) {
          // console.log("secondelseifffff")

          this.per;
          // console.warn("else")


          const FormData = require("form-data");
          // var percent1 = 100
          // var percent2 = 0
          // var percent3 = 0
          let data = new FormData();
          data.append("clientcode", this.client_code);
          data.append("Nominee1_name", this.newNomineeName);
          data.append("Nominee1_PAN", this.newNomineePan);
          // console.log(this.date,"-----------------")
          data.append("Nominee1_DOB", this.date != null ? this.date : "");
          data.append(
            "Nominee1_relation",
            this.newNomineeRelation.toUpperCase()
          );
          data.append(
            "Nominee1_address1",
            this.checkbox == true && this.yesorno == "yes"
              ? this.profileData.CL_RESI_ADD1
              : this.newNomineeAddress
          );
          data.append(
            "Nominee1_address2",
            this.checkbox == true && this.yesorno == "yes"
              ? this.profileData.CL_RESI_ADD2
              : ""
          );
          data.append(
            "Nominee1_city",
            this.newNomineeCity ? this.newNomineeCity : ""
          );
          data.append(
            "Nominee1_state",
            this.newNomineeState ? this.newNomineeState : ""
          );
          data.append(
            "Nominee1_country",
            this.newNomineeCountry ? this.newNomineeCountry : ""
          );
          data.append(
            "Nominee1_pincode",
            this.newNomineePincode ? this.newNomineePincode : ""
          );
          data.append(
            "Nominee1_mobile",
            this.nomineeMobile != undefined ? this.nomineeMobile : ""
          );
          data.append("Nominee1_email", this.nomineeEmail);
          data.append("client_email", this.profileData.CLIENT_ID_MAIL);
          data.append("client_pan", this.profileData.PAN_NO);
          data.append("Nominee1_percentage", this.newNomineepercentone);
          data.append("Nominee1_Select_id", "");
          data.append("Nominee2_name", this.newNomineeNameto);
          data.append("dp_code", this.profileData.CLIENT_DP_CODE);
          data.append("nominee_req", this.yesorno);
          data.append("client_name", this.name);

          data.append("Nominee2_PAN", this.newNomineePannto);
          data.append("Nominee2_DOB", this.datento != null ? this.datento : "");
          data.append("Nominee2_relation", this.newNomineeRelationto);
          data.append(
            "Nominee2_address1",
            this.checkboxnto == true &&
              this.yesorno == "yes" &&
              this.checkboxTow
              ? this.profileData.CL_RESI_ADD1
              : this.newNomineeAddressnto != undefined
                ? this.newNomineeAddressnto
                : ""
          );
          data.append("Nominee2_address2", "");
          data.append(
            "Nominee2_city",
            this.newNomineeCitynto != undefined ? this.newNomineeCitynto : ""
          );
          data.append(
            "Nominee2_state",
            this.newNomineeStatento != undefined ? this.newNomineeStatento : ""
          );
          data.append(
            "Nominee2_country",
            this.newNomineeCountrynto != undefined
              ? this.newNomineeCountrynto
              : ""
          );
          data.append(
            "Nominee2_pincode",
            this.newNomineePincodento != undefined
              ? this.newNomineePincodento
              : ""
          );
          data.append("Nominee2_mobile", this.nomineeMobilento);
          data.append("Nominee2_email", this.nomineeEmailnto);
          data.append(
            "Nominee2_percentage",
            this.yesorno == "no"
              ? ""
              : this.checkboxTow
                ? this.newNomineepercentnto
                : ""
          );
          data.append("Nominee2_Select_id", "");
          data.append("Nominee3_name", this.newNomineeNamenthre);
          data.append("Nominee3_PAN", this.newNomineePannthre);
          data.append(
            "Nominee3_DOB",
            this.datenthre != null ? this.datenthre : ""
          );
          data.append("Nominee3_relation", this.newNomineeRelationnthre);
          data.append(
            "Nominee3_address1",
            this.checkboxnthre == true &&
              this.yesorno == "yes" &&
              this.checkboxThree
              ? this.profileData.CL_RESI_ADD1
              : this.newNomineeAddressnthre != undefined
                ? this.newNomineeAddressnthre
                : ""
          );
          data.append("Nominee3_address2", "");
          data.append(
            "Nominee3_city",
            this.newNomineeCitynthre != undefined
              ? this.newNomineeCitynthre
              : ""
          );
          data.append(
            "Nominee3_state",
            this.newNomineeStatenthre != undefined
              ? this.newNomineeStatenthre
              : ""
          );
          data.append(
            "Nominee3_country",
            this.newNomineeCountrynthre != undefined
              ? this.newNomineeCountrynthre
              : ""
          );
          data.append(
            "Nominee3_pincode",
            this.newNomineePincodenthre != undefined
              ? this.newNomineePincodenthre
              : ""
          );
          data.append("Nominee3_mobile", this.nomineeMobilenthre);
          data.append("Nominee3_email", this.nomineeEmailnthre);
          data.append(
            "Nominee3_percentage",
            this.yesorno == "no"
              ? ""
              : this.checkboxTow && this.checkboxThree
                ? this.newNomineepercentnthre
                : ""
          );
          data.append("Nominee3_Select_id", "");
          data.append("Guardian_name", this.newNomineeGuardian);
          data.append("Guardian_dob", this.datee != null ? this.datee : "");
          data.append(
            "Guardian_address",
            this.belowAge &&
              this.yesorno == "yes" &&
              this.newNomineeGuardianAddress != undefined
              ? this.newNomineeGuardianAddress
              : ""
          );
          data.append(
            "Guardian_city",
            this.newNomineeGuardianCity != undefined
              ? this.newNomineeGuardianCity
              : ""
          );
          data.append(
            "Guardian_state",
            this.newNomineeGuardianState != undefined
              ? this.newNomineeGuardianState
              : ""
          );
          data.append(
            "Guardian_country",
            this.newNomineeGuardianCountry != undefined
              ? this.newNomineeGuardianCountry
              : ""
          );
          data.append("Guardian_mobile", this.newNomineegurdianmobile);
          data.append("Guardian_email", this.newNomineeGuardianEmail);
          data.append("Guardian_relation", this.newNomineeGuardianRelation);
          data.append("Guardian_doc_type", "");
          data.append(
            "Guardian_pincode",
            this.newNomineeGuardianPincode != undefined
              ? this.newNomineeGuardianPincode
              : ""
          );
          data.append("Guardian_name2", this.newNomineeGuardiannto);
          data.append(
            "Guardian_dob2",
            this.dateento != null ? this.dateento : ""
          );
          data.append(
            "Guardian_address2",
            this.belowAgento &&
              this.yesorno == "yes" &&
              this.newNomineeGuardianAddressnto != undefined
              ? this.newNomineeGuardianAddressnto
              : ""
          );
          data.append(
            "Guardian_city2",
            this.newNomineeGuardianCitynto != undefined
              ? this.newNomineeGuardianCitynto
              : ""
          );
          data.append(
            "Guardian_state2",
            this.newNomineeGuardianStatento != undefined
              ? this.newNomineeGuardianStatento
              : ""
          );
          data.append(
            "Guardian_country2",
            this.newNomineeGuardianCountrynto != undefined
              ? this.newNomineeGuardianCountrynto
              : ""
          );
          data.append("Guardian_mobile2", this.newNomineegurdianmobilento);
          data.append("Guardian_email2", this.newNomineeGuardianEmailnto);
          data.append("Guardian_relation2", this.newNomineeGuardianRelationnto);
          data.append("Guardian_doc_type2", "");
          data.append(
            "Guardian_pincode2",
            this.newNomineeGuardianPincodento != undefined
              ? this.newNomineeGuardianPincodento
              : ""
          );
          data.append("Guardian_name3", this.newNomineeGuardiannthre);
          data.append(
            "Guardian_dob3",
            this.dateenthre != null ? this.dateenthre : ""
          );
          data.append(
            "Guardian_address3",
            this.belowAgenthre &&
              this.yesorno == "yes" &&
              this.newNomineeGuardianAddressnthre != undefined
              ? this.newNomineeGuardianAddressnthre
              : ""
          );
          data.append(
            "Guardian_city3",
            this.newNomineeGuardianCitynthre != undefined
              ? this.newNomineeGuardianCitynthre
              : ""
          );
          data.append(
            "Guardian_state3",
            this.newNomineeGuardianStatenthre != undefined
              ? this.newNomineeGuardianStatenthre
              : ""
          );
          data.append(
            "Guardian_country3",
            this.newNomineeGuardianCountrynthre != undefined
              ? this.newNomineeGuardianCountrynthre
              : ""
          );
          data.append("Guardian_mobile3", this.newNomineegurdianmobilenthre);
          data.append("Guardian_email3", this.newNomineeGuardianEmailnthre);
          data.append(
            "Guardian_relation3",
            this.newNomineeGuardianRelationnthre
          );
          data.append("Guardian_doc_type3", "");
          data.append(
            "Guardian_pincode3",
            this.newNomineeGuardianPincodenthre != undefined
              ? this.newNomineeGuardianPincodenthre
              : ""
          );
          // console.log(this.newNomineeProof);
          data.append("nominee_doc1", this.newNomineeProof);
          data.append("nominee_doc2", "");
          data.append("nominee_doc3", "");
          data.append("guardian_doc", "");
          data.append("guardian_doc2", "");
          data.append("guardian_doc3", "");

          axios
            .post(api.api_url + "/nominee", data, {
              headers: {
                Authorization: this.tok,
                clientid: this.profileData.CLIENT_ID,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              axiosThis.radioBtn = true;
              axiosThis.nomineesubmitBtn = true;
              // console.log(JSON.stringify(response.data));
              if (response.data.msg == "Success") {
                var resp = response.data;
                // axiosThis.digioEsign(resp.fileid, resp.mailid, resp.session);
                axiosThis.statusCheckNominee(this.client_code);
              // axiosThis.panel = false
              window.location.reload();


                axiosThis.filedegio = resp.fileid;
              } else if (response.data.msg == "Client already exists") {
                axiosThis.errtext = "Nominee form already initiated";
                axiosThis.errorSnackbar = true;
                axiosThis.blurLoader = false;
                //           setTimeout(() => {
                //             location.reload();

                // }, 1000);
              }
              else {
                axiosThis.errtext = response.data.msg
                axiosThis.errorSnackbar = true;
                axiosThis.blurLoader = false;
                setTimeout(() => {
                  location.reload();

                }, 1500);
              }
            })
            .catch((error) => {
              console.log(error);
              axiosThis.errtext = error
                    axiosThis.errorSnackbar = true;
                    axiosThis.blurLoader = false;
            });
        }
      }
    }, nomineUpdate() {
      // console.log("clicke");
      this.editdialog = true;
      this.nomineePop = true;
      this.personalDetail = false;
    },

    fetchMobileStatus() {
      this.blurLoader = true
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          axiosThis.blurLoader = false

          if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
          // console.log(response.data);
          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.blurLoader = false

        });
    },

    digilockerAdressSubmit() {
      var axiosThis = this;

      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;

      // var url_data = this.$route.params;
      // console.log(token,"poiuytrewqqqqqqasdfghjkllkmnbvcxz");

      // var addressvalid = this.$refs.formadress.validate();
      if (token && state) {
        this.editType = "address_change";
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", "");
        data.append("proff", "");
        data.append("cur_address", "");
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append(
          "client_email",
          this.profileData.CLIENT_ID_MAIL.toUpperCase()
        );
        data.append("aadhar_address", "");
        data.append("adr_manual", "aadhar");
        data.append("code", token);
        data.append("state", state);

        // let config = {
        //   method: 'post',
        //   // 
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              // axiosThis.digioEsign(
              //   response.data.fileid,
              //   response.data.mailid,
              //   response.data.session
              // );
              axiosThis.statusCheckNominee(this.client_code);
              // axiosThis.panel = false
              window.location.reload();

            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            axiosThis.$router.replace("/");
            // window.location.assign(window.location.href.split('?')[0])

            // const query = this.$route.query;
            //   delete query.code;
            //   delete query.state;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    checkSame() {
      if (this.profileData.MOBILE_NO == this.usernewmobile) {
        this.usernewmobile = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new Mobile number";
      }
    },
    mobileEdit(data) {
      this.tok = localStorage.getItem("usession");

      if (data == "mobile") {
        this.currentClick = "mobile";
        this.editType = "mobile_change";
        // this.cardTitle = 'Mobile Number Verification'
        this.subTitle = "Enter your new mobile number";
        this.requestotp = true;
        this.requestotpemail = false;
        this.bankchange = false;
        this.editdialog = true;

        this.annualIncomechange = false;
        this.mobileLoader = false;

        this.nosegmentsPop = false;
        this.segments = false;

        this.adresschange = false;
        // this.addressVerification = false;
      }
      if (data == "email") {
        this.currentClick = "email";
        this.editType = "email_change";
        this.requestotpemail = true;
        this.requestotp = false;
        this.editdialog = true;

        this.nosegmentsPop = false;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.addressVerification = false;
        this.adresschange = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Enter your new email number";
      }
      if (data == "address") {
        this.currentClick = "address";
        this.editType = "address_change";
        this.adressOptions = true;
        this.manual = false;
        this.nosegmentsPop = false;
        this.editdialog = true;

        this.requestotp = false;
        this.requestotpemail = false;
        this.adresschange = true;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      if (data == "addbank") {
        this.currentClick = "addbank";
        this.editType = "bank_change";
        this.adressOptions = false;
        this.manual = false;
        this.editdialog = true;

        this.requestotp = false;
        this.nosegmentsPop = false;
        this.requestotpemail = false;
        this.adresschange = false;
        this.segments = false;
        this.annualIncomechange = false;
        this.bankproofTypes = "Saving Account";
        this.bankchange = true;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      if (data == "income") {
        this.currentClick = "income";
        this.editType = "income_change";
        this.adressOptions = false;
        this.manual = false;
        this.requestotp = false;
        this.requestotpemail = false;
        this.editdialog = true;

        this.adresschange = false;
        this.bankchange = false;
        this.segments = false;
        this.annualIncomechange = true;
        this.nosegmentsPop = false;
        this.annualIncomeverifyotp = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adress ";
        if (this.profileData.ANNUAL_INCOME == "Less Then One Lakhs") {
          this.selectionas = 0;
          // console.log("responce",0);
        }
        if (this.profileData.ANNUAL_INCOME == "One To Five Lakhs") {
          this.selectionas = 1;
          // console.log("responce",1);
        }
        if (this.profileData.ANNUAL_INCOME == "Five To Ten Lakhs") {
          // console.log("responce",2);
          this.selectionas = 2;
        }
        if (this.profileData.ANNUAL_INCOME == "Ten To TwentyFive Lakhs") {
          // console.log("responce",3);
          this.selectionas = 3;
        }
        if (
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To Fifty Lakhs" ||
          this.profileData.ANNUAL_INCOME == "Above Five Crore" ||
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To One Crore"
        ) {
          // console.log("responce",4);
          this.selectionas = 4;
        }
      }
      if (data == "segment") {
        this.inactiveSegments = [];
        this.existingSegments = [];
        this.currentClick = "Segment";
        this.editType = "Segment_change";
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to activate segment";
        for (var a = 0; a < this.equty.length; a++) {
          if (this.equty[a].COMPANY_CODE == "BSE_CASH") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);
              this.bsecash = true;
              this.bsecashDisable = true;
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
            // console.log(this.equty[a],"bse")
          }
          if (this.equty[a].COMPANY_CODE == "MF_BSE") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);

              this.mfbse = true;
              this.mfDisable = true;
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
            // console.log(this.equty[a],"mf")
          }
          if (this.equty[a].COMPANY_CODE == "NSE_CASH") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);

              this.nsecash = true;
              this.nsecashDisable = true;
              // console.log(this.equty[a],"nse")
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
          }

          // }
        }
        for (var b = 0; b < this.derivat.length; b++) {
          if (this.derivat[b].COMPANY_CODE == "BSE_FNO") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.bsefno = true;
              this.bsefnoDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "NSE_FNO") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.nsefno = true;
              this.nsefnoDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "CD_NSE") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.cdnse = true;
              this.cdnseDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "CD_BSE") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.cdbse = true;
              this.cdbseDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          // }
        }

        for (var c = 0; c < this.commoditi.length; c++) {
          if (this.commoditi[c].COMPANY_CODE == "BSE_COM") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.bsecom = true;
              this.bsecomDisable = true;
              // console.log(this.commoditi[c],"bse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "NSE_COM") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.nsecom = true;
              this.nsecomDisable = true;
              // console.log(this.commoditi[c],"mf")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "ICEX") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.icex = true;
              this.icexDisable = true;
              // console.log(this.commoditi[a],"nse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "MCX") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.mcx = true;
              this.mcxDisable = true;
              // console.log(this.commoditi[c],"nse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          // }
        }
        var array1;
        var array2;

        // for (var i = 0;i<this.allSegments.length;i++) {
        let arr = this.allSegments;
        // let remove = arr.filter((id) => id !== this.existingSegments[i].COMPANY_CODE)
        array1 = arr.filter((item) => !this.existingSegments.includes(item));
        array2 = array1.filter((item) => !this.inactiveSegments.includes(item));

        this.newSegments = array2;
        // console.log(array1, this.existingSegments.length);
        if (array2.length == 11) {
          this.nosegmentsPop = true;
        } else {
          this.editdialog = true;
          this.adressOptions = false;
          this.manual = false;
          this.requestotp = false;
          this.requestotpemail = false;
          this.adresschange = false;
          this.bankchange = false;
          this.annualIncomechange = false;
          this.segments = true;
          this.annualIncomeverifyotp = false;
          this.segOpt = false;
          this.addNewSeg = true;
          this.activateSeg = false;
          this.nosegmentsPop = false;
        }
        // [1,2,3,4,6]
        // }
        // console.log(this.inactiveSegments,"......",this.existingSegments,this.allSegments)
      }
      // console.log("edit mobile");
    },
    valu(val) {
      // console.log(val, "val");
      const validat = val;
      if (validat == false) {
        this.createChartsred();
      }
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },

    getAvailBal(dataa) {
      var axiosThis = this;
      var dall = JSON.stringify({
        client_id: dataa,
      });
      var enc = this.encryptionFunction(dall);
      // console.log(dall, enc);
      let data = JSON.stringify({
        string: enc,
      });

      let config = {
        method: "post",
        // url: 'http://192.168.5.121:5555/profile',
        url: api.api_url + "/profile",

        headers: {
          Authorization: this.tok,
          clientid: dataa,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
          if (!response.data.emsg) {
            if (!response.data.msg) {
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(response.data.str);
              // console.log(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              localStorage.setItem('clinet_name', axiosThis.name)

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation =
                  axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }
              axiosThis.fetchMobileStatus();

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
              for (
                var i = 0;
                i < axiosThis.profileData.segments_data.length;
                i++
              ) {
                // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE);
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_NSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_BSE"
                ) {
                  axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_BSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_NSE"
                ) {
                  axiosThis.derivat.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MCX" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX"
                ) {
                  axiosThis.commoditi.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
              }
              // console.log(axiosThis.derivat);
              // axiosThis.payout.ledger_amount = axiosThis.payout.ledger_amount.toFixed(2)
              // axiosThis.payout.withdraw_amount = axiosThis.payout.withdraw_amount.toFixed(2)

              // axiosThis.cashledg = axiosThis.payout.ledger_amount
            } else {
              // msg = axiosThis.decryptionFunction(response.data.msg);
              // console.warn(JSON.parse(msg));
            }
          } 
          //  else {
          //     axiosThis.errtext = "Server Down try again later";
          // axiosThis.errorSnackbar = true;
          //     console.warn("Backoffice error")
          // }
          axiosThis.digilockerAdressSubmit();
          // axiosThis.getFamily()
        })
        .catch((error) => {
          // alert("Server error, please try again later",error)
          console.log(error);
        });
    },
    chipp() {
      this.chha = !this.chha;
    },
    getCardColor(index) {
      // Custom logic to determine the card color based on the index or other conditions
      if (index % 2 === 0) {
        return "#ECF9EF"; // Example: Blue color for even index cards
      } else {
        return "#FAEAEA"; // Example: Green color for odd index cards
      }
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""),
        (this.requestotpemail = false),
        (this.useremailOtp = ""),
        (this.verifyotpemail = false),
        (this.stateAddress = ""),
        (this.countryAddress = ""),
        (this.distAddress = ""),
        (this.pincodeAddress = "");
      (this.usernewAdress = ""),
        (this.adressproffdoc = null),
        (this.bankIfscCode = ""),
        (this.bankAccountnumber = ""),
        (this.bankproofTypes = ""),
        (this.bankproffdoc = null),
        (this.defaultCheckbox = false);
    },
  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field",
      (value) =>
      !value ||
      !/^\s+|\s+$/.test(value) ||
      "Leading or trailing spaces are not allowed"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required",];
    },
  },
  mounted() {
    // this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    // console.log("jiji", vendorAcc, vendorToken);
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
        this.statusCheckNominee(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>

