<template>
    <v-app>

        <v-app-bar dense width="100%" permanent class="cust-appbar px-0 px-md-2 mt- overflow-hidden" style="">
            <img src="@/assets/zebulogo.svg" style="max-width: 80px;" alt="zebulogo">

            <v-spacer></v-spacer>
            <v-row class="flex-row-reverse mr-0 mr-md-4 pt-4">
                <v-list nav color="transparent">
                    <v-list-item-group class="d-flex">
                        <!-- https://report.mynt.in/?ucode=''
                                http://localhost:8080/?ucode='' -->
                        <!-- :href=""https://profile.mynt.in/?uid=${actid}&token=${susertoken} -->
                        <!-- <v-list-item target="_blank"
                                    :href="`https://profile.mynt.in/?uid=${actid}&token=${susertoken}`">
                                    <v-list-item-title style="color:#666;" class="font-weight-medium text-capitalize">
                                        Profile
                                    </v-list-item-title>
                                </v-list-item> -->

                        <!-- <v-list-item target="_blank"
                                    :href="`https://reports-cf9df.web.app/Ledger?uid=${actid}&token=${susertoken}`" class="px-4 mx-1 mb-0">
                                    <v-list-item-title style="color:#666;" class="font-weight-medium text-capitalize">
                                        Funds
                                    </v-list-item-title>
                                </v-list-item> -->
                        <!-- <v-list-item style="background-color:#EBEFFA;" class="px-4 mx-1 mb-0 rounded-t-lg">
                                    <v-list-item-title style="color:#0037B7;" class="font-weight-medium text-capitalize">
                                        Reports
                                    </v-list-item-title>
                                </v-list-item> -->
                    </v-list-item-group>
                </v-list>
            </v-row>

            <!-- <v-btn @click="logout" icon class="d-none d-md-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <g clip-path="url(#clip0_1680_167)">
                                <path d="M12.5068 4.79297L16.7146 9.00076L12.5068 13.2086" stroke="#4C4C4C"
                                    stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.7149 9H5.49414" stroke="#4C4C4C" stroke-width="1.2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M8.29912 16.7137H3.39003C2.83204 16.7137 2.29691 16.4921 1.90235 16.0975C1.50779 15.703 1.28613 15.1678 1.28613 14.6098V3.38905C1.28613 2.83106 1.50779 2.29593 1.90235 1.90137C2.29691 1.50682 2.83204 1.28516 3.39003 1.28516H8.29912"
                                    stroke="#4C4C4C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1680_167">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </v-btn> -->
            <!-- <v-app-bar-nav-icon @click="drawer = true" class="#4C4C4C d-md-none"></v-app-bar-nav-icon> -->
            <div class="d-flex row mt-n2">
                <div class="">

                </div>
                <div class="ml-auto">
                    <v-list-item>

                        <v-list-item-content>
                            <!-- <v-list-item-title
                                                class="font-weight-bold black--text subtitle-2 text-capitalize">{{
                                                    cname
                                                }}</v-list-item-title> -->
                            <v-list-item-subtitle class="txt-666 fs-12 text-uppercase"><span
                                    class="black--text font-weight-bold">{{ USER_ID
                                    }}</span></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar color="#DDD" size="30">
                            <!-- <span class="text-uppercase font-weight-bold title">{{ singlename }}</span> -->
                            {{ USER_ID[0] }}
                        </v-list-item-avatar>
                    </v-list-item>
                </div>
            </div>
        </v-app-bar>
        <v-container>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">

                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="" style="background-color: transparent">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class="pt-0">
                                        <v-list-item-title class="text-start" style="
                        color: #000000;
                        font-size: 20px;
                        font-weight: 559;
                        line-height: 19px;
                        letter-spacing: -1px;
                      ">
                                            Verified P&L
                                        </v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1" style="
                        color: #666666;
                        font-size: 12px;
                        font-weight: 469;
                        line-height: 14px;
                      "><v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
                                            P&L verified by Zebu
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>
                            <!-- <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">

                                    <v-list-item-content class="pl-2 pa-0  d-none d-md-block">
                                        <v-list-item-title v-if="downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ dessertsoriginal.length }}
                                            Symbols
                                        </v-list-item-title>
                                        <v-list-item-title v-if="!downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ desserts23.length }}
                                            Symbols
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
                            <!-- <v-menu v-if="downsum && dessertsoriginal.length > 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
<v-list>
    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
        <v-list-item-title @click="downloadsum(item.title)">{{ item.title
            }}</v-list-item-title>
    </v-list-item>
</v-list>
</v-menu> -->

                            <!-- <v-menu  readonly class="" v-model="menu23" 
                            transition="scale-transition" offset-y min-width="auto"> -->
                            <!-- <template v-slot:activator="{ on, attrs }"> -->
                            <v-text-field style="max-width: 205px" class="mt-4 mr-2" solo rounded flat
                                v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            <!-- </template> -->
                            <!-- <v-date-picker color="black" v-model="fromdate"></v-date-picker> -->
                            <!-- <v-date-picker color="black" v-model="todate" @change="gettradedata()"
                                :allowed-dates="allowedDates" :max="maxval"></v-date-picker>
                        </v-menu> -->

                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->
                            <!-- <v-select v-if="downsum"  :readonly="segloader" :loading="segloader" :items="filterr" @change="filterseg(filterdata_unpl)" dense
                                v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px; overflow: hidden;">
                            </v-select> -->
                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
                            <!-- <v-select :readonly="segloader" :loading="segloader" :items="filterr"
                                @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl"
                                placeholder="filter" solo rounded flat background-color="#F1F3F8" hide-details
                                class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 14px; overflow: hidden">
                            </v-select> -->
                            <v-text-field style="width: 2%" class="tophundraedmutual d-none d-md-block mt-4 mr-5"
                                height="36px" background-color="#F1F3F8" label="Search" v-model="search" solo rounded
                                text flat dense>
                                <template v-slot:prepend-inner>
                                    <!-- <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="2s0px" height="18px" /> -->
                                </template>
                            </v-text-field>
                        </v-toolbar>

                        <!-- <v-btn @click="takeScreenshot">take</v-btn> -->
                        <div id="target-element">
                            <div class="display-flex row d-none d-md-block pa-8">
                                <v-row>
                                    <v-col>
                                        <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                                            <p class="pt-1 mb-3" style="
                        font-weight: 450;
                        font-size: 16px;
                        color: #000000;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                                                Realised P&L
                                            </p>
                                            <v-divider class="mt-2" style="border: solid px #000000" width="30px"
                                                color="#000000"></v-divider>
                                            <div class="display-flex row pt-6 pl-2">
                                                <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                    <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl < 0" style="
                            color: red;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ disrealised_pnl.toFixed(2) }}
                                                    </p>
                                                    <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl > 0" style="
                            color: green;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ disrealised_pnl.toFixed(2) }}
                                                    </p>
                                                    <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl == 0"
                                                        style="
                            color: black;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ disrealised_pnl.toFixed(2) }}
                                                    </p>
                                                </v-card>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                                            <p class="pt-1 mb-3" style="
                        font-weight: 450;
                        font-size: 16px;
                        color: #000000;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                                                Unrealised P&L
                                            </p>
                                            <v-divider class="mt-2" style="border: solid px #000000" width="30px"
                                                color="#000000"></v-divider>
                                            <div class="display-flex row pt-6 pl-2">
                                                <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                    <p class="mb-0" v-if="disunrealised_pnl && disunrealised_pnl < 0"
                                                        style="
                            color: red;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ disunrealised_pnl.toFixed(2) }}
                                                    </p>
                                                    <p class="mb-0"
                                                        v-else-if="disunrealised_pnl && disunrealised_pnl > 0" style="
                            color: green;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ disunrealised_pnl.toFixed(2) }}
                                                    </p>

                                                    <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl == 0
                                                    " style="
                            color: black;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ disunrealised_pnl.toFixed(2) }}
                                                    </p>
                                                </v-card>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                                            <p class="pt-1 mb-3" style="
                        font-weight: 450;
                        font-size: 16px;
                        color: #000000;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                                                Charges & taxes
                                            </p>
                                            <v-progress-linear style="width: 30px" v-if="segloader" indeterminate
                                                color="blue"></v-progress-linear>
                                            <v-divider v-else-if="!segloader" class="mt-2"
                                                style="border: solid px #000000" width="30px"
                                                color="#000000"></v-divider>
                                            <div class="display-flex row pt-6 pl-2">
                                                <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                    <p class="mb-0" v-if="segval" style="
                            color: red;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                                                        {{ segval.toFixed(2) }}
                                                    </p>


                                                </v-card>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <!-- <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px"> -->

                                        <!-- </v-card> -->
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mt-2 mt-sm-0 mt-md-0">
                                <div class="mb-n4 pa-2">
                                    <!-- <v-row class="pt-0">
                                    <v-col lg="4" sm="12" md="12" cols="12">
                                        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                                            <v-tab @click="downsum = true">
                                                P&L
                                            </v-tab>
                                            <v-tab @click="downsum = false">
                                                Tax p&l
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row> -->
                                    <!-- {{ loader }} -->

                                    <!-- {{ dialog }} -->

                                    <!-- <v-tabs-items v-model="tab"> -->
                                    <!-- <v-tab-item> -->
                                    <!-- {{ TableData }} -->
                                    <!-- @click:row="SymbolMatchedDate" -->
                                    <v-data-table :close-on-click="false" 
                                        ref="dataTableRef" :loading="loader" mobile-breakpoint hide-default-footer
                                        height="70vh" :search="search" fixed-header :headers="HeaderFortable"
                                        :items="TableData" :items-per-page="TableData.length"
                                        style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;"
                                        class="elevation-0 rounded-lg mt-">


                                        <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                            <span class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                        </template>

                                        <template v-slot:[`item.TRADE_DATEDt`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.TRADE_DATEDt }}</span>
                                        </template>



                                        <template v-slot:[`item.total_buy_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_qty }}</span>
                                        </template>

                                        <template v-slot:[`item.total_buy_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_rate ?
                                                (item.total_buy_rate).toFixed(2) : 0 }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_buy_qty ?
                                                (item.total_buy_qty *
                                                    item.total_buy_rate).toFixed(2) : 0 }}</p>
                                        </template>


                                        <template v-slot:[`item.total_sell_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_qty }}</span>
                                        </template>

                                        <template v-slot:[`item.total_sell_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_rate }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_sell_qty ?
                                                (item.total_sell_qty *
                                                    item.total_sell_rate).toFixed(2) : 0 }}</p>

                                        </template>
                                        <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                        </template>
                                        <template v-slot:[`item.remainingbprice`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                        </template>

                                        <template v-slot:[`item.remainingqty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                        </template>

                                        <template v-slot:[`item.unrealized_pnl`]="{ item }">
                                            <span
                                                :class="{ 'green--text': item.unrealized_pnl > 0, 'red--text': item.unrealized_pnl < 0, 'black--text': item.unrealized_pnl == 0 }"
                                                class="font-weight-bold caption ">{{ item.unrealized_pnl ?
                                                    (item.unrealized_pnl).toFixed(2) : 0 }}</span>
                                        </template>
                                        <template v-slot:[`item.realized_pnl`]="{ item }">
                                            <span
                                                :class="{ 'green--text': item.realized_pnl > 0, 'red--text': item.realized_pnl < 0, 'black--text': item.realized_pnl == 0 }"
                                                class="font-weight-bold caption">{{ item.realized_pnl ?
                                                    (item.realized_pnl).toFixed(2) : 0 }}</span>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-col class="mx-auto pa-15 mt-5">
                                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                                    height="" />
                                                <p class="font-weight-bold black--text">No data available</p>
                                            </v-col>
                                        </template>
                                    </v-data-table>

                                    <!-- </v-tab-item> -->
                                    <!-- <v-tab-item> -->
                                    <!-- <p>Tax p and l datas</p> -->
                                    <!-- </v-tab-item> -->
                                    <!-- </v-tabs-items> -->
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </div>
            <div class="d-md-none">
                <v-toolbar class="mt-10 mb-1 px-2 toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent">
                        <v-list-item class="pt-0 px-0">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                      fill="#363636" />
                    <path
                      d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                      fill="#E3E3E3" />
                    <path
                      d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                      fill="#363636" />
                  </svg> -->
                            <v-list-item-content class="pt-0">
                                <v-list-item-title class="text-start" style="
                    color: #000000;
                    font-size: 20px;
                    font-weight: 559;
                    line-height: 16px;
                    letter-spacing: -1px;
                  ">
                                    Verified P&L
                                </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1" style="
                    color: #666666;
                    font-size: 12px;
                    font-weight: 469;
                    line-height: 14px;
                  ">Verified P&L
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class="pt-0" style="background-color: transparent">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px">
                            <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
                            <!-- height="16px" /> -->
                        </v-list-item>
                        <!-- <v-list-item-content class="pa-0 pt-1">
                  <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                    recommended</v-list-item-title>
                </v-list-item-content> -->
                    </v-list>
                </v-toolbar>
                <v-divider class="mx-2"></v-divider>
                <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption" style="
                                            color: #696969;
                                            letter-spacing: 0.16em;
                                            text-transform: uppercase;
                                        ">
                                            Realised P&L
                                        </p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p v-if="disrealised_pnl < 0 && disrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height: 28px; color: #e12626">
                                            {{ disrealised_pnl.toFixed(2) }}
                                        </p>
                                        <p v-else-if="disrealised_pnl > 0 && disrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height: 28px; color: #34a853">
                                            {{ disrealised_pnl.toFixed(2) }}
                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height: 28px; color: #505359">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption" style="
                                            color: #696969;
                                            letter-spacing: 0.16em;
                                            text-transform: uppercase;
                                        ">
                                            Unrealised P&L
                                        </p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p v-if="disunrealised_pnl < 0 && disunrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height: 28px; color: #e12626">
                                            {{ disunrealised_pnl.toFixed(2) }}
                                        </p>
                                        <p v-else-if="disunrealised_pnl > 0 && disunrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height: 28px; color: #34a853">
                                            {{ disunrealised_pnl.toFixed(2) }}
                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height: 28px; color: #505359">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption" style="
                                            color: #696969;
                                            letter-spacing: 0.16em;
                                            text-transform: uppercase;
                                        ">
                                            Charges & taxes
                                        </p>
                                    </v-col>
                                    <v-col cols="7">
                                        <span v-if="segloader" class="d-flex">
                                            <v-progress-linear class="ml-auto" style="width: 30px" indeterminate
                                                color="blue"></v-progress-linear>
                                        </span>
                                        <p v-else-if="segval < 0 && segval" class="ctnbtl body-1 text-right"
                                            style="line-height: 28px; color: #e12626">
                                            {{ segval.toFixed(2) }}
                                        </p>
                                        <p v-else-if="segval > 0 && segval" class="ctnbtl body-1 text-right"
                                            style="line-height: 28px; color: #34a853">
                                            {{ segval.toFixed(2) }}
                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height: 28px; color: #505359">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-card>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheet" inset>
                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <p class="mb-0 pt-3 title font-weight-medium">Summary P&L</p>
                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloadsum(radios)" class="white--text elevation-0" color="black" block
                                    rounded flat solo>
                                    {{ radios }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheet1" inset>
                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet1 = !sheet1">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <p class="mb-0 pt-3 title font-weight-medium">Detailed P&L</p>

                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios1" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloaddet(radios1)" class="white--text elevation-0" color="black" block
                                    rounded flat solo>
                                    {{ radios1 }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
                <v-divider class="mt-4 mb-2"></v-divider>
                <div class="d-flex row">
                    <p v-if="summary" class="text-start mt-3 mb-3 ml-2 px-4" style="
              color: #696969;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            ">
                        No of symbols
                        <span class="pa-1 rounded-lg" style="color: #000000">({{ TableData.length }})</span>
                    </p>
                    <p v-if="!summary" class="text-start mt-3 mb-3 ml-2 px-4" style="
              color: #696969;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            ">
                        No of symbols
                        <span class="pa-1 rounded-lg" style="color: #000000">({{ TableData.length }})</span>
                    </p>
                    <!-- <v-btn v-if="summary" @click="sheet = true" class="ml-auto mr-6" flat solo icon>
                        <v-icon>mdi mdi-download</v-icon></v-btn>
                    <v-btn v-if="!summary" @click="sheet1 = true" class="ml-auto mr-6" flat solo icon>
                        <v-icon>mdi mdi-download</v-icon></v-btn> -->
                </div>
                <v-divider class="mb-2 mt-2"></v-divider>

                <v-row class="px-5 pa-0 mt-3">
                    <v-col class="pa-0 px-2" cols="12">
                        <v-text-field style="width: 100%" class="tophundraedmutual" height="36px"
                            background-color="#F1F3F8" label="Search" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field>

                    </v-col>
                    <v-col class="pa-0 px-2" cols="12">
                        <v-text-field style="width: 100%" class="   mb-4 mt-n4" solo rounded flat v-model="fromshow"
                            label="From date" dense color="black" background-color="#F1F3F8"
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>

                    </v-col>
                    <!-- <v-col cols="12"> -->
                    <!-- <v-select :items="filterr" :disabled="segloader" @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl"
                            placeholder="filter" solo rounded flat background-color="#F1F3F8" hide-details
                            class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select> -->
                    <!-- <v-select v-if="!summary" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                            v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select> -->
                    <!-- <v-select :readonly="segloader" :loading="segloader" :items="filterr"
                            @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl" placeholder="filter"
                            solo rounded flat background-color="#F1F3F8" hide-details class="mr-2 mt-n2 fildrop"
                            style="max-width: 140px; font-size: 21px; overflow: hidden">
                        </v-select> -->

                    <!-- </v-col> -->
                </v-row>
                <!-- <v-menu class="" :offset-x="offset" left v-model="menu2" :close-on-content-click="false"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }"> -->

                <!-- </template> -->
                <!-- <v-date-picker color="black" v-model="fromdate"></v-date-picker> -->
                <!-- <v-date-picker color="black" v-model="todate" @change="gettradedata()" :allowed-dates="allowedDates"
            :max="maxval"></v-date-picker>
        </v-menu> -->
                <v-row class="pt-0 mt-n9">
                    <v-col lg="4" sm="4" md="12" cols="12">
                        <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow> -->
                        <!-- <v-tab>
                               
                            </v-tab> -->
                        <!-- <v-tab @click="detailed()">
                                Tax P&L
                            </v-tab> -->
                        <!-- </v-tabs> -->
                    </v-col>
                </v-row>
                <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogmobile">
                    <v-sheet class="text-center" style="height: 100vh">
                        <v-list-item-content class="pt-5 pl-5">
                            <v-list-item-title class="text-start" style="
                  color: #000000;
                  font-size: 18px;
                  font-weight: 559;
                  line-height: 24px;
                  letter-spacing: -1px;
                ">
                                Detailed P&L</v-list-item-title>

                            <v-list-item-subtitle class="text-start pt-1" style="
                  color: #666666;
                  font-size: 11px;
                  font-weight: 469;
                  line-height: 14px;
                ">
                                {{ scriptname }}
                            </v-list-item-subtitle>
                            <v-btn absolute right top icon color="black" @click="dialogmobile = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-list-item-content>
                        <v-divider></v-divider>

                        <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3"
                            height="90%" style="overflow: scroll">
                            <div class="text-center pa-4">
                                <p class="caption" style="
                    color: #696969;
                    letter-spacing: 0.16em;
                    text-transform: uppercase;
                  ">
                                    Total
                                </p>
                                <p v-if="finaltotal < 0" class="ctnbtl headline mb-0"
                                    style="line-height: 28px; color: red">
                                    {{ finaltotal.toFixed(2) }}
                                </p>
                                <p v-if="finaltotal > 0" class="ctnbtl headline mb-0"
                                    style="line-height: 28px; color: green">
                                    {{ finaltotal }}
                                </p>
                                <p v-if="finaltotal == 0" class="ctnbtl headline mb-0"
                                    style="line-height: 28px; color: rgb(0, 0, 0)">
                                    {{ finaltotal }}
                                </p>
                                <p v-if="finaltotal == ''" class="ctnbtl headline mb-0" style="line-height: 28px">
                                    -
                                </p>
                            </div>
                            <v-card v-for="item in detailedpoparray.data" :key="item.index"
                                style="border-top: 5px #f1f3f8 solid" class="elevation-0 px-4" width="100%">
                                <span class="d-flex row mt-3 mb-3 px-4">
                                    <p class="pb-0 mb-0" style="color: #000000; font-size: 15px; font-weight: 459">
                                        {{ item.TRADE_DATE }}
                                    </p>
                                    <p v-if="item.NETAMT > 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: green">
                                        {{ item.NETAMT }}
                                    </p>
                                    <p v-if="item.NETAMT < 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: red">
                                        {{ item.NETAMT }}
                                    </p>
                                    <p v-if="item.NETAMT == 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: #000000">
                                        {{ item.NETAMT }}
                                    </p>
                                </span>

                                <v-divider class="mb-2"></v-divider>
                                <v-row class="px-2">
                                    <v-col>
                                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666">
                                            Net Quantity:
                                            <span class="mb-0 body-2 font-weight-regular text-start"
                                                style="color: #000000">{{
                                                    item.NETQTY
                                                }}</span>
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666">
                                            Buy Quantity:
                                            <span class="mb-0 body-2 font-weight-regular text-start"
                                                style="color: #000000">{{
                                                    item.BQTY
                                                }}</span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular text-start" style="color: #666666">
                                            Buy Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                                                item.BRATE }}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="text-end">
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                                            Net Rate:
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                                                item.NRATE.toFixed(2) }}
                                            </span>
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                                            Sale Quantity:
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                                                item.SQTY }}
                                            </span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666">
                                            Sale Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                                                item.SRATE.toFixed(2) }}
                                            </span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card v-if="loaderpopup" style="
                  border-top: 5px #f1f3f8 solid;
                  border-bottom: 5px #f1f3f8 solid;
                " class="elevation-0 px-4" width="100%">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <v-progress-circular :size="50" color="primary"
                                            indeterminate></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card v-if="detailedpoparray.data == 0 && loaderpopup == false" style="
                  border-top: 5px #f1f3f8 solid;
                  border-bottom: 5px #f1f3f8 solid;
                " class="elevation-0 px-4" width="100%">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                            height="" />
                                        <p class="font-weight-bold black--text">
                                            No data available
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                    </v-sheet>
                </v-dialog>

                <v-card v-for="item in TableData" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <div class="d-flex ">
                        <!-- @click="SymbolMatchedDate(item, 'mobile')" -->
                        <span class="font-weight-bold">

                            {{ item.SCRIP_SYMBOL }}

                            <!-- {{ item.COMPANY_CODE }} -->
                            <!-- <span class="caption font-weight-medium">({{
                            desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                        </span>



                    </div>


                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                        <v-col>
                            <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Net Qty:
                                <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                    item.NET_QUANTITY
                                }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_AMOUNT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_QUANTITY
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_RATE
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Realised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        (item.realized_pnl).toFixed(2)
                                    }}</span>
                            </p>
                        </v-col>
                        <v-col class="text-end">
                            <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_QUANTITY
                                    }}</span>
                            </p> -->
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_AMOUNT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_QUANTITY
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_RATE
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Unealised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        (item.unrealized_pnl).toFixed(2)

                                    }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card v-if="filteredDesserts.length == 0 && loader == false" style="
            border-top: 5px #f1f3f8 solid;
            border-bottom: 5px #f1f3f8 solid;
          " class="elevation-0 px-4" width="100%">
                    <v-row class="px-2">
                        <v-col class="mx-auto text-center my-4">
                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                            <p class="font-weight-bold black--text">No data available</p>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card v-if="loader" style="
            border-top: 5px #f1f3f8 solid;
            border-bottom: 5px #f1f3f8 solid;
          " class="elevation-0 px-4" width="100%">
                    <v-row class="px-2">
                        <v-col class="mx-auto text-center my-10">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-container>
        <v-bottom-sheet v-model="dialogForSymDetailsMobile" height="600px">

            <v-card height="600px">
                <v-toolbar class=" mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">

                            <v-list-item-content class=" pt-0 mt-2">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:20px;letter-spacing:-1px">
                                    Calender </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Calender
                                    data of
                                    profit and loss.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">





                        </v-list-item>

                    </v-list>


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <v-card v-for="item in SymbolDataMatchedTableArray" :key="item.index"
                    style="border-top: 5px #F1F3F8 solid;" class="elevation-0  px-4 pt-2" width="100%">

                    <div class="d-flex ">
                        <span class="font-weight-bold">

                            {{ item.SCRIP_SYMBOL }}

                            <!-- {{ item.COMPANY_CODE }} -->
                            <!-- <span class="caption font-weight-medium">({{
            desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                        </span>



                    </div>


                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                        <v-col>
                            <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Net
                                Qty: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                    item.Updated_NETQTY
                                    }}</span></p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BAMT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_buy_qty
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_buy_rate
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Realised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.realisedpnl
                                    }}</span>
                            </p>
                        </v-col>
                        <v-col class="text-end">
                            <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.BUY_QUANTITY
                    }}</span>
            </p> -->
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SAMT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_sell_qty
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_sell_rate
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Unealised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.unrealized_pnl

                                    }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-bottom-sheet>
        <v-dialog v-model="dialogForSymDetails" width="1000px">

            <v-card height="400px" width="1000px">
                <v-toolbar class=" mb-1 px-1 toolbar-contant" elevation="0" color="white">
                    <v-list class="mr-2" style="background-color: transparent;">
                        <v-list-item class="px-0 pt-4">
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                    Calender </v-list-item-title>

                                <v-list-item-subtitle class="text-start "
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Calender
                                    for data of
                                    profit and loss
                                </v-list-item-subtitle>

                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogForSymDetails = false"><v-icon>mdi mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-data-table ref="dataTableRef" mobile-breakpoint hide-default-footer height="320px" fixed-header
                    disable-sort :headers="HeaderFortable" :items="SymbolDataMatchedTableArray"
                    :items-per-page="SymbolDataMatchedTableArray.length"
                    style="  border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg ">


                    <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                        <span class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                    </template>

                    <template v-slot:[`item.TRADE_DATEDt`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.TRADE_DATEDt }}</span>
                    </template>

                    <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                    <template v-slot:[`item.total_buy_qty`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.BQTY }}</span>
                    </template>

                    <template v-slot:[`item.total_buy_rate`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.BRATE ? (item.BRATE).toFixed(2) : 0
                        }}</span>
                        <p class="font-weight-regular caption mb-0">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</p>
                    </template>

                    <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                    <template v-slot:[`item.total_sell_qty`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.SQTY }}</span>
                    </template>

                    <template v-slot:[`item.total_sell_rate`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.SRATE }}</span>
                        <p class="font-weight-regular caption mb-0">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</p>

                    </template>
                    <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                    </template>
                    <template v-slot:[`item.remainingbprice`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                    </template>

                    <template v-slot:[`item.remainingqty`]="{ item }">
                        <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                    </template>

                    <template v-slot:[`item.unrealized_pnl`]="{ item }">
                        <span
                            :class="{ 'green--text': item.unrealized_pnl > 0, 'red--text': item.unrealized_pnl < 0, 'black--text': item.unrealized_pnl == 0 }"
                            class="font-weight-bold caption ">{{ item.unrealized_pnl ?
                                (item.unrealized_pnl).toFixed(2) : 0 }}</span>
                    </template>
                    <template v-slot:[`item.realisedpnl`]="{ item }">
                        <span
                            :class="{ 'green--text': item.realisedpnl > 0, 'red--text': item.realisedpnl < 0, 'black--text': item.realisedpnl == 0 }"
                            class="font-weight-bold caption">{{ item.realisedpnl ? (item.realisedpnl).toFixed(2) : 0
                            }}</span>
                    </template>
                    <template v-slot:no-data>
                        <v-col class="mx-auto pa-15">
                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                            <p class="font-weight-bold black--text">No data available</p>
                        </v-col>
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import axios from "axios"
import { apiurl } from "../../../Api.js";


export default {
    name: 'HelloWorld',

    data() {
        return {

            dialogmobile: false,
            segval: 0,
            summary: true,
            radios: null,
            radios1: null,
            inject: {
                theme: {
                    default: { isDark: false },
                },
            },
            itemss: [],
            search: "",
            searchde: "",
            expanded: [],
            more: true,
            less: false,
            more1: true,
            less1: false,
            itemperpage: 8,
            msg: "",

            alert: false,

            sheet: false,
            sheet1: false,
            itemperpage1: 8,
            loader: true,
            headerval: [],
            dialog: false,
            detailedpoparray: [],

            yearindex: "",
            search1: "",

            itemperpage1inner: 0,
            // tab: null,
            // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
            desserts: [],
            dessertsoriginal: [],
            dialogForSymDetailsMobile: false,

            scriptname: "",
            dessertspop: [],
            dessertspopexpense: [],

            desserts2: [],
            sortBy: "SCRIP_NAME",
            offset: "",
            segfrom: "",
            dates: [""],
            filterr: [
                "All",
                "Equities",
                "Future & Options",
                "Commodities",
                "Currencies",
            ],
            filterdata_unpl: "All",
            filterdata_unpl1: "All",
            itemsdwl: [
                {
                    title: "Download Excel",
                },
                {
                    title: "Download Pdf",
                },
            ],
            show: false,
            Overviewheaders2: [
                {
                    text: "Trade Date",
                    value: "TRADE_DATE",
                    width: "10%",
                    class: "headerfont",
                },

                // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },

                {
                    text: "Buy Qty",
                    value: "BQTY",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
                {
                    text: "Buy Rate",
                    value: "BRATE",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
                // { text: 'Buy Amt', value: 'BAMT', align: "end" },
                {
                    text: "Sell Qty",
                    value: "SQTY",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
                {
                    text: "Sell Rate",
                    value: "SRATE",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
                // { text: 'Sale Amt', value: 'SAMT', align: "end" },
                {
                    text: "Net Qty",
                    value: "NETQTY",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
                {
                    text: "Net Rate",
                    value: "NRATE",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
                // { text: 'NET Amt', value: 'NETAMT', align: "end" },
                // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
                {
                    text: "P & L",
                    value: "NETAMT",
                    align: "end",
                    class: "headerfont",
                    width: "10%",
                },
            ],
            finaltotal: 0,
            HeaderFortable: [
                { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont", width: '250px' },
                { text: 'Buy qty', value: 'BUY_QUANTITY', align: 'right', class: "headerfont" },
                // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
                // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
                { text: 'Buy rate', value: 'BUY_RATE', align: 'right', class: "headerfont" },
                { text: 'Sell qty', value: 'SALE_QUANTITY', align: 'right', class: "headerfont" },
                // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
                { text: 'Sell rate', value: 'SALE_RATE', align: 'right', class: "headerfont" },
                { text: 'Net Qty', value: 'NET_QUANTITY', align: 'right', class: "headerfont" },
                { text: 'Close Price', value: 'CLOSING_PRICE', align: 'right', class: "headerfont" },
                { text: 'unrealized_pnl', value: 'unrealized_pnl', align: 'right', class: "headerfont" },
                { text: 'Realisedpnl', value: 'realized_pnl', align: 'right', class: "headerfont" },
                // { text: 'pnl', value: 'unrealized_pnl' , align: 'start', class: "headerfont" },
            ],
            SymbolDataMatchedTableArray: [],
            Overviewheaders: [
                { text: "Symbol", value: "SCRIP_NAME1", class: "headerfont" },
                // { text: "com", value: "Sort_No", class: "headerfont", },
                // { text: "comcode", value: "COMPANY_CODE", class: "headerfont", },
                // { text: '', value: 'data-table-expand', class: "headerfont" },
                // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
                // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
                {
                    text: "Qty",
                    value: "NET_QUANTITY",
                    align: "end",
                    class: "headerfont",
                },
                { text: "Avg", value: "NET_RATE", align: "end", class: "headerfont" },
                // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
                {
                    text: "Close Price",
                    value: "CLOSING_PRICE",
                    align: "end",
                    class: "headerfont",
                },
                {
                    text: "Unrealized P&L",
                    value: "unrpnl",
                    align: "end",
                    class: "headerfont",
                },
                // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
                {
                    text: "Realized P&L",
                    value: "rpnl",
                    align: "end",
                    class: "headerfont",
                },
                // { text: "Net Rate", value: "", align: "end", class: "headerfont" },
                // { text: "Close price", value: "", align: "end", class: "headerfont" },
                // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
                // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
                {
                    text: "P & L",
                    value: "NOT_PROFIT",
                    align: "end",
                    class: "headerfont",
                },
            ],
            tab: null,
            items: ["Summary", "Detailed"],
            SymbolDataMatched: [],
            desserts23: [],
            sortKey: "Sort_No",
            dialogForSymDetails: false,
            detpop: [],
            actid: "",
            TableData: [],
            susertoken: "",
            loaderpopup: false,
            keyyy: "",
            downsum: true,
            disrealised_pnl: "",
            disunrealised_pnl: "",
            year: "",
            dessertstaxdis: [],
            menu2: false,
            menu23: false,
            todate: "",
            fromdate: "",
            segloader: false,
            fromshow: "",
            sortOrder: 1, // 1 for ascending, -1 for descending
            originalrea: 0,
            originalunrea: 0,
            maxval: '',
            switchval: '',
            agreeforshare: false,
            shareresdata: [],
            USER_ID: '',
        };
    },
    methods: {

        SymbolMatchedDate(item, val) {
            // console.log(this.SymbolDataMatched);
            let ScriptName = item.SCRIP_SYMBOL
            if (val == 'mobile') {
                this.dialogForSymDetailsMobile = true
            } else {
                this.dialogForSymDetails = true

            }
            // for (let i = 0; i < this.SymbolDataMatched.length; i++) {
            if (this.SymbolDataMatched[ScriptName]) {
                this.SymbolDataMatchedTableArray = this.SymbolDataMatched[ScriptName]
            }
            // }
            // console.log(this.SymbolDataMatchedTableArray);
        },
        filterseg(filterdata_unpl) {
            var data = this.dessertspop;
            // var data1 = this.dessertspop
            // var headrea = this.disrealised_pnl;
            // var headrea1 = this.disunrealised_pnl;
            // var totalseg = this.segval
            const seg = filterdata_unpl;
            if (seg == "Equities") {
                var addval = 0;
                var unaddval = 0;
                var total = 0
                this.desserts = [];
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].COMPANY_CODE == "BSE_CASH" ||
                        data[i].COMPANY_CODE == "NSE_CASH" ||
                        data[i].COMPANY_CODE == "MF_BSE" ||
                        data[i].COMPANY_CODE == "MF_NSE" ||
                        data[i].COMPANY_CODE == "NSE_SLBM" ||
                        data[i].COMPANY_CODE == "NSE_SPT"
                    ) {
                        this.desserts.push(data[i]);
                    }
                }
                //console.log("test", this.desserts)

                for (let y = 0; y < this.desserts.length; y++) {

                    addval += this.desserts[y].realized_pnl;
                }
                //console.log(addval)
                this.disrealised_pnl = addval;

                for (let y = 0; y < this.desserts.length; y++) {
                    unaddval += this.desserts[y].unrealized_pnl;
                }
                //console.log(unaddval)

                this.disunrealised_pnl = unaddval;
                total = this.disrealised_pnl + this.disunrealised_pnl

                //console.log(total)

                this.segval = total;


            } else if (seg == "Future & Options") {
                this.desserts = [];
                let addval = 0;
                let unaddval = 0;

                // const data1 = this.dessertsoriginal
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].COMPANY_CODE == "NSE_FNO" ||
                        data[i].COMPANY_CODE == "BSE_FNO"
                    ) {
                        this.desserts.push(data[i]);
                    }
                }
                //console.log("test", this.desserts);
                for (let y = 0; y < this.desserts.length; y++) {

                    addval += this.desserts[y].realized_pnl;
                }
                //console.log(addval)
                this.disrealised_pnl = addval;

                for (let y = 0; y < this.desserts.length; y++) {
                    unaddval += this.desserts[y].unrealized_pnl;
                }
                //console.log(unaddval)

                this.disunrealised_pnl = unaddval;
                total = this.disrealised_pnl + this.disunrealised_pnl

                //console.log(total)

                this.segval = total;
                //console.log(this.segval);

                //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

            } else if (seg == "Commodities") {
                this.desserts = [];
                let addval = 0;
                let unaddval = 0;

                // const data1 = this.dessertsoriginal
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].COMPANY_CODE == "MCX" ||
                        data[i].COMPANY_CODE == "NCDEX" ||
                        data[i].COMPANY_CODE == "NSE_COM" ||
                        data[i].COMPANY_CODE == "BSE_COM"
                    ) {
                        this.desserts.push(data[i]);
                    }
                }
                //console.log("test", this.desserts);
                for (let y = 0; y < this.desserts.length; y++) {

                    addval += this.desserts[y].realized_pnl;
                }
                //console.log(addval)
                this.disrealised_pnl = addval;

                for (let y = 0; y < this.desserts.length; y++) {
                    unaddval += this.desserts[y].unrealized_pnl;
                }
                //console.log(unaddval)

                this.disunrealised_pnl = unaddval;
                total = this.disrealised_pnl + this.disunrealised_pnl

                //console.log(total)

                this.segval = total;
                //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

                ////console.log(this.dessertsoriginal);
                // for (let y = 0; y < this.dessertsoriginal.length; y++) {
                //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
                //     addval += this.dessertsoriginal[y].NOT_PROFIT;
                //   }
                // }
                // this.disrealised_pnl = addval;
                // for (let y = 0; y < this.dessertsoriginal.length; y++) {
                //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
                //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
                //   }
                // }
                // this.disunrealised_pnl = unaddval;
                // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
            } else if (seg == "Currencies") {
                this.desserts = [];
                let addval = 0;
                let unaddval = 0;
                let total = 0
                // const data1 = this.dessertsoriginal
                //console.log("test", this.desserts);

                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].COMPANY_CODE == "CD_NSE" ||
                        data[i].COMPANY_CODE == "CD_MCX" ||
                        data[i].COMPANY_CODE == "CD_USE" ||
                        data[i].COMPANY_CODE == "CD_BSE"
                    ) {
                        this.desserts.push(data[i]);
                    }
                }
                for (let y = 0; y < this.desserts.length; y++) {

                    addval += this.desserts[y].realized_pnl;
                }
                //console.log(addval)
                this.disrealised_pnl = addval;

                for (let y = 0; y < this.desserts.length; y++) {
                    unaddval += this.desserts[y].unrealized_pnl;
                }
                //console.log(unaddval)

                this.disunrealised_pnl = unaddval;
                total = this.disrealised_pnl + this.disunrealised_pnl

                //console.log(total)

                this.segval = total;
                //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

                // for (let y = 0; y < this.dessertsoriginal.length; y++) {
                //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
                //     addval += this.dessertsoriginal[y].NOT_PROFIT;
                //   }
                // }
                // this.disrealised_pnl = addval;
                // for (let y = 0; y < this.dessertsoriginal.length; y++) {
                //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
                //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
                //   }
                // }
                // this.disunrealised_pnl = unaddval;
                // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
            } else if (seg == "All") {
                this.desserts = data;

                this.disrealised_pnl = this.originalrea;
                this.disunrealised_pnl = this.originalunrea
                const total = this.disrealised_pnl + this.disunrealised_pnl
                this.segval = total;
            }

        },
        // filterseg(filterdata_unpl) {
        //     var data = this.dessertspop;
        //     // var data1 = this.dessertspop
        //     // var headrea = this.disrealised_pnl;
        //     // var headrea1 = this.disunrealised_pnl;
        //     // var totalseg = this.segval
        //     const seg = filterdata_unpl;
        //     if (seg == "Equities") {
        //         var addval = 0;
        //         var unaddval = 0;
        //         var total = 0
        //         this.desserts = [];
        //         for (let i = 0; i < data.length; i++) {
        //             if (
        //                 data[i].COMPANY_CODE == "BSE_CASH" ||
        //                 data[i].COMPANY_CODE == "NSE_CASH" ||
        //                 data[i].COMPANY_CODE == "MF_BSE" ||
        //                 data[i].COMPANY_CODE == "MF_NSE" ||
        //                 data[i].COMPANY_CODE == "NSE_SLBM" ||
        //                 data[i].COMPANY_CODE == "NSE_SPT"
        //             ) {
        //                 this.desserts.push(data[i]);
        //             }
        //         }
        //         //console.log("test", this.desserts)

        //         for (let y = 0; y < this.desserts.length; y++) {

        //             addval += this.desserts[y].realized_pnl;
        //         }
        //         //console.log(addval)
        //         this.disrealised_pnl = addval;

        //         for (let y = 0; y < this.desserts.length; y++) {
        //             unaddval += this.desserts[y].unrealized_pnl;
        //         }
        //         //console.log(unaddval)

        //         this.disunrealised_pnl = unaddval;
        //         total = this.disrealised_pnl + this.disunrealised_pnl

        //         //console.log(total)

        //         this.segval = total;


        //     } else if (seg == "Future & Options") {
        //         this.desserts = [];
        //         let addval = 0;
        //         let unaddval = 0;

        //         // const data1 = this.dessertsoriginal
        //         for (let i = 0; i < data.length; i++) {
        //             if (
        //                 data[i].COMPANY_CODE == "NSE_FNO" ||
        //                 data[i].COMPANY_CODE == "BSE_FNO"
        //             ) {
        //                 this.desserts.push(data[i]);
        //             }
        //         }
        //         //console.log("test", this.desserts);
        //         for (let y = 0; y < this.desserts.length; y++) {

        //             addval += this.desserts[y].realized_pnl;
        //         }
        //         //console.log(addval)
        //         this.disrealised_pnl = addval;

        //         for (let y = 0; y < this.desserts.length; y++) {
        //             unaddval += this.desserts[y].unrealized_pnl;
        //         }
        //         //console.log(unaddval)

        //         this.disunrealised_pnl = unaddval;
        //         total = this.disrealised_pnl + this.disunrealised_pnl

        //         //console.log(total)

        //         this.segval = total;
        //         //console.log(this.segval);

        //         //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        //     } else if (seg == "Commodities") {
        //         this.desserts = [];
        //         let addval = 0;
        //         let unaddval = 0;

        //         // const data1 = this.dessertsoriginal
        //         for (let i = 0; i < data.length; i++) {
        //             if (
        //                 data[i].COMPANY_CODE == "MCX" ||
        //                 data[i].COMPANY_CODE == "NCDEX" ||
        //                 data[i].COMPANY_CODE == "NSE_COM" ||
        //                 data[i].COMPANY_CODE == "BSE_COM"
        //             ) {
        //                 this.desserts.push(data[i]);
        //             }
        //         }
        //         //console.log("test", this.desserts);
        //         for (let y = 0; y < this.desserts.length; y++) {

        //             addval += this.desserts[y].realized_pnl;
        //         }
        //         //console.log(addval)
        //         this.disrealised_pnl = addval;

        //         for (let y = 0; y < this.desserts.length; y++) {
        //             unaddval += this.desserts[y].unrealized_pnl;
        //         }
        //         //console.log(unaddval)

        //         this.disunrealised_pnl = unaddval;
        //         total = this.disrealised_pnl + this.disunrealised_pnl

        //         //console.log(total)

        //         this.segval = total;
        //         //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        //         ////console.log(this.dessertsoriginal);
        //         // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //         //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
        //         //     addval += this.dessertsoriginal[y].NOT_PROFIT;
        //         //   }
        //         // }
        //         // this.disrealised_pnl = addval;
        //         // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //         //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
        //         //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
        //         //   }
        //         // }
        //         // this.disunrealised_pnl = unaddval;
        //         // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

        //         // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
        //     } else if (seg == "Currencies") {
        //         this.desserts = [];
        //         let addval = 0;
        //         let unaddval = 0;
        //         let total = 0
        //         // const data1 = this.dessertsoriginal
        //         //console.log("test", this.desserts);

        //         for (let i = 0; i < data.length; i++) {
        //             if (
        //                 data[i].COMPANY_CODE == "CD_NSE" ||
        //                 data[i].COMPANY_CODE == "CD_MCX" ||
        //                 data[i].COMPANY_CODE == "CD_USE" ||
        //                 data[i].COMPANY_CODE == "CD_BSE"
        //             ) {
        //                 this.desserts.push(data[i]);
        //             }
        //         }
        //         for (let y = 0; y < this.desserts.length; y++) {

        //             addval += this.desserts[y].realized_pnl;
        //         }
        //         //console.log(addval)
        //         this.disrealised_pnl = addval;

        //         for (let y = 0; y < this.desserts.length; y++) {
        //             unaddval += this.desserts[y].unrealized_pnl;
        //         }
        //         //console.log(unaddval)

        //         this.disunrealised_pnl = unaddval;
        //         total = this.disrealised_pnl + this.disunrealised_pnl

        //         //console.log(total)

        //         this.segval = total;
        //         //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        //         // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //         //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
        //         //     addval += this.dessertsoriginal[y].NOT_PROFIT;
        //         //   }
        //         // }
        //         // this.disrealised_pnl = addval;
        //         // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //         //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
        //         //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
        //         //   }
        //         // }
        //         // this.disunrealised_pnl = unaddval;
        //         // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

        //         // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
        //     } else if (seg == "All") {
        //         this.desserts = data;

        //         this.disrealised_pnl = this.originalrea;
        //         this.disunrealised_pnl = this.originalunrea
        //         const total = this.disrealised_pnl + this.disunrealised_pnl
        //         this.segval = total;
        //     }

        // },

        DataGetApi() {
            this.TaxesAndCharges = 0
            this.UnRealishedPnl = 0
            this.RealishedPnl = 0
            this.TableData = []
            this.ChartValue = []
            this.StatArray = []
            // if (this.filterdata_unpl == 'Equity') {
            //     var SegmentValue = 'NSE_CASH,BSE_CASH'
            // } else if (this.filterdata_unpl == 'Fno') {
            //     SegmentValue = 'NSE_FNO,BSE_FNO'
            // } else if (this.filterdata_unpl == 'Commodity') {
            //     SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
            // } else if (this.filterdata_unpl == 'Currency') {
            //     SegmentValue = 'CD_NSE,CD_BSE,CD_MCX,CD_USE'
            // }
            this.filterdata_unpl1 = "All";
            this.filterdata_unpl = "All";
            // this.fromshow = this.toshow;
            var urlcode = new URL(window.location.href); ''
            // const axios = require('axios');

            var ucode = urlcode.searchParams.get("ucode");
            let data = JSON.stringify({
                "Unique_Code": ucode
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${apiurl}/getsharedata`,
                // url: 'http://192.168.5.141:9003/getJournal',

                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    //console.log((response.data));
                    // axiosthis.ChartValue = response.data.journal
                    if (response.data.msg != 'Sharing is Turned Off') {
                        axiosthis.fromshow = `${response.data.from_date}_to_${response.data.to_date}`
                        if (response.data.old_new == 'new') {


                            axiosthis.USER_ID = response.data.uname

                            axiosthis.ChartValue = []
                            axiosthis.TableData = []
                            axiosthis.Nodata = false
                            axiosthis.loader = false
                            axiosthis.SymbolDataMatched = response.data.data.symbolarr
                            axiosthis.StatArray = response.data.data.summary
                            axiosthis.TableData = response.data.data.trades
                            // console.log(tableData);

                            // var RealishedPnl = 0
                            // var UnRealishedPnl = 0
                            // var TaxesAndCharges = 0
                            // for (let i = 0; i < tableData.length; i++) {
                            //     if (tableData[i].last == true) {
                            //         axiosthis.TableData.push(tableData[i])
                            //     }
                            // }
                            console.log(axiosthis.TableData);

                            // for (let i = 0; i < response.data.data.journal.length; i++) {
                            //     let dateStr = response.data.data.journal[i]['TRADE_DATE'];
                            //     let dateObj = new Date(dateStr);
                            //     let formattedDate = dateObj.toISOString().slice(0, 10);
                            //     var ProfValue = response.data.data.journal[i]['realisedpnl']
                            //     if (ProfValue != 0) {
                            //         // console.log("iflaenter",ProfValue,i)
                            //         let num = response.data.data.journal[i]['realisedpnl']

                            //         ProfValue = Number(parseFloat(num).toFixed(2))
                            //     } else {
                            //         ProfValue = ''
                            //         //console.log("elsela enter ",ProfValue)
                            //     }
                            //     axiosthis.ChartValue.push(
                            //         {
                            //             date: formattedDate,
                            //             score: ProfValue,
                            //             realisedpnl: ProfValue
                            //         }
                            //     )
                            //     //console.log(ProfValue);
                            //     RealishedPnl += response.data.data.journal[i]['realisedpnl'];
                            //     UnRealishedPnl += response.data.data.journal[i]['unrealized_pnl'];
                            //     TaxesAndCharges += response.data.data.journal[i]['total_bill_net'];
                            // }

                            //console.log(RealishedPnl,UnRealishedPnl,TaxesAndCharges);
                            axiosthis.disrealised_pnl = response.data.data.realized_pnl
                            axiosthis.disunrealised_pnl = response.data.data.unrealized_pnl
                            axiosthis.segval = response.data.data.expenses

                        } else {
                            //
                        }
                        // axiosthis.chart()
                        // setTimeout(() => {


                        // }, 6000);
                    } else {



                        axiosthis.ChartValue = []
                        axiosthis.loader = false
                        axiosthis.Nodata = true
                        axiosthis.TableData = []
                        axiosthis.TaxesAndCharges = 0
                        axiosthis.UnRealishedPnl = 0
                        axiosthis.UnRealishedPnl = 0
                    }
                    //console.log(axiosthis.ChartValue);

                })
                .catch((error) => {
                    console.log(error);
                    axiosthis.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                    axiosthis.coloralert = 'error'

                });
        },
        //   redirectpages() {
        //         this.actid = localStorage.getItem("userid");
        //         this.susertoken = localStorage.getItem("usession");     
        //         // this.actid = 'BL241';
        //         // this.susertoken = 'ad5aaf26fd75b2769ebc2e5e4df57a14a125b0804d79eaf602d4151f248bef6c';
        //         // this.susertoken = localStorage.getItem("usession");
        //         // //console.log('mosaaaaaaaaaaaaa', this.susertoken, this.actid);
        //         var dall = JSON.stringify({
        //             client_id: this.USER_ID,
        //         });        // this.actid = localStorage.getItem("");
        //         let date1 = this.encryptionFunction(dall)
        //         // //console.log(date1, "1saaaaaaaaaaaaa");
        //         const axios = require('axios');
        //         let data = JSON.stringify({
        //             "string": date1
        //         });

        //         let config = {
        //             method: 'post',
        //             maxBodyLength: Infinity,
        //             url: 'https://rekycbe.mynt.in/profile', 
        //             headers: {
        //                 'clientid': this.actid,
        //                 'Authorization': this.susertoken,
        //                 'Content-Type': 'application/json'
        //             },
        //             data: data
        //         };
        //         let axiosthis = this
        //         axios.request(config)
        //             .then((response) => {
        //                 this.namedate = response.data.str
        //                 let con = this.decryptionFunction(this.namedate)
        //                 con = JSON.parse(con)
        //                 con = con.client_data.CLIENT_NAME
        //                 localStorage.setItem("cname", con);
        //                 axiosthis.load = true

        //             })
        //             .catch((error) => {
        //                 //console.log(error);
        //             });

        //     },
    },
    computed: {
        filteredDesserts() {
            const searchTerm = this.search.toLowerCase();
            return this.desserts.filter((item) =>
                item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm)
            );
        },
    },
    mounted() {
        // var today = new Date();
        // var dd = String(today.getDate() - 1).padStart(2, "0");
        // var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        // var yyyy = today.getFullYear();
        // // var todayapi = dd + "/" + mm + "/" + yyyy;
        // var fromapi = dd + "/" + mm + "/" + yyyy;
        // var today1 = dd + "/" + mm + "/" + yyyy;
        // this.maxval = yyyy + '-' + mm + '-' + dd
        // this.toshow = today1;
        // this.fromshow = fromapi;
        // this.segfrom = fromapi;
        // this.fromdateapi = fromapi;

        this.filterdata_unpl1 = "All";
        this.filterdata_unpl = "All";
        this.DataGetApi()
        // this.fromshow = this.toshow;
        // var urlcode = new URL(window.location.href); ''
        // var ucode = urlcode.searchParams.get("ucode");

        // // //console.log(urlparams);
        // // var url = "http://192.168.5.198:5050/getsharedata"
        // // var url = "https://rekycbe.mynt.in/report/getsharedata"

        // // var url = "https://rekycbe.mynt.in/report/getsharedata"
        // // http://localhost:8080/Daily_pnl?ucode=0hpwdaFCyRSpKOFCKIzqM7KG

        // // const axios = require('axios');
        // let data = JSON.stringify({
        //     "Unique_Code": ucode
        // });

        // let config = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     // url: url,
        //     url: `${apiurl}/getsharedata`,

        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: data
        // };
        // let axiosthis = this
        // axios.request(config)
        //     .then((response) => {
        //         // //console.log(JSON.stringify(response.data));
        //         if (response.data.msg != "No Data Available") {
        //             axiosthis.loader = false;
        //             // console.log(response.data,"tyest");
        //             // axiosthis.shareresdata = response.data.data
        //             axiosthis.desserts = response.data.data["trades"];
        //             axiosthis.dessertspop = response.data.data["trades"];
        //             axiosthis.fromshow = response.data.date
        //             axiosthis.USER_ID = response.data.uname
        //             axiosthis.dessertsoriginal = axiosthis.desserts;
        //             axiosthis.itemperpage = axiosthis.desserts.length;

        //             axiosthis.disrealised_pnl = response.data.data.realized_pnl
        //             //console.log(axiosthis.disrealised_pnl);
        //             axiosthis.disunrealised_pnl = response.data.data.unrealized_pnl

        //             axiosthis.originalrea = response.data.data.realized_pnl
        //             axiosthis.originalunrea = response.data.data.unrealized_pnl


        //             for (let i = 0; i < axiosthis.desserts.length; i++) {
        //                 axiosthis.segval += axiosthis.desserts[i].NOT_PROFIT;
        //             }
        //         } else if (response.data.msg == "No Data Available") {
        //             axiosthis.loader = false;
        //         }

        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

    }
}
</script>
<style>
.toolbar .v-toolbar__content {
    padding: 0 !important;
}

.headerfont {
    font-weight: 500
}

.v-menu__content.theme--light.menuable__content__active {
    border-radius: 20px;
}

.dropdown {
    .v-input__slot {
        width: 141px;
    }
}
</style>
