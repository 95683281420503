<template>
  <v-app>
    <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
      <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">

        <v-col cols="12" class="pl-7">
          <v-card class="mt-7 elevation-0">

            <v-toolbar class="mb-3 toolbar-contantd px-md-3" elevation="0" color="white">
              <v-list-item class="pl-0 pl-md-1 ">
                <v-list-item-content>
                  <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Tax P&L </v-list-item-title>
                  <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Tax P&L Data
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-menu v-if="downloadarray.length > 0" :disabled="disdowbtn" bottom offset-y>

                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                    <v-list-item-title @click="valdwl(item.title)">{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-select style="width: 200px;overflow: hidden" dense background-color="#F1F3F8" :loading="disabletabs"
                rounded :readonly="disabletabs" @change="gettaxpnl(yearis)" :items="items" v-model="yearis" flat solo
                class="mt-n3 dropdown  elevation-0 rounded-pill pr-5 " label="From" prepend-inner-icon="mdi-calendar"
                hide-details append-icon="mdi-chevron-down"></v-select>


              <!-- <v-select style="width: 160px; overflow: hidden" dense background-color="#F1F3F8" :loading="disabletabs"
                :readonly="disabletabs" rounded @change="gettaxpnl(yearis)" :items="items" v-model="ye|aris" flat solo
                class="mt-n3 dropdown  elevation-0 rounded-pill  pr-4" label="From" prepend-inner-icon="mdi-calendar"
                hide-details append-icon="mdi-chevron-down"></v-select> -->
              <!-- <v-text-field v-model="search" class=" ml-3 mt-4" height="36px" background-color="#F1F3F8"
                style="min-width: 180px; " label="Search " solo rounded flat dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field> -->
            </v-toolbar>
            <v-divider></v-divider>

            <div class="display-flex row   d-none d-md-block pa-8">

              <v-row v-if="tabvaluestack == 'Equities'">
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Long Term Realized P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="longnetval < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (longnetval).toFixed(2) }} </p>
                        <p class="mb-0" v-if="longnetval > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (longnetval).toFixed(2) }} </p>
                        <p class="mb-0" v-if="longnetval == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (longnetval).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Short Term Realized P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="stnetamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (stnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="stnetamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (stnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="stnetamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (stnetamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Trading P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="tradenetamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (tradenetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="tradenetamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (tradenetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="tradenetamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (tradenetamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>


                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Assets P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="assnetamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (assnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="assnetamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (assnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="assnetamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (assnetamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Trading Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="totalintraday < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (totalintraday).toFixed(2) }} </p>
                        <p class="mb-0" v-if="totalintraday > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (totalintraday).toFixed(2) }} </p>
                        <p class="mb-0" v-if="totalintraday == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (totalintraday).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Total Charges</p>
                    <v-progress-linear style="width: 30px" v-if="segloader" indeterminate
                      color="blue"></v-progress-linear>
                    <v-divider v-else-if="!segloader" class="mt-2" style="border:solid px #000000" width="30px"
                      color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (segval).toFixed(2) }} </p>
                        <!-- <p class="mb-0" v-if="segval > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (segval).toFixed(2) }} </p>
                        <p class="mb-0" v-if="segval == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (segval).toFixed(2) }} </p> -->
                      </v-card>
                    </div>
                  </v-card>
                </v-col>


              </v-row>
              <v-row v-else-if="tabvaluestack == 'Derivatives'">
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Futures P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="futopened < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futopened).toFixed(2) }} </p>
                        <p class="mb-0" v-if="futopened > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futopened).toFixed(2) }} </p>
                        <p class="mb-0" v-if="futopened == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futopened).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Futures Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="futurnover < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futurnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="futurnover > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futurnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="futurnover == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futurnover).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Total Charges</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (derchargers).toFixed(2) }} </p>
                        <!-- <p class="mb-0" v-if="derchargers > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (derchargers).toFixed(2) }} </p>
                        <p class="mb-0" v-if="derchargers == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (derchargers).toFixed(2) }} </p> -->
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Options P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="optionopn < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (optionopn).toFixed(2) }} </p>
                        <p class="mb-0" v-if="optionopn > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (optionopn).toFixed(2) }} </p>
                        <p class="mb-0" v-if="optionopn == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (optionopn).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Options Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="opturnover < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (opturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="opturnover > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (opturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="opturnover == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (opturnover).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>

                <!-- <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Other credits/debits</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="derothersdbcr < 0 && derothersdbcr"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (derothersdbcr).toFixed(2) }} </p>
                        <p class="mb-0" v-if="derothersdbcr > 0 && derothersdbcr"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (derothersdbcr).toFixed(2) }} </p>
                        <p class="mb-0" v-if="derothersdbcr == 0 && derothersdbcr"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (derothersdbcr).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                 -->
              </v-row>

              <v-row v-else-if="tabvaluestack == 'Commodity'">
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Futures P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="comnetfutamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comnetfutamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comnetfutamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comnetfutamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comnetfutamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comnetfutamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Futures Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="futturnover < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="futturnover > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="futturnover == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (futturnover).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Total Charges</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comchargers).toFixed(2) }} </p>
                        <!-- <p class="mb-0" v-if="comchargers > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comchargers).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comchargers == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comchargers).toFixed(2) }} </p> -->
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Options P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="comnetamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comnetamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comnetamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comnetamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Options Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="comturnover < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comturnover > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comturnover == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comturnover).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>

                <!-- <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Other credits/debits</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="comothersdbcr < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comothersdbcr).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comothersdbcr > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comothersdbcr).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comothersdbcr == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comothersdbcr).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col> -->

              </v-row>



              <v-row v-else-if="tabvaluestack == 'Currency'">
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Futures P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="curnetfutamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curnetfutamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curnetfutamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curnetfutamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curnetfutamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curnetfutamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Futures Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="curfutturnover < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curfutturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curfutturnover > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curfutturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curfutturnover == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curfutturnover).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Total Charges</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curchargers).toFixed(2) }} </p>
                        <!-- <p class="mb-0" v-if="curchargers > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curchargers).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curchargers == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curchargers).toFixed(2) }} </p> -->
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Options P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="curopnetamt < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curopnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curopnetamt > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curopnetamt).toFixed(2) }} </p>
                        <p class="mb-0" v-if="curopnetamt == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (curopnetamt).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Options Turnover</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="comopturnover < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comopturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comopturnover > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comopturnover).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comopturnover == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comopturnover).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>

                <!-- <v-col cols="4">
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Other credits/debits</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" v-if="comothersdbcr < 0"
                          style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comothersdbcr).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comothersdbcr > 0"
                          style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comothersdbcr).toFixed(2) }} </p>
                        <p class="mb-0" v-if="comothersdbcr == 0"
                          style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (comothersdbcr).toFixed(2) }} </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col> -->


              </v-row>
            </div>
            <div class="mt-2 mt-sm-0 mt-md-0">
              <div class="mb-n4 pa-2">
                <v-row class="pt-0">
                  <v-col lg="8" sm="12" md="12" cols="12">
                    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>

                      <v-tab v-model="tab2" @click="tabdata('Equities')">
                        Equities
                      </v-tab>

                      <v-tab style="cursor: wait;" v-if="disabletabs" disabled>
                        Derivatives
                      </v-tab>

                      <v-tab v-else-if="!disabletabs" v-model="tab3" @click="tabdata('Derivatives')">
                        Derivatives
                      </v-tab>

                      <v-tab :disabled="disabletabs" v-model="tab4" @click="tabdata('Commodity')">
                        Commodity
                      </v-tab>
                      <v-tab :disabled="disabletabs" v-model="tab5" @click="tabdata('Currency')"
                        :class="{ 'red-text': disabletabs }">
                        Currency
                      </v-tab>

                      <!-- <div v-else-if="!disabletabs">
                        <v-tab v-model="tab3" @click="tabdata('Derivatives')">
                          Derivatives
                        </v-tab>
                        <v-tab v-model="tab4" @click="tabdata('Commodity')">
                          Commodity
                        </v-tab>
                        <v-tab v-model="tab5" @click="tabdata('Currency')">
                          Currency
                        </v-tab>
                      </div> -->
                    </v-tabs>
                  </v-col>
                </v-row>
                <!-- {{ loader }} -->

                <!-- {{ dialog }} -->

                <v-tabs-items v-model="tab">

                  <!-- <v-tab-item>
                    <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                      fixed-header disable-sort :headers="Overviewheaders" :items="dessertsoriginal"
                      :items-per-page="itemperpage" item-key="NSE_SCRIPCODE"
                      style="border: 1px solid #efeef3; cursor: pointer" class="elevation-0 rounded-lg "
                      @click:row="detailedpop">
                      <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                        <span v-if="item.COMPANY_CODE == 'DERIVATIVES'" class="font-weight-bold">
                          {{ item.SCRIP_SYMBOL }} <v-icon color="#0037B7">mdi
                            mdi-menu-right</v-icon>
                        </span>
                        <span v-else-if="item.COMPANY_CODE == 'CAPITAL'" class="font-weight-bold">

                          <span
                            v-if="item.SCRIP_SYMBOL[0] == 1 || item.SCRIP_SYMBOL[0] == 2 || item.SCRIP_SYMBOL[0] == 3 || item.SCRIP_SYMBOL[0] == 4 || item.SCRIP_SYMBOL[0] == 5 || item.SCRIP_SYMBOL[0] == 6 || item.SCRIP_SYMBOL[0] == 7 || item.SCRIP_SYMBOL[0] == 8 || item.SCRIP_SYMBOL[0] == 9 || item.SCRIP_SYMBOL[0] == 0">{{
                              item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}<v-icon color="#0037B7">mdi
                              mdi-menu-right</v-icon></span>

                          <span v-else>{{ item.SCRIP_SYMBOL }}<v-icon color="#0037B7">mdi
                              mdi-menu-right</v-icon></span>
                        </span>
                        <span v-else class="font-weight-bold">

                          {{ item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}<v-icon color="#0037B7">mdi
                            mdi-menu-right</v-icon>

                        </span>
                      </template>


                      <template v-slot:[`item.BUY_QUANTITY`]="{ item }">
                        <span class="font-weight-light"> {{ item.BUY_QUANTITY }}</span>
                      </template>
                      <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                        <span v-if="item.CLOSING_PRICE" class="font-weight-light"> {{
                          item.CLOSING_PRICE }}</span>
                      </template>
                      <template v-slot:[`item.BUY_RATE`]="{ item }">
                        <span class="font-weight-light"> {{ item.BUY_RATE }}</span>
                        <p class="font-weight-light caption mb-0"> {{ item.BUY_AMOUNT }}</p>

                      </template>
                      <template v-slot:[`item.SALE_QUANTITY`]="{ item }">
                        <span class="font-weight-light">
                          {{ item.SALE_QUANTITY }}</span>
                      </template>
                      <template v-slot:[`item.SALE_RATE`]="{ item }">
                        <span class="font-weight-light"> {{ item.SALE_RATE }}</span>
                        <p class="font-weight-light caption mb-0"> {{ item.SALE_AMOUNT }}</p>
                      </template>

                      <template v-slot:[`item.NET_QUANTITY`]="{ item }">
                        <span class="font-weight-light"> {{ item.NET_QUANTITY }}</span>
                      </template>
                      <template v-slot:[`item.NET_RATE`]="{ item }">
                        <span class="font-weight-light"> {{ item.NET_RATE }}</span>
                        <p class="font-weight-light caption mb-0"> {{ item.NET_AMOUNT }}</p>

                      </template>

                    
                      <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                        <span v-if="item.NOT_PROFIT < 0" class="font-weight-light" style="color: red;"> {{
                          item.NOT_PROFIT }}</span>
                        <span v-if="item.NOT_PROFIT > 0" class="font-weight-light" style="color: green;">
                          {{
                            item.NOT_PROFIT }}</span>
                        <span v-if="item.NOT_PROFIT == 0" class="font-weight-light" style="color: black;">
                          {{
                            item.NOT_PROFIT }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-col class="mx-auto pa-15 mt-5">
                          <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                          <p class="font-weight-bold black--text">No data available</p>
                        </v-col>
                      </template>
                      
                    </v-data-table>
                    <v-dialog v-model="dialog" width="70%" class="elevation-0">
                      <v-card class=" elevation-0 pt-3 pb-2" height="100%">
                        <v-btn :ripple="false" dense class="mt-n4" text icon solo absolute top right
                          @click="dialog = false"><v-icon size="18" color="grey">mdi
                            mdi-close</v-icon></v-btn>
                        <v-toolbar class=" mb-1 mt-4 toolbar-contant" elevation="0" color="white">
                          <v-list class="" style="background-color: transparent;">
                            <v-list-item class=" pt-0">
                              <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                  style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                  Detailed P & L </v-list-item-title>
                                <v-list-item-subtitle class="text-start pt-1"
                                  style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">{{
                                    scriptname }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-spacer></v-spacer>
                          <v-list class="mr-7 pt-0" style="background-color: transparent;">
                            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                              <v-list-item-content class="pl-2 pa-0">
                                <v-list-item-title class="text-start font-weight-medium d-none d-md-block"
                                  style="color: #000000;font-size: 14px;line-height:16px;">
                                  {{ detailedpoparray.length }}
                                  Trades</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-text-field v-model="search1" class="tophundraedmutual mt-4" height="36px"
                            background-color="#F1F3F8" style="width: 2%;" label="Search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                              <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                height="18px" />
                            </template>
                          </v-text-field>
                        </v-toolbar>
                        <v-divider></v-divider>

                        <div class=" mb-4 mx-4 my-6">
                          <v-data-table :search="search1" dense :sort-by.sync="sortBy" hide-default-footer
                            :loading="loader" height="405px" mobile-breakpoint fixed-header disable-sort
                            :headers="Overviewheaders2" :items="detailedpoparray" :items-per-page="itemperpage1inner"
                            style="" class="elevation-0 rounded-lg">
                            <template v-slot:[`item.SCRIP_NAME`]="{ item }">
                            
                              <span class="font-weight-regular subtitle-2"> {{ item.SCRIP_NAME
                              }}</span>

                            </template>
                            <template v-slot:footer>
                              <v-divider></v-divider>
                              <div class="text-end">

                                <span class="mr-5 mt-4"
                                  style="color: black;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Total
                                  : <span v-if="finaltotal > 0" style="color: green"> {{
                                    finaltotal.toFixed(2)
                                  }}</span>
                                  <span v-if="finaltotal < 0" style="color: red"> {{
                                    finaltotal.toFixed(2)
                                  }}</span>
                                </span>
                              </div>

                            </template>
                            <template v-slot:[`item.BQTY`]="{ item }">
                              <span class="font-weight-regular subtitle-2"> {{ item.BQTY
                              }}</span>
                            </template>
                            <template v-slot:[`item.BRATE`]="{ item }">
                              <span class="font-weight-regular subtitle-2"> {{
                                (item.BRATE.toFixed(2))
                              }}</span>
                              <p class="font-weight-medium caption mb-0"> {{ (item.BQTY *
                                item.BRATE).toFixed(2) }}</p>
                            </template>
                            <template v-slot:[`item.NRATE`]="{ item }">
                              <span class="font-weight-regular subtitle-2"> {{ item.NRATE
                              }}</span>
                              <p class="font-weight-medium caption mb-0"> {{ item.NETAMT }}
                              </p>
                            </template>


                            <template v-slot:[`item.SRATE`]="{ item }">
                              <span class="font-weight-regular subtitle-2"> {{
                                (item.SRATE).toFixed(2)
                              }}</span>
                              <p class="font-weight-medium caption mb-0"> {{ (item.SQTY *
                                item.SRATE).toFixed(2) }}</p>
                            </template>
                            <template v-slot:[`item.SQTY`]="{ item }">
                              <span class="font-weight-regular subtitle-2"> {{ item.SQTY
                              }}</span>
                            </template>
                            <template v-slot:[`item.TRADE_DATE`]="{ item }">
                              <span class="font-weight-bold subtitle-2"> {{ item.TRADE_DATE
                              }}</span>
                            </template>
                            <template v-slot:[`item.PRO`]="{ item }">
                              <span v-if="item.NETAMT < 0" class="font-weight-regular subtitle-2" style="color: red;">
                                {{ item.NETAMT }}</span>
                              <span v-if="item.NETAMT > 0" class="font-weight-regular subtitle-2" style="color: green;">
                                {{ item.NETAMT }}</span>
                              <span v-if="item.NETAMT == 0" class="font-weight-regular subtitle-2" style="color: black;">
                                {{ item.NETAMT }}</span>
                            </template>
                            <template v-slot:no-data>
                              <v-col class="mx-auto pa-15 mt-5">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                <p class="font-weight-bold black--text">No data available
                                </p>
                              </v-col>
                            </template>
                           
                          </v-data-table>
                        </div>
                      </v-card>
                    </v-dialog>

                  </v-tab-item> -->

                  <v-tab-item>
                    <v-data-table :headers="dessertHeaders" :items="dessertsexpand" :single-expand="singleExpand"
                      style="border: 1px solid #efeef3; cursor: pointer"
                      class="cust-table elevation-0 rounded-lg cell-height" fixed-header @click:row="toggleExpanded"
                      :loading="eqloader" height="495px" hide-default-footer show-expand :expanded.sync="expanded"
                      item-key="name" disable-sort>

                      <template v-slot:[`item.name`]="{ item }">
                        <span class="overline"> {{ item.name
                          }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-col class="mx-auto pa-15 mx-0 px-0" style="background-color: white ;">
                          <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                          <p class="font-weight-bold black--text">No data available
                          </p>
                        </v-col>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="px-0" :colspan="headers.length">
                          <!-- v-if="item.name != 'Assets'" -->
                          <v-data-table :items-per-page="item.val.length" item fixed-header hide-default-footer dense
                            :headers="headersexpandinside" hide-default-header :items="item.val" item-key="name"
                            class="elevation-0">


                            <template v-slot:item="{ item }">
                              <tr class="" style=" position: relative">

                                <td class="" style="width:1200px">
                                  <span class="font-weight-bold body-2"> <span v-if="item.SCRIP_NAMEDATA != ''">{{
        item.SCRIP_NAMEDATA }}</span>
                                    <span v-else>{{ item.SCRIP_NAME }}</span>

                                    <v-tooltip color="black" v-if="item.OPQTY > 0 || item.OPQTY < 0" v-model="item.show"
                                      top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" color="blue" class="ml-2" x-small outlined>{{
        item.OPQTY }}</v-chip>
                                      </template>
                                      <span>Open Quantity!</span>
                                    </v-tooltip>
                                  </span>

                                </td>


                                <td class=" text-right" style="width: 200px">
                                  <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>

                                <td class=" text-right pl-0" style="width: 200px">
                                  <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </td>

                                <td class="  text-right" style="width: 200px">
                                  <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>
                                <td style="width: 200px" class="pr-0  text-right">
                                  <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>

                                </td>
                                <td class=" text-right pr-2 " style="width: 200px">
                                  <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>


                                </td>
                                <td class=" text-right " style="width: 200px">
                                  <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </td>
                                <td style="width: 200px" class=" pr-0 text-right">
                                  <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{
        item.Closing_Price
      }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>

                                </td>

                                <td style="width: 250px" class="  text-right">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2"
                                    style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>

                                </td>
                                <td style="width: 80px">

                                </td>

                              </tr>
                            </template>

                            <!-- <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                              <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                            </template>
                            <template v-slot:[`item.NET_QTY`]="{ item }">
                              <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.NET_RATE`]="{ item }">
                              <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                              <br>
                              <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_QTY`]="{ item }">
                              <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_RATE`]="{ item }">
                              <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                              <span v-else class="font-weight-medium caption">0</span>
                            </template>
                            <template v-slot:[`item.SALE_QTY`]="{ item }">
                              <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.SALE_RATE`]="{ item }">
                              <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.Closing_Price`]="{ item }">
                              <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price
                              }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                              <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template>
                            <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                              <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2" style="color: green">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2" style="color: black">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template> -->
                          </v-data-table>
                          <!-- v-else -->
                          <!-- <v-data-table  :headers="dessertHeaders" :items="dessertsexpand"
                                :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer;  "
                                class="elevation-0 rounded-lg pa-4" fixed-header hide-header @click:row="toggleExpanded4"
                                :loading="loader" height="400px" hide-default-footer hide-default-header show-expand
                                :expanded.sync="expandedinner" item-key="name">
                                <template v-slot:[`item.name`]="{ item }">
                                  <span class="font-weight-bold body-1"> {{ item.name }}</span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                            <td class="px-0" :colspan="headers.length">
                              <v-data-table style="border: 1px solid rgb(233, 233, 233); border-radius: 10px"
                                :items-per-page="item.val.length" height="300px" item fixed-header hide-default-footer dense
                                :headers="headersexpandinside" :items="item.val" item-key="name" class="elevation-0">
                                <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                                </template>
                                <template v-slot:[`item.NET_QTY`]="{ item }">
                                  <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.NET_RATE`]="{ item }">
                                  <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_QTY`]="{ item }">
                                  <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_RATE`]="{ item }">
                                  <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </template>
                                <template v-slot:[`item.SALE_QTY`]="{ item }">
                                  <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.SALE_RATE`]="{ item }">
                                  <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.Closing_Price`]="{ item }">
                                  <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                                  <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                                <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                              </v-data-table>

                            </td>
                          </template>
                        </v-data-table> -->
                        </td>
                      </template>
                    </v-data-table>
                  </v-tab-item>

                  <v-tab-item>


                    <v-data-table :headers="dessertHeaders" :items="dessertsexpandderivative"
                      :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer"
                      class="cust-table elevation-0 rounded-lg cell-height" fixed-header @click:row="toggleExpanded1"
                      :loading="eqloader" height="495px" hide-default-footer show-expand :expanded.sync="expandedder"
                      item-key="name" disable-sort>

                      <template v-slot:[`item.name`]="{ item }">
                        <span class="overline"> {{ item.name
                          }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-col class="mx-auto pa-15 mx-0 px-0 " style="background-color: white ;">
                          <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                          <p class="font-weight-bold black--text">No data available
                          </p>
                        </v-col>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="px-0" :colspan="headers.length">
                          <!-- v-if="item.name != 'Assets'" -->
                          <v-data-table :items-per-page="item.val.length" item fixed-header hide-default-footer dense
                            :headers="headersexpandinside" hide-default-header :items="item.val" item-key="name"
                            class="elevation-0">


                            <template v-slot:item="{ item }">
                              <tr class="" style=" position: relative">

                                <td class="" style="width:800px">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_SYMBOL }}

                                    <v-tooltip color="black" v-if="item.OPQTY > 0 || item.OPQTY < 0" v-model="item.show"
                                      top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" color="blue" class="ml-2" x-small outlined>{{
        item.OPQTY }}</v-chip>
                                      </template>
                                      <span>Open Quantity!</span>
                                    </v-tooltip>

                                  </span>

                                </td>

                                <td class=" text-right" style="width: 200px">
                                  <span v-if="item.BUYQTY" class="font-weight-medium body-2"> {{ item.BUYQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>

                                <td class=" text-right pr-4" style="width: 200px">
                                  <span v-if="item.BUYRATE" class="font-weight-medium body-2"> {{ item.BUYRATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUYAMT" class="font-weight-medium caption"> {{ item.BUYAMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </td>

                                <td class="pl-0  text-right" style="width: 200px">
                                  <span v-if="item.SALEQTY" class="font-weight-medium body-2"> {{ item.SALEQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>
                                <td style="width: 200px" class="pl-0 text-right">
                                  <span v-if="item.SALERATE" class="font-weight-medium body-2"> {{ item.SALERATE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALEAMT" class="font-weight-medium caption"> {{ item.SALEAMT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>

                                </td>
                                <td class=" text-right" style="width: 200px">
                                  <span v-if="item.NETQTY" class="font-weight-medium body-2"> {{ item.NETQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>


                                </td>
                                <td class=" text-right " style="width: 200px">
                                  <span v-if="item.NETRATE" class="font-weight-medium body-2"> {{ item.NETRATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NETAMT" class="font-weight-medium caption"> {{ item.NETAMT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </td>
                                <td style="width: 200px" class=" pr-0 text-right">
                                  <span v-if="item.CL_PRICE" class="font-weight-medium body-2"> {{ item.CL_PRICE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>

                                </td>

                                <td style="width: 250px" class="  text-right">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2"
                                    style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>

                                </td>
                                <td style="width: 80px">

                                </td>

                              </tr>
                            </template>

                            <!-- <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                              <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                            </template>
                            <template v-slot:[`item.NET_QTY`]="{ item }">
                              <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.NET_RATE`]="{ item }">
                              <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                              <br>
                              <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_QTY`]="{ item }">
                              <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_RATE`]="{ item }">
                              <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                              <span v-else class="font-weight-medium caption">0</span>
                            </template>
                            <template v-slot:[`item.SALE_QTY`]="{ item }">
                              <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.SALE_RATE`]="{ item }">
                              <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.Closing_Price`]="{ item }">
                              <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price
                              }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                              <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template>
                            <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                              <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2" style="color: green">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2" style="color: black">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template> -->
                          </v-data-table>
                          <!-- v-else -->
                          <!-- <v-data-table  :headers="dessertHeaders" :items="dessertsexpand"
                                :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer;  "
                                class="elevation-0 rounded-lg pa-4" fixed-header hide-header @click:row="toggleExpanded4"
                                :loading="loader" height="400px" hide-default-footer hide-default-header show-expand
                                :expanded.sync="expandedinner" item-key="name">
                                <template v-slot:[`item.name`]="{ item }">
                                  <span class="font-weight-bold body-1"> {{ item.name }}</span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                            <td class="px-0" :colspan="headers.length">
                              <v-data-table style="border: 1px solid rgb(233, 233, 233); border-radius: 10px"
                                :items-per-page="item.val.length" height="300px" item fixed-header hide-default-footer dense
                                :headers="headersexpandinside" :items="item.val" item-key="name" class="elevation-0">
                                <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                                </template>
                                <template v-slot:[`item.NET_QTY`]="{ item }">
                                  <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.NET_RATE`]="{ item }">
                                  <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_QTY`]="{ item }">
                                  <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_RATE`]="{ item }">
                                  <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </template>
                                <template v-slot:[`item.SALE_QTY`]="{ item }">
                                  <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.SALE_RATE`]="{ item }">
                                  <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.Closing_Price`]="{ item }">
                                  <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                                  <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                                <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                              </v-data-table>

                            </td>
                          </template>
                        </v-data-table> -->
                        </td>
                      </template>
                    </v-data-table>
                  </v-tab-item>


                  <v-tab-item>
                    <v-data-table :headers="dessertHeaders" :items="dessertsexpandcommodity"
                      :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer"
                      class="cust-table elevation-0 rounded-lg cell-height" fixed-header @click:row="toggleExpanded2"
                      :loading="eqloader" height="495px" hide-default-footer show-expand :expanded.sync="expandedcom"
                      item-key="name" disable-sort>

                      <template v-slot:[`item.name`]="{ item }">
                        <span class="overline"> {{ item.name
                          }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-col class="mx-auto pa-15 mx-0 px-0" style="background-color: white ;">
                          <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                          <p class="font-weight-bold black--text">No data available
                          </p>
                        </v-col>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="px-0" :colspan="headers.length">
                          <!-- v-if="item.name != 'Assets'" -->
                          <v-data-table :items-per-page="item.val.length" item fixed-header hide-default-footer dense
                            :headers="headersexpandinsidecom" hide-default-header :items="item.val" item-key="name"
                            class="elevation-0">


                            <template v-slot:item="{ item }">
                              <tr class="" style="position: relative">

                                <td class="" style="width:700px">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_SYMBOL }}

                                    <v-tooltip color="black" v-if="item.OPQTY > 0 || item.OPQTY < 0" v-model="item.show"
                                      top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" color="blue" class="ml-2" x-small outlined>{{
        item.OPQTY }}</v-chip>
                                      </template>
                                      <span>Open Quantity!</span>
                                    </v-tooltip>
                                  </span>

                                </td>


                                <td class=" text-right" style="width: 160px">
                                  <span v-if="item.BUYQTY" class="font-weight-medium body-2"> {{ item.BUYQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>

                                <td class=" text-right pl-0" style="width: 160px">
                                  <span v-if="item.BUYRATE" class="font-weight-medium body-2"> {{ item.BUYRATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUYAMT" class="font-weight-medium caption"> {{ item.BUYAMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </td>

                                <td class="  text-right" style="width: 150px">
                                  <span v-if="item.SALEQTY" class="font-weight-medium body-2"> {{ item.SALEQTY }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </td>
                                <td style="width: 130px" class="pr-0  text-right">
                                  <span v-if="item.SALERATE" class="font-weight-medium body-2"> {{ item.SALERATE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALEAMT" class="font-weight-medium caption"> {{ item.SALEAMT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>

                                </td>
                                <td class=" text-right pr-2 " style="width: 160px">
                                  <span v-if="item.NETQTY" class="font-weight-medium body-2"> {{ item.NETQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>


                                </td>
                                <td class=" text-right " style="width: 200px">
                                  <span v-if="item.NETRATE" class="font-weight-medium body-2"> {{ item.NETRATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NETAMT" class="font-weight-medium caption"> {{ item.NETAMOUNT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </td>
                                <td style="width: 200px" class=" pr-0 text-right">
                                  <span v-if="item.CL_PRICE" class="font-weight-medium body-2"> {{ item.CL_PRICE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>

                                </td>

                                <td style="width: 250px" class="  text-right">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2"
                                    style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>

                                </td>
                                <td style="width: 80px">

                                </td>

                              </tr>
                            </template>

                            <!-- <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                              <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                            </template>
                            <template v-slot:[`item.NET_QTY`]="{ item }">
                              <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.NET_RATE`]="{ item }">
                              <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                              <br>
                              <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_QTY`]="{ item }">
                              <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_RATE`]="{ item }">
                              <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                              <span v-else class="font-weight-medium caption">0</span>
                            </template>
                            <template v-slot:[`item.SALE_QTY`]="{ item }">
                              <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.SALE_RATE`]="{ item }">
                              <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.Closing_Price`]="{ item }">
                              <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price
                              }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                              <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template>
                            <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                              <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2" style="color: green">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2" style="color: black">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template> -->
                          </v-data-table>
                          <!-- v-else -->
                          <!-- <v-data-table  :headers="dessertHeaders" :items="dessertsexpand"
                                :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer;  "
                                class="elevation-0 rounded-lg pa-4" fixed-header hide-header @click:row="toggleExpanded4"
                                :loading="loader" height="400px" hide-default-footer hide-default-header show-expand
                                :expanded.sync="expandedinner" item-key="name">
                                <template v-slot:[`item.name`]="{ item }">
                                  <span class="font-weight-bold body-1"> {{ item.name }}</span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                            <td class="px-0" :colspan="headers.length">
                              <v-data-table style="border: 1px solid rgb(233, 233, 233); border-radius: 10px"
                                :items-per-page="item.val.length" height="300px" item fixed-header hide-default-footer dense
                                :headers="headersexpandinside" :items="item.val" item-key="name" class="elevation-0">
                                <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                                </template>
                                <template v-slot:[`item.NET_QTY`]="{ item }">
                                  <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.NET_RATE`]="{ item }">
                                  <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_QTY`]="{ item }">
                                  <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_RATE`]="{ item }">
                                  <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </template>
                                <template v-slot:[`item.SALE_QTY`]="{ item }">
                                  <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.SALE_RATE`]="{ item }">
                                  <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.Closing_Price`]="{ item }">
                                  <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                                  <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                                <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                              </v-data-table>

                            </td>
                          </template>
                        </v-data-table> -->
                        </td>
                      </template>
                    </v-data-table>


                  </v-tab-item>


                  <v-tab-item>
                    <v-data-table :headers="dessertHeaders" :items="dessertsexpandcurrency"
                      :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer"
                      class="cust-table elevation-0 rounded-lg cell-height " fixed-header @click:row="toggleExpanded3"
                      :loading="eqloader" height="495px" hide-default-footer show-expand :expanded.sync="expandedcur"
                      item-key="name" disable-sort>

                      <template v-slot:[`item.name`]="{ item }">
                        <span class="overline"> {{ item.name
                          }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-col class="mx-auto pa-15 mx-0 px-0 " style="background-color: white ;">
                          <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                          <p class="font-weight-bold black--text">No data available
                          </p>
                        </v-col>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="px-0" :colspan="headers.length">
                          <!-- v-if="item.name != 'Assets'" -->
                          <v-data-table :items-per-page="item.val.length" item fixed-header hide-default-footer dense
                            :headers="headersexpandinsidecom" hide-default-header :items="item.val" item-key="name"
                            class="elevation-0">


                            <template v-slot:item="{ item }">
                              <tr class="" style="position: relative">

                                <td class="" style="width:700px">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_SYMBOL }}

                                    <v-tooltip color="black" v-if="item.OPQTY > 0 || item.OPQTY < 0" v-model="item.show"
                                      top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" color="blue" class="ml-2" x-small outlined>{{
        item.OPQTY }}</v-chip>
                                      </template>
                                      <span>Open Quantity!</span>
                                    </v-tooltip>

                                  </span>

                                </td>


                                <td class=" text-right" style="width: 160px">
                                  <span v-if="item.BUYQTY" class="font-weight-medium body-2"> {{ item.BUYQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>

                                <td class=" text-right pl-0" style="width: 160px">
                                  <span v-if="item.BUYRATE" class="font-weight-medium body-2"> {{ item.BUYRATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUYAMT" class="font-weight-medium caption"> {{ item.BUYAMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </td>

                                <td class="  text-right" style="width: 150px">
                                  <span v-if="item.SALEQTY" class="font-weight-medium body-2"> {{ item.SALEQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </td>
                                <td style="width: 130px" class="pr-0  text-right">
                                  <span v-if="item.SALERATE" class="font-weight-medium body-2"> {{ item.SALERATE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALEAMT" class="font-weight-medium caption"> {{ item.SALEAMT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>

                                </td>
                                <td class=" text-right pr-2 " style="width: 160px">
                                  <span v-if="item.NETQTY" class="font-weight-medium body-2"> {{ item.NETQTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>


                                </td>
                                <td class=" text-right " style="width: 200px">
                                  <span v-if="item.NETRATE" class="font-weight-medium body-2"> {{ item.NETRATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NETAMT" class="font-weight-medium caption"> {{ item.NETAMOUNT
                                    }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </td>
                                <td style="width: 200px" class=" pr-0 text-right">
                                  <span v-if="item.CL_PRICE" class="font-weight-medium body-2"> {{ item.CL_PRICE
                                    }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>

                                </td>

                                <td style="width: 250px" class="  text-right">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2"
                                    style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>

                                </td>
                                <td style="width: 80px">

                                </td>

                              </tr>
                            </template>

                            <!-- <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                              <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                            </template>
                            <template v-slot:[`item.NET_QTY`]="{ item }">
                              <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.NET_RATE`]="{ item }">
                              <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                              <br>
                              <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_QTY`]="{ item }">
                              <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.BUY_RATE`]="{ item }">
                              <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                              <span v-else class="font-weight-medium caption">0</span>
                            </template>
                            <template v-slot:[`item.SALE_QTY`]="{ item }">
                              <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.SALE_RATE`]="{ item }">
                              <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                              <br>
                              <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                              <span v-else class="font-weight-medium caption"> 0</span>
                            </template>
                            <template v-slot:[`item.Closing_Price`]="{ item }">
                              <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price
                              }}</span>
                              <span v-else class="font-weight-medium body-2"> 0</span>
                            </template>
                            <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                              <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT
                              }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template>
                            <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                              <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2" style="color: green">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2" style="color: black">
                                {{ item.NOTIONAL_NET }}</span>
                              <span v-else class="font-weight-medium body-2">0</span>
                            </template> -->
                          </v-data-table>
                          <!-- v-else -->
                          <!-- <v-data-table  :headers="dessertHeaders" :items="dessertsexpand"
                                :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer;  "
                                class="elevation-0 rounded-lg pa-4" fixed-header hide-header @click:row="toggleExpanded4"
                                :loading="loader" height="400px" hide-default-footer hide-default-header show-expand
                                :expanded.sync="expandedinner" item-key="name">
                                <template v-slot:[`item.name`]="{ item }">
                                  <span class="font-weight-bold body-1"> {{ item.name }}</span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                            <td class="px-0" :colspan="headers.length">
                              <v-data-table style="border: 1px solid rgb(233, 233, 233); border-radius: 10px"
                                :items-per-page="item.val.length" height="300px" item fixed-header hide-default-footer dense
                                :headers="headersexpandinside" :items="item.val" item-key="name" class="elevation-0">
                                <template v-slot:[`item.SCRIP_NAMEDATA`]="{ item }">
                                  <span class="font-weight-bold body-2"> {{ item.SCRIP_NAMEDATA }}</span>
                                </template>
                                <template v-slot:[`item.NET_QTY`]="{ item }">
                                  <span v-if="item.NET_QTY" class="font-weight-medium body-2"> {{ item.NET_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.NET_RATE`]="{ item }">
                                  <span v-if="item.NET_RATE" class="font-weight-medium body-2"> {{ item.NET_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                  <br>
                                  <span v-if="item.NET_AMOUNT" class="font-weight-medium caption"> {{ item.NET_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_QTY`]="{ item }">
                                  <span v-if="item.BUY_QTY" class="font-weight-medium body-2"> {{ item.BUY_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.BUY_RATE`]="{ item }">
                                  <span v-if="item.BUY_RATE" class="font-weight-medium body-2"> {{ item.BUY_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.BUY_AMT" class="font-weight-medium caption"> {{ item.BUY_AMT }}</span>
                                  <span v-else class="font-weight-medium caption">0</span>
                                </template>
                                <template v-slot:[`item.SALE_QTY`]="{ item }">
                                  <span v-if="item.SALE_QTY" class="font-weight-medium body-2"> {{ item.SALE_QTY }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.SALE_RATE`]="{ item }">
                                  <span v-if="item.SALE_RATE" class="font-weight-medium body-2"> {{ item.SALE_RATE }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                  <br>
                                  <span v-if="item.SALE_AMT" class="font-weight-medium caption"> {{ item.SALE_AMT }}</span>
                                  <span v-else class="font-weight-medium caption"> 0</span>
                                </template>
                                <template v-slot:[`item.Closing_Price`]="{ item }">
                                  <span v-if="item.Closing_Price" class="font-weight-medium body-2"> {{ item.Closing_Price }}</span>
                                  <span v-else class="font-weight-medium body-2"> 0</span>
                                </template>
                                <template v-slot:[`item.CURR_AMOUNT`]="{ item }">
                                  <span v-if="item.CURR_AMOUNT" class="font-weight-medium body-2"> {{ item.CURR_AMOUNT }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                                <template v-slot:[`item.NOTIONAL_NET`]="{ item }">
                                  <span v-if="item.NOTIONAL_NET < 0" class="font-weight-medium body-2" style="color: red">
                                    {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET > 0" class="font-weight-medium body-2"
                                    style="color: green"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else-if="item.NOTIONAL_NET == 0" class="font-weight-medium body-2"
                                    style="color: black"> {{ item.NOTIONAL_NET }}</span>
                                  <span v-else class="font-weight-medium body-2">0</span>
                                </template>
                              </v-data-table>

                            </td>
                          </template>
                        </v-data-table> -->
                        </td>
                      </template>
                    </v-data-table>


                  </v-tab-item>
                </v-tabs-items>

              </div>
            </div>
          </v-card>
        </v-col>
      </div>

      <div class="d-md-none">
        <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent;">
            <v-list-item class=" pt-0 px-0">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                  <path
                    d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                    fill="#C8C8C8" />
                  <path
                    d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                    fill="#C8C8C8" />
                  <path
                    d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                    fill="#363636" />
                  <path
                    d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                    fill="#E3E3E3" />
                  <path
                    d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                    fill="#43A6DD" />
                  <path
                    d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                    fill="#43A6DD" />
                  <path
                    d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                    fill="#43A6DD" />
                  <path
                    d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                    fill="#43A6DD" />
                  <path
                    d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                    fill="#43A6DD" />
                  <path
                    d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                    fill="#43A6DD" />
                  <path
                    d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                    fill="#43A6DD" />
                  <path
                    d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                    fill="#43A6DD" />
                  <path
                    d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                    fill="#363636" />
                </svg> -->
              <v-list-item-content class=" pt-0">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                  Tax P&L </v-list-item-title>

                <v-list-item-subtitle class="text-start pt-1"
                  style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                  and Loss of your trades.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>

          <v-list class=" pt-0" style="background-color: transparent;">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
              <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
              <!-- height="16px" /> -->




            </v-list-item>
            <!-- <v-list-item-content class="pa-0 pt-1">
                <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                  recommended</v-list-item-title>
              </v-list-item-content> -->
          </v-list>


        </v-toolbar>
        <v-divider class=" mx-2"></v-divider>
        <v-card v-if="tabvaluestack == 'Equities'" color="#eeeeee" outlined
          class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
          <!-- <div class="text-center pa-4">
            <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
              Total Charges</p>
            <p class="headline ctnbtltext-center" style="line-height:28px; color: #E12626; ">
              {{ segval }}
            </p>
             
          </div> -->
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Long Term Realized P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="longnetval < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (longnetval).toFixed(2) }}

                    </p>
                    <p v-else-if="longnetval > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (longnetval).toFixed(2) }}

                    </p>
                    <p v-else-if="longnetval == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ longnetval }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Short Term Realized P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="stnetamt < 0" class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                      {{ (stnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="stnetamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (stnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="(stnetamt).toFixed(2) == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ stnetamt }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Trading P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="tradenetamt < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (tradenetamt).toFixed(2) }}

                    </p>
                    <p v-if="tradenetamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (tradenetamt).toFixed(2) }}

                    </p>
                    <p v-if="tradenetamt == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (tradenetamt).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Assets P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="assnetamt < 0" class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                      {{ (assnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="assnetamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (assnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="(assnetamt).toFixed(2) == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ assnetamt }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Trading Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="totalintraday < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (totalintraday).toFixed(2) }}

                    </p>
                    <p v-if="totalintraday > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (totalintraday).toFixed(2) }}

                    </p>
                    <p v-if="totalintraday == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (totalintraday).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Total Charges</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="segval < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (segval).toFixed(2) }}

                    </p>
                    <p v-if="segval > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (segval).toFixed(2) }}

                    </p>
                    <p v-if="segval == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (segval).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>


            </v-row>
          </v-card>
        </v-card>
        <v-card v-else-if="tabvaluestack == 'Derivatives'" color="#eeeeee" outlined
          class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
          <!-- <div class="text-center pa-4">
            <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
              Total Charges</p>
            <p v-if="derchargers" class="headline ctnbtltext-center" style="line-height:28px; color: #E12626; ">
              {{ (derchargers).toFixed(2) }}
            </p>
           
          </div> -->
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Futures P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="futopened < 0" class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                      {{ (futopened).toFixed(2) }}

                    </p>
                    <p v-if="futopened > 0" class="ctnbtl body-1 text-right" style="line-height:28px; color: #34A853; ">
                      {{ (futopened).toFixed(2) }}

                    </p>
                    <p v-if="futopened == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (futopened).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Futures Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="futurnover < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (futurnover).toFixed(2) }}

                    </p>
                    <p v-if="futurnover > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (futurnover).toFixed(2) }}

                    </p>
                    <p v-if="futurnover == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (futurnover).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Options P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="optionopn < 0" class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                      {{ (optionopn).toFixed(2) }}

                    </p>
                    <p v-else-if="optionopn > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (optionopn).toFixed(2) }}

                    </p>
                    <p v-else-if="optionopn == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (optionopn).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Options Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="opturnover < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (opturnover).toFixed(2) }}

                    </p>
                    <p v-else-if="opturnover > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (opturnover).toFixed(2) }}

                    </p>
                    <p v-else-if="opturnover == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (opturnover).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Total Charges</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="derchargers < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (derchargers).toFixed(2) }}

                    </p>
                    <p v-else-if="derchargers > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (derchargers).toFixed(2) }}

                    </p>
                    <p v-else-if="derchargers == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (derchargers).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Other credits/debits</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="derothersdbcr < 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #E12626; ">
                        {{ (derothersdbcr).toFixed(2) }}

                      </p>
                      <p v-else-if="derothersdbcr > 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #34A853; ">
                        {{ (derothersdbcr).toFixed(2) }}

                      </p>
                      <p v-else-if="(derothersdbcr).toFixed(2) == 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #505359; ">
                        {{ derothersdbcr }}

                      </p>
                  </v-col>
                </v-row>
              </v-col> -->

            </v-row>
          </v-card>
        </v-card>
        <v-card v-else-if="tabvaluestack == 'Commodity'" color="#eeeeee" outlined
          class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
          <!-- <div class="text-center pa-4">
            <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
              Total Charges</p>
            <p class="headline ctnbtltext-center" style="line-height:28px; color: #E12626; ">
              {{ (comchargers).toFixed(2) }}
             
          </div> -->
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Futures P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="comnetfutamt < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (comnetfutamt).toFixed(2) }}

                    </p>
                    <p v-if="comnetfutamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (comnetfutamt).toFixed(2) }}

                    </p>
                    <p v-if="comnetfutamt == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (comnetfutamt).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Futures Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="futturnover < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (futturnover).toFixed(2) }}

                    </p>
                    <p v-if="futturnover > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (futturnover).toFixed(2) }}

                    </p>
                    <p v-if="futturnover == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (futturnover).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Options P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="comnetamt < 0" class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                      {{ (comnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="comnetamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (comnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="comnetamt == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (comnetamt).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Options Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="comturnover < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (comturnover).toFixed(2) }}

                    </p>
                    <p v-else-if="comturnover > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (comturnover).toFixed(2) }}

                    </p>
                    <p v-else-if="comturnover == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (comturnover).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Total charges</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="comchargers < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (comchargers).toFixed(2) }}

                    </p>
                    <p v-else-if="comchargers > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (comchargers).toFixed(2) }}

                    </p>
                    <p v-else-if="comchargers == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (comchargers).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Other credits/debits</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="comothersdbcr < 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #E12626; ">
                        {{ (comothersdbcr).toFixed(2) }}

                      </p>
                      <p v-else-if="comothersdbcr > 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #34A853; ">
                        {{ (comothersdbcr).toFixed(2) }}

                      </p>
                      <p v-else-if="comothersdbcr == 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #505359; ">
                        {{ comothersdbcr }}

                      </p>
                  </v-col> -->
              <!-- </v-row> -->
              <!-- </v-col> -->

            </v-row>
          </v-card>
        </v-card>
        <v-card v-else-if="tabvaluestack == 'Currency'" color="#eeeeee" outlined
          class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
          <!-- <div class="text-center pa-4">
            <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
              Total Charges</p>
            <p class="headline ctnbtltext-center" style="line-height:28px; color: #E12626; ">
              {{ (curchargers).toFixed(2) }}
            </p>
            
          </div> -->
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Futures P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="curnetfutamt < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (curnetfutamt).toFixed(2) }}

                    </p>
                    <p v-if="curnetfutamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (curnetfutamt).toFixed(2) }}

                    </p>
                    <p v-if="curnetfutamt == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (curnetfutamt).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Futures Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="curfutturnover < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (curfutturnover).toFixed(2) }}

                    </p>
                    <p v-if="curfutturnover > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (curfutturnover).toFixed(2) }}

                    </p>
                    <p v-if="curfutturnover == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (curfutturnover).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Options P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="curopnetamt < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (curopnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="curopnetamt > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (curopnetamt).toFixed(2) }}

                    </p>
                    <p v-else-if="curopnetamt == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (curopnetamt).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Options Turnover</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="comopturnover < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (comopturnover).toFixed(2) }}

                    </p>
                    <p v-else-if="comopturnover > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (comopturnover).toFixed(2) }}

                    </p>
                    <p v-else-if="comopturnover == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (comopturnover).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Total charges</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="curchargers < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (curchargers).toFixed(2) }}

                    </p>
                    <p v-else-if="curchargers > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (curchargers).toFixed(2) }}

                    </p>
                    <p v-else-if="curchargers == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (curchargers).toFixed(2) }}

                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Other credits/debits</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="curothersdbcr < 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #E12626; ">
                        {{ (curothersdbcr).toFixed(2) }}

                      </p>
                      <p v-else-if="curothersdbcr > 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #34A853; ">
                        {{ (curothersdbcr).toFixed(2) }}

                      </p>
                      <p v-else-if="(curothersdbcr).toFixed(2) == 0" class="ctnbtl body-1 text-right"
                        style="line-height:28px; color: #505359; ">
                        {{ curothersdbcr }}

                      </p>
                  </v-col>
                </v-row>
              </v-col> -->

            </v-row>
          </v-card>
        </v-card>
        <div class="text-center">
          <v-bottom-sheet v-model="sheet" inset>

            <v-sheet class="text-center" height="200px">
              <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                              <v-icon size="40">mdi mdi-close-circle</v-icon>
                          </v-btn> -->
              <!-- {{ radios }}/ -->
              <p class="mb-0 pt-3 title font-weight-medium">Summary P&L</p>
              <v-card class="elevation-0 ma-8 mt-0">
                <v-radio-group class="mt-0" v-model="radios" mandatory>
                  <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                  <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                </v-radio-group>
                <v-btn @click="downloadsum(radios)" class="white--text elevation-0" color="black" block rounded flat
                  solo>
                  {{
        radios }}</v-btn>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </div>
        <div class="text-center">
          <v-bottom-sheet v-model="sheet1" inset>

            <v-sheet class="text-center" height="200px">
              <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet1 = !sheet1">
                              <v-icon size="40">mdi mdi-close-circle</v-icon>
                          </v-btn> -->
              <!-- {{ radios }}/ -->
              <p class="mb-0 pt-3 title font-weight-medium">Tax PnlL</p>

              <v-card class="elevation-0 ma-8 mt-0">
                <v-radio-group class="mt-0" v-model="radios1" mandatory>
                  <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                  <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                </v-radio-group>
                <v-btn @click="valdwl(radios1)" class="white--text elevation-0" color="black" block rounded flat solo>
                  {{
        radios1 }}</v-btn>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </div>
        <!-- <v-divider class="mt-4 mb-2 "></v-divider>
        <div class="d-flex row">
          <p v-if="summary" class="text-start mt-3 mb-3  ml-2 px-4 "
            style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
            No of symbols
            <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ dessertsoriginal.length }})</span>
          </p>
          <p v-if="!summary" class="text-start mt-3 mb-3  ml-2 px-4 "
            style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
            No of symbols
            <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ desserts23.length }})</span>
          </p>
          <v-btn v-if="summary" @click="sheet = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
              mdi-download</v-icon></v-btn>
          <v-btn v-if="!summary" @click="sheet1 = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
              mdi-download</v-icon></v-btn>
        </div> -->
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-row class="px-5 pa-0 mt-3">

          <!-- <v-col class=" pa-0 px-2">
            <v-text-field v-if="summary" style="width:100%;" class="tophundraedmutual  " height="36px"
              background-color="#F1F3F8" label="Search" v-model="search" solo rounded flat dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
              </template>
            </v-text-field>
            <v-text-field v-if="!summary" style="width:100%;" class="tophundraedmutual  " height="36px"
              background-color="#F1F3F8" label="Search" v-model="searchde" solo rounded flat dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
              </template>
            </v-text-field>
          </v-col> -->
          <v-col class="mb-3 d-flex" cols="6">
            <!-- <v-select v-if="summary" :items="filterr" @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl"
              placeholder="filter" solo rounded flat background-color="#F1F3F8" hide-details class=""
              style="max-width: 100%; font-size: 21px">
            </v-select> -->
            <v-select style="max-width: 100%; font-size: 21px;overflow: hidden" dense background-color="#F1F3F8"
              :loading="disabletabs" rounded :readonly="disabletabs && disabletabsexpense" @change="gettaxpnl(yearis)"
              :items="items" v-model="yearis" flat solo class="mr-2 mt-n3 fildrop" label="From"
              prepend-inner-icon="mdi-calendar" hide-details append-icon="mdi-chevron-down"></v-select>

            <!-- {{disdowbtn}} -->
            <v-btn :disabled="disdowbtn" @click="sheet1 = true" class="ml-auto mr-6 mt-n3" flat solo icon> <v-icon>mdi
                mdi-download</v-icon></v-btn>
            <!-- <v-select v-if="!summary" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
              v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8" hide-details
              class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
            </v-select> -->
          </v-col>
        </v-row>

        <v-row class="pt-0 mt-n3">
          <v-col lg="4" sm="12" md="12" cols="12">
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
              <!-- @click="summary1()"   -->
              <v-tabs v-model="tab" background-color="transparent" color="basil" grow>

                <v-tab v-model="tab2" @click="tabdata('Equities')">
                  Equities
                </v-tab>

                <v-tab style="cursor: wait;" v-if="disabletabs" disabled>
                  Derivatives
                </v-tab>

                <v-tab v-else-if="!disabletabs" v-model="tab3" @click="tabdata('Derivatives')">
                  Derivatives
                </v-tab>

                <v-tab :disabled="disabletabs" v-model="tab4" @click="tabdata('Commodity')">
                  Commodity
                </v-tab>
                <v-tab :disabled="disabletabs" v-model="tab5" @click="tabdata('Currency')"
                  :class="{ 'red-text': disabletabs }">
                  Currency
                </v-tab>

                <!-- <div v-else-if="!disabletabs">
  <v-tab v-model="tab3" @click="tabdata('Derivatives')">
    Derivatives
  </v-tab>
  <v-tab v-model="tab4" @click="tabdata('Commodity')">
    Commodity
  </v-tab>
  <v-tab v-model="tab5" @click="tabdata('Currency')">
    Currency
  </v-tab>
</div> -->
              </v-tabs>

            </v-tabs>

          </v-col>

        </v-row>
        <!-- {{ loader }} -->
        <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogmobile">
          <v-sheet class="text-center" style="height: 100vh">
            <v-list-item-content class=" pt-5 pl-5">
              <v-list-item-title class="text-start"
                style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                Detailed P&L</v-list-item-title>

              <v-list-item-subtitle class="text-start pt-1"
                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> {{ scriptname }}
              </v-list-item-subtitle>
              <v-btn absolute right top icon color="black" @click="dialogmobile = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-content>
            <v-divider></v-divider>

            <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3" height="90%"
              style="overflow: scroll">
              <div class="text-center pa-4">
                <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                  Total</p>
                <p v-if="finaltotal < 0" class="ctnbtl headline mb-0" style="line-height:28px; color: red; ">
                  {{ (finaltotal).toFixed(2) }}</p>
                <p v-if="finaltotal > 0" class="ctnbtl headline mb-0" style="line-height:28px; color: green; ">
                  {{ finaltotal }}</p>
                <p v-if="finaltotal == 0" class="ctnbtl headline mb-0" style="line-height:28px; color: rgb(0, 0, 0); ">
                  {{ finaltotal }}</p>
                <p v-if="finaltotal == ''" class="ctnbtl headline mb-0" style="line-height:28px;  ">
                  -</p>
              </div>
              <v-card v-for="item in detailedpoparray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                class="elevation-0  px-4" width="100%">
                <span class="d-flex row mt-3 mb-3 px-4">
                  <p class="  pb-0 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">{{
        item.TRADE_DATE }}
                  </p>
                  <p v-if="item.NETAMT > 0" class="mb-0 ml-auto body-2 font-weight-regular" style="color: green;">
                    {{ item.NETAMT }}</p>
                  <p v-if="item.NETAMT < 0" class="mb-0 ml-auto body-2 font-weight-regular" style="color: red;">{{
        item.NETAMT }}</p>
                  <p v-if="item.NETAMT == 0" class="mb-0 ml-auto body-2 font-weight-regular" style="color: #000000;">
                    {{ item.NETAMT }}</p>
                </span>

                <v-divider class="mb-2"></v-divider>
                <v-row class="px-2">
                  <v-col>
                    <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">Net
                      Quantity:
                      <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000;">{{
        item.NETQTY
      }}</span>
                    </p>
                    <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">Buy
                      Quantity:
                      <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000;">{{
          item.BQTY
        }}</span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular text-start" style="color: #666666;">Buy
                      Rate :
                      <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
          item.BRATE }}</span>
                    </p>
                  </v-col>
                  <v-col class="text-end">
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net
                      Rate: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        (item.NRATE).toFixed(2) }}
                      </span>
                    </p>
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sale
                      Quantity: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SQTY }}
                      </span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sale Rate :
                      <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        item.SRATE }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                class="elevation-0  px-4" width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-sheet>
        </v-dialog>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!-- <v-card v-for="item in dessertsexpand " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
              class="elevation-0  px-4 pt-2" width="100%"> -->

            <!-- <span @click="detailedpopmobile(item)" class="font-weight-bold">

           

                <span>{{ item.name }}<v-icon color="#0037B7">mdi
                    mdi-menu-right</v-icon></span>
             
              </span> -->
            <v-expansion-panels inset style="z-index: 0">
              <v-expansion-panel v-for="(item, i) in dessertsexpand" :key="i">
                <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card v-for="iitem in item.val " :key="iitem.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <span class="font-weight-bold">

                      {{ iitem.SCRIP_NAMEDATA }}

                      <!-- {{ item.COMPANY_CODE }} -->
                      <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                    </span>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                      <v-col>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                        </p>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.BUY_QTY
      }}</span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ iitem.BUY_RATE
                            }}</span>
                        </p>
                      </v-col>
                      <v-col class="text-end">
                        <p v-if="iitem.NET_AMOUNT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
        iitem.NET_AMOUNT }}</p>
                        <p v-if="iitem.NET_AMOUNT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
        iitem.NET_AMOUNT }}</p>
                        <p v-if="iitem.NET_AMOUNT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                          {{ iitem.NET_AMOUNT }}</p>

                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        item.SALE_QTY }}

                          </span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        item.SALE_RATE
      }}</span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                  </p>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.BUY_QUANTITY
                      }}</span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BUY_RATE
                      }}</span>
                  </p>
                </v-col>
                <v-col class="text-end">
                  <p v-if="item.NET_AMOUNT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                    {{ item.NET_AMOUNT }}</p>

                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_QUANTITY }}

                    </span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_RATE
                      }}</span>
                  </p>
                </v-col>
              </v-row> -->
            <!-- </v-card> -->
            <v-card v-if="dessertsexpand.length == 0 && loader == false"
              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
              width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
              class="elevation-0  px-4" width="100%">
              <v-row class="px-2 ">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- <v-card v-for="item in dessertsexpand " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
              class="elevation-0  px-4 pt-2" width="100%"> -->

            <!-- <span @click="detailedpopmobile(item)" class="font-weight-bold">

           

                <span>{{ item.name }}<v-icon color="#0037B7">mdi
                    mdi-menu-right</v-icon></span>
             
              </span> -->
            <v-expansion-panels inset style="z-index: 0">

              <v-expansion-panel v-for="(item, i) in dessertsexpandderivative" :key="i">
                <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card v-for="iitem in item.val " :key="iitem.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <span class="font-weight-bold">

                      {{ iitem.SCRIP_SYMBOL }}

                      <!-- {{ item.COMPANY_CODE }} -->
                      <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                    </span>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                      <v-col>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                        </p>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.BUYQTY
      }}</span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ iitem.BUYRATE
                            }}</span>
                        </p>
                      </v-col>
                      <v-col class="text-end">
                        <p v-if="iitem.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
        iitem.NETAMT }}</p>
                        <p v-if="iitem.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
        iitem.NETAMT }}</p>
                        <p v-if="iitem.NETAMT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                          {{ iitem.NETAMT }}</p>

                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.SALEQTY }}

                          </span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.SALERATE
      }}</span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                  </p>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.BUY_QUANTITY
                      }}</span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BUY_RATE
                      }}</span>
                  </p>
                </v-col>
                <v-col class="text-end">
                  <p v-if="item.NET_AMOUNT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                    {{ item.NET_AMOUNT }}</p>

                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_QUANTITY }}

                    </span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_RATE
                      }}</span>
                  </p>
                </v-col>
              </v-row> -->
            <!-- </v-card> -->
            <v-card v-if="dessertsexpandderivative.length == 0 && loader == false"
              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
              width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
              class="elevation-0  px-4" width="100%">
              <v-row class="px-2 ">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- <v-card v-for="item in dessertsexpand " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
              class="elevation-0  px-4 pt-2" width="100%"> -->

            <!-- <span @click="detailedpopmobile(item)" class="font-weight-bold">

           

                <span>{{ item.name }}<v-icon color="#0037B7">mdi
                    mdi-menu-right</v-icon></span>
             
              </span> -->
            <v-expansion-panels inset style="z-index: 0">

              <v-expansion-panel v-for="(item, i) in dessertsexpandcommodity" :key="i">
                <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card v-for="iitem in item.val " :key="iitem.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <span class="font-weight-bold">

                      {{ iitem.SCRIP_SYMBOL }}

                      <!-- {{ item.COMPANY_CODE }} -->
                      <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                    </span>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                      <v-col>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                        </p>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.BUYQTY
      }}</span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ iitem.BUYRATE
                            }}</span>
                        </p>
                      </v-col>
                      <v-col class="text-end">
                        <p v-if="iitem.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
        iitem.NETAMT }}</p>
                        <p v-if="iitem.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
        iitem.NETAMT }}</p>
                        <p v-if="iitem.NETAMT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                          {{ iitem.NETAMT }}</p>

                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.SALEQTY }}

                          </span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.SALERATE
      }}</span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                  </p>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.BUY_QUANTITY
                      }}</span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BUY_RATE
                      }}</span>
                  </p>
                </v-col>
                <v-col class="text-end">
                  <p v-if="item.NET_AMOUNT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                    {{ item.NET_AMOUNT }}</p>

                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_QUANTITY }}

                    </span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_RATE
                      }}</span>
                  </p>
                </v-col>
              </v-row> -->
            <!-- </v-card> -->
            <v-card v-if="dessertsexpandcommodity.length == 0 && loader == false"
              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
              width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
              class="elevation-0  px-4" width="100%">
              <v-row class="px-2 ">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- <v-card v-for="item in dessertsexpand " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
              class="elevation-0  px-4 pt-2" width="100%"> -->

            <!-- <span @click="detailedpopmobile(item)" class="font-weight-bold">

           

                <span>{{ item.name }}<v-icon color="#0037B7">mdi
                    mdi-menu-right</v-icon></span>
             
              </span> -->
            <v-expansion-panels inset style="z-index: 0">

              <v-expansion-panel v-for="(item, i) in dessertsexpandcurrency" :key="i">
                <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card v-for="iitem in item.val " :key="iitem.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <span class="font-weight-bold">

                      {{ iitem.SCRIP_SYMBOL }}

                      <!-- {{ item.COMPANY_CODE }} -->
                      <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                    </span>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                      <v-col>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                        </p>
                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.BUYQTY
      }}</span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ iitem.BUYRATE
                            }}</span>
                        </p>
                      </v-col>
                      <v-col class="text-end">
                        <p v-if="iitem.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
        iitem.NETAMT }}</p>
                        <p v-if="iitem.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
        iitem.NETAMT }}</p>
                        <p v-if="iitem.NETAMT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                          {{ iitem.NETAMT }}</p>

                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.SALEQTY }}

                          </span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
        iitem.SALERATE
      }}</span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                  </p>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.BUY_QUANTITY
                      }}</span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BUY_RATE
                      }}</span>
                  </p>
                </v-col>
                <v-col class="text-end">
                  <p v-if="item.NET_AMOUNT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                    item.NET_AMOUNT }}</p>
                  <p v-if="item.NET_AMOUNT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                    {{ item.NET_AMOUNT }}</p>

                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_QUANTITY }}

                    </span>
                  </p>
                  <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                      class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.SALE_RATE
                      }}</span>
                  </p>
                </v-col>
              </v-row> -->
            <!-- </v-card> -->
            <v-card v-if="dessertsexpandcurrency.length == 0 && loader == false"
              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
              width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
              class="elevation-0  px-4" width="100%">
              <v-row class="px-2 ">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>


      </div>
    </v-container>
  </v-app>
</template>

<script>
// import CalendarProfitlose from '@/views/heatmap calendars/CalendarProfitlose.vue';
import { apiurl } from '../../../Api.js'
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import zebupdfImage from '../../../assets/zebupdf.png';
import ExcelJS from 'exceljs';
import imagePath from '@/assets/zebulogo.svg';
export default {
  data() {
    return {
      show: false,
      othersdbcr: 0,
      longnetval: 0,
      eqarr: [],
      comothersdbcr: 0,
      downloadarray: [],
      curothersdbcr: 0,
      derothersdbcr: 0,
      opturnover: 0,
      futurnover: 0,
      derclsed: 0,
      futopened: 0,
      optioncls: 0,
      optionopn: 0,
      tradenetamt: 0,
      stnetamt: 0,
      assnetamt: 0,
      statequity: [
        {
          name: 'Trade P&L',
          val: ''
        },
        {
          name: 'TradeTurnover',
          val: ''
        },
        {
          name: 'Short Term Realized P&L',
          val: ''
        },
        {
          name: 'Long Term Realized P&L',
          val: ''
        },
        {
          name: 'Total Chanrges',
          val: ''
        },
        {
          name: 'Other credits/debits',
          val: ''
        },

      ],
      downloadarrayForPdf : [],
      chararr: [],
      statderivate: [
        {
          name: 'Futures P&L',
          val: ''
        },
        {
          name: 'Futures Turnover',
          val: ''
        },
        {
          name: 'Options P&L',
          val: ''
        },
        {
          name: 'Oprions Turnover',
          val: ''
        },
        {
          name: 'Total Charges',
          val: ''
        },
        {
          name: 'Other credits/debits',
          val: ''
        },
        {
          name: 'Total Gross Profit',
          val: ''
        },

      ],
      statcommo: [
        {
          name: 'Futures P&L',
          val: ''
        },
        {
          name: 'Futures Turnover',
          val: ''
        },
        {
          name: 'Options P&L',
          val: ''
        },
        {
          name: 'Oprions Turnover',
          val: ''
        },
        {
          name: 'Total Charges',
          val: ''
        },
        {
          name: 'Other credits/debits',
          val: ''
        },
        {
          name: 'Total Gross Profit',
          val: ''
        },

      ],
      statcurr: [
        {
          name: 'Futures P&L',
          val: ''
        },
        {
          name: 'Futures Turnover',
          val: ''
        },
        {
          name: 'Options P&L',
          val: ''
        },
        {
          name: 'Oprions Turnover',
          val: ''
        },
        {
          name: 'Total Charges',
          val: ''
        },
        {
          name: 'Other credits/debits',
          val: ''
        },
        {
          name: 'Total Gross Profit',
          val: ''
        },

      ],
      menu2: false,

      dessertsdummy: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: 1,
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: 1,
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: 7,
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: 8,
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: 16,
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: 0,
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: 2,
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: 45,
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: 22,
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: 6,
        },
      ],
      expanded: [],
      expandedder: [],
      expandedcom: [],
      expandedcur: [],
      downbtn: true,
      expandedinner: [],
      singleExpand: false,
      dessertsexpandinner: [],
      tab1: null,
      tab2: null,
      tab3: null,
      tab4: null,
      tab5: null,
      segloader: false,
      disdowbtn: true,
      dessertHeaders: [
        {
          text: 'Symbol',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '28%'

        },
        { text: 'Buy Qty', value: '', align: 'right', width: '9%' },
        { text: 'Buy Rate', value: '', width: '9%', align: 'right' },
        { text: 'Sell Qty', value: '', width: '9%', align: 'right' },
        { text: 'Sell Rate', value: '', width: '9%', align: 'right' },
        { text: 'Net Qty', value: '', width: '9%', align: 'right' },
        { text: 'Net Rate', value: '', width: '9%', align: 'right' },
        { text: 'Close price', value: '', width: '9%', align: 'right' },
        { text: 'P & L', value: '', width: '15%', align: 'right' },
        { text: '', value: 'data-table-expand' },
      ],
      headersexpandinside: [
        {
          text: 'Symbol',
          align: 'start',
          sortable: false,
          value: 'SCRIP_NAMEDATA',
          width: '30%'
        },

        // { text: 'Net Amount', value: 'NETAMT' },

        { text: 'Buy Qty', value: 'BUY_QTY', sortable: false, align: 'right' },
        { text: 'Buy Rate', value: 'BUY_RATE', sortable: false, align: 'right' },
        // { text: 'Buy Amount', value: 'BAMT' },
        { text: 'Sell Qty', value: 'SALE_QTY', sortable: false, align: 'right' },
        { text: 'Sell Rate', value: 'SALE_RATE', sortable: false, align: 'right' },

        { text: 'Net Qty', value: 'NET_QTY', sortable: false, align: 'right' },
        { text: 'Net Rate', value: 'NET_RATE', sortable: false, align: 'right' },
        // { text: 'Sell Amount', value: 'SAMT' },
        { text: 'Close price', value: 'Closing_Price', sortable: false, align: 'right' },
        // { text: 'Current Value', value: 'CURR_AMOUNT', sortable: false, align: 'right' },
        { text: 'P&l', value: 'NOTIONAL_NET', align: 'right' },
        { text: '', value: 'data-table-expand' },

        // { text: 'P & L', value: '' },
        // { text: '', value: 'data-table-expand' },
      ],
      headersexpandinsidecom: [
        {
          text: 'Symbol',
          align: 'start',
          sortable: false,
          value: 'SCRIP_SYMBOL',
          width: '15%'
        },

        // { text: 'Net Amount', value: 'NETAMT' },

        { text: 'Buy Qty', value: 'BUYQTY', sortable: false, align: 'right' },
        { text: 'Buy Rate', value: 'BUYRATE', sortable: false, align: 'right' },
        // { text: 'Buy Amount', value: 'BAMT' },
        { text: 'Sell Qty', value: 'SALEQTY', sortable: false, align: 'right' },
        { text: 'Sell Rate', value: 'SALERATE', sortable: false, align: 'right' },

        { text: 'Net Qty', value: 'NETQTY', sortable: false, align: 'right' },
        { text: 'Net Rate', value: 'NETRATE', sortable: false, align: 'right' },
        // { text: 'Sell Amount', value: 'SAMT' },
        { text: 'Close price', value: 'CL_PRICE', sortable: false, align: 'right' },
        // { text: 'Current Value', value: 'CURR_AMOUNT', sortable: false, align: 'right' },
        { text: 'P&l', value: 'NOTIONAL_NET', align: 'right' },
        { text: '', value: 'data-table-expand' },

        // { text: 'P & L', value: '' },
        // { text: '', value: 'data-table-expand' },
      ],
      items: [
      ],
      yearis: '',
      dessertsexpand: [],
      dessertsexpandderivative: [],
      eqloader: false,
      dessertsexpandcommodity: [],
      dessertsexpandcurrency: [],

      dialogmobile: false,
      summary: true,
      radios: null,
      radios1: null,
      inject: {
        theme: {
          default: { isDark: false },
        },
      },
      itemss: [

      ],
      search: '',
      itemperpageinexpandinner: 0,
      searchde: '',
      // expanded: [],
      more: true,
      less: false, more1: true,
      less1: false,
      itemperpage: 8,
      msg: '',

      alert: false,

      sheet: false,
      sheet1: false,
      itemperpage1: 8,
      loader: true,
      headerval: [],
      dialog: false,
      detailedpoparray: [],
      tables: [
        
        // Add more tables as needed
      ],
      yearindex: "",
      search1: '',
      tabvaluestack: 'Equities',
      itemperpage1inner: 0,
      // tab: null,
      // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
      desserts: [],
      dessertsoriginal: [],
      scriptname: '',
      dessertspop: [],
      dessertspopexpense: [],
      disabletabsexpense: true,
      totalintraday: 0,
      desserts2: [],
      sortBy: 'SCRIP_NAME',
      dates: [""],
      filterr: [
        "All", "Equities", "Future & Options", "Commodities", "Currencies"
      ],
      filterdata_unpl: 'All',
      filterdata_unpl1: 'All',
      itemsdwl: [
        {
          title: 'Download Excel',
        },
        {
          title: 'Download Pdf',
        },
      ],
      Overviewheaders2: [
        { text: "Trade Date", value: "TRADE_DATE", width: "10%", class: "headerfont" },

        // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },


        { text: 'Buy Qty', value: 'BQTY', align: "end", class: "headerfont", width: "10%" },
        { text: 'Buy Rate', value: 'BRATE', align: "end", class: "headerfont", width: "10%" },
        // { text: 'Buy Amt', value: 'BAMT', align: "end" },
        { text: 'Sell Qty', value: 'SQTY', align: "end", class: "headerfont", width: "10%" },
        { text: 'Sell Rate', value: 'SRATE', align: "end", class: "headerfont", width: "10%" },
        // { text: 'Sale Amt', value: 'SAMT', align: "end" },
        { text: 'Net Qty', value: 'NETQTY', align: "end", class: "headerfont", width: "10%" },
        { text: 'Net Rate', value: 'NRATE', align: "end", class: "headerfont", width: "10%" },
        // { text: 'NET Amt', value: 'NETAMT', align: "end" },
        // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
        { text: 'P & L', value: 'PRO', align: "end", class: "headerfont", width: "10%" },


      ],
      finaltotal: 0,
      profiledetails: [],
      cname: '',
      Overviewheaders: [
        { text: "Symbol", value: "SCRIP_SYMBOL", class: "headerfont" },
        // { text: '', value: 'data-table-expand', class: "headerfont" },
        // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
        // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
        { text: "Buy Qty", value: "BUY_QUANTITY", align: "end", class: "headerfont" },
        { text: "Buy Rate", value: "BUY_RATE", align: "end", class: "headerfont" },
        // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
        { text: "Sell Qty", value: "SALE_QUANTITY", align: "end", class: "headerfont" },
        { text: "Sell Rate", value: "SALE_RATE", align: "end", class: "headerfont" },
        // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
        { text: "Net Qty", value: "NET_QUANTITY", align: "end", class: "headerfont" },
        { text: "Net Rate", value: "NET_RATE", align: "end", class: "headerfont" },
        { text: "Close price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
        // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        { text: "P & L", value: "NOT_PROFIT", align: "end", class: "headerfont" },
      ],
      Overviewheadersder: [
        { text: "Symbol", value: "SCRIP_SYMBOL", class: "headerfont" },
        // { text: '', value: 'data-table-expand', class: "headerfont" },
        // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
        // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
        { text: "Buy Qty", value: "BUY_QUANTITY", align: "end", class: "headerfont" },
        { text: "Buy Rate", value: "BUY_RATE", align: "end", class: "headerfont" },
        // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
        { text: "Sell Qty", value: "SALE_QUANTITY", align: "end", class: "headerfont" },
        { text: "Sell Rate", value: "SALE_RATE", align: "end", class: "headerfont" },
        // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
        { text: "Net Qty", value: "NET_QUANTITY", align: "end", class: "headerfont" },
        { text: "Net Rate", value: "NET_RATE", align: "end", class: "headerfont" },
        { text: "Close price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
        // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        { text: "P & L", value: "NOT_PROFIT", align: "end", class: "headerfont" },
      ],
      tab: null,
      // items: [
      //   'Summary', 'Detailed',
      // ],
      desserts23: [],
      detpop: [],
      actid: '',
      susertoken: '',
      keyyy: '',
      downsum: true,
      disrealised_pnl: '',
      disunrealised_pnl: '',
      year: '',
      dessertstaxdis: [],
      disabletabs: true,
      fromdate: '',
      fromdateapi: '',
      fromshow: '',
      toshow: '',
      todate: '',
      comnetamt: 0,
      comturnover: 0,
      futturnover: 0,
      comnetfutamt: 0,
      derchargers: 0,
      comchargers: 0,
      curchargers: 0,
      segval: 0,
      curfutturnover: 0,
      curopnetamt: 0,
      comopturnover: 0,
      curnetfutamt: 0,
      imageBase64: null,
      // intraday: 0,
    };
  },
  // components: { CalendarProfitlose },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    filteredDesserts() {
      const searchTerm = this.search.toLowerCase();
      return this.dessertsoriginal.filter(item => item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm));
    },
    filteredDesserts1() {
      const searchTerm = this.searchde.toLowerCase();
      return this.desserts23.filter(item => item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm));
    },
  },
  methods: {
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },

    convertImageToBase64() {

      fetch(imagePath)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.imageBase64 = reader.result;
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('Error loading local image:', error);
        });
    },




    valdwl(item) {
      this.sheet1 = false

      let data = item
     

//       if (data === "Download Excel") {
//   const workbook = new ExcelJS.Workbook();
//   const worksheet = workbook.addWorksheet('My Sheet');

//   // Combine tables with the same heading
//   const combinedTables = this.tables.reduce((acc, current) => {
//     let existingTable = acc.find(table => table.heading === current.heading);

//     if (existingTable) {
//       // Combine data if the heading already exists
//       existingTable.data = existingTable.data.concat(current.data);
//     } else {
//       // Add new table if heading doesn't exist
//       acc.push({
//         heading: current.heading,
//         data: current.data
//       });
//     }

//     return acc;
//   }, []);

//   let rows = [];

//   // Loop through the combined tables and generate rows based on headings and data
//   combinedTables.forEach(table => {
//     const heading = table.heading;
//     const tableData = table.data;

//     // Add table heading
//     rows.push([heading]);

//     // Add header row (if it exists)
//     if (tableData.length > 0) {
//       rows.push(tableData[0]);

//       // Add data rows (excluding the header row)
//       for (let i = 1; i < tableData.length; i++) {
//         rows.push(tableData[i]);
//       }
//     }
//     rows.push([]); // Add a blank row between tables
//   });

//   // Header rows for the export
//   const headerRows = [
//     ["ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED"],
//     [],
//     ["Tax P&L"],
//     [],
//     ["Client ID", this.actid],
//     ["Client Name", this.cname],
//     [],
//     ['Equities', "", "", 'Derivatives'],
//     [`Long Term Realized P&L : ${(this.longnetval).toFixed(2)}`, `Assets P&L : ${(this.assnetamt).toFixed(2)}`, "", `Futures P&L : ${(this.futopened).toFixed(2)}`, `Options P&L : ${(this.optionopn).toFixed(2)}`],
//     [`Short Term Realized P&L : ${(this.stnetamt).toFixed(2)}`, `Trading Turnover : ${(this.totalintraday).toFixed(2)}`, "", `Futures Turnover : ${(this.futurnover).toFixed(2)}`, `Options Turnover : ${(this.opturnover).toFixed(2)}`],
//     [`Trading P&L : ${(this.tradenetamt).toFixed(2)}`, `Total Charges : ${(this.segval).toFixed(2)}`, "", `Total Charges ${(this.derchargers).toFixed(2)}`],
//     [],
//     ['Commodity', "", "", 'Currency'],
//     [`Futures P&L : ${(this.comnetfutamt).toFixed(2)}`, `Options P&L : ${(this.comnetamt).toFixed(2)}`, "", `Futures P&L : ${(this.curnetfutamt).toFixed(2)}`, `Options P&L : ${(this.curopnetamt).toFixed(2)}`],
//     [`Futures Turnover : ${(this.futturnover).toFixed(2)}`, `Options Turnover : ${(this.comturnover).toFixed(2)}`, "", `Futures Turnover  : ${(this.curfutturnover).toFixed(2)}`, `Options Turnover : ${(this.comopturnover).toFixed(2)}`],
//     [`Total Charges : ${(this.comchargers).toFixed(2)}`, "", "", `Total Charges : ${(this.curchargers).toFixed(2)}`],
//     []
//   ];

//   // Arrays for specific data sections
//   let equityRows = [];
//   let derivativeRows = [];
//   let commodityRows = [];
//   let currencyRows = [];

//   if (this.eqarr && Array.isArray(this.eqarr)) {
//     equityRows.push(['Equities Charges']);
//     equityRows.push(["Symbol", "Net Amount"]);
//     this.eqarr.forEach(element => {
//       equityRows.push([element.SCRIP_SYMBOL, element.NOT_PROFIT]);
//     });
//   }
//   equityRows.push([]);

//   if (this.chararr && this.chararr.der_charges && Array.isArray(this.chararr.der_charges)) {
//     derivativeRows.push(['Derivative Charges']);
//     derivativeRows.push(["Symbol", "Net Amount"]);
//     this.chararr.der_charges.forEach(element => {
//       derivativeRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
//     });
//   }
//   derivativeRows.push([]);

//   if (this.chararr && this.chararr.comm_charges && Array.isArray(this.chararr.comm_charges)) {
//     commodityRows.push(['Commodity Charges']);
//     commodityRows.push(["Symbol", "Net Amount"]);
//     this.chararr.comm_charges.forEach(element => {
//       commodityRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
//     });
//   }
//   commodityRows.push([]);

//   if (this.chararr && this.chararr.cur_charges && Array.isArray(this.chararr.cur_charges)) {
//     currencyRows.push(['Currency Charges']);
//     currencyRows.push(["Symbol", "Net Amount"]);
//     this.chararr.cur_charges.forEach(element => {
//       currencyRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
//     });
//   }
//   currencyRows.push([]);

//   // Combine all rows for the export
//   let allRows = headerRows.concat(rows, [], equityRows, [], derivativeRows, [], commodityRows, [], currencyRows);

//   // Add rows to the worksheet
//   allRows.forEach((row) => {
//     const excelRow = worksheet.addRow(row);

//     // Apply background colors based on row content
//     row.forEach((cell, cellIndex) => {
//       const cellRef = excelRow.getCell(cellIndex + 1);
//       if (['Equities Charges', 'Derivative Charges', 'Commodity Charges', 'Currency Charges', 'Commodity Option Open', 'Commodity Option Closed', 'Equities', 'Derivatives', 'Commodity', 'Currency', 'Derivative Option Open', 'Derivative Option Closed', 'Assets', 'Liabilities', 'Trading', 'ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Tax P&L', 'Client ID', 'Client Name'].includes(cell)) {
//         cellRef.fill = {
//           type: 'pattern',
//           pattern: 'solid',
//           fgColor: { argb: 'FCD5B4' } // Light orange background color
//         };
//       } else if (['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L', 'Net Amount'].includes(cell)) {
//         cellRef.fill = {
//           type: 'pattern',
//           pattern: 'solid',
//           fgColor: { argb: 'FFFF00' } // Yellow background color
//         };
//       }
//     });
//   });

//   // Set specific column width for "ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED"
//   const longText = "ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED";
//   worksheet.getColumn(1).width = longText.length + 5; // Adding extra space for padding

//   // Auto-fit other columns
//   worksheet.columns.forEach((column, index) => {
//     if (index !== 0) { // Skip the first column (index starts at 0)
//       let maxLength = 0;
//       column.eachCell({ includeEmpty: true }, (cell) => {
//         const columnLength = cell.value ? cell.value.toString().length : 10;
//         maxLength = Math.max(maxLength, columnLength);
//       });
//       column.width = maxLength < 10 ? 10 : maxLength + 2; // Adding extra space for padding
//     }
//   });

//   worksheet.eachRow({ includeEmpty: false }, (row) => {
//     row.eachCell({ includeEmpty: false }, (cell) => {
//       cell.border = {
//         top: { style: 'thin' },
//         left: { style: 'thin' },
//         bottom: { style: 'thin' },
//         right: { style: 'thin' }
//       };
//     });
//   });

//   // Generate Excel file and trigger download
//   workbook.xlsx.writeBuffer().then((buffer) => {
//     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = `Tax_P&L_${this.fromshow}.xlsx`;
//     link.click();
//   }).catch(error => console.error('Error generating Excel file:', error));
// }

// this code i need seprate seprate sheet for 


if (data === "Download Excel") {
  if (!this.imageBase64) {
    alert('Excel Download error');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('TAX P&L');
  const equitySheet = workbook.addWorksheet('Equity');
  const devisheet = workbook.addWorksheet('Derivatives');
  const commsheet = workbook.addWorksheet('Commodity');
  const currsheet = workbook.addWorksheet('Currency');




  // Combine tables and add rows
  const combinedTables = this.tables.reduce((acc, current) => {
    let existingTable = acc.find(table => table.heading === current.heading);
    if (existingTable) {
      existingTable.data = existingTable.data.concat(current.data);
    } else {
      acc.push({
        heading: current.heading,
        data: current.data
      });
    }
    return acc;
  }, []);

  let rows = [];
  let headingRowNumbers = {}; // To store row numbers of specific headings

  combinedTables.forEach(table => {
    const heading = table.heading;
    const tableData = table.data;

    // Add table heading
    rows.push([heading]);
    headingRowNumbers[heading] = rows.length; // Store row number for merging

    // Add header row (if it exists)
    if (tableData.length > 0) {
      rows.push(tableData[0]);

      // Add data rows (excluding the header row)
      for (let i = 1; i < tableData.length; i++) {
        rows.push(tableData[i]);
      }
    }
    rows.push([]); // Add a blank row between tables
  });

  // Fill the main sheet
  const headerRows = [
    ["", "Tax P&L", "Client ID", this.actid],
    ["", "", "Client Name", this.cname],
    ["", "", "Client PAN", this.profiledetails['PAN_NO']],
    ["", "", "Client Email-Id", this.profiledetails['CLIENT_ID_MAIL']],
    [],
    ['Equities', 'Derivatives', 'Commodity', 'Currency'],
    [`Long Term Realized P&L : ${(this.longnetval).toFixed(2)}`, `Futures P&L : ${(this.futopened).toFixed(2)}`, `Futures P&L : ${(this.comnetfutamt).toFixed(2)}`, `Futures P&L : ${(this.curnetfutamt).toFixed(2)}`],
    [`Short Term Realized P&L : ${(this.stnetamt).toFixed(2)}`, `Futures Turnover : ${(this.futurnover).toFixed(2)}`, `Futures Turnover : ${(this.futturnover).toFixed(2)}`, `Futures Turnover  : ${(this.curfutturnover).toFixed(2)}`],
    [`Trading P&L : ${(this.tradenetamt).toFixed(2)}`, `Options P&L : ${(this.optionopn).toFixed(2)}`, `Options P&L : ${(this.comnetamt).toFixed(2)}`, `Options P&L : ${(this.curopnetamt).toFixed(2)}`],
    [`Assets P&L : ${(this.assnetamt).toFixed(2)}`, `Options Turnover : ${(this.opturnover).toFixed(2)}`, `Options Turnover : ${(this.comturnover).toFixed(2)}`, `Options Turnover : ${(this.comopturnover).toFixed(2)}`],
    [`Trading Turnover : ${(this.totalintraday).toFixed(2)}`, `Total Charges ${(this.derchargers).toFixed(2)}`, `Total Charges : ${(this.comchargers).toFixed(2)}`, `Total Charges : ${(this.curchargers).toFixed(2)}`],
    [`Total Charges : ${(this.segval).toFixed(2)}`],
    []
  ];

  let equityRows = [];
  let derivativeRows = [];
  let commodityRows = [];
  let currencyRows = [];

  if (this.eqarr && Array.isArray(this.eqarr)) {
    equityRows.push(['Equities Charges']);
    equityRows.push(["Symbol", "Net Amount"]);
    this.eqarr.forEach(element => {
      equityRows.push([element.SCRIP_SYMBOL, element.NOT_PROFIT]);
    });
    equityRows.push([]);
  }

  if (this.chararr && this.chararr.der_charges && Array.isArray(this.chararr.der_charges)) {
    derivativeRows.push(['Derivative Charges']);
    derivativeRows.push(["Symbol", "Net Amount"]);
    this.chararr.der_charges.forEach(element => {
      derivativeRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
    });
    derivativeRows.push([]);
  }

  if (this.chararr && this.chararr.comm_charges && Array.isArray(this.chararr.comm_charges)) {
    commodityRows.push(['Commodity Charges']);
    commodityRows.push(["Symbol", "Net Amount"]);
    this.chararr.comm_charges.forEach(element => {
      commodityRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
    });
    commodityRows.push([]);
  }

  if (this.chararr && this.chararr.cur_charges && Array.isArray(this.chararr.cur_charges)) {
    currencyRows.push(['Currency Charges']);
    currencyRows.push(["Symbol", "Net Amount"]);
    this.chararr.cur_charges.forEach(element => {
      currencyRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
    });
    currencyRows.push([]);
  }

  let allRows = headerRows.concat(rows, [], equityRows, [], derivativeRows, [], commodityRows, [], currencyRows);

  const imageId = workbook.addImage({
    base64: this.imageBase64,
    extension: 'svg'
  });

  worksheet.getRow(1).height = 32.53; // Set row height to fit the image
  worksheet.getColumn(1).width = 25; // Adjust column width to fit the image

  worksheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });

  // Apply styles to the main sheet
  allRows.forEach((row) => {
    const excelRow = worksheet.addRow(row);
    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);
      if (cell === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }
      if (['Equities Charges', 'Derivative Charges', 'Commodity Charges', 'Currency Charges', 'Commodity Option Open', 'Commodity Option Closed', 'Equities', 'Derivatives', 'Commodity', 'Currency', 'Derivative Option Open', 'Derivative Option Closed', 'Assets', 'Liabilities', 'Trading', 'ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Commodity Future Closed'].includes(cell)) {
        cellRef.font = {
          name: 'Arial',
          size: 11,
          bold: true
        };
      } else if (['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L', 'Net Amount'].includes(cell)) {
         cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
         cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      cellRef.alignment = { horizontal: 'left' };
      if (cell === "Tax P&L") {
        cellRef.font = {
          name: 'Arial',
          size: 14,
          bold: true
        };
      }
    });

    // Merge cells vertically for specific rows
    if (row[0] === "ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED") {
      const rowNum = rows.indexOf(row) + 1; // Calculate the actual row number in the worksheet
      worksheet.mergeCells(`A${rowNum}:A${rowNum + 1}`);
    }
  });

  // Adjust column width
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  // Fill and style the Equity sheet
  equitySheet.addRow(["", "Tax P&L", "Client ID", this.actid]);
  equitySheet.addRow(["", "", "Client Name", this.cname]);
  equitySheet.addRow(["", "", "Client PAN", this.profiledetails['PAN_NO']]);
  equitySheet.addRow(["", "", "Client Email-Id", this.profiledetails['CLIENT_ID_MAIL']]);
  equitySheet.addRow([]);
  equitySheet.addRow(['Equities']);
  equitySheet.addRow([`Long Term Realized P&L : ${(this.longnetval).toFixed(2)}`]);
  equitySheet.addRow([`Short Term Realized P&L : ${(this.stnetamt).toFixed(2)}`]);
  equitySheet.addRow([`Trading P&L : ${(this.tradenetamt).toFixed(2)}`]);
  equitySheet.addRow([`Assets P&L : ${(this.assnetamt).toFixed(2)}`]);
  equitySheet.addRow([`Trading Turnover : ${(this.totalintraday).toFixed(2)}`]);
  equitySheet.addRow([`Total Charges : ${(this.segval).toFixed(2)}`]);

  equitySheet.addRow([]);

  if (this.eqarr && Array.isArray(this.eqarr)) {
    equitySheet.addRow(['Equities Charges']);
    equitySheet.addRow(["Symbol", "Net Amount"]);
    this.eqarr.forEach(element => {
      equitySheet.addRow([element.SCRIP_SYMBOL, element.NOT_PROFIT]);
    });
    equitySheet.addRow([]);
  }

  // Add 'Assets' data to the Equity sheet
  combinedTables.forEach(table => {
    if (table.heading === 'Assets' || table.heading === 'Liabilities' || table.heading === 'Trading') {
      equitySheet.addRow([table.heading]);
      table.data.forEach((row) => {
        equitySheet.addRow(row);
      });
      equitySheet.addRow([]);
    }
  });

  equitySheet.getRow(1).height = 32.53; // Set row height to fit the image
  equitySheet.getColumn(1).width = 35; // Adjust column width to fit the image

  equitySheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });

  // Apply styles to the Equity sheet
  equitySheet.eachRow((row) => {
    row.eachCell((cell) => {
      const cellRef = cell;

      if (cell.value === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }
      if (['Equities Charges', 'Derivative Charges', 'Commodity Charges', 'Currency Charges', 'Commodity Option Open', 'Commodity Option Closed', 'Equities', 'Derivatives', 'Commodity', 'Currency', 'Derivative Option Open', 'Derivative Option Closed', 'Assets', 'Liabilities', 'Trading', 'ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Commodity Future Closed'].includes(cell.value)) {
        cellRef.font = {
          name: 'Arial',
          size: 11,
          bold: true
        };
      } else if (['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L', 'Net Amount'].includes(cell.value)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      cellRef.alignment = { horizontal: 'left' };
      if (cell.value === "Tax P&L") {
        cellRef.font = {
          name: 'Arial',
          size: 14,
          bold: true
        };
      }
    });
  });

  // Adjust column width
  equitySheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  equitySheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  devisheet.addRow(["", "Tax P&L", "Client ID", this.actid]);
  devisheet.addRow(["", "", "Client Name", this.cname]);
  devisheet.addRow(["", "", "Client PAN", this.profiledetails['PAN_NO']]);
  devisheet.addRow(["", "", "Client Email-Id", this.profiledetails['CLIENT_ID_MAIL']]);
  devisheet.addRow([]);
  devisheet.addRow(['Derivatives']);
  devisheet.addRow([`Futures P&L : ${(this.futopened).toFixed(2)}`]);
  devisheet.addRow([`Futures Turnover : ${(this.futurnover).toFixed(2)}`]);
  devisheet.addRow([`Options P&L : ${(this.optionopn).toFixed(2)}`]);
  devisheet.addRow([`Options Turnover : ${(this.opturnover).toFixed(2)}`]);
  devisheet.addRow([`Total Charges : ${(this.derchargers).toFixed(2)}`]);


  devisheet.addRow([]);

  if (this.chararr && this.chararr.der_charges && Array.isArray(this.chararr.der_charges)) {
    derivativeRows.push(['Derivative Charges']);
    derivativeRows.push(["Symbol", "Net Amount"]);
    this.chararr.der_charges.forEach(element => {
      derivativeRows.push([element.SCRIP_SYMBOL, element.NETAMT]);
    });
  }
  derivativeRows.push([]);

  // Add 'Assets' data to the Equity sheet
  combinedTables.forEach(table => {
    if (table.heading === 'Derivative Option Open' || table.heading === 'Derivative Option Closed') {
      devisheet.addRow([table.heading]);
      table.data.forEach((row) => {
        devisheet.addRow(row);
      });
      devisheet.addRow([]);
    }
  });

  devisheet.getRow(1).height = 32.53; // Set row height to fit the image
  devisheet.getColumn(1).width = 35; // Adjust column width to fit the image

  devisheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });

  // Apply styles to the Equity sheet
  devisheet.eachRow((row) => {
    row.eachCell((cell) => {
      const cellRef = cell;

      if (cell.value === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }
      if (['Equities Charges', 'Derivative Charges', 'Commodity Charges', 'Currency Charges', 'Commodity Option Open', 'Commodity Option Closed', 'Equities', 'Derivatives', 'Commodity', 'Currency', 'Derivative Option Open', 'Derivative Option Closed', 'Assets', 'Liabilities', 'Trading', 'ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Commodity Future Closed'].includes(cell.value)) {
        cellRef.font = {
          name: 'Arial',
          size: 11,
          bold: true
        };
      } else if (['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L', 'Net Amount'].includes(cell.value)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      cellRef.alignment = { horizontal: 'left' };
      if (cell.value === "Tax P&L") {
        cellRef.font = {
          name: 'Arial',
          size: 14,
          bold: true
        };
      }
    });
  });

  // Adjust column width
  devisheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  devisheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  commsheet.addRow(["", "Tax P&L", "Client ID", this.actid]);
  commsheet.addRow(["", "", "Client Name", this.cname]);
  commsheet.addRow(["", "", "Client PAN", this.profiledetails['PAN_NO']]);
  commsheet.addRow(["", "", "Client Email-Id", this.profiledetails['CLIENT_ID_MAIL']]);
  commsheet.addRow([]);
  commsheet.addRow(['Commodity']);
  commsheet.addRow([`Futures P&L : ${(this.comnetfutamt).toFixed(2)}`]);
  commsheet.addRow([`Futures Turnover : ${(this.futturnover).toFixed(2)}`]);
  commsheet.addRow([`Options P&L : ${(this.comnetamt).toFixed(2)}`]);
  commsheet.addRow([`Options Turnover : ${(this.comturnover).toFixed(2)}`]);
  commsheet.addRow([`Total Charges : ${(this.comchargers).toFixed(2)}`]);


  commsheet.addRow([]);


  if (this.chararr && this.chararr.comm_charges && Array.isArray(this.chararr.comm_charges)) {
    commsheet.addRow(['Commodity Charges']);
    commsheet.addRow(["Symbol", "Net Amount"]);
    this.chararr.comm_charges.forEach(element => {
      commsheet.addRow([element.SCRIP_SYMBOL, element.NETAMT]);
    });
    commsheet.addRow([]);
  }

  // Add 'Assets' data to the Equity sheet
  combinedTables.forEach(table => {
    if (table.heading === 'Commodity Option Open' || table.heading === 'Commodity Option Closed' || table.heading === 'Commodity Future  Closed') {
      commsheet.addRow([table.heading]);
      table.data.forEach((row) => {
        commsheet.addRow(row);
      });
      commsheet.addRow([]);
    }
  });

  commsheet.getRow(1).height = 32.53; // Set row height to fit the image
  commsheet.getColumn(1).width = 35; // Adjust column width to fit the image

  commsheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });

  // Apply styles to the Equity sheet
  commsheet.eachRow((row) => {
    row.eachCell((cell) => {
      const cellRef = cell;

      if (cell.value === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }
      if (['Equities Charges', 'Derivative Charges', 'Commodity Charges', 'Currency Charges', 'Commodity Option Open', 'Commodity Option Closed', 'Equities', 'Derivatives', 'Commodity', 'Currency', 'Derivative Option Open', 'Derivative Option Closed', 'Assets', 'Liabilities', 'Trading', 'ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Commodity Future Closed'].includes(cell.value)) {
        cellRef.font = {
          name: 'Arial',
          size: 11,
          bold: true
        };
      } else if (['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L', 'Net Amount'].includes(cell.value)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      cellRef.alignment = { horizontal: 'left' };
      if (cell.value === "Tax P&L") {
        cellRef.font = {
          name: 'Arial',
          size: 14,
          bold: true
        };
      }
    });
  });

  // Adjust column width
  commsheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  commsheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });


  currsheet.addRow(["", "Tax P&L", "Client ID", this.actid]);
  currsheet.addRow(["", "", "Client Name", this.cname]);
  currsheet.addRow(["", "", "Client PAN", this.profiledetails['PAN_NO']]);
  currsheet.addRow(["", "", "Client Email-Id", this.profiledetails['CLIENT_ID_MAIL']]);
  currsheet.addRow([]);
  currsheet.addRow(['Currency']);
  currsheet.addRow([`Futures P&L : ${(this.curnetfutamt).toFixed(2)}`]);
  currsheet.addRow([`Futures Turnover : ${(this.curfutturnover).toFixed(2)}`]);
  currsheet.addRow([`Options P&L : ${(this.curopnetamt).toFixed(2)}`]);
  currsheet.addRow([`Options Turnover : ${(this.comopturnover).toFixed(2)}`]);
  currsheet.addRow([`Total Charges : ${(this.curchargers).toFixed(2)}`]);


  currsheet.addRow([]);




  
  if (this.chararr && this.chararr.cur_charges && Array.isArray(this.chararr.cur_charges)) {
    currsheet.addRow(['Currency Charges']);
    currsheet.addRow(["Symbol", "Net Amount"]);
    this.chararr.cur_charges.forEach(element => {
      currsheet.addRow([element.SCRIP_SYMBOL, element.NETAMT]);
    });
    currsheet.addRow([]);
  }



  currsheet.getRow(1).height = 32.53; // Set row height to fit the image
  currsheet.getColumn(1).width = 35; // Adjust column width to fit the image

  currsheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });

  // Apply styles to the Equity sheet
  currsheet.eachRow((row) => {
    row.eachCell((cell) => {
      const cellRef = cell;

      if (cell.value === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }
      if (['Equities Charges', 'Derivative Charges', 'Commodity Charges', 'Currency Charges', 'Commodity Option Open', 'Commodity Option Closed', 'Equities', 'Derivatives', 'Commodity', 'Currency', 'Derivative Option Open', 'Derivative Option Closed', 'Assets', 'Liabilities', 'Trading', 'ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Commodity Future Closed'].includes(cell.value)) {
        cellRef.font = {
          name: 'Arial',
          size: 11,
          bold: true
        };
      } else if (['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L', 'Net Amount'].includes(cell.value)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      cellRef.alignment = { horizontal: 'left' };
      if (cell.value === "Tax P&L") {
        cellRef.font = {
          name: 'Arial',
          size: 14,
          bold: true
        };
      }
    });
  });

  // Adjust column width
  currsheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  currsheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Tax_P&L_${this.fromshow}.xlsx`;
    link.click();
  }).catch(error => console.error('Error generating Excel file:', error));
}


 else if (data == 'Download Pdf') {
        

        // console.log(rows);
        // Create a new jsPDF document
        var doc = new jsPDF();
        // Add header and customize text
        //doc.setFont('Tenon');
        // doc.setFontSize(12);
        // doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
        // doc.setFontSize(6);
        // doc.text("Correspondence Office:No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096.", 38, 13);
        // doc.setFontSize(6);
        // doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
        // doc.setFontSize(6);
        // doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
        // doc.setFontSize(6);
        // doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
        doc.setFontSize(7);
        doc.setFont('bold');
        doc.text(`${'Client Name   :  '}${this.cname}`, 15, 33);
        doc.setFontSize(7);
        doc.text(`${'Client Code    :  '}${this.actid}`, 15, 37);
        doc.setFontSize(7);
        doc.text(`${'PAN               :  '}${this.profiledetails['PAN_NO']}`, 15, 41);
        doc.setFontSize(7);
        doc.text(`${'Email Id         :  '}${this.profiledetails['CLIENT_ID_MAIL']}`, 15, 45);
        doc.setFontSize(7);
        doc.text(`${'From     :  '}${this.fromshow.split('-')[0]}`, 133, 33);
        doc.setFontSize(7);
        doc.text(`${'To         :  '}${this.fromshow.split('-')[1]}`, 133, 37);
        doc.setFontSize(7);
        doc.text(`${'Mobile  :  '}${this.profiledetails['MOBILE_NO']}`, 133, 41);
        doc.setFontSize(7);
        doc.text(`${'Address :  '}${this.profiledetails['CL_RESI_ADD1']}`, 133, 45);
        doc.setFontSize(7);
        doc.text(`${this.profiledetails['CL_RESI_ADD2']}`, 144, 48);
        doc.setFontSize(7);
        doc.text(`${this.profiledetails['CL_RESI_ADD3']}`, 144, 51);
        doc.setFontSize(14);
        doc.text('Tax Pnl', 90, 14.5);
        const dividerY = 26; // Adjust the Y-coordinate based on your layout
        doc.setLineWidth(0.2); // Set line width
        doc.setDrawColor(0); // Set line color to black
        doc.line(0, dividerY, 218, dividerY);
        // Add image from local file
        doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
        // Draw a rectangle with black border
        doc.setFontSize(11);
        doc.setFont('bold');
        doc.text('Equities', 15, 60);
        doc.setFontSize(8);
        doc.setFont('bold');
        doc.text('Long Term Realized P&L :', 15, 68);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.longnetval).toFixed(2), 50, 68);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Short Term Realized P&L :', 15, 73);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.stnetamt).toFixed(2), 50, 73);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Trading P&L :', 15, 78);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.tradenetamt).toFixed(2), 50, 78);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Assets P&L :', 15, 83);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.assnetamt).toFixed(2), 50, 83);


        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Trading Turnover :', 15, 88);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.totalintraday).toFixed(2), 50, 88);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Total Charges :', 15, 93);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.segval).toFixed(2), 50, 93);

        //der
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(11);
        doc.setFont('bold');
        doc.text('Derivatives', 72, 60);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Futures P&L :', 72, 68);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.futopened).toFixed(2), 96, 68);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Futures Turnover :', 72, 73);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.futurnover).toFixed(2), 96, 73);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Options P&L :', 72, 78);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.optionopn).toFixed(2), 96, 78);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Options Turnover :', 72, 83);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.opturnover).toFixed(2), 96, 83);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Total Charges :', 72, 88);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.derchargers).toFixed(2), 96, 88);

        // com
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(11);
        doc.setFont('bold');
        doc.text('Commodity', 115, 60);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Futures P&L :', 115, 68);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.comnetfutamt).toFixed(2), 140, 68);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Futures Turnover :', 115, 73);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.futturnover).toFixed(2), 140, 73);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Options P&L :', 115, 78);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.comnetamt).toFixed(2), 140, 78);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Options Turnover :', 115, 83);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.comturnover).toFixed(2), 140, 83);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Total Charges :', 115, 88);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        doc.text((this.comchargers).toFixed(2), 140, 88);

        // curr

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(11);
        doc.setFont('bold');
        doc.text('Currency', 158, 60);

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Futures P&L :', 158, 68);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        if(this.cornetfutamt){
          doc.text((this.cornetfutamt).toFixed(2), 182, 68);
        } 
         
        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Futures Turnover :', 158, 73);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        if(this.curfutturnover){
          doc.text((this.curfutturnover).toFixed(2), 182, 68);
        } 

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Options P&L :', 158, 78);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        if(this.curopnetamt){
          doc.text((this.curopnetamt).toFixed(2), 182, 68);
        } 


        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Options Turnover :', 158, 83);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        if(this.comopturnover){
          doc.text((this.comopturnover).toFixed(2), 182, 68);
        } 
         

        doc.setFontSize(8);
        doc.setFont('bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Total Charges :', 158, 88);
        doc.setFontSize(8);
        doc.setTextColor(117, 115, 115);
        doc.setFont('bold');
        if(this.curchargers){
          doc.text((this.curchargers).toFixed(2), 182, 68);
        } 
        

        doc.setFontSize(8);
       
        const columnStyles = {
          0: { fontStyle: 'bold', textColor: [0, 0, 0], halign: 'left' },
          1: { halign: 'left' },
          2: { halign: 'right' },
          3: { halign: 'right' },
          4: { halign: 'right' },
          5: { halign: 'right' },
          6: { halign: 'right' },
          7: { halign: 'right' },
        };
        // let rows = [];
       
        // let columnHeader = ['Type', 'Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L'];


        let pdfName = 'Tax pnl';
 
        // doc.setFontSize(10);
        // doc.setFont('bold');
        // doc.setTextColor(0, 0, 0);
        // doc.text('Assets', 88, 99);
        // this.downloadarray.forEach(element => {
        //   var temp = [
        //     element.TR_TYPE,
        //     element.SCRIP_NAMEDATA || element.SCRIP_SYMBOL,
        //     element.BUY_QTY || element.BUYQTY,
        //     element.BUY_RATE || element.BUYRATE,
        //     element.SALE_QTY || element.SALEQTY,
        //     element.SALE_RATE || element.SALERATE,
        //     element.NET_QTY || element.NETQTY,
        //     element.NET_RATE || element.NETRATE,
        //     element.Closing_Price || element.CL_PRICE,
        //     element.NOTIONAL_NET
        //   ];
        //   // if (element.TR_TYPE == 'ASSETS') {
        //     rows.push(temp);
        //   // }
          
        // });
        // const doc = new jsPDF();
        
      const tables = this.downloadarrayForPdf.slice().sort((a, b) => a.id - b.id);
      console.log(tables)
      let yOffset = 100; // Starting Y offset
tables.forEach((table, index) => {
  if (index == 0) {
    yOffset = yOffset + 10; // Add space between tables
  } else {
    yOffset = yOffset + 1; // Add space between tables
  }

  // Add heading
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.setFont('bold');
  doc.text(table.heading, 15, yOffset);
  yOffset += 10; // Add space after heading

  // Create a deep copy of the table data to avoid mutation
  let dataForTableData = JSON.parse(JSON.stringify(table.data)).splice(1);

  // Add table
  doc.autoTable({
    head: [table.data[0]], // Header row
    body: dataForTableData, // Data rows
    startY: yOffset, // Position table
    styles: {
      fontSize: 7,
      lineColor: [200, 200, 200],
    },
    theme: 'grid', // Use the 'grid' theme
    headStyles: {
      fillColor: [31, 52, 101], // Dark blue header background
      textColor: [255, 255, 255], // White text color for the header
    },
    columnStyles: {
      // Define any specific column styles here if needed
    },
  });

  yOffset = doc.lastAutoTable.finalY + 10; // Update Y offset after table
});



          
        // autoTable(doc, {
        //   head: [columnHeader], body: rows, startY: 100, styles: {

        //     fontSize: 7,
        //     // font: 'Tenon',
        //     lineColor: [200, 200, 200],
        //     // textColor: [0,0,0],
        //   }, theme: 'grid', // Use the 'grid' theme
        //   headStyles: {
        //     fillColor: [31, 52, 101], // RGB color for dark blue header background
        //     textColor: [255, 255, 255], // White text color for the header
        //   },
        //   columnStyles: columnStyles,
        //   table: {
        //     // fillColor: [31, 52, 101]
        //     // fillColor: tableBackgroundColor,
        //   },
        // });
        if (doc.internal.pages.length > 1) {
          doc.addPage();
          doc.autoTable.previous.finalY = 10

        } else {
          doc.autoTable.previous.finalY += 20; // Add some spacing between tables
        }
        // doc.text('End table', 10, doc.autoTable.previous.finalY + 10);
        // comm table
        let eqval = this.eqarr.length

        if (eqval > 0) {
          doc.setFontSize(12);
          doc.setFont('bold');
          doc.setTextColor(0, 0, 0);
          doc.text('Equities Charges', 15, 15);
          let rowsexpense = [];
          let headerexpense = ["Symbol", "Net Amount"];
          this.eqarr.forEach(element => {
            var temp = [
              element.SCRIP_SYMBOL, element.NOT_PROFIT
            ];
            rowsexpense.push(temp);
          });
          autoTable(doc, {
            head: [headerexpense], body: rowsexpense, startY: 21, styles: {

              fontSize: 7,
              // font: 'Tenon',
              lineColor: [200, 200, 200],
              // textColor: [0,0,0],
            }, theme: 'grid', // Use the 'grid' theme
            headStyles: {
              fillColor: [31, 52, 101], // RGB color for dark blue header background
              textColor: [255, 255, 255], // White text color for the header
            },
            columnStyles: columnStyles,
            table: {
              // fillColor: [31, 52, 101]
              // fillColor: tableBackgroundColor,
            },
          });
        }
        let derval = this.chararr.der_charges.length
        // / crgdfhtdfurr table
        if (derval > 0) {
          doc.setFontSize(12);
          doc.setFont('bold');
          doc.setTextColor(0, 0, 0);
          doc.text('Derivative charges', 15, 85);
          let rowsexpense2 = [];
          let headerexpense2 = ["Symbol", "Net Amount"];
          this.chararr.der_charges.forEach(element => {
            var temp = [
              element.SCRIP_SYMBOL, element.NETAMT
            ];
            rowsexpense2.push(temp);
          });
          autoTable(doc, {
            head: [headerexpense2], body: rowsexpense2, startY: 90, styles: {

              fontSize: 7,
              // font: 'Tenon',
              lineColor: [200, 200, 200],
              // textColor: [0,0,0],
            }, theme: 'grid', // Use the 'grid' theme
            headStyles: {
              fillColor: [31, 52, 101], // RGB color for dark blue header background
              textColor: [255, 255, 255], // White text color for the header
            },
            columnStyles: columnStyles,
            table: {
              // fillColor: [31, 52, 101]
              // fillColor: tableBackgroundColor,
            },
          });
        }

        let comval = this.chararr.comm_charges.length

        if (comval > 0) {
          doc.setFontSize(12);
          doc.setFont('bold');
          doc.setTextColor(0, 0, 0);
          doc.text('Commodity charges', 15, 150);
          let rowsexpense3 = [];
          let headerexpense3 = ["Symbol", "Net Amount"];
          this.chararr.comm_charges.forEach(element => {
            var temp = [
              element.SCRIP_SYMBOL, element.NETAMT
            ];
            rowsexpense3.push(temp);
          });
          autoTable(doc, {
            head: [headerexpense3], body: rowsexpense3, startY: 155, styles: {

              fontSize: 7,
              // font: 'Tenon',
              lineColor: [200, 200, 200],
              // textColor: [0,0,0],
            }, theme: 'grid', // Use the 'grid' theme
            headStyles: {
              fillColor: [31, 52, 101], // RGB color for dark blue header background
              textColor: [255, 255, 255], // White text color for the header
            },
            columnStyles: columnStyles,
            table: {
              // fillColor: [31, 52, 101]
              // fillColor: tableBackgroundColor,
            },
          });
        }
        let curval = this.chararr.comm_charges.length

        if (curval> 0) {
          doc.setFontSize(12);
          doc.setFont('bold');
          doc.setTextColor(0, 0, 0);
          doc.text('Currency charges', 15, 215);
          let rowsexpense3 = [];
          let headerexpense3 = ["Symbol", "Net Amount"];
          this.chararr.cur_charges.forEach(element => {
            var temp = [
              element.SCRIP_SYMBOL, element.NETAMT
            ];
            rowsexpense3.push(temp);
          });
          autoTable(doc, {
            head: [headerexpense3], body: rowsexpense3, startY: 220, styles: {

              fontSize: 7,
              // font: 'Tenon',
              lineColor: [200, 200, 200],
              // textColor: [0,0,0],
            }, theme: 'grid', // Use the 'grid' theme
            headStyles: {
              fillColor: [31, 52, 101], // RGB color for dark blue header background
              textColor: [255, 255, 255], // White text color for the header
            },
            columnStyles: columnStyles,
            table: {
              // fillColor: [31, 52, 101]
              // fillColor: tableBackgroundColor,
            },
          });
        }

        // Add custom text
        // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

        // Save the PDF
        var totalPages = doc.internal.getNumberOfPages();
        for (var i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          const dividerY = doc.internal.pageSize.height - 13; // Adjust the Y-coordinate based on your layout
          doc.setFontSize(6);
          doc.text(14, doc.internal.pageSize.height - 9, 'Zebu Share And Wealth Management Private Limited');
          doc.setFontSize(4);
          doc.text(14, doc.internal.pageSize.height - 7, 'Correspondence Office:No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096.');
          doc.setFontSize(4);
          doc.text(84, doc.internal.pageSize.height - 7, 'Phone No:044-4855 7991, Fax :044-4855 7991');
          doc.setFontSize(4);
          doc.text(14, doc.internal.pageSize.height - 5, 'TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704');
          doc.setFontSize(4);
          doc.text(109, doc.internal.pageSize.height - 5, 'Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com');
          doc.setLineWidth(0.2); // Set line width
          doc.setDrawColor(0); // Set line color to black
          doc.line(0, dividerY, 218, dividerY);
        }
        doc.save(pdfName + '.pdf');
      }


      //pdf


    },
    gettradedata() {
      this.menu2 = false
      this.eqloader = true
      this.dessertsexpand = []
      this.dessertsexpandderivative = []
      this.dessertsexpandcommodity = []
      this.dessertsexpandcurrency = []

      this.disabletabs = true
      this.disabletabsexpense = true
      this.dessertsoriginal = []

      if (this.fromdate != '' && this.todate != '') {
        this.loader = true
        var fromdate = ''
        var todate = ''
        let dateString = this.fromdate
        let dateObject = new Date(dateString);
        let year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
        let day = dateObject.getDate();
        let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
        fromdate = formattedDate
        this.fromshow = fromdate
        this.fromdateapi = fromdate
        let dateString1 = this.todate
        let dateObject1 = new Date(dateString1);
        let year1 = dateObject1.getFullYear();
        let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
        let day1 = dateObject1.getDate();
        let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
        todate = formattedDate1
        this.toshow = todate
        this.fromshow = this.fromshow + '/' + this.toshow
        this.equitiesdata()
        this.derivativesdat()
        // const axios = require("axios");
        // let data = JSON.stringify({
        //   "cc": this.actid,
        //   "from": fromdate,
        //   "to": todate
        // });

        // let config = {
        //   method: "post",
        //   maxBodyLength: Infinity,
        //   url: `${apiurl}/getpnl`,

        //   headers: {
        //     'Content-Type': 'application/json',
        //     'clientid': this.actid,
        //     'Authorization': this.susertoken
        //   },
        //   data: data,
        // };
        // let axiosthis = this;
        // axios.request(config)
        //   .then((response) => {
        //     let res = response.data
        //     if (res != 'no data found') {
        //       axiosthis.desserts = response.data["transactions"];
        //       // console.log(axiosthis.desserts,'kjkjkj');
        //       axiosthis.dessertspop = response.data["transactions"];
        //       axiosthis.dessertsexpense = response.data["expenses"];
        //       axiosthis.dessertspopexpense = response.data["expenses"];
        //       var headval = response.data

        //       axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
        //       axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
        //       axiosthis.headervalpop = response.data;
        //       // console.log(axiosthis.desserts, 'lklklklk');

        //       axiosthis.itemperpage = axiosthis.desserts.length
        //       let data1 = JSON.stringify({
        //         "cc": this.actid,

        //       });

        //       let config1 = {
        //         method: 'post',
        //         maxBodyLength: Infinity,
        //         url: `${apiurl}/getpnlsummary`,
        //         headers: {
        //           'Content-Type': 'application/json',
        //           'clientid': this.actid,
        //           'Authorization': this.susertoken
        //         },
        //         data: data1
        //       };
        //       // var axiosthis = this
        //       axios.request(config1)
        //         .then((response) => {
        //           axiosthis.headerval = headval
        //           axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
        //           axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
        //           //// // //console.log(JSON.stringify(response.data));
        //           // // // //console.log(response.data, typeof response.data,'');
        //           axiosthis.desserts2 = response.data
        //           for (const [key, value] of Object.entries(axiosthis.desserts2)) {
        //             // // // //console.log(key, value, "axiosthis..");
        //             for (let i = 0; i < value.length; i++) {
        //               axiosthis.desserts23.push(value[i])
        //               this.keyyy = key
        //             }
        //           }
        //           // console.log(axiosthis.desserts, 'lklklklk');

        //           for (let i = 0; i < axiosthis.desserts23.length; i++) {
        //             let smdata = axiosthis.desserts23[i].SCRIP_SYMBOL;
        //             for (let y = 0; y < axiosthis.desserts.length; y++) {
        //               let famItem = axiosthis.desserts[y]['SCRIP_SYMBOL'];
        //               if (famItem && smdata === famItem) {
        //                 // console.log('found');
        //                 if (!axiosthis.desserts[y].detailed) {
        //                   axiosthis.desserts[y].detailed = [];
        //                 }
        //                 axiosthis.desserts[y].detailed.push(axiosthis.desserts23[i]);
        //               }
        //             }
        //           }
        //           axiosthis.dessertsoriginal = axiosthis.desserts
        //           if (axiosthis.dessertsoriginal != []) {
        //             axiosthis.loader = false;
        //           }

        //           // console.log(axiosthis.desserts, 'lklklklk');
        //           axiosthis.itemperpage1 = axiosthis.desserts23.length
        //           axiosthis.detpop = axiosthis.desserts23

        //         })
        //         .catch((error) => {
        //           if (error.message == 'Network Error') {
        //             this.msg = error.message
        //             axiosthis.loader = false
        //             axiosthis.alert = true
        //             setTimeout(() => {
        //               axiosthis.alert = false
        //             }, 5000);
        //           }
        //         });
        //     } else {
        //       // 
        //     }



        //     // //// // //console.log("axiosthis.desserts", axiosthis.desserts);
        //   })
        //   .catch((error) => {
        //     // // //console.log(error);
        //     if (error.message == 'Network Error') {
        //       this.msg = error.message
        //       axiosthis.loader = false
        //       axiosthis.alert = true
        //       setTimeout(() => {
        //         axiosthis.alert = false
        //       }, 5000);
        //       // alert(error.message)
        //     }
        //   });
        // // const axios = require('axios');

        // // this.equitiesdata()
        // // this.derivativesdat()

      }
    },


    // dateinsert() {
    //   //console.log('kjj')
    //   var fromdate = ''
    //   // var todate = ''
    //   let dateString = this.fromdate
    //   let dateObject = new Date(dateString);
    //   let year = dateObject.getFullYear();
    //   let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
    //   let day = dateObject.getDate();
    //   let formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
    //   fromdate = formattedDate
    //   this.fromshow = fromdate
    //   this.fromdateapi = fromdate

    // },
    tabdata(item) {
      this.tabvaluestack = item;
    },
    toggleExpanded(item) {
      // console.log("fgfg", item)
      const index = this.expanded.indexOf(item);
      if (index === -1) {
        this.expanded = this.singleExpand ? [item] : [...this.expanded, item];
      } else {
        this.expanded = this.expanded.filter((el) => el !== item);
      }
    },
    toggleExpanded1(item) {
      const index = this.expandedder.indexOf(item);
      if (index === -1) {
        this.expandedder = this.singleExpand ? [item] : [...this.expandedder, item];
      } else {
        this.expandedder = this.expandedder.filter((el) => el !== item);
      }
    },
    toggleExpanded2(item) {
      const index = this.expandedcom.indexOf(item);
      if (index === -1) {
        this.expandedcom = this.singleExpand ? [item] : [...this.expandedcom, item];
      } else {
        this.expandedcom = this.expandedcom.filter((el) => el !== item);
      }
    },
    toggleExpanded3(item) {
      const index = this.expandedcur.indexOf(item);
      if (index === -1) {
        this.expandedcur = this.singleExpand ? [item] : [...this.expandedcur, item];
      } else {
        this.expandedcur = this.expandedcur.filter((el) => el !== item);
      }
    },
    toggleExpanded4(item) {
      const index = this.expandedinner.indexOf(item);
      if (index === -1) {
        this.expandedinner = this.singleExpand ? [item] : [...this.expandedinner, item];
      } else {
        this.expandedinner = this.expandedinner.filter((el) => el !== item);
      }
    },
    detailedpop(item) {
      // console.log(item);
      // alert("dfd")
      this.search = ''
      let detailedarr = item
      this.detailedpoparray = detailedarr.detailed

      this.finaltotal = 0
      for (let i = 0; i < this.detailedpoparray.length; i++) {
        this.finaltotal += this.detailedpoparray[i].NET_AMOUNT

      }
      // console.log(this.detailedpoparray[0]);
      this.dialog = true
      // console.log(item);
      // this.scriptname = item.SCRIP_SYMBOL

      // this.itemperpage1inner = this.detailedpoparray.length

    },
    detailedpopmobile(item) {
      this.search = ''
      let detailedarr = item
      this.detailedpoparray = detailedarr.detailed

      this.finaltotal = 0
      for (let i = 0; i < this.detailedpoparray.length; i++) {
        this.finaltotal += this.detailedpoparray[i].NETAMT

      }
      // console.log(this.detailedpoparray[0]);
      this.dialogmobile = true
      // console.log(item);
      this.scriptname = item.SCRIP_SYMBOL

      this.itemperpage1inner = this.detailedpoparray.length
    },
    filterseg1(filterdata_unpl1) {
      var data = this.detpop
      // const dataval = this.desserts
      // const data2 = this.desserts
      // const data3 = this.desserts
      const seg = filterdata_unpl1
      // // // //console.log("sssss", seg);
      if (seg == 'Equities') {
        this.desserts23 = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'NSE_CASH' || data[i].COMPANY_CODE == 'BSE_CASH') {
            this.desserts23.push(data[i])
          }
        }


        //console.log(this.disunrealised_pnl, 'this.headerval.total_invested');
      } else if (seg == 'Future & Options') {
        this.desserts23 = []
        // const data1 = this.desserts
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'NSE_FNO' || data[i].COMPANY_CODE == 'BSE_FNO') {
            this.desserts23.push(data[i])
          }
        }
      } else if (seg == 'Commodities') {
        this.desserts23 = []
        // const data1 = this.desserts
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'MCX') {
            this.desserts23.push(data[i])
          }
        }
      } else if (seg == 'Currencies') {
        this.desserts23 = []
        // const data1 = this.desserts
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPANY_CODE == 'CD_NSE') {
            this.desserts23.push(data[i])
          }
        }
      } else if (seg == 'All') {
        this.desserts23 = data
      }
    },
    filterseg(filterdata_unpl) {
      var data = this.dessertspop
      // var data1 = this.dessertspopexpense
      var headrea = this.headervalpop.realised_pnl
      var headrea1 = this.headervalpop.unrealised_pnl
      // expense_amt
      // unrealised_pnl
      // realised_pnl
      // //console.log(data1,headrea);
      // const dataval = this.desserts
      // const data2 = this.desserts
      // const data3 = this.desserts
      const seg = filterdata_unpl
      // alert("summary");
      if (seg == 'Equities') {
        let addval = 0
        let unaddval = 0

        this.dessertsoriginal = []
        this.dessertstaxdis = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPCODE == 'BSE_CASH' || data[i].COMPCODE == 'NSE_CASH' || data[i].COMPCODE == 'MF_BSE' || data[i].COMPCODE == 'MF_NSE' || data[i].COMPCODE == 'NSE_SLBM' || data[i].COMPCODE == 'NSE_SPT') {
            this.dessertsoriginal.push(data[i])

          }

        }

        // for (let e = 0; e < data1.length; e++) {
        //     if (data1[e].COMPCODE == 'BSE_CASH' || data1[e].COMPCODE == 'NSE_CASH' || data1[e].COMPCODE == 'MF_BSE' || data1[e].COMPCODE == 'MF_NSE' || data1[e].COMPCODE == 'NSE_SLBM' || data1[e].COMPCODE == 'NSE_SPT') {
        //         this.desserts.push(data1[e])
        //     }
        // }

        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

            addval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disrealised_pnl = addval
        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

            unaddval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disunrealised_pnl = unaddval



        // //console.log(this.headerval.realised_pnl, "sisisisi");






        // }
        // for (let i = 0; i < this.desserts23.length; i++) {
        //     if (this.desserts23[i].NET_QUANTITY != 0) {
        //         this.disunrealised_pnl = this.desserts23[i].reduce((accumulator, object) => {
        //             return accumulator + object.BUY_RATE;
        //         }, 0);
        //     }
        // }
        //console.log(this.disunrealised_pnl, this.disrealised_pnl);
        // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == 'Future & Options') {
        this.dessertsoriginal = []
        let addval = 0
        let unaddval = 0

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPCODE == 'NSE_FNO' || data[i].COMPCODE == 'BSE_FNO   ') {
            this.dessertsoriginal.push(data[i])

          }
        }
        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

            addval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disrealised_pnl = addval
        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

            unaddval += this.dessertsoriginal[y].NOT_PROFIT
          }
          // else{
          //     // alert("kl")
          // }

        }
        this.disunrealised_pnl = unaddval
        // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == 'Commodities') {
        this.dessertsoriginal = []
        let addval = 0
        let unaddval = 0

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPCODE == 'MCX' || data[i].COMPCODE == 'NCDEX' || data[i].COMPCODE == 'NSE_COM' || data[i].COMPCODE == 'BSE_COM') {
            this.dessertsoriginal.push(data[i])
          }
        }
        //console.log(this.dessertsoriginal);
        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

            addval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disrealised_pnl = addval
        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

            unaddval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disunrealised_pnl = unaddval
        // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == 'Currencies') {
        this.dessertsoriginal = []
        let addval = 0
        let unaddval = 0

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (data[i].COMPCODE == 'CD_NSE' || data[i].COMPCODE == 'CD_MCX' || data[i].COMPCODE == 'CD_USE' || data[i].COMPCODE == 'CD_BSE') {
            this.dessertsoriginal.push(data[i])
          }
        }

        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

            addval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disrealised_pnl = addval
        for (let y = 0; y < this.dessertsoriginal.length; y++) {
          if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

            unaddval += this.dessertsoriginal[y].NOT_PROFIT
          }

        }
        this.disunrealised_pnl = unaddval
        // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == 'All') {
        this.dessertsoriginal = data

        this.disrealised_pnl = headrea
        this.disunrealised_pnl = headrea1
      }
    },
    downloaddet(item) {

      this.sheet1 = false
      this.radios1 = null
      let data = item
      // // // //console.log("dsdsdsdsds", data);

      if (data == 'Download Excel') {
        // const data = this.desserts23;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        let dada = this.desserts23;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        //                 dada.forEach(function (v) {
        //                     // delete v.SCRIP_SYMBOL

        //                     delete v.MARKET
        // ;
        //                     // delete v.NSE_SCRIPCODE;
        //                     delete v.EXCHANGE
        // ;
        //                     delete v.COMPANY_CODE
        //                     delete v.CLOSING_PRICE
        //                 });
        const exportType = exportFromJSON.types.csv;
        const filename = `${'Detailed_'}${'Profit and Loss_'}${this.year}`
        if (dada) exportFromJSON({ data: dada, filename: filename, exportType: exportType });
      } else if (data == 'Download Pdf') {
        let rows = [];
        let columnHeader = ['Trade date', 'Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'P&L'];
        let pdfName = `${'Detailed_Profit and loss_'}${this.yearis}`
        // doc.text(`Ledger_${this.yearis}`, 10, 10);
        // doc.text('Zebu', 10 ,10)
        this.desserts23.forEach(element => {
          var temp = [
            element.TRADE_DATE, element.SCRIP_NAME, element.BQTY, element.BRATE, element.SQTY, element.SRATE, element.NETQTY, element.NRATE, element.PRO
          ];
          rows.push(temp);
        });
        var doc = new jsPDF();
        autoTable(doc, { head: [columnHeader], body: rows, });
        doc.save(pdfName + '.pdf');
      }

    },
    downloadsum(item) {

      this.sheet = false
      this.radios = null

      let data = item
      // // //console.log("dsdsdsdsds", data);

      if (data == 'Download Excel') {
        let dada = this.dessertsoriginal;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        // vgb
        const exportType = exportFromJSON.types.csv;
        const filename = `${'Summary_'}${'Profit and Loss_'}${this.year}`
        // // //console.log("dada",this.year,dada)
        if (dada) {
          // // //console.log("inside",dada);
          exportFromJSON({ data: dada, fileName: filename, exportType: exportType });
        }
      } else if (data == 'Download Pdf') {
        let rows = [];
        let columnHeader = ['Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'P & L'];
        let pdfName = `${'Summary_Profit and loss_'}${this.yearis}`
        // doc.text(`Ledger_${this.yearis}`, 10, 10);
        // doc.text('Zebu', 10 ,10)
        this.dessertsoriginal.forEach(element => {
          var temp = [
            element.SCRIP_SYMBOL, element.BUY_QUANTITY, element.BUY_RATE, element.SALE_QUANTITY, element.SALE_RATE, element.NET_QUANTITY, element.NET_RATE, element.NOT_PROFIT
          ];
          rows.push(temp);
        });
        var doc = new jsPDF();
        autoTable(doc, { head: [columnHeader], body: rows, });
        doc.save(pdfName + '.pdf');
      }
    },
    detailed() {
      this.summary = false
    },
    summary1() {
      this.summary = true
    },
    lessbtn() {
      this.more = true
      this.less = false
      this.itemperpage = 8
    },
    morebtn() {
      this.more = false
      this.less = true
      this.itemperpage = this.dessertsoriginal.length
    },
    lessbtn1() {
      this.more1 = true
      this.less1 = false
      this.itemperpage1 = 8
    },
    morebtn1() {
      this.more1 = false
      this.less1 = true
      this.itemperpage1 = this.desserts23.length
    },
    equitiesdata() {
      this.eqloader = true
      this.loader = true
      this.tables = []
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.actid,
        'from': this.fromdateapi,
        'to': this.toshow
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.150:5050/getdetailedpnl',
        url: `${apiurl}/getequitypnl`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          axiosthis.eqloader = false
          var headerForPdf = [ 'Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L']

          axiosthis.loader = false
          if (response.data.msg == 'No Data Available') {
            axiosthis.eqloader = false
            axiosthis.loader = false
            axiosthis.dessertsexpand = []
          }else{
            const addass = response.data.ASSETS
          const addlab = response.data.LIABILITIES
          const addst = response.data.SHORTTERM
          const addtrd = response.data.TRADING
          const addtrdlong = response.data.LONGTERM
          const categories = ['ASSETS', 'LIABILITIES', 'SHORTTERM', 'TRADING', 'LONGTERM'];

          // axiosthis.downloadarray = [];

          categories.forEach(category => {
            const data = response.data[category] || [];
            axiosthis.downloadarray.push(...data);
          });
          // console.log(axiosthis.downloadarray, "DADA");
          if (addass) {
           // Add 'Assets' heading and data array to tables
              axiosthis.tables.push({ heading: 'Assets', data: [] });

              // Prepare the data for 'Assets'
              const assetsData = addass.map(item => [
               
                item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
                item.BUY_QTY || item.BUYQTY,
                item.BUY_RATE || item.BUYRATE,
                item.SALE_QTY || item.SALEQTY,
                item.SALE_RATE || item.SALERATE,
                item.NET_QTY || item.NETQTY,
                item.NET_RATE || item.NETRATE,
                item.Closing_Price || item.CL_PRICE,
                item.NOTIONAL_NET
              ]);

              // Add header and assets data to the 'Assets' table
              const assetsTable = axiosthis.tables.find(table => table.heading === 'Assets');
              if (assetsTable) {
              // Remove any existing instances of headerForPdf
                

              // Add the header and assets data
              assetsTable.data.unshift(headerForPdf);
              assetsTable.data.push(...assetsData);
            }

            // console.log(axiosthis.tables);
            axiosthis.dessertsexpand.push({  name: 'Assets', val: addass })
            // axiosthis.tables.push({ name: 'Assets', val: addass })
            const assnetAmtSum = addass.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
            axiosthis.assnetamt = assnetAmtSum
            // console.log("Sum of NETAMT values:", assnetAmtSum);
            const asssell = response.data.ASSETS
            var asssellval = asssell.reduce((sum, item) => sum + Number(item.SALE_AMT), 0);
            var assbuyval = asssell.reduce((sum, item) => sum + Number(item.BUY_AMT), 0);
          } else {
            asssellval = 0
            assbuyval = 0
          }
          if (addlab) {
          // Add 'Assets' heading and data array to tables
          axiosthis.tables.push({ heading: 'Liabilities', data: [] });

// Prepare the data for 'Assets'
          const assetsData = addlab.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Liabilities');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }

            axiosthis.dessertsexpand.push({ name: 'Liabilities', val: addlab })
            const labsell = response.data.LIABILITIES
            var labsellval = labsell.reduce((sum, item) => sum + Number(item.SALE_AMT), 0);
            var sellbuyval = labsell.reduce((sum, item) => sum + Number(item.BUY_AMT), 0);
          } else {
            labsellval = 0
            sellbuyval = 0
          }
          if (addst) {
            axiosthis.tables.push({ heading: 'Short Term', data: [] });

// Prepare the data for 'Assets'
          const assetsData = addst.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Short Term');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
            axiosthis.dessertsexpand.push({ name: 'Short Term', val: addst })

            const stnetAmtSum = addst.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
            // console.log("Sum of stnetAmtSum values:", stnetAmtSum);
            axiosthis.stnetamt = stnetAmtSum
            const stsell = response.data.SHORTTERM
            var stsellval = stsell.reduce((sum, item) => sum + Number(item.SALE_AMT), 0);
            var stbuyval = stsell.reduce((sum, item) => sum + Number(item.BUY_AMT), 0);
          } else {
            stsellval = 0
            stbuyval = 0
          }
          if (addtrd) {
            axiosthis.tables.push({ heading: 'Trading', data: [] });

// Prepare the data for 'Assets'
          const assetsData = addtrd.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Trading');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
            axiosthis.dessertsexpand.push({ name: 'Trading', val: addtrd })
            const tradenetAmtSum = addtrd.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
            // console.log("Sum of tradenetAmtSum values:", tradenetAmtSum);
            axiosthis.tradenetamt = tradenetAmtSum
            const tradesell = response.data.TRADING
            var tradesellval = tradesell.reduce((sum, item) => sum + Number(item.SALE_AMT), 0);
            var tradebuyval = tradesell.reduce((sum, item) => sum + Number(item.BUY_AMT), 0);
          } else {
            tradesellval = 0
            tradebuyval = 0
          }

          if (addtrdlong) {
            axiosthis.tables.push({ heading: 'Long Term', data: [] });

// Prepare the data for 'Assets'
          const assetsData = addtrdlong.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Long Term');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
            axiosthis.dessertsexpand.push({ name: 'Long Term', val: addtrdlong })
            const assnetAmtSum = addtrdlong.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
            axiosthis.longnetval = assnetAmtSum
            // console.log("Sum of NETAMT values:", assnetAmtSum);
            // const asssell = response.data.ASSETS
            // var asssellval = asssell.reduce((sum, item) => sum + Number(item.SALE_AMT), 0);
            // var assbuyval = asssell.reduce((sum, item) => sum + Number(item.BUY_AMT), 0);
          } else {
            // asssellval = 0
            // assbuyval = 0
          }
          // console.log(addass, "DADA");
          const totalval = asssellval + assbuyval + labsellval + sellbuyval + stsellval + stbuyval + tradesellval + tradebuyval
          this.totalintraday = totalval
          }

          
          // console.log(totalval, asssellval, assbuyval, sellbuyval, sellbuyval, "totalval");

          // console.log(response.data.Equity.assets);
          // axiosthis.assnetamt = addass.reduce((accumulator, object) => {
          //   return accumulator + object.Notional_PandL;
          // }, 0);
          // console.log("Sum of asset add values:", axiosthis.assnetamt);














          // const add = response.data.Equity.trading
          // const tradenetAmtSum = add.reduce((sum, item) => sum + item.NETAMT, 0);
          // console.log("Sum of tradenetAmtSum values:", tradenetAmtSum);
          // length code
          // axiosthis.dessertsexpand = []
          // const eqassetsdata = response.data.Equity.assets
          // if (eqassetsdata.length > 0) {
          //   axiosthis.dessertsexpand.push({ "name": 'Assets', "val": eqassetsdata })
          // }
          // const eqstermdata = response.data.Equity.short_term
          // if (eqstermdata.length > 0) {
          //   axiosthis.dessertsexpand.push({ "name": 'Short Term', "val": eqstermdata })
          // }
          // const eqtradingdata = response.data.Equity.trading
          // if (eqtradingdata.length > 0) {
          //   axiosthis.dessertsexpand.push({ "name": 'Trading', "val": eqtradingdata })
          // }
          // console.log(axiosthis.dessertsexpand);
          // //  dervatives
          // axiosthis.dessertsexpandderivative = []
          // const deri_fu_op_data = response.data.Derivatives.der_Fut_booked
          // if (deri_fu_op_data.length > 0) {
          //   axiosthis.dessertsexpandderivative.push({ "name": 'Future Closed', "val": deri_fu_op_data })
          // }
          // const deri_fu_cl_data = response.data.Derivatives.der_Fut_open
          // if (deri_fu_cl_data.length > 0) {
          //   axiosthis.dessertsexpandderivative.push({ "name": 'Future Open', "val": deri_fu_cl_data })
          // }
          // const der_op_opn_data = response.data.Derivatives.der_Opt_booked
          // if (der_op_opn_data.length > 0) {
          //   axiosthis.dessertsexpandderivative.push({ "name": 'Option Closed', "val": der_op_opn_data })
          // }
          // const der_op_cl_data = response.data.Derivatives.der_Opt_open
          // if (der_op_cl_data.length > 0) {
          //   axiosthis.dessertsexpandderivative.push({ "name": 'Option Open', "val": der_op_cl_data })
          // }
          // console.log(axiosthis.dessertsexpandderivative);
          // simplify code
          // function pushToDessertsExpand(array, response, category, name) {
          //   const data = response.data[category];
          //   // const data = response.data[category];
          //   if (data.length > 0) {
          //     array.push({ name, val: data });
          //   }
          // }
          // let valdata = response.data['ASSETS'];
          // let valarray = [];
          // let valarray1 = [];
          // for (let t = 0; t < valdata.length; t++) {
          //   if (valdata[t].Close === 0) {
          //     valarray.push(valdata[t]);
          //   } else {
          //     valarray1.push(valdata[t]);
          //   }
          // }
          // axiosthis.dessertsexpandinner
          // console.log(valarray1, valarray, "valarray1valarray1");
          // axiosthis.dessertsexpand = [];
          // axiosthis.dessertsexpandinner.push({ name: 'WithCloseValue', val: valarray }, { name: 'Withoutclosevalue', val: valarray1 })
          // console.log(axiosthis.dessertsexpandinner, "dessertsexpandinnerdessertsexpandinnerdessertsexpandinnerdessertsexpandinner");
          // let headassert = [
          //   {
          //     text: 'Symbol',
          //     align: 'start',
          //     sortable: false,
          //     value: 'SCRIP_NAME',
          //   },
          //   { text: 'Open Qty', value: 'NETQTY' },
          //   { text: 'Open Rate', value: 'NRATE' },
          //   { text: 'Buy Qty', value: 'BQTY' },
          //   { text: 'Buy Rate', value: 'BRATE' },
          //   { text: 'Sell Qty', value: 'SQTY' },
          //   { text: 'Sell Rate', value: 'SRATE' },
          //   { text: 'Close price', value: 'Close' },
          //   { text: 'Current Value', value: 'Curr_Value' },
          //   { text: 'Notional P&l', value: 'Notional_PandL' },
          //   // { text: '', value: 'data-table-expand' },
          // ]
          // let headshort = [
          //   {
          //     text: 'Symbol',
          //     align: 'start',
          //     sortable: false,
          //     value: 'SCRIP_NAME',
          //   },
          //   { text: 'Net Amount', value: 'NETAMT' },
          //   { text: 'Buy Qty', value: 'BQTY' },
          //   { text: 'Buy Rate', value: 'BRATE' },
          //   { text: 'Buy Amount', value: 'BAMT' },

          //   { text: 'Sell Qty', value: 'SQTY' },
          //   { text: 'Sell Rate', value: 'SRATE' },
          //   { text: 'Sell Amount', value: 'SAMT' },
          //   // { text: 'Close price', value: 'Close' },
          //   // { text: 'Current Value', value: 'Curr_Value' },
          //   { text: 'Notional P&l', value: 'NETAMT' },
          //   // { text: '', value: 'data-table-expand' },
          // ]
          // let headliab = [
          //   {
          //     text: 'Symbol',
          //     align: 'start',
          //     sortable: false,
          //     value: 'SCRIP_NAME',
          //   },
          //   { text: 'Open Qty', value: 'NETQTY' },
          //   { text: 'Open Rate', value: 'NRATE' },
          //   { text: 'Net Amount', value: 'NETAMT' },
          //   // { text: 'Buy Qty', value: 'BQTY' },
          //   // { text: 'Buy Rate', value: 'BRATE' },
          //   // { text: 'Buy Amount', value: 'BAMT' },

          //   { text: 'Sell Qty', value: 'SQTY' },
          //   { text: 'Sell Rate', value: 'SRATE' },
          //   { text: 'Sell Amount', value: 'SAMT' },
          //   { text: 'Close price', value: 'Close' },
          //   { text: 'Current Value', value: 'Curr_Value' },
          //   // { text: 'Notional P&l', value: 'NETAMT' },
          //   // { text: '', value: 'data-table-expand' },
          // ]
          // pushToDessertsExpand(axiosthis.dessertsexpand, response, 'ASSETS', 'Assets');
          // // pushToDessertsExpand(axiosthis.dessertsexpand, response, 'short_term', 'Short Term');
          // // pushToDessertsExpand(axiosthis.dessertsexpand, response, 'Liabilities', 'Liabilities');
          // // pushToDessertsExpand(axiosthis.dessertsexpand, response, 'trading', 'Trading');
          // console.log(axiosthis.dessertsexpand, 'fdfdfd');


          // for (let p = 0; p < axiosthis.dessertsexpand.length; p++) {
          //   if (axiosthis.dessertsexpand[p].name == 'Assets') {
          //     axiosthis.dessertsexpand[p]['header'] = headassert;
          //   }
          // }
          // for (let p = 0; p < axiosthis.dessertsexpand.length; p++) {
          //   if (axiosthis.dessertsexpand[p].name == 'Short Term') {
          //     axiosthis.dessertsexpand[p]['header'] = headshort;
          //   }
          // }
          // for (let p = 0; p < axiosthis.dessertsexpand.length; p++) {
          //   if (axiosthis.dessertsexpand[p].name == 'Liabilities') {
          //     axiosthis.dessertsexpand[p]['header'] = headliab;
          //   }
          // }
          // console.log(axiosthis.dessertsexpand);
          // .........................................................................
          // let headfutclose = [
          //   {
          //     text: 'Symbol',
          //     align: 'start',
          //     sortable: false,
          //     value: 'SCRIP_SYMBOL',
          //   },
          //   // { text: 'Open Qty', value: 'NETQTY' },
          //   // { text: 'Open Rate', value: 'NRATE' },
          //   { text: 'Net Amount', value: 'NETAMT' },
          //   { text: 'Buy Qty', value: 'BQTY' },
          //   { text: 'Buy Rate', value: 'BRATE' },
          //   { text: 'Buy Amount', value: 'BAMT' },

          //   { text: 'Sell Qty', value: 'SQTY' },
          //   { text: 'Sell Rate', value: 'SRATE' },
          //   { text: 'Sell Amount', value: 'SAMT' },
          //   // { text: 'Close price', value: 'Close' },
          //   // { text: 'Current Value', value: 'Curr_Value' },
          //   { text: 'Notional P&l', value: 'NETAMT' },
          //   // { text: '', value: 'data-table-expand' },
          // ]
          // let headfutopen = [
          //   {
          //     text: 'Symbol',
          //     align: 'start',
          //     sortable: false,
          //     value: 'SCRIP_SYMBOL',
          //   },
          //   { text: 'Net Amount', value: 'NETAMT' },

          //   { text: 'Open Qty', value: 'NETQTY' },
          //   { text: 'Open Rate', value: 'NRATE' },
          //   { text: 'Buy Qty', value: 'BQTY' },
          //   { text: 'Buy Rate', value: 'BRATE' },
          //   { text: 'Buy Amount', value: 'BAMT' },

          //   { text: 'Sell Qty', value: 'SQTY' },
          //   { text: 'Sell Rate', value: 'SRATE' },
          //   { text: 'Sell Amount', value: 'SAMT' },
          //   { text: 'Close price', value: 'Close' },
          //   { text: 'Current Value', value: 'Curr_Value' },
          //   { text: 'Notional P&l', value: 'NETAMT' },
          //   // { text: '', value: 'data-table-expand' },
          // ]
          // axiosthis.dessertsexpandderivative = [];

          // function pushToDessertsExpandDerivative(array, response, category, name) {
          //   const data = response.data.Derivatives[category];
          //   if (data.length > 0) {
          //     array.push({ name, val: data });
          //   }
          // }
          // pushToDessertsExpandDerivative(axiosthis.dessertsexpandderivative, response, 'der_Fut_booked', 'Future Closed');
          // pushToDessertsExpandDerivative(axiosthis.dessertsexpandderivative, response, 'der_Fut_open', 'Future Open');
          // pushToDessertsExpandDerivative(axiosthis.dessertsexpandderivative, response, 'der_Opt_booked', 'Option Closed');
          // pushToDessertsExpandDerivative(axiosthis.dessertsexpandderivative, response, 'der_Opt_open', 'Option Open');

          // for (let p = 0; p < axiosthis.dessertsexpandderivative.length; p++) {
          //   if (axiosthis.dessertsexpandderivative[p].name == 'Future Closed' || axiosthis.dessertsexpandderivative[p].name == 'Option Closed') {
          //     axiosthis.dessertsexpandderivative[p]['header'] = headfutclose;
          //   }
          // }
          // for (let p = 0; p < axiosthis.dessertsexpandderivative.length; p++) {
          //   if (axiosthis.dessertsexpandderivative[p].name == 'Future Open' || axiosthis.dessertsexpandderivative[p].name == 'Option Open') {
          //     axiosthis.dessertsexpandderivative[p]['header'] = headfutopen;
          //   }
          // }

          // console.log(axiosthis.dessertsexpandderivative);

          // ........................................................................................
          // function pushToDessertsExpandcommodity(array, response, category, name) {
          //   const data = response.data.Commodity[category];
          //   if (data.length > 0) {
          //     array.push({ name, val: data });
          //   }
          // }
          // axiosthis.dessertsexpandcommodity = []
          // pushToDessertsExpandcommodity(axiosthis.dessertsexpandcommodity, response, 'com_booked', 'Commodity Closed');
          // pushToDessertsExpandcommodity(axiosthis.dessertsexpandcommodity, response, 'der_open', 'Commodity Open');
          // console.log(axiosthis.dessertsexpandcommodity);

          // function pushToDessertsExpandcurrency(array, response, category, name) {
          //   const data = response.data.Currency[category];
          //   if (data.length > 0) {
          //     array.push({ name, val: data });
          //   }
          // }
          // axiosthis.dessertsexpandcurrency = []
          // pushToDessertsExpandcurrency(axiosthis.dessertsexpandcurrency, response, 'curr_booked', 'Currency Closed');
          // pushToDessertsExpandcurrency(axiosthis.dessertsexpandcurrency, response, 'curr_open', 'Currency Open');
          // console.log(axiosthis.dessertsexpandcurrency);





          // axiosthis.loader = false

        })
        .catch((error) => {
          console.log(error);
        });

    },
    derivativesdat() {
      // this.eqloader = true
      this.disabletabsexpense = true
      this.disabletabs = true
      const axios = require('axios');
      let data = JSON.stringify({
        // "cc": "ZTN21H37"
        "cc": this.actid,
        'from': this.fromdateapi,
        'to': this.toshow
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.150:5050/getderpnl',
        url: `${apiurl}/getderpnl`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this

      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          // console.log(axiosthis);
          if (response.data.msg != 'No Data Available') {
            var headerForPdf = [ 'Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L']

            axiosthis.disabletabs = false
            axiosthis.disdowbtn = false
            axiosthis.chararr = response.data.Charges

            var resdata = response.data
            const charges = response.data.Charges
            if (charges && charges.der_charges) {
              var dercharge = charges.der_charges
            }
            if (charges && charges.comm_charges) {
              var comcharge = charges.comm_charges
            }
            if (charges && charges.cur_charges) {
              var curcharge = charges.cur_charges
            }
            if (dercharge.length != 0) {
              for (let i = 0; i < dercharge.length; i++) {
                axiosthis.derchargers = dercharge.reduce((sum, item) => sum + Number(item.NETAMT), 0);
              }
              // axiosthis.derchargers = val
            } else {
              axiosthis.derchargers = 0

            }
            if (comcharge.length != 0) {
              for (let i = 0; i < comcharge.length; i++) {
                axiosthis.comchargers = comcharge.reduce((sum, item) => sum + Number(item.NETAMT), 0);
              }
              // axiosthis.comchargers = val
            } else {
              axiosthis.comchargers = 0

            }
            if (curcharge.length != 0) {
              for (let i = 0; i < curcharge.length; i++) {
                axiosthis.curchargers = curcharge.reduce((sum, item) => sum + Number(item.NETAMT), 0);
              }
              // axiosthis.curchargers = val
            } else {
              axiosthis.curchargers = 0

            }
            // console.log("test4", axiosthis.derchargers, axiosthis.comchargers, axiosthis.curchargers);




            // console.log(resdata.Derivatives.der_Fut_booked, "resdata");

            const derclose = resdata.Derivatives.der_Fut_booked
            const futopen = resdata.Derivatives.der_Fut_open
            const optclose = resdata.Derivatives.der_Opt_booked
            const optopen = resdata.Derivatives.der_Opt_open
            const categories = ['der_Fut_booked', 'der_Fut_open', 'der_Opt_booked', 'der_Opt_open'];


            categories.forEach(category => {
              const data = resdata.Derivatives[category] || [];
              axiosthis.downloadarray.push(...data);
            });
            // console.log("turnover", axiosthis.downloadarray);

            axiosthis.dessertsexpandderivative = []
            if (derclose.length != 0) {
              // console.log("turnover", derclose);
              axiosthis.tables.push({ heading: 'Derivative Future Closed', data: [] });

// Prepare the data for 'Assets'
          const assetsData = derclose.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Derivative Future Closed');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandderivative.push({ name: 'Future Closed', val: derclose })
              const derclosed = derclose.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const derclosedbuy = derclose.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const derclosedsel = derclose.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              axiosthis.derclsed = derclosed
              var deummyturnoverfut = derclosedbuy + derclosedsel
              // console.log("turnoverif", axiosthis.derclsed);

            } else {
              axiosthis.derclsed = 0
              deummyturnoverfut = 0
              // console.log("turnoverelse", axiosthis.derclsed);

              // deummyturnoverfut = 0
            }
            if (futopen.length != 0) {
              axiosthis.tables.push({ heading: 'Derivative Future Open', data: [] });

// Prepare the data for 'Assets'
          const assetsData = futopen.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Derivative Future Open');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              // console.log("turnoverif", futopen);
              axiosthis.dessertsexpandderivative.push({ name: 'Future Open', val: futopen })
              const futopend = futopen.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const futopendbuy = futopen.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const futopensel = futopen.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              const dummysel = futopensel + futopendbuy
              axiosthis.futopened = futopend + axiosthis.derclsed
              axiosthis.futurnover = deummyturnoverfut + dummysel
              // console.log("turnover", axiosthis.futopened);
              // console.log("Sum of NETAMT values:", futopend);
            } else {
              axiosthis.futopened = 0 + axiosthis.derclsed
              axiosthis.futurnover = 0 + deummyturnoverfut
              // axiosthis.futurnover = 0
              // console.log("turnoverelse", axiosthis.futurnover);

            }
            if (optclose.length != 0) {
              // console.log("turnover", optclose);
              axiosthis.tables.push({ heading: 'Derivative Option Closed', data: [] });

// Prepare the data for 'Assets'
          const assetsData = optclose.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Derivative Option Closed');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandderivative.push({ name: 'Option Closed', val: optclose })
              const opcld = optclose.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const opoclsdbuy = optclose.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const opclssel = optclose.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              var dummy = opoclsdbuy + opclssel
              axiosthis.optioncls = opcld
              // console.log("turnoverif", axiosthis.optioncls);

              // console.log("Sum of NETAMT values:", opcld);
            } else {
              axiosthis.optioncls = 0
              // console.log("turnoverelse", axiosthis.optioncls);
              dummy = 0
              // dummy = 0
            }
            if (optopen.length != 0) {
              axiosthis.tables.push({ heading: 'Derivative Option Open', data: [] });

// Prepare the data for 'Assets'
          const assetsData = optopen.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Derivative Option Open');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandderivative.push({ name: 'Option Open', val: optopen })
              // console.log("turnover", optopen);

              const opopn = optopen.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const opopendbuy = optopen.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const opopensel = optopen.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              const dummy2 = opopendbuy + opopensel
              axiosthis.opturnover = dummy2 + dummy
              axiosthis.optionopn = opopn + axiosthis.optioncls
              // console.log("turnoverif", axiosthis.optionopn);

              // console.log("Sum of NETAMT values:", opopn);
            } else {
              axiosthis.optionopn = 0 + axiosthis.optioncls
              // console.log("turnoverelse", axiosthis.optionopn);
              axiosthis.opturnover = 0 + dummy
              // axiosthis.opturnover = 0
            }

            // ...........................................................................................................................



            axiosthis.dessertsexpandcommodity = []
            const comfutop = resdata.Commodity.com_fut_open
            const comfuclose = resdata.Commodity.com_fut_booked
            const comclose = resdata.Commodity.com_opt_booked
            const compen = resdata.Commodity.com_opt_open
            const categories1 = ['com_fut_open', 'com_fut_booked', 'com_opt_booked', 'com_opt_open'];


            categories1.forEach(category => {
              const data = resdata.Commodity[category] || [];
              axiosthis.downloadarray.push(...data);
            });
            // console.log("turnover 2",axiosthis.downloadarray)

            if (comfuclose.length != 0) {
              axiosthis.tables.push({ heading: 'Commodity Future  Closed', data: [] });

// Prepare the data for 'Assets'
          const assetsData = comfuclose.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Commodity Future  Closed');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcommodity.push({ name: 'Future  Closed', val: comfuclose })
              const comnetAmtSum = comfuclose.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comclosedbuy = comfuclose.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comclosesel = comfuclose.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              var dummycomfut = comclosedbuy + comclosesel
              axiosthis.comnetfutamt = comnetAmtSum
            } else {
              axiosthis.comnetfutamt = 0
              dummycomfut = 0
            }

            if (comfutop.length != 0) {
              axiosthis.tables.push({ heading: 'Commodity Future  Open', data: [] });

// Prepare the data for 'Assets'
          const assetsData = comfutop.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Commodity Future  Open');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcommodity.push({ name: 'Future  Open', val: comfutop })
              const comnetAmfuttSum = comfutop.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comptfutdbuy = comfutop.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comopfutsel = comfutop.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              axiosthis.comnetfutamt = comnetAmfuttSum + axiosthis.comnetfutamt
              const dummyturn = comptfutdbuy + comopfutsel
              axiosthis.futturnover = dummycomfut + dummyturn
            } else {
              axiosthis.comnetfutamt = 0 + axiosthis.comnetfutamt
              axiosthis.futturnover = dummycomfut
            }

            if (comclose.length != 0) {
              axiosthis.tables.push({ heading: 'Commodity Option Closed', data: [] });

// Prepare the data for 'Assets'
          const assetsData = comclose.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Commodity Option Closed');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcommodity.push({ name: 'Option Closed', val: comclose })
              const comnetAmtSum = comclose.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comclosedbuy = comclose.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comclosesel = comclose.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              var dummycom = comclosedbuy + comclosesel
              axiosthis.comnetamt = comnetAmtSum
            } else {
              axiosthis.comnetamt = 0
              dummycom = 0
            }

            if (compen.length != 0) {
              axiosthis.tables.push({ heading: 'Commodity Option Open', data: [] });

// Prepare the data for 'Assets'
          const assetsData = compen.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Commodity Option Open');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcommodity.push({ name: 'Option Open', val: compen })
              const comopnetAmtSum = compen.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comopdbuy = compen.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comopsel = compen.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              const ttval = comopdbuy + comopsel + dummycom
              axiosthis.comturnover = ttval
              axiosthis.comnetamt = comopnetAmtSum + axiosthis.comnetamt
            } else {
              axiosthis.comnetamt = 0 + axiosthis.comnetamt
              axiosthis.comturnover = dummycom
            }
            // console.log("test", axiosthis.comnetamt, axiosthis.comturnover, axiosthis.futturnover, axiosthis.comnetfutamt);






            axiosthis.dessertsexpandcurrency = []
            const curfutclose = resdata.Currency.curr_fut_booked
            const curfutopen = resdata.Currency.curr_fut_open
            const curopclose = resdata.Currency.curr_opt_booked
            const curopopen = resdata.Currency.curr_opt_open
            const categories2 = ['curr_fut_booked', 'curr_fut_open', 'curr_opt_booked', 'curr_opt_open'];


            categories2.forEach(category => {
              const data = resdata.Commodity[category] || [];
              axiosthis.downloadarray.push(...data);
            });
            // console.log("turnover 3",axiosthis.downloadarray)


            if (curfutclose.length != 0) {
              axiosthis.tables.push({ heading: 'Currency Future  Closed', data: [] });

// Prepare the data for 'Assets'
          const assetsData = curfutclose.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Currency Future  Closed');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcurrency.push({ name: 'Future  Closed', val: curfutclose })
              const comnetAmtSum = curfutclose.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comclosedbuy = curfutclose.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comclosesel = curfutclose.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              var dummycurfut = comclosedbuy + comclosesel
              axiosthis.curnetfutamt = comnetAmtSum
            } else {
              axiosthis.curnetfutamt = 0
              dummycurfut = 0
            }

            if (curfutopen.length != 0) {
              axiosthis.tables.push({ heading: 'Currency Future  Open', data: [] });

// Prepare the data for 'Assets'
          const assetsData = curfutopen.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Currency Future  Open');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcurrency.push({ name: 'Future  Open', val: curfutopen })
              const comnetAmfuttSum = curfutopen.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comptfutdbuy = curfutopen.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comopfutsel = curfutopen.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              axiosthis.curnetfutamt = comnetAmfuttSum + axiosthis.curnetfutamt
              const dummyturn = comptfutdbuy + comopfutsel
              axiosthis.curfutturnover = dummycurfut + dummyturn
            } else {
              axiosthis.curnetfutamt = 0 + axiosthis.curnetfutamt
              axiosthis.curfutturnover = dummycurfut
            }

            if (curopclose.length != 0) {
              axiosthis.tables.push({ heading: 'Currency Option Closed', data: [] });

// Prepare the data for 'Assets'
          const assetsData = curopclose.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Currency Option Closed');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcurrency.push({ name: 'Option Closed', val: curopclose })
              const comnetAmtSum = curopclose.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comclosedbuy = curopclose.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comclosesel = curopclose.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              var dummycur = comclosedbuy + comclosesel
              axiosthis.curopnetamt = comnetAmtSum
            } else {
              axiosthis.curopnetamt = 0
              dummycur = 0
            }

            if (curopopen.length != 0) {
              axiosthis.tables.push({ heading: 'Currency Option Open', data: [] });

// Prepare the data for 'Assets'
          const assetsData = curopopen.map(item => [
           
            item.SCRIP_NAMEDATA || item.SCRIP_SYMBOL,
            item.BUY_QTY || item.BUYQTY,
            item.BUY_RATE || item.BUYRATE,
            item.SALE_QTY || item.SALEQTY,
            item.SALE_RATE || item.SALERATE,
            item.NET_QTY || item.NETQTY,
            item.NET_RATE || item.NETRATE,
            item.Closing_Price || item.CL_PRICE,
            item.NOTIONAL_NET
          ]);

          // Add header and assets data to the 'Assets' table
          const assetsTable = axiosthis.tables.find(table => table.heading === 'Currency Option Open');
          if (assetsTable) {
          // Remove any existing instances of headerForPdf
            

          // Add the header and assets data
          assetsTable.data.unshift(headerForPdf);
          assetsTable.data.push(...assetsData);
          }
              axiosthis.dessertsexpandcurrency.push({ name: 'Option Open', val: curopopen })
              const comopnetAmtSum = curopopen.reduce((sum, item) => sum + Number(item.NOTIONAL_NET), 0);
              const comopdbuy = curopopen.reduce((sum, item) => sum + Number(item.BUYAMT), 0);
              const comopsel = curopopen.reduce((sum, item) => sum + Number(item.SALEAMT), 0);
              const ttval = comopdbuy + comopsel + dummycur
              axiosthis.comopturnover = ttval
              axiosthis.curopnetamt = comopnetAmtSum + axiosthis.curopnetamt
            } else {
              axiosthis.curopnetamt = 0 + axiosthis.curopnetamt
              axiosthis.comopturnover = dummycur
            }
          } else {
            axiosthis.disabletabs = false
            axiosthis.disdowbtn = false
          }


          // console.log(response.data.msg == 'No Data Available',response.data.msg);
          // if(response.data.msg == 'No Data Available'){

          //   // alert("fghjk")
          // }

          // if (curclose.length != 0) {
          //   axiosthis.dessertsexpandcurrency.push({ name: 'Currency Closed', val: curclose })
          // }
          // if (curopen.length != 0) {
          //   axiosthis.dessertsexpandcurrency.push({ name: 'Currency Open', val: curopen })
          // }
          // console.log("test2", axiosthis.curnetfutamt, axiosthis.curfutturnover, axiosthis.curopnetamt, axiosthis.comopturnover);
       axiosthis.downloadarrayForPdf = axiosthis.tables

        })
        .catch((error) => {
          console.log(error);
        });
    },
    gettaxpnl(yearis) {
      this.loader = true

      // var todayapi = dd + '/' + mm + '/' + yyyy;
      this.dessertsexpand = []
      this.dessertsexpandderivative = []
      this.dessertsexpandcommodity = []
      this.dessertsexpandcurrencys = []
      this.tradenetamt = 0
      this.totalintraday = 0
      this.longnetval = 0
      this.stnetamt = 0
      this.assnetamt = 0
      this.futopened = 0
      this.futurnover = 0
      this.optionopn = 0
      this.opturnover = 0
      this.comnetfutamt = 0
      this.futturnover = 0
      this.comnetamt = 0
      this.comturnover = 0
      this.tabvaluestack = 'Equities'
      this.tab = this.tab2
      // this.futturnover = 0
      // this.futturnover = 0
      // this.futturnover = 0
      // this.futturnover = 0
      // var year = yearis
      var today = new Date();
      // var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      if (mm < 3 && yyyy == yearis) {
        var yearmount = yearis - 1
      } else {
        yearmount = yearis
      }
      let yearnext = yearmount + 1
      var fromapi = '01' + '/' + '04' + '/' + yearmount;
      var today1 = '31' + '/' + '03' + '/' + yearnext;
      this.toshow = today1
      this.fromshow = fromapi
      this.fromdateapi = fromapi

      // this.fromshow = (this.fromshow) + '-' + (this.toshow)
      // this.toshow = today1
      // this.fromshow = this.fromshow + '-' + this.toshow
      this.equitiesdata()
      this.derivativesdat()
      this.expenseforequity()
    },
    expenseforequity() {
      this.segloader = true
      this.segval = 0
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.actid,
        "from": this.fromdateapi,
        "to": this.toshow,
        "seg": "eq"
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.198:5050/getexpenses',
        url: `${apiurl}/getexpenseseperate`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data.msg != 'No Data Available') {
            axiosthis.segloader = false
            //  alert("ghjkl")
            axiosthis.disabletabsexpense = false
            axiosthis.downbtn = false

            axiosthis.eqarr = response.data.eq
            // console.log(JSON.stringify(response.data));
            axiosthis.segval = response.data.Total
          } else {
            axiosthis.downbtn = true
            axiosthis.eqarr = []
            axiosthis.segloader = false
            axiosthis.disabletabsexpense = false
          }

          // console.log(axiosthis.segval);
        })
        .catch((error) => {
          console.log(error);
        });

    }

  },
  mounted() {

    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    this.cname = localStorage.getItem("cname");
    var res = this.decryptionFunction(localStorage.getItem("profile_data"));
    // console.log('ttttt',JSON.parse(res));
    var resp = JSON.parse(res);
    this.profiledetails = resp.client_data;
    this.loader = true

    var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    // var nextyear = yyyy + 1
    // var startYear = yyyy - 4;
    this.yearis = yyyy
    // for (let year = startYear; year <= yyyy; year++) {
    //   this.items.push(year);
    // }
    // var today = new Date();
    // var yyyy = today.getFullYear();

    if (mm < 4) {
      var yearmount = yyyy - 1
    } else {
      yearmount = yyyy
    }
    var startYear = yearmount - 4;
    this.yearis = yearmount;

    for (let year = yearmount; year >= startYear; year--) {
      this.items.push(year);
    }
    // var todayapi = dd + '/' + mm + '/' + yyyy;
    var fromapi = '01' + '/' + '04' + '/' + yearmount;
    let nextyear = parseInt(yearmount) + 1

    var today1 = '31' + '/' + '03' + '/' + nextyear;
    this.toshow = today1
    this.fromshow = fromapi
    this.fromdateapi = fromapi

    this.fromshow = (this.fromshow) + '-' + (this.toshow)
    // this.toshow = today1
    // this.fromshow = this.fromshow + '-' + this.toshow
    this.equitiesdata()
    this.derivativesdat()
    this.expenseforequity()
    // const axios = require('axios');
    this.convertImageToBase64();



  }

};
</script>

<style lang="scss">
.basil {
  background-color: #ffffff !important;
}

.basil--text {
  color: #020202 !important;
}

.v-tab {
  text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
  /* flex: 1 0 auto; */
  max-width: none !important;
}

.itemRowBackground {
  background-color: orange;
}

.customRowClass {
  background-color: red !important;
}

.cust-table .v-data-table__wrapper tr,
.cust-table tr.v-data-table__expanded.v-data-table__expanded__row {
  background-color: rgb(238, 238, 255) !important;
  color: rgb(62, 62, 62) !important;
  font-weight: 500;

}

.cust-table .v-data-table__wrapper tr tr {
  background-color: #fff !important;
  color: rgb(0, 0, 0) !important;

}

.cust-table .theme--light.v-table tr {
  background-color: none !important;

}

.v-data-table__empty-wrapper td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Merged styles */

// .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
// .cust-table {
//     font-size: 0.875rem;
//     height: 36px;
// }</style>