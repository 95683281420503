<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
            <v-alert v-if="alert" dense :type="coloralert" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
                <strong>{{ msg }}</strong>
            </v-alert>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
                <!-- <v-switch x-small v-model="$vuetify.theme.dark" class="mt-n1 mr-2"></v-switch> -->
                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0 mb-5">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="mr-2" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Trading Journal</v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Track, Learn, and Grow - Your Trading Journey Logged.
                                        </v-list-item-subtitle>
                                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Old
                                            format P&L Reference Available <a href="/pnl">Click here</a>
                                        </v-list-item-subtitle> -->
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>


                            <v-select :disabled="loader" label="Filled" :items="filterr" @change="filterseg()" dense rounded solo flat
                                v-model="filterdata_unpl" placeholder="filter" background-color="#F1F3F8" hide-details
                                class="mr-5 mt-n3 fildrop" style="max-width: 140px; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                    <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select>
                            <!-- <v-text-field style="max-width: 250px" class="tophundraedmutual d-none d-md-block mt-4 mr-5"
                                height="36px" background-color="#F1F3F8" label="Search" v-model="search" solo rounded
                                text flat dense>
                                <template v-slot:prepend-inner>
                                    <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                        height="18px" />
                                </template>
                            </v-text-field> -->
                            <v-menu :disabled="loader" class="" v-model="menuForDatePicker" :close-on-click="fromdate.length == 2"
                                :close-on-content-click="false" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :disabled="loader" style="max-width: 240px;" class="mt-4 mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black"
                                        background-color="#F1F3F8" prepend-inner-icon="mdi-calendar" readonly
                                        v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :min="fromdate[0]" :max="maxval" color="black"
                                            v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="getCalenderData()"></v-date-picker> -->
                                    </div>
                                    <!-- <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>Fsort
                                    </p> -->
                                    <p v-if="fromdate != '' && fromdate != []" class="ml-2 caption mb-1 "><span
                                            class="red--text">*</span>Your are
                                        in {{ Number(maxval.split("-")[0]) - 1 }}-{{ maxval.split("-")[0] }} Financial
                                        year
                                        <v-tooltip color="black" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span @click="reset" class="caption blue--text font-weight-bold"
                                                    style="cursor: pointer" v-bind="attrs" v-on="on">
                                                    Change
                                                </span>
                                            </template>
                                            <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            :class="{ 'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur' }"
                                            @click="GetDataWithYearSelector(0, 'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            :class="{ 'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear' }"
                                            @click="GetDataWithYearSelector(-1, 'lyear')">Last FY</p>

                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            :class="{ 'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0] }"
                                            @click="GetDataWithYearSelector(-2, yearsarray[0])">{{ yearsarray[0] }}</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            :class="{ 'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1] }"
                                            @click="GetDataWithYearSelector(-3, yearsarray[1])">{{ yearsarray[1] }}</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            :class="{ 'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2] }"
                                            @click="GetDataWithYearSelector(-4, yearsarray[2])">{{ yearsarray[2] }}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>

                            </v-menu>


                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->

                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->

                        </v-toolbar>
                        <v-divider></v-divider>

                        <div class="display-flex row   d-none d-md-block mt-8 pa-8 pt-0">


                            <v-row class="mt-4">
                                <v-col cols="3">
                                    <v-card class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L </p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L </p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </p>

                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col cols="3">
                                    <v-card class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes </p>

                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <!-- <v-skeleton-loader  max-width="300" height="10"
                                                type="card"></v-skeleton-loader> -->

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0 red--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </p>

                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col cols="3">
                                    <v-card class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Net Realised P&L </p>

                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <!-- <v-skeleton-loader  max-width="300" height="10"
                                                type="card"></v-skeleton-loader> -->

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="(RealishedPnl - TaxesAndCharges) > 0 ? 'green--text' : (RealishedPnl - TaxesAndCharges) < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ ((RealishedPnl - TaxesAndCharges)).toFixed(2) }}
                                                </p>

                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <!-- P&L verified by Zebu -->
                                <!-- <v-col v-if="TableData.length > 0" cols="3">
                                    <div class="mt-4 ml-4">
                                        <p class="mt-2 mb-3" style="
                                                font-weight: 450;
                                                font-size: 14px;
                                                color: #4b4b4b;
                                                line-height: 16px;
                                                letter-spacing: -0.4px;
                                            ">
                                            <v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
                                            P&L verified by Zebu

                                        </p>


                                        <v-row>
                                            <v-col cols="7" class="pa-0 pl-3">


                                                <p class="mt-2 mb-0 " style="font-size: 12px">Share to Everyone</p>
                                                <div class="d-flex mt-n1">
                                                    <v-switch color="black" @change="switchbtn" class="mt-1"
                                                        v-model="agreeforshare" inset dense></v-switch>
                                                    <v-btn class="mt-2 px-3" disabled rounded x-small
                                                        v-if="!agreeforshare">
                                                        <v-icon class="" size="16">mdi mdi-share-variant</v-icon>
                                                    </v-btn>
                                                    <v-btn dark class="mt-2 px-3" rounded x-small v-else
                                                        @click="dialogcopytext = true">
                                                        <v-icon color="white" class="" size="16">mdi
                                                            mdi-share-variant</v-icon>
                                                    </v-btn>

                                                </div>
                                            </v-col>

                                        </v-row>

                                    </div>
                                </v-col> -->
                                <!-- {{dessertspopexpense}} -->
                            </v-row>
                            <v-card class="mt-8" outlined style=" height : 100%;width : 100%;overflow-x: scroll">

                                <div v-if="loader == false" class="ml-n3 mt-4">

                                    <!-- <v-row class="mx-auto mt-6" style="width: 80%;">
                                        <v-col cols="3" class="pa-0">
                                            <p class=" "
                                                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                                Realised P&L :<span
                                                    :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </span></p>

                                        </v-col>
                                        <v-col cols="3" class="pa-0">
                                            <p class=" "
                                                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                                Unrealised P&L :<span
                                                    :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </span></p>

                                        </v-col>
                                        <v-col cols="3" class="pa-0">
                                            <p class=" "
                                                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                                Charges & taxes :<span class="mb-0 red--text"
                                                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </span></p>

                                        </v-col>
                                        <v-col cols="3" class="pa-0">
                                            <p class=" "
                                                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                                Net Realised P&L : <span
                                                    :class="(RealishedPnl - TaxesAndCharges) > 0 ? 'green--text' : (RealishedPnl - TaxesAndCharges) < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ ((RealishedPnl - TaxesAndCharges)).toFixed(2) }}
                                                </span></p>

                                        </v-col>
                                    </v-row> -->

                                    <!-- <div class="ml-1 mt-2 pa-4" id="cal-heatmap" style=""></div> -->
                                    <div class="d-flex pl-12" style="width: 100%;">
                                        <v-card v-for="(key, val, ind) in calendermontharray" :key="ind" outlined
                                            class="ma-2 mr-3 my-4 elevation-0" style="min-width:  100px;  "
                                            :style="{ backgroundColor: Number(key.value) < 0 ? '#ffd4d4' : Number(key.value) > 0 ? '#cdfad1' : '#ededed' }">



                                            <p :style="{ color: Number(val[0].value) == 0 ? 'black' : 'black' }"
                                                class="mb-0 caption pl-2 pt-1 pr-2" style="width: ">
                                                {{ val.substring(0, 3) }} {{
                                                    val == 'January' || val
                                                        == 'February' || val == 'March' ? ToDateForApi.split("/")[2] :
                                                        FromDateForApi.split("/")[2] }} <br>{{

                                                    (key.value).toFixed(2)
                                                }}</p>
                                            <!-- <p :style="{ color: Number(val[0].value) == 0 ? 'black' : 'black' }"
                                                class="mb-0 caption pl-2 pt-1 pr-2" style="width: 100px;">{{
                                                    val[0].value.toFixed(2) }} </p> -->


                                        </v-card>
                                    </div>
                                </div>
                                <!-- <div v-else-if="loader == false" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div> -->
                                <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                                <div v-else class="text-center mt-4">
                                    <v-progress-circular :size="50" :width="4" color="#0037B7"
                                        indeterminate></v-progress-circular>
                                    <p class="mb-0 caption mt-4">Getting your data please wait...</p>
                                </div>
                                <!-- </v-overlay> -->
                            </v-card>
                        </div>
                        <!-- <v-list-item-title class="text-start ml-6"
                        style="color: #000000;font-size: 20px;font-weight:559;line-height:20px;letter-spacing:-1px">
                        Statistics </v-list-item-title>
                        <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col cols="3">
                                   <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Trading Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Trading Days'] ? (StatArray['Trading Days'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Win Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Win Days'] ? (StatArray['Win Days'])  : 0 }}

                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Loss Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 red--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Loss Days'] ? (StatArray['Loss Days'])  : 0 }}

                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Winning Streak Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Winning Streak Days'] ? (StatArray['Winning Streak Days'])  : 0 }}

                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Losing Streak Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 red--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Losing Streak Days'] ? (StatArray['Losing Streak Days'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Win Rate</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Win Rate'] ? (StatArray['Win Rate'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Maximum Profit in a Day</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Maximum Profit in a Day'] ? (StatArray['Maximum Profit in a Day'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Maximum Loss in a Day</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 red--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Maximum Loss in a Day'] ? (StatArray['Maximum Loss in a Day'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Average Profit/Loss Daily</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Average Profit/Loss Daily'] ? (StatArray['Average Profit/Loss Daily'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Average Profit on Profit Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 green--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Average Profit on Profit Days'] ? (StatArray['Average Profit on Profit Days'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Average Loss on Loss Days</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 red--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Average Loss on Loss Days'] ? (StatArray['Average Loss on Loss Days'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3">
                                    <v-card :loading="loader" class=" pl-3  pr-2 " outlined height="90px">
                                        <p class="pt-3 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Maximum Drawdown</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p 
                                                    class="mb-0 red--text"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ StatArray['Maximum Drawdown'] ? (StatArray['Maximum Drawdown'])  : 0 }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>

                            </v-row>
                        </div> -->

                        <!-- <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card @click="chargerdialoge = true" class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                        <div class="display-flex row pt-6 pl-2"> 
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0" style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col> 
                            </v-row>
                        </div> -->
                        <!-- {{ ChartValue }} -->
                        <!-- <v-col cols="3" class="pa-0">
                            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
  
 
                                <v-tab @click="tabdata('Equities')"> 
                                    Daily
                                </v-tab>

                                <v-tab>
                                    Monthly
                                </v-tab>



 
                            </v-tabs>
                        </v-col> -->

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-data-table hide-default-header :headers="HeaderFortableForexpand" :items="TableData"
                                    :single-expand="singleExpand" style="border: 1px solid #efeef3; cursor: pointer"
                                    class="cust-table elevation-0 rounded-lg cell-height mt-4" fixed-header
                                    @click:row="toggleExpanded" :loading="loader" height="495px" hide-default-footer
                                    :expanded.sync="expanded" item-key="name" disable-sort :items-per-page="-1">

                                    <template v-slot:[`item.name`]="{ item }">
                                        <span @click="toggleExpanded(item)" class="overline"> {{ formatDate(item.name)
                                            }}
                                         (
                                        <span class="overline"> {{ (item.val).length }}</span>

                                        )    
                                        </span>



                                    </template>
                                    
                                

                                    <template v-slot:[`item.real`]="{ item }">
                                        <span class="overline" :class="{
                                            'green--text': realisedcal(item.val) > 0,
                                            'red--text': realisedcal(item.val) < 0,
                                            'black--text': realisedcal(item.val) === 0
                                        }"> {{ realisedcal(item.val) }}</span>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td class="px-0" :colspan="headers.length">
                                            <!-- v-if="item.name != 'Assets'" -->
                                            <v-data-table @click:row="SymbolMatchedDate"
                                                :items-per-page="item.val.length" item fixed-header hide-default-footer
                                                dense :headers="HeaderFortableForexpandinSide" :items="item.val"
                                                item-key="name" class="elevation-0">

                                                <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                                    <span class="caption font-weight-bold"> {{ item.SCRIP_SYMBOL
                                                        }}</span>
                                                    <v-chip v-if="item.Open_Qty" class="ma-2" small color="green"
                                                        outlined> {{ item.Open_Qty }} @ {{ item.Open_Rate }} </v-chip> 
                                                </template>

                                                <template v-slot:[`item.BQTY`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.BQTY }}</span>
                                                </template>

                                                <template v-slot:[`item.BRATE`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.BRATE
                                                        }}</span><br>
                                                    <span class=" font-weight-normal" style="font-size: 11px;"> {{
                                                        (Number(item.BRATE) * Number(item.BQTY)).toFixed(2) }}</span>
                                                </template>

                                                <template v-slot:[`item.SQTY`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.SQTY }}</span>
                                                </template>

                                                <template v-slot:[`item.SRATE`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.SRATE
                                                        }}</span><br>
                                                    <span class=" font-weight-normal" style="font-size: 11px;"> {{
                                                        (Number(item.SRATE) * Number(item.SQTY)).toFixed(2) }}</span>
                                                </template>

                                                <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.Updated_NETQTY
                                                        }}</span>
                                                </template>

                                                <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.CLOSING_PRICE
                                                        }}</span>
                                                </template>

                                                <template v-slot:[`item.realisedpnl`]="{ item }">
                                                    <span class="caption font-weight-normal"> {{ item.realisedpnl
                                                        }}</span>
                                                </template>
                                                <!-- <template v-slot:item="{ item }">
                              <tr class=" " style=" height: 30px;">
                             
                                <td class="" style="width:350px">
                                  <span class="font-weight-bold caption mt-8"> <span >{{   item.SCRIP_SYMBOL   }}</span>
                                    

                                    
                                  </span>

                                </td>


                                <td class=" text-right" style="width: 400px">
                                    <span class="font-weight-normal caption"> <span >{{   item.total_buy_qty   }}</span>
                                    

                                    
                                </span>
                                </td>
                                <td style="width: 150px">

</td>
                                <td class=" text-right pl-0" style="width: 100px">
                                    <span class="font-weight-normal caption"> <span >{{  item.total_buy_rate   }}</span>
                                    

                                    
                                </span>
                                </td>

                                <td class="  text-right" style="width: 200px">
                                    <span class="font-weight-normal caption"> <span >{{  item.total_sell_qty     }}</span>
                                    

                                    
                                </span>
                                </td>
                                <td style="width: 200px" class="pr-0  text-right">
                                    <span class="font-weight-normal caption"> <span >{{ item.total_sell_rate  }}</span>
                                    

                                    
                                </span>

                                </td>
                                <td style="width: 200px" class="pr-0  text-right">
                                    <span class="font-weight-normal caption"> <span >{{ item.NETQTY  }}</span>
                                    

                                    
                                </span>

                                </td>
                                <td class=" text-right pr-2 " style="width: 200px">
                                    <span class="font-weight-normal caption"> <span >{{   item.CLOSING_PRICE  }}</span>
                                    

                                    
                                </span>

                                </td>
                                <td style="width: 80px">

</td>
                                <td class=" text-right " style="width: 200px">
                                    <span class="font-weight-normal caption"> <span >{{   item.realisedpnl  }}</span>
                                    

                                    
                                </span>
                                </td>
                                <td class=" text-right " style="width: 200px">
                                    <span class="font-weight-normal caption"> <span >{{   item.last  }}</span>
                                    

                                    
                                </span>
                                </td>
                                <td style="width: 80px">

                                </td>

                              </tr>
                            </template> -->


                                            </v-data-table>

                                        </td>
                                    </template>
                                </v-data-table>
                                <!-- :group-by="['TRADE_DATE']"  -->

                                <!-- TableData -->
                                <!-- <v-expansion-panels style="border: 0.5px solid #F1F3F8;">
                                    <v-expansion-panel  v-for="(item, key, ind) in reversedData" :key="ind">
                                        <v-expansion-panel-header class="pa-0 pa-2">
                                            <p v-if="item.length > 0" class="ml-3 mb-0 body-1 font-weight-normal">
                                                {{ formatDate(key) }} </p>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-data-table v-if="item.length" :search="search" :close-on-click="false"
                                                @click:row="SymbolMatchedDate" ref="dataTableRef" :loading="loader"
                                                mobile-breakpoint hide-default-footer height="350px" fixed-header
                                                :headers="HeaderFortable" :items="item"
                                                :items-per-page="TableData.length"
                                                style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;"
                                                class="elevation-0 rounded-lg mt-4">
                                                <template slot="group.header" slot-scope="{}">
                                                 
                                                </template>
                                                <template slot="group.summary" slot-scope="{ items  }">
                                                   
                                                    <td style="background-color: #F9FBFC">

                                                        <p class="caption font-weight-bold  mb-0 ml-2">Total
                                                            Trades:<span class="caption font-weight-regular ml-2"> {{
                                                                items.length
                                                                }}</span>
                                                        </p>


                                                    </td>
                                                    <td style="background-color: #F9FBFC"> </td>
                                                  
                                                    <td style="background-color: #F9FBFC"> </td>
                                                    <td style="background-color: #F9FBFC"> </td>
                                                    <td style="background-color: #F9FBFC"> </td>
                                                    <td style="background-color: #F9FBFC"> </td>
                                                    <td style="background-color: #F9FBFC"> </td>

                                                    <td style="background-color: #f9fbfc">
                                                        <p class="caption font-weight-bold  ml-2 mb-0">Realised : <span
                                                                class="font-weight-regular">
                                                                {{ realisedcal(items) }}
                                                            </span> </p>
                                                    </td>
                                                   
                                                </template>
                                                <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                                    <span class="font-weight-bold caption">{{ item.SCRIP_SYMBOL
                                                        }}</span>
                                                    <p class="font-weight-normal mb-0 caption">{{
                                                        item.TRADE_DATE.split(" ")[1]
                                                        +
                                                        "/" +
                                                        item.TRADE_DATE.split(" ")[2] + "/" + item.TRADE_DATE.split(" ")[3] }}
                                                    </p>
                                                </template>

                                                <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.CLOSING_PRICE
                                                        }}</span>
                                                </template>

                                             

                                                <template v-slot:[`item.total_buy_qty`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.total_buy_qty
                                                        }}</span>
                                                </template>

                                                <template v-slot:[`item.total_buy_rate`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.total_buy_rate ?
                                                        (item.total_buy_rate).toFixed(2) : 0 }}</span>
                                                    <p class="font-weight-regular caption mb-0">{{ item.total_buy_qty ?
                                                        (item.total_buy_qty *
                                                            item.total_buy_rate).toFixed(2) : 0 }}</p>
                                                </template>

                                                

                                                <template v-slot:[`item.total_sell_qty`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.total_sell_qty
                                                        }}</span>
                                                </template>

                                                <template v-slot:[`item.total_sell_rate`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.total_sell_rate
                                                        }}</span>
                                                    <p class="font-weight-regular caption mb-0">{{ item.total_sell_qty ?
                                                        (item.total_sell_qty *
                                                            item.total_sell_rate).toFixed(2) : 0 }}</p>

                                                </template>
                                                <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.Updated_NETQTY
                                                        }}</span>
                                                </template>
                                                <template v-slot:[`item.remainingbprice`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.remainingbprice
                                                        }}</span>
                                                </template>

                                                <template v-slot:[`item.remainingqty`]="{ item }">
                                                    <span class="font-weight-regular caption">{{ item.remainingqty
                                                        }}</span>
                                                </template>

                                                <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                                    <span
                                                        :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0, 'black--text': item.unrealisedpnl == 0 }"
                                                        class="font-weight-regular caption ">{{ item.unrealisedpnl ?
                                                            (item.unrealisedpnl).toFixed(2) : 0 }}</span>
                                                </template>
                                                <template v-slot:[`item.realisedpnl`]="{ item }">
                                                    <span
                                                        :class="{ 'green--text': item.total_realised_pnl > 0, 'red--text': item.total_realised_pnl < 0, 'black--text': item.total_realised_pnl == 0 }"
                                                        class="font-weight-regular caption">{{ item.total_realised_pnl ?
                                                            (item.total_realised_pnl).toFixed(2) : 0 }}</span>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-col class="mx-auto pa-15 mt-5">
                                                        <img alt="" class="shrink" src="../../../assets/nodata.svg"
                                                            width="15%" height="" />
                                                        <p class="font-weight-bold black--text">No data available</p>
                                                    </v-col>
                                                </template>
                                            </v-data-table>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels> -->
                                <!-- <div v-for="(item, key, ind) in TableData" :key="ind">
                                    <p v-if="item.length > 0" class="mt-6 mb-0 body-1 font-weight-bold">
                                        {{ key }} : </p>
                                    <v-data-table v-if="item.length" :search="search" :close-on-click="false"
                                        @click:row="SymbolMatchedDate" ref="dataTableRef" :loading="loader"
                                        mobile-breakpoint hide-default-footer height="350px" fixed-header
                                        :headers="HeaderFortable" :items="item" :items-per-page="TableData.length"
                                        style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;"
                                        class="elevation-0 rounded-lg mt-4">
                                        <template slot="group.header" slot-scope="{  }">
                                      
                                        </template>
                                        <template slot="group.summary" slot-scope="{ items  }">
                                            
                                            <td style="background-color: #F9FBFC">

                                                <p class="caption font-weight-bold  mb-0 ml-2">Total Trades:<span
                                                        class="caption font-weight-regular ml-2"> {{ items.length
                                                        }}</span>
                                                </p>


                                            </td>
                                            <td style="background-color: #F9FBFC"> </td>
                                          
                                            <td style="background-color: #F9FBFC"> </td>
                                            <td style="background-color: #F9FBFC"> </td>
                                            <td style="background-color: #F9FBFC"> </td>
                                            <td style="background-color: #F9FBFC"> </td>
                                            <td style="background-color: #F9FBFC"> </td>

                                            <td style="background-color: #f9fbfc">
                                                <p class="caption font-weight-bold  ml-2 mb-0">Realised : <span
                                                        class="font-weight-regular">
                                                        {{ realisedcal(items) }}
                                                    </span> </p>
                                            </td>
                                          
                                        </template>
                                        <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                            <span class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                            <p class="font-weight-normal mb-0 caption">{{ item.TRADE_DATE.split(" ")[1]
                                                +
                                                "/" +
                                                item.TRADE_DATE.split(" ")[2] + "/" + item.TRADE_DATE.split(" ")[3] }}
                                            </p>
                                        </template>

                                        <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.CLOSING_PRICE }}</span>
                                        </template>

                                      

                                        <template v-slot:[`item.total_buy_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_qty }}</span>
                                        </template>

                                        <template v-slot:[`item.total_buy_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_rate ?
                                                (item.total_buy_rate).toFixed(2) : 0 }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_buy_qty ?
                                                (item.total_buy_qty *
                                                    item.total_buy_rate).toFixed(2) : 0 }}</p>
                                        </template>

                                       

                                        <template v-slot:[`item.total_sell_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_qty }}</span>
                                        </template>

                                        <template v-slot:[`item.total_sell_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_rate }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_sell_qty ?
                                                (item.total_sell_qty *
                                                    item.total_sell_rate).toFixed(2) : 0 }}</p>

                                        </template>
                                        <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                        </template>
                                        <template v-slot:[`item.remainingbprice`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                        </template>

                                        <template v-slot:[`item.remainingqty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                        </template>

                                        <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                            <span
                                                :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0, 'black--text': item.unrealisedpnl == 0 }"
                                                class="font-weight-regular caption ">{{ item.unrealisedpnl ?
                                                    (item.unrealisedpnl).toFixed(2) : 0 }}</span>
                                        </template>
                                        <template v-slot:[`item.realisedpnl`]="{ item }">
                                            <span
                                                :class="{ 'green--text': item.total_realised_pnl > 0, 'red--text': item.total_realised_pnl < 0, 'black--text': item.total_realised_pnl == 0 }"
                                                class="font-weight-regular caption">{{ item.total_realised_pnl ?
                                                    (item.total_realised_pnl).toFixed(2) : 0 }}</span>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-col class="mx-auto pa-15 mt-5">
                                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                                    height="" />
                                                <p class="font-weight-bold black--text">No data available</p>
                                            </v-col>
                                        </template>
                                    </v-data-table>
                                </div> -->

                            </v-tab-item>
                            <v-tab-item>
                                <v-card class="pl-4 pr-4" outlined
                                    style=" height : 125px;width : 100%;overflow-x: scroll">
                                    <div v-if="loader == false" class="ml-n3 mt-4 d-flex">
                                        <!-- <div id="cal-heatmap"></div> -->

                                        <v-card v-for="(key, val, ind) in calendermontharray" :key="ind" outlined
                                            class="ma-2 my-4 elevation-0" style="width: 110px; cursor: pointer;">

                                            <!-- :style="{ backgroundColor: Number(val.value) < 0 ? '#ffd4d4' : Number(val.value) > 0 ? '#cdfad1' : '#ededed' }" -->

                                            {{ key }}
                                            <!-- <p :style="{ color: Number(val.value) == 0 ? 'black' : 'black' }"
                                                class="mb-0 caption pl-2 pt-1 pr-2" style="width: 100px;"> {{
                                                    val.month
                                                }} {{
                                                    val.month == 'Jan' || val.month
                                                        == 'Feb' || val.month == 'Mar' ? ToDateForApi.split("/")[2] :
                                                        FromDateForApi.split("/")[2] }} </p>
                                            <p :style="{ color: Number(val.value) == 0 ? 'black' : 'black' }"
                                                class="mb-0 caption pl-2 pt-1 pr-2" style="width: 100px;">{{
                                                    val.value.toFixed(2) }} </p> -->


                                        </v-card>

                                    </div>
                                    <!-- <div v-else-if="loader == false" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div> -->
                                    <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                                    <div v-else class="text-center mt-4">
                                        <v-progress-circular :size="50" :width="4" color="#0037B7"
                                            indeterminate></v-progress-circular>
                                        <p class="mb-0 caption mt-4">Getting your data please wait...</p>
                                    </div>
                                    <!-- </v-overlay> -->
                                </v-card>
                                <v-data-table :close-on-click="false" @click:row="SymbolMatchedDate" ref="dataTableRef"
                                    :loading="loader" mobile-breakpoint hide-default-footer height="450px" fixed-header
                                    :headers="HeaderForMonthtable" :items="TableDataForMonth"
                                    :items-per-page="TableData.length"
                                    style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;"
                                    class="elevation-0 rounded-lg mt-8">


                                    <template v-slot:[`item.month`]="{ item }">
                                        <span class="font-weight-bold caption">{{ item.month }}</span>

                                    </template>

                                    <template v-slot:[`item.trades`]="{ item }">
                                        <span class="font-weight-regular caption">{{ item.trades }}</span>
                                    </template>

                                    <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->








                                    <template v-slot:[`item.value`]="{ item }">
                                        <span
                                            :class="{ 'green--text': item.value > 0, 'red--text': item.value < 0, 'black--text': item.value == 0 }"
                                            class="font-weight-regular caption">{{ item.value ?
                                                (item.value).toFixed(2) : 0 }}</span>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-col class="mx-auto pa-15 mt-5">
                                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                                height="" />
                                            <p class="font-weight-bold black--text">No data available</p>
                                        </v-col>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs-items>


                    </v-card>
                    <!-- :search="search" -->
                    <!-- {{ TableData.length }} -->
                    <!-- <a class="ml-1" href="/pnl" target="_blank">Classic pnl >>>>></a> -->



                </v-col>

            </div>
            <!-- mobile -->
            <div class="d-md-none">
                <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="mr-2" style="background-color: transparent;">
                        <v-list-item class="px-0 pt-0 mt-2">
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                    Trading Journal </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Track, Learn, and Grow - Your Trading Journey Logged.
                                </v-list-item-subtitle>
                                <!-- <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Old format
                                    P&L Reference
                                    Available <a href="/pnl">Click here</a>
                                </v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">





                        </v-list-item>

                    </v-list>


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
                    <div class="text-center pa-4">
                        <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                            Realised P&L</p>
                        <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                            class="mb-0"
                            style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                            {{ (RealishedPnl).toFixed(2) }}
                        </p>
                        <!-- {{ disrealised_pnl }} -->
                    </div>
                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Unrealised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                            class="mb-0 text-right"
                                            style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                            {{ (UnRealishedPnl).toFixed(2) }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters @click="chargerdialoge = true">
                                    <v-col cols="5">
                                        <p class="caption" @click="chargerdialoge = true"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Charges & taxes</p>
                                    </v-col>
                                    <v-col cols="7">

                                        <!-- <p :class="TaxesAndCharges > 0 ? 'green--text' : TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                            class="mb-0 text-right"
                                            style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                            {{ (TaxesAndCharges).toFixed(2) }}
                                        </p> -->
                                        <p class="mb-0 text-right red--text"
                                            style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                            {{ (TaxesAndCharges).toFixed(2) }}
                                        </p>


                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Net Realised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p :class="RealishedPnl - TaxesAndCharges > 0 ? 'green--text' : RealishedPnl - TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                            class="mb-0 text-right"
                                            style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                            {{ (RealishedPnl - TaxesAndCharges).toFixed(2) }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
                <div class="d-flex mt-4 mb-4">
                    <v-select label="Filled" :disabled="loader" :items="filterr" @change="filterseg('mobile')" dense rounded solo flat
                        v-model="filterdata_unpl" placeholder="filter" background-color="#F1F3F8" hide-details
                        class="mx-4 fildrop" style="max-width: 100%; font-size: 21px">

                        <template v-slot:selection="{ item }">
                            <span class="font-weight-medium caption">{{ item }}</span>
                        </template>
                    </v-select>

                </div>
                <v-menu :disabled="loader" class="" v-model="menuForDatePickerMobile" left :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field style="max-width: 100%;" class="  mx-4" solo rounded flat v-model="fromshow"
                            label="From date" dense color="black" background-color="#F1F3F8"
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-card class="pa-2">
                        <div>
                            <v-date-picker range :min="fromdate[0]" :max="maxval" color="black" v-model="fromdate"
                                @input="datemaxvalue('click')"></v-date-picker>
                            <!-- <v-date-picker  range color="black" v-model="fromdate" @change="getCalenderData()"></v-date-picker> -->
                        </div>
                        <!-- <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p> -->
                        <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'"
                            class="ml-2 caption mb-1 ">
                            <span class="red--text">*</span>Your are in {{ Number(maxval.split("-")[0]) -
                                1 }}-{{ maxval.split("-")[0] }} Financial year
                            <v-tooltip color="black" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span @click="reset" class="caption blue--text font-weight-bold"
                                        style="cursor: pointer" v-bind="attrs" v-on="on">
                                        Change
                                    </span>
                                </template>
                                <span>Click here to move the date forward for Financial year</span>
                            </v-tooltip>
                        </p>
                        <v-divider></v-divider>
                        <div class="d-flex mt-n2">
                            <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                            <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                :class="{ 'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur' }"
                                @click="GetDataWithYearSelector(0, 'cur')">Current FY</p>
                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                :class="{ 'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear' }"
                                @click="GetDataWithYearSelector(-1, 'lyear')">Last FY</p>

                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                :class="{ 'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0] }"
                                @click="GetDataWithYearSelector(-2, yearsarray[0])">{{ yearsarray[0] }}</p>
                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                :class="{ 'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1] }"
                                @click="GetDataWithYearSelector(-3, yearsarray[1])">{{ yearsarray[1] }}</p>
                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                :class="{ 'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2] }"
                                @click="GetDataWithYearSelector(-4, yearsarray[2])">{{ yearsarray[2] }}</p>
                            <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                        </div>
                    </v-card>

                </v-menu>
                <!-- P&L verified by Zebu -->
                <!-- <div v-if="TableData.length > 0" class="d-flex row  ml-5 mb-2">
                    <p class=" mb-3 mt-8" style="
                        font-weight: 450;
                        font-size: 14px;
                        color: #4b4b4b;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                        <v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
                        P&L verified by Zebu
                        
                    </p>
                    <div class="ml-auto">
                        <p class="mt-2 mr-7 mb-0 " style="font-size: 12px">Share to Everyone</p>
                        <div class="d-flex">
                            <v-switch color="black" @change="switchbtn" class="mt-1" v-model="agreeforshare" inset
                                dense></v-switch>
                            <v-btn class="mt-2 px-3" disabled rounded x-small v-if="!agreeforshare">
                                <v-icon class="" size="16">mdi mdi-share-variant</v-icon>
                            </v-btn>
                            <v-btn dark class="mt-2 px-3" rounded x-small v-else @click="sharetomobile">
                                <v-icon color="white" class="" size="16">mdi mdi-share-variant</v-icon>
                            </v-btn>

                        </div>
                    </div>
                </div> -->
                <v-card class=" mb-6" outlined style=" height : 100%;width : 100%;overflow-x: scroll">

<div v-if="loader == false" class="ml-n3 mt-4">

    <!-- <v-row class="mx-auto mt-6" style="width: 80%;">
        <v-col cols="3" class="pa-0">
            <p class=" "
                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                Realised P&L :<span
                    :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                    class="mb-0"
                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                    {{ (RealishedPnl).toFixed(2) }}
                </span></p>

        </v-col>
        <v-col cols="3" class="pa-0">
            <p class=" "
                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                Unrealised P&L :<span
                    :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                    class="mb-0"
                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                    {{ (UnRealishedPnl).toFixed(2) }}
                </span></p>

        </v-col>
        <v-col cols="3" class="pa-0">
            <p class=" "
                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                Charges & taxes :<span class="mb-0 red--text"
                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                    {{ (TaxesAndCharges).toFixed(2) }}
                </span></p>

        </v-col>
        <v-col cols="3" class="pa-0">
            <p class=" "
                style="font-weight:450;font-size: 14px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                Net Realised P&L : <span
                    :class="(RealishedPnl - TaxesAndCharges) > 0 ? 'green--text' : (RealishedPnl - TaxesAndCharges) < 0 ? 'red--text' : 'black--text'"
                    class="mb-0"
                    style="font-size: 12px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                    {{ ((RealishedPnl - TaxesAndCharges)).toFixed(2) }}
                </span></p>

        </v-col>
    </v-row> -->

    <!-- <div class="ml-1 mt-2 pa-4" id="cal-heatmap" style=""></div> -->
    <div class="d-flex pl-8" style="width: 100%;">
        <v-card v-for="(key, val, ind) in calendermontharray" :key="ind" outlined
            class="ma-2 mr-3 my-4 elevation-0" style="min-width:  100px;  "
            :style="{ backgroundColor: Number(key.value) < 0 ? '#ffd4d4' : Number(key.value) > 0 ? '#cdfad1' : '#ededed' }">



            <p :style="{ color: Number(val[0].value) == 0 ? 'black' : 'black' }"
                class="mb-0 caption pl-2 pt-1 pr-2" style="width: ">
                {{ val.substring(0, 3) }} {{
                    val == 'January' || val
                        == 'February' || val == 'March' ? ToDateForApi.split("/")[2] :
                        FromDateForApi.split("/")[2] }} <br>{{

                    (key.value).toFixed(2)
                }}</p>
            <!-- <p :style="{ color: Number(val[0].value) == 0 ? 'black' : 'black' }"
                class="mb-0 caption pl-2 pt-1 pr-2" style="width: 100px;">{{
                    val[0].value.toFixed(2) }} </p> -->


        </v-card>
    </div>
</div>
<!-- <div v-else-if="loader == false" class="text-center mt-4">
<img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
<p class="mb-0 caption mt-2">No data available</p>   
</div> -->
<!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
<div v-else class="text-center mt-4">
    <v-progress-circular :size="50" :width="4" color="#0037B7"
        indeterminate></v-progress-circular>
    <p class="mb-0 caption mt-4">Getting your data please wait...</p>
</div>
<!-- </v-overlay> -->
</v-card>
                <!-- <v-card class="" outlined style=" height : 260px; overflow-x: scroll;">
                    <div v-if="ChartValue.length > 0 && loader == false" class="pa-5">
                      

                        <div class="ml-1" id="cal-heatmap2"></div>

                    </div>
                    <div v-else-if="loader == false" class="text-center mt-4">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                        <p class="mb-0 caption mt-2">No data available</p>
                    </div>
                    
                    <div v-if="loader == true" class="text-center mt-6">
                        <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                        <p class="mb-0 caption mt-4">Getting your data please wait...</p>
                    </div>
                   
                </v-card> -->

                <!-- <v-card v-for="item in TableData" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <div class="d-flex ">
                        <span @click="SymbolMatchedDate(item, 'mobile')" class="font-weight-bold">

                            {{ item.SCRIP_SYMBOL }}

                            
                        </span>



                    </div>


                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                        <v-col>
                            <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Net Qty:
                                <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                    item.Updated_NETQTY
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BAMT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_buy_qty
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_buy_rate
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Realised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.realisedpnl
                                    }}</span>
                            </p>
                        </v-col>
                        <v-col class="text-end">
                          
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SAMT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_sell_qty
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.total_sell_rate
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Unealised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.unrealisedpnl

                                    }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card> -->
                <!-- {{ TableData }} -->
                <v-expansion-panels inset style="z-index: 0">
                    <v-expansion-panel v-for="(item, i) in TableData" :key="i">
                        <v-expansion-panel-header>{{ formatDate(item.name) }} ({{  item.val.length }})    <span class="overline text-right" :class="{
                                            'green--text': realisedcal(item.val) > 0,
                                            'red--text': realisedcal(item.val) < 0,
                                            'black--text': realisedcal(item.val) === 0
                                        }"> {{ realisedcal(item.val) }}</span> </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card v-for="iitem in item.val" :key="iitem.index" style="border-top: 5px #F1F3F8 solid;"
                                class="elevation-0  px-4 pt-2" width="100%">

                                <span class="font-weight-bold">

                                    {{ iitem.SCRIP_SYMBOL }}
                                    <v-chip v-if="iitem.Open_Qty" class="ma-2" small color="green"
                                    outlined> {{ iitem.Open_Qty }} @ {{ iitem.Open_Rate }} </v-chip>

                                    <!-- {{ item.COMPANY_CODE }} -->
                                    <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                                </span>
                                <v-divider class="mb-2 mt-2"></v-divider>
                                <v-row class="px-2">
                                    <v-col>
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Qty
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty:
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                iitem.BQTY ? iitem.BQTY : '-'
                                                }}</span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                iitem.BRATE ? iitem.BRATE : '-'
                                                }}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="text-end">
                                        <p v-if="iitem.Updated_NETQTY > 0" class="mb-0 body-2 font-weight-regular"
                                            style="color: green;">{{
                                            iitem.Updated_NETQTY }}</p>
                                        <p v-if="iitem.Updated_NETQTY < 0" class="mb-0 body-2 font-weight-regular"
                                            style="color: red;">
                                            {{
                                            iitem.Updated_NETQTY }}</p>
                                        <p v-if="iitem.Updated_NETQTY == 0" class="mb-0 body-2 font-weight-regular"
                                            style="color: #000000;">
                                            {{ iitem.Updated_NETQTY }}</p>

                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell
                                            Quantity: <span class="mb-0 body-2 font-weight-regular"
                                                style="color: #000000;">{{
                                                iitem.SQTY ? iitem.SQTY : '-' }}

                                            </span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                iitem.SRATE ? iitem.SRATE : '-'
                                                }}</span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>


            <!-- dialogue -->
            <v-bottom-sheet v-model="sheetforshare" inset>
                <v-sheet class="text-center" height="200px">
                    <v-card class="elevation-0">
                        <v-list-item-content class=" pt-5 pl-5">
                            <v-list-item-title class="text-start"
                                style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">Grab
                                your URL
                            </v-list-item-title>

                            <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Click to share
                                your
                                triumphant
                                journey.
                            </v-list-item-subtitle>

                        </v-list-item-content>
                        <v-divider></v-divider>

                        <div class="px-6 mt-3 mb-2">
                            <p class="mb-1 caption">Share this link Or copy link</p>

                        </div>
                        <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->
                        <div class="d-flex">

                            <v-text-field readonly label="Regular" solo rounded flat dense background-color="#F1F3F8"
                                v-model="copytext" style="width: 60px" class="  ml-6" height="36px"></v-text-field>
                            <v-btn @click="copyTextclick" class="ml-2  text-capitalize white--text" solo text rounded
                                flat dense style="background-color: black;"><v-icon size="18">mdi
                                    mdi-content-copy</v-icon></v-btn>
                            <v-btn @click="shareTweet" class="mr-5 ml-2 text-capitalize white--text" solo text rounded
                                flat dense style="background-color: black;"><img src="@/assets/xmark.svg" width="12px"
                                    class="ml-1" alt="Portfolio icon" /></v-btn>


                        </div>

                    </v-card>

                </v-sheet>
            </v-bottom-sheet>

            <v-dialog v-model="dialogForSymDetails" width="80%">

                <v-card height="600px" width="100%">
                    <v-toolbar class=" mb-1 px-1 toolbar-contant" elevation="0" color="white">
                        <v-list class="mr-2" style="background-color: transparent;">
                            <v-list-item class="px-0 pt-4">
                                <v-list-item-content class=" pt-0">
                                    <v-list-item-title class="text-start"
                                        style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                        {{ monthval.month ? `${monthval.month} Month Data` : 'Calender P&L' }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle class="text-start "
                                        style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                        and
                                        Loss of your
                                        trades.
                                    </v-list-item-subtitle>

                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogForSymDetails = false"><v-icon>mdi mdi-close</v-icon></v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-data-table ref="dataTableRef" mobile-breakpoint hide-default-footer height="520px" fixed-header
                        disable-sort :headers="HeaderFortable" :items="SymbolDataMatchedTableArray"
                        :items-per-page="SymbolDataMatchedTableArray.length"
                        style="  border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg ">


                        <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                            <span class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                            <p class="font-weight-normal mb-0 caption">{{ item.TRADE_DATE.split(" ")[1] + "/" +
                                item.TRADE_DATE.split(" ")[2] + "/" + item.TRADE_DATE.split(" ")[3] }}</p>
                        </template>

                        <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.CLOSING_PRICE }}</span>
                        </template>

                        <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                        <template v-slot:[`item.total_buy_qty`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.BQTY }}</span>
                        </template>

                        <template v-slot:[`item.total_buy_rate`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.BRATE ? (item.BRATE).toFixed(2) : 0
                                }}</span>
                            <p class="font-weight-regular caption mb-0">{{ item.BRATE ? (item.BQTY *
                                item.BRATE).toFixed(2) : 0 }}
                            </p>
                        </template>

                        <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                        <template v-slot:[`item.total_sell_qty`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.SQTY }}</span>
                        </template>

                        <template v-slot:[`item.total_sell_rate`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.SRATE }}</span>
                            <p class="font-weight-regular caption mb-0">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</p>

                        </template>
                        <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                        </template>
                        <template v-slot:[`item.remainingbprice`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                        </template>

                        <template v-slot:[`item.remainingqty`]="{ item }">
                            <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                        </template>

                        <template v-slot:[`item.unrealisedpnl`]="{ item }">
                            <span
                                :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0, 'black--text': item.unrealisedpnl == 0 }"
                                class="font-weight-regular caption ">{{ item.unrealisedpnl ?
                                    (item.unrealisedpnl).toFixed(2) : 0 }}</span>
                        </template>
                        <template v-slot:[`item.realisedpnl`]="{ item }">
                            <span
                                :class="{ 'green--text': item.realisedpnl > 0, 'red--text': item.realisedpnl < 0, 'black--text': item.realisedpnl == 0 }"
                                class="font-weight-regular caption">{{ item.realisedpnl ? (item.realisedpnl).toFixed(2)
                                    : 0
                                }}</span>
                        </template>
                        <template v-slot:no-data>
                            <v-col class="mx-auto pa-15">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                                <p class="font-weight-bold black--text">No data available</p>
                            </v-col>
                        </template>
                    </v-data-table>
                </v-card>
            </v-dialog>
            <v-bottom-sheet v-model="dialogForSymDetailsMobile" height="600px">

                <v-card height="600px">
                    <v-toolbar class=" mb-1 px-2  toolbar-contant" elevation="0" color="white">
                        <v-list class="pb-0" style="background-color: transparent;">
                            <v-list-item class=" pt-0 px-0">

                                <v-list-item-content class=" pt-0 mt-2">
                                    <v-list-item-title class="text-start"
                                        style="color: #000000;font-size: 20px;font-weight:559;line-height:20px;letter-spacing:-1px">
                                        Calender P&L </v-list-item-title>

                                    <v-list-item-subtitle class="text-start pt-1"
                                        style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                        and
                                        Loss of your
                                        trades.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-spacer></v-spacer>

                        <v-list class=" pt-0" style="background-color: transparent;">
                            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">





                            </v-list-item>

                        </v-list>


                    </v-toolbar>
                    <v-divider class=" mx-2"></v-divider>
                    <v-card v-for="item in SymbolDataMatchedTableArray" :key="item.index"
                        style="border-top: 5px #F1F3F8 solid;" class="elevation-0  px-4 pt-2" width="100%">

                        <div class="d-flex ">
                            <span class="font-weight-bold">

                                {{ item.SCRIP_SYMBOL }}

                                <!-- {{ item.COMPANY_CODE }} -->
                                <!-- <span class="caption font-weight-medium">({{
                            desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                            </span>
                            <p class="font-weight-normal mb-0 ml-2 caption">

                                {{ item.TRADE_DATE.split(" ")[1] + "/" + item.TRADE_DATE.split(" ")[2] + "/"
                                    + item.TRADE_DATE.split(" ")[3] }}

                                <!-- {{ item.COMPANY_CODE }} -->
                                <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                            </p>



                        </div>


                        <v-divider class="mb-2 mt-2"></v-divider>
                        <v-row class="px-2">
                            <v-col>
                                <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Net
                                    Qty: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.Updated_NETQTY
                                        }}</span></p>
                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Amt: <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.BAMT
                                        }}</span>
                                </p>
                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.total_buy_qty
                                        }}</span>
                                </p>
                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.total_buy_rate
                                        }}</span>
                                </p>
                                <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Realised Pnl : <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.realisedpnl
                                        }}</span>
                                </p>
                            </v-col>
                            <v-col class="text-end">
                                <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_QUANTITY
                                    }}</span>
                            </p> -->
                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Amt: <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.SAMT
                                        }}</span>
                                </p>
                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.total_sell_qty
                                        }}</span>
                                </p>
                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.total_sell_rate
                                        }}</span>
                                </p>
                                <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Unealised Pnl : <span
                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                            item.unrealisedpnl

                                        }}</span>
                                </p>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-bottom-sheet>
        </v-container>
        <v-dialog v-model="dialogcopytext" width="500">


            <v-card>
                <v-list-item-content class=" pt-5 pl-5">
                    <v-list-item-title class="text-start"
                        style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">Grab
                        your URL
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-start pt-1"
                        style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Click to share your
                        triumphant
                        journey.
                    </v-list-item-subtitle>
                    <v-btn absolute right top icon color="black" @click="dialogcopytext = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-content>
                <v-divider></v-divider>

                <div class="px-6 mt-3 mb-2">
                    <p class="mb-1 caption">Share this link Or copy link</p>

                </div>
                <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->
                <div class="d-flex">

                    <v-text-field readonly label="Regular" solo rounded flat dense background-color="#F1F3F8"
                        v-model="copytext" style="width: 60px" class="  ml-6" height="36px"></v-text-field>
                    <v-btn @click="copyTextclick" class="ml-2  text-capitalize white--text" solo text rounded flat dense
                        style="background-color: black;"><v-icon size="18">mdi mdi-content-copy</v-icon></v-btn>
                    <v-btn @click="shareTweet" class="mr-5 ml-2 text-capitalize white--text" solo text rounded flat
                        dense style="background-color: black;"><img src="@/assets/xmark.svg" width="12px" class="ml-1"
                            alt="Portfolio icon" /></v-btn>


                </div>

            </v-card>

        </v-dialog>

    </v-app>
</template>

<script>
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import 'cal-heatmap/cal-heatmap.css';
import { apiurl } from '../../../Api.js'
import { apidailypnl } from "../../../Api.js";

// import { apiurl } from '../../../Api.js'
// import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
// import Profit_Loss from './Profit_Loss.vue';
export default {
    name: 'HeatmapComponent',
    data() {
        return {
            calendermontharray: [],
            ChartValue: [],
            tab: null,
            HeaderFortableForexpand: [
                {
                    text: 'Symbol',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                    width: '28%'

                },
                { text: 'Buy Qty', value: '', align: 'right', width: '9%' },
                { text: 'Buy Rate', value: '', width: '9%', align: 'right' },
                { text: 'Sell Qty', value: '', width: '9%', align: 'right' },
                { text: 'Sell Rate', value: '', width: '9%', align: 'right' },
                { text: 'Net Qty', value: '', width: '9%', align: 'right' },
                // { text: 'Net Rate', value: '', width: '9%', align: 'right' },
                { text: 'Close price', value: '', width: '9%', align: 'right' },
                { text: 'last', value: 'count', width: '15%', align: 'right' },
                { text: '', value: 'data-table-expand' },
                { text: 'Realised P&L', value: 'real', width: '15%', align: 'right' },

            ],
            HeaderFortableForexpandinSide: [
                {
                    text: 'Symbol',
                    align: 'start',
                    sortable: false,
                    value: 'SCRIP_SYMBOL',

                },
                { text: 'Buy Qty', value: 'BQTY', align: 'right', },
                { text: 'Buy Rate', value: 'BRATE', align: 'right' },
                { text: 'Sell Qty', value: 'SQTY', align: 'right' },
                { text: 'Sell Rate', value: 'SRATE', align: 'right' },
                { text: 'Net Qty', value: 'Updated_NETQTY', align: 'right' },
                // { text: 'Net Rate', value: '', width: '9%', align: 'right' },
                { text: 'Close price', value: 'CLOSING_PRICE', align: 'right' },
                { text: 'Realised P&L', value: 'realisedpnl', align: 'right' },
                // { text: 'last', value: 'last',  align: 'right' },
            ],
            fromshow: '',
            filterdata_unpl: 'Equity',
            agreeforshare: false,
            yearsarray: [],
            cal: null,
            sheetforshare: false,
            shareresdata: [],
            uniqueCode: false,
            highlightyearsValue: 'cur',
            dialogForSymDetails: false,
            dialogForSymDetailsMobile: false,
            dialogcopytext: false,
            TableDataForMonth: [],
            fromdate: [],
            maxval: '',
            postalready: null,
            menuForDatePicker: false,
            menuForDatePickerMobile: false,
            postalreadystats: '',
            monthval: {},
            expanded: [],

            monthArray: [
                { month: "Apr", fullname: 'April', array: [] },
                { month: "May", fullname: 'May', array: [] },
                { month: "Jun", fullname: 'June', array: [] },
                { month: "Jul", fullname: 'July', array: [] },
                { month: "Aug", fullname: 'August', array: [] },
                { month: "Sep", fullname: 'September', array: [] },
                { month: "Oct", fullname: 'October', array: [] },
                { month: "Nov", fullname: 'November', array: [] },
                { month: "Dec", fullname: 'December', array: [] },
                { month: "Jan", fullname: 'January', array: [] },
                { month: "Feb", fullname: 'February', array: [] },
                { month: "Mar", fullname: 'March', array: [] }],

            TaxesAndCharges: 0,
            UnRealishedPnl: 0,
            RealishedPnl: 0,
            FromDateForApi: '',
            copytext: '',
            ToDateForApi: '',
            susertoken: '',
            actid: '',
            cname: '',
            minval: '',
            loader: true,
            coloralert: '',
            alert: false,
            rangeColr: ['#ff6e6e', '#5aae61'],
            rangeValue: [-0.01, 0.01],
            Nodata: false,
            msg: '',
            singleExpand: true,
            HeaderFortable: [
                { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont", width: '250px' },
                { text: 'Buy qty', value: 'total_buy_qty', align: 'right', class: "headerfont" },
                // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
                // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
                { text: 'Buy rate', value: 'total_buy_rate', align: 'right', class: "headerfont" },
                { text: 'Sell qty', value: 'total_sell_qty', align: 'right', class: "headerfont" },
                // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
                { text: 'Sell rate', value: 'total_sell_rate', align: 'right', class: "headerfont" },
                { text: 'Net Qty', value: 'Updated_NETQTY', align: 'right', class: "headerfont" },
                { text: 'Close Price', value: 'CLOSING_PRICE', align: 'right', class: "headerfont" },
                // { text: 'Unrealisedpnl', value: 'unrealisedpnl', align: 'right', class: "headerfont" },
                { text: 'Realisedpnl', value: 'realisedpnl', align: 'right', class: "headerfont" },
                // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            search: null,
            HeaderForMonthtable: [

                { text: 'Month', value: 'month', align: 'start', class: "headerfont" },
                { text: 'No of trades', value: 'trades', align: 'right', class: "headerfont" },
                { text: 'Realisedpnl', value: 'value', align: 'right', class: "headerfont" },
                // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            TableData: [],
            // filterr: ['All', 'Equity', 'Fno', 'Commodity', 'Currency'],
            filterr: ['Equity', 'Fno', 'Commodity', 'Currency'],
            SymbolDataMatched: [],
            SymbolDataMatchedTableArray: [],
            StatArray: [],
            username: '',
            // agreeforshare: false,
        };
    },
    computed: {
        reversedData() {
            return Object.fromEntries(Object.entries(this.TableData).reverse());
        }
    },
    methods: {

        sortedTableData(val) {
            return [...val].sort((a, b) => new Date(b.TRADE_DATE) - new Date(a.TRADE_DATE));
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        toggleExpanded(item) {
            // console.log("fgfg", item)
            const index = this.expanded.indexOf(item);
            if (index === -1) {
                this.expanded = this.singleExpand ? [item] : [...this.expanded, item];
            } else {
                this.expanded = this.expanded.filter((el) => el !== item);
            }
        },
        realisedcal(item) {
            // console.log(item);

            var val = 0
            for (let i = 0; i < item.length; i++) {
                const element = item[i];
                val += element.realisedpnl
            }
            return val.toFixed(2)
        },
        sharetomobile() {
            // alert('kjghkjh')
            this.sheetforshare = true
        },
        copyTextclick() {
            navigator.clipboard.writeText(this.copytext);
            this.dialogcopytext = false
            this.sheetforshare = false
            this.alert = true,
                this.coloralert = 'success'
            this.msg = 'URL Copied'

            setTimeout(() => {
                this.alert = false,
                    this.msg = ''

            }, 3000);
        },
        switchbtn() {
            // alert('jhvh')
            // this.agreeforshare = !this.agreeforshare
            //console.log(this.agreeforshare);
            if (this.agreeforshare == true) {
                // console.log(this.postalready, this.agreeforshare);

                if (this.postalready == false) {
                    // console.log('cond 1');
                    if (this.filterdata_unpl == 'Equity') {
                        var SegmentValue = 'NSE_CASH,BSE_CASH'
                    } else if (this.filterdata_unpl == 'Fno') {
                        SegmentValue = 'NSE_FNO,BSE_FNO'
                    } else if (this.filterdata_unpl == 'Commodity') {
                        SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
                    } else if (this.filterdata_unpl == 'Currency') {
                        SegmentValue = 'CD_NSE,CD_BSE,CD_MCX,CD_USE'
                    } else if (this.filterdata_unpl == 'All') {
                        SegmentValue = 'NSE_CASH,BSE_CASH,NSE_FNO,BSE_FNO,MCX,NCDEX,NSE_COM,BSE_COM,CD_NSE,CD_BSE,CD_MCX,CD_USE'
                    }
                    // this.postalreadyucode = ''
                    const axios = require('axios');
                    let data = JSON.stringify({
                        "cc": this.actid,
                        "from": this.fromshow.split("_to_")[0],
                        "to": this.fromshow.split("_to_")[1],
                        "sharing": this.agreeforshare,
                        "response": this.shareresdata,
                        "Unique_Code": "",
                        "uname": this.username,
                        "segment": SegmentValue

                    });
                    let axiosthis = this
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        // url: 'http://192.168.5.198:5050/sharenew',
                        url: `${apiurl}/sharenew`,

                        headers: {
                            'Content-Type': 'application/json',
                            'clientid': this.actid,
                            'Authorization': this.susertoken
                        },
                        data: data
                    };
                    axios.request(config)
                        .then((response) => {
                            // //console.log(JSON.stringify(response.data));
                            let res = response.data.data.uq_code ? response.data.data : []
                            // console.log(response.data.data[0].uq_code);

                            let val = res.uq_code
                            //console.log(val);
                            axiosthis.postalreadyucode = val
                            axiosthis.uniqueCode = val
                            axiosthis.postalready = true
                            // https://reports-cf9df.web.app/Profit_Loss?uid=${actid}&token=${susertoken}"

                            axiosthis.copytext = `${apidailypnl}/dailypnl?ucode=${axiosthis.postalreadyucode}`
                            // axiosthis.copytext = `http://localhost:8080/dailypnl?ucode=${axiosthis.postalreadyucode}`
                            // axiosthis.copytext = `http://192.168.5.117:8080/dailypnl?ucode=${axiosthis.postalreadyucode}`

                        })
                        .catch((error) => {
                            console.log(error);
                            if (error.message == 'Network Error') {
                                axiosthis.msg = error.message
                                axiosthis.loader = false
                                axiosthis.alert = true
                                axiosthis.coloralert = 'error'
                                axiosthis.agreeforshare = false
                                setTimeout(() => {
                                    axiosthis.alert = false
                                    this.msg = ''
                                }, 5000);
                                // alert(error.message)
                            }
                        });
                } else if (this.postalready == true) {
                    // console.log('cond 2');

                    const axios = require('axios');
                    let data = JSON.stringify({
                        "cc": '',
                        "from": '',
                        "to": '',
                        "sharing": this.agreeforshare,
                        "response": '',
                        "Unique_Code": this.postalreadyucode,
                        "uname": '',
                        "segment": ''

                    });
                    let axiosthis = this
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        // url: 'http://192.168.5.198:5050/sharenew',
                        url: `${apiurl}/sharenew`,

                        headers: {
                            'Content-Type': 'application/json',
                            'clientid': this.actid,
                            'Authorization': this.susertoken
                        },
                        data: data
                    };

                    axios.request(config)
                        .then((response) => {
                            // //console.log(JSON.stringify(response.data));
                            let res = response.data.data.uq_code ? response.data.data : []

                            let val = res.uq_code
                            //console.log(val);
                            axiosthis.postalreadyucode = val
                            axiosthis.uniqueCode = val
                            // axiosthis.uniqueCode = val
                            // // https://reports-cf9df.web.app/Profit_Loss?uid=${actid}&token=${susertoken}"

                            // axiosthis.copytext = `http://localhost:8080/dailypnl?ucode=${axiosthis.uniqueCode}`
                            // // axiosthis.copytext = `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
                        })
                        .catch((error) => {
                            console.log(error);
                            if (error.message == 'Network Error') {
                                this.msg = error.message
                                axiosthis.loader = false
                                axiosthis.alert = true
                                axiosthis.coloralert = 'error'

                                this.agreeforshare = false
                                setTimeout(() => {
                                    axiosthis.alert = false
                                    this.msg = ''
                                }, 5000);
                                // alert(error.message)
                            }
                        });
                }


            } else if (this.agreeforshare == false) {
                // console.log('cond 3');

                const axios = require('axios');
                let data = JSON.stringify({
                    "cc": '',
                    "from": '',
                    "to": '',
                    "sharing": this.agreeforshare,
                    "response": '',
                    "Unique_Code": this.postalreadyucode,
                    "uname": '',
                    "segment": ''


                });
                let axiosthis = this
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: 'http://192.168.5.198:5050/sharenew',
                    url: `${apiurl}/sharenew`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        // //console.log(JSON.stringify(response.data));
                        let res = response.data.data.uq_code ? response.data.data : []

                        let val = res.uq_code
                        //console.log(val);
                        axiosthis.postalreadyucode = val
                        axiosthis.uniqueCode = val
                        // // https://reports-cf9df.web.app/Profit_Loss?uid=${actid}&token=${susertoken}"

                        // axiosthis.copytext = `http://localhost:8080/dailypnl?ucode=${axiosthis.uniqueCode}`
                        // // axiosthis.copytext = `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.message == 'Network Error') {
                            this.msg = error.message
                            axiosthis.loader = false
                            axiosthis.alert = true
                            axiosthis.coloralert = 'error'

                            this.agreeforshare = false
                            setTimeout(() => {
                                axiosthis.alert = false
                                this.msg = ''
                            }, 5000);
                            // alert(error.message)
                        }
                    });

            }
        },
        shareTweet() {
            //console.log("Custom action: Tweet button clicked!");

            //   const twitterIntentUrl = "https://twitter.com/intent/tweet?text=custom+share+text&hashtags=example,demo&via=twitterdev&related=twitterapi,twitter";
            //   window.open(twitterIntentUrl, '_blank');
            const tweetUrl = "https://twitter.com/intent/tweet";
            // let urlfortweet = 'https://report.mynt.in/dailypnl?ucode=' + this.postalreadyucode
            let urlfortweet = 'http://profile.mynt.in/dailypnl?ucode=' + this.postalreadyucode
            const tweetParams = {
                // url : `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
                // url: "https://zebuetrade.com/",
                text: "Excited about my recent trading triumph using Zebu—profits surging! Skillful moves on the Zebu app have significantly boosted my success",
                hashtags: "Traders",
                via: "zebuetrade",
                related: "twitterapi,twitter",
                // url : `http://localhost:8080/dailypnl?ucode=${this.uniqueCode}`,
                url: urlfortweet,

                media:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
            };

            const queryString = Object.keys(tweetParams)
                .map(
                    (key) =>
                        encodeURIComponent(key) + "=" + encodeURIComponent(tweetParams[key])
                )
                .join("&");

            window.open(`${tweetUrl}?${queryString}`, "_blank");
        },
        SymbolMatchedDate(item, val) {
            // console.log(item,val);
            this.monthval = item
            this.SymbolDataMatchedTableArray = []
            if (val == 'mobile') {
                this.dialogForSymDetailsMobile = true
            } else {
                this.dialogForSymDetails = true

            }
            // for (let i = 0; i < this.SymbolDataMatched.length; i++) {

            if (item.month) {
                if (item.hint == 'Jan' || item.hint == 'Feb' || item.hint == 'Mar') {
                    for (let i = 0; i < this.TableData.length; i++) {
                        const element = this.TableData[i];
                        if (element.TRADE_DATE.includes(item.hint) && (element.TRADE_DATE).includes(this.ToDateForApi.split("/")[2])) {
                            this.SymbolDataMatchedTableArray.push(element)
                        }
                    }
                } else {
                    for (let i = 0; i < this.TableData.length; i++) {
                        const element = this.TableData[i];
                        if (element.TRADE_DATE.includes(item.hint) && (element.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            this.SymbolDataMatchedTableArray.push(element)
                        }
                    }
                }

                // console.log(this.SymbolDataMatchedTableArray, "march");

            } else if (this.SymbolDataMatched[item.SCRIP_SYMBOL]) {
                this.SymbolDataMatchedTableArray = this.SymbolDataMatched[item.SCRIP_SYMBOL]
            }
            // }
            // console.log(this.SymbolDataMatchedTableArray);
        },

        reset() {
            this.maxval = this.resetrange
            this.fromdate = []
            this.highlightyearsValue = ''
        },
        GetDataWithYearSelector(valuevalue, item) {
            // this.postalready = false
            this.agreeforshare = false
            this.postalready = false
            this.highlightyearsValue = item
            //console.log(this.highlightyearsValue);
            this.menuForDatePicker = false
            let valuedate = valuevalue
            this.loader = true
            this.menuForDatePickerMobile = false
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            var offset = valuedate * -1;
            var yearmount = ""
            var yearnext = ""
            if (mm < 4) {
                yearmount = yyyy - 1 - offset;
                yearnext = yyyy - offset;
            } else {
                yearmount = yyyy - offset;
                yearnext = yyyy + 1 - offset;
            }



            this.fromdate = []
            this.fromdate.push(yearmount + '-' + '04' + '-' + '01');
            this.loader = true
            this.fromdate.push(yearnext + '-03-31')
            this.fromshow = '01' + '/' + '04' + '/' + yearmount + '_to_' + '31/03/' + yearnext
            this.datemaxvalue()
            this.FromDateForApi = '01' + '/' + '04' + '/' + yearmount
            this.ToDateForApi = '31/03/' + yearnext
            this.DataGetApi()

        },
        filterseg(item) {
            // console.log( this.fromdate)
            if (item == 'mobile' && this.fromdate.length < 2) {
                this.menuForDatePickerMobile = true

            } else if (this.fromdate.length < 2) {
                this.menuForDatePicker = true
            } else {
                this.getCalenderData()
            }
        },
        datemaxvalue(item) {
            let valuefordate = this.fromdate
            //console.log(valuefordate);
            //    if (this.fromdate.length < 1) {
            //         this.fromdate = valuefordate
            //         //console.log(this.fromdate.length,this.fromdate)

            //    }else{
            //         this.fromdate = []
            //         this.fromdate = valuefordate
            // //console.log(valuefordate[0].split("-")[0])

            //    }

            // this.maxval = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
            // //console.log(valuefordate,this.maxval,typeof(Number(valuefordate.split("-")[0])))
            // //console.log(valuefordate[0])
            // //console.log(Number(valuefordate[1]))
            // if (condition) {

            // }
            if (Number(valuefordate[0].split("-")[1]) > 3) {
                this.maxval = `${Number(valuefordate[0].split("-")[0]) + 1}-03-31`

                // //console.log(this.maxval,"huhu");
                // if (item != 'mounted') {
                //     this.todate = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
                // }
                // //console.log(this.maxval,"1")
            }
            else {
                this.maxval = `${Number(valuefordate[0].split("-")[0])}-03-31`
                // //console.log(this.maxval,"2")
                // //console.log(this.maxval,"huhu");

                // if (item != 'mounted') {
                //         this.todate = `${Number(valuefordate.split("-")[0])}-03-31`
                // }
            }

            if (item == 'click' && valuefordate.length > 1) {
                this.getCalenderData()
                this.highlightyearsValue = ''
            }
        },
        getCalenderData() {
            this.Nodata = true
            this.menuForDatePicker = false
            this.menuForDatePickerMobile = false
            this.loader = true
            let dateString = this.fromdate[0]
            let dateObject = new Date(dateString);
            let year = dateObject.getFullYear();
            let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
            let day = dateObject.getDate();
            let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
            this.FromDateForApi = formattedDate
            let dateString1 = this.fromdate[1]
            let dateObject1 = new Date(dateString1);
            let year1 = dateObject1.getFullYear();
            let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
            let day1 = dateObject1.getDate();
            this.ToDateForApi = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
            this.fromshow = this.FromDateForApi + '_' + 'to' + '_' + this.ToDateForApi
            this.datemaxvalue()
            this.DataGetApi()

        },
        chart() {
            const data = [];
            for (let i = 0; i < this.ChartValue.length; i++) {
                data.push(this.ChartValue[i])
            }
            //console.log(data);
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            this.cal = new CalHeatmap();
            this.cal.paint({
                data: { source: data, x: "date", y: "score" },
                date: { start: new Date((this.fromdate[0]).split("-")[0] + '-' + '04' + '-01') },
                range: 12,
                scale: {

                    color:
                        { range: this.rangeColr, type: 'linear', interpolate: 'hsl', domain: this.rangeValue }
                    //     color: function (date, value, dayjsDate) {
                    //     //console.log(value, dayjsDate,date);
                    //     if (value > 0) {
                    //         return {
                    //             type: "quantize",
                    //             scheme: 'Greens',
                    //             domain: [-100000, 100000]
                    //         };
                    //     } else {
                    //         return {
                    //             type: "quantize",
                    //             scheme: 'Reds',
                    //             domain: [-100000, 100000]
                    //         };
                    //     }
                    // }
                },
                domain: {
                    type: 'month',
                    gutter: 10,
                    // label: {
                    //     position: 'top',
                    //     text: function (value) {
                    //         const monthName = new Date(value).toLocaleString('en-US', { month: 'long' });


                    //         if (typeof value === 'number' || value instanceof Date) {
                    //             const additionalValue = this.calendermontharray ? Math.abs(this.calendermontharray[monthName].value) > 100000 ? (((this.calendermontharray[monthName].value) / 100000).toFixed(2) + "L") : Math.abs(this.calendermontharray[monthName].value) > 1000 ? (((this.calendermontharray[monthName].value) / 1000).toFixed(2) + "K") : (this.calendermontharray[monthName].value).toFixed(2) : "";

                    //             console.log(this.calendermontharray[monthName].value);


                    //             return monthName.substring(0, 3) + "\n(" + (additionalValue ? " " + additionalValue : "") + ")";
                    //         }
                    //         return "Unknown";
                    //     }.bind(this)  // bind this if needed so that "this.calendermontharray" works properly

                    // }
                },

                subDomain: {
                    type: 'day',
                    radius: 2,
                    width: 20,
                    height: 20,

                },
                itemSelector: '#cal-heatmap',
                // itemSelector: '#cal-heatmap',

            },
                [
                    [
                        CalendarLabel,
                        {
                            position: 'left',
                            key: 'left',
                            text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                            textAlign: 'end',
                            width: 30,
                            padding: [0, 5, 0, 0],
                        },
                    ],
                    // [
                    //     LegendLite,
                    //     {
                    //     radius: 3,
                    //     itemSelector: '#cal-heatmap',
                    //     },
                    // ],
                    [
                        Tooltip,
                        {
                            text: function (date, value, dayjsDate) {
                                // console.log('valvalvalvalvalvalvalvlavla',dayjsDate ,date);
                                return value == null ? "" : `Realised P&L  ${value} <br> ${dayjsDate.format('LL')}`;
                            },
                        },
                    ],

                ]

            );

        },

        chartMobile() {
            const data = [];
            for (let i = 0; i < this.ChartValue.length; i++) {
                data.push(this.ChartValue[i])
            }
            //console.log(data);
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            this.cal = new CalHeatmap();
            this.cal.paint({
                data: { source: data, x: "date", y: "score" },
                date: { start: new Date((this.fromdate[0]).split("-")[0] + '-' + '04' + '-01') },
                //   verticalOrientation: true,
                range: 12,
                scale: {
                    color:
                        { range: this.rangeColr, type: 'linear', interpolate: 'hsl', domain: this.rangeValue }
                    //     color: function (date, value, dayjsDate) {
                    //     //console.log(value, dayjsDate,date);
                    //     if (value > 0) {
                    //         return {
                    //             type: "quantize",
                    //             scheme: 'Greens',
                    //             domain: [-100000, 100000]
                    //         };
                    //     } else {
                    //         return {
                    //             type: "quantize",
                    //             scheme: 'Reds',
                    //             domain: [-100000, 100000]
                    //         };
                    //     }
                    // }
                },
                domain: {
                    type: 'month',
                    // label: "MM",
                    gutter: 6
                },
                subDomain: {
                    type: 'day', radius: 2, width: 25,
                    // label: 'DD',
                    height: 25,
                },
                // itemSelector: '#cal-heatmap',
                itemSelector: '#cal-heatmap2',

            },
                [
                    [
                        CalendarLabel,
                        {
                            position: 'left',
                            key: 'left',
                            text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                            textAlign: 'end',
                            width: 30,
                            padding: [0, 5, 0, 0],
                        },
                    ],
                    [
                        Tooltip,
                        {
                            text: function (date, value, dayjsDate) {
                                // console.log(value, dayjsDate);
                                return value == null ? "" : `Realised P&L  ${value} <br> ${dayjsDate.format('LL')}`;

                            },
                        },
                    ],

                ]

            );
        },
        MountedDData() {
            this.susertoken = localStorage.getItem("usession");
            this.actid = localStorage.getItem("userid");
            this.username = localStorage.getItem("cname");
            this.yearlist()
            this.cname = localStorage.getItem("cname");
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            if (mm < 4) {

                var yearmount = yyyy - 1
            } else {
                yearmount = yyyy
            }

            this.FromDateForApi = '01' + '/' + '04' + '/' + yearmount;
            this.fromdate.push((yearmount) + '-' + '04' + '-' + '01')

            this.fromdate.push(yyyy + '-' + mm + '-' + dd)
            this.resetrange = yyyy + '-' + mm + '-' + dd
            // this.minval = yearmount - 2 +  '-' + '04' + '-' +'01'  ;
            this.ToDateForApi = dd + '/' + mm + '/' + yyyy;
            this.fromshow = (this.FromDateForApi) + '_' + 'to' + '_' + (this.ToDateForApi)
            this.postalready = false
            this.datemaxvalue()
            this.DataGetApi()

        },
        yearlist() {
            var today = new Date();
            let sevthdate = new Date(today);

            let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')

            let yyyysevthdate = sevthdate.getFullYear();
            if (mmsevthdate < 4) {
                var yearmount = yyyysevthdate - 1
            } else {
                yearmount = yyyysevthdate
            }
            var startYear = yearmount - 4;
            // this.yearis = yearmount;

            for (let year = yearmount; year >= startYear; year--) {
                // let yeararraytem = []

                this.yearsarray.push(year)
            }
            this.yearsarray = this.yearsarray.slice(2, 5)
            // //console.log(this.yearsarray)
        },
        DataGetApi() {
            this.getclientshare()
            this.monthArray = [
                { month: "Apr", value: 0, array: [] },
                { month: "May", value: 0, array: [] },
                { month: "Jun", value: 0, array: [] },
                { month: "Jul", value: 0, array: [] },
                { month: "Aug", value: 0, array: [] },
                { month: "Sep", value: 0, array: [] },
                { month: "Oct", value: 0, array: [] },
                { month: "Nov", value: 0, array: [] },
                { month: "Dec", value: 0, array: [] },
                { month: "Jan", value: 0, array: [] },
                { month: "Feb", value: 0, array: [] },
                { month: "Mar", value: 0, array: [] },
            ],
                this.calendermontharray = {

                    "April": { value: 0 },
                    "May": { value: 0 },
                    "June": { value: 0 },
                    "July": { value: 0 },
                    "August": { value: 0 },
                    "September": { value: 0 },
                    "October": { value: 0 },
                    "November": { value: 0 },
                    "December": { value: 0 },
                    "January": { value: 0 },
                    "February": { value: 0 },
                    "March": { value: 0 },
                };



            this.TaxesAndCharges = 0
            this.UnRealishedPnl = 0
            this.RealishedPnl = 0
            this.tab = 0
            this.TableData = []
            this.ChartValue = []
            this.StatArray = []
            if (this.filterdata_unpl == 'Equity') {
                var SegmentValue = 'NSE_CASH,BSE_CASH'
            } else if (this.filterdata_unpl == 'Fno') {
                SegmentValue = 'NSE_FNO,BSE_FNO'
            } else if (this.filterdata_unpl == 'Commodity') {
                SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
            } else if (this.filterdata_unpl == 'Currency') {
                SegmentValue = 'CD_NSE,CD_BSE,CD_MCX,CD_USE'
            } else if (this.filterdata_unpl == 'All') {
                SegmentValue = 'NSE_CASH,BSE_CASH,NSE_FNO,BSE_FNO,MCX,NCDEX,NSE_COM,BSE_COM,CD_NSE,CD_BSE,CD_MCX,CD_USE'
            }
            const axios = require("axios");
            let data = JSON.stringify({
                "cc": this.actid,
                "from": this.FromDateForApi,
                "to": this.ToDateForApi,
                "segment": SegmentValue
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${apiurl}/getJournalDiary`,
                // url: 'http://192.168.5.141:9003/getJournal',

                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    //console.log((response.data));
                    // axiosthis.ChartValue = response.data.journal
                    if (response.data != 'No Data') {
                        axiosthis.shareresdata = response.data
                        axiosthis.ChartValue = []
                        axiosthis.TableData = []
                        axiosthis.Nodata = false
                        axiosthis.loader = false
                        axiosthis.SymbolDataMatched = response.data.symbolarr
                        axiosthis.StatArray = response.data.summary
                        // let arrdata = response.data.Data;
                        // console.log(arrdata);
                        var dumm = []
                        // var tableData = []
                        //   for (let i = 0; i < response.data.Data.length; i++) {
                        //     if (response.data.Data[i].last == true) {
                        //         tableData.push(response.data.Data[i])
                        //     }
                        // }
                        Object.entries(response.data.Data).forEach(([key, value]) => {
                            console.log(key, 'finalvall');

                            dumm.push({ name: key, val: value })
                        });
                        console.log(axiosthis.TableData);
                        this.TableData = [...dumm].reverse();

                        // axiosthis.TableData = response.data.Data

                        // axiosthis.TableData = [...arrdata].sort((a, b) => new Date(a.TRADE_DATE) - new Date(b.TRADE_DATE));
                        // axiosthis.TableData.sort((a, b) => {
                        // if (a.TRADE_DATEDt < b.TRADE_DATEDt) return -1;
                        // if (a.TRADE_DATEDt > b.TRADE_DATEDt) return 1;
                        // return 0;
                        // });
                        //console.log('tableytable' ,axiosthis.TableData )
                        var RealishedPnl = 0
                        var UnRealishedPnl = 0
                        var TaxesAndCharges = 0

                        this.TableDataForMonth = []

                        // var valJan = 0
                        // var valJanCount = 0
                        // var valFeb = 0
                        // var valFebCount = 0
                        // var valMar = 0
                        // var valMarCount = 0
                        // var valApr = 0
                        // var valAprCount = 0
                        // var valMay = 0
                        // var valMayCount = 0
                        // var valJun = 0
                        // var valJunCount = 0
                        // var valJul = 0
                        // var valJulCount = 0
                        // var valAug = 0
                        // var valAugCount = 0
                        // var valSep = 0
                        // var valSepCount = 0
                        // var valOct = 0
                        // var valOctCount = 0
                        // var valNov = 0
                        // var valNovCount = 0
                        // var valDec = 0
                        // var valDecCount = 0
                        let dumpdata = Object.values(response.data.Data).flat();

                        console.log(dumpdata, 'dumm');


                        for (let i = 0; i < dumpdata.length; i++) {
                            let dateStr = dumpdata[i]['TRADE_DATE'];
                            let dateObj = new Date(dateStr);
                            let formattedDate = dateObj.toISOString().slice(0, 10);
                            var ProfValue = dumpdata[i]['realisedpnl']

                            let dateString = dumpdata[i]
                            let abbrevToFull = {
                                Jan: "January",
                                Feb: "February",
                                Mar: "March",
                                Apr: "April",
                                May: "May",
                                Jun: "June",
                                Jul: "July",
                                Aug: "August",
                                Sep: "September",
                                Oct: "October",
                                Nov: "November",
                                Dec: "December"
                            };
                            // Parse the TRADE_DATE using Date object
                            const tradeDate = new Date(dateString.TRADE_DATE);
                            // console.log(tradeDate, 'date');

                            // Get month abbreviation, date and year parts
                            // tradeDate.toUTCString() returns a similar string; you can also use getUTCMonth() for month index
                            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                            const tradeMonthAbbrev = months[tradeDate.getUTCMonth()]; // e.g., "Dec"

                            // const tradeYear = tradeDate.getUTCFullYear(); // e.g., 2024 

                            // Parse the year from ToDateForApi (assuming format like "MM/DD/YYYY")


                            // Check if the years match
                            // if (tradeMonthAbbrev) {
                            //     let monthEntry = this.monthArray.find(entry => entry.month === tradeMonthAbbrev);

                            //     if (!monthEntry) {
                            //         monthEntry = { month: tradeMonthAbbrev, array: [] };
                            //         this.monthArray.push(monthEntry);
                            //     }

                            //     monthEntry.array.push(dateString);


                            // }
                            // console.log(this.monthArray);

                            if (tradeMonthAbbrev) {
                                // Example: if you want to count for December
                                // if (tradeMonthAbbrev === "Dec") {
                                //     valDecCount = (typeof valDecCount !== "undefined" ? valDecCount : 0) + 1;
                                //     valDec = (typeof valDec !== "undefined" ? valDec : 0) + dateString.realisedpnl;
                                // }

                                // Now update the calendermontharray based on full month name
                                // We need to convert the abbreviated month to full month name if necessary.
                                // Mapping abbreviations to full names:


                                const fullMonthName = abbrevToFull[tradeMonthAbbrev];

                                if (this.calendermontharray[fullMonthName]) {
                                    this.calendermontharray[fullMonthName].value += dateString.realisedpnl;
                                }
                            }

                            // if ((dateString.TRADE_DATE).includes("Feb") && (dateString.TRADE_DATE).includes(this.ToDateForApi.split("/")[2])) {
                            //     valFebCount = valFebCount + 1

                            //     valFeb += dateString.realisedpnl
                            //     for (let o = 0; o < this.monthArray.length; o++) {
                            //         const element = this.monthArray[o];
                            //         if (element.month == (dateString.TRADE_DATE).includes("Feb")) {
                            //             element.array.push(dateString)
                            //         }
                            //     }

                            // }
                            // 
                            //  else if ((dateString.TRADE_DATE).includes("Mar") && (dateString.TRADE_DATE).includes(this.ToDateForApi.split("/")[2])) {
                            //     valMarCount = valMarCount + 1
                            //     valMar += dateString.realisedpnl
                            //     // console.log("march enter", dateString, dateString.realisedpnl);
                            // } else if ((dateString.TRADE_DATE).includes("Apr") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valAprCount = valAprCount + 1
                            //     valApr += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("May") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valMayCount = valMayCount + 1
                            //     valMay += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Jun") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valJunCount = valJunCount + 1
                            //     valJun += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Jul") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valJulCount = valJulCount + 1
                            //     valJul += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Aug") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valAugCount = valAugCount + 1
                            //     valAug += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Sep") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valSepCount = valSepCount + 1
                            //     valSep += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Oct") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valOctCount = valOctCount + 1
                            //     valOct += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Nov") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valNovCount = valNovCount + 1
                            //     valNov += dateString.realisedpnl

                            // } else if ((dateString.TRADE_DATE).includes("Dec") && (dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //     valDecCount = valDecCount + 1
                            //     valDec += dateString.realisedpnl

                            // }

                            //     let dateString = response.data.Data[i]

                            //     if ((dateString.TRADE_DATE).includes("Jan") && (this.dateString.TRADE_DATE).includes(this.ToDateForApi.split("/")[2])) {
                            //         // if (condition) {

                            //         // }
                            //         valJanCount = valJanCount + 1
                            //         valJan += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Feb") && (this.dateString.TRADE_DATE).includes(this.ToDateForApi.split("/")[2])) {
                            //         valFebCount = valFebCount + 1

                            //         valFeb += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Mar") && (this.dateString.TRADE_DATE).includes(this.ToDateForApi.split("/")[2])) {
                            //         valMarCount = valMarCount + 1
                            //         valMar += dateString.realisedpnl
                            //         console.log("march enter" ,dateString,dateString.realisedpnl);
                            //     } else if ((dateString.TRADE_DATE).includes("Apr") && (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valAprCount = valAprCount + 1
                            //         valApr += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("May")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valMayCount = valMayCount + 1
                            //         valMay += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Jun")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valJunCount = valJunCount + 1
                            //         valJun += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Jul")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valJulCount = valJulCount + 1
                            //         valJul += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Aug")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valAugCount = valAugCount + 1
                            //         valAug += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Sep")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valSepCount = valSepCount + 1
                            //         valSep += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Oct")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valOctCount = valOctCount + 1
                            //         valOct += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Nov")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valNovCount = valNovCount + 1
                            //         valNov += dateString.realisedpnl

                            //     } else if ((dateString.TRADE_DATE).includes("Dec")&&  (this.dateString.TRADE_DATE).includes(this.FromDateForApi.split("/")[2])) {
                            //         valDecCount = valDecCount + 1
                            //         valDec += dateString.realisedpnl

                            //     } 


                            if (ProfValue != 0) {
                                // console.log("iflaenter",ProfValue,i)
                                let num = dumpdata[i]['realisedpnl']

                                ProfValue = Number(parseFloat(num).toFixed(2))
                            } else {
                                ProfValue = ''
                                //console.log("elsela enter ",ProfValue)
                            }
                            axiosthis.ChartValue.push(
                                {
                                    date: formattedDate,
                                    score: ProfValue,
                                    realisedpnl: ProfValue
                                }
                            )
                            //     //console.log(ProfValue);

                            // TaxesAndCharges += response.data.journal[i]['total_bill_net'];

                        }

                        this.monthArray.reverse()





                        // if (valAprCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "April ",
                        //         "hint": "Apr",
                        //         "trades": valAprCount,
                        //         "value": valApr
                        //     })
                        // }
                        // if (valMayCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "May ",
                        //         "hint": "May",
                        //         "trades": valMayCount,
                        //         "value": valMay
                        //     })
                        // }
                        // if (valJunCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "June ",
                        //         "hint": "Jun",
                        //         "trades": valJunCount,
                        //         "value": valJun
                        //     })
                        // }
                        // if (valJulCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "July ",
                        //         "hint": "Jul",
                        //         "trades": valJulCount,
                        //         "value": valJul
                        //     })
                        // }
                        // if (valAugCount > 0) {

                        //     this.TableDataForMonth.push({
                        //         "month": "August ",
                        //         "hint": "Aug",
                        //         "trades": valAugCount,
                        //         "value": valAug
                        //     })
                        // }
                        // if (valSepCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "September ",
                        //         "hint": "Sep",
                        //         "trades": valSepCount,
                        //         "value": valSep
                        //     })
                        // }
                        // if (valOctCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "October ",
                        //         "hint": "Oct",
                        //         "trades": valOctCount,
                        //         "value": valOct
                        //     })
                        // }
                        // if (valNovCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "November ",
                        //         "hint": "Nov",
                        //         "trades": valNovCount,
                        //         "value": valNov
                        //     })
                        // }
                        // if (valDecCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "December ",
                        //         "hint": "Dec",
                        //         "trades": valDecCount,
                        //         "value": valDec
                        //     })
                        // }
                        // if (valJanCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "January",
                        //         "hint": "Jan",
                        //         "trades": valJanCount,
                        //         "value": valJan
                        //     })
                        // }
                        // if (valFebCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "February ",
                        //         "hint": "Feb",
                        //         "trades": valFebCount,
                        //         "value": valFeb
                        //     })
                        // }

                        // if (valMarCount > 0) {
                        //     this.TableDataForMonth.push({
                        //         "month": "March ",
                        //         "hint": "Mar",
                        //         "trades": valMarCount,
                        //         "value": valMar
                        //     })
                        // }



























                        // for (let y = 0; y < this.monthArray.length; y++) {
                        //     const element = this.monthArray[y];
                        //     if (element.month == 'Jan') {
                        //         element.value = valJan
                        //     } else if (element.month == 'Feb') {
                        //         element.value = valFeb
                        //     } else if (element.month == 'Mar') {
                        //         element.value = valMar
                        //     } else if (element.month == 'Apr') {
                        //         element.value = valApr
                        //     } else if (element.month == 'May') {
                        //         element.value = valMay
                        //     } else if (element.month == 'Jun') {
                        //         element.value = valJun
                        //     } else if (element.month == 'Jul') {
                        //         element.value = valJul
                        //     } else if (element.month == 'Aug') {
                        //         element.value = valAug
                        //     } else if (element.month == 'Sep') {
                        //         element.value = valSep
                        //     } else if (element.month == 'Oct') {
                        //         element.value = valOct
                        //     } else if (element.month == 'Nov') {
                        //         element.value = valNov
                        //     } else if (element.month == 'Dec') {
                        //         element.value = valDec
                        //     }
                        // }
                        TaxesAndCharges = response.data.total_charges
                        axiosthis.TaxesAndCharges = TaxesAndCharges

                        for (let i = 0; i < dumpdata.length; i++) {
                            const element = dumpdata[i];

                            // if (Number(element.NETQTY) === 0) {
                            RealishedPnl += Number(element.realisedpnl); // Add to realised pnl if NETQTY is 0
                            // } else {
                            UnRealishedPnl += Number(element.unrealisedpnl); // Add to unrealised pnl otherwise
                            // }


                        }

                        // Final Totals


                        axiosthis.RealishedPnl = RealishedPnl
                        axiosthis.UnRealishedPnl = UnRealishedPnl

                        //console.log(RealishedPnl,UnRealishedPnl,TaxesAndCharges);


                        // axiosthis.chart()
                        // setTimeout(() => {
                        if (!this.cal) {
                            //console.log('ififififif')
                            axiosthis.chart()
                            axiosthis.chartMobile()
                        } else {
                            axiosthis.cal.destroy();
                            axiosthis.chart()
                            axiosthis.chartMobile()
                            //console.log('elseslseelser')
                        }

                        // }, 6000);
                    } else {
                        // alert("dfghjk")
                        axiosthis.loader = false
                        axiosthis.chart()
                        axiosthis.chartMobile()

                        axiosthis.ChartValue = []

                        axiosthis.Nodata = true
                        axiosthis.TableData = []
                        axiosthis.TaxesAndCharges = 0
                        axiosthis.UnRealishedPnl = 0
                        axiosthis.UnRealishedPnl = 0
                    }
                    //console.log(axiosthis.ChartValue);

                })
                .catch((error) => {
                    console.log(error);
                    axiosthis.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                    axiosthis.coloralert = 'error'

                });
        },
        getclientshare() {
            const axios = require("axios");
            if (this.filterdata_unpl == 'Equity') {
                var SegmentValue = 'NSE_CASH,BSE_CASH'
            } else if (this.filterdata_unpl == 'Fno') {
                SegmentValue = 'NSE_FNO,BSE_FNO'
            } else if (this.filterdata_unpl == 'Commodity') {
                SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
            } else if (this.filterdata_unpl == 'Currency') {
                SegmentValue = 'CD_NSE,CD_BSE,CD_MCX,CD_USE'
            } else if (this.filterdata_unpl == 'All') {
                SegmentValue = 'NSE_CASH,BSE_CASH,NSE_FNO,BSE_FNO,MCX,NCDEX,NSE_COM,BSE_COM,CD_NSE,CD_BSE,CD_MCX,CD_USE'
            }
            let data1 = JSON.stringify({
                "cc": this.actid,
                "from": this.fromshow.split("_to_")[0],
                "to": this.fromshow.split("_to_")[1],
                "segment": SegmentValue
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: 'http://192.168.5.198:5050/getclientsharelist',
                url: `${apiurl}/getclientsharelist`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data1
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                    let postval = response.data.msg
                    let res = response.data.data ? response.data.data[0] : []
                    //console.log(res);
                    axiosthis.postalreadyucode = res.uq_code
                    axiosthis.postalreadystats = res.sharing
                    axiosthis.copytext = `${apidailypnl}/dailypnl?ucode=${axiosthis.postalreadyucode}`

                    // axiosthis.copytext = `http://localhost:8080/dailypnl?ucode=${axiosthis.postalreadyucode}`
                    // axiosthis.copytext = `http://192.168.5.117:8080/dailypnl?ucode=${axiosthis.postalreadyucode}`



                    axiosthis.uniqueCode = axiosthis.postalreadyucode
                    if (axiosthis.postalreadystats == 'True') {
                        axiosthis.agreeforshare = true
                        // axiosthis.postalready = true
                    } else if (axiosthis.postalreadystats == 'False') {
                        axiosthis.agreeforshare = false
                        axiosthis.postalready = false
                    }
                    //console.log(axiosthis.postalreadystats, axiosthis.postalreadyucode);
                    if (postval == 'No Data Available') {
                        axiosthis.postalready = false
                        axiosthis.agreeforshare = false


                    } else {
                        axiosthis.postalready = true

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    mounted() {
        this.MountedDData()
    }
};
</script>

<style lang="scss">
.basil {
    background-color: #FFFBE6 !important;
}

.basil--text {
    color: #020202 !important;
}

.v-tab {
    text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
    /* flex: 1 0 auto; */
    max-width: none !important;
}
</style>