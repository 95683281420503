<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">

    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
    v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
    <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
    Session <strong>{{ txt }}</strong> Kindly Login again.
    <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
  </v-snackbar>

    <div class="mx-3 px-1">
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Margin Trading Facility (MTF)</v-list-item-title>
                <!-- <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Allows you to sell your holdings with one-time authorisation.
                </v-list-item-subtitle> -->
            </v-list-item-content>
       </v-list-item>
        <!-- <p class="font-weight-bold fs-22 mb-0"> Margin Trading Facility (MTF)</p> -->
        <!-- <p class="subtitle-2 txt-666">You can close your account.</p> -->
      </div>
      <v-divider></v-divider>

      <v-card class="py-md-6 px-md-3 mb-6 mt-8" elevation="0" :outlined="$vuetify.breakpoint.smAndDown ? false : true"
        width="100%">
        <div class="d-flex">
        <p  class="subtitle-1 txt-666 font-weight-medium">
         Would you like to activate Margin Trading Facility (MTF) on your account
        </p>
        <div class="ml-auto">
          <div class="d-flex"> 
<div v-if="profileData.DDPI == 'Y'">
<v-chip label small style="background-color:green;border:1px solid green"> <span style="color:white">DDPI</span></v-chip>
</div>
<div v-if="profileData.DDPI == 'N'">
<v-chip label small style="background-color:red;border:1px solid red"> <span style="color:white">DDPI</span></v-chip>
</div>
<div class="ml-2" v-if="profileData.POA == 'Y'">
<v-chip label small style="background-color:green;border:1px solid green"> <span style="color:white">POA</span></v-chip>
</div>
<div class="ml-2" v-if="profileData.POA == 'N'">
<v-chip label small style="background-color:red;border:1px solid red"> <span style="color:white">POA</span></v-chip>
</div>
</div>
</div>
      </div>
        <v-row class="mt-2" no-gutters>
          <v-col>

            <div v-if="(profileData.DDPI == 'Y' || profileData.POA == 'Y') && (profileData.MTFCl != 'Y' && profileData.MTFClAuto != 'Y')">
          <div v-if="accountstatus != 'client_close'">
          <v-btn v-if="mobStatus.mtf_status != 'e-signed pending' &&
              mobStatus.mtf_status != 'e-signed completed'
              " :disabled="accountstatus == 'client_close'"
               :block="$vuetify.breakpoint.xsOnly ? true : false" elevation="0" color="black white--text" rounded
             @click="deactivEapi()">
              <span class="text-none px-8">Enable MTF</span>
            </v-btn>
          </div>
          <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          Enable MTF
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>
            </div>
            <div v-else>
<p class="red--text" v-if="profileData.MTFCl != 'Y' || profileData.MTFClAuto != 'Y'">
You need to enable DDPI before you can proceed with processing  MTF (Margin Trading Facility).</p>
            </div>
          </v-col>
        </v-row>

<div v-if="profileData.MTFCl == 'Y' && profileData.MTFClAuto == 'Y'">
        <span><v-chip label  style="
                              border: 1px solid #c1e7ba;
                              background: #ecf8f1;
                            "><span style="color: #43a833">MTF Enabled</span></v-chip></span>

</div>

        <v-card rounded="lg" v-if="mobStatus.mtf_status == 'e-signed pending'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Account activate Margin Trading Facility (MTF) is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
                digioEsign(
                  mobStatus.mtf_fileid,
                  mobStatus.mtf_client_email,
                  mobStatus.mtf_session
                )
               
                ">
                Click here esign</span>
              <span @click="editType = 'mtf',confirmation = true" class="mt-1  mr-2"
                style="color:red;cursor: pointer;float:right">Cancel request</span>
            </p>
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.mtf_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1 ">
            <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
             Your request for MTF enablement is currently being processed
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>





          </div>
          
        </v-card>

    
      </v-card>

   
    </div>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-dialog v-model="confirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-2">
        <v-card-title class="pr-2">Cancel request? <v-spacer></v-spacer>
          <v-btn icon @click="confirmation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <v-card-text>
          Are you sure you want to cancel your request?
        </v-card-text>
        <v-card-actions class="pb-4">


          <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign">
            <span class="text-none ">Proceed</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




    <v-dialog persistent v-model="deactivateConfirmation" max-width="400">
        <v-card outlined class="pa-4 rounded-lg" elevation="0">
          <v-toolbar class="mb-1 toolbar-contant" elevation="0" dense color="white">
            <p class="mb-0" style="
                color: #000;
                font-size: 18px;
                font-weight: 459;
                line-height: 100%;
                letter-spacing: -0.36px;
              ">
       
         
         Are you sure you want to Enable MTP?

            </p>
            <v-btn class="ml-auto"  icon small
              @click="(deactivateConfirmation = false)"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
      
          <v-row class="px-4 mb-3">
   
          

            <v-col cols="12" class="pb-0">
              <v-btn @click="deactivEapi()"  rounded block  
                class="text-none white--text font-weight-bold" color="#000">Proceed</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>





  </div>
</template>
<script src="https://app.digio.in/sdk/v11/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v11/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      snackbar:false,
      txt:"",
      blurLoader:false,
      deactivateConfirmation:false,
      fileid:"",
      profileData:[],
      name:"",
      client_code:'',
      closure_status:"",
      mobStatus:"",
      Succtext:'',
      errtext:'',
      errorSnackbar:false,
      successSnackbar:false,
      confirmation:false,
      clemail:'',
      mailid:'',
      session:'',
      mobStatus:"",
      file:null,
    email: null,
    radioBtn: false,
    nomineesubmitBtn: false,

    message: "H",
    accountstatus : ""
    };
  },
  
  methods: {

    rejectEsign() {

  //   var axiosThis = this;

      let data = JSON.stringify({
        "client_id": this.profileData.CLIENT_ID,
        "file_id": this.mobStatus.mtf_fileid,
        "type": 'mtf'
      });

      let config = {
        method: 'post',
        url: api.api_url + '/manual_cancel_request',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          this.confirmation = false
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == 'canceled successfully') {
            this.Succtext = 'Canceled successfully'
            this.successSnackbar = true
            this.fetchMobileStatus()
            // location.reload();
          } else {
            this.errtext = response.data.msg
            this.errorSnackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },



    deactivEapi() {

      this.editType = "mtf";
  
      this.blurLoader = true;
     
  
        let data = JSON.stringify({
          client_email:this.clemail,
          dp_code:this.profileData.CLIENT_DP_CODE,
          client_address:this.profileData.CL_RESI_ADD1 + ',' + this.profileData.CL_RESI_ADD2 + ',' + this.profileData.CL_RESI_ADD3,
          client_id: this.client_code,
          client_name: this.name,
      
        });

        let config = {
          method: "post",

          url: api.api_url + "/mtf",
          headers: {
            Authorization: this.tok,
            clientid: this.client_code,
            "Content-Type": "application/json",
          },
          data: data,
        };
        var axiosThis = this;

        axios
          .request(config)
          .then((response) => {
          if (response.data.msg == 'Success') {
              var responceReq = response.data;
              axiosThis.fetchMobileStatus()
          // axiosThis.digioEsign(
          //     responceReq.fileid,
          //     responceReq.mailid,
          //     responceReq.session
          //   );
     
      axiosThis.blurLoader = true;

           axiosThis.successSnackbar = true
           axiosThis.Succtext = response.data.msg
          
            }
            else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.msg;
 
      axiosThis.blurLoader = false;

             


            }
   
          })
          .catch((error) => {
            console.log(error);
            axiosThis.errorSnackbar = true;
              axiosThis.errtext = error
         
      axiosThis.blurLoader = false;

          });

    },
    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },




    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);

      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },
 
  

    digioEsign(file, email, session) {
    var axo = this;
    this.blurLoader = true;
  //   axo.editdialog = false;
    // console.log(file, email,session)
    // localStorage.setItem('fileid', this.digioFile)
    var options = {
      environment: "production",
      callback: function (t) {
    
        axo.blurLoader = false;

        if (axo.stata != undefined) {
          if (axo.stata.app_status == "e-signed pending") {
            axo.radioBtn = true;
            axo.nomineesubmitBtn = true;
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }
        } else {
          axo.radioBtn = false;
          axo.nomineesubmitBtn = false;
        }

        if (t.message == "Signing cancelled") {
      
          axo.filedownloadDatabase("failure", file);
          axo.fetchMobileStatus();

        } else if (t.message == "Signed Successfully") {
          // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
          // if(axo.editType == 'mobile_change') {
          axo.filedownloadDatabase("success", file);
          // }
          // if(axo.editType == 'email_change') {
          // axo.filedownloadDatabase("success", file);
          // }
          // if(axo.editType == 'address_change') {
          // axo.filedownloadDatabase("success", file);
          // }
        }

        if (t["hasOwnProperty"]("error_code")) {
          // document.getElementById("loading").style.display='none';
          //// console.log('Error Digio')
          //// console.log(t.message)
        } else {
          //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
          //successDigio();
          //// console.log(this.step, this.ekycStatus);
          setTimeout(function () {
            //code goes here
            window.location.reload();
          }, 4500);
        }
      },
    };

    var digio = new Digio(options);
    digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
    // digio.submit(this.digioFile, this.digioMail,);
    // console.log(file,email,session)
    digio.submit(file, email, session);
    // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

    // console.log(digio)
  },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: 'mtf',
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },


 
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    getAvailBal() {

      var axiosThis = this;
     

    
          axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
       
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
              // console.warn(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              // console.log(axiosThis.profileData)
           
    axiosThis.fetchMobileStatus()
         

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              axiosThis.clemail = axiosThis.profileData.CLIENT_ID_MAIL

              localStorage.setItem('clinet_name', axiosThis.name)

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

         
           
          

       
        
        
    },

    fetchMobileStatus() {
      this.mobStatus =[]
    var axiosThis = this;
    let data = JSON.stringify({
      client_id: this.profileData.CLIENT_ID,
    });

    let config = {
      method: "post",
      url: api.api_url + "/add_mob_email_stat",
      headers: {
        Authorization: this.tok,
        clientid: this.profileData.CLIENT_ID,
        "Content-Type": "application/json",
      },
      data: data,
    };
    // console.log("jhasdgajb",this.client_code,this.tok)
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        axiosThis.blurLoader = false;
        if (response.data.emsg == "token expired") {
              axiosThis.snackbar = true;
              axiosThis.txt = "Expired";
              // console.warn("Hs")
              localStorage.clear();
              sessionStorage.clear();
              setTimeout(function () {
                // axiosThis.$router.push("/");
                axiosThis.goSso();

                window.location.reload();
              }, 2000);
            }
            if (response.data.emsg == "invalid token") {
              axiosThis.snackbar = true;
              axiosThis.txt = "Expired";
              // console.warn("Hs")
              localStorage.clear();
              sessionStorage.clear();
              setTimeout(function () {
                // axiosThis.$router.push("/");
                axiosThis.goSso();

                window.location.reload();
              }, 2000);
            }
            if (response.data.emsg == "clientid not exist") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }

        axiosThis.blurLoader = false;
        axiosThis.mobStatus = response.data;
        if (
          axiosThis.mobStatus.mobile_status == "e-signed pending" ||
          axiosThis.mobStatus.address_status == "e-signed pending" ||
          axiosThis.mobStatus.email_status == "e-signed pending"
        ) {
          // document.getElementById("mobileedit").removeAttribute(":append-icon");
          axiosThis.mobileDisable = true;
          axiosThis.emailDisable = true;
          axiosThis.addressDisable = true;
        }
        if (
          axiosThis.mobStatus.address_status == "e-signed completed" ||
          axiosThis.mobStatus.address_status == "e-signed pending"
        ) {
          // document.getElementById("mobileedit").removeAttribute(":append-icon");
          axiosThis.addressDisable = true;
        }
        if (
          axiosThis.mobStatus.mobile_status == "e-signed completed" ||
          axiosThis.mobStatus.mobile_status == "e-signed pending"
        ) {
          // document.getElementById("mobileedit").removeAttribute(":append-icon");
          axiosThis.mobileDisable = true;
        }
        if (
          axiosThis.mobStatus.email_status == "e-signed completed" ||
          axiosThis.mobStatus.email_status == "e-signed pending"
        ) {
          // document.getElementById("mobileedit").removeAttribute(":append-icon");
          axiosThis.emailDisable = true;
        }
        if (
          axiosThis.mobStatus.income_status == "e-signed completed" ||
          axiosThis.mobStatus.income_status == "e-signed pending"
        ) {
          // document.getElementById("mobileedit").removeAttribute(":append-icon");
          axiosThis.yearlyIncomeDisable = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("jiji", vendorAcc, vendorToken);
    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
   
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
      
        this.getAvailBal(id);


      } else {
        this.goSso();
      }
    }

  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>