<template>
  <div class="no-xhide ml-10 mt-10">
    <div data-aos="fade-up" class="pos-rlt">
      <v-card outlined class="elevation-0 pa-8 rounded-xl ma-4">
        <p class="black--text fs-50 font-weight-bold mb-6">Refer your friends to Zebu</p>
        <p class="black--text headline">
          Get 20% of brokerage fees for trades made <br />
          by your friends! in every
        </p>
      </v-card>
    </div>
    <div class="">
      <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
        <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
        {{ snackmsgbar }}
        <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
      </v-snackbar>
      <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10" transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
        <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
        {{ snackmsgbar }}
        <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
      </v-snackbar>
      <div class="pa-md-4">
        <v-row>
          <v-col cols="12" md="6" class="pl-md-4">
            <v-card outlined width="100%" class="price-card-sty elevation-0 rounded-xl px-6 px-sm-8 py-md-6 pt-6 mb-8">
              <div class="d-md-inline-flex">
                <p class="font-weight-bold lh-28 fs-32 mb-md-0">1.</p>
                <div>
                  <p class="font-weight-bold lh-28 fs-24 ml-md-2"><span>Create your referral link, to easy share</span></p>
                  <p class="font-weight-light txt-444 ml-md-2 mb-7">Enter your client ID below to generate the refferal link. You can get your client ID from your dashboard.</p>

                  <v-text-field readonly outlined rounded prepend-inner-icon="mdi-account" dense id="content" block flat solo background-color="#ffffff" v-model="clientid" oninput="this.value = this.value.toUpperCase()" class="ref-field elevation-0 rounded-pill caption" label="Enter client code">
                    <!-- <template #prepend-inner> -->
                    <!-- <img src="@/assets/contactus/user-c-frame.svg" width="100%" class="mx-2"
                                                alt="search-icon"> -->
                    <!-- </template> -->
                    <template #append>
                      <v-btn v-if="btnenable == true" :loading="btnload" class="cursor-p" icon plain @click="clientid.length > 0 ? getrefferalUrl() : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M3.33337 10H16.6667M16.6667 10L11.6667 5M16.6667 10L11.6667 15" stroke="#2A2A2A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </v-btn>

                      <div v-if="btnenable == false" class="cursor-p" @click="copylink()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g clip-path="url(#clip0_251_10223)">
                            <path
                              d="M3.33333 9.99992C2.71207 9.99992 2.40145 9.99992 2.15642 9.89842C1.82971 9.7631 1.57015 9.50353 1.43482 9.17683C1.33333 8.9318 1.33333 8.62117 1.33333 7.99992V3.46659C1.33333 2.71985 1.33333 2.34648 1.47865 2.06126C1.60648 1.81038 1.81046 1.60641 2.06134 1.47858C2.34656 1.33325 2.71992 1.33325 3.46666 1.33325H7.99999C8.62125 1.33325 8.93188 1.33325 9.17691 1.43475C9.50361 1.57007 9.76318 1.82964 9.8985 2.15634C9.99999 2.40137 9.99999 2.712 9.99999 3.33325M8.13333 14.6666H12.5333C13.2801 14.6666 13.6534 14.6666 13.9386 14.5213C14.1895 14.3934 14.3935 14.1895 14.5213 13.9386C14.6667 13.6534 14.6667 13.28 14.6667 12.5333V8.13325C14.6667 7.38651 14.6667 7.01315 14.5213 6.72793C14.3935 6.47705 14.1895 6.27307 13.9386 6.14524C13.6534 5.99992 13.2801 5.99992 12.5333 5.99992H8.13333C7.38659 5.99992 7.01322 5.99992 6.72801 6.14524C6.47712 6.27307 6.27315 6.47705 6.14532 6.72793C5.99999 7.01315 5.99999 7.38651 5.99999 8.13325V12.5333C5.99999 13.28 5.99999 13.6534 6.14532 13.9386C6.27315 14.1895 6.47712 14.3934 6.72801 14.5213C7.01322 14.6666 7.38659 14.6666 8.13333 14.6666Z"
                              stroke="#2A2A2A"
                              stroke-width="1.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_251_10223">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="pl-md-4">
            <v-card outlined width="100%" class="price-card-sty elevation-0 rounded-xl px-6 px-sm-8 py-md-6 pt-6 mb-8">
              <div class="d-md-inline-flex">
                <p class="font-weight-bold lh-28 fs-32 mb-md-0">2.</p>
                <div>
                  <p class="font-weight-bold lh-28 fs-24 ml-md-2"><span>Refer your family and friends</span></p>
                  <p class="font-weight-light txt-444 ml-md-2">Get discount on brokerages by referring them with your referral link.</p>
                  <div class="d-none d-md-block">
                    <v-btn :disabled="btnenable" :href="sharetextweb + clientid" target="_blank" rel="noopener noreferrer" large color="#2A2A2A" class="elevation-0 rounded-pill mb-3 mt-3 ml-2 text-none brd-2">
                      <div class="mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_1307_10879)">
                            <path
                              d="M17 2.875C15.125 1 12.75 0 10 0C4.625 0 0.125 4.5 0.125 9.875C0.125 11.625 0.625 13.375 1.5 14.875L0 20L5.25 18.625C6.75 19.375 8.375 19.875 10 19.875C15.5 19.875 19.875 15.375 19.875 10C20 7.25 18.875 4.75 17 2.875ZM14.875 13.5C14.625 14.125 13.625 14.625 13.25 14.625C12.875 14.75 11.625 14.625 10.25 14C7.75 12.875 6.125 10.375 6 10.25C5.875 10.125 5 8.875 5 7.75C5 6.625 5.625 5.875 5.875 5.625C6.375 5.125 7.375 5.25 7.625 5.75C7.75 6.25 8.25 7.5 8.375 7.625C8.5 7.75 8.5 8.25 8.125 8.5C8 8.5 7.375 9 7.625 9.375C7.75 9.625 8.25 10.375 9 11.125C10 12 10.75 12.25 11 12.375C11.25 12.5 11.375 12.5 11.5 12.25C11.625 12.125 12.125 11.5 12.25 11.25C12.375 11 12.625 11 12.75 11.125C12.875 11.25 14.25 11.75 14.5 11.875C14.75 12.125 15 12.125 15 12.25C15.125 12.375 15.125 12.875 14.875 13.5Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1307_10879">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <span class="white--text">Refer on whatsapp</span>
                    </v-btn>

                    <v-btn :disabled="btnenable" @click="shareViaWebShare" large color="#2A2A2A" class="elevation-0 rounded-pill ml-2 text-none brd-2" outlined>
                      <span>More ways to share</span>
                    </v-btn>
                  </div>
                  <div class="d-md-none">
                    <v-btn :disabled="btnenable" block :href="sharetextweb + clientid" target="_blank" rel="noopener noreferrer" large color="#2A2A2A" class="elevation-0 rounded-pill mb-3 text-none brd-2">
                      <div class="mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_1307_10879)">
                            <path
                              d="M17 2.875C15.125 1 12.75 0 10 0C4.625 0 0.125 4.5 0.125 9.875C0.125 11.625 0.625 13.375 1.5 14.875L0 20L5.25 18.625C6.75 19.375 8.375 19.875 10 19.875C15.5 19.875 19.875 15.375 19.875 10C20 7.25 18.875 4.75 17 2.875ZM14.875 13.5C14.625 14.125 13.625 14.625 13.25 14.625C12.875 14.75 11.625 14.625 10.25 14C7.75 12.875 6.125 10.375 6 10.25C5.875 10.125 5 8.875 5 7.75C5 6.625 5.625 5.875 5.875 5.625C6.375 5.125 7.375 5.25 7.625 5.75C7.75 6.25 8.25 7.5 8.375 7.625C8.5 7.75 8.5 8.25 8.125 8.5C8 8.5 7.375 9 7.625 9.375C7.75 9.625 8.25 10.375 9 11.125C10 12 10.75 12.25 11 12.375C11.25 12.5 11.375 12.5 11.5 12.25C11.625 12.125 12.125 11.5 12.25 11.25C12.375 11 12.625 11 12.75 11.125C12.875 11.25 14.25 11.75 14.5 11.875C14.75 12.125 15 12.125 15 12.25C15.125 12.375 15.125 12.875 14.875 13.5Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1307_10879">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <span class="white--text">Refer on whatsapp</span>
                    </v-btn>

                    <v-btn :disabled="btnenable" @click="shareViaWebShare" block large color="#2A2A2A" class="elevation-0 rounded-pill mb-6 text-none brd-2" outlined>
                      <span>More ways to share</span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",

    clientid: "",
    btnenable: true,
    btnload: false,
    sharetextweb: "https://wa.me/?text=Click this link to open a Trading account with Zebu and Gain an unified Trading and Investment Experience. ",
  }),
  methods: {
    getrefferalUrl() {
      this.btnload = true;
      var respdata = "https://oa.mynt.in/?ref=";
      var config = {
        method: "get",
        url: "https://dwld.zebuetrade.com/gen?id=" + this.clientid,
        headers: {},
      };

      let axiosThis = this;
      axios(config)
        .then(function (response) {
          if (response.data.link != "Invalid") {
            axiosThis.clientid = respdata + axiosThis.clientid;
            axiosThis.btnenable = false;
            const element = document.getElementById("content");
            element.scrollIntoView();
          } else {
            axiosThis.clientid = null;
            axiosThis.btnenable = true;
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "#2A2A2A";
            axiosThis.snackmsgbar = "Invalid Client ID.";
          }
          axiosThis.btnload = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    nullClients() {
      this.clientid = null;
      this.btnenable = true;
      this.btnload = false;
    },

    shareViaWebShare() {
      navigator.share({
        title: "Zebu",
        text: "Zebu offers trading and investment opportunities. Create an account right away. ",
        url: this.clientid,
      });
    },
    copylink() {
      navigator.clipboard.writeText(this.clientid);
      this.snackbar = true;
      this.snackbarclr = "#2A2A2A";
      this.snackmsgbar = "Link as copied.";
    },
  },
  mounted() {
    this.clientid = localStorage.getItem("userid");
    this.getrefferalUrl();
  },
};
</script>

<style lang="scss">
.support-main-bg {
  background: linear-gradient(400deg, #003f9e 0, #005fec 68.23%, #005fec 97.4%) !important;
}

.refer-bar {
  /* background: linear-gradient(400deg, #003f9e 0, #005fec 68.23%, #005fec 97.4%) !important; */
  /* background: linear-gradient(400deg, #00D8B2 0, #00F27E 97.4%) !important; */
  /* background: linear-gradient(400deg, #9B1CFF 0, #B61CFF 97.4%) !important; */
  /* background: linear-gradient(400deg, #7F009E 0, #AF00EC 68.23%, #CB00EC 97.4%) !important */
  background-color: #01409f !important;
}
.no-xhide {
  overflow: hidden !important;
}

.pos-rlt {
  position: relative !important;
}

.pos-abs,
.pos-md-abs {
  position: absolute !important;
}

.pos-stk,
.pos-md-stk {
  position: sticky !important;
}

.pos-ini {
  position: initial !important;
}

.pos-fix {
  position: fixed !important;
}

.float-btn,
.snakbar-sty {
  position: fixed !important;
}

.float-btn {
  bottom: 32px;
  right: 32px;
  z-index: 1 !important;
}

.btm-0,
.map-body-crd {
  bottom: 0 !important;
}

.btm-32 {
  bottom: 32px !important;
}

.top-24 {
  top: 24px !important;
}

.top-8 {
  top: 8px !important;
}

.top-16 {
  top: 16px !important;
}

.rig-0 {
  right: 0 !important;
}

.white-space {
  white-space: pre-line !important;
}

.ws-p {
  white-space: pre !important;
}

.prd-btn-active {
  padding-bottom: 4px !important;
  border-bottom: 2px solid #000 !important;
  color: #000 !important;
}

.exp-sty,
.prd-btn-noactive {
  border-bottom: 0 !important;
}

.prd-btn-noactive {
  padding-bottom: 0 !important;
  color: #666 !important;
}

.mailto-txt,
.mailto-txt-adds:hover {
  font-weight: 700;
  color: #0037b7 !important;
}
</style>
