<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
            <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
                <!-- <v-switch x-small v-model="$vuetify.theme.dark" class="mt-n1 mr-2"></v-switch> -->
                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="mr-2" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Tax Report </v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Calender for data of profit and loss
                                        </v-list-item-subtitle>
                                       
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>
                            <v-menu  bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                        <v-list-item-title @click="downloadfun(item.title)">{{ item.title
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- <v-select label="Filled" :items="filterr" @change="filterseg()" dense
                                rounded
                            
                                        solo flat
                                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                                class="mr-2 mt-n3 fildrop" style="max-width: 140px; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select> -->
                            <v-menu class="" v-model="menuForDatePicker" left  :close-on-click="fromdate.length == 2"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :min="fromdate[0]" :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="getCalenderData()"></v-date-picker> -->
                                    </div>
                                    <!-- <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>Fsort
                                    </p> -->
                                    <p  v-if="fromdate != '' && fromdate != []  " class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur'}"  @click="GetDataWithYearSelector(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear'}"  @click="GetDataWithYearSelector(-1,'lyear')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0]}"  @click="GetDataWithYearSelector(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1]}"  @click="GetDataWithYearSelector(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2]}"  @click="GetDataWithYearSelector(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>
                                
                            </v-menu>
                           
                           
                           
                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->
                          
                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
                           
                        </v-toolbar>
                        <v-divider></v-divider>
                        <!-- <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class=" pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card  class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                         
                                        <v-divider  class="mt-2" style="border:solid px #000000"
                                            width="30px" color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                           

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="TaxesAndCharges > 0 ? 'green--text' : TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </p>
                                              
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                
                            </v-row>
                        </div> -->
                        <!-- <v-text-field style="width: 100%;" class="tophundraedmutual d-md-none mt-4 mr-5" height="36px"
                            background-color="#F1F3F8" label="Search symbols" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                        <!-- <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card @click="chargerdialoge = true" class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                        <div class="display-flex row pt-6 pl-2"> 
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0" style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col> 
                            </v-row>
                        </div> -->
                        
                        <!-- <v-card class="mt-8" outlined  style=" height : 160px;width : 100%;overflow-x: scroll">
                            <div v-if=" loader == false" class="ml-n3 mt-4">
                                 

                                <div class="ml-1" id="cal-heatmap"></div>
                                   
                             </div>
                            
                               
                            <div v-else class="text-center mt-4">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                 
                        </v-card> -->
                    </v-card>
                    <!-- :search="search" -->
                     <!-- {{ TableData.length }} -->
                     <v-tabs v-model="tabs" background-color="transparent" color="basil" >
                        <v-tab class="text-capitalize caption"  >
                            <v-badge v-if="TableData0.length != 0"
                                color="black"
                                :content="TableData0.length"
                                >
                                STCG
                            </v-badge>
                            <p v-else class="mb-0 caption">STCG </p>
                         </v-tab>
                        <v-tab class="text-capitalize caption" >
                            <v-badge v-if="TableData1.length != 0"
                                color="black"
                                :content="TableData1.length"
                                >
                                LTCG
                            </v-badge>
                            <p v-else class="mb-0 caption">LTCG </p>
                        </v-tab>
                        <v-tab class="text-capitalize caption" >
                            <v-badge v-if="TableData2.length != 0"
                                color="black"
                                :content="TableData2.length"
                                >
                                Intraday
                            </v-badge>
                            <p v-else class="mb-0 caption">Intraday </p>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs" class="mt-">
                        <v-tab-item>
                            <v-data-table :close-on-click="false" @click:row="SymbolMatchedDate" ref="dataTableRef"  :loading="loader" mobile-breakpoint
                                        hide-default-footer height="450px" fixed-header  :headers="HeaderFortable1"
                                        :items="TableData0" :items-per-page="TableData0.length"
                                        style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg  ">
                                        <template v-slot:[`item.total_stt`]="{ item }">
                                    <span  class="font-weight-regular caption">{{ item.total_stt }}</span>
                                </template>
                                        <template v-slot:[`item.Selldate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item['Selldate'] }}</span>
                                        </template>
                                        <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                            <span  class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                        </template>

                                        <template v-slot:[`item.Buydate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item['Buy date'] }}</span>
                                        </template>

                                        <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                        </template> -->

                                        <template v-slot:[`item.total_buy_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_qty }}</span>
                                        </template>

                                        <template v-slot:[`item.total_buy_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_rate ? (item.total_buy_rate).toFixed(2) : 0 }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_buy_qty ? (item.total_buy_qty * item.total_buy_rate).toFixed(2) : 0 }}</p>
                                        </template>

                                        <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                        </template> -->

                                        <template v-slot:[`item.total_sell_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_qty }}</span>
                                        </template>
                                        <template v-slot:[`item.LTCG`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_ltcg }}</span>
                                        </template>

                                        <template v-slot:[`item.STCG`]="{ item }">
                                            <span class="font-weight-regular caption">{{ (item.total_stcg).toFixed(2) }}</span>
                                        </template>
                                        
                                        <template v-slot:[`item.total_sell_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_rate }}</span>
                                            <p class="font-weight-regular caption mb-0">{{item.total_sell_qty ? (item.total_sell_qty * item.total_sell_rate).toFixed(2) : 0 }}</p>

                                        </template>
                                        <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                        </template>
                                        <template v-slot:[`item.remainingbprice`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                        </template>
                                        <template v-slot:[`item.High_31Jan`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.High_31Jan }}</span>
                                        </template>
                                        

                                        <template v-slot:[`item.remainingqty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                        </template>

                                        <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                            <span  :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0,'black--text': item.unrealisedpnl  == 0 }"  class="font-weight-bold caption "  >{{ item.unrealisedpnl ? (item.unrealisedpnl).toFixed(2) : 0 }}</span>  
                                        </template>
                                        <template v-slot:[`item.realisedpnl`]="{ item }">
                                            <span :class="{ 'green--text': item.total_realised_pnl > 0, 'red--text': item.total_realised_pnl < 0,'black--text': item.total_realised_pnl  == 0 }" class="font-weight-bold caption"  >{{ item.total_realised_pnl ? (item.total_realised_pnl).toFixed(2) : 0 }}</span>  
                                        </template>
                                        <template v-slot:no-data>
                                            <v-col class="mx-auto pa-15 mt-5">
                                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                                <p class="font-weight-bold black--text">No data available</p>
                                            </v-col>
                                        </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <v-data-table :close-on-click="false" @click:row="SymbolMatchedDate" ref="dataTableRef"  :loading="loader" mobile-breakpoint
                                        hide-default-footer height="450px" fixed-header  :headers="HeaderFortable2"
                                        :items="TableData1" :items-per-page="TableData1.length"
                                        style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg  ">

                                        <template v-slot:[`item.Selldate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item['Selldate'] }}</span>
                                        </template>
                                        <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                            <span  class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                        </template>

                                        <template v-slot:[`item.Buydate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item['Buy date'] }}</span>
                                        </template>

                                        <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                        </template> -->

                                        <template v-slot:[`item.total_buy_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_qty }}</span>
                                        </template>

                                        <template v-slot:[`item.total_buy_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_rate ? (item.total_buy_rate).toFixed(2) : 0 }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_buy_qty ? (item.total_buy_qty * item.total_buy_rate).toFixed(2) : 0 }}</p>
                                        </template>

                                        <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                        </template> -->

                                        <template v-slot:[`item.total_sell_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_qty }}</span>
                                        </template>
                                        <template v-slot:[`item.LTCG`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_ltcg }}</span>
                                        </template>
                                        <template v-slot:[`item.total_stt`]="{ item }">
                                    <span  class="font-weight-regular caption">{{ item.total_stt }}</span>
                                </template>
                                        <template v-slot:[`item.STCG`]="{ item }">
                                            <span class="font-weight-regular caption">{{ (item.total_stcg).toFixed(2) }}</span>
                                        </template>
                                        <template v-slot:[`item.total_sell_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_rate }}</span>
                                            <p class="font-weight-regular caption mb-0">{{item.total_sell_qty ? (item.total_sell_qty * item.total_sell_rate).toFixed(2) : 0 }}</p>

                                        </template>
                                        <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                        </template>
                                        <template v-slot:[`item.remainingbprice`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                        </template>
                                        <template v-slot:[`item.High_31Jan`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.High_31Jan }}</span>
                                        </template>
                                        

                                        <template v-slot:[`item.remainingqty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                        </template>

                                        <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                            <span  :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0,'black--text': item.unrealisedpnl  == 0 }"  class="font-weight-bold caption "  >{{ item.unrealisedpnl ? (item.unrealisedpnl).toFixed(2) : 0 }}</span>  
                                        </template>
                                        <template v-slot:[`item.realisedpnl`]="{ item }">
                                            <span :class="{ 'green--text': item.total_realised_pnl > 0, 'red--text': item.total_realised_pnl < 0,'black--text': item.total_realised_pnl  == 0 }" class="font-weight-bold caption"  >{{ item.total_realised_pnl ? (item.total_realised_pnl).toFixed(2) : 0 }}</span>  
                                        </template>
                                        <template v-slot:no-data>
                                            <v-col class="mx-auto pa-15 mt-5">
                                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                                <p class="font-weight-bold black--text">No data available</p>
                                            </v-col>
                                        </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <v-data-table :close-on-click="false" @click:row="SymbolMatchedDate" ref="dataTableRef"  :loading="loader" mobile-breakpoint
                                        hide-default-footer height="450px" fixed-header  :headers="HeaderFortable3"
                                        :items="TableData2" :items-per-page="TableData2.length"
                                        style="cursor: pointer; border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg  ">

                                        <template v-slot:[`item.Selldate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item['Selldate'] }}</span>
                                        </template>
                                        <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                            <span  class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                        </template>

                                        <template v-slot:[`item.Buydate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item['Buy date'] }}</span>
                                        </template>

                                        <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                        </template> -->

                                        <template v-slot:[`item.total_buy_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_qty }}</span>
                                        </template>
                                        <template v-slot:[`item.total_stt`]="{ item }">
                                    <span  class="font-weight-regular caption">{{ item.total_stt }}</span>
                                </template>
                                        <template v-slot:[`item.total_intraday`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_intraday }}</span>
                                        </template>
                                        <template v-slot:[`item.total_buy_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_buy_rate ? (item.total_buy_rate).toFixed(2) : 0 }}</span>
                                            <p class="font-weight-regular caption mb-0">{{ item.total_buy_qty ? (item.total_buy_qty * item.total_buy_rate).toFixed(2) : 0 }}</p>
                                        </template>

                                        <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                        </template> -->

                                        <template v-slot:[`item.total_sell_qty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_qty }}</span>
                                        </template>
                                        <template v-slot:[`item.LTCG`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_ltcg }}</span>
                                        </template>

                                        <template v-slot:[`item.STCG`]="{ item }">
                                            <span class="font-weight-regular caption">{{ (item.total_stcg).toFixed(2) }}</span>
                                        </template>
                                        <template v-slot:[`item.total_sell_rate`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_sell_rate }}</span>
                                            <p class="font-weight-regular caption mb-0">{{item.total_sell_qty ? (item.total_sell_qty * item.total_sell_rate).toFixed(2) : 0 }}</p>

                                        </template>
                                        <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                        </template>
                                        <template v-slot:[`item.remainingbprice`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                        </template>
                                        <template v-slot:[`item.High_31Jan`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.High_31Jan }}</span>
                                        </template>
                                        

                                        <template v-slot:[`item.remainingqty`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                        </template>

                                        <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                            <span  :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0,'black--text': item.unrealisedpnl  == 0 }"  class="font-weight-bold caption "  >{{ item.unrealisedpnl ? (item.unrealisedpnl).toFixed(2) : 0 }}</span>  
                                        </template>
                                        <template v-slot:[`item.realisedpnl`]="{ item }">
                                            <span :class="{ 'green--text': item.total_realised_pnl > 0, 'red--text': item.total_realised_pnl < 0,'black--text': item.total_realised_pnl  == 0 }" class="font-weight-bold caption"  >{{ item.total_realised_pnl ? (item.total_realised_pnl).toFixed(2) : 0 }}</span>  
                                        </template>
                                        <template v-slot:no-data>
                                            <v-col class="mx-auto pa-15 mt-5">
                                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                                <p class="font-weight-bold black--text">No data available</p>
                                            </v-col>
                                        </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                    
                     
                </v-col>
                
            </div>
            <!-- mobile -->
            <div class="d-md-none">
                <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">
                             
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                                    Tax Report
 </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Calender for data of profit and loss

                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                          




                        </v-list-item>
                      
                    </v-list>


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <!-- <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
                    <div class="text-center pa-4">
                        <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                            Realised P&L</p>
                            <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </p>
                       
                    </div>
                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Unrealised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                          <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0 text-right"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters @click="chargerdialoge = true">
                                    <v-col cols="5">
                                        <p class="caption" @click="chargerdialoge = true"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Charges & taxes</p>
                                    </v-col>
                                    <v-col cols="7">
                                      
                                                <p :class="TaxesAndCharges > 0 ? 'green--text' : TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0 text-right"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </p>


                                        
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card> -->
                <div class="d-flex mt-4"> 
                    <!-- <v-select label="Filled" :items="filterr" @change="filterseg('mobile')" dense
                                rounded
                            
                                        solo flat
                                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                                class="mr-2 fildrop" style="max-width: 50%; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select> -->
                            <v-menu class="" v-model="menuForDatePickerMobile" left :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 50%;" class="  mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :min="fromdate[0]" :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="getCalenderData()"></v-date-picker> -->
                                    </div>
                                    <!-- <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p> -->
                                    <p  v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur'}"  @click="GetDataWithYearSelector(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear'}"  @click="GetDataWithYearSelector(-1,'lyear')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0]}"  @click="GetDataWithYearSelector(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1]}"  @click="GetDataWithYearSelector(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2]}"  @click="GetDataWithYearSelector(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>
                                
                            </v-menu>
                            <v-btn  @click="sheet = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
                                mdi-download</v-icon></v-btn>
                </div>
                <v-tabs v-model="tabs" background-color="transparent" color="basil" >
                    <v-tab class="text-capitalize caption"  >
                            <v-badge v-if="TableData0.length != 0"
                                color="black"
                                :content="TableData0.length"
                                >
                                STCG
                            </v-badge>
                            <p v-else class="mb-0 caption">STCG </p>
                         </v-tab>
                        <v-tab class="text-capitalize caption" >
                            <v-badge v-if="TableData1.length != 0"
                                color="black"
                                :content="TableData1.length"
                                >
                                LTCG
                            </v-badge>
                            <p v-else class="mb-0 caption">LTCG </p>
                        </v-tab>
                        <v-tab class="text-capitalize caption" >
                            <v-badge v-if="TableData2.length != 0"
                                color="black"
                                :content="TableData2.length"
                                >
                                Intraday
                            </v-badge>
                            <p v-else class="mb-0 caption">Intraday </p>
                        </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs" class="mt-">
                    <v-tab-item>
                        <v-card class="" outlined  style=" height : 260px; overflow-x: scroll;">
                            <!-- <div v-if="ChartValue.length > 0 && loader == false" class="pa-5">
                               

                                <div class="ml-1" id="cal-heatmap2"></div>
                                   
                             </div> -->
                             <div  v-if="loader == false && TableData0.length == 0" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="18%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div>
                                <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                            <div v-else-if="loader == true" class="text-center mt-6">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                <!-- </v-overlay> -->
                                 <div v-else-if="TableData0.length > 0 ">
                                    <v-card v-for="item in TableData0" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                                        class="elevation-0  px-4 pt-2" width="100%">

                                        <div class="d-flex ">
                                            <span @click="SymbolMatchedDate(item,'mobile')"  class="font-weight-bold">

                                                {{ item.SCRIP_SYMBOL }}  

                                                <!-- {{ item.COMPANY_CODE }} -->
                                                <!-- <span class="caption font-weight-medium">({{
                                                desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                                                </span>
                                            
                                                        
                                        
                                        </div>
                                    

                                        <v-divider class="mb-2 mt-2"></v-divider>
                                        <v-row class="px-2">
                                            <v-col>
                                                <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Buy Date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item['Buy date']
                                                        }}</span></p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_buy_qty
                                                        }}</span>
                                                </p>
                                                
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_buy_rate
                                                        }}</span>
                                                </p>
                                                <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">STCG : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.STCG
                                                        }}</span>
                                                </p>
                                            </v-col>
                                            <v-col class="text-end">
                                                <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.BUY_QUANTITY
                                                        }}</span>
                                                </p> -->
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.Selldate
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_sell_qty
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_sell_rate
                                                        }}</span>
                                                </p>
                                                 
                                            </v-col>
                                        </v-row>
                                            </v-card>
                                 </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card class="" outlined  style=" height : 260px; overflow-x: scroll;">
                            <!-- <div v-if="ChartValue.length > 0 && loader == false" class="pa-5">
                               

                                <div class="ml-1" id="cal-heatmap2"></div>
                                   
                             </div> -->
                             <div  v-if="loader == false && TableData1.length == 0" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="18%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div>
                                <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                            <div v-else-if="loader == true" class="text-center mt-6">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                <!-- </v-overlay> -->
                                 <div v-else-if="TableData1.length > 0 ">
                                    <v-card v-for="item in TableData1" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                                        class="elevation-0  px-4 pt-2" width="100%">

                                        <div class="d-flex ">
                                            <span @click="SymbolMatchedDate(item,'mobile')"  class="font-weight-bold">

                                                {{ item.SCRIP_SYMBOL }}  

                                                <!-- {{ item.COMPANY_CODE }} -->
                                                <!-- <span class="caption font-weight-medium">({{
                                                desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                                                </span>
                                            
                                                        
                                        
                                        </div>
                                    

                                        <v-divider class="mb-2 mt-2"></v-divider>
                                        <v-row class="px-2">
                                            <v-col>
                                                <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Buy Date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item['Buy date']
                                                        }}</span></p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_buy_qty
                                                        }}</span>
                                                </p>
                                                
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_buy_rate
                                                        }}</span>
                                                </p>
                                                <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">LTCG : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.LTCG
                                                        }}</span>
                                                </p>
                                            </v-col>
                                            <v-col class="text-end">
                                                <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.BUY_QUANTITY
                                                        }}</span>
                                                </p> -->
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.Selldate
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_sell_qty
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_sell_rate
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Fair Value 31/01/2018 : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.High_31Jan
                                                        }}</span>
                                                </p>
                                            </v-col>
                                        </v-row>
                                            </v-card>
                                 </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card class="" outlined  style=" height : 80vh; overflow-x: scroll;">
                            <!-- <div v-if="ChartValue.length > 0 && loader == false" class="pa-5">
                               

                                <div class="ml-1" id="cal-heatmap2"></div>
                                   
                             </div> -->
                             <div  v-if="loader == false && TableData2.length == 0" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="18%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div>
                                <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                            <div v-else-if="loader == true" class="text-center mt-6">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                <!-- </v-overlay> -->
                                 <div v-else-if="TableData2.length > 0 ">
                                    <v-card v-for="item in TableData2" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                                        class="elevation-0  px-4 pt-2" width="100%">

                                        <div class="d-flex ">
                                            <span @click="SymbolMatchedDate(item,'mobile')"  class="font-weight-bold">

                                                {{ item.SCRIP_SYMBOL }}  

                                                <!-- {{ item.COMPANY_CODE }} -->
                                                <!-- <span class="caption font-weight-medium">({{
                                                desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                                                </span>
                                            
                                                        
                                        
                                        </div>
                                    

                                        <v-divider class="mb-2 mt-2"></v-divider>
                                        <v-row class="px-2">
                                            <v-col>
                                                <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Buy Date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.Buydate
                                                        }}</span></p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_buy_qty
                                                        }}</span>
                                                </p>
                                                 
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_buy_rate
                                                        }}</span>
                                                </p>
                                                <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Intraday : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_intraday
                                                        }}</span>
                                                </p>
                                            </v-col>
                                            <v-col class="text-end">
                                                <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.BUY_QUANTITY
                                                        }}</span>
                                                </p> -->
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.Selldate
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_sell_qty
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_sell_rate
                                                        }}</span>
                                                </p>
                                                 
                                            </v-col>
                                        </v-row>
                                            </v-card>
                                 </div>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                        

                        
            </div>


            <!-- dialogue -->

            <v-dialog
      v-model="dialogForSymDetails"
           width="1000px"
    >
      
      <v-card height="400px" width="1000px">
        <v-toolbar class=" mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="mr-2" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-4">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Tax Report </v-list-item-title>

                                        <v-list-item-subtitle class="text-start "
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Calender for data of profit and loss
                                        </v-list-item-subtitle>
                                       
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>  
                            <v-btn icon @click="dialogForSymDetails = false"><v-icon>mdi mdi-close</v-icon></v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-data-table  ref="dataTableRef"   mobile-breakpoint
                                hide-default-footer height="320px" fixed-header   :headers="HeaderFortable"
                                :items="SymbolDataMatchedTableArray" :items-per-page="SymbolDataMatchedTableArray.length"
                                style="  border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg ">

                                <template v-slot:[`item.LTCG`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.LTCG }}</span>
                                </template>

                                <template v-slot:[`item.STCG`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.STCG }}</span>
                                </template>
                                <template v-slot:[`item.total_intraday`]="{ item }">
                                            <span class="font-weight-regular caption">{{ item.total_intraday }}</span>
                                        </template>
                                <template v-slot:[`item.Buydate`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item['Buy date'] }}</span>
                                </template>
                                <template v-slot:[`item.Selldate`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item['Selldate'] }}</span>
                                </template>
                                <template v-slot:[`item.Intraday_PnL`]="{ item }">
                                            <span class="font-weight-regular caption">{{ (item.Intraday_PnL).toFixed(2) }}</span>
                                        </template>
                                <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                    <span  class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                </template>
                                <template v-slot:[`item.STT`]="{ item }">
                                    <span  class="font-weight-bold caption">{{ item.STT }}</span>
                                </template>
                                <template v-slot:[`item.High_31Jan`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.High_31Jan }}</span>
                                </template>
                                <template v-slot:[`item.TRADE_DATEDt`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.TRADE_DATEDt }}</span>
                                </template>

                                <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.total_buy_qty`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BQTY }}</span>
                                </template>

                                <template v-slot:[`item.total_buy_rate`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BRATE ? (item.BRATE).toFixed(2) : 0 }}</span>
                                    <p class="font-weight-regular caption mb-0">{{ item.BRATE ? (item.BQTY * item.BRATE).toFixed(2) : 0 }}</p>
                                </template>

                                <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.total_sell_qty`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SQTY }}</span>
                                </template>

                                <template v-slot:[`item.total_sell_rate`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SRATE }}</span>
                                    <p class="font-weight-regular caption mb-0">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</p>

                                </template>
                                <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                </template>
                                <template v-slot:[`item.remainingbprice`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                </template>

                                <template v-slot:[`item.remainingqty`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                </template>

                                <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                    <span  :class="{ 'green--text': item.unrealisedpnl > 0, 'red--text': item.unrealisedpnl < 0,'black--text': item.unrealisedpnl  == 0 }"  class="font-weight-bold caption "  >{{ item.unrealisedpnl ? (item.unrealisedpnl).toFixed(2) : 0 }}</span>  
                                </template>
                                <template v-slot:[`item.realisedpnl`]="{ item }">
                                    <span :class="{ 'green--text': item.realisedpnl > 0, 'red--text': item.realisedpnl < 0,'black--text': item.realisedpnl  == 0 }" class="font-weight-bold caption"  >{{ item.realisedpnl ? (item.realisedpnl).toFixed(2) : 0 }}</span>  
                                </template>
                                <template v-slot:no-data>
                                    <v-col class="mx-auto pa-15">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                                        <p class="font-weight-bold black--text">No data available</p>
                                    </v-col>
                                </template>
                     </v-data-table>
      </v-card>
    </v-dialog>
    <v-bottom-sheet   v-model="dialogForSymDetailsMobile" height="600px">

        <v-card  height="600px">
            <v-toolbar class=" mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">
                             
                            <v-list-item-content class=" pt-0 mt-2">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:20px;letter-spacing:-1px">
                                    Profit & Loss </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                    and Loss of your trades.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                          




                        </v-list-item>
                      
                    </v-list>


            </v-toolbar>
            <v-divider class=" mx-2"></v-divider>
            {{ SymbolDataMatchedTableArray.length }}
            <v-card v-for="item in SymbolDataMatchedTableArray" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                                        class="elevation-0  px-4 pt-2" width="100%">

                                        <div class="d-flex ">
                                            <span    class="font-weight-bold">

                                                {{ item.SCRIP_SYMBOL }}  

                                                <!-- {{ item.COMPANY_CODE }} -->
                                                <!-- <span class="caption font-weight-medium">({{
                                                desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                                                </span>
                                            
                                                        
                                        
                                        </div>
                                    

                                        <v-divider class="mb-2 mt-2"></v-divider>
                                        <v-row class="px-2">
                                            <v-col>
                                                <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Buy Date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item['Buy date']
                                                        }}</span></p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_buy_qty
                                                        }}</span>
                                                </p>
                                                
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_buy_rate
                                                        }}</span>
                                                </p>
                                                <p v-if="item.is_stcg" class="mb-3 body-2 font-weight-regular" style="color: #666666;">STCG : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.STCG
                                                        }}</span>
                                                </p>
                                                <p v-else-if="item.is_ltcg" class="mb-3 body-2 font-weight-regular" style="color: #666666;">LTCG : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.LTCG
                                                        }}</span>
                                                </p>
                                                <p v-else-if="item.is_intraday" class="mb-3 body-2 font-weight-regular" style="color: #666666;">Intraday : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_intraday
                                                        }}</span>
                                                </p>
                                            </v-col>
                                            <v-col class="text-end">
                                                <!-- <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.BUY_QUANTITY
                                                        }}</span>
                                                </p> -->
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell date: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.Selldate
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                            item.total_sell_qty
                                                        }}</span>
                                                </p>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.total_sell_rate
                                                        }}</span>
                                                </p>
                                                <p v-if="item.is_ltcg" class="mb-3 body-2 font-weight-regular" style="color: #666666;">Fair Value 31/01/2018 : <span
                                                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.High_31Jan
                                                        }}</span>
                                                </p>
                                            </v-col>
                                        </v-row>
                                            </v-card>
        </v-card>
    </v-bottom-sheet>
    <div class="text-center">
                    <v-bottom-sheet v-model="sheet" inset>

                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <!-- <p class="mb-0 pt-3 title font-weight-medium">Summary P&L</p> -->
                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloadfun(radios)" class="white--text elevation-0" color="black" block
                                    rounded flat solo> {{
                                        radios }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
        </v-container>
    </v-app>
</template>
  
<script>
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import 'cal-heatmap/cal-heatmap.css';
import { apiurl } from '../../../Api.js'
import ExcelJS from 'exceljs';
// import { apiurl } from '../../../Api.js'
// import LegendLite from 'cal-heatmap/plugins/LegendLite';
import imagePath from '@/assets/zebulogo.svg';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import zebupdfImage from '../../../assets/zebupdf.png';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
export default {
    name: 'HeatmapComponent',
    data() {
        return {
            sheet:false,
            ChartValue : [],
            fromshow : '',
            filterdata_unpl : 'Equity',
            yearsarray : [],
            cal : null,
            highlightyearsValue : 'cur',
            dialogForSymDetails : false,
            dialogForSymDetailsMobile : false,
            fromdate : [],
            maxval : '',
            profiledetails :  [] ,
            menuForDatePicker : false,
            menuForDatePickerMobile : false,
            TaxesAndCharges : 0,
            UnRealishedPnl : 0,
            RealishedPnl : 0,
            DownloadedArray : [],
            FromDateForApi : '',
            ToDateForApi : '',
            susertoken : '',
            actid : '',
            cname : '',
            tabs : null,
            minval: '',
            loader : true,
            alert : false,
            rangeColr : ['#ff6e6e'  , '#5aae61'],
            rangeValue : [-0.01,0.01],
            Nodata : false,
            msg : '',
            HeaderFortable : [
            { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"    },
            // { text: 'Script Name', value: 'SCRIP_NAME', align: 'start', class: "headerfont"  },
            { text: 'Buy Date', value: 'Buydate' , align: 'right', class: "headerfont"   },
            { text: 'Buy Qty', value: 'total_buy_qty' , align: 'right', class: "headerfont"   },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            { text: 'Buy Rate', value: 'total_buy_rate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Date', value: 'Selldate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Qty', value: 'total_sell_qty' , align: 'right', class: "headerfont"   },
            // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            { text: 'Sell Rate', value: 'total_sell_rate' , align: 'right', class: "headerfont"   },
            { text: 'STCG', value: 'STCG' , align: 'right', class: "headerfont"   },
            { text: 'LTCG', value: 'LTCG' , align: 'right', class: "headerfont"   },
            { text: 'Fair Value  31/01/2018', value: 'High_31Jan', align: 'right', class: "headerfont"   },
            // { text: '31/01/2018 below', value: 'High_31Jan' , align: 'right', class: "headerfont" },
            // { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'Realisedpnl', value: 'realisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            HeaderFortable1 : [
            { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"    },
            // { text: 'Script Name', value: 'SCRIP_NAME', align: 'start', class: "headerfont"  },
            { text: 'Buy Date', value: 'Buydate' , align: 'right', class: "headerfont"   },
            { text: 'Buy Qty', value: 'total_buy_qty' , align: 'right', class: "headerfont"   },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            { text: 'Buy Rate', value: 'total_buy_rate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Date', value: 'Selldate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Qty', value: 'total_sell_qty' , align: 'right', class: "headerfont"   },
            // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            { text: 'Sell Rate', value: 'total_sell_rate' , align: 'right', class: "headerfont"   },
            { text: 'STCG', value: 'STCG' , align: 'right', class: "headerfont"   },
            { text: 'STT', value: 'total_stt', align: 'right', class: "headerfont"  },

            // { text: 'LTCG', value: 'LTCG' , align: 'right', class: "headerfont"   },
            // { text: 'Fair Value  31/01/2018', value: 'High_31Jan', align: 'right', class: "headerfont"   },
            // { text: '31/01/2018 below', value: 'High_31Jan' , align: 'right', class: "headerfont" },
            // { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'Realisedpnl', value: 'realisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            HeaderFortable2 : [
            { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"    },
            // { text: 'Script Name', value: 'SCRIP_NAME', align: 'start', class: "headerfont"  },
            { text: 'Buy Date', value: 'Buydate' , align: 'right', class: "headerfont"   },
            { text: 'Buy Qty', value: 'total_buy_qty' , align: 'right', class: "headerfont"   },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            { text: 'Buy Rate', value: 'total_buy_rate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Date', value: 'Selldate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Qty', value: 'total_sell_qty' , align: 'right', class: "headerfont"   },
            // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            { text: 'Sell Rate', value: 'total_sell_rate' , align: 'right', class: "headerfont"   },
            // { text: 'STCG', value: 'STCG' , align: 'right', class: "headerfont"   },
            { text: 'LTCG', value: 'LTCG' , align: 'right', class: "headerfont"   },
            { text: 'Fair Value  31/01/2018', value: 'High_31Jan', align: 'right', class: "headerfont"   },
            { text: 'STT', value: 'total_stt', align: 'right', class: "headerfont"  },

            // { text: '31/01/2018 below', value: 'High_31Jan' , align: 'right', class: "headerfont" },
            // { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'Realisedpnl', value: 'realisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            HeaderFortable3 : [
            { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"    },
            // { text: 'Script Name', value: 'SCRIP_NAME', align: 'start', class: "headerfont"  },
            { text: 'Buy Date', value: 'Buydate' , align: 'right', class: "headerfont"   },
            { text: 'Buy Qty', value: 'total_buy_qty' , align: 'right', class: "headerfont"   },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            { text: 'Buy Rate', value: 'total_buy_rate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Date', value: 'Selldate' , align: 'right', class: "headerfont"   },
            { text: 'Sell Qty', value: 'total_sell_qty' , align: 'right', class: "headerfont"   },
            // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            { text: 'Sell Rate', value: 'total_sell_rate' , align: 'right', class: "headerfont"   },
            { text: 'Intraday', value: 'total_intraday' , align: 'right', class: "headerfont"   },
            { text: 'STT', value: 'total_stt' , align: 'right', class: "headerfont"   },
            // { text: 'STCG', value: 'STCG' , align: 'right', class: "headerfont"   },
            // { text: 'LTCG', value: 'LTCG' , align: 'right', class: "headerfont"   },
            // { text: 'Fair Value  31/01/2018', value: 'High_31Jan', align: 'right', class: "headerfont"   },
            // { text: '31/01/2018 below', value: 'High_31Jan' , align: 'right', class: "headerfont" },
            // { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'Realisedpnl', value: 'realisedpnl' , align: 'right', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            TableData0 : [],
            TableData : [],
            TableData2 : [],
            TableData1 : [],
            filterr: ['Equity','Fno','Commodity','Currency'],
            SymbolDataMatched : [],
            SymbolDataMatchedTableArray: [],
            itemsdwl: [
                {
                    title: 'Download Excel',
                },
                {
                    title: 'Download Pdf',
                },
            ],
            radios:null,
        };
    }, 
    methods : {
        decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
        updateTableHeaders(item) {
                if (item.is_stcg) {
                    this.HeaderFortable = [
                    { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"  },
                    { text: 'Buy Date', value: 'Buydate', align: 'right', class: "headerfont"  },
                    { text: 'Buy qty', value: 'total_buy_qty', align: 'right', class: "headerfont"  },
                    { text: 'Buy Rate', value: 'total_buy_rate', align: 'right', class: "headerfont"  },
                    { text: 'Sell Date', value: 'Selldate', align: 'right', class: "headerfont"  },
                    { text: 'Sell Qty', value: 'total_sell_qty', align: 'right', class: "headerfont"  },
                    { text: 'Srate', value: 'total_sell_rate', align: 'right', class: "headerfont"  },
                    { text: 'STCG', value: 'STCG', align: 'right', class: "headerfont"  },
                    { text: 'STT', value: 'STT', align: 'right', class: "headerfont"  },

                    ];
                } else if (item.is_ltcg) {
                    this.HeaderFortable = [
                    { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"  },
                    { text: 'Buy Date', value: 'Buydate', align: 'right', class: "headerfont"  },
                    { text: 'Buy Qty', value: 'total_buy_qty', align: 'right', class: "headerfont"  },
                    { text: 'Buy Rate', value: 'total_buy_rate', align: 'right', class: "headerfont"  },
                    { text: 'Sell Date', value: 'Selldate', align: 'right', class: "headerfont"  },
                    { text: 'Sell Qty', value: 'total_sell_qty', align: 'right', class: "headerfont"  },
                    { text: 'Sell Rate', value: 'total_sell_rate', align: 'right', class: "headerfont"  },
                    { text: 'LTCG', value: 'LTCG', align: 'right', class: "headerfont"  },
                    { text: 'Fair Value 31/01/2018', value: 'High_31Jan', align: 'right', class: "headerfont"  },
                    { text: 'STT', value: 'STT', align: 'right', class: "headerfont"  },

                    ];
                } else if (item.is_intraday) {
                    this.HeaderFortable = [
                    { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont"  },
                    { text: 'Buy Date', value: 'Buydate', align: 'right', class: "headerfont"  },
                    { text: 'Buy Qty', value: 'total_buy_qty', align: 'right', class: "headerfont"  },
                    { text: 'Buy Rate', value: 'total_buy_rate', align: 'right', class: "headerfont"  },
                    { text: 'Sell Date', value: 'Selldate', align: 'right', class: "headerfont"  },
                    { text: 'Sell Qty', value: 'total_sell_qty', align: 'right', class: "headerfont"  },
                    { text: 'Sell Rate', value: 'total_sell_rate', align: 'right', class: "headerfont"  },
                    { text: 'Intraday', value: 'Intraday_PnL', align: 'right', class: "headerfont"  },
                    { text: 'STT', value: 'STT', align: 'right', class: "headerfont"  },
                    ];
                }
        },
        SymbolMatchedDate(item,val){
            // console.log(item,val);
            this.SymbolDataMatchedTableArray = []
            let ScriptName = item.SCRIP_SYMBOL
            if (this.SymbolDataMatched[ScriptName]) {
                    this.SymbolDataMatchedTableArray = this.SymbolDataMatched[ScriptName] 
                }
                 console.log(this.SymbolDataMatchedTableArray,item.SCRIP_SYMBOL);
                 
            this.updateTableHeaders(item)
                 
           
            if(val == 'mobile'){
                this.dialogForSymDetailsMobile = true
            }else{
                this.dialogForSymDetails = true

            }
            // for (let i = 0; i < this.SymbolDataMatched.length; i++) {
                
            // }
            // console.log(this.SymbolDataMatchedTableArray);
        },
        reset(){
            this.maxval = this.resetrange
            this.fromdate = []
            this.highlightyearsValue = ''
        },
        GetDataWithYearSelector(valuevalue,item) {
            this.tabs = 0

                this.highlightyearsValue = item
                //console.log(this.highlightyearsValue);
                this.menuForDatePicker = false
                let valuedate = valuevalue
                this.loader = true 
                this.menuForDatePickerMobile = false 
                var today = new Date();
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();
                var offset = valuedate * -1;
                var yearmount = ""
                var yearnext = ""
                if (mm < 4) {
                    yearmount = yyyy - 1 - offset;
                    yearnext = yyyy - offset;
                } else {
                    yearmount = yyyy - offset;
                    yearnext = yyyy + 1 - offset;
                }

              
               
                this.fromdate = []
                this.fromdate.push(yearmount + '-' + '04' + '-' + '01');  
                this.loader = true 
                this.fromdate.push( yearnext + '-03-31') 
                this.fromshow =  '01'+ '/' + '04' + '/'  + yearmount + '_to_' + '31/03/' + yearnext 
                this.datemaxvalue()
                this.FromDateForApi = '01' + '/' + '04' + '/' + yearmount 
                this.ToDateForApi = '31/03/' + yearnext  
            
                this.DataGetApi()
        },
        filterseg(item){
            // console.log( this.fromdate)
            this.tabs = 0
            if (item == 'mobile' && this.fromdate.length < 2) {
                this.menuForDatePickerMobile = true
                
            }else if(this.fromdate.length < 2){
                this.menuForDatePicker = true
            }else{
                this.getCalenderData()
            }
        },
        datemaxvalue(item){
            let valuefordate = this.fromdate
            //console.log(valuefordate);
        //    if (this.fromdate.length < 1) {
        //         this.fromdate = valuefordate
        //         //console.log(this.fromdate.length,this.fromdate)

        //    }else{
        //         this.fromdate = []
        //         this.fromdate = valuefordate
            // //console.log(valuefordate[0].split("-")[0])

        //    }
            
            // this.maxval = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
            // //console.log(valuefordate,this.maxval,typeof(Number(valuefordate.split("-")[0])))
            // //console.log(valuefordate[0])
            // //console.log(Number(valuefordate[1]))
            // if (condition) {
                
            // }
            if (Number(valuefordate[0].split("-")[1]) > 3) {
                this.maxval = `${Number(valuefordate[0].split("-")[0]) + 1}-03-31`
              
                // //console.log(this.maxval,"huhu");
                // if (item != 'mounted') {
                //     this.todate = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
                // }
                // //console.log(this.maxval,"1")
            }
            else{
                this.maxval = `${Number(valuefordate[0].split("-")[0])}-03-31`
            // //console.log(this.maxval,"2")
                // //console.log(this.maxval,"huhu");

                // if (item != 'mounted') {
                //         this.todate = `${Number(valuefordate.split("-")[0])}-03-31`
                // }
            }

            if (item == 'click' && valuefordate.length > 1) {
                this.getCalenderData()
                this.highlightyearsValue = ''
                this.tabs = 0
            }
         },
        getCalenderData() {
                this.Nodata = true
                this.menuForDatePicker = false  
                this.menuForDatePickerMobile = false  
                this.loader = true 
                let dateString = this.fromdate[0]
                let dateObject = new Date(dateString);
                let year = dateObject.getFullYear();
                let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                let day = dateObject.getDate();
                let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
                this.FromDateForApi = formattedDate 
                let dateString1 = this.fromdate[1]
                let dateObject1 = new Date(dateString1);
                let year1 = dateObject1.getFullYear();
                let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
                let day1 = dateObject1.getDate();
                this.ToDateForApi = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1; 
                this.fromshow = this.FromDateForApi + '_' + 'to' + '_' + this.ToDateForApi
                this.datemaxvalue()
                this.DataGetApi()
               
        },
        chart(){ 
                const data = [  ];
                for (let i = 0; i < this.ChartValue.length; i++) {
                    data.push(this.ChartValue[i])
                }
                //console.log(data);
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                this.cal = new CalHeatmap();
                this.cal.paint({
                    data: { source: data, x: "date", y: "score"},
                    date: { start: new Date((this.fromdate[0]).split("-")[0] + '-' + '04' +  '-01') },
                    range: 12,
                    scale: {
                    color: 
                    { range: this.rangeColr,     type: 'linear',interpolate: 'hsl', domain: this.rangeValue }
                //     color: function (date, value, dayjsDate) {
                //     //console.log(value, dayjsDate,date);
                //     if (value > 0) {
                //         return {
                //             type: "quantize",
                //             scheme: 'Greens',
                //             domain: [-100000, 100000]
                //         };
                //     } else {
                //         return {
                //             type: "quantize",
                //             scheme: 'Reds',
                //             domain: [-100000, 100000]
                //         };
                //     }
                // }
                },
                domain: {
                    type: 'month',
                    // label: "MM",
                    gutter: 6
                },
                subDomain: { type: 'day', radius: 2,  width: 13, 
                // label: 'DD',
                height: 13,},
                itemSelector: '#cal-heatmap',
                // itemSelector: '#cal-heatmap',
            
            },
            [
            [
            CalendarLabel,
                {
                    position: 'left',
                    key: 'left',
                    text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                    textAlign: 'end',
                    width: 30,
                    padding: [0, 5, 0, 0],
                },
            ],
            // [
            //     LegendLite,
            //     {
            //     radius: 3,
            //     itemSelector: '#cal-heatmap',
            //     },
            // ],
                [
                Tooltip,
                    {
                        text: function (date, value, dayjsDate) {
                            // console.log('valvalvalvalvalvalvalvlavla',dayjsDate ,date);
                        return value == null ? "" : `Realised P&L  ${value} <br> ${dayjsDate.format('LL')}`;
                        },
                    },
                ],
    
            ]
        
        );
        },
        chartMobile(){ 
                const data = [  ];
                for (let i = 0; i < this.ChartValue.length; i++) {
                    data.push(this.ChartValue[i])
                }
                //console.log(data);
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            this.cal = new CalHeatmap();
            this.cal.paint({
                data: { source: data, x: "date", y: "score"},
                date: { start: new Date((this.fromdate[0]).split("-")[0] + '-' + '04' +  '-01') },
                //   verticalOrientation: true,
                range: 12,
                scale: {
                    color: 
                    { range: this.rangeColr,     type: 'linear',interpolate: 'hsl', domain: this.rangeValue }
                //     color: function (date, value, dayjsDate) {
                //     //console.log(value, dayjsDate,date);
                //     if (value > 0) {
                //         return {
                //             type: "quantize",
                //             scheme: 'Greens',
                //             domain: [-100000, 100000]
                //         };
                //     } else {
                //         return {
                //             type: "quantize",
                //             scheme: 'Reds',
                //             domain: [-100000, 100000]
                //         };
                //     }
                // }
                },
                domain: {
                    type: 'month',
                    // label: "MM",
                    gutter: 6
                },
                subDomain: { type: 'day', radius: 2,  width: 25, 
                // label: 'DD',
                height: 25,},
                // itemSelector: '#cal-heatmap',
                itemSelector: '#cal-heatmap2',
            
            },
            [
            [
            CalendarLabel,
                {
                    position: 'left',
                    key: 'left',
                    text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                    textAlign: 'end',
                    width: 30,
                    padding: [0, 5, 0, 0],
                },
            ],
                [
                Tooltip,
                {
                text: function (date, value, dayjsDate) {
                    // console.log(value, dayjsDate);
                    return value == null ? "" : `Realised P&L  ${value} <br> ${dayjsDate.format('LL')}`;
         
        },
    },
                ],
    
            ]
        
        );
        },
        MountedDData(){
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        this.yearlist()
        this.cname = localStorage.getItem("cname"); 
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        if (mm < 4) {
            var yearmount = yyyy - 1
        } else {
            yearmount = yyyy
        }
        
        this.FromDateForApi = '01' + '/' + '04' + '/' + yearmount;
        this.fromdate.push((yearmount) + '-' + '04' + '-' +  '01')
       
        this.fromdate.push(yyyy + '-' + mm + '-' + dd) 
        this.resetrange= yyyy + '-' + mm + '-' + dd
        // this.minval = yearmount - 2 +  '-' + '04' + '-' +'01'  ;
        this.ToDateForApi = dd + '/' + mm + '/' + yyyy; 
        this.fromshow = (this.FromDateForApi) + '_' + 'to' + '_' + (this.ToDateForApi) 
        this.datemaxvalue()
        this.DataGetApi()
        },
        yearlist(){
            var today = new Date();
            let sevthdate = new Date(today);
            
            let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
            
            let yyyysevthdate = sevthdate.getFullYear();
            if (mmsevthdate < 4) {
                var yearmount = yyyysevthdate - 1
                }else{
                    yearmount = yyyysevthdate
                }
                var startYear = yearmount - 4;
            // this.yearis = yearmount;

            for (let year = yearmount; year >= startYear; year--) {
            // let yeararraytem = []

            this.yearsarray.push(year)
            }
            this.yearsarray = this.yearsarray.slice(2, 5)
            // //console.log(this.yearsarray)
        },
        formatDate(dateStr) {
            const dateObj = new Date(dateStr);
            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = dateObj.getFullYear();
            
            return `${day}/${month}/${year}`;
        },
        DataGetApi(){
            this.TaxesAndCharges = 0
            this.UnRealishedPnl = 0
            this.RealishedPnl = 0
            this.TableData = []
            this.TableData0 = []
            this.TableData1 = []
            this.TableData2 = []
            this.ChartValue = []
            if (this.filterdata_unpl == 'Equity') {
                var SegmentValue = 'NSE_CASH,BSE_CASH'
            }else if(this.filterdata_unpl == 'Fno'){ 
                SegmentValue = 'NSE_FNO,BSE_FNO'
            }else if( this.filterdata_unpl == 'Commodity'){
                SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
            }else if(this.filterdata_unpl == 'Currency'){
                SegmentValue =  'CD_NSE,CD_BSE,CD_MCX,CD_USE'
            }
            const axios = require("axios");
                let data = JSON.stringify({
                    "cc": this.actid,
                    "from": this.FromDateForApi,
                    "to": this.ToDateForApi,
                    "segment": SegmentValue
                });
                let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${apiurl}/gettaxdetails`,
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
                };
                let axiosthis = this
                axios.request(config)
                .then((response) => {
                console.log( response.data);
                
                // axiosthis.ChartValue = response.data.journal
                        if (response.data != 'No Data') {
                            // axiosthis.ChartValue = []
                            axiosthis.TableData = []
                            axiosthis.TableData0 = []
                            axiosthis.TableData1 = []
                            axiosthis.TableData2 = []
                            // axiosthis.Nodata = false
                            axiosthis.loader = false
                            axiosthis.SymbolDataMatched =  response.data.symbolarr
                            var tableData = response.data.Data
                            // axiosthis.TableData.sort((a, b) => {
                            // if (a.TRADE_DATEDt < b.TRADE_DATEDt) return -1;
                            // if (a.TRADE_DATEDt > b.TRADE_DATEDt) return 1;
                            // return 0;
                            // });
                            //console.log('tableytable' ,axiosthis.TableData )
                            // var RealishedPnl = 0
                            // var UnRealishedPnl = 0
                            // var TaxesAndCharges = 0
                            for (let i = 0; i < tableData.length; i++) {
                                 if (tableData[i].last == true) {
                                    if (tableData[i]['Buy date'] != '') {
                                        tableData[i]['Buy date'] = axiosthis.formatDate(tableData[i]['Buy date']) 
                                        
                                    }else{
                                        tableData[i]['Buy date'] = '-'

                                    }
                                    if (tableData[i]['Sell date'] != '') {
                                            tableData[i]['Selldate'] = axiosthis.formatDate(tableData[i]['Sell date'])
                                        }else{
                                            tableData[i]['Selldate'] = '-'

                                        }
                                   
                                    axiosthis.TableData.push(tableData[i])
                                 }
                            }

                            Object.keys(axiosthis.SymbolDataMatched).forEach(key => {
                            axiosthis.SymbolDataMatched[key].forEach(item => {
                                // If 'Buy date' is not empty, format it
                                if (item["Buy date"]) {
                                item['Buy date'] = this.formatDate(item["Buy date"]);
                                } else {
                                item['Buy date'] = "-"; // Handle empty 'Buy date'
                                }
                                if (item["Sell date"]) {
                                item['Selldate'] = this.formatDate(item["Sell date"]);
                                } else {
                                item['Selldate'] = "-"; // Handle empty 'Buy date'
                                }

                            });
                            });
                            for (let i = 0; i < axiosthis.SymbolDataMatched.length; i++) {
                                  
                                    if (axiosthis.SymbolDataMatched[i]['Buy date'] != '') {
                                        axiosthis.SymbolDataMatched[i]['Buy date'] = axiosthis.formatDate(axiosthis.SymbolDataMatched[i]['Buy date']) 
                                        
                                    }else{
                                        axiosthis.SymbolDataMatched[i]['Buy date'] = '-'

                                    }
                                    if (axiosthis.SymbolDataMatched[i]['Sell date'] != '') {
                                        axiosthis.SymbolDataMatched[i]['Selldate'] = axiosthis.formatDate(axiosthis.SymbolDataMatched[i]['Sell date'])
                                        }else{
                                            axiosthis.SymbolDataMatched[i]['Selldate'] = '-'

                                        }
                                   
                                    axiosthis.axiosthis.SymbolDataMatched.push(axiosthis.SymbolDataMatched[i])
                                  
                            }

                           for (let i = 0; i < axiosthis.TableData.length; i++) {
                            const element = axiosthis.TableData[i];
                            if (element.is_stcg == true) {
                                axiosthis.TableData0.push(element)
                            }else if(element.is_ltcg == true){
                                axiosthis.TableData1.push(element)
                            }else if(element.is_intraday == true){
                                axiosthis.TableData2.push(element)
                                
                            }
                           }
                        //    const HeadingArr = ['STCG','LTCG','Intraday']
                        //    const ValArr = [axiosthis.TableData0,axiosthis.TableData1,axiosthis.TableData2]
                        //    const EmptyLine = {"BAMT":"","BAMT_ACK":"","BQTY":"","BRANCH_CODE":"","BRANCH_CODE1":"","BRANCH_CODE_FAX":"","BRANCH_CODE_MAIL":"","BRANCH_NAME":"","BRATE":"","BRATE_ACK":"","Buy date":"","CLIENT_ID":"","CLIENT_ID_FAX":"","CLIENT_ID_MAIL":"","CLIENT_NAME":"","CLOSING_PRICE":"","COMPANY_CODE":"","Delivery_buy_STT":"","Delivery_sell_STT":"","EXCHANGE":"","EXPIRY_DATE":"","FAMILY_GROUP":"","FAMILY_GROUP_FAX":"","FAMILY_GROUP_MAIL":"","FAMILY_GROUP_NAME":"","FULL_SCRIP_SYMBOL":"","High_31Jan":"","ISIN":"","Intraday_PnL":"","Intraday_STT":"","LTCG":"","MAIN_BRANCH_CODE":"","MARKET":"","NARRATION":"","NETAMT":"","NETAMT_ACK":"","NETQTY":"","NRATE":"","NRATE_ACK":"","SAMT":"","SAMT_ACK":"","SCRIP_NAME":"","SCRIP_SYMBOL":"","SQTY":"","SR":"","SRATE":"","SRATE_ACK":"","STCG":"","STT":"","Sell date":"","TRADE_DATE":"","TRADE_DATEDt":"","Updated_NETQTY":"","cf_buy_amt":"","cf_buy_qty":"","cf_sell_amt":"","cf_sell_qty":"","index":"","is_intraday":"","is_ltcg":"","is_stcg":"","last":"","realisedpnl":"","total_buy_qty":"","total_buy_rate":"","total_intraday":"","total_ltcg":"","total_realised_pnl":"","total_sell_qty":"","total_sell_rate":"","total_stcg":"","total_stt":"","unrealisedpnl":""}
                        //    for (let i = 0; i < HeadingArr.length; i++) {
                        //     const Heading = HeadingArr[i];
                        //     const Value = ValArr[i];
                        //     axiosthis.DownloadedArray.push(Heading)
                        //     axiosthis.DownloadedArray.push(EmptyLine)
                        //     axiosthis.DownloadedArray.push(Value)
                        //    }
                        //    console.log(axiosthis.DownloadedArray,"download")
                            // for (let i = 0; i < axiosthis.TableData.length; i++) {
                            //     const element = axiosthis.TableData[i];
                            //     if (element['Buy date'] != '') {
                                    
                            //     }
                                

                            // }
                            // for (let i = 0; i < response.data.journal.length; i++) {
                            //     let dateStr = response.data.journal[i]['TRADE_DATE'];
                            //     let dateObj = new Date(dateStr);
                            //     let formattedDate = dateObj.toISOString().slice(0, 10);
                            //     var ProfValue = response.data.journal[i]['realisedpnl']
                            //             if (ProfValue != 0) {
                            //                 // console.log("iflaenter",ProfValue,i)
                            //                 let num = response.data.journal[i]['realisedpnl']
                                            
                            //                 ProfValue = Number(parseFloat(num).toFixed(2))
                            //             }else {
                            //                 ProfValue = ''
                            //                 //console.log("elsela enter ",ProfValue)
                            //             }
                            //             axiosthis.ChartValue.push(
                            //                 {
                            //                     date : formattedDate,
                            //                     score :  ProfValue,
                            //                     realisedpnl : ProfValue
                            //                 }
                            //             )
                            //             //console.log(ProfValue);
                            //             // RealishedPnl += response.data.journal[i]['realisedpnl'];
                            //             // UnRealishedPnl += response.data.journal[i]['unrealisedpnl'];
                            //             // TaxesAndCharges += response.data.journal[i]['total_bill_net'];
                            // }

                            //console.log(RealishedPnl,UnRealishedPnl,TaxesAndCharges);
                            // axiosthis.RealishedPnl = RealishedPnl
                            // axiosthis.UnRealishedPnl = UnRealishedPnl
                            // axiosthis.TaxesAndCharges = TaxesAndCharges
                            
                            // axiosthis.chart()
                            // setTimeout(() => {
                        }else{
                         
                            // axiosthis.chart()
                            // axiosthis.chartMobile()
                            axiosthis.ChartValue = []
                            axiosthis.loader = false
                            axiosthis.Nodata = true
                            axiosthis.TableData = []
                            axiosthis.TaxesAndCharges = 0
                            axiosthis.UnRealishedPnl = 0
                            axiosthis.UnRealishedPnl = 0
                        }
                    //console.log(axiosthis.ChartValue);

                })
                .catch((error) => {
                    console.log(error);
                    axiosthis.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                });
        },
        convertImageToBase64() {

fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.imageBase64 = reader.result;
        };
        reader.readAsDataURL(blob);
    })
    .catch((error) => {
        console.error('Error loading local image:', error);
    });
},
        downloadfun(item){
            
this.sheet = false
this.radios = null
            let data = item
//             if (data === 'Download Excel') {
//   const workbook = new ExcelJS.Workbook();
//   const worksheet = workbook.addWorksheet('TaxReport');

//   // Define header rows
//   const headerRows = [
//     ["", "TaxReport", "Client ID", this.actid],
//     ["", "", "Client Name", this.cname],
//     ["", "", "From Date", this.FromDateForApi],
//     ["", "", "To Date", this.ToDateForApi],
//     ["", "", "Segment", this.filterdata_unpl],
//     [],  // Empty row for spacing
//   ];

//   // Object to store total values for each type
//   let totalSum = {
//     Bqty: 0, Brate: 0, bavg: 0, Sqty: 0, Srate: 0, savl: 0, STCG: 0, LTCG: 0, Intraday: 0, sttad: 0
//   };

//   const rows = [];

//   // Function to calculate totals and prepare row data
//   const calculateTotals = (dataArray) => {
//     return dataArray.map(element => {
//       totalSum.Bqty += parseFloat(element.total_buy_qty || 0);
//       totalSum.Brate += parseFloat(element.total_buy_rate || 0);
//       totalSum.bavg += parseFloat((element.total_buy_qty * element.total_buy_rate).toFixed(2) || 0);
//       totalSum.Sqty += parseFloat(element.total_sell_qty || 0);
//       totalSum.Srate += parseFloat(element.total_sell_rate || 0);
//       totalSum.savl += parseFloat((element.total_sell_qty * element.total_sell_rate).toFixed(2) || 0);
//       totalSum.STCG += parseFloat(element.total_stcg || 0);
//       totalSum.LTCG += parseFloat(element.total_ltcg || 0);
//       totalSum.Intraday += parseFloat(element.total_intraday || 0);
//       totalSum.sttad += parseFloat(element.total_stt || 0);

//       return [
//         element.SCRIP_SYMBOL || "",
//         element["Buy date"] || "",
//         element.total_buy_qty || "0",
//         element.total_buy_rate || "0",
//         (element.total_buy_qty * element.total_buy_rate).toFixed(2) || "0",
//         element.Selldate || "",
//         element.total_sell_qty || "0",
//         element.total_sell_rate || "0",
//         (element.total_sell_qty * element.total_sell_rate).toFixed(2) || "0",
//         element.total_intraday || "0",
//         element.total_stcg || "0",
//         element.total_ltcg || "0",
//         element.High_31Jan || "0",
//         element.total_stt || "0"
//       ];
//     });
//   };

//   // Reset totalSum for new section (STCG, LTCG, Intraday)
//   const resetTotalSum = () => {
//     totalSum = { Bqty: 0, Brate: 0, bavg: 0, Sqty: 0, Srate: 0, savl: 0, STCG: 0, LTCG: 0, Intraday: 0, sttad: 0 };
//   };

//   const processDataTable = (tableData, title) => {
//     if (tableData.length > 0) {
//       // Add section title row
//       rows.push([title, "", "", "", "", "", "", "", "", "", "", "", "", ""]);
      
//       // Add table heading row
//       rows.push(["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sel Date", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"]);
      
//       // Add rows from data
//       rows.push(...calculateTotals(tableData));
      
//       // Add total row for the section
//       rows.push([
//         "Total", "", 
//         totalSum.Bqty.toFixed(2), totalSum.Brate.toFixed(2), totalSum.bavg.toFixed(2), "", 
//         totalSum.Sqty.toFixed(2), totalSum.Srate.toFixed(2), totalSum.savl.toFixed(2), 
//         totalSum.Intraday.toFixed(2), totalSum.STCG.toFixed(2), totalSum.LTCG.toFixed(2), 
//         "", totalSum.sttad.toFixed(2)
//       ]);

//       rows.push([]);  // Empty row for spacing
//       resetTotalSum();
//     }
//   };

//   // Process TableData0 (STCG)
//   processDataTable(this.TableData0, "STCG");
  
//   // Process TableData1 (LTCG)
//   processDataTable(this.TableData1, "LTCG");

//   // Process TableData2 (Intraday)
//   processDataTable(this.TableData2, "Intraday");

//   // Combine headerRows with data rows
//   const allRows = headerRows.concat(rows);

//   // Add rows to the worksheet
//   allRows.forEach((row) => {
//     const excelRow = worksheet.addRow(row);
//     row.forEach((cell, cellIndex) => {
//       const cellRef = excelRow.getCell(cellIndex + 1);

//       // Apply font and fill styles based on cell content
//       if (["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sel Date", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"].includes(cell)) {
//         cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
//         cellRef.font = { bold: true, color: { argb: 'ffffff' } };
//       } else if (['TaxReport', 'Total'].includes(cell)) {
//         cellRef.font = { name: 'Arial', size: 11, bold: true };
//       }

//       // Align number cells to the right and set number format
//       if (["Buy Qty", "Buy Rate", "Buy Value", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"].includes(headerRows[headerRows.length - 1][cellIndex])) {
//         cellRef.alignment = { horizontal: 'right' };
//         cellRef.numFmt = '#,##0.00';  // Set number format for better readability
//       } else {
//         cellRef.alignment = { horizontal: 'left' };
//       }
//     });
//   });

//   // Adjust column widths
//   worksheet.columns.forEach((column) => {
//     let maxLength = 0;
//     column.eachCell({ includeEmpty: true }, (cell) => {
//       const cellValue = cell.value ? cell.value.toString() : '';
//       maxLength = Math.max(maxLength, cellValue.length);
//     });
//     column.width = Math.max(10, maxLength + 2); // Set a minimum width of 10
//   });

//   // Add borders to all cells
//   worksheet.eachRow({ includeEmpty: false }, (row) => {
//     row.eachCell({ includeEmpty: false }, (cell) => {
//       if (cell.value !== "    .") {
//         cell.border = {
//           top: { style: 'thin' },
//           left: { style: 'thin' },
//           bottom: { style: 'thin' },
//           right: { style: 'thin' }
//         };
//       }
//     });
//   });

//   // Add the image to the worksheet if exists
//   if (this.imageBase64) {
//     const imageId = workbook.addImage({
//       base64: this.imageBase64,
//       extension: 'svg'  // or 'png', 'jpeg', etc.
//     });

//     worksheet.getRow(1).height = 32.53;  // Adjust height to fit the image
//     worksheet.getColumn(1).width = 20;  // Adjust width to fit the image

//     worksheet.addImage(imageId, {
//       tl: { col: 0, row: 0 },
//       ext: { width: 102.53, height: 40.53 }
//     });
//   } else {
//     console.warn('No image base64 data provided.');
//   }

//   // Write the Excel file to a buffer
//   workbook.xlsx.writeBuffer().then((buffer) => {
//     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = `Tax Report_${this.FromDateForApi}_${this.ToDateForApi}.xlsx`;
//     link.click();
//   });
// }

// if (data === 'Download Excel') {
//   const workbook = new ExcelJS.Workbook();
//   const worksheet = workbook.addWorksheet('TaxReport');

//   // Define header rows
//   const headerRows = [
//     ["", "TaxReport", "Client ID", this.actid],
//     ["", "", "Client Name", this.cname],
//     ["", "", "From Date", this.FromDateForApi],
//     ["", "", "To Date", this.ToDateForApi],
//     ["", "", "Segment", this.filterdata_unpl],
//     [],  // Empty row for spacing
//   ];

//   // Object to store total values for each type
//   let totalSum = {
//     Bqty: 0, Brate: 0, bavg: 0, Sqty: 0, Srate: 0, savl: 0, STCG: 0, LTCG: 0, Intraday: 0, sttad: 0
//   };

//   const rows = [];

//   // Function to calculate totals and prepare row data
//   const calculateTotals = (dataArray, isSTCG) => {
//     return dataArray.map(element => {
//       totalSum.Bqty += parseFloat(element.total_buy_qty || 0);
//       totalSum.Brate += parseFloat(element.total_buy_rate || 0);
//       totalSum.bavg += parseFloat((element.total_buy_qty * element.total_buy_rate).toFixed(2) || 0);
//       totalSum.Sqty += parseFloat(element.total_sell_qty || 0);
//       totalSum.Srate += parseFloat(element.total_sell_rate || 0);
//       totalSum.savl += parseFloat((element.total_sell_qty * element.total_sell_rate).toFixed(2) || 0);
//       totalSum.STCG += parseFloat(element.total_stcg || 0);
//       totalSum.LTCG += parseFloat(element.total_ltcg || 0);
//       totalSum.Intraday += parseFloat(element.total_intraday || 0);
//       totalSum.sttad += parseFloat(element.total_stt || 0);

//       // Return row data based on whether it's STCG or LTCG/Intraday
//       if (isSTCG) {
//         return [
//           element.SCRIP_SYMBOL || "",
//           element["Buy date"] || "",
//           element.total_buy_qty || "0",
//           element.total_buy_rate || "0",
//           (element.total_buy_qty * element.total_buy_rate).toFixed(2) || "0",
//           element.Selldate || "",
//           element.total_sell_qty || "0",
//           element.total_sell_rate || "0",
//           (element.total_sell_qty * element.total_sell_rate).toFixed(2) || "0",
//         //   element.total_intraday || "0",
//           element.total_stcg || "0",
//         //   element.total_ltcg || "0",
//         //   element.High_31Jan || "0",
//           element.total_stt || "0"
//         ];
//       } else {
//         return [
//           element.SCRIP_SYMBOL || "",
//           element["Buy date"] || "",
//           element.total_buy_qty || "0",
//           element.total_buy_rate || "0",
//           (element.total_buy_qty * element.total_buy_rate).toFixed(2) || "0",
//           element.Selldate || "",
//           element.total_sell_qty || "0",
//           element.total_sell_rate || "0",
//           (element.total_sell_qty * element.total_sell_rate).toFixed(2) || "0",
//           element.total_intraday || "0",
//           element.total_stcg || "0",
//           element.total_ltcg || "0",
//           element.High_31Jan || "0",
//           element.total_stt || "0"
//         ];
//       }
//     });
//   };

//   // Reset totalSum for new section (STCG, LTCG, Intraday)
//   const resetTotalSum = () => {
//     totalSum = { Bqty: 0, Brate: 0, bavg: 0, Sqty: 0, Srate: 0, savl: 0, STCG: 0, LTCG: 0, Intraday: 0, sttad: 0 };
//   };

//   const processDataTable = (tableData, title, isSTCG = false) => {
//     if (tableData.length > 0) {
//       // Add section title row
//       rows.push([title]);

//       // Add table heading row
//       const headings = isSTCG ? ["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sell Date", "Sell Qty", "Sell Rate", "Sell Value",  "STCG",  "STT"] : 
//                                 ["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sell Date", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"];
//       rows.push(headings);

//       // Add rows from data
//       rows.push(...calculateTotals(tableData, isSTCG));

//       // Add total row for the section
//       const totalRow = isSTCG ? [
//         "Total", "", 
//         totalSum.Bqty.toFixed(2), totalSum.Brate.toFixed(2), totalSum.bavg.toFixed(2), "", 
//         totalSum.Sqty.toFixed(2), totalSum.Srate.toFixed(2), totalSum.savl.toFixed(2), 
//         totalSum.STCG.toFixed(2), totalSum.sttad.toFixed(2)
//       ] : [
//         "Total", "", 
//         totalSum.Bqty.toFixed(2), totalSum.Brate.toFixed(2), totalSum.bavg.toFixed(2), "", 
//         totalSum.Sqty.toFixed(2), totalSum.Srate.toFixed(2), totalSum.savl.toFixed(2), 
//         totalSum.Intraday.toFixed(2), totalSum.STCG.toFixed(2), totalSum.LTCG.toFixed(2), 
//         "", totalSum.sttad.toFixed(2)
//       ];
//       rows.push(totalRow);

//       rows.push([]);  // Empty row for spacing
//       resetTotalSum();
//     }
//   };

//   // Process TableData0 (STCG)
//   processDataTable(this.TableData0, "STCG", true);
  
//   // Process TableData1 (LTCG)
//   processDataTable(this.TableData1, "LTCG");

//   // Process TableData2 (Intraday)
//   processDataTable(this.TableData2, "Intraday");

//   // Combine headerRows with data rows
//   const allRows = headerRows.concat(rows);

//   // Add rows to the worksheet
//   allRows.forEach((row) => {
//     const excelRow = worksheet.addRow(row);
//     row.forEach((cell, cellIndex) => {
//       const cellRef = excelRow.getCell(cellIndex + 1);

//       // Apply font and fill styles based on cell content
//       if (["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sell Date", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"].includes(cell)) {
//         cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
//         cellRef.font = { bold: true, color: { argb: 'ffffff' } };
//       } else if (['TaxReport', 'Total'].includes(cell)) {
//         cellRef.font = { name: 'Arial', size: 11, bold: true };
//       }

//       // Align number cells to the right and set number format
//       if (["Buy Qty", "Buy Rate", "Buy Value", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"].includes(headerRows[headerRows.length - 1][cellIndex])) {
//         cellRef.alignment = { horizontal: 'right' };
//         cellRef.numFmt = '#,##0.00';  // Set number format for better readability
//       } else {
//         cellRef.alignment = { horizontal: 'left' };
//       }
//     });
//   });

//   // Adjust column widths
//   worksheet.columns.forEach((column) => {
//     let maxLength = 0;
//     column.eachCell({ includeEmpty: true }, (cell) => {
//       const cellValue = cell.value ? cell.value.toString() : '';
//       maxLength = Math.max(maxLength, cellValue.length);
//     });
//     column.width = Math.max(10, maxLength + 2); // Set a minimum width of 10
//   });

//   // Add borders to all cells
//   worksheet.eachRow({ includeEmpty: false }, (row) => {
//     row.eachCell({ includeEmpty: false }, (cell) => {
//       if (cell.value !== "    .") {
//         cell.border = {
//           top: { style: 'thin' },
//           left: { style: 'thin' },
//           bottom: { style: 'thin' },
//           right: { style: 'thin' }
//         };
//       }
//     });
//   });

//   // Add the image to the worksheet if exists
//   if (this.imageBase64) {
//     const imageId = workbook.addImage({
//       base64: this.imageBase64,
//       extension: 'svg'  // or 'png', 'jpeg', etc.
//     });

//     worksheet.getRow(1).height = 32.53;  // Adjust height to fit the image
//     worksheet.getColumn(1).width = 20;  // Adjust width to fit the image

//     worksheet.addImage(imageId, {
//       tl: { col: 0, row: 0 },
//       ext: { width: 102.53, height: 40.53 }
//     });
//   } else {
//     console.warn('No image base64 data provided.');
//   }

//   // Save the workbook
//   workbook.xlsx.writeBuffer().then((buffer) => {
//     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'TaxReport.xlsx';
//     a.click();
//     URL.revokeObjectURL(url);
//   });
// }


if (data === 'Download Excel') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('TaxReport');

  // Define header rows
  const headerRows = [
    ["", "TaxReport", "Client ID", this.actid],
    ["", "", "Client Name", this.cname],
    ["", "", "From Date", this.FromDateForApi],
    ["", "", "To Date", this.ToDateForApi],
    ["", "", "Segment", this.filterdata_unpl],
    [],  // Empty row for spacing
  ];

  // Object to store total values for each type
  let totalSum = {
    Bqty: 0, Brate: 0, bavg: 0, Sqty: 0, Srate: 0, savl: 0, STCG: 0, LTCG: 0, Intraday: 0, sttad: 0,LTCGpair:0
  };

  const rows = [];

  // Function to calculate totals and prepare row data
  const calculateTotals = (dataArray, type) => {
    return dataArray.map(element => {
      totalSum.Bqty += parseFloat(element.total_buy_qty || 0);
      totalSum.Brate += parseFloat(element.total_buy_rate || 0);
      totalSum.bavg += parseFloat((element.total_buy_qty * element.total_buy_rate).toFixed(2) || 0);
      totalSum.Sqty += parseFloat(element.total_sell_qty || 0);
      totalSum.Srate += parseFloat(element.total_sell_rate || 0);
      totalSum.savl += parseFloat((element.total_sell_qty * element.total_sell_rate).toFixed(2) || 0);
      totalSum.sttad += parseFloat(element.total_stt || 0);
      
      // Conditional totals based on type
      if (type === 'STCG') {
        totalSum.STCG += parseFloat(element.total_stcg || 0);
        // totalSum.STCG += parseFloat(element.total_stt || 0);

        return [
          element.SCRIP_SYMBOL || "",
          element["Buy date"] || "",
          element.total_buy_qty || "0",
          element.total_buy_rate || "0",
          (element.total_buy_qty * element.total_buy_rate).toFixed(2) || "0",
          element.Selldate || "",
          element.total_sell_qty || "0",
          element.total_sell_rate || "0",
          (element.total_sell_qty * element.total_sell_rate).toFixed(2) || "0",
          element.total_stcg || "0",
          element.total_stt || "0"
        ];
      } else if (type === 'LTCG') {
        totalSum.LTCG += parseFloat(element.total_ltcg || 0);
        totalSum.LTCGpair += parseFloat(element.High_31Jan || 0);

        return [
          element.SCRIP_SYMBOL || "",
          element["Buy date"] || "",
          element.total_buy_qty || "0",
          element.total_buy_rate || "0",
          (element.total_buy_qty * element.total_buy_rate).toFixed(2) || "0",
          element.Selldate || "",
          element.total_sell_qty || "0",
          element.total_sell_rate || "0",
          (element.total_sell_qty * element.total_sell_rate).toFixed(2) || "0",
          element.total_ltcg || "0",
          element.High_31Jan || "0",
          element.total_stt || "0"
        ];
      } else if (type === 'Intraday') {
        totalSum.Intraday += parseFloat(element.total_intraday || 0);
        // totalSum.Intraday += parseFloat(element.total_stt || 0);

        return [
          element.SCRIP_SYMBOL || "",
          element["Buy date"] || "",
          element.total_buy_qty || "0",
          element.total_buy_rate || "0",
          (element.total_buy_qty * element.total_buy_rate).toFixed(2) || "0",
          element.Selldate || "",
          element.total_sell_qty || "0",
          element.total_sell_rate || "0",
          (element.total_sell_qty * element.total_sell_rate).toFixed(2) || "0",
          element.total_intraday || "0",
          element.total_stt || "0"
        ];
      }
      return [];
    });
  };
  
  // Reset totalSum for new section
  const resetTotalSum = () => {
    totalSum = { Bqty: 0, Brate: 0, bavg: 0, Sqty: 0, Srate: 0, savl: 0, STCG: 0, LTCG: 0, Intraday: 0, sttad: 0,LTCGpair:0 };
  };

  const processDataTable = (tableData, title, type) => {
    if (tableData.length > 0) {
      // Add section title row
      rows.push([title]);
      
      // Add table heading row based on type
      let headings;
      if (type === 'STCG') {
        headings = ["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sel Date", "Sell Qty", "Sell Rate", "Sell Value", "STCG", "STT"];
      } else if (type === 'LTCG') {
        headings = ["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sel Date", "Sell Qty", "Sell Rate", "Sell Value", "LTCG", "Fair Value 31/01/2018", "STT"];
      } else if (type === 'Intraday') {
        headings = ["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sel Date", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STT"];
      }
      
      rows.push(headings);
      
      // Add rows from data
      rows.push(...calculateTotals(tableData, type));
      
      // Add total row for the section
      rows.push([
    "Total", "", 
    totalSum.Bqty.toFixed(2), 
    totalSum.Brate.toFixed(2), 
    totalSum.bavg.toFixed(2), 
    "", 
    totalSum.Sqty.toFixed(2), 
    totalSum.Srate.toFixed(2), 
    totalSum.savl.toFixed(2), 
    type === 'STCG' ? totalSum.STCG.toFixed(2) : 
    type === 'LTCG' ? totalSum.LTCG.toFixed(2) : 
    totalSum.Intraday.toFixed(2), 
    type === 'LTCG' ? totalSum.LTCGpair.toFixed(2) : totalSum.sttad.toFixed(2),
    type === 'LTCG' ?  totalSum.sttad.toFixed(2) : ""
]);


      rows.push([]);  // Empty row for spacing
      resetTotalSum();
    }
  };

  // Process TableData0 (STCG)
  processDataTable(this.TableData0, "STCG", 'STCG');
  
  // Process TableData1 (LTCG)
  processDataTable(this.TableData1, "LTCG", 'LTCG');

  // Process TableData2 (Intraday)
  processDataTable(this.TableData2, "Intraday", 'Intraday');

  // Combine headerRows with data rows
  const allRows = headerRows.concat(rows);

  // Add rows to the worksheet
  allRows.forEach((row) => {
    const excelRow = worksheet.addRow(row);
    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);

      // Apply font and fill styles based on cell content
      if (["Script Symbol", "Buy Date", "Buy Qty", "Buy Rate", "Buy Value", "Sel Date", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"].includes(cell)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true, color: { argb: 'ffffff' } };
      } else if (['TaxReport', 'Total'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 11, bold: true };
      }

      // Align number cells to the right and set number format
      if (["Buy Qty", "Buy Rate", "Buy Value", "Sell Qty", "Sell Rate", "Sell Value", "Intraday", "STCG", "LTCG", "Fair Value 31/01/2018", "STT"].includes(allRows[headerRows.length - 1][cellIndex])) {
        cellRef.alignment = { horizontal: 'right' };
        cellRef.numFmt = '#,##0.00';  // Set number format for better readability
      } else {
        cellRef.alignment = { horizontal: 'left' };
      }
    });
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = Math.max(10, maxLength + 2); // Set a minimum width of 10
  });

  // Add borders to all cells
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });
   if (this.imageBase64) {
    const imageId = workbook.addImage({
      base64: this.imageBase64,
      extension: 'svg'  // or 'png', 'jpeg', etc.
    });

    worksheet.getRow(1).height = 32.53;  // Adjust height to fit the image
    worksheet.getColumn(1).width = 20;  // Adjust width to fit the image

    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 102.53, height: 40.53 }
    });
  } else {
    console.warn('No image base64 data provided.');
  }
  // Write to file
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `TaxReport_${this.actid}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  });
}







        
            else if (data == 'Download Pdf') {
                let rows = [];
                let rows1 = [];
                let rows2 = [];
                // let rowsexpense = [];
                // let headerexpense = [];

                let pdfName = `${'Tax report'}`;
               
                // this.dessertsexpense.forEach(element => {
                //     var temp3 = [
                //         element.NET_AMOUNT
                //     ]
                //     rowsexpense.push(temp3)
                // });
          
                // let columnHeader = ['Script Symbol','Buy Date','Buy qty','Buy Rate','Sell date','Sell qty','Sell rate','Intraday','STCG','LTCG','Fair Value 31/01/2018','STT'];
                
                // console.log(rows);
                // Create a new jsPDF document
                var doc = new jsPDF();
                // Add header and customize text
                //doc.setFont('Tenon');
                // doc.setFontSize(12);
                // doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
                // doc.setFontSize(6);
                // doc.text("Correspondence Office:No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096.", 38, 13);
                // doc.setFontSize(6);
                // doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
                // doc.setFontSize(6);
                // doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
                // doc.setFontSize(6);
                // doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
                doc.setFontSize(7);
                doc.setFont('bold');
                doc.text(`${'Client Name   :  '}${this.cname}`, 15, 33);
                doc.setFontSize(7);
                doc.text(`${'Client Code    :  '}${this.actid}`, 15, 37);
                doc.setFontSize(7);
                doc.text(`${'PAN               :  '}${this.profiledetails['PAN_NO']}`, 15, 41);
                doc.setFontSize(7);
                doc.text(`${'Email Id         :  '}${this.profiledetails['CLIENT_ID_MAIL']}`, 15, 45);
                doc.setFontSize(7);
                doc.text(`${'From     :  '}${this.fromshow.split('_to_')[0]}`, 133, 33);
                doc.setFontSize(7);
                doc.text(`${'To         :  '}${this.fromshow.split('_to_')[1]}`, 133, 37);
                doc.setFontSize(7);
                doc.text(`${'Mobile  :  '}${this.profiledetails['MOBILE_NO']}`, 133, 41);
                doc.setFontSize(7);
                doc.text(`${'Address :  '}${this.profiledetails['CL_RESI_ADD1']}`, 133, 45);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD2']}`, 144, 48);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD3']}`, 144, 51);
                doc.setFontSize(14);
                doc.text('Tax report', 90, 14.5);
                const dividerY = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(0, dividerY, 218, dividerY); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                
                doc.setFontSize(8);
                // //doc.setFont('Tenon');
                
                const columnStyles = {
                    0: { fontStyle: 'bold',textColor: [0, 0, 0],halign: 'left'},
                    1 : {halign: 'left'},
                    2 : {halign: 'right'},
                    3 : {halign: 'right'},
                    4 : {halign: 'right'},
                    5:  {halign: 'left'},
                    6 : {halign: 'right'},
                    7 : {halign: 'right'},
                    8 : {halign: 'right'},
                    9 : {halign: 'right'},
                    // 8 : {halign: 'left'}
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
                // doc.setFontSize(7);
                // doc.setFont('bold');
                // doc.text(`${'Client Name   :  '}${this.cname}`, 15, 58);
                let columnHeader = ['Script Symbol','Buy Date','Buy qty','Buy Rate','Buy value','Sell date','Sell qty','Sell rate','Sell value' ,'STCG' ,'STT'];
                let columnHeader1 = ['Script Symbol','Buy Date','Buy qty','Buy Rate','Buy value','Sell date','Sell qty','Sell rate','Sell value', 'LTCG','Fair Value 31/01/2018','STT'];
                let columnHeader2 = ['Script Symbol','Buy Date','Buy qty','Buy Rate','Buy value','Sell date','Sell qty','Sell rate','Sell value','Intraday' ,'STT'];
                // let val1 = {  'SCRIP_SYMBOL' : 'Total', 'Buy date' : '', 'total_buy_qty' : 0,'total_buy_rate' : 0,'Selldate' : '','total_sell_qty' : 0,'total_sell_rate' : 0,'STCG' : '','total_stt' : ''}
                // let val2 = {  'SCRIP_SYMBOL' : 'Total', 'Buy date' : '', 'total_buy_qty' : 0,'total_buy_rate' : 0,'Selldate' : '','total_sell_qty' : 0,'total_sell_rate' : 0,'LTCG' : '','High_31Jan' : '','total_stt' : ''}
                // let val3 = {  'SCRIP_SYMBOL' : 'Total', 'Buy date' : '', 'total_buy_qty' : 0,'total_buy_rate' : 0,'Selldate' : '','total_sell_qty' : 0,'total_sell_rate' : 0,'STCG' : '','total_stt' : ''}
               // console.log(headerexpense);
            //    let totalsRow1 = ['Total', '', 0, 0, 0, '', 0, 0, 0, 0,0,0];
            //    let totalsRow2 = ['Total', '', 0, 0, 0, '', 0, 0, 0, 0,0];
                var BqtyTotal =  0
                var BrateTotal =  0
                var BvalueTotal =  0
                var SqtyTotal =  0
                var SrateTotal =  0
                var SvalueTotal =  0
                var STCG =  0
                var STT =  0
                
                var BqtyTotal1 =  0
                var BrateTotal1 =  0
                var BvalueTotal1 =  0
                var SqtyTotal1 =  0
                var SrateTotal1 =  0
                var SvalueTotal1 =  0
                var LTCG =  0
                var FValue =  0
                var STT1 =  0
               
                var BqtyTotal2 =  0
                var BrateTotal2 =  0
                var BvalueTotal2 =  0
                var SqtyTotal2 =  0
                var SrateTotal2 =  0
                var SvalueTotal2 =  0
                var IntradayValue = 0
                var STT2 =  0


               this.TableData0.forEach(element => {
                    
                    BqtyTotal += parseFloat(element.total_buy_qty || 0);
                    BrateTotal += parseFloat(element.total_buy_rate || 0);
                    BvalueTotal += parseFloat(Number(element.total_buy_rate) * Number(element.total_buy_qty) || 0);
                    SqtyTotal += parseFloat(element.total_sell_qty || 0);
                    SrateTotal += parseFloat(element.total_sell_rate || 0);
                    SvalueTotal += parseFloat((Number(element.total_sell_qty) * Number(element.total_sell_rate)) || 0);
                    STCG += parseFloat(element.STCG || 0);
                    STT += parseFloat(element.total_stt || 0);
                    

                   var temp = [
                       element.SCRIP_SYMBOL,
                       element['Buy date'],
                       element.total_buy_qty,
                       element.total_buy_rate,
                       (Number(element.total_buy_rate) * Number(element.total_buy_qty)).toFixed(2), 
                       element['Selldate'],
                       element.total_sell_qty,
                       element.total_sell_rate,
                       (Number(element.total_sell_qty) * Number(element.total_sell_rate)).toFixed(2), 

                       // element.is_intraday == true ? element.total_intraday : '-',
                       element.is_stcg == true ? element.STCG : '-',
                       // element.is_ltcg == true ? element.LTCG : '-',
                       // element.is_ltcg == true ? element.High_31Jan : '-',
                       element.total_stt,
                       
                   ];
                   rows.push(temp);
                    
               });
               let totalsRow = ['Total', '', BqtyTotal.toFixed(2), BrateTotal.toFixed(2), BvalueTotal.toFixed(2), '', SqtyTotal.toFixed(2), SrateTotal.toFixed(2), SvalueTotal.toFixed(2),STCG.toFixed(2),STT.toFixed(2) ];
               rows.push(totalsRow);

               this.TableData1.forEach(element => {
                    BqtyTotal1 += parseFloat(element.total_buy_qty || 0);
                    BrateTotal1 += parseFloat(element.total_buy_rate || 0);
                    BvalueTotal1 += parseFloat(Number(element.total_buy_rate) * Number(element.total_buy_qty) || 0);
                    SqtyTotal1 += parseFloat(element.total_sell_qty || 0);
                    SrateTotal1 += parseFloat(element.total_sell_rate || 0);
                    SvalueTotal1 += parseFloat((Number(element.total_sell_qty) * Number(element.total_sell_rate)) || 0);
                    FValue += parseFloat(element.High_31Jan || 0);
                    LTCG += parseFloat(element.LTCG || 0);
                    STT1 += parseFloat(element.total_stt || 0);
                   var temp = [
                       element.SCRIP_SYMBOL,
                       element['Buy date'],
                       element.total_buy_qty,
                       element.total_buy_rate,
                       (Number(element.total_buy_rate) * Number(element.total_buy_qty)).toFixed(2), 
                       element['Selldate'],
                       element.total_sell_qty,
                       element.total_sell_rate,
                       (Number(element.total_sell_qty) * Number(element.total_sell_rate)).toFixed(2), 

                       // element.is_intraday == true ? element.total_intraday : '-',
                    //    element.is_stcg == true ? element.STCG : '-',
                       element.is_ltcg == true ? element.LTCG : '-',
                       element.is_ltcg == true ? element.High_31Jan : '-',
                       element.total_stt,
                       
                   ];
                   rows1.push(temp);
               });

               let totalsRow1 = ['Total', '', BqtyTotal1.toFixed(2), BrateTotal1.toFixed(2), BvalueTotal1.toFixed(2), '', SqtyTotal1.toFixed(2), SrateTotal1.toFixed(2), SvalueTotal1.toFixed(2),LTCG.toFixed(2),FValue.toFixed(2),STT1.toFixed(2) ];
               rows1.push(totalsRow1);

               this.TableData2.forEach(element => {
                    BqtyTotal2 += parseFloat(element.total_buy_qty || 0);
                    BrateTotal2 += parseFloat(element.total_buy_rate || 0);
                    BvalueTotal2 += parseFloat(Number(element.total_buy_rate) * Number(element.total_buy_qty) || 0);
                    SqtyTotal2 += parseFloat(element.total_sell_qty || 0);
                    SrateTotal2 += parseFloat(element.total_sell_rate || 0);
                    SvalueTotal2 += parseFloat((Number(element.total_sell_qty) * Number(element.total_sell_rate)) || 0);
                    IntradayValue += parseFloat(element.total_intraday || 0);
                    STT2 += parseFloat(element.total_stt || 0);

                   var temp = [
                       element.SCRIP_SYMBOL,
                       element['Buy date'],
                       element.total_buy_qty,
                       element.total_buy_rate,
                       (Number(element.total_buy_rate) * Number(element.total_buy_qty)).toFixed(2), 
                       element['Selldate'],
                       element.total_sell_qty,
                       element.total_sell_rate,
                       (Number(element.total_sell_qty) * Number(element.total_sell_rate)).toFixed(2), 

                       element.is_intraday == true ? element.total_intraday : '-',
                    //    element.is_stcg == true ? element.STCG : '-',
                    //    element.is_ltcg == true ? element.LTCG : '-',
                    //    element.is_ltcg == true ? element.High_31Jan : '-',
                       element.total_stt,
                       
                   ];
                   rows2.push(temp);
               });
                // Initial table starting Y position
                let totalsRow2 = ['Total', '', BqtyTotal2.toFixed(2), BrateTotal2.toFixed(2), BvalueTotal2.toFixed(2), '', SqtyTotal2.toFixed(2), SrateTotal2.toFixed(2), SvalueTotal2.toFixed(2),IntradayValue.toFixed(2),STT2.toFixed(2) ];
                rows2.push(totalsRow2);
            let currentY = 61;

            // Generate the first table
           
            if (this.TableData0.length > 0) {
                    doc.setFontSize(10);
                    doc.setFont('bold');
                    doc.text(`STCG`, 15, currentY-5);
                    autoTable(doc, {
                    head: [columnHeader],
                    body: rows,
                    startY: currentY,
                    styles: { fontSize: 7 },
                    theme: 'grid',
                    headStyles: { fillColor: [31, 52, 101], textColor: [255, 255, 255] },
                    columnStyles: columnStyles
                });

                // Update currentY to the end of the first table
                currentY = doc.lastAutoTable.finalY + 20; // Adding 10 for some space between tables
                }
            // Check if the second table has data
            if (this.TableData1.length > 0) {
                doc.setFontSize(10);
                    doc.setFont('bold');
                    doc.text(`LTCG`, 15, currentY-5);

                autoTable(doc, {
                    head: [columnHeader1],
                    body: rows1,
                    startY: currentY,
                    styles: { fontSize: 7 },
                    theme: 'grid',
                    headStyles: { fillColor: [31, 52, 101], textColor: [255, 255, 255] },
                    columnStyles: columnStyles
                });

                // Update currentY to the end of the second table
                currentY = doc.lastAutoTable.finalY + 20; // Add space for the next table
            }

            // Repeat for the third table if needed
            if (this.TableData2.length > 0) {
                doc.setFontSize(10);
                    doc.setFont('bold');
                    doc.text(`Intraday`, 15, currentY-5);

                autoTable(doc, {
                    head: [columnHeader2],
                    body: rows2,
                    startY: currentY,
                    styles: { fontSize: 7 },
                    theme: 'grid',
                    headStyles: { fillColor: [31, 52, 101], textColor: [255, 255, 255] },
                    columnStyles: columnStyles
                });
            }

//                         if (doc.internal.pages.length > 1) {
//                             doc.addPage();
//                             doc.autoTable.previous.finalY = 10

// } else {
//     doc.autoTable.previous.finalY += 20; // Add some spacing between tables
// }
                        // doc.text('End table', 10, doc.autoTable.previous.finalY + 10);
                        doc.setFontSize(12);
                        doc.setTextColor(0, 0, 0);
                        
                                        // Add custom text
                                        // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                                        // Save the PDF
                                        var totalPages = doc.internal.getNumberOfPages();
                        for (var i = 1; i <= totalPages; i++) {
                            doc.setPage(i);
                            
                            
                            const dividerY = doc.internal.pageSize.height - 13; // Adjust the Y-coordinate based on your layout
                            doc.setFontSize(6);
                            doc.text(14, doc.internal.pageSize.height - 9, 'Zebu Share And Wealth Management Private Limited');
                            doc.setFontSize(4);
                            doc.text(14, doc.internal.pageSize.height - 7, 'Correspondence Office:No: 301, 4th Main Road, Burma Colony, Perungudi, Chennai, Tamil Nadu 600 096.');
                            doc.setFontSize(4);
                            doc.text(84, doc.internal.pageSize.height - 7, 'Phone No:044-4855 7991, Fax :044-4855 7991');
                            doc.setFontSize(4);
                            doc.text(14, doc.internal.pageSize.height - 5, 'TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704');
                            doc.setFontSize(4);
                            doc.text(109, doc.internal.pageSize.height - 5, 'Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com');
                            doc.setLineWidth(0.2); // Set line width
                            doc.setDrawColor(0); // Set line color to black
                            doc.line(0, dividerY, 218, dividerY); 
                        }
                                        doc.save(pdfName + '.pdf');
            }





        }
    }, 
    mounted() { 
        var res = this.decryptionFunction(localStorage.getItem("profile_data"));
    // console.log('ttttt',JSON.parse(res));
    var resp = JSON.parse(res);
    this.profiledetails = resp.client_data;
        this.MountedDData()
        this.convertImageToBase64()
    }
};
</script>

<style lang="scss">
.basil {
    background-color: #FFFBE6 !important;
}

.basil--text {
    color: #020202 !important;
}

.v-tab {
    text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
    /* flex: 1 0 auto; */
    max-width: none !important;
}
</style>