// src/services/apiService.js
const axios = require('axios');

// Define your API base URL (in this case, JSONPlaceholder)
// const API_BASE_URL = 'https://rekycbe.mynt.in/report';
var API_BASE_URL = '';

/**
 * Sends a POST request to the specified endpoint with data.
 * @param {string} endpoint = '- The API endpoint (e.g., 'posts').
 * @param {Object} data - The data to be sent in the request body.
 * @returns {Promise<Object>} - The response data from the API.
 * @param {Object} headers 
 */
 
    const postData = async (endpoint, data,headers) => {
        //  if (endpoint == 'getJournal') {
        //     API_BASE_URL = 'http://192.168.5.163:9003'
        //  }else{
            API_BASE_URL = 'https://rekycbe.mynt.in/report'
        //  }
        
        try {
          const response = await axios.post(`${API_BASE_URL}/${endpoint}`, data,{
              headers: headers,
            });
          return response.data; // Return the response data
        } catch (error) {
          console.error('Error sending data:', error);
          throw error; 
        }
      };
      
 

module.exports = { postData };
